import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';

import { Label } from 'reactstrap';
import InputMask from 'react-input-mask';

import { DropDown, DropdownItem } from '@app/components/Autocomplete';

import { toast } from 'react-hot-toast';

import Api from '@app/services/Api';
import { URLs, StateAcronymsToName, useReplaceParams } from '@app/constants';

export default function AddressFields({ field_context = '', disabled = false }) {
    const replaceParams = useReplaceParams;

    const { values, setValues, setFieldValue, setFieldTouched } = useFormikContext();

    const [fieldContext, setFieldContext] = useState('');
    useEffect(() => {
        if ((field_context || '') === '') return;
        setFieldContext(`${field_context}.`);
    }, [field_context]);

    /**
     * Consulta por cidades
     */
    const [citiesSuggestions, setCitiesSuggestions] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const onCitiesSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason !== 'input-changed') return;
        const url = replaceParams(URLs.services.locations.cities.list, { name: value, state: selectedState || '' });
        try {
            Api({ method: 'get', url })
                .then(({ data }) => {
                    setCitiesSuggestions(data);
                })
                .catch((err) => {
                    toast.error(err?.data?.message || 'Erro inesperado ao tentar consultar a cidade.');
                });
        } catch (error) {
            // console.log(error);
        }
    };

    const handleStateChange = (state) => {
        if (values === null || setValues === null) return;
        setSelectedState(state);
        setFieldValue(`${fieldContext}state`, state);
        setFieldValue(`${fieldContext}city_id`, '');
        setFieldValue(`${fieldContext}city_name`, '');

        setCitiesSuggestions([]);
    };
    // Adiciona o usuário à lista de acesso
    const handleCitySuggestionSelected = (suggestion) => {
        if (values === null || setValues === null) return;
        setFieldValue(`${fieldContext}city_id`, suggestion.id);
        setFieldValue(`${fieldContext}city_name`, suggestion.name);

        setCitiesSuggestions([]);
        return suggestion.name;
    };
    // Render the suggested user list
    const renderCitiesSuggestions = (suggestion) => (
        <DropdownItem tag="label" className="suggestion-item" key={suggestion.id}>
            {suggestion.name}
        </DropdownItem>
    );

    const getValue = (obj, context, defaultValue = '', count = 0) => {
        if (count >= 50) return defaultValue;
        const arr = context.split('.');
        if (arr.length === 0) return defaultValue;
        const key = arr.shift();

        if (!obj?.[key]) return defaultValue;
        const res = obj[key];
        if (typeof res === 'object') return getValue(res, arr.join('.'), defaultValue, count + 1);
        return res;
    };

    return (
        <>
            <div className="col-12 col-sm-8 px-2 py-1">
                <Label htmlFor={`${fieldContext}address`}>
                    Endereço<em>*</em>
                </Label>
                <Field type="text" name={`${fieldContext}address`} className="col-12" disabled={disabled || false} />
                <ErrorMessage component="span" name={`${fieldContext}address`} className="text-error text-small" />
            </div>
            <div className="col-12 col-sm-4 px-2 py-1">
                <Label htmlFor={`${fieldContext}number`}>
                    Número<em>*</em>
                </Label>
                <Field type="text" name={`${fieldContext}number`} className="col-12" disabled={disabled || false} />
                <ErrorMessage component="span" name={`${fieldContext}number`} className="text-error text-small" />
            </div>
            <div className="col-12 col-sm-6 px-2 py-1">
                <Label htmlFor={`${fieldContext}complement`}>Complemento</Label>
                <Field type="text" name={`${fieldContext}complement`} className="col-12" disabled={disabled || false} />
                <ErrorMessage component="span" name={`${fieldContext}complement`} className="text-error text-small" />
            </div>
            <div className="col-12 col-sm-6 px-2 py-1">
                <Label htmlFor={`${fieldContext}reference`}>Referência</Label>
                <Field type="text" name={`${fieldContext}reference`} className="col-12" disabled={disabled || false} />
                <ErrorMessage component="span" name={`${fieldContext}reference`} className="text-error text-small" />
            </div>
            <div className="col-12 col-sm-4 px-2 py-1">
                <Label htmlFor="zip">
                    CEP<em>*</em>
                </Label>
                <InputMask
                    name={`${fieldContext}zip`}
                    type="text"
                    className="col-12"
                    mask="99 999-999"
                    placeholder="__ ___-___"
                    value={getValue(values, `${fieldContext}zip`)}
                    disabled={disabled || false}
                    onChange={({ target: { value } }) => {
                        setFieldValue(`${fieldContext}zip`, value);
                        setFieldTouched(`${fieldContext}zip`, true, true);
                    }}
                    onBlur={() => {
                        setFieldTouched(`${fieldContext}zip`, true, true);
                    }}
                />
                <ErrorMessage component="span" name={`${fieldContext}zip`} className="text-error text-small" />
            </div>
            <div className="col-12 col-sm-8 px-2 py-1">
                <Label htmlFor={`${fieldContext}neighborhood`}>
                    Bairro<em>*</em>
                </Label>
                <Field type="text" name={`${fieldContext}neighborhood`} className="col-12" disabled={disabled || false} />
                <ErrorMessage component="span" name={`${fieldContext}neighborhood`} className="text-error text-small" />
            </div>
            <div className="col-12 col-sm-4 px-2 py-1">
                <Label htmlFor="state" title="Estado">
                    Estado<em>*</em>
                </Label>
                <select
                    type="text"
                    name="state"
                    className="col-12"
                    value={getValue(values, `${fieldContext}state`)}
                    onChange={(e) => handleStateChange(e.target.value)}
                    disabled={disabled || false}
                >
                    <option key="all_states" value="">
                        Selecione um estado
                    </option>
                    {Object.keys(StateAcronymsToName).map((acronym) => (
                        <option key={acronym} value={acronym}>
                            {StateAcronymsToName[acronym]}
                        </option>
                    ))}
                </select>
                <ErrorMessage component="span" name={`${fieldContext}state`} className="text-error text-small" />
            </div>
            <div className="col-12 col-sm-8 px-2 py-1 pb-3">
                <Label htmlFor={`${fieldContext}city_id`}>
                    Cidade<em>*</em>
                </Label>
                <DropDown
                    className="col-12"
                    disabled={getValue(values, `${fieldContext}state`) === '' || disabled || false}
                    name={`${fieldContext}city_id`}
                    placeholder="Buscar cidade"
                    selectedValue={getValue(values, `${fieldContext}city_name`)}
                    suggestions={citiesSuggestions}
                    onSuggestionsFetchRequested={onCitiesSuggestionsFetchRequested}
                    renderSuggestion={renderCitiesSuggestions}
                    getSuggestionValue={(suggestion) => {
                        handleCitySuggestionSelected(suggestion);
                    }}
                    formValues={values}
                    onSetValues={setValues}
                />
                <ErrorMessage component="span" name={`${fieldContext}city_id`} className="text-error text-small" />
            </div>
        </>
    );
}
