import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';

import { Row, Col, Button, Label } from 'reactstrap';
import { URLs } from '@app/constants';

import Modal from 'react-modal';
import Api from '@app/services/Api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
function ScheduleConfirmation({
    data = {},
    closeFormModal = () => {},
    updateRequest = () => {},
    closeModal = () => {},
    title = '',
    modalIsOpen = false,
}) {
    const history = useHistory();

    const [isSubmitting, setSubmitting] = useState(false);
    const handleConfirmSchedule = () => {
        setSubmitting(true);
        const url = URLs.club.certificates.calendar.index;
        Api({ method: 'put', url, data: data || {} })
            .then(({ data, status }) => {
                if (data && status === 200) {
                    toast.success(data?.message || 'Agendamento realizado com sucesso.');
                    history.push('/certificados');
                } else {
                    toast.error(data?.message || 'Não foi possível realizar o agendamento.');
                    closeFormModal();
                    updateRequest();
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar enviar os dados');
                setSubmitting(false);
            });
    };

    const afterOpenModal = () => {};

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel={title}
            className=""
            ariaHideApp={false}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">{title}</h2>
                    <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={closeModal}>
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    </Button>
                </Col>
            </Row>
            <p className="col-12">
                Verifique os dados abaixo e clique em &quot;CONFIRMAR&quot; para efetuar o agendamento da sua entrevista.
            </p>
            <div className="d-flex flex-wrap border p-2 m-2">
                <div className="col-12 col-lg-4 px-2 py-1">
                    <Label htmlFor="name">Nome: </Label>
                    <Label className="fw-bold mx-2">{data?.name}</Label>
                </div>
                <div className="col-12 col-lg-4 px-2 py-1">
                    <Label htmlFor="group_id">Documento: </Label>
                    <Label className="fw-bold mx-2">{data?.document}</Label>
                </div>
                <div className="col-12 col-lg-4 px-2 py-1">
                    <Label htmlFor="group_id">Telefone: </Label>
                    <Label className="fw-bold mx-2">{data?.phone}</Label>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 px-2 py-1">
                    <Label htmlFor="group_id">Data: </Label>
                    <Label className="fw-bold mx-2">{data?.date}</Label>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 px-2 py-1">
                    <Label htmlFor="group_id">Hora: </Label>
                    <Label className="fw-bold mx-2">{data?.hour}</Label>
                </div>
            </div>
            <Row className="col-12">
                <Col className="mb-3 col-12 col-sm-6 text-center">
                    <Button type="button" className="col-12 mx-2" color="danger" disabled={isSubmitting} onClick={closeModal}>
                        CANCELAR
                    </Button>
                </Col>
                <Col className="mb-3 col-12 col-sm-6 text-center">
                    <Button type="submit" className="col-12 mx-2" color="success" disabled={isSubmitting} onClick={handleConfirmSchedule}>
                        CONFIRMAR
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}

export default ScheduleConfirmation;
