import React, { useContext } from 'react';
import { Product } from '@app/components/Products';
import ProductDisplayContext from '../ProductDisplayContext';

import './CourseDisplay.scss';
import CourseItems from './CourseItems';

function CourseDisplay({ productClassName = '', className = '', ...props }) {
    const { products } = useContext(ProductDisplayContext);

    if (!products) return null;
    return (products || []).map((product, id) => {
        const show = !product?.user_has || (product?.user_has && !product?.extras?.hide_after_buy);
        return (
            show && (
                <div {...props} key={id} className={`product-display course-display d-flex flex-wrap justify-content-center ${className}`}>
                    <div className={`course-data col-12 justify-content-center ${productClassName}`}>
                        <Product className="h-100" data={product} />
                    </div>
                    <CourseItems key={`product-items-${id}`} courseId={product.token} />
                </div>
            )
        );
    });
}
export default CourseDisplay;
