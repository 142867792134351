import axios from 'axios';
import { URLs } from '@app/constants';
import toast from 'react-hot-toast';

const Api = axios.create({
    baseURL: URLs.base(),
});

Api.interceptors.request.use(
    (config) => {
        const newConfig = config;
        const user_data = JSON.parse(localStorage.getItem('user-data'));
        const token = `Bearer ${user_data?.access_token}` || newConfig.headers?.Authorization;
        newConfig.headers.Authorization = token;
        newConfig.headers['Content-Type'] = 'application/json';

        return newConfig;
    },
    (error) =>
        // Do something with request error
        Promise.reject(error)
);

async function refreshToken(error) {
    const retrying = localStorage.getItem('retrying');
    if (retrying) return;

    localStorage.setItem('retrying', true);
    return new Promise((resolve, reject) => {
        try {
            Api.post(URLs.auth.refresh)
                .then(async (resp) => {
                    const { data } = resp;
                    if (data) {
                        const expiresIn = (data.expires_in - 5) * 60000;
                        const refreshTokenAfter = new Date().getTime() + expiresIn;

                        localStorage.setItem('user-data', JSON.stringify(data));
                        localStorage.setItem('refresh', refreshTokenAfter);
                        localStorage.removeItem('retrying');
                    } else {
                        localStorage.removeItem('user-data');
                        localStorage.removeItem('refresh');
                        toast.error('Sua sessão expirou!');
                        window.location = '/login';
                    }
                    return resolve(resp);
                })
                .catch(() => {
                    localStorage.removeItem('user-data');
                    localStorage.removeItem('refresh');
                    toast.error('Sua sessão expirou!');
                    window.location = '/login';
                    return reject(error);
                });
        } catch (err) {
            reject(err);
        }
    });
}

Api.interceptors.response.use(
    (response) => {
        if (response.headers && response.headers.access_token) {
            const { access_token } = response.headers;

            // Atualizando o token automaticamente caso exista
            const user_data = JSON.parse(localStorage.getItem('user-data'));
            const token = user_data?.access_token || '';
            if (access_token !== token) {
                // Salvando novo token no local storage
                user_data.access_token = access_token;
                localStorage.setItem('user-data', JSON.stringify(user_data));
            }
        }
        // Atualizando o token após determinado tempo
        const refreshAfter = localStorage.getItem('refresh');
        if (refreshAfter !== null) {
            const time = new Date().getTime();
            if (time > refreshAfter) refreshToken();
        }
        return response;
    },
    async (error) => {
        const user_data = JSON.parse(localStorage.getItem('user-data'));
        // Access Token was expired
        if (error.response.status === 401 && (user_data?.access_token || null) !== null) {
            const response = await refreshToken(error);
            return response;
        }
        return Promise.reject(error);
    }
);

const useRefreshToken = () => ({
    execute: refreshToken,
});

export default Api;
export { useRefreshToken };
