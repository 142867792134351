import React, { useMemo } from 'react';

import { faMobileAlt, faTabletAlt, faLaptop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SizeSelector({ size, onChangeColumns, contentClass }) {
    const icon = useMemo(() => {
        if (size === 'sm') return faMobileAlt;
        if (size === 'md') return faTabletAlt;
        return faLaptop;
    }, [size]);

    const title = useMemo(() => {
        if (size === 'sm') return 'pequenos';
        if (size === 'md') return 'médios';
        return 'grandes';
    }, [size]);

    let [defaultValue] = contentClass.split(' ').filter((item) => item.indexOf(`-${size}-`) !== -1);
    defaultValue = (defaultValue || '').replaceAll('col-', '');

    return (
        <div className="size-selector d-flex align-items-center p-1" title={`Colunas em dispositivos ${title}`}>
            <FontAwesomeIcon icon={icon} className="mx-1" />
            <div className="sizes-container d-flex">
                <span
                    role="button"
                    tabIndex={0}
                    key={0}
                    title={`Remover formatação para dispositivos ${title}`}
                    value=""
                    onClick={() => onChangeColumns('', size)}
                >
                    X
                </span>
                {Array(12)
                    .fill(0)
                    .map((_, index) => {
                        const value = `${size}-${index + 1}`;
                        return (
                            <span
                                role="button"
                                tabIndex={0}
                                key={index + 1}
                                title={`Ocupar ${index + 1} de 12 colunas`}
                                className={defaultValue === value ? 'selected' : ''}
                                value={value}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onChangeColumns(value, size);
                                }}
                            >
                                {index + 1}
                            </span>
                        );
                    })}
            </div>
        </div>
    );
}

export default SizeSelector;
