import React, { useState, useEffect, useCallback } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';

import { toastConfirmation } from '@app/components/ui';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext } from 'react-beautiful-dnd';
import OrderSimulationPopup from './popups/OrderSimulationPopup';
import NewRulePopup from './popups/NewRulePopup';
import TaxationContext from './components/TaxationContext';
import TaxationRule from './components/TaxationRule';

function Taxation() {
    const replaceParams = useReplaceParams;
    /**
     * Consulta dados de regras de taxação
     */
    const [rules, setRules] = useState([]);
    const loadTaxationData = useCallback(() => {
        Api({ method: 'get', url: URLs.admin.taxation.list })
            .then(({ data }) => {
                setRules(data);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao obter as regras de tarifas');
            });
    }, []);
    const updateRequest = useCallback(() => loadTaxationData(), [loadTaxationData]);

    useEffect(() => {
        loadTaxationData();
    }, [loadTaxationData]);

    /**
     * Popup de cadastro/alteração de regras
     */
    const [newRulePopupConfig, setNewRulePopupConfig] = useState({
        isOpen: false,
        parent: { id: null, description: '' },
        rule: {},
    });
    const onAddNewRule = (parent_id, parent_description) =>
        setNewRulePopupConfig({ isOpen: true, parent: { id: parent_id, description: parent_description }, rule: {} });
    const onChangeRule = (rule, parent_id, parent_description) =>
        setNewRulePopupConfig({ isOpen: true, parent: { id: parent_id, description: parent_description }, rule });
    const onAddNewRuleForSuppliers = () => setNewRulePopupConfig({ isOpen: true, parent: { id: null, description: '' }, rule: {} });

    // Exclui uma regra
    const onDeleteRule = (id, description) => {
        toastConfirmation(
            () => {
                Api({ method: 'delete', url: replaceParams(URLs.admin.taxation.delete, { id }) })
                    .then(({ data, status }) => {
                        if (status === 200) {
                            toast.success(data?.message || 'Regra excluída com sucesso');
                            updateRequest();
                        } else {
                            toast.error(data?.message || 'Houve um erro ao tentar excluir a regra');
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response?.data?.message || 'Erro inesperado ao tentar excluir a regra');
                    });
            },
            <span>Tem certeza que deseja excluir a regra {description}?</span>
        );
    };
    const onCloseNewRulePopup = () => setNewRulePopupConfig({ isOpen: false, parent: {}, rule: {} });

    const onChangeRuleOrderDragEnd = useCallback(
        (result) => {
            if (!result.destination) return;

            const url = replaceParams(URLs.admin.taxation.reorder, { id: result.draggableId });
            Api({ method: 'put', url, data: { from: result.source.index, to: result.destination.index } })
                .then((response) => {
                    if (!response.data || response.status !== 200) {
                        toast.error(response?.data?.message || 'Erro ao alterar a ordem da regra.');
                    } else {
                        updateRequest();
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Houve um erro ao tentar alterar a ordem da regra!');
                });

            // context.handleSectionOrderChange(result.draggableId, result.source.index, result.destination.index);
        },
        [replaceParams, updateRequest]
    );

    /**
     * Popup de simulação de pedido
     */
    const [orderSimulationPopupConfig, setOrderSimulationPopupConfig] = useState({
        isOpen: false,
    });
    const onCloseOrderSimulationPopup = () => setOrderSimulationPopupConfig({ isOpen: false });
    const onOpenOrderSimulationPopup = () => setOrderSimulationPopupConfig({ isOpen: true });

    return (
        <ContentWrapper>
            <div className="page-content">
                <TaxationContext.Provider
                    value={{
                        onAddNewRule,
                        onDeleteRule,
                        onChangeRule,
                        onCloseNewRulePopup,
                        onCloseOrderSimulationPopup,
                        updateRequest,
                    }}
                >
                    <div className="d-flex justify-content-between col-12 mb-2">
                        <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={onAddNewRuleForSuppliers}>
                            <FontAwesomeIcon icon={faPlus} />
                            <span className="mx-2">Nova regra para fornecedores</span>
                        </Button>
                        <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={onOpenOrderSimulationPopup}>
                            <FontAwesomeIcon icon={faPlus} />
                            <span className="mx-2">Simulador de pedidos</span>
                        </Button>
                    </div>
                    <DragDropContext onDragEnd={onChangeRuleOrderDragEnd}>
                        {rules.map((rule, index) => (
                            <TaxationRule key={index} rule={rule} />
                        ))}
                    </DragDropContext>

                    <NewRulePopup {...newRulePopupConfig} />
                    <OrderSimulationPopup {...orderSimulationPopupConfig} />
                </TaxationContext.Provider>
            </div>
        </ContentWrapper>
    );
}

export default Taxation;
