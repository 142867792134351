import React, { useContext, useState } from 'react';

import { useFormikContext } from 'formik';
import PlanDisplay from '@app/components/Plans/PlanDisplay';

import ContentEditorContext from '../ContentEditorContext';
import PlanDisplayEditorToolbar from './components/PlanDisplayEditorToolbar';
import PlanDisplayEditorContext from './PlanDisplayEditorContext';
import PlanDisplayEditorPlanRenderer from './components/PlanRenderer/PlanDisplayEditorPlanRenderer';
import SelectPlansPopup from './popups/SelectPlansPopup';

export default function PlanDisplayEditor({ content }) {
    const { isChanging, onApplyChanges, onDeleteContent } = useContext(ContentEditorContext);
    const { setValues } = useFormikContext();

    const [selectPlansPopupConfig, setSelectPlansPopupConfig] = useState({ isOpen: false });
    const openSelectPlansPopup = () => {
        setSelectPlansPopupConfig((prev) => ({
            ...prev,
            ...{
                isOpen: true,
            },
        }));
    };
    const closeSelectPlansPopup = () => {
        setSelectPlansPopupConfig((prev) => ({ ...prev, isOpen: false }));
    };
    const onApplyPlansSelection = (plans) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => {
                if (item.id === content.id) {
                    return {
                        ...item,
                        filter: {
                            ...item.filter,
                            plans,
                        },
                    };
                }
                return item;
            }),
        }));
        closeSelectPlansPopup();
    };
    const onDeletePlan = (planId) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => {
                if (item.id === content.id) {
                    return {
                        ...item,
                        filter: {
                            ...item.filter,
                            plans: (item.filter?.plans || []).filter((plan) => plan.id !== planId),
                        },
                    };
                }
                return item;
            }),
        }));
        closeSelectPlansPopup();
    };

    const onApplyPlanChanges = (plan) => {
        const newPlan = plan;
        newPlan.id = newPlan.id === null ? new Date().getTime() : newPlan.id;
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => {
                if (item.id === content.id) {
                    const newSet = JSON.parse(JSON.stringify(item.filter?.plans || []));
                    const planIndex = newSet.findIndex((item) => item.id === newPlan.id);
                    if (planIndex >= 0) newSet.splice(planIndex, 1);
                    newSet.splice(newPlan.order, 0, newPlan);
                    return {
                        ...item,
                        filter: {
                            ...item.filter,
                            plans: newSet.map((item, index) => ({ ...item, order: index })),
                        },
                    };
                }
                return item;
            }),
        }));
    };

    return (
        <PlanDisplayEditorContext.Provider
            value={{
                content,
                isChanging,
                openSelectPlansPopup,
                closeSelectPlansPopup,
                onApplyPlansSelection,
                onApplyPlanChanges,
                onDeletePlan,
            }}
        >
            {isChanging === true && content && (
                <>
                    <PlanDisplayEditorToolbar content={content}>
                        <div className="col-12 my-1 d-flex">
                            <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                                <button type="button" className="btn btn-success col-10" onClick={onApplyChanges}>
                                    APLICAR
                                </button>
                            </div>
                            <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                                <button type="button" className="btn btn-danger col-10" onClick={() => onDeleteContent(content.id)}>
                                    EXCLUIR
                                </button>
                            </div>
                        </div>
                    </PlanDisplayEditorToolbar>
                    <SelectPlansPopup {...selectPlansPopupConfig} />
                </>
            )}
            <PlanDisplay
                type={content.displayType}
                filter={content.filter}
                Renderer={PlanDisplayEditorPlanRenderer}
                className={content.className}
                productClassName=""
                style={{ width: '100%' }}
                blockControls
            />
        </PlanDisplayEditorContext.Provider>
    );
}
