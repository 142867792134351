const useAddressValidator = (Yup) =>
    Yup.object().shape({
        address: Yup.string().min(5, 'O endereço deve conter ao menos 5 caracteres').required('É necessário informar um endereço.'),
        number: Yup.string().required('É necessário informar o número.'),
        zip: Yup.string()
            .min(8, 'O CEP deve conter ao menos 8 caracteres')
            .cep('Formato de CEP inválido')
            .required('É necessário informar o CEP.'),
        neighborhood: Yup.string().min(5, 'O bairro deve conter ao menos 5 caracteres').required('É necessário informar o bairro.'),
        state: Yup.string().length(2, 'É necessário selecionar um estado').required('É necessário selecionar um estado.'),
        city_id: Yup.string().required('É necessário selecionar uma cidade.'),
    });
export default useAddressValidator;
