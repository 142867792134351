export function useCastFieldValueByType(type, value) {
    switch (type) {
        case 'separator':
            return undefined;
        case 'html':
            return String(value);
        case 'mixed':
            return value;
        case 'file':
            return value;
        case 'string':
            return String(value);
        case 'masked':
            return String(value);
        case 'states':
            return String(value);
        case 'dropdown':
            return String(value);
        case 'cities':
            return String(value);
        case 'number':
            return parseFloat(value);
        case 'integer':
            return parseInt(value);
        case 'float':
            return parseFloat(value);
        case 'boolean':
            return parseInt(value);
        case 'date':
            return String(value);
        case 'array':
            return value;
        case 'group':
            return value;
        case 'object':
            return value;
        default:
            return value;
    }
}

export function useFieldDefaultValueByType(type) {
    const castFieldValueByType = useCastFieldValueByType;
    switch (type) {
        case 'separator':
            return undefined;
        case 'html':
            return castFieldValueByType(type, '');
        case 'mixed':
            return castFieldValueByType(type, null);
        case 'file':
            return castFieldValueByType(type, {});
        case 'string':
            return castFieldValueByType(type, '');
        case 'masked':
            return castFieldValueByType(type, '');
        case 'states':
            return castFieldValueByType(type, '');
        case 'dropdown':
            return castFieldValueByType(type, '');
        case 'cities':
            return castFieldValueByType(type, '');
        case 'number':
            return castFieldValueByType(type, 0);
        case 'integer':
            return castFieldValueByType(type, 0);
        case 'float':
            return castFieldValueByType(type, 0.0);
        case 'boolean':
            return castFieldValueByType(type, 0);
        case 'date':
            return castFieldValueByType(type, '');
        case 'array':
            return castFieldValueByType(type, []);
        case 'group':
            return castFieldValueByType(type, []);
        case 'object':
            return castFieldValueByType(type, {});
        default:
            return '';
    }
}
export default function useFormDisplayInitialValues(fields) {
    const fieldDefaultValueByType = useFieldDefaultValueByType;
    const castFieldValueByType = useCastFieldValueByType;

    const response = {};
    fields.forEach((field) => {
        response[field.name] =
            (field?.default || null) !== null ? castFieldValueByType(field.type, field.default) : fieldDefaultValueByType(field?.type);
    });
    return response;
}
