import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';

function SalesTable(props) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <Tr handleOrderBy={props?.handleOrderBy || null}>
                    <Th param="status">Status</Th>
                    <Th param="purchase_date">Dt. Compra</Th>
                    <Th param="confirmation_date">Dt. Pgto.</Th>
                    <Th param="amount">Valor (R$)</Th>
                    <Th param="payment_method">Método de Pgto.</Th>
                    <Th param="purchase_type">Tipo de Compra</Th>
                    <Th param="has_shipping">Postagem</Th>
                    <th className="text-center">&nbsp;</th>
                </Tr>
            </thead>
            <tbody>
                {props?.data?.length > 0 ? (
                    props?.data.map((record, id) => (
                        <tr key={id}>
                            <td>
                                <a href={`/admin/vendas/${record?.token || ''}`} className="text-primary" title="ver detalhes da compra">
                                    {record?.status_label || ''}
                                </a>
                            </td>
                            <td>{record?.purchase_date || ''}</td>
                            <td>{record?.confirmation_date || ''}</td>
                            <td>{(record?.amount || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                            <td>{record?.method_label || ''}</td>
                            <td>{record?.type_label || ''}</td>
                            <td style={{ verticalAlign: 'middle' }}>{record.has_shipping === 1 ? 'SIM' : 'NÃO'}</td>
                            <td align="center">
                                <a href={`/admin/vendas/${record?.token || ''}`} className="text-primary" title="ver detalhes da compra">
                                    <FontAwesomeIcon icon={faEye} className="mr-2" />
                                </a>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={8}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default SalesTable;
