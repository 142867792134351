import React, { useMemo } from 'react';
import { Label } from 'reactstrap';
import { CustomSwitch } from '@app/components/ui';

export default function BooleanField({
    field,
    formikProps: {
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        disabled = false,
        hidden = false,
        required = false,
        label = '',
    },
}) {
    const checked = useMemo(() => values?.[field.name] || false, [field, values]);

    return (
        <div className={`d-flex flex-wrap p-1 ${field.style} ${hidden ? 'd-none' : ''}`} key={field.name}>
            <div className="d-flex col-12 align-items-center">
                <CustomSwitch
                    name={`field-${field.name}`}
                    className="justify-content-center"
                    onChange={(value) => {
                        setFieldTouched(field.name);
                        setFieldValue(field.name, value ? 1 : 0, true);
                    }}
                    checked={checked}
                    isAsync={false}
                    data={{ status: checked }}
                    disabled={disabled || isSubmitting}
                />
                <Label className="field-label pl-1" htmlFor={`field-${field.name}`}>
                    {required ? <em>*</em> : ''}
                    {label}
                </Label>
            </div>
            {errors?.[field.name] && <span className="col-12 text-error text-small px-2">{errors?.[field.name]}</span>}
        </div>
    );
}
