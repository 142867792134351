import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PopupStyle, URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import ReCAPTCHA from 'react-google-recaptcha';
import toast from 'react-hot-toast';
import { Button, Form } from 'reactstrap';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import Modal from 'react-modal';
import * as yup from 'yup';

import FormikDevTools from '@app/components/FormikDevTools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Field, Formik } from 'formik';

import './ChangeSubscriptionCardPopup.scss';

export default function ChangeSubscriptionCardPopup({
    closeModal = () => {},
    updateRequest = () => {},
    modalIsOpen = false,
    currentCardId = null,
    token = null,
    description = '',
}) {
    const replaceParams = useReplaceParams;
    const [isSubmitting, setSubmitting] = useState(false);

    const captchaRef = useRef(null);
    const handleCaptchaChange = (token, setFieldValue) => {
        setFieldValue('captcha_token', token);
    };
    const resetCaptchaToken = () => {
        captchaRef.current.reset();
    };

    const afterOpenModal = () => {};

    // Carrega os dados do produto
    const [cards, setCards] = useState([]);
    const loadCardsData = useCallback(() => {
        const url = replaceParams(URLs.services.account.cards.list, {
            nocache: new Date().getTime(),
            card_type: 'credit_card,debit_card',
        });
        Api({ method: 'get', url })
            .then((response) => {
                if (response.data && response.status === 200) {
                    setCards(response.data);
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch((err) => {
                toast.error(err?.data?.message || 'Erro inesperado ao obter os dados do usuário');
                setSubmitting(false);
            });
    }, [replaceParams]);

    useEffect(() => {
        loadCardsData();
    }, [loadCardsData]);

    const handleFormSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        const url = replaceParams(URLs.club.subscriptions.subscriptions.change_card, {
            token: values.token,
        });
        const data = {
            user_card_id: values.user_card_id,
            captcha_token: values.captcha_token,
        };
        Api({ method: 'post', url, data })
            .then(({ data, status }) => {
                if (data && status === 200) {
                    toast.success(data?.message || 'Assinatura alterada com sucesso');
                    updateRequest(token);
                    resetCaptchaToken();
                    closeModal();
                } else {
                    toast.error(data?.message || 'Houve um erro ao alterar o cartão da assinatura');
                    resetCaptchaToken();
                }
                setSubmitting(false);
            })
            .catch((err) => {
                toast.error(err?.data?.message || 'Erro inesperado ao alterar o cartão da assinatura');
                setSubmitting(false);
            });
    };

    const validations = yup.object().shape({
        user_card_id: yup.string().when('payment_method', {
            is: 'credit_card' || 'debit_card',
            then: yup.string().required('Selecione um meio de pagamento'),
        }),
        captcha_token: yup.string().required('Clique na cauxa de seleção "Não sou um robô"'),
    });

    const initialValues = {
        token: token || '',
        user_card_id: '',
        captcha_token: '',
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{ ...PopupStyle, ...{ content: { ...PopupStyle.content, ...{ maxWidth: '800px' } } } }}
            contentLabel="ALTERAR CARTÃO DE PAGAMENTO"
            className="change-subscription-card-popup"
            ariaHideApp={false}
        >
            <div className="col-12 mb-2 d-flex flex-wrap align-items-center border-bottom p-3">
                <h2 className="flex-grow-1 text-center text-large my-0">
                    ALTERAR CARTÃO DE PAGAMENTO <strong>{description || ''}</strong>
                </h2>
                <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={closeModal}>
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                </Button>
            </div>
            <div className="col-12 d-flex p-3">
                <div className="col-12">
                    <p>
                        Selecione o cartão que deseja utilizar para o pagamento desta assinatura e depois clique em &quot;APLICAR&quot; para
                        prosseguir com a alteração.
                    </p>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validations}
                        onSubmit={handleFormSubmit}
                        enableReinitialize
                        render={(formikProps) => (
                            <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                                <div className="cards-list col-12 d-flex">
                                    {cards.map((card) => (
                                        <div className="card d-flex col-12 px-2 py-1 mb-1">
                                            <label
                                                htmlFor={`user-card-${card.id}`}
                                                className="d-flex col-12 align-items-center justify-content-center"
                                            >
                                                <span className="col-11 d-flex flex-wrap flex-shrink-1 text-medium">
                                                    {card.description}
                                                    {!card?.gateway_id && (
                                                        <span className="col-12 text-small text-error">
                                                            Cartão não sincronizado com o gateway de pagamento
                                                        </span>
                                                    )}
                                                </span>
                                                {card.id === currentCardId ? (
                                                    <span className="col-1 text-small text-center">ATUAL</span>
                                                ) : (
                                                    <span className="col-1 text-center">
                                                        <Field
                                                            type="radio"
                                                            id={`user-card-${card.id}`}
                                                            name="user_card_id"
                                                            value={String(card.id)}
                                                            disabled={isSubmitting || formikProps.isSubmitting || !card?.gateway_id}
                                                        />
                                                    </span>
                                                )}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <p className="text-medium p-1">
                                    O que cartão que você deseja utilizar não está na lista?{' '}
                                    <a href="/conta/cartoes" title="Ir para meus cartões">
                                        Clique aqui
                                    </a>{' '}
                                    para cadastrá-lo.
                                </p>
                                <div className="col-12 justify-content-center d-flex">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        ref={captchaRef}
                                        onChange={(token) => handleCaptchaChange(token, formikProps.setFieldValue)}
                                    />
                                </div>
                                <div className="d-flex col-12 py-2 mt-1">
                                    <div className="mb-1 px-1 col-6 text-center">
                                        <Button type="submit" className="col-12" color="danger" disabled={false} onClick={closeModal}>
                                            CANCELAR
                                        </Button>
                                    </div>
                                    <div className="mb-1 px-1 col-6 text-center">
                                        <Button
                                            type="submit"
                                            className="col-12"
                                            color="success"
                                            disabled={
                                                isSubmitting ||
                                                formikProps.isSubmitting ||
                                                formikProps.values.captcha_token === '' ||
                                                formikProps.values.captcha_token === null
                                            }
                                        >
                                            APLICAR {formikProps.isSubmitting && <LoadingSpinner />}
                                        </Button>
                                    </div>
                                </div>
                                <FormikDevTools />
                            </Form>
                        )}
                    />
                </div>
            </div>
        </Modal>
    );
}
