import React, { useState, useEffect } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import { URLs, useReplaceParams } from '@app/constants';

import Paginator from '@app/components/Paginator/Paginator';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import { useFetch } from '@app/hooks/useFetch';
import FileDownloader from '@app/components/FileDownloader/FileDownloader';
import CheckPermission from '@app/components/CheckPermission';
import { useUserManager } from '@app/hooks/useUserManager';
import Notification from './components/Notification';

function Notifications() {
    const replaceParams = useReplaceParams;
    const UserManager = useUserManager();

    const [params, setParams] = useState({
        status: 'all',
        nocache: new Date().getTime(),
        pg: 1,
        orderBy: '',
        orderDir: 'asc',
    });
    const { data, error, last_page } = useFetch(replaceParams(URLs.services.notifications.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
        UserManager.updateSummary();
    }, [data, error, UserManager]);

    /**
     * Configuração de navegação
     */
    const handleNavigateFirst = () => setParams({ ...params, ...{ pg: 1 } });
    const handleNavigateLast = () => setParams({ ...params, ...{ pg: last_page } });
    const handleNavigateNext = () => setParams({ ...params, ...{ pg: data?.length > 0 ? params.pg + 1 : params.pg } });
    const handleNavigatePrevious = () => setParams({ ...params, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } });

    return (
        <ContentWrapper className="notications">
            <h2 className="text-center col-12 bg-primary br-4 p-1 text-white text-large mt-1">NOTIFICAÇÕES</h2>
            <FilterContainer title="">
                <FilterFields className="col-4 d-flex">
                    <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                </FilterFields>
            </FilterContainer>
            <div className="page-content">
                <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                    <CheckPermission permission="export">
                        <div className="d-flex mb-1">
                            <FileDownloader
                                url={replaceParams(URLs.admin.sales.export, { ...params, ...{ ext: 'csv' } })}
                                filename="vendas"
                                label="Exportar CSV"
                                extension="csv"
                                className=""
                            />
                        </div>
                    </CheckPermission>
                </div>
                <div className="notifications-container d-flex flex-column p-1">
                    {(data || []).length > 0 ? (
                        data.map((record, id) => <Notification data={record} key={id} />)
                    ) : (
                        <h3 className="text-center">Não existem notificações para serem exibidas</h3>
                    )}
                </div>
                <Paginator
                    pg={params.pg}
                    last_page={last_page}
                    hasPrevious={params.pg > 1}
                    hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                    handleNavigateFirst={handleNavigateFirst}
                    handleNavigateNext={handleNavigateNext}
                    handleNavigatePrevious={handleNavigatePrevious}
                    handleNavigateLast={handleNavigateLast}
                />
            </div>
        </ContentWrapper>
    );
}

export default Notifications;
