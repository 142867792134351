export default {
    '#59268f': { bg: '#bea8d6', dark: '#401d6d', text: '#0a3e5f', light: '#59268f', title: '#ffffff' },
    '#a35bb8': { bg: '#b977c925', dark: '#5d316f', text: '#5d316f', light: '#a35bb8', title: '#ffffff' },
    '#453db4': { bg: '#92a7d935', dark: '#20186a', text: '#0a3e5f', light: '#453db4', title: '#ffffff' },
    '#0071b7': { bg: '#94b4d95e', dark: '#00378b', text: '#0a3e5f', light: '#0071b7', title: '#ffffff' },
    '#05a782': { bg: '#7ac2a648', dark: '#007257', text: '#0a3e5f', light: '#05a782', title: '#ffffff' },
    '#69d959': { bg: '#b2eab540', dark: '#348136', text: '#0a3e5f', light: '#69d959', title: '#ffffff' },
    '#f2ce00': { bg: '#fbe79e5e', dark: '#b58a00', text: '#0a3e5f', light: '#f2ce00', title: '#ffffff' },
    '#fd7c17': { bg: '#fbc48c40', dark: '#b85103', text: '#0a3e5f', light: '#fd7c17', title: '#ffffff' },
    '#fd3d26': { bg: '#fc8b7240', dark: '#b81e05', text: '#0a3e5f', light: '#fd3d26', title: '#ffffff' },
    '#c90132': { bg: '#f28e8b40', dark: '#890007', text: '#0a3e5f', light: '#c90132', title: '#ffffff' },
    '#a60030': { bg: '#da6c6b40', dark: '#7a0a09', text: '#0a3e5f', light: '#a60030', title: '#ffffff' },
    '#d0b08d': { bg: '#ebd2b08c', dark: '#7e634a', text: '#0a3e5f', light: '#d0b08d', title: '#ffffff' },
};
