import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';

import { SectionContext } from '@app/contexts';

import { Button } from 'reactstrap';

import { usePurchaseManager, useCartManager } from '@app/hooks/';
import DRCAContext from '@app/contexts/DRCAContext';

import { faCartPlus, faShoppingBag, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import './PaymentControls.scss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ProductContext from '../ProductContext';

function PaymentControls({
    product = {
        id: null,
        cart: null,
        price: 0,
        old_price: 0,
        max_offering: null,
        free_offer: false,
        stock: null,
        extras: {},
    },
}) {
    const DRCA = useContext(DRCAContext);
    const history = useHistory();
    const context = useContext(SectionContext);
    const { blockControls, updateRequest } = useContext(ProductContext) || false;

    const { cart } = useSelector((state) => state.summary);

    const PurchaseManager = usePurchaseManager();
    const CartManager = useCartManager();

    const [priceWhole, setPriceWhole] = useState(0); // priceArr[0];
    const [priceFraction, setPriceFraction] = useState(0); // = priceArr[1];
    const [oldPrice, setOldPrice] = useState(null);
    const [qtd, setQtd] = useState(product?.cart?.qtd || 1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const priceArr = (product?.price || 0.0).toLocaleString('pt-br', { minimumFractionDigits: 2 }).split(',');
        setQtd(1);
        setPriceWhole(priceArr[0]);
        setPriceFraction(priceArr[1]);
        setOldPrice(product?.old_price ? (product?.old_price || 0.0).toLocaleString('pt-br', { minimumFractionDigits: 2 }) : null);
    }, [product]);

    /**
     * stock==null && mo!=null => mo=mo
     * stock==null && mo==null => mo=null
     * stock!=null && mo==null => mo=stock
     * stock!=null && mo!=null => mo=min(stock,mo)
     */
    const stock = useMemo(() => {
        const val = parseInt(product.stock);
        return Number(val).isNaN ? null : val;
    }, [product]);

    const maxOffering = useMemo(() => {
        const mo = parseInt(product.max_offering) || null;

        if (stock !== null && mo !== null) return Math.min(stock, mo);
        const response = stock || mo || null;
        return response;
    }, [stock, product]);

    const showQtdSelector = useMemo(() => maxOffering === null || maxOffering > 1, [maxOffering]);

    /**
     * Adiciona produto ao carrinho de compras
     */
    const addToCart = useCallback(() => {
        if (blockControls === true) return;
        DRCA.action('click', {
            component: 'BUTTON',
            product_id: product?.id || '',
            product_name: product?.name || '',
            title: String('Adicionar produto ao carrinho'),
            id: 'add-to-cart',
        });

        CartManager.addToCart([
            {
                id: product?.id,
                qtd,
            },
        ])
            .then((resp) => {
                const { data } = resp;
                if (data && resp.status === 200) {
                    toast.success(data?.message || 'Produto adicionado ao carrinho!');
                    updateRequest();
                } else {
                    toast.error(data?.message || 'Erro ao adicionar produto ao carrinho!');
                }
            })
            .catch((err) => toast.error(err?.response?.data || 'Erro ao adicionar produto ao carrinho!'));
    }, [product, qtd, updateRequest, CartManager, DRCA, blockControls]);

    /**
     * Inicia uma compra direta criando um novo pedido
     */
    const directPurchase = () => {
        if (blockControls === true) return;
        setIsLoading(true);
        PurchaseManager.createPurchase([{ token: product?.id, qtd }])
            .then((response) => {
                const { status, message } = response;
                if (status === 200) {
                    history.push({
                        pathname: `/checkout/${response?.purchase_id}`,
                        state: {
                            redirect_to: context?.pathname,
                        },
                    });
                } else {
                    toast.error(message || 'Erro ao iniciar o método de pagamento!');
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err?.response?.message || 'Erro inesperado ao iniciar o método de pagamento!');
            });
    };

    const setNewQtd = (value) => {
        // if (blockControls===true) return;
        // const mo = maxOffering || 1;
        let newValue = value;
        newValue = Math.max(1, newValue);
        newValue = maxOffering !== null ? Math.min(newValue, maxOffering) : newValue;
        setQtd(newValue);
    };
    const handleQtdChange = (e) => {
        setNewQtd(parseInt(e.target.value) || 0);
    };
    const handleIncrementQtd = () => {
        setNewQtd(qtd + 1);
    };
    const handleDecrementQtd = () => {
        setNewQtd(qtd - 1);
    };

    const CartButton = useMemo(() => {
        const isInCart = (cart?.products || []).findIndex((token) => token === product.id) >= 0;
        if ((!product.free_offer && !isInCart) || (maxOffering || 1) > 1)
            return (
                <Button
                    type="button"
                    title="Adicionar este item ao carrinho"
                    className="btn btn-payment btn-add-to-cart btn-icon-left btn-width-flex col-12"
                    color="custom"
                    onClick={addToCart}
                    disabled={maxOffering === 0 || isLoading}
                >
                    {!isLoading ? <FontAwesomeIcon icon={faCartPlus} className="mx-2" /> : <LoadingSpinner />}
                    <span>Adicionar ao carrinho</span>
                </Button>
            );
        return (
            <Link
                to="/carrinho"
                title="Ver meu carrinho de compras"
                className="btn btn-payment btn-red btn-icon-left col-12"
                disabled={maxOffering === 0 || isLoading}
            >
                {!isLoading ? <FontAwesomeIcon icon={faShoppingCart} className="mx-2" /> : <LoadingSpinner />}
            </Link>
        );
    }, [product, cart, addToCart, isLoading, maxOffering]);

    return (
        <div className="payment-controls">
            <div className="d-flex product-price_container">
                <div className="d-flex align-items-start product-price">
                    <span className="price-symbol">R$</span>
                    <span className="price-whole fw-bold">{priceWhole}</span>
                    <span className="price-fraction">{priceFraction}</span>
                </div>
                {oldPrice && (
                    <div className="d-flex align-items-start product-price strikethrough">
                        <span className="price-whole fw-bold">R$ {oldPrice}</span>
                    </div>
                )}
            </div>
            <div className={`payment-controls_qtd-selector max-offering-${product?.max_offering} d-flex justify-content-center`}>
                {maxOffering === 0 ? (
                    <label className="badge badge-secondary" title="Este produto está indisponível no momento">
                        Indisponível
                    </label>
                ) : (
                    showQtdSelector && (
                        <>
                            <input type="button" className="btn add-to-cart-btn-less" value="-" onClick={handleDecrementQtd} />
                            <div className="add-to-cart-input-container">
                                <input type="text" className="add-to-cart-input" value={qtd} onChange={handleQtdChange} />
                            </div>
                            <input type="button" className="btn add-to-cart-btn-plus" value="+" onClick={handleIncrementQtd} />
                        </>
                    )
                )}
            </div>

            <div className="payment-controls_buttons-container">
                {CartButton}
                {/* {(!product.free_offer && product?.cart === null) || (maxOffering || 1) > 1 ? (
                    <Button
                        type="button"
                        title="Adicionar este item ao carrinho"
                        className="btn btn-payment btn-add-to-cart btn-icon-left btn-width-flex col-12"
                        color="custom"
                        onClick={addToCart}
                        disabled={maxOffering === 0 || isLoading}
                    >
                        {!isLoading ? <FontAwesomeIcon icon={faCartPlus} className="mx-2" /> : <LoadingSpinner />}
                        <span>Adicionar ao carrinho</span>
                    </Button>
                ) : (
                    <Button
                        type="button"
                        title="Remover este item do carrinho"
                        className="btn btn-payment btn-remove-from-cart btn-icon-left col-12"
                        color="custom"
                        onClick={removeFromCart}
                        disabled={maxOffering === 0 || isLoading}
                    >
                        {!isLoading ? <FontAwesomeIcon icon={faCartArrowDown} className="mx-2" /> : <LoadingSpinner />}
                        <span>Remover do carrinho</span>
                    </Button>
                )} */}
                {!product?.free_offer && (
                    <Button
                        type="button"
                        title="Comprar este item agora mesmo"
                        className="btn btn-payment btn-buy-now btn-icon-left btn-width-flex col-12"
                        color="custom"
                        onClick={directPurchase}
                        disabled={maxOffering === 0 || isLoading}
                    >
                        {!isLoading ? <FontAwesomeIcon icon={faShoppingBag} className="mx-2" /> : <LoadingSpinner />}
                        <span>Comprar agora</span>
                    </Button>
                )}
            </div>
        </div>
    );
}
export default PaymentControls;
