import React from 'react';
import { Row, Col, Button, Form, InputGroup, Label } from 'reactstrap';
import Modal from 'react-modal';
import { ErrorMessage, Field, Formik } from 'formik';
import * as yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './DocumentReview.scss';

const customStyles = {
    overlay: {
        zIndex: 1200,
    },
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
function CancelScheduleForm(props) {
    const afterOpenModal = () => {};

    const handleFormSubmit = (values) => {
        props?.handleCancelInterviewSchedule(values.justification);
    };

    const certificateValidations = yup.object().shape({
        justification: yup
            .string()
            .min(10, 'A justificativa deve conter ao menos 10 caracteres.')
            .required('É necessário informar uma justificativa.'),
    });

    const certificateInitialValues = {
        token: props?.token || '',
        justification: '',
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={props?.modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={props?.closeModal}
            style={customStyles}
            contentLabel={props?.title}
            className=""
            ariaHideApp={false}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">{props?.title}</h2>
                    <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={props?.closeModal}>
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    </Button>
                </Col>
            </Row>
            <Formik
                initialValues={certificateInitialValues}
                validationSchema={certificateValidations}
                onSubmit={handleFormSubmit}
                render={(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <p className="col-12 text-justify">
                            Esta ação retornará o certificado ao status &quot;Aguardando agendamento da entrevista&quot;. Informe uma
                            justificativa e clique em APLICAR para prosseguir.
                        </p>
                        <InputGroup className="mb-3">
                            <Label htmlFor="name">Justificativa</Label>
                            <Field name="justification" type="text" placeholder="Justificativa" className="col-12" />
                            <ErrorMessage component="span" name="justification" className="text-error text-small" />
                        </InputGroup>
                        <Row className="col-12">
                            <Col className="mb-3 col-12 col-sm-6 text-center">
                                <Button type="submit" className="col-12 mx-2" color="primary" disabled={props?.isSubmitting}>
                                    APLICAR
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            />
        </Modal>
    );
}

export default CancelScheduleForm;
