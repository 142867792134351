import React from 'react';
import { Table } from 'reactstrap';

function ItemsTable(props) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <tr>
                    <th>Produto</th>
                    <th>Qtd.</th>
                    <th>Val. Un. (R$)</th>
                    <th>Desconto (R$)</th>
                    <th>Val. Total (R$)</th>
                    <th>Postagem</th>
                </tr>
            </thead>
            <tbody>
                {props?.data?.length > 0 ? (
                    props?.data.map((record, id) => (
                        <tr key={id}>
                            <td>{record?.product?.name || ''}</td>
                            <td>{record?.qtd || ''}</td>
                            <td>{(record?.price || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                            <td>{(record?.discount || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                            <td>{(record?.amount || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                            <td style={{ verticalAlign: 'middle' }}>{record?.product?.has_shipping === 1 ? 'SIM' : 'NÃO'}</td>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={7}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default ItemsTable;
