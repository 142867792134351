import React from 'react';
import parse from 'html-react-parser';
import FieldGroup from './FieldGroup';

function formatFieldValueByType(value, type) {
    switch (type) {
        case 'F':
            return value?.name || null;
        case 'f':
            return Number(value || 0).toLocaleString('pt-br', { minimumFractionDigits: 2 });
        case 'n':
            return Number(value || 0).toLocaleString('pt-br', { minimumFractionDigits: 2 });
        case 'b':
            return value ? 'SIM' : 'NÃO';
        case 'i':
            return Math.round(Number(value || 0));
        case 'H':
            return value;
        default:
            return value;
    }
}
export default function FieldData({ template, data }) {
    switch (template?.typ) {
        case 'G':
            return (data || []).map((record, index) => (
                <FieldGroup key={index} templates={template?.cdr || []} data={record} className={template?.stl || ''} />
            ));
        case 'T':
            return <h3 className={`${template?.stl || ''}`}>{template?.lbl || ''}</h3>;
        case 'H':
            return (
                <div className={`form-content-field d-flex flex-wrap my-2 ${template?.stl || ''}`}>
                    <label htmlFor="" className="field-value col-12">
                        {parse(data)}
                    </label>
                </div>
            );
        default: {
            const value = formatFieldValueByType(data, template?.typ);
            return (
                <div className={`form-content-field d-flex flex-wrap mb-2 ${template?.stl || ''}`}>
                    <label htmlFor="" className="field-label col-12">
                        {template?.lbl || ''}
                    </label>
                    <label htmlFor="" className="field-value col-12">
                        {value || <>&nbsp;</>}
                    </label>
                </div>
            );
        }
    }
}
