import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { ErrorMessage, Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import * as yup from 'yup';

import { useUserManager } from '@app/hooks/useUserManager';
import { clearAllMessages, selectAuth } from '@app/store/AuthSlice';

import { Container, Button, Label } from 'reactstrap';

import '@app/assets/styles/auth.scss';

import { PasswordField } from '@app/components/ui';
import { SideContent, TipMessage, AuthFooter, AuthHeader } from '../components';

function Login() {
    const { messages } = useSelector(selectAuth);
    const dispatch = useDispatch();

    const UserManager = useUserManager();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (!messages) return;
        messages.forEach(({ type, message }) => {
            switch (type) {
                case 'error':
                    toast.error(message);
                    break;
                case 'success':
                    toast.success(message);
                    break;
                default:
                    break;
            }
        });
        if (messages.length > 0) dispatch(clearAllMessages());
    }, [messages, dispatch]);

    const handleSubmit = (values) => {
        UserManager.login(values.username, values.password)
            .then((response) => {
                setErrorMessage(response?.message);
            })
            .catch(() => {
                // console.log('ERRO: ', error);
            });
    };

    const validations = yup.object().shape({
        username: yup.string().phone('Formato de telefone inválido.').required('É necessário informar um endereço de email.'),
        password: yup
            .string()
            .min(8, 'A senha deve possuir ao menos 8 caracteres.')
            .required('A senha deve possuir ao menos 8 caracteres.'),
    });

    const initialValues = {
        username: '',
        password: '',
    };

    return (
        <div className="auth-screen">
            <Container className="col-10" style={{ maxWidth: '1200px' }}>
                <div className="col-12 justify-content-center">
                    <AuthHeader />
                    <div className="auth-container col-12">
                        <div className="auth-form-container col-12 col-md-5">
                            <Formik initialValues={initialValues} validationSchema={validations} onSubmit={handleSubmit} enableReinitialize>
                                {({ values, setFieldValue, isLoading }) => (
                                    <Form>
                                        <div className="justify-content-center mb-3 col-12">
                                            <div className="col-12">
                                                <h2 className="text-center">Acesse sua conta</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex flex-column mb-3">
                                            <Label className="field-label" htmlFor="phone">
                                                Celular / WhatsApp
                                            </Label>
                                            <InputMask
                                                name="username"
                                                type="text"
                                                className="col-12"
                                                mask="(99) 99999-9999"
                                                placeholder="(__) _____-____"
                                                autoComplete="username"
                                                value={values.username}
                                                disabled={isLoading}
                                                onChange={({ target: { value } }) => setFieldValue('username', value)}
                                            />
                                            <ErrorMessage component="span" name="username" className="text-error text-small" />
                                        </div>
                                        <div className="col-12 d-flex flex-column mb-3">
                                            <Label>Senha:</Label>
                                            <PasswordField
                                                type="password"
                                                name="password"
                                                autoComplete="password"
                                                className="login-field col-12 mb-1"
                                                value={values.password}
                                                onChange={(e) => setFieldValue('password', e.target.value)}
                                                disabled={isLoading}
                                            />
                                            <ErrorMessage component="span" name="password" className="text-error text-small" />
                                        </div>
                                        {errorMessage && <p className="mb-3 message-error text-small">{errorMessage}</p>}
                                        <div className="col-12 d-flex mb-3">
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Button type="submit" className="col-12" color="primary">
                                                    ENTRAR
                                                </Button>
                                            </div>
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Link to="/recuperar" className="btn btn-primary-outline col-12">
                                                    ESQUECI A SENHA
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex mb-3">
                                            <div className="d-flex col-12 text-center px-1">
                                                <p className="col-12 text-center">
                                                    Ainda não é cadastrado?{' '}
                                                    <Link to="/cadastrar" className="text-red">
                                                        CADASTRE-SE AQUI!
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                        <TipMessage>
                                            <em>***</em> Por favor, certifique-se de estar usando o mesmo número de telefone que usou para
                                            se cadastrar.
                                        </TipMessage>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <SideContent className="col-12 col-md-7" />
                    </div>
                </div>
            </Container>
            <AuthFooter />
        </div>
    );
}

export default Login;
