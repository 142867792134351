import React, { useMemo, useState } from 'react';
import { CardsDisplay, CourseDisplay, CarouselDisplay, MatDisplay } from '@app/components/Products/ProductDisplays';
import ProductDisplayContext from '@app/components/Products/ProductDisplays/ProductDisplayContext';
import { useFormikContext } from 'formik';

function FormSliceProductPreview() {
    const { values } = useFormikContext();

    const productBeforeBuy = useMemo(() => {
        const userHas = {
            qtd: 1,
            remaining: 1,
            extras: {
                filename: 'temp',
                button_label: 'BAIXAR DOCUMENTO',
                ...values.private,
            },
        };
        return {
            cart: null,
            section_group: 1,
            user_has: values.free_offer ? userHas : null,
            ...values,
        };
    }, [values]);

    const productAfterBuy = useMemo(
        () => ({
            ...values,
            cart: null,
            section_group: 1,
            user_has: {
                qtd: 1,
                remaining: 1,
                extras: {
                    ...{
                        filename: 'temp',
                        button_label: 'BAIXAR DOCUMENTO',
                    },
                    ...values.private,
                },
            },
        }),
        [values]
    );

    const [displayType, setDisplayType] = useState('mat');
    const handleProductDisplayChange = (e) => {
        setDisplayType(e.target.value);
    };

    const display = useMemo(() => {
        switch (displayType) {
            case 'cards':
                return <CardsDisplay productClassName="" />;
            case 'carousel':
                return <CarouselDisplay productClassName="" />;
            case 'course':
                return <CourseDisplay productClassName="" />;
            default:
                return <MatDisplay productClassName="" />;
        }
    }, [displayType]);

    return (
        <div className="mb-1 col-12 px-1 d-flex flex-wrap">
            <div className="mb-1 px-1 col-12">
                <label className="field-label" htmlFor="product_type">
                    Tipo de expositor
                </label>
                <select name="product_type" className="col-12" onChange={handleProductDisplayChange}>
                    <option value="mat">Esteira</option>
                    <option value="cards">Cartões de destaque</option>
                    <option value="carousel">Carrossel de produtos</option>
                    <option value="course">Sanfona vertical</option>
                </select>
            </div>
            <div className={`${displayType === 'course' ? 'col-12' : 'col-12 col-md-6'} px-1`}>
                <h3 className="text-center col-12">Antes da compra</h3>
                <ProductDisplayContext.Provider
                    value={{
                        products: [productBeforeBuy],
                        blockControls: true,
                    }}
                >
                    {display}
                </ProductDisplayContext.Provider>
            </div>
            <div className={`${displayType === 'course' ? 'col-12' : 'col-12 col-md-6'} px-1`}>
                <h3 className="text-center col-12">Após a compra</h3>
                <ProductDisplayContext.Provider
                    value={{
                        products: [productAfterBuy],
                        blockControls: true,
                    }}
                >
                    {display}
                </ProductDisplayContext.Provider>
            </div>
        </div>
    );
}
export default FormSliceProductPreview;
