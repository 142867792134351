import React from 'react';
import ContentWrapper from '../shared/ContentWrapper/ContentWrapper';

function NotFound() {
    return (
        <ContentWrapper>
            <div className="page-content">
                <h1 className="my-3 text-center">PÁGINA NÃO ENCONTRADA</h1>
                <p className="my-3 text-center">O endereço que você está tentando acessar não foi encontrado.</p>
                <p className="my-3 text-center">Utilize o menu lateral para navegar.</p>
            </div>
        </ContentWrapper>
    );
}
export default NotFound;
