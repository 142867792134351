import React, { useCallback, useEffect, useState } from 'react';
import { useReplaceParams, URLs } from '@app/constants';
import Api from '@app/services/Api';
import { toast } from 'react-hot-toast';
import Certificate from './Certificate';

export default function Certificates({ ...props }) {
    const replaceParams = useReplaceParams;
    const [certificates, setCertificates] = useState([]);
    const loadCertificatesData = useCallback(() => {
        const certParams = {
            key: 'certificado_a1',
            nocache: new Date().getTime(),
        };
        const url = replaceParams(URLs.club.certificates.list, certParams);
        Api({ url })
            .then(({ data, status }) => {
                if (status === 200) {
                    setCertificates(data);
                } else {
                    toast.error(data?.message || 'Houve um erro ao carregar os dados dos certificados!');
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao carregar os dados dos certificados!');
            });
    }, [replaceParams]);

    useEffect(() => {
        loadCertificatesData();
    }, [loadCertificatesData]);
    // Consulta de dados de sessões

    return (
        <div {...props}>
            <div className="col-12 d-flex flex-column justify-content-start align-items-center">
                {certificates?.vouchers?.remaining ? (
                    <>
                        <label className="text-medium fw-bold">
                            {replaceParams('Você tem {qtd} certificados do tipo A1 a serem emitidos.', {
                                qtd: certificates?.vouchers?.remaining,
                            })}
                        </label>
                        <a href="certificados/novo" className="btn m-3">
                            EMITIR NOVO CERTIFICADO
                        </a>
                    </>
                ) : (
                    <div className="d-flex flex-column align-items-center">
                        <label>Você não possui certificados para emitir. Adquira novos certificados agora mesmo.</label>
                    </div>
                )}
            </div>
            <div className="col-12 my-2 d-flex flex-column justify-content-start align-items-center">
                <h3 className="border-bottom col-12 text-center">MEUS CERTIFICADOS</h3>
                {certificates?.certificates?.length ? (
                    certificates?.certificates?.map((certificate, id) => (
                        <Certificate key={id} certificate={certificate} className="mb-2" />
                    ))
                ) : (
                    <label>Você não possui nenhum certificado cadastrado.</label>
                )}
            </div>
        </div>
    );
}
