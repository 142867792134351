import React, { useState, useEffect, useMemo } from 'react';

import { ErrorMessage, Field, Formik } from 'formik';
import { Button, Form, Label } from 'reactstrap';
import InputMask from 'react-input-mask';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import * as yup from 'yup';
import { useUserManager } from '@app/hooks/useUserManager';
import { useValidators } from '@app/validators';

import { toast } from 'react-hot-toast';

import AddressFields from '@app/components/AddressFields/AddressFields';
import { PasswordField } from '@app/components/ui';
import FormikDevTools from '@app/components/FormikDevTools';
import PhoneNumberConfirmation from './components/PhoneNumberConfirmation';

export default function CustomerForm({ onSave = () => {}, requireShippingAddress = true }) {
    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    const { AddressValidator, CPFValidator, CNPJValidator } = useValidators();

    // Lista de vídeos disponíveis no VIMEO
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (userData?.id) return;
        // Carrega os dados do produto
        const loadUserData = () => {
            setIsLoading(true);
            const url = replaceParams(URLs.club.account.index, { nocache: new Date().getTime() });
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setUserData(response.data);
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch((err) => {
                    toast.error(err?.data?.message || 'Erro inesperado ao obter os dados do usuário');
                });
        };

        loadUserData();
    }, [replaceParams, token, userData]);

    // Faz o submit dos dados do usuário
    const handleUserSubmit = (values, { setSubmitting, setFieldValue, setErrors }) => {
        setSubmitting(true);
        const url = replaceParams(URLs.club.account.index, { nocache: new Date().getTime() });

        Api({ method: 'put', url, data: values })
            .then((response) => {
                setFieldValue('password_confirmation', '');
                if (response.data && response.status === 200) {
                    toast.success(response?.data?.message || 'Dados atualizados com sucesso');
                    UserManager.refresh().then(() => {
                        if (typeof onSave === 'function') onSave();
                    });
                } else {
                    toast.error(response?.data?.message || 'Houve um erro ao tentar alterar os dados do usuário');
                }
                setSubmitting(false);
            })
            .catch((error) => {
                setSubmitting(false);
                const { response } = error;
                if (response?.data?.errors) setErrors(response.data.errors);
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados do usuário!');
            });
    };

    const setMainAddressAs = (e, address_context, values, setValues) => {
        if (e.target.checked) {
            setValues((prev) => ({
                ...prev,
                [address_context]: JSON.parse(JSON.stringify(prev.address)),
                [`${address_context}_id`]: prev.main_address_id,
                [`main_as_${address_context}`]: true,
            }));
        } else {
            setValues((prev) => ({
                ...prev,
                [`${address_context}_id`]: userData?.billing_address_id || null,
                [`main_as_${address_context}`]: false,
                [address_context]: {
                    address: userData?.[address_context]?.address || '',
                    number: userData?.[address_context]?.number || '',
                    complement: userData?.[address_context]?.complement || '',
                    reference: userData?.[address_context]?.reference || '',
                    zip: userData?.[address_context]?.zip || '',
                    neighborhood: userData?.[address_context]?.neighborhood || '',
                    state: userData?.[address_context]?.city?.state || '',
                    city_id: userData?.[address_context]?.city?.id || '',
                    city_name: userData?.[address_context]?.city?.name || '',
                },
            }));
        }
        if (address_context && values && setValues) return true;
    };

    /**
     * Inicialização e validação do formulário
     */
    const validations = useMemo(
        () =>
            yup.object().shape({
                name: yup
                    .string()
                    .min(3, 'O nome deve ter entre 3 e 100 caracteres')
                    .max(100, 'O nome deve ter entre 3 e 100 caracteres')
                    .required('É necessário informar o nome'),
                email: yup.string().email('Formato de email inválido').required('É necessário informar um endereço de email.'),
                phone: yup
                    .string()
                    .min(11, 'Informe o número de telefone com o DDD')
                    .required('É necessário informar um número de telefone.'),
                document_type: yup.string(),
                document: yup.string().required('É necessário informar o número do documento.').when(['document_type'], {
                    is: 'cpf',
                    then: CPFValidator,
                    otherwise: CNPJValidator,
                }),
                password_confirmation: yup
                    .string()
                    .min(8, 'A senha deve possuir ao menos 8 caracteres.')
                    .required('A senha deve possuir ao menos 8 caracteres.'),
                address: AddressValidator,
                billing_address: AddressValidator,
                shipping_address: requireShippingAddress ? AddressValidator : undefined,
            }),
        [requireShippingAddress, AddressValidator, CPFValidator, CNPJValidator]
    );

    const mainAddressAsBillingAddress = useMemo(() => {
        const mainAddressToken = userData?.address?.address_token || null;
        const billingAddressToken = userData?.billing_address?.address_token || null;
        return mainAddressToken !== null && billingAddressToken !== null && mainAddressToken === billingAddressToken;
    }, [userData]);
    const mainAddressAsShippingAddress = useMemo(() => {
        const mainAddressToken = userData?.address?.address_token || null;
        const shippingAddressToken = userData?.shipping_address?.address_token || null;
        return mainAddressToken !== null && shippingAddressToken !== null && mainAddressToken === shippingAddressToken;
    }, [userData]);

    const initialValues = useMemo(() => {
        let response = {
            id: userData?.id || '',
            name: userData?.name || '',
            email: userData?.email || '',
            phone: userData?.phone || '',
            confirmation_code: '',
            document: userData?.document || '',
            document_type: userData?.document_type || 'cpf',
            entity_type: userData?.entity_type || 'individual',
            rntc: userData?.rntc || '',
            main_address_id: userData?.main_address_id || null,
            address: {
                address: userData?.address?.address || '',
                number: userData?.address?.number || '',
                complement: userData?.address?.complement || '',
                reference: userData?.address?.reference || '',
                zip: userData?.address?.zip || '',
                neighborhood: userData?.address?.neighborhood || '',
                state: userData?.address?.city?.state || '',
                city_id: userData?.address?.city?.id || null,
                city_name: userData?.address?.city?.name || '',
            },
            billing_address_id: userData?.billing_address_id || null,
            main_as_billing_address: mainAddressAsBillingAddress,
            billing_address: {
                address: userData?.billing_address?.address || '',
                number: userData?.billing_address?.number || '',
                complement: userData?.billing_address?.complement || '',
                reference: userData?.billing_address?.reference || '',
                zip: userData?.billing_address?.zip || '',
                neighborhood: userData?.billing_address?.neighborhood || '',
                state: userData?.billing_address?.city?.state || '',
                city_id: userData?.billing_address?.city?.id || '',
                city_name: userData?.billing_address?.city?.name || '',
            },
            password_confirmation: '',
        };
        if (requireShippingAddress) {
            response = {
                ...response,
                shipping_address_id: userData?.shipping_address_id || null,
                main_as_shipping_address: mainAddressAsShippingAddress,
                shipping_address: {
                    address: userData?.shipping_address?.address || '',
                    number: userData?.shipping_address?.number || '',
                    complement: userData?.shipping_address?.complement || '',
                    reference: userData?.shipping_address?.reference || '',
                    zip: userData?.shipping_address?.zip || '',
                    neighborhood: userData?.shipping_address?.neighborhood || '',
                    state: userData?.shipping_address?.city?.state || '',
                    city_id: userData?.shipping_address?.city?.id || '',
                    city_name: userData?.shipping_address?.city?.name || '',
                },
            };
        }
        return response;
    }, [requireShippingAddress, userData, mainAddressAsBillingAddress, mainAddressAsShippingAddress]);

    const document_formats = {
        cpf: { mask: '999.999.999-99', placeholder: '___.___.___-__' },
        cnpj: { mask: '99.999.999/9999-99', placeholder: '__.___.___/____-__' },
        individual: { mask: '999.999.999-99', placeholder: '___.___.___-__' },
        company: { mask: '99.999.999/9999-99', placeholder: '__.___.___/____-__' },
    };

    // Funções de alteração dos dados do usuário
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleUserSubmit}
            enableReinitialize
            render={(formikProps) => (
                <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                    <Field type="hidden" name="id" />
                    <Field type="hidden" name="stripe_id" />
                    <div className="mb-1 px-1 col-12 col-sm-6">
                        <Label className="field-label" htmlFor="name">
                            Nome<em>*</em>
                        </Label>
                        <Field name="name" type="text" placeholder="Nome do fornecedor" className="col-12" disabled={isLoading} />
                        <ErrorMessage component="span" name="name" className="text-error text-small px-2" />
                    </div>
                    <div className="mb-1 px-1 col-12 col-sm-6">
                        <Label className="field-label" htmlFor="email">
                            Email<em>*</em>
                        </Label>
                        <Field name="email" type="email" placeholder="Ex: admin@gmail.com" className="col-12" disabled={isLoading} />
                        <ErrorMessage component="span" name="email" className="text-error text-small px-2" />
                    </div>
                    <div className="mb-1 px-1 col-12 col-sm-6 col-lg-4">
                        <Label className="field-label" htmlFor="phone">
                            Telefone<em>*</em>
                        </Label>
                        <InputMask
                            name="phone"
                            type="text"
                            className="col-12"
                            mask="(99) 99999-9999"
                            placeholder="(__) _____-____"
                            value={formikProps.values.phone}
                            onChange={({ target: { value } }) => {
                                formikProps.setFieldTouched('phone');
                                formikProps.setValues({
                                    ...formikProps.values,
                                    ...{
                                        phone: value.toUpperCase(),
                                    },
                                });
                            }}
                        />
                        <ErrorMessage component="span" name="phone" className="text-error text-small px-2" />
                    </div>
                    <div className="mb-1 px-1 col-12 col-sm-6 col-lg-4">
                        <Label className="field-label" htmlFor="document">
                            Documento<em>*</em>
                        </Label>
                        <InputMask
                            name="document"
                            type="text"
                            className="col-12"
                            mask={document_formats?.[formikProps.values.document_type]?.mask || ''}
                            placeholder={document_formats?.[formikProps.values.document_type]?.placeholder || ''}
                            value={formikProps.values.document}
                            onChange={({ target: { value } }) => {
                                formikProps.setFieldTouched('document');
                                formikProps.setValues({
                                    ...formikProps.values,
                                    ...{
                                        document: value.toUpperCase(),
                                    },
                                });
                            }}
                        />
                        <ErrorMessage component="span" name="document" className="text-error text-small px-2" />
                    </div>
                    <div className="mb-1 px-1 col-12 col-sm-6 col-lg-4">
                        <Label className="field-label" htmlFor="rntc">
                            No. RNTRC (TAC/ETC/CTC)
                        </Label>
                        <Field name="rntc" type="text" placeholder="" className="col-12" disabled={isLoading} />
                        <ErrorMessage component="span" name="rntc" className="text-error text-small px-2" />
                    </div>
                    {formikProps.values.phone !== userData.phone && <PhoneNumberConfirmation disabled={isLoading} />}

                    <p className="col-12 text-center m-0 mt-3 mb-1 p-2 badge badge-secondary text-wrap">
                        PARA COMPRS COM CARTÃO OU BOLETO É NECESSÁRIO COMPLETAR AS INFORMAÇÕES ABAIXO
                    </p>
                    <h3 className="field-group-title col-12">Endereço principal</h3>
                    <AddressFields field_context="address" errors={formikProps.errors?.address || {}} />

                    <h3 className="field-group-title col-12 mt-3">
                        Endereço de cobrança
                        <div>
                            <input
                                type="checkbox"
                                name="use_main_address_as_billing"
                                className="mx-1"
                                checked={formikProps.values.main_as_billing_address}
                                onChange={(e) => setMainAddressAs(e, 'billing_address', formikProps.values, formikProps.setValues)}
                            />
                            <span className="text-small">Utilizar o endereço principal</span>
                        </div>
                    </h3>
                    {!formikProps.values.main_as_billing_address && (
                        <AddressFields
                            field_context="billing_address"
                            errors={formikProps.errors?.billing_address || {}}
                            disabled={formikProps.values.main_as_billing_address}
                        />
                    )}

                    {!!requireShippingAddress && (
                        <>
                            <h3 className="field-group-title col-12 mt-3">
                                Endereço de entrega
                                <div>
                                    <input
                                        type="checkbox"
                                        name="use_main_address_as_shipping"
                                        className="mx-1"
                                        checked={formikProps.values.main_as_shipping_address}
                                        onChange={(e) => setMainAddressAs(e, 'shipping_address', formikProps.values, formikProps.setValues)}
                                    />
                                    <span className="text-small">Utilizar o endereço principal</span>
                                </div>
                            </h3>
                            {!formikProps.values.main_as_shipping_address && (
                                <AddressFields
                                    field_context="shipping_address"
                                    errors={formikProps.errors?.shipping_address || {}}
                                    disabled={formikProps.values.main_as_shipping_address}
                                />
                            )}
                        </>
                    )}

                    <h3 className="field-group-title col-12 mt-3">Confirmação com senha</h3>
                    <div className="mb-1 px-1 col-12">
                        <Field name="disabled-password-2" type="password" style={{ display: 'none' }} autoComplete="password-1" />
                        <Field name="disabled-password-1" type="password" style={{ display: 'none' }} autoComplete="password-2" />
                        <p>
                            Para garantir sua segurança, é necessário informar sua senha de acesso para continuar com a alteração dos seus
                            dados.
                        </p>
                        <Label className="field-label" htmlFor="password_confirmation">
                            Senha atual<em>*</em>
                        </Label>
                        <Field
                            name="password_confirmation"
                            component={PasswordField}
                            className="login-field col-12 col-sm-6 col-lg-4 mb-1"
                            autoComplete="new-password"
                            readOnly
                            onFocus={(e) => e.target.removeAttribute('readonly')}
                        />
                        <ErrorMessage component="span" name="password_confirmation" className="text-error text-small px-2" />
                        <p className="text-error text-small">** ESTA OPERAÇÃO NÃO ALTERA SUA SENHA **</p>
                    </div>

                    <div className="col-12 mt-3 d-flex justify-content-center">
                        <div className="mb-3 col-6 text-center">
                            <Button
                                type="submit"
                                className="col-10"
                                color="success"
                                disabled={'password_confirmation' in formikProps.errors || formikProps.isSubmitting || isLoading}
                            >
                                SALVAR
                            </Button>
                        </div>
                    </div>
                    <FormikDevTools />
                </Form>
            )}
        />
    );
}
