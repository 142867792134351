import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import CheckPermission from '@app/components/CheckPermission';
import FileDownloader from '@app/components/FileDownloader/FileDownloader';
import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import { toast } from 'react-hot-toast';
import Paginator from '@app/components/Paginator/Paginator';

function CustomersTable({ descriptor, formName = 'dados' }) {
    const replaceParams = useReplaceParams;

    const columns = descriptor.show.split('|');
    const filename = formName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({ pg: 1, last_page: 1 });

    useEffect(() => {
        const loadDescriptorData = () => {
            const url = replaceParams(URLs.admin.forms.show, { descriptor: descriptor?.token || null, pg: pagination.pg });
            Api({ method: 'get', url })
                .then(({ data: { last_page, data } }) => {
                    setData(data?.data || []);
                    setPagination((prev) => ({
                        ...prev,
                        last_page,
                    }));
                })
                .catch(() => {
                    toast.error('Houve um erro inesperado ao carregar o conteúdo da página!');
                });
        };

        loadDescriptorData();
    }, [descriptor, pagination.pg, replaceParams]);

    const handleNavigateFirst = () => setPagination({ ...pagination, ...{ pg: 1 } });
    const handleNavigateLast = () => setPagination({ ...pagination, ...{ pg: pagination.last_page } });
    const handleNavigateNext = () => setPagination({ ...pagination, ...{ pg: data?.length > 0 ? pagination.pg + 1 : pagination.pg } });
    const handleNavigatePrevious = () => setPagination({ ...pagination, ...{ pg: pagination.pg > 1 ? pagination.pg - 1 : 1 } });

    return (
        <>
            <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                <Paginator
                    pg={pagination.pg}
                    last_page={pagination.last_page}
                    hasPrevious={pagination.pg > 1}
                    hasNext={
                        (pagination.last_page !== null && pagination.last_page > pagination.pg) ||
                        (pagination.last_page === null && data?.length > 0)
                    }
                    handleNavigateFirst={handleNavigateFirst}
                    handleNavigateNext={handleNavigateNext}
                    handleNavigatePrevious={handleNavigatePrevious}
                    handleNavigateLast={handleNavigateLast}
                />
                <CheckPermission permission="export">
                    <FileDownloader
                        url={replaceParams(URLs.admin.forms.export, { descriptor: descriptor.token, ext: 'csv' })}
                        filename={filename}
                        label="Exportar como CSV"
                        extension="csv"
                        className="py-1"
                    />
                </CheckPermission>
            </div>
            <Table striped bordered hover col="12">
                <thead>
                    {(descriptor?.status || false) && (
                        <tr>
                            <td colSpan={columns.length + 1} align="center" className="text-small text-center bg-success text-white p-0">
                                Versão atual
                            </td>
                        </tr>
                    )}
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                        <th>Criado</th>
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data?.map((record, index) => (
                            <tr key={index}>
                                {columns.map((column, index) => (
                                    <td key={index}>{record?.data?.[column] || ''}</td>
                                ))}
                                <td>{record?.created_at || ''}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </>
    );
}

export default CustomersTable;
