import React, { useState } from 'react';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import { Button, Form, Label } from 'reactstrap';

import { Field, Formik } from 'formik';
import * as yup from 'yup';

import { URLs, useReplaceParams } from '@app/constants';
import { useUserManager } from '@app/hooks/useUserManager';

import Api from '@app/services/Api';

import { useFetch } from '@app/hooks';
import DocumentsTable from './tables/DocumentsTable';
import CertificateHistory from './tables/CertificateHistory';

function CertificatesDocuments(props) {
    const history = useHistory();
    const certificate_token = props?.match?.params?.token;

    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    const documentsData = new FormData();

    // Consulta de dados de sessões
    const [params, setParams] = useState({ token: certificate_token, nocache: new Date().getTime() });
    const certificate = useFetch(replaceParams(URLs.club.certificates.documents, params));

    const updateRequest = () => setParams({ ...params, ...{ nocache: new Date().getTime() } });

    const certificateFAddress = () => {
        if (!certificate?.data) return '';
        let res = '';
        res += certificate?.data?.address || '';
        res += certificate?.data?.number ? `,${certificate?.data?.number}` : '';
        res += certificate?.data?.complement ? `,${certificate?.data?.complement}` : '';
        res += certificate?.data?.reference ? `,${certificate?.data?.reference}` : '';
        return res;
    };

    const handleCertificateDocumentsSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);

        const url = replaceParams(URLs.club.certificates.documents, { token: certificate_token });
        Api({
            method: 'post',
            url,
            data: documentsData,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (response.data && response.status === 200) {
                    if (response?.data?.remaining === 0) {
                        toast.success('Documentos enviados com sucesso. Aguarde a revisão dos documentos.');
                        history.push('/certificados');
                    } else {
                        toast.success(response.data.message);
                        updateRequest();
                    }
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar enviar os dados!');
                setSubmitting(false);
            });
    };

    const handleFileUploadChange = (file, token) => {
        documentsData.append(token, file);
    };

    /**
     * Inicialização e validação do formulário
     */
    const certificateDocumentsValidations = yup.object().shape({});

    const certificateDocumentsInitialValues = {};

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper title="EMISSÃO DE DOCUMENTOS">
            <div className="page-content">
                <Formik
                    initialValues={certificateDocumentsInitialValues}
                    validationSchema={certificateDocumentsValidations}
                    onSubmit={handleCertificateDocumentsSubmit}
                    render={(formikProps) => (
                        <Form
                            onSubmit={(e) => {
                                formikProps.handleSubmit(e);
                            }}
                        >
                            <Field type="hidden" name="token" />
                            <div className="d-flex flex-wrap">
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="name">Nome: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.name}</Label>
                                </div>
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="group_id" title={props?.userData?.group?.id}>
                                        Tipo:{' '}
                                    </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.entity_label}</Label>
                                </div>
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="document">Documento: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.document}</Label>
                                </div>

                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="email">Email: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.email}</Label>
                                </div>
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="phone">Telefone: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.phone}</Label>
                                </div>
                                <div className="col-12 col-sm-8 px-2 py-1">
                                    <Label htmlFor="address">Endereço: </Label>
                                    <Label className="fw-bold mx-2">{certificateFAddress()}</Label>
                                </div>
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="zip">CEP: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.zip}</Label>
                                </div>
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="neighborhood">Bairro: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.neighborhood}</Label>
                                </div>
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="neighborhood">Cidade/UF: </Label>
                                    <Label className="fw-bold mx-2">
                                        {certificate?.data?.city?.name}/{certificate?.data?.city?.state}
                                    </Label>
                                </div>
                                <DocumentsTable
                                    data={certificate?.data?.documents}
                                    token={certificate_token}
                                    handleFileUploadChange={handleFileUploadChange}
                                    values={formikProps.values}
                                    setValues={formikProps.setValues}
                                />
                                <div className="col-12 col-sm-6 col-md-3 p-2 d-flex align-items-end">
                                    <a href="/certificados" className="btn btn-danger mx-2 col-12">
                                        Cancelar
                                    </a>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 p-2 d-flex align-items-end">
                                    <Button type="submit" className="mx-2 col-12" color="primary" disabled={formikProps.isSubmitting}>
                                        Enviar
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                />
                {false && (
                    <div className="col-12">
                        <CertificateHistory data={certificate?.data?.history} />
                    </div>
                )}
            </div>
        </ContentWrapper>
    );
}

export default CertificatesDocuments;
