import React from 'react';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './Notification.scss';

const priority_labels = {
    high: 'Alta prioridade',
    medium: '',
    low: 'Baixa prioridade',
};
function Notification(props) {
    return (
        <div className={`notification priority-${props?.data?.priority || ''} ${props?.data?.status === 0 ? 'new' : ''}`}>
            <div className="notification-content">
                <div className="datetime" title={priority_labels[props?.data?.priority]}>
                    <label>{props?.data?.created_at || ''}</label>
                </div>
                {parse(props?.data?.content || '')}
            </div>
            <div className="notification-tools">
                <button
                    type="button"
                    name="archive-notification"
                    color="custom"
                    className="btn btn-primary-outline p-2"
                    title="Arquivar notificação"
                >
                    <FontAwesomeIcon icon={faTrashAlt} className="mx-2" />
                </button>
            </div>
        </div>
    );
}
export default Notification;
