import React, { useContext } from 'react';
import OrderContext from '../OrderContext';
import ChargeCard from './ChargeCard';
import ChargeBoleto from './ChargeBoleto';
import ChargePix from './ChargePix';

export default function CheckoutResume({ className = '' }) {
    const { purchase } = useContext(OrderContext);

    return (
        <div className={`checkout-resume d-flex justify-content-center ${className}`}>
            <div className="checkout-form col-12">
                {(purchase?.charges || []).map((charge, cIndex) => {
                    switch (charge.payment_method) {
                        case 'credit_card':
                            return <ChargeCard key={cIndex} method="CARTÃO DE CRÉDITO" charge={charge} />;
                        case 'debit_card':
                            return <ChargeCard key={cIndex} method="CARTÃO DE BÉDITO" charge={charge} />;
                        case 'boleto':
                            return <ChargeBoleto key={cIndex} method="BOLETO" charge={charge} />;
                        case 'pix':
                            return <ChargePix key={cIndex} method="PIX" charge={charge} />;
                        default:
                            return 'carregando...';
                    }
                })}
            </div>
        </div>
    );
}
