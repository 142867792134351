import React, { useCallback, useEffect, useState } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import { useFloatToCurrency } from '@app/helpers';
import { NavigateBackButton } from '@app/components/ui';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faBox, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AccountTabsMenu from '../components/AccountTabsMenu';
import CancelSubscriptionPopup from './popups/CancelSubscriptionPopup';
import ChangeSubscriptionCardPopup from './popups/ChangeSubscriptionCardPopup';
import SubscriptionItemsTable from './table/SubscriptionItemsTable';
import SubscriptionIncrementsTable from './table/SubscriptionIncrementsTable';

// import ChangeSubscriptionCardPopup from './popups/ChangeSubscriptionCardPopup';
// import CancelSubscriptionPopup from './popups/CancelSubscriptionPopup';

function getRenewLabel(interval, interval_count) {
    const labels = {
        singular: {
            days: 'dia',
            weeks: 'semana',
            months: 'mês',
            years: 'ano',
        },
        plural: {
            days: 'dias',
            weeks: 'semanas',
            months: 'meses',
            years: 'anos',
        },
    };
    return `A cada ${interval_count} ${labels[interval_count === 1 ? 'singular' : 'plural'][interval]}`;
}

function getStatusColor(status) {
    switch (status) {
        case 'created':
            return 'text-primary';
        case 'processing':
            return 'text-info';
        case 'active':
            return 'text-success';
        case 'expired':
            return 'text-alert';
        case 'payment_failed':
            return 'text-error';
        case 'canceling':
            return 'text-info';
        default:
            return '';
    }
}

export default function SubscriptionDetails(props) {
    const replaceParams = useReplaceParams;
    const floatToCurrency = useFloatToCurrency;

    const [subscription, setSubscription] = useState({});

    const loadSubscriptionData = useCallback(
        (token) => {
            const url = replaceParams(URLs.club.subscriptions.subscriptions.show, { token });
            Api({ method: 'get', url }).then(({ data }) => {
                setSubscription(data);
            });
        },
        [replaceParams]
    );

    useEffect(() => {
        const token = props?.match?.params?.token || undefined;

        if (!token) return;
        loadSubscriptionData(token);
    }, [loadSubscriptionData, props?.match?.params]);

    const [modalParams, setModalParams] = useState({ modalIsOpen: false, token: null, description: '' });
    const openCancelSubscriptionPopup = (token, description) => {
        setModalParams({ modalIsOpen: true, token, description });
    };
    const closeCancelSubscriptionModal = () => {
        setModalParams({ modalIsOpen: false, token: null, description: '' });
    };
    // Parâmetros da modal de alteração do cartão
    const [changeCardModalParams, setChangeCardModalParams] = useState({
        modalIsOpen: false,
        token: null,
        description: '',
        currentCardId: null,
    });
    const openChangeCardPopup = (token, description, currentCardId) => {
        setChangeCardModalParams({ modalIsOpen: true, token, description, currentCardId });
    };
    const closeChangeCardPopup = () => {
        setChangeCardModalParams({ modalIsOpen: false, token: null, description: '', currentCardId: null });
    };

    return (
        <ContentWrapper title="">
            <AccountTabsMenu active="subscriptions" />
            <div className="d-flex px-3 py-1">
                <NavigateBackButton title="Retornar para Minhas Assinaturas" forceUrl="/conta/assinaturas" />
                <h2 className="flex-shrink-1 col-12 text-center text-large fw-bold d-flex align-items-center justify-content-center">
                    {subscription?.name || ''}
                </h2>
            </div>
            <div className="page-content d-flex flex-wrap">
                <div className="col-12 col-sm-6 p-1">
                    <label htmlFor="" className="field-label col-12">
                        Descrição da assinatura
                    </label>
                    <label htmlFor="" className="field-value col-12">
                        {subscription?.description || ''}
                    </label>
                </div>
                <div className="col-12 col-sm-3 p-1">
                    <label htmlFor="" className="field-label col-12">
                        Status
                    </label>
                    <label className={`field-value col-12 fw-bold text-center ${getStatusColor(subscription?.status)}`}>
                        {subscription?.status_label || ''}
                    </label>
                </div>
                <div className="col-12 col-sm-3 p-1">
                    <label htmlFor="" className="field-label col-12">
                        Data de Início
                    </label>
                    <label htmlFor="" className="field-value col-12">
                        {subscription?.start_at || ''}
                    </label>
                </div>

                <h3 className="field-group-title col-12 mt-3">DADOS DE COBRANÇA</h3>
                <div className="col-12 col-sm-4 p-1">
                    <label htmlFor="" className="field-label col-12">
                        Renovação
                    </label>
                    <label className="field-value col-12">{getRenewLabel(subscription?.interval, subscription.interval_count)}</label>
                </div>
                <div className="col-12 col-sm-4 p-1">
                    <label htmlFor="" className="field-label col-12">
                        Dia da cobrança
                    </label>
                    <label htmlFor="" className="field-value col-12">
                        {subscription?.billing_day || ''}
                    </label>
                </div>
                <div className="col-12 col-sm-4 p-1">
                    <label htmlFor="" className="field-label col-12">
                        Proxima cobrança
                    </label>
                    <label htmlFor="" className="field-value col-12">
                        {subscription?.next_billing_at || ''}
                    </label>
                </div>

                <div className="col-12 col-sm-4 p-1">
                    <label htmlFor="" className="field-label col-12">
                        Método de pgto.
                    </label>
                    <label htmlFor="" className="field-value col-12">
                        {subscription?.payment_method_label || ''}
                    </label>
                </div>
                <div className="col-12 col-sm-4 p-1">
                    <label htmlFor="" className="field-label col-12">
                        Cartão
                    </label>
                    <label htmlFor="" className="field-value col-12">
                        {subscription?.payment_method_data?.user_card_number || ''}
                    </label>
                </div>
                <div className="col-12 col-sm-4 p-1">
                    <label htmlFor="" className="field-label col-12">
                        Valor (R$)
                    </label>
                    <label htmlFor="" className="field-value col-12 text-right">
                        {floatToCurrency(subscription?.amount || 0)}
                    </label>
                </div>
                {(subscription?.increments || []).length > 0 && <SubscriptionIncrementsTable items={subscription.increments} />}
                {['active', 'future', 'payment_failed'].includes(subscription.status) && (
                    <div className="col-12 p-1">
                        <div className="col-12 col-sm-6">
                            <button
                                type="button"
                                className="btn"
                                title="Solicitar cancelamento da assinatura"
                                onClick={() =>
                                    openChangeCardPopup(
                                        subscription.token,
                                        subscription.description,
                                        subscription?.payment_method_data?.user_card_id || null
                                    )
                                }
                            >
                                <span className="mr-1">ALTERAR FORMA DE PAGAMENTO</span>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                        </div>
                    </div>
                )}

                <h3 className="field-group-title col-12 mt-3">ENDEREÇO DE COBRANÇA</h3>
                <div className="col-12 px-2 py-1">
                    <label htmlFor="" className="field-label col-12">
                        Endereço
                    </label>
                    <label className="field-value col-12">{subscription?.billing_address?.line_1.replaceAll(',', ', ')}</label>
                </div>
                <div className="col-12 px-2 py-1">
                    <label htmlFor="" className="field-label col-12">
                        Complemento
                    </label>
                    <label className="field-value col-12">{subscription?.billing_address?.line_2}</label>
                </div>
                <div className="col-12 col-sm-3 px-2 py-1">
                    <label htmlFor="" className="field-label col-12">
                        CEP
                    </label>
                    <label className="field-value col-12">{subscription?.billing_address?.zip_code}</label>
                </div>
                <div className="col-12 col-sm-3 px-2 py-1">
                    <label htmlFor="" className="field-label col-12">
                        Estado
                    </label>
                    <label className="field-value col-12">{subscription?.billing_address?.state}</label>
                </div>
                <div className="col-12 col-sm-6 px-2 py-1">
                    <label htmlFor="" className="field-label col-12">
                        Cidade
                    </label>
                    <label className="field-value col-12">{subscription?.billing_address?.city}</label>
                </div>
                {['active', 'payment_failed', 'pending', 'waiting_change', 'future'].includes(subscription.status) && (
                    <div className="col-12 p-1">
                        <div className="col-12 col-sm-6">
                            <button
                                type="button"
                                className="btn btn-danger"
                                title="Solicitar cancelamento da assinatura"
                                onClick={() => openCancelSubscriptionPopup(subscription.token, subscription.description)}
                            >
                                <span className="mr-1">CANCELAR ASSINATURA</span>
                                <FontAwesomeIcon icon={faBan} />
                            </button>
                        </div>
                    </div>
                )}
                <SubscriptionItemsTable items={subscription?.items || []} />
                {subscription.status === 'created' && (
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <NavLink
                            tag={Link}
                            to={{
                                pathname: `/assinatura/checkout/${subscription.token}`,
                                section: { name: '' },
                            }}
                            className="px-3 py-2 btn btn-warning"
                            title="Ir para o pagamento"
                        >
                            <FontAwesomeIcon icon={faBox} />
                            <span className="mx-2">Ir para pagamento</span>
                        </NavLink>
                    </div>
                )}
                <div className="col-12 text-center mt-3 text-small">
                    <p className="p-0 m-0">
                        Conheça nossa{' '}
                        <strong>
                            <a href="/politica-de-assinaturas" target="_blank" title="Clique aqui para ler nossa Política de Assinaturas">
                                Política de Assinaturas
                            </a>
                        </strong>
                        .
                    </p>
                </div>
            </div>
            <CancelSubscriptionPopup {...modalParams} closeModal={closeCancelSubscriptionModal} updateRequest={loadSubscriptionData} />
            <ChangeSubscriptionCardPopup
                {...changeCardModalParams}
                closeModal={closeChangeCardPopup}
                updateRequest={loadSubscriptionData}
            />
        </ContentWrapper>
    );
}
