import React from 'react';
import AppFooter from '@app/pages/shared/AppFooter/AppFooter';

function Privacy() {
    return (
        <section className="d-flex flex-column align-items-center">
            <div className="d-flex col-10 col-md-8" title="">
                <div className="page-content my-3">
                    <h1 className="text-center mb-3">Política de Privacidade</h1>
                    <p>
                        A proteção de dados pessoais é uma preocupação constante do <strong>Doutor Caminhoneiro</strong> da{' '}
                        <strong>TRANSPROLOG</strong>, e por isso, estabelecemos esta política de privacidade para explicar como coletamos,
                        usamos, compartilhamos e protegemos as informações dos nossos usuários.
                    </p>

                    <h3 className="fw-bold">Coleta de informações</h3>
                    <p>
                        Coletamos informações de nossos usuários para permitir que nossos serviços sejam oferecidos com qualidade e
                        segurança. As informações coletadas incluem nome, endereço de e-mail, endereço físico, número de telefone,
                        informações de pagamento, histórico de compras e outras informações que possam ser necessárias para prestar nossos
                        serviços.
                    </p>

                    <h3 className="fw-bold">Uso das informações</h3>
                    <p>
                        As informações coletadas são utilizadas para fornecer os serviços do <strong>Doutor Caminhoneiro</strong> da{' '}
                        <strong>TRANSPROLOG</strong>, bem como para melhorar a qualidade e segurança dos mesmos. Além disso, podemos usar
                        essas informações para fornecer informações sobre produtos e serviços relacionados, para fins publicitários e para
                        atender a requisitos legais.
                    </p>

                    <h3 className="fw-bold">Compartilhamento de informações</h3>
                    <p>
                        Não compartilhamos as informações dos nossos usuários com terceiros, exceto quando necessário para prestar nossos
                        serviços ou quando exigido por lei. Em alguns casos, podemos compartilhar informações com parceiros de negócios ou
                        fornecedores de serviços que nos auxiliam na prestação de nossos serviços.
                    </p>

                    <h3 className="fw-bold">Proteção de informações</h3>
                    <p>
                        Adotamos medidas de segurança adequadas para proteger as informações dos nossos usuários contra perda, uso indevido,
                        acesso não autorizado, divulgação, alteração ou destruição. Tomamos medidas técnicas, administrativas e físicas para
                        proteger as informações dos nossos usuários, incluindo criptografia, autenticação de usuários e backups regulares.
                    </p>

                    <h3 className="fw-bold">Cookies e tecnologias similares</h3>
                    <p>
                        O <strong>Doutor Caminhoneiro</strong> da <strong>TRANSPROLOG</strong> utiliza cookies e outras tecnologias
                        similares para melhorar a experiência do usuário e personalizar o conteúdo de acordo com os interesses dos usuários.
                        Os usuários podem optar por desativar os cookies em seus navegadores, mas isso pode afetar a funcionalidade de
                        alguns recursos do site.
                    </p>

                    <h3 className="fw-bold">Alterações nesta política de privacidade</h3>
                    <p>
                        O <strong>Doutor Caminhoneiro</strong> pode atualizar esta política de privacidade a qualquer momento para refletir
                        mudanças em nossas práticas de coleta e uso de informações. As alterações entrarão em vigor imediatamente após sua
                        publicação no site.
                    </p>

                    <h3 className="fw-bold">Contato</h3>
                    <p>
                        Se você tiver dúvidas ou preocupações sobre nossa política de privacidade, entre em contato conosco através de um
                        dos canais abaixo:
                        <br />
                        <br />
                        <ul>
                            <li>
                                E-mail:{' '}
                                <strong>
                                    <a href="mailto:contato@doutorcaminhoneiro.com.br">contato@doutorcaminhoneiro.com.br</a>
                                </strong>
                            </li>
                            <li>
                                E-mail:{' '}
                                <strong>
                                    <a href="mailto:contato@transprolog.com">contato@transprolog.com</a>
                                </strong>
                            </li>
                            <li>
                                Whatsapp:{' '}
                                <strong>
                                    <a href="https://web.whatsapp.com/send?phone=5519993142939">(19) 99314 2939</a>
                                </strong>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
            <AppFooter />
        </section>
    );
}
export default Privacy;
