import React, { useState, useEffect } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import { URLs, useReplaceParams } from '@app/constants';

import Paginator from '@app/components/Paginator/Paginator';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import { useFetch } from '@app/hooks/useFetch';
import FileDownloader from '@app/components/FileDownloader/FileDownloader';
import CheckPermission from '@app/components/CheckPermission';
import EventsTable from './tables/EventsTable';

export default function Whatsapp() {
    const replaceParams = useReplaceParams;

    const [params, setParams] = useState({
        status: 'all',
        startDate: '',
        endDate: '',
        nocache: new Date().getTime(),
        pg: 1,
        orderBy: '',
        orderDir: 'asc',
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { data, error, last_page } = useFetch(replaceParams(URLs.admin.whatsapp.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
    }, [data, error]);

    /**
     * Configuração de busca
     */
    const onSearchByStatusChange = (e) => setParams({ ...params, ...{ status: e.target.value, pg: 1 } });
    const onSearchByStartDateChange = (date) => {
        setStartDate(date);
        if (endDate && date > endDate) setEndDate(date);

        const dt = date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : '';
        setParams({ ...params, ...{ startDate: dt, pg: 1 } });
    };
    const onSearchByEndDateChange = (date) => {
        setEndDate(date);
        const dt = date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : '';
        setParams({ ...params, ...{ endDate: dt, pg: 1 } });
    };
    const handleOrderBy = (orderBy, orderDir) => setParams({ ...params, ...{ orderBy, orderDir, pg: 1 } });

    const handleNavigateFirst = () => setParams({ ...params, ...{ pg: 1 } });
    const handleNavigateLast = () => setParams({ ...params, ...{ pg: last_page } });
    const handleNavigateNext = () => setParams({ ...params, ...{ pg: data?.length > 0 ? params.pg + 1 : params.pg } });
    const handleNavigatePrevious = () => setParams({ ...params, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } });

    return (
        <ContentWrapper>
            <FilterContainer title="">
                <FilterFields className="col-4 d-flex">
                    <fieldset className="col-12 col-sm-5">
                        <label htmlFor="orderBy">Status:</label>
                        <select name="status" id="filter-status" onChange={onSearchByStatusChange}>
                            <option value="all" data-order-dir="asc">
                                Todos
                            </option>
                            <option value="new" data-order-dir="asc">
                                Novo Evento
                            </option>
                            <option value="processed" data-order-dir="asc">
                                Evento Processado
                            </option>
                            <option value="postponed" data-order-dir="asc">
                                Evento Postergado
                            </option>
                            <option value="error" data-order-dir="asc">
                                Erro no Processamento
                            </option>
                            <option value="ignored" data-order-dir="asc">
                                Evento Ignorado
                            </option>
                        </select>
                    </fieldset>
                    <fieldset className="col-12 col-sm-3">
                        <label htmlFor="orderBy">Data Inicial:</label>
                        <DatePicker
                            className="col-12"
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            clearButtonTitle="Limpar"
                            clearButtonClassName="px-1"
                            selected={startDate}
                            onChange={(date) => onSearchByStartDateChange(date)}
                        />
                    </fieldset>
                    <fieldset className="col-12 col-sm-3">
                        <label htmlFor="orderBy">Data Final:</label>
                        <DatePicker
                            className="col-12"
                            dateFormat="dd/MM/yyyy"
                            isClearable
                            clearButtonTitle="Limpar"
                            clearButtonClassName="px-1"
                            selected={endDate}
                            minDate={startDate}
                            onChange={(date) => onSearchByEndDateChange(date)}
                        />
                    </fieldset>
                    <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                </FilterFields>
            </FilterContainer>
            <div className="page-content">
                <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                    <CheckPermission permission="export">
                        <div className="d-flex mb-1">
                            <FileDownloader
                                url={replaceParams(URLs.admin.sales.export, { ...params, ...{ ext: 'csv' } })}
                                filename="vendas"
                                label="Exportar CSV"
                                extension="csv"
                                className=""
                            />
                        </div>
                    </CheckPermission>
                </div>
                <EventsTable data={data} handleOrderBy={handleOrderBy} />
                <Paginator
                    pg={params.pg}
                    last_page={last_page}
                    hasPrevious={params.pg > 1}
                    hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                    handleNavigateFirst={handleNavigateFirst}
                    handleNavigateNext={handleNavigateNext}
                    handleNavigatePrevious={handleNavigatePrevious}
                    handleNavigateLast={handleNavigateLast}
                />
            </div>
        </ContentWrapper>
    );
}
