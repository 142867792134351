import React from 'react';
import ProductSearch from '@app/components/SearchFields/ProductSearch';

export default function RelatedProductSelector({ value = '', className = '', onRelatedProductChange, ...props }) {
    return (
        <div className={`${className}`} {...props}>
            <div className="col-12 col-sm-2 flex-shrink-1">
                <label className="col-12 text-medium">Produto relacionado:</label>
            </div>
            <ProductSearch className="col-12 col-sm-10 flex-shrink-1" value={value} onChange={onRelatedProductChange} />
        </div>
    );
}
