import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Privacy from '@app/pages/policies/privacy';
import Terms from '@app/pages/policies/terms';

import Login from '@app/pages/auth/login/login';
import Register from '@app/pages/auth/register/register';
import ForgotPassword from '@app/pages/auth/forgot_password/forgot_password';
import ResetPassword from '@app/pages/auth/reset_password/reset_password';

import MainClub from '@app/pages/club/main';
import MainAdmin from '@app/pages/admin/main';
import NotFound from '@app/pages/not_found/not_fund';

import PrivateRoute from '@app/routes/PrivateRoute';
import ConfirmationCode from '../pages/auth/confirmation_code';
import SubscriptionsPolicy from '../pages/policies/subscriptions';
import PhoneConfirmation from '../pages/auth/phone_confirmation';
import Welcome from '../pages/auth/welcome';
import LoginAdmin from '../pages/auth/login/login_admin';
import EmailConfirmation from '../pages/auth/email_confirmation';
import PhoneAutoConfirmation from '../pages/auth/phone_auto_confirmation';

function Routes() {
    return (
        <Router>
            <Switch>
                <Route exact path="/politica" component={Privacy} />
                <Route exact path="/termos" component={Terms} />
                <Route exact path="/politica-de-assinaturas" component={SubscriptionsPolicy} />

                <Route exact path="/login" component={Login} />
                <Route exact path="/login/admin" component={LoginAdmin} />
                <Route exact path="/cadastrar" component={Register} />
                <Route exact path="/confirmar-telefone" component={PhoneConfirmation} />
                <Route exact path="/confirmar-telefone/:token" component={PhoneAutoConfirmation} />
                <Route path="/confirmar-email/:token" component={EmailConfirmation} />
                <Route exact path="/welcome" component={Welcome} />

                <Route exact path="/recuperar" component={ForgotPassword} />
                <Route exact path="/recuperar/confirmacao" component={ConfirmationCode} />
                <Route path="/redefinir/:token" component={ResetPassword} />
                <Route path="/admin" component={MainAdmin} />
                <PrivateRoute path="/" component={MainClub} />
                <PrivateRoute component={NotFound} />
            </Switch>
        </Router>
    );
}

export default Routes;
