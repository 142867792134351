import React, { useContext, useMemo, useState } from 'react';
import { Row, Col, Button, Table } from 'reactstrap';
import { CustomSwitch } from '@app/components/ui';

import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { PopupStyle } from '@app/constants';
import FormEditorContext from '../../FormEditorContext';
import DefaultFields from './DefaultFields';

function ImportFieldsPopup({ isOpen, ...props }) {
    const { content, onCloseImportFieldsPopup, onApplyFieldsImport } = useContext(FormEditorContext);

    const [fields, setFields] = useState(DefaultFields);
    const allSelected = useMemo(() => {
        const unselected = fields.reduce((unselected, item) => (unselected + !item.selected && !item.disabled ? 1 : 0), 0);
        return unselected === 0;
    }, [fields]);

    const afterOpenModal = () => {
        setFields((prev) =>
            prev.map((item) => {
                const isSelected = content.fields.findIndex((field) => field.name === item.name) >= 0;
                return {
                    ...item,
                    disabled: isSelected,
                };
            })
        );
    };

    const applyFieldsImport = () => {
        onApplyFieldsImport(
            fields
                .filter((item) => item.selected)
                .map((item) => {
                    const newItem = item;
                    delete newItem.selected;
                    delete newItem.disabled;
                    return {
                        ...newItem,
                        section_content_id: content.id,
                    };
                })
        );
    };

    const onSelectAllFields = (checked) => {
        setFields((prev) =>
            prev.map((item) => ({
                ...item,
                selected: item.disabled ? item.selected : checked,
            }))
        );
    };

    const onSelectFieldChange = (name, checked) => {
        setFields((prev) => prev.map((item) => (item.name === name ? { ...item, selected: checked } : item)));
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseImportFieldsPopup}
            style={PopupStyle}
            contentLabel=""
            ariaHideApp={false}
            {...props}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">SELECIONE OS CAMPOS QUE DESEJA IMPORTAR</h2>
                    <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={onCloseImportFieldsPopup}>
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    </Button>
                </Col>
            </Row>
            <div className="d-flex flex-wrap col-12">
                <Table striped bordered hover className="sections-table ">
                    <thead>
                        <tr>
                            <th className="text-center">
                                <CustomSwitch
                                    name="select-all"
                                    className="justify-content-center"
                                    isAsync={false}
                                    checked={allSelected}
                                    data={{ index: null }}
                                    onChange={onSelectAllFields}
                                />
                            </th>
                            <th>Nome</th>
                            <th>Título</th>
                            <th>Tipo</th>
                            <th>Máscara</th>
                            <th>Referência</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <CustomSwitch
                                        name={`select-field-${index}`}
                                        className="justify-content-center"
                                        isAsync={false}
                                        checked={item.selected || item.disabled}
                                        data={{ index }}
                                        disabled={item.disabled}
                                        onChange={(checked) => {
                                            // fields[index].selected = checked
                                            onSelectFieldChange(item.name, checked);
                                        }}
                                    />
                                </td>
                                <td>{item.name}</td>
                                <td>{item.label}</td>
                                <td>{item.type}</td>
                                <td>{item.mask}</td>
                                <td>{item.ref}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="col-12 my-1 d-flex">
                    <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                        <button type="submit" className="btn btn-success col-10" onClick={applyFieldsImport}>
                            APLICAR
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ImportFieldsPopup;
