import React from 'react';
import FileDownloader from '@app/components/FileDownloader/FileDownloader';

function BoletoStatusPending(props) {
    const { charge } = props;
    const boleto_pdf = charge?.transaction?.boleto_pdf || '';
    const boleto_barcode = charge?.transaction?.boleto_barcode || '';
    const boleto_line = charge?.transaction?.boleto_line || '';
    const expires_at = new Date(charge?.transaction?.due_at);

    let expires_at_label = `${String(expires_at.getDate()).padStart(2, '0')}/`;
    expires_at_label += `${String(expires_at.getMonth()).padStart(2, '0')}/`;
    expires_at_label += `${expires_at.getFullYear()} às `;
    expires_at_label += `${String(expires_at.getHours()).padStart(2, '0')}:`;
    expires_at_label += String(expires_at.getMinutes()).padStart(2, '0');

    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            {boleto_pdf !== '' && (
                <>
                    <p>
                        Seu boleto já está disponível para pagamento. Você pode baixar a versão em PDF clicando no botão abaixo ou através{' '}
                        <a href={boleto_pdf} title="Acessar boleto bancário" target="_blank" rel="noreferrer">
                            deste link
                        </a>
                    </p>
                    <FileDownloader className="mx-2" url={boleto_pdf} label="Baixar Boleto" filename="Boleto" extension="pdf" />
                </>
            )}
            {boleto_barcode !== '' && (
                <>
                    <p>Se preferir, você também pode realizar o pagamento através do código de barras abaixo</p>
                    <div className="py-3 qr-code-container d-flex justify-content-center">
                        <img src={boleto_barcode} alt="Código de barras" />
                    </div>
                    <p>Linha digitável: {boleto_line}</p>
                </>
            )}
            <p className="my-3">Page até: {expires_at_label}</p>
            <p className="text-small text-red">
                Caso o pagamento desta fatura já tenha sido realizada, você pode ignorar esta mensagem e aguardar até que o{' '}
                <strong>Doutor Caminhoneiro</strong> receba a confirmação do pagamento do seu pedido.
            </p>
        </>
    );
}
function BoletoStatusPaid(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">Tudo certo com seu pagamento, em breve os itens do seu pedido serão disponibilizados.</p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function BoletoStatusCanceled(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">O pagamento do seu pedido foi cancelado.</p>
            <p className="text-center">
                Você pode continuar navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para você.
            </p>
        </>
    );
}
function BoletoStatusProcessing(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido está sendo processado pelo gateway. O <strong>Doutor Caminhoneiro</strong> será notificado assim
                que houver uma atualização sobre o status do pagamento.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function BoletoStatusFailed(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido falhou. Você pode realizar este pedido novamente a qualquer momento. Fique atento à forma de
                pagamento escolhida e às datas de expiração da cobrança.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function BoletoStatusOverpaid(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">Tudo certo com seu pagamento, em breve os itens do seu pedido serão disponibilizados.</p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function BoletoStatusUnderpaid(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido foi realizado de forma parcial. Assim que o <strong>Doutor Caminhoneiro</strong> verificar o
                pagamento total do valor do pedido os itens solicitados serão disponibilizados.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function BoletoStatusChargedback(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido foi extornado. Você pode realizar este pedido novamente a qualquer momento. Fique atento à forma
                de pagamento escolhida e às datas de expiração da cobrança.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}

function ChargeBoleto({ charge }) {
    let content = 'carregando';
    switch (charge.status) {
        case 'pending':
            content = <BoletoStatusPending charge={charge} />;
            break;
        case 'paid':
            content = <BoletoStatusPaid charge={charge} />;
            break;
        case 'canceled':
            content = <BoletoStatusCanceled charge={charge} />;
            break;
        case 'processing':
            content = <BoletoStatusProcessing charge={charge} />;
            break;
        case 'failed':
            content = <BoletoStatusFailed charge={charge} />;
            break;
        case 'overpaid':
            content = <BoletoStatusOverpaid charge={charge} />;
            break;
        case 'underpaid':
            content = <BoletoStatusUnderpaid charge={charge} />;
            break;
        case 'chargedback':
            content = <BoletoStatusChargedback charge={charge} />;
            break;
        default:
            content = 'carregando...';
    }

    return (
        <div className="charge col-12 p-3 d-flex flex-column align-items-center">
            <h1 className="text-larges">BOLETO</h1>
            {content}
        </div>
    );
}

export default ChargeBoleto;
