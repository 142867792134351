import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Row, Col, Button, Form } from 'reactstrap';
import { URLs, useReplaceParams } from '@app/constants';

import Modal from 'react-modal';
import { Field, Formik } from 'formik';

import * as yup from 'yup';
import Api from '@app/services/Api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import FormikDevTools from '@app/components/FormikDevTools';
import { CopyToClipboardField } from '@app/components/ui';

const availableChannels = [
    {
        channel: 'whatsapp',
        label: 'Enviar link de redefinição via Whatsapp',
        needsConfirmation: true,
    },
    {
        channel: 'e-mail',
        label: 'Enviar link de redefinição via e-mail',
        needsConfirmation: true,
    },
    {
        channel: 'link',
        label: 'Copiar link de redefinição',
        needsConfirmation: false,
    },
];
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'initial',
    },
};
export default function ResetPasswordPopup({ isOpen, userId, userName, confirmedChannels, onClose }) {
    const replaceParams = useReplaceParams;
    const [resetLink, setResetLink] = useState(null);

    const handleRuleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        setResetLink(null);
        const url = replaceParams(URLs.admin.customers.reset, values);

        Api({ method: 'post', url, data: { channel: values.channel } })
            .then((response) => {
                if (response.data && response.status === 200) {
                    if (values.channel === 'link') {
                        setResetLink(response.data.link);
                        toast.success('Link de redefinição de senha gerado com sucesso!');
                    } else {
                        toast.success(response.data.message);
                        onClose();
                    }
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar redefinir a senha do cliente!');
                setSubmitting(false);
            });
    };

    const contractValidations = yup.object().shape({});

    const initialValues = useMemo(() => {
        let channel = 'link';
        if (confirmedChannels?.['e-mail']) channel = 'e-mail';
        if (confirmedChannels?.whatsapp) channel = 'whatsapp';
        return {
            id: userId,
            expires_after: '20 minute',
            channel,
        };
    }, [userId, confirmedChannels]);

    const afterOpenModal = () => {
        setResetLink(null);
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={onClose}
            style={customStyles}
            className=""
            contentLabel="REDEFINIR SENHA DO USUÁRIO"
            ariaHideApp={false}
        >
            <div className="modal-content">
                <Row>
                    <Col className="col-12 d-flex align-items-center">
                        <h2 className="flex-grow-1">REDEFINIR SENHA DO USUÁRIO</h2>
                        <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                        </Button>
                    </Col>
                </Row>
                <Formik
                    initialValues={initialValues}
                    validationSchema={contractValidations}
                    onSubmit={handleRuleSubmit}
                    render={({ values, isSubmitting, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} className="form d-flex flex-wrap new-rule-popup-form">
                            <Field type="hidden" name="id" />
                            <div className="cards-list col-12 py-3">
                                Escolha um canal de comunicação para redefinir a senha de <strong>{userName}</strong>.
                            </div>
                            <div className="cards-list col-12 d-flex flex-column">
                                {availableChannels.map((channel) => {
                                    const confirmed = !channel.needsConfirmation || confirmedChannels?.[channel.channel] || false;
                                    return (
                                        <div className="card d-flex col-12 px-2 py-1 mb-1">
                                            <label
                                                htmlFor={`channel-${channel.channel}`}
                                                className="d-flex col-12 align-items-center justify-content-center cursor-pointer"
                                            >
                                                <span className="col-11 d-flex flex-wrap flex-shrink-1 text-medium">{channel.label}</span>
                                                <span className="col-1 text-center">
                                                    <Field
                                                        type="radio"
                                                        id={`channel-${channel.channel}`}
                                                        name="channel"
                                                        value={String(channel.channel)}
                                                        disabled={isSubmitting || !confirmed}
                                                    />
                                                </span>
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="cards-list col-12 d-flex flex-wrap align-items-center py-2">
                                <label className="col-12">Expirar token após:</label>
                                <Field as="select" name="expires_after" className="col-12" disabled={isSubmitting}>
                                    <option value="20 minute">20 minutos</option>
                                    <option value="1 hour">1 hora</option>
                                    <option value="3 hour">3 horas</option>
                                    <option value="6 hour">6 horas</option>
                                    <option value="9 hour">9 horas</option>
                                    <option value="12 hour">12 horas</option>
                                    <option value="1 day">1 dia</option>
                                    <option value="2 day">2 dias</option>
                                    <option value="3 day">3 dias</option>
                                    <option value="4 day">4 dias</option>
                                    <option value="5 day">5 dias</option>
                                </Field>
                            </div>
                            {resetLink !== null && (
                                <div className="cards-list col-12 d-flex flex-wrap align-items-center py-2">
                                    <label className="col-12">Utilize o link abaixo para redefinir a senha deste usuário:</label>
                                    <CopyToClipboardField value={resetLink} />
                                </div>
                            )}

                            <div className="col-12 mt-3 d-flex">
                                <div className="mb-1 px-3 col-6 text-center">
                                    <Button className="col-10" color="danger" onClick={onClose} disabled={isSubmitting}>
                                        CANCELAR
                                    </Button>
                                </div>
                                <div className="mb-1 col-6 text-center">
                                    <Button type="submit" className="col-10" color="success" disabled={isSubmitting}>
                                        {values.channel === 'link' ? 'GERAR LINK' : 'ENVIAR'}
                                    </Button>
                                </div>
                            </div>

                            <FormikDevTools />
                        </Form>
                    )}
                />
            </div>
        </Modal>
    );
}
