import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { Button, Form, Label } from 'reactstrap';

import { CustomSwitch, LabelTip, NavigateBackButton, CurrencyInputField } from '@app/components/ui';

import { URLs, useReplaceParams } from '@app/constants';

import { ErrorMessage, Field, Formik } from 'formik';

import * as yup from 'yup';
// import { useUserManager } from '@app/hooks/useUserManager';
import Api from '@app/services/Api';

import { toast } from 'react-hot-toast';
import FormikDevTools from '@app/components/FormikDevTools';
import ProductsContext from './components/ProductsContext';
import { productTypeIcons } from './components/ProductTypeIcons';

import './products_form.scss';

import {
    FormSlicePack,
    FormSliceGallery,
    FormSliceButtonAfterBuy,
    FormSliceDetails,
    FormSliceTags,
    FormSliceSelectVideo,
    FormSliceProductPreview,
} from './components/slices';
import FormSliceSelectDocument from './components/slices/FormSliceSelectDocument';

// Converte JSON em FormData recursivamente
const objectToFormData = (obj, form, namespace, instance = 0) => {
    if (instance >= 10) return;

    const data = form || new FormData();
    Object.keys(obj).forEach((property) => {
        if (obj[property] !== null) {
            const formKey = namespace ? `${namespace}[${property}]` : property;

            if ((typeof obj[property] === typeof {} || typeof obj[property] === typeof []) && !(obj[property] instanceof File)) {
                objectToFormData(obj[property], data, formKey, instance + 1);
            } else {
                let value = obj[property];
                if (typeof value === 'boolean') value = value ? 1 : 0;
                data.append(formKey, value);
            }
        } else {
            console.log(`obj?.[${property}] is NULL!!!`, obj?.[property]);
        }
    });
    return data;
};

const getProductConfigByType = (product_type) => {
    switch (product_type) {
        case 'video':
            return {
                show_button_link: false,
                private: {
                    video_url: '',
                    button_label: '',
                    button_target: '_self',
                    url: '',
                },
            };
        case 'document':
            return {
                show_button_link: true,
                private: {
                    filename: '',
                    file_url: '',
                    format: '',
                    button_label: 'DOWNLOAD',
                    button_target: '_self',
                    url: '',
                },
            };
        case 'object':
            return {
                show_button_link: false,
                private: {},
            };
        default:
            return {
                show_button_link: false,
                private: {
                    button_label: '',
                    button_target: '_self',
                    url: '',
                },
            };
    }
};

function ProductsForm(props) {
    const replaceParams = useReplaceParams;

    // Lista de vídeos disponíveis no VIMEO
    const [isLoading, setIsLoading] = useState(false);
    const [productToken, setProductToken] = useState('');
    const [productData, setProductData] = useState({});
    const [document, setDocument] = useState(null);

    useEffect(() => {
        if ((props?.match?.params?.token || '') === '') return;
        setProductToken(props?.match?.params?.token);
    }, [props?.match?.params?.token]);

    useEffect(() => {
        if (productToken === '') return;
        // Carrega os dados do produto
        const loadProductData = (token) => {
            setIsLoading(true);
            const url = replaceParams(URLs.admin.products.show, { nocache: new Date().getTime(), token });
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setProductData(response.data);
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do produto');
                });
        };
        loadProductData(productToken);
    }, [productToken, replaceParams]);

    // Faz o submit dos dados do produto
    const [imageFiles, setImageFiles] = useState({});
    const handleProductSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        const method = (values?.token || '') !== '' ? 'put' : 'post';
        const url = replaceParams(URLs.admin.products[method], { token: values.token ? values.token : '' });
        // Preparando os dados
        const productData = objectToFormData(values);
        Object.keys(imageFiles).forEach((key) => {
            productData.append(key, imageFiles[key]);
        });
        if (document !== null) productData.append('document', document);

        Api({
            method: 'post',
            url,
            data: productData,
        })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    setProductToken(response.data.token);
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados da mídia!');
                setSubmitting(false);
            });
    };

    const handleProductTypeChange = (value, values, setValues) => {
        setValues((prev) => ({
            ...values,
            ...{
                product_type: value,
                ...getProductConfigByType(value),
                has_shipping: value === 'object' ? true : prev?.has_shipping || false,
            },
        }));
    };
    const handleFreeOfferChange = (value, values, setValues) => {
        setValues((prev) => ({
            ...prev,
            ...{
                free_offer: value,
                price: value ? 0 : prev?.old_price || 0,
            },
        }));
    };
    const handlePackOnlyChange = (value, values, setValues) => {
        setValues((prev) => ({
            ...prev,
            ...{
                pack_only: value,
            },
        }));
    };
    const handleOfferOnceChange = (value, values, setValues) => {
        setValues({ ...values, ...{ offer_once: value } });
    };
    const handleRemoveFromCartChange = (value, values, setValues) => {
        setValues({ ...values, ...{ remove_from_cart: value } });
    };

    /**
     * Validações do formulário
     */

    const validations = yup.object().shape({
        name: yup.string().min(3, 'O nome da mídia precisa ter ao menos 3 dígitos.').required('É necessário informar o nome da mídia.'),
        description: yup
            .string()
            .min(3, 'A descrição precisa ter ao menos 3 caracteres.')
            .required('É necessário informar a descrição da mídia.'),
        price: yup
            .number()
            .typeError('Informe um preço válido')
            .transform((_value, originalValue) => Number(`${originalValue}`.replace(/,/, '.')))
            .required('É necessário informar um valor unitário.'),
        old_price: yup
            .number()
            .transform((_value, originalValue) => Number(`${originalValue}`.replace(/,/, '.')))
            .typeError('Informe um preço válido'),
        stock: yup.number().integer('A quantidade precisa ser um valor inteiro').nullable(true).typeError('Informe uma quantidade válida'),
        max_offering: yup
            .number()
            .integer('A quantidade máxima oferecida precisa ser um valor inteiro')
            .typeError('Informe uma quantidade válida'),
        product_type: yup.string().required('É necessário selecionar o tipo do produto.'),
        document_name: yup.string().when('product_type', {
            is: 'document',
            then: yup.string().required('É necessário selecionar um arquivo'),
        }),
    });

    const initialValues = {
        name: productData?.name || '',
        token: productData?.token || '',
        description: productData?.description || '',
        details: productData?.details || '',
        extract_description: productData?.extract_description || '',
        tags: productData?.tags || '',
        price: productData?.price || 0,
        old_price: productData?.old_price || 0,
        expires_after: productData?.expires_after || '',
        expires_unit: productData?.expires_unit || 'days',
        product_type: productData?.product_type || 'document',
        stock: productData?.stock || '',
        max_offering: productData?.max_offering || 1,
        remove_from_cart: productData?.remove_from_cart || 0,
        has_shipping: productData?.has_shipping || (productData?.product_type || '') === 'object',
        offer_once: productData.offer_once || 0,
        free_offer: productData.free_offer || 0,
        pack_only: productData.pack_only || 0,
        gallery: productData?.gallery || [],
        document_name: productData?.private?.filename || '',
        document_format: productData?.private?.format || '',

        show_button_link: (productData?.private?.button_label || '') !== '',
        private: Array.isArray(productData?.private) ? Object.fromEntries(productData?.private) : productData?.private || {},

        supplier: productData?.supplier || {},

        pack: productData?.pack || [],
    };

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper className="products_form" hideTitle>
            <div className="d-flex px-3">
                <NavigateBackButton title="Retornar para a página de produtos" />
                <h1 className="content-title flex-shrink-1 col-12 text-center">
                    {(productData?.token || '') === '' ? 'CADASTRAR NOVO PRODUTO' : 'ALTERAR DADOS DO PRODUTO'}
                </h1>
            </div>
            <div className="page-content">
                <ProductsContext.Provider
                    value={{
                        setDocument,
                        imageFiles,
                        setImageFiles,
                        productTypeIcons,
                    }}
                >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validations}
                        onSubmit={handleProductSubmit}
                        enableReinitialize
                        render={(formikProps) => (
                            <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                                <Field type="hidden" name="id" />
                                <div className="mb-1 px-1 col-12 col-sm-6">
                                    <Label className="field-label" htmlFor="product_type">
                                        Tipo<em>*</em>
                                        <LabelTip>
                                            O tipo do produto determina a forma como ele será apresentado ao cliente. Existem 5 tipos:
                                            Objeto, Serviço, Conteúdo em Vídeo, Documento (pdf, xlsx, docx, etc) e Pacote, onde é possível
                                            agrupar vários produtos.
                                        </LabelTip>
                                    </Label>
                                    <select
                                        name="product_type"
                                        className="col-12"
                                        value={formikProps.values.product_type}
                                        onChange={(e) => handleProductTypeChange(e.target.value, formikProps.values, formikProps.setValues)}
                                        disabled={isLoading || (formikProps.values?.token || '') !== ''}
                                    >
                                        <option value="object">Objeto</option>
                                        <option value="service">Serviço</option>
                                        <option value="video">Vídeo</option>
                                        <option value="document">Documento</option>
                                        <option value="pack">Pacote / Curso</option>
                                    </select>
                                    <ErrorMessage component="span" name="product_type" className="text-error text-small" />
                                </div>
                                <div className="mb-1 px-1 col-12 col-sm-6">
                                    <Label className="field-label" htmlFor="name">
                                        Nome<em>*</em>
                                    </Label>
                                    <Field name="name" type="text" placeholder="Nome do produto" className="col-12" disabled={isLoading} />
                                    <ErrorMessage component="span" name="name" className="text-error text-small" />
                                </div>
                                <div className="mb-1 col-12 px-1 d-flex flex-column">
                                    <Label className="field-label" htmlFor="description">
                                        Descrição<em>*</em>
                                    </Label>
                                    <Field
                                        name="description"
                                        type="text"
                                        component="textarea"
                                        maxLength="200"
                                        placeholder="Descrição do produto"
                                        className="col-12"
                                        disabled={isLoading}
                                    />
                                    <span className="text-small col-12 text-right">( {formikProps.values.description.length} / 200 )</span>
                                    <ErrorMessage component="span" name="description" className="text-error text-small" />
                                </div>
                                <div className="mb-1 col-12 px-1">
                                    <Label className="field-label" htmlFor="extract_description">
                                        Descrição no extrato
                                        <LabelTip className="mx-1">
                                            Descrição do produto que será exibida no extrato de compras do cliente.
                                        </LabelTip>
                                    </Label>
                                    <Field
                                        name="extract_description"
                                        maxLength={20}
                                        type="text"
                                        placeholder="Descrição do produto no extrato"
                                        className="col-12"
                                        disabled={isLoading}
                                    />
                                    <ErrorMessage component="span" name="extract_description" className="text-error text-small" />
                                </div>

                                <FormSliceTags isLoading={isLoading} />

                                <div className="col-12 py-2 d-flex flex-wrap align-items-center">
                                    <div className="col-12 d-flex align-items-center">
                                        <CustomSwitch
                                            name="pack_only"
                                            className="justify-content-center mx-2"
                                            onChange={(e) => handlePackOnlyChange(e, formikProps.values, formikProps.setValues)}
                                            checked={formikProps.values?.pack_only || false}
                                            isAsync={false}
                                            disabled={isLoading}
                                            data={{ id: props?.data?.token, status: formikProps.values?.pack_only || false }}
                                        />
                                        <Label className="field-label" htmlFor="offer_once">
                                            Não vender separadamente
                                            <LabelTip className="mx-1">
                                                <strong>Marcado:</strong> Indica que o produto faz parte de um pacote ou curso e{' '}
                                                <strong>NÃO</strong> poderá ser oferecido separadamente.
                                                <br />
                                            </LabelTip>
                                        </Label>
                                    </div>
                                </div>

                                <div className="col-12 py-2 mb-3 d-flex flex-wrap align-items-center">
                                    <div className="col-12 d-flex align-items-center">
                                        <CustomSwitch
                                            name="free_offer"
                                            className="justify-content-center mx-2"
                                            onChange={(e) => handleFreeOfferChange(e, formikProps.values, formikProps.setValues)}
                                            checked={formikProps.values?.free_offer || false}
                                            isAsync={false}
                                            disabled={isLoading}
                                            data={{ id: props?.data?.token, status: formikProps.values?.free_offer || false }}
                                        />
                                        <Label className="field-label" htmlFor="offer_once">
                                            Oferecer gratuitamente
                                            <LabelTip className="mx-1">
                                                <strong>Marcado:</strong> Indica que o produto <strong>SERÁ</strong> oferecido
                                                gratuitamente.
                                                <br />
                                                <strong>Desmarcado:</strong> Indica que o produto <strong>NÃO</strong> será oferecido
                                                gratuitamente.
                                                <br />
                                            </LabelTip>
                                        </Label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-1 px-1">
                                    <Label className="field-label" htmlFor="price">
                                        Preço unitário (atual)<em>*</em>
                                        <LabelTip>
                                            Preço unitário de oferecimento do produto. Este valor será utilizado na cobrança do produto.
                                        </LabelTip>
                                    </Label>
                                    <Field
                                        name="price"
                                        component={CurrencyInputField}
                                        className="col-12 text-right"
                                        disabled={isLoading || formikProps.values.free_offer || formikProps.values.product_type === 'pack'}
                                    />
                                    <ErrorMessage component="span" name="price" className="text-error text-small" />
                                </div>
                                <div className="mb-1  px-1 col-12 col-sm-6 col-md-4 col-lg-3">
                                    <Label className="field-label" htmlFor="old_price">
                                        Preço unitário (anterior)
                                        <LabelTip className="mx-1">
                                            Preço unitário anterior do produto. Este valor tem função apenas visual e não é utilizado na
                                            cobrança.
                                        </LabelTip>
                                    </Label>
                                    <Field
                                        name="old_price"
                                        component={CurrencyInputField}
                                        className="col-12 text-right"
                                        disabled={isLoading}
                                    />
                                    <ErrorMessage component="span" name="old_price" className="text-error text-small" />
                                </div>
                                <div className="mb-1  px-1 col-12 col-sm-6 col-md-4 col-lg-3">
                                    <Label className="field-label" htmlFor="stock">
                                        Qtd. em estoque
                                        <LabelTip className="mx-1">
                                            Quantidade de produto disponível em estoque para venda.
                                            <br />
                                            <strong>Vazio:</strong> Indica que não há uma quantidade limite.
                                            <br />
                                            <strong>0:</strong> Indica que não há produto disponível para venda.
                                            <br />
                                            <strong>&gt;= 1:</strong> Indica a quantidade disponível do produto para venda.
                                            <br />
                                        </LabelTip>
                                    </Label>
                                    <Field name="stock" type="text" placeholder="" className="col-12" disabled={isLoading} />
                                    <ErrorMessage component="span" name="stock" className="text-error text-small" />
                                </div>
                                <div className="mb-1  px-1 col-12 col-sm-6 col-md-4 col-lg-3">
                                    <Label className="field-label" htmlFor="max_offering">
                                        Qtd. máx. / compra
                                        <LabelTip className="mx-1">
                                            Quantidade máxima permitida por usuário em uma única compra.
                                            <br />
                                            <strong>Vazio:</strong> Indica que não há uma quantidade limite. Caso uma quantidade em estoque
                                            seja definida, este então será o limite de unidades por compra.
                                            <br />
                                            <strong>1:</strong> Indica que o cliente não poderá comprar mais de 1 item. Não exibe o controle
                                            de seleção de quantidade.
                                            <br />
                                            <strong>&gt;= 2:</strong> Indica o valor máximo que o cliente poderá indicar no seletor de
                                            quantidade, limitado pela quantidade disponível em estoque.
                                            <br />
                                        </LabelTip>
                                    </Label>
                                    <Field name="max_offering" type="text" placeholder="" className="col-12" disabled={isLoading} />
                                    <ErrorMessage component="span" name="max_offering" className="text-error text-small" />
                                </div>

                                <div className="col-12 d-flex flex-wrap">
                                    <div className="mb-1 px-1 col-12 col-sm-6 col-lg-3">
                                        <Label className="field-label" htmlFor="expires_after">
                                            Expira após
                                            <LabelTip className="mx-1">
                                                Determina o intervalo de duração do produto, após o qual o produto não estará mais
                                                disponível para o cliente.
                                            </LabelTip>
                                        </Label>
                                        <Field name="expires_after" type="text" placeholder="0" className="col-12" disabled={isLoading} />
                                        <ErrorMessage component="span" name="expires_after" className="text-error text-small" />
                                    </div>
                                    <div className="mb-1 px-1 col-12 col-sm-6 col-lg-3">
                                        <Label className="field-label" htmlFor="expires_after">
                                            Unidade de tempo
                                            <LabelTip className="mx-1">
                                                Determina a unidade de tempo de duração do produto, após o qual o produto não estará mais
                                                disponível para o cliente.
                                            </LabelTip>
                                        </Label>
                                        <select
                                            name="expires_unit"
                                            className="col-12"
                                            value={formikProps.values?.expires_unit || ''}
                                            onChange={(e) =>
                                                formikProps.setValues({ ...formikProps.values, ...{ expires_unit: e.target.value } })
                                            }
                                            disabled={isLoading}
                                        >
                                            <option value="minutes">Minutos</option>
                                            <option value="hours">Horas</option>
                                            <option value="days">Dias</option>
                                            <option value="months">Meses</option>
                                            <option value="years">Anos</option>
                                        </select>
                                        <ErrorMessage component="span" name="expires_unit" className="text-error text-small" />
                                    </div>
                                    <div className="col-12 my-2 px-1 d-flex flex-wrap align-items-center">
                                        <div className="col-12 d-flex align-items-center">
                                            <CustomSwitch
                                                name="offer_once"
                                                className="justify-content-center mx-2"
                                                onChange={(e) => handleOfferOnceChange(e, formikProps.values, formikProps.setValues)}
                                                checked={formikProps.values?.offer_once || false}
                                                isAsync={false}
                                                disabled={isLoading}
                                                data={{ id: props?.data?.token, status: formikProps.values?.offer_once || false }}
                                            />
                                            <Label className="field-label" htmlFor="offer_once">
                                                Permitir a compra apenas uma vez.
                                                <LabelTip className="mx-1">
                                                    <strong>Marcado:</strong> Indica que o cliente <strong>NÃO</strong> poderá comprar este
                                                    produto novamente caso já o possua.
                                                    <br />
                                                    <strong>Desmarcado:</strong> Indica que o cliente <strong>PODERÁ</strong> comprar este
                                                    produto novamente mesmo que já o possua.
                                                    <br />
                                                </LabelTip>
                                            </Label>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2 px-1 d-flex flex-wrap align-items-center">
                                        <div className="col-12 d-flex align-items-center">
                                            <CustomSwitch
                                                name="remove_from_cart"
                                                className="justify-content-center mx-2"
                                                onChange={(e) => handleRemoveFromCartChange(e, formikProps.values, formikProps.setValues)}
                                                checked={formikProps.values?.remove_from_cart || false}
                                                isAsync={false}
                                                disabled={isLoading}
                                                data={{ id: props?.data?.token, status: formikProps.values?.remove_from_cart || false }}
                                            />
                                            <Label className="field-label" htmlFor="remove_from_cart">
                                                Remover do carrinho após confirmação de compra direta.
                                                <LabelTip className="mx-1">
                                                    Indica se o produto deverá ser removido do carrinho de compras após ser adquirido via
                                                    compra direta.
                                                </LabelTip>
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 my-2 px-1 d-flex flex-wrap align-items-center">
                                    <div className="col-12 d-flex align-items-center">
                                        <CustomSwitch
                                            name="has_shipping"
                                            className="justify-content-center mx-2"
                                            onChange={(e) => formikProps.setFieldValue('has_shipping', e)}
                                            checked={
                                                formikProps.values?.has_shipping || (formikProps.values?.product_type || '') === 'object'
                                            }
                                            isAsync={false}
                                            disabled={isLoading || (formikProps.values?.product_type || '') === 'object'}
                                            data={{ id: props?.data?.token, status: formikProps.values?.has_shipping }}
                                        />
                                        <Label className="field-label" htmlFor="has_shipping">
                                            Este produto está sujeito à postagem.
                                            <LabelTip className="mx-1">
                                                Indica que este é um produto físico e está sujeito à postagem via serviços de transporte.
                                            </LabelTip>
                                        </Label>
                                    </div>
                                </div>

                                <h3 className="field-group-title col-12 mt-3">Configurações de exibição pós compra</h3>
                                <FormSliceButtonAfterBuy isLoading={isLoading} />

                                {formikProps.values.product_type === 'video' && <FormSliceSelectVideo isLoading={isLoading} />}

                                {formikProps.values.product_type === 'document' && <FormSliceSelectDocument isLoading={isLoading} />}

                                {formikProps.values.product_type === 'pack' && <FormSlicePack isLoading={isLoading} />}

                                <h3 className="field-group-title col-12 mt-3">Informações detalhadas do produto</h3>
                                <FormSliceDetails isLoading={isLoading} />

                                <h3 className="field-group-title col-12 mt-3">Galeria de imagens</h3>
                                <FormSliceGallery isLoading={isLoading} />

                                <div className="col-12 mt-3 d-flex">
                                    <div className="mb-3 px-3 col-6 text-center">
                                        <NavigateBackButton
                                            className="col-10"
                                            color="danger"
                                            disabled={formikProps.isSubmitting || isLoading}
                                        >
                                            CANCELAR
                                        </NavigateBackButton>
                                    </div>
                                    <div className="mb-3 col-6 text-center">
                                        <Button
                                            type="submit"
                                            className="col-10"
                                            color="success"
                                            disabled={formikProps.isSubmitting || isLoading}
                                        >
                                            SALVAR
                                        </Button>
                                    </div>
                                </div>

                                <h3 className="field-group-title col-12 mt-3">Pré visualização do produto</h3>
                                <FormSliceProductPreview />

                                <FormikDevTools />
                            </Form>
                        )}
                    />
                </ProductsContext.Provider>
            </div>
        </ContentWrapper>
    );
}

export default ProductsForm;
