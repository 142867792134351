import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { Button, Form, Label } from 'reactstrap';
import { LabelTip, NavigateBackButton } from '@app/components/ui';

import { URLs, useReplaceParams } from '@app/constants';

import { ErrorMessage, Field, Formik } from 'formik';

import * as yup from 'yup';
import Api from '@app/services/Api';

import { toast } from 'react-hot-toast';
import FormikDevTools from '@app/components/FormikDevTools';
import SubscriptionPlansContext from '../components/SubscriptionPlansContext';
import { productTypeIcons } from './ProductTypeIcons';

import './plans_form.scss';

import {
    FormSliceIncludedProducts,
    FormSliceOptionalProducts,
    FormSlicePaymentMethods,
    FormSliceDetails,
    FormSlicePrices,
    FormSliceBillingDays,
    FormSlicePreview,
} from './slices';

function priceIntervals(interval_count, interval) {
    const labels = {
        plural: {
            day: { label: 'Dias', multiplier: 1 },
            week: { label: 'Semanas', multiplier: 365 / 52 },
            month: { label: 'Meses', multiplier: 365 / 12 },
            year: { label: 'Anos', multiplier: 365 },
        },
        singular: {
            day: { label: 'Dia', multiplier: 1 },
            week: { label: 'Semana', multiplier: 365 / 52 },
            month: { label: 'Mês', multiplier: 365 / 12 },
            year: { label: 'Ano', multiplier: 365 },
        },
    };
    return labels[interval_count === 1 ? 'singular' : 'plural']?.[interval] || {};
}

const recalculatePrices = (total, defaultInterval, defaultIntervalCount, prices) =>
    prices.map((price) => {
        const interval_reference = priceIntervals(defaultIntervalCount || 1, defaultInterval || 'month');
        const price_interval = priceIntervals(price.interval_count, price.interval);
        const multiplier = price.interval_count * (price_interval.multiplier / interval_reference.multiplier);
        const result = (total || 0) * multiplier;
        return {
            ...price,
            price: result - (price.discount / 100) * result,
        };
    });

function SubscriptionPlansForm(props) {
    const replaceParams = useReplaceParams;

    // Lista de vídeos disponíveis no VIMEO
    const [isLoading, setIsLoading] = useState(false);
    const [groupToken, setGroupToken] = useState(props?.match?.params?.group || '');
    const [groupName, setGroupName] = useState('');

    const [planToken, setPlanToken] = useState('');
    const [planData, setPlanData] = useState({});

    useEffect(() => {
        if ((props?.match?.params?.group || '') === '') return;

        // Carrega os dados do produto
        const loadGroupData = (token) => {
            setIsLoading(true);
            const url = replaceParams(URLs.admin.subscriptions.groups.show, { token });
            Api({ method: 'get', url })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setGroupName(response.data?.name || '');
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do produto');
                });
        };

        const token = props?.match?.params?.group;
        setGroupToken(token);
        loadGroupData(token);
    }, [props?.match?.params?.group, replaceParams]);

    useEffect(() => {
        if ((props?.match?.params?.token || '') === '') return;
        setPlanToken(props?.match?.params?.token);
    }, [props?.match?.params?.token]);

    useEffect(() => {
        if (planToken === '') return;
        // Carrega os dados do produto
        const loadPlanData = (token) => {
            setIsLoading(true);
            const url = replaceParams(URLs.admin.subscriptions.plans.show, {
                nocache: new Date().getTime(),
                token,
            });
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setPlanData(response.data);
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do produto');
                });
        };

        loadPlanData(planToken);
    }, [planToken, replaceParams]);

    // Faz o submit dos dados do produto
    const handlePlanSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        const method = (values?.token || '') !== '' ? 'put' : 'post';
        const url = replaceParams(URLs.admin.subscriptions.plans[method], {
            token: values?.token || '',
        });

        Api({
            method,
            url,
            data: values,
        })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    setPlanToken(response.data.token);
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados do plano!');
                setSubmitting(false);
            });
    };

    /**
     * Validações do formulário
     */

    const validations = yup.object().shape({
        name: yup.string().min(3, 'O nome do plano precisa ter ao menos 3 dígitos.').required('É necessário informar o nome do plano.'),
        description: yup
            .string()
            .min(3, 'A descrição precisa ter ao menos 3 caracteres.')
            .required('É necessário informar a descrição do plano.'),
        billing_days: yup.array().test('billing-days', 'Lista de datas inválida', (value) => {
            let valid = true;
            value.forEach((val) => {
                const parsed = parseInt(val);
                if (val !== '' && val !== undefined && (Number(parsed).isNaN || parsed < 1 || parsed > 28)) valid = false;
            });
            return valid;
        }),
        interval_count: yup.number().required('É preciso informar uma quantidade de tempo').min(1, 'Este valor deve ser maior do que 1'),
        items: yup.mixed().test('plan_items', 'Não há produtos associados ao plano', (items) => (items || []).length > 0),
        prices: yup
            .mixed()
            .test('plan_prices', 'Não há opções de preços cadastrados para o plano', (items) => (items || []).length > 0)
            .test('plan_prices_consistency', 'Foi encontrada uma inconsistência nos totais dos preços', (items, values) => {
                const { price, interval_count, interval } = values.parent;
                let check = true;
                items.forEach((item, index) => {
                    const interval_reference = priceIntervals(interval_count || 1, interval || 'month');
                    const price_interval = priceIntervals(item.interval_count, item.interval);
                    const multiplier = item.interval_count * (price_interval.multiplier / interval_reference.multiplier);
                    const result = (price || 0) * multiplier;
                    const desiredPrice = result - (item.discount / 100) * result;
                    if (Math.round(desiredPrice * 100) !== Math.round(item.price * 100)) {
                        check = false;
                        console.log(
                            `Inconsistência encontrada no item ${index}: Valor esperado: ${Math.round(
                                desiredPrice * 100
                            )}, valor encontrado: ${Math.round(item.price * 100)}`
                        );
                    }
                });
                return check;
            }),
        price: yup
            .number()
            .typeError('Este plano não possui nenhum produto.')
            .transform((_value, originalValue) => Number(`${originalValue}`.replace(/,/, '.')))
            .required('Este plano não possui nenhum produto.'),
    });

    const planDetails =
        'O plano MEI Básico é o plano perfeito para quem está começando. Nele você encontra todos os serviços e produtos necesssários para abrir seu MEI Caminhoneiro, emitir todos os documentos necessários e colocar sua empresa no caminho certo.<div><b><br></b></div><div><b>Veja as vantagens abaixo:</b></div><div><ul><li>Acesso ao sistema Transprolog TMS</li><li>30 Solicitações de emissão de CTe</li><li>1 Emissão de Certificado Digital A1</li></ul></div>';
    const initialValues = {
        group_id: groupToken || '',
        token: planData?.token || '',
        name: planData?.name || 'MEI Básico',
        description: planData?.description || 'Plano MEI Caminhoneiro básico',
        extract_description: planData?.extract_description || 'MEI_BASICO',
        details: planData?.details || planDetails,
        interval_count: planData?.interval_count || 1,
        interval: planData?.interval || 'month',
        billing_type: planData?.billing_type || 'prepaid',
        billing_days: planData?.billing_days || [],
        payment_methods: planData?.payment_methods || ['credit_card'],
        trial_period_days: planData?.trial_period_days || 0,
        has_shipping: planData?.has_shipping || false,
        items: planData?.items || [],
        prices: planData?.prices || [],
        price: (planData?.items || []).reduce((total, item) => total + (item?.amount || 0), 0),
    };

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper className="products_form" hideTitle>
            <div className="d-flex px-3">
                <NavigateBackButton title="Retornar para a página de assinaturas" />
                <h1 className="content-title flex-shrink-1 col-12 text-center">
                    {(planToken || '') === '' ? 'CADASTRAR NOVO PLANO' : 'ALTERAR DADOS DO PLANO'} NO GRUPO{' '}
                    <strong>{groupName || 'NÃO IDENTIFICADO'}</strong>
                </h1>
            </div>
            <div className="page-content">
                <SubscriptionPlansContext.Provider
                    value={{
                        productTypeIcons,
                        priceIntervals,
                        recalculatePrices,
                    }}
                >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validations}
                        onSubmit={handlePlanSubmit}
                        enableReinitialize
                        render={(formikProps) => (
                            <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                                <div className="mb-1 px-1 col-12 col-sm-6">
                                    <Label className="field-label" htmlFor="name">
                                        Nome<em>*</em>
                                    </Label>
                                    <Field name="name" type="text" placeholder="Nome do produto" className="col-12" disabled={isLoading} />
                                    <ErrorMessage component="span" name="name" className="text-error text-small" />
                                </div>
                                <div className="mb-1 col-12 px-1 d-flex flex-column">
                                    <Label className="field-label" htmlFor="description">
                                        Descrição<em>*</em>
                                    </Label>
                                    <Field
                                        name="description"
                                        type="text"
                                        component="textarea"
                                        maxLength="200"
                                        placeholder="Descrição do produto"
                                        className="col-12"
                                        disabled={isLoading}
                                    />
                                    <span className="text-small col-12 text-right">( {formikProps.values.description.length} / 200 )</span>
                                    <ErrorMessage component="span" name="description" className="text-error text-small" />
                                </div>
                                <div className="mb-1 px-1 col-12 col-sm-6 col-md-3">
                                    <Label className="field-label" htmlFor="extract_description">
                                        Descrição no extrato
                                        <LabelTip className="mx-1">
                                            Descrição do produto que será exibida no extrato de compras do cliente.
                                        </LabelTip>
                                    </Label>
                                    <Field
                                        name="extract_description"
                                        maxLength={20}
                                        type="text"
                                        placeholder="Descrição do produto no extrato"
                                        className="col-12"
                                        disabled={isLoading}
                                    />
                                    <ErrorMessage component="span" name="extract_description" className="text-error text-small" />
                                </div>

                                <div className="mb-1 px-1 col-12 col-sm-6 col-md-3">
                                    <Label className="field-label" htmlFor="billing_type">
                                        Tipo de cobrança
                                    </Label>
                                    <Field as="select" name="billing_type" className="col-12" disabled={isLoading}>
                                        <option value="prepaid">Pré-pago</option>
                                        <option value="postpaid" disabled>
                                            Pós-pago
                                        </option>
                                        <option value="exact_day">Dia exato</option>
                                    </Field>
                                    <ErrorMessage component="span" name="billing_type" className="text-error text-small" />
                                </div>

                                <FormSliceBillingDays isLoading={isLoading} />

                                <div className="mb-1 px-1 col-12 col-sm-6 col-md-3">
                                    <Label className="field-label" htmlFor="trial_period_days">
                                        Dias de teste
                                        <LabelTip className="mx-1">
                                            Informe uma quantidade de dias durante os quais o usuário poderá testar o plano antes de ser
                                            cobrado.
                                        </LabelTip>
                                    </Label>
                                    <Field
                                        name="trial_period_days"
                                        maxLength={20}
                                        type="number"
                                        min={0}
                                        placeholder=""
                                        className="col-12"
                                        disabled={isLoading}
                                    />
                                    <ErrorMessage component="span" name="trial_period_days" className="text-error text-small" />
                                </div>

                                <FormSlicePaymentMethods className="col-sm-6" />

                                <h3 className="field-group-title col-12 mt-3">Informações detalhadas do plano</h3>
                                <FormSliceDetails isLoading={isLoading} />

                                <FormSliceIncludedProducts isLoading={isLoading} itemType="included" />
                                <FormSliceOptionalProducts isLoading={isLoading} itemType="optional" title="ITENS ADICIONAIS AO PLANO" />

                                <FormSlicePrices isLoading={isLoading} />

                                {/* <h3 className="field-group-title col-12 mt-3">Configurações de exibição pós compra</h3>
                                    <FormSliceButtonAfterBuy isLoading={isLoading} />

                                    {formikProps.values.product_type==='video' && <FormSliceSelectVideo isLoading={isLoading} />}

                                    {formikProps.values.product_type==='document' && <FormSliceSelectDocument isLoading={isLoading} />}



                                    <h3 className="field-group-title col-12 mt-3">Galeria de imagens</h3>
                                    <FormSliceGallery isLoading={isLoading} /> */}

                                <div className="col-12 mt-3 d-flex">
                                    <div className="mb-3 px-3 col-6 text-center">
                                        <NavigateBackButton
                                            className="col-10"
                                            color="danger"
                                            disabled={formikProps.isSubmitting || isLoading}
                                        >
                                            CANCELAR
                                        </NavigateBackButton>
                                    </div>
                                    <div className="mb-3 col-6 text-center">
                                        <Button
                                            type="submit"
                                            className="col-10"
                                            color="success"
                                            disabled={formikProps.isSubmitting || isLoading}
                                        >
                                            SALVAR
                                        </Button>
                                    </div>
                                </div>

                                <h3 className="field-group-title col-12 mt-3">Pré visualização do plano</h3>
                                <FormSlicePreview />

                                <FormikDevTools />
                            </Form>
                        )}
                    />
                </SubscriptionPlansContext.Provider>
            </div>
        </ContentWrapper>
    );
}

export default SubscriptionPlansForm;
