import React from 'react';
import { Label } from 'reactstrap';
import { LabelTip } from '@app/components/ui';
import { ErrorMessage, Field, useFormikContext } from 'formik';

export default function FormSliceBillingDays({ className = '', isLoading = false, ...props }) {
    const { values, setFieldValue } = useFormikContext();
    const onChangeBillingDays = (values) => {
        const result = values.split(',').map((val) => val.replaceAll(' ', ''));
        setFieldValue('billing_days', result);
    };
    return (
        <div className={`mb-1 px-1 col-12 col-sm-6 col-md-3 ${className}`} {...props}>
            <Label className="field-label" htmlFor="billing_days">
                Dias p/ cobrança
                <LabelTip className="mx-1">
                    Lista de dias disponívei para a cobrança do plano.
                    <br />
                    Informe os dias, separados por vírgula, entre 1 e 28.
                    <br />
                    Caso nenhum dia seja informado, o dia de cobrança será o mesmo da assinatura, caso este seja menor que 28, ou 1 caso
                    contrário.
                    <br />
                    <strong>*Obrigatório caso o tipo de cobrança seja &quot;Dia exato&quot;</strong>
                </LabelTip>
            </Label>
            <Field
                name="billing_days"
                value={(values?.billing_days || []).join(',')}
                onChange={(e) => onChangeBillingDays(e.target.value)}
                type="text"
                placeholder="Ex.: 5, 10, 28"
                className="col-12"
                disabled={isLoading}
            />
            <ErrorMessage component="span" name="billing_days" className="text-error text-small" />
        </div>
    );
}
