import React, { useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Row, Col, Button, Form, Label } from 'reactstrap';
import { URLs, useReplaceParams } from '@app/constants';

import Modal from 'react-modal';
import { ErrorMessage, Field, Formik } from 'formik';

import * as yup from 'yup';
import Api from '@app/services/Api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { CustomSwitch, LabelTip } from '@app/components/ui';
import FormikDevTools from '@app/components/FormikDevTools';

import TaxationContext from '../../components/TaxationContext';
import SuppliersSearch from './components/SuppliersSearch';
import ProductsSearch from './components/ProductsSearch';
import FormSliceConditions from './components/FormSliceConditions';
import FormSliceSplit from './components/FormSliceSplit';

import './NewRulePopup.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'initial',
    },
};
function NewRulePopup({ isOpen, parent, rule }) {
    const replaceParams = useReplaceParams;
    const { onCloseNewRulePopup, updateRequest } = useContext(TaxationContext);

    const handleRuleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        const method = values.id ? 'put' : 'post';
        const url = replaceParams(URLs.admin.taxation[method], { id: values.id ? `${values.id}` : '' });

        Api({ method, url, data: values })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    updateRequest();
                    onCloseNewRulePopup();
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados da regra!');
                setSubmitting(false);
            });
    };

    const [allProducts, setAllProducts] = useState((rule?.product?.id || null) === null);
    const [allSuppliers, setAllSuppliers] = useState(
        rule.main === true || ((rule?.rule_id || null) !== null && (rule?.supplier?.id || null) === null)
    );
    const onChangeAllSuppliers = (option, setValues) => {
        setAllSuppliers(option);
        if (option) {
            setValues((prev) => ({
                ...prev,
                supplier_id: null,
                supplier: { id: null, name: '' },
                product_id: null,
                product: { id: null, name: '' },
            }));
            setAllProducts(true);
        }
    };
    const onChangeAllProducts = (option, setValues) => {
        setAllProducts(option);
        if (option) {
            setValues((prev) => ({
                ...prev,
                product_id: null,
                product: {
                    id: null,
                    name: '',
                },
            }));
        }
    };

    const contractValidations = yup.object().shape({
        description: yup
            .string()
            .min(3, 'A descrição precisa ter ao menos 3 caracteres.')
            .required('É necessário informar uma descrição para a regra.'),
        split: yup
            .array()
            .of(
                yup.object().shape({
                    amount: yup.number().required('É necessário informar um valor'),
                })
            )
            .min(1, 'É necessário selecionar ao menos 1 fornecedor para realizar o split de cobrança'),
        rule_id: yup.number().nullable().notRequired(),
        supplier_id: yup.mixed().when('rule_id', {
            is: null,
            then: yup.mixed().when('main', {
                is: false,
                then: yup.mixed().required('É necessário informar um fornecedor'),
                otherwise: yup.mixed().notRequired(),
            }),
            otherwise: yup.mixed().notRequired(),
        }),
    });

    const initialValues = useMemo(
        () => ({
            id: rule?.id || null,
            rule_id: parent.id,
            main: rule?.main || false,
            supplier_id: rule?.supplier_id || null,
            supplier: {
                id: rule?.supplier?.id || null,
                name: rule?.supplier?.name || '',
                token: rule?.supplier?.token || '',
            },
            product_id: rule?.product_id || null,
            product: {
                id: rule?.product?.id || null,
                name: rule?.product?.name || '',
                token: rule?.product?.token || '',
            },
            order: rule?.order || 0,
            description: rule?.description || '',
            conditions: rule?.conditions || [],
            conditions_label: rule?.conditions_label || '',
            split: rule?.split || [],
        }),
        [rule, parent]
    );

    const title = useMemo(() => ((rule?.id || null) === null ? 'CRIAR NOVA REGRA DE TAXAÇÃO' : 'ALTERAR REGRA DE TAXAÇÃO'), [rule]);

    useEffect(() => {
        const cond1 = initialValues.main === true;
        const cond2 = (initialValues?.rule_id || null) !== null && (initialValues?.supplier_id || null) === null;
        setAllSuppliers(cond1 || cond2 ? 1 : 0);

        setAllProducts((initialValues?.product?.id || null) === null ? 1 : 0);
    }, [initialValues]);

    const afterOpenModal = () => {};

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseNewRulePopup}
            style={customStyles}
            className=""
            contentLabel={title}
            ariaHideApp={false}
        >
            <div className="modal-content">
                <Row>
                    <Col className="col-12 d-flex align-items-center">
                        <h2 className="flex-grow-1">{title}</h2>
                        <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={onCloseNewRulePopup}>
                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                        </Button>
                    </Col>
                </Row>
                <Formik
                    initialValues={initialValues}
                    validationSchema={contractValidations}
                    onSubmit={handleRuleSubmit}
                    render={({ values, setValues, isSubmitting, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} className="form d-flex flex-wrap new-rule-popup-form">
                            <Field type="hidden" name="id" />
                            {parent?.id && parent?.description && (
                                <div className="p-1 col-12">
                                    <Label htmlFor="name">
                                        Sub-regra de: <strong>{parent.description}</strong>
                                    </Label>
                                </div>
                            )}
                            <div className="p-1 col-12">
                                <Label htmlFor="description" className="field-label">
                                    Descrição<em>*</em>
                                    <LabelTip>
                                        Informe uma descrição para facilitar a identificação da regra.
                                        <br />
                                        Ex.: <i>5% para serviços com valor cima de R$ 1000,00</i>
                                    </LabelTip>
                                </Label>
                                <Field name="description" type="text" maxLength={50} placeholder="Descrição da regra" className="col-12" />
                                <ErrorMessage component="span" name="description" className="text-error text-small" />
                            </div>

                            <div className="p-1 col-12 col-md-6">
                                <div className="col-12 mb-2 d-flex align-items-center">
                                    <CustomSwitch
                                        name="all_suppliers"
                                        className="justify-content-center"
                                        onChange={(e) => onChangeAllSuppliers(e, setValues)}
                                        checked={allSuppliers}
                                        disabled={values.main === true || (values?.rule_id || null) === null || false}
                                    />
                                    <Label className="field-label mx-2" htmlFor="all_suppliers">
                                        Aplicar à todos os fornecedores<em>*</em>
                                        <LabelTip>
                                            Selecionando um fornecedor específico fará com que esta regra seja aplicada apenas a ele e seus
                                            produtos
                                        </LabelTip>
                                    </Label>
                                </div>
                                <SuppliersSearch name={values.supplier.name} disabled={allSuppliers === true} />
                                <ErrorMessage component="span" name="supplier_id" className="text-error text-small" />
                            </div>
                            <div className="p-1 col-12 col-md-6">
                                <div className="col-12 mb-2 d-flex align-items-center">
                                    <CustomSwitch
                                        name="all_products"
                                        className="justify-content-center"
                                        onChange={(e) => onChangeAllProducts(e, setValues)}
                                        checked={allProducts}
                                        disabled={allSuppliers || values.supplier_id === null}
                                    />
                                    <Label className="field-label mx-2" htmlFor="all_suppliers">
                                        Aplicar à todos os produtos<em>*</em>
                                        <LabelTip>
                                            Você pode aplicar esta regra à todos os produtos de todos os fornecedores, à todos os produtos
                                            do fornecedor selecionado ou à um produto específico.
                                        </LabelTip>
                                    </Label>
                                </div>
                                <ProductsSearch name={values.product?.name || ''} disabled={allProducts === true} />
                            </div>

                            {values.main === false && (
                                <div className="p-1 col-12">
                                    <h3 className="field-group-title">Condições de aplicação</h3>
                                    <FormSliceConditions />
                                </div>
                            )}
                            <div className="p-1 col-12">
                                <h3 className="field-group-title">Definição das taxas</h3>
                                <FormSliceSplit />
                                <ErrorMessage component="span" name="split" className="text-error text-small" />
                            </div>

                            <div className="col-12 mt-3 d-flex">
                                <div className="mb-1 px-3 col-6 text-center">
                                    <Button className="col-10" color="danger" onClick={onCloseNewRulePopup} disabled={isSubmitting}>
                                        CANCELAR
                                    </Button>
                                </div>
                                <div className="mb-1 col-6 text-center">
                                    <Button type="submit" className="col-10" color="success" disabled={isSubmitting}>
                                        SALVAR
                                    </Button>
                                </div>
                            </div>

                            <FormikDevTools />
                        </Form>
                    )}
                />
            </div>
        </Modal>
    );
}

export default NewRulePopup;
