import React, { useMemo, useState } from 'react';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ProductGallery.scss';

function ProductGallery({ thumb = '', gallery = [] }) {
    const imageGallery = useMemo(
        () => (thumb !== '' ? [thumb, ...gallery.filter((image) => image.token !== thumb.token)] : gallery),
        [gallery, thumb]
    );

    const [imageIndex, setImageIndex] = useState(0);
    const navigateLeft = (e) => {
        e.preventDefault();
        setImageIndex((prev) => Math.max(0, prev - 1));
    };
    const navigateRight = (e) => {
        e.preventDefault();
        setImageIndex((prev) => Math.min(gallery.length, prev + 1));
    };
    return (
        <div className="product-gallery col-12">
            <div className="product-gallery_slider-container col-12">
                <div className="product-gallery_slider col-12" style={{ marginLeft: `-${imageIndex * 100}%` }}>
                    {imageGallery.map((image, index) => (
                        <div key={index} className="product-gallery_image col-12" style={{ backgroundImage: `url(${image?.url})` }} />
                    ))}
                </div>
            </div>
            <div className="product-gallery-anchors">
                {imageGallery.length > 1 &&
                    imageGallery.map((_, index) => (
                        <div
                            role="button"
                            tabIndex={0}
                            key={index}
                            className={`product-gallery-anchor ${index === imageIndex ? 'active' : ''}`}
                            onClick={() => setImageIndex(index)}
                            title={`Imagem ${index + 1}`}
                            aria-label={`Imagem ${index + 1}`}
                        />
                    ))}
            </div>
            <button type="button" className="product-gallery_nav nav-left" onClick={navigateLeft} disabled={imageIndex <= 0}>
                <FontAwesomeIcon icon={faAngleDoubleLeft} className="mx-2 my-1" />
            </button>
            <button
                type="button"
                className="product-gallery_nav nav-right"
                onClick={navigateRight}
                disabled={imageIndex >= gallery.length - 1}
            >
                <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-2 my-1" />
            </button>
        </div>
    );
}
export default ProductGallery;
