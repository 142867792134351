import React, { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import PlanPositionSelector from '../PlanPositionSelector';
import PlanDisplayEditorContext from '../../PlanDisplayEditorContext';
import PlanThemeSelector from '../PlanThemeSelector';

function PlanEditorToolbar({ plan, children }) {
    const { onDeletePlan } = useContext(PlanDisplayEditorContext);
    return (
        <div className="rsw-toolbar plan-editor-toolbar d-flex flex-wrap">
            <div className="d-flex col-12 align-items-center">
                <PlanPositionSelector plan={plan} className="col-12 flex-shrink-1" />
                <button
                    type="button"
                    className="rsw-btn btn-change-field mx-2"
                    title={`Excluir plano '${plan.name}'`}
                    onClick={() => onDeletePlan(plan.id)}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
            </div>
            <PlanThemeSelector plan={plan} />
            <div className="d-flex flex-wrap" />
            {children}
        </div>
    );
}

export default function PlanEditor({ plan, children, customTools = '', ...props }) {
    const { isChanging } = useContext(PlanDisplayEditorContext);
    return (
        <div {...props} className={`plan-editor ${isChanging ? 'is-changing' : ''}`}>
            {isChanging && <PlanEditorToolbar plan={plan}>{customTools}</PlanEditorToolbar>}
            {children}
        </div>
    );
}
