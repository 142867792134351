import React, { useCallback, useMemo, useState } from 'react';
import Api from '@app/services/Api';
import { URLs } from '@app/constants';
import { toast } from 'react-hot-toast';

import ChangeAddressPopup from './ChangeAddressPopup';

export default function CustomerAddress({
    title = 'Endereço',
    address_field = 'billing_address',
    address = {},
    url = null,
    data = {},
    required = false,
    canChange = false,
    onAfterChange = () => {},
    closeOnSave = true,
    autoUpdateProfile = true,
}) {
    const [selectedAddress, setSelectedAddress] = useState({});
    const [addressPopupIsOpen, setAddressPopupIsOpen] = useState(false);

    // Controles da popup de alteração de preço do produto
    const openAddressPopup = () => {
        setAddressPopupIsOpen(true);
    };
    const closeAddressPopup = () => {
        setAddressPopupIsOpen(false);
    };

    const destUrl = useMemo(() => {
        if (url !== null) return url;
        return URLs.club.account.change_address;
    }, [url]);

    const handleChangeAddress = useCallback(() => {
        setSelectedAddress({
            address_field,
            title,
            address,
        });
        openAddressPopup();
    }, [address_field, title, address]);

    const handleAddressChanged = useCallback(
        (values, setSubmitting) => {
            setSubmitting(true);
            Api({ method: 'post', url: destUrl, data: { ...data, ...values } })
                .then((response) => {
                    if (response?.data && response?.status === 200) {
                        toast.success(response.data?.message || 'Endereço atualizado com sucesso.');
                        onAfterChange(response.data?.newAddress, address_field);
                        if (closeOnSave) closeAddressPopup();
                    } else {
                        toast.error(response?.data?.message || 'Houve um erro ao tentar atualizar o endereço!');
                    }
                    setSubmitting(false);
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar o endereço!');
                    setSubmitting(false);
                });
        },
        [onAfterChange, closeOnSave, address_field, destUrl, data]
    );

    const change_link = useMemo(() => {
        if (!canChange) return null;
        return (
            <>
                {required && (address?.address || null) === null && (
                    <div className="bg-error p-1 br-4 text-center mt-1 text-white text-small">É necessário cadastrar um {title}</div>
                )}
                <a
                    href={0}
                    className="payment-address-change mt-1"
                    title={`${(address?.id || null) !== null ? 'Alterar' : 'Cadastrar'} ${title}`}
                    onClick={(e) => {
                        e.preventDefault();
                        handleChangeAddress();
                    }}
                >
                    {(address?.id || null) !== null ? 'Alterar' : 'Cadastrar'}
                </a>
            </>
        );
    }, [canChange, required, address, handleChangeAddress, title]);

    // Address, Number, Complement
    const line1 = useMemo(
        () =>
            (address?.line_1 || null) !== null
                ? address.line_1
                : [address?.address || '', address?.number || '', address?.complement || ''].filter((value) => value !== '').join(', '),
        [address]
    );
    // ZIP Code
    const line2 = useMemo(() => {
        if ((address?.line_2 || null) !== null) return address.line_2;
        if ((address?.zip || '') === '') return '';
        return `CEP: ${address?.zip}`;
    }, [address]);
    // Neighborhood
    const line3 = useMemo(() => address?.neighborhood || '', [address]);
    // City / State
    const line4 = useMemo(
        () =>
            [address?.city?.name || address?.city || '', address?.city?.state || address?.state || '']
                .filter((value) => value !== '')
                .join('/'),
        [address]
    );

    return (
        <div className="payment-address d-flex text-small flex-column col-12 col-sm-6 px-1">
            <label className="payment-address-title">{title}</label>
            <div className="payment-address-details d-flex flex-column">
                <span className="payment-address-address">{line1 || <>&nbsp;</>}</span>
                <span className="payment-address-address">{line2 || <>&nbsp;</>}</span>
                <span className="payment-address-address">{line3 || <>&nbsp;</>}</span>
                <span className="payment-address-city">{line4 || <>&nbsp;</>}</span>
            </div>
            {change_link}
            <ChangeAddressPopup
                modalIsOpen={addressPopupIsOpen}
                closeModal={closeAddressPopup}
                onSubmit={handleAddressChanged}
                autoUpdateProfile={autoUpdateProfile}
                {...selectedAddress}
            />
        </div>
    );
}
