import React, { useCallback, useMemo, useState } from 'react';
import { Label } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import FieldsDisplay from '../../../FieldsDisplay';
import GroupFieldTable from './GroupFieldTable';
import useFormDisplayValidations from '../../useFormDisplayValidations';
import useFormDisplayInitialValues from '../../useFormDisplayInitialValues';

export default function GroupField({ field, formikProps }) {
    const formDisplayValidations = useFormDisplayValidations;
    const formDisplayInitialValues = useFormDisplayInitialValues;

    const { errors, disabled, hidden, label, renderer } = formikProps;
    const required = (field?.validations || '').indexOf('required') >= 0;

    const getDefaultValues = useCallback(
        (values) => {
            const defaultValues = formDisplayInitialValues(field?.children);
            return { ...defaultValues, ...values };
        },
        [field, formDisplayInitialValues]
    );

    const [values, setValues] = useState(() => {
        const response = {};
        (field?.children || []).forEach((child) => {
            if ((child?.default || null) !== null) response[child.name] = child.default;
        });
        return response;
    });

    const validations = useMemo(() => formDisplayValidations(field?.children || []), [field, formDisplayValidations]);

    const setFieldValue = useCallback((fieldName, value) => setValues((prev) => ({ ...prev, [fieldName]: value })), [setValues]);

    /**
     * File Upload
     */
    const [files, setFiles] = useState({});
    const addNewFile = useCallback((key, fieldName, fieldLabel, fileName, fileType, file) => {
        setFiles((prev) => ({ ...prev, [key]: { key, fieldName, fieldLabel, fileName, fileType, file } }));
    }, []);
    const removeFile = useCallback((fieldName) => setFiles((prev) => ({ ...prev, [fieldName]: undefined })), []);
    // const [files, setFiles] = useState({});
    // const addNewFile = useCallback((fileKey, fieldName, fieldLabel, fileName, fileType, file) => {
    //     setFiles((prev) => ({ ...prev, [String(fileKey)]: { fileKey, fieldName, fieldLabel, fileName, fileType, file } }));
    // }, []);
    // const removeFile = useCallback((fileKey) => setFiles((prev) => ({ ...prev, [fileKey]: undefined })), []);

    const [localErrors, setLocalErrors] = useState({});
    const onAddNewRecord = async () => {
        const newValues = getDefaultValues(values);
        const isValid = await validations.isValid(newValues);

        if (isValid) {
            // Setando valores dos campos
            const newSet = Array.isArray(formikProps.values?.[field.name]) ? formikProps.values?.[field.name] : [];
            newSet.push(newValues);
            formikProps.setFieldValue(field.name, newSet);
            // Adicionando arquivos
            Object.keys(files).forEach((fileId) => {
                if (files[fileId] !== undefined) {
                    const { key, fieldName, fieldLabel, fileName, fileType, file } = files[fileId];
                    formikProps.addNewFile(key, fieldName, fieldLabel, fileName, fileType, file);
                }
            });
            // Limpando dados do grupo
            setValues({});
            setFiles({});
            setLocalErrors({});
        } else {
            validations.validate(newValues, { abortEarly: false }).catch((err) => {
                setLocalErrors(
                    err.inner.reduce(
                        (acc, error) => ({
                            ...acc,
                            [error.path]: error.message,
                        }),
                        {}
                    )
                );
            });
        }
    };

    const onRemoveRecord = (index) => {
        const newSet = Array.isArray(formikProps.values?.[field.name]) ? formikProps.values?.[field.name] : [];
        newSet.splice(index, 1);
        formikProps.setFieldValue(field.name, newSet);
    };

    return (
        <div
            className={`d-flex flex-wrap ${field.style} ${hidden ? 'd-none' : ''}`}
            key={field.name}
            // style={hidden ? { opacity: 0.5 } : {}}
        >
            {(label || '') !== '' && (
                <Label className="field-label" htmlFor="name">
                    {label} - {hidden ? 'hidden' : 'visible'}
                    {required ? <em>*</em> : ''}
                </Label>
            )}
            <div className="col-12 d-flex">
                <div className="col-12 d-flex flex-shrink-1 flex-wrap">
                    <FieldsDisplay
                        fields={field.children}
                        renderer={renderer}
                        disabled={disabled}
                        props={{ ...formikProps, setFieldValue, addNewFile, removeFile, values, errors: localErrors }}
                    />
                </div>
                <div className="d-flex flex-column justify-content-start py-1">
                    <Label className="field-label mb-2" htmlFor="name">
                        &nbsp;
                    </Label>
                    <button
                        type="button"
                        className="btn btn-primary"
                        name="btn-add-record"
                        title="Adicionar dados do registro"
                        onClick={onAddNewRecord}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            </div>
            <GroupFieldTable
                fields={(field?.children || []).filter((field) => field?.type !== 'separator')}
                records={formikProps.values?.[field.name] || []}
                onRemoveRecord={onRemoveRecord}
            />
            {errors?.[field.name] && <span className="col-12 text-error text-small px-2">{errors?.[field.name]}</span>}
        </div>
    );
}
