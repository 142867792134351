import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import PrivateRoute from '@app/routes/PrivateRoute';

import { useUserManager } from '@app/hooks';
import { DRCAContext, SectionContext } from '@app/contexts';
import AppHeader from '../shared/AppHeader/AppHeader';
import { PublicSidebar } from '../shared/SideBar';
import AppFooter from '../shared/AppFooter/AppFooter';

import Cart from './cart/cart';
import OrderList from './orders/order_list';
import Order from './orders/order';

import Certificates from './certificates/certificates';
import NotFound from '../not_found/not_fund';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@app/assets/styles/app.scss';
import Notifications from '../shared/Notifications/Notifications';
import Account from './account';
import Api from '../../services/Api';
import { URLs } from '../../constants';
import DynamicSection from './DynamicSection';
import ProductPage from './product';
import Loading from '../loading';
import Subscription from './subscription';
import SubscriptionCheckout from './subscription_checkout';
import OrderCheckout from './order_checkout';

export default function MainClub() {
    const DRCA = useContext(DRCAContext);
    const UserManager = useUserManager();

    const { updateSummary, checkPermission } = useUserManager();

    const [sideBarIsClosed, setSideBarIsClosed] = useState(window.screen.width < 992);
    const toggleSideBar = () => {
        setSideBarIsClosed((prev) => !prev);
    };

    const location = useLocation();

    const [sections, setSections] = useState([]);

    const sectionRoutes = useMemo(() => {
        const getRoutes = (sections) => {
            if (sections.length === 0) return [];
            return sections
                .filter((section) => ['dynamic', 'group'].includes(section.type))
                .map((section, index) => [
                    <PrivateRoute
                        key={index}
                        exact
                        path={section?.url}
                        render={() => (section.type === 'group' ? <NotFound /> : <DynamicSection section={section} />)}
                    />,
                    ...getRoutes(section?.subsections || []),
                ]);
        };
        const routes = getRoutes(sections);
        return routes;
    }, [sections]);

    const currentSection = useMemo(() => {
        const filterSections = (sections, url) => {
            let response = null;
            sections.forEach((section) => {
                if (section.subsections.length > 0) {
                    response = filterSections(section.subsections, url) || response;
                } else if (url.startsWith(section.url)) {
                    response = section;
                }
            });
            return response;
        };
        const url = location.pathname.replaceAll('/admin', '');
        const section = filterSections(sections, url);
        return section;
    }, [sections, location.pathname]);

    const loadMenuData = useCallback(() => {
        if ((sections || []).length > 0) return;
        Api({ url: URLs.club.sections.index }).then(({ data }) => {
            setSections(data.data);
        });
    }, [sections]);

    useEffect(() => {
        UserManager.updatePendenciesStatus();
        loadMenuData();
        updateSummary();
    }, [updateSummary, loadMenuData, UserManager]);

    useEffect(() => {
        window.scrollTo(0, 0);
        DRCA.pageview(location.pathname, currentSection?.token || null);
    }, [DRCA, location, currentSection]);

    return (
        <SectionContext.Provider
            value={{
                sections,
                currentSection,
                pathname: location.pathname.replaceAll('/admin', ''),
                checkPermission,
                sideBarIsClosed,
            }}
        >
            <AppHeader toggleSideBar={toggleSideBar} sideBarIsClosed={sideBarIsClosed} />
            <section className={`sidebar-${sideBarIsClosed ? 'closed' : 'open'}`}>
                <PublicSidebar toggleSideBar={toggleSideBar} isClosed={sideBarIsClosed} />
                <Switch>
                    <PrivateRoute exact path="/">
                        <Redirect to="/home" />
                    </PrivateRoute>

                    <PrivateRoute exact path="/carrinho" component={Cart} />
                    <PrivateRoute exact path="/pedidos" component={OrderList} />
                    <PrivateRoute path="/pedido/:token" component={Order} />
                    <PrivateRoute path="/checkout/:token" component={OrderCheckout} />

                    <PrivateRoute exact path="/certificados" render={() => <Certificates />} />
                    <PrivateRoute exact path="/certificados/novo" component={Certificates} />
                    <PrivateRoute exact path="/certificados/:token/documentos" component={Certificates} />
                    <PrivateRoute exact path="/certificados/:token/agendamento" component={Certificates} />

                    <PrivateRoute exact path="/assinatura/checkout/:token" component={SubscriptionCheckout} />
                    <PrivateRoute exact path="/assinatura/:token/:model" component={Subscription} />

                    <PrivateRoute exact path="/produto/:token" component={ProductPage} />

                    <PrivateRoute exact path="/notificacoes" render={() => <Notifications section={currentSection?.token} />} />
                    <PrivateRoute path="/conta" render={() => <Account section={currentSection?.token} />} />

                    {sectionRoutes}

                    {sections.length > 0 ? <PrivateRoute component={NotFound} /> : <PrivateRoute component={Loading} />}
                </Switch>
            </section>
            <AppFooter sideBarIsClosed={sideBarIsClosed} />
        </SectionContext.Provider>
    );
}
