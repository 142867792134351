import React from 'react';
import './dynamic-section-editor.scss';
import { useFormikContext } from 'formik';
import { Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faPallet, faPen, faTable } from '@fortawesome/free-solid-svg-icons';
import ContentEditor from './ContentEditor';

function DynamicSectionTools({ isLoading, ...props }) {
    const { values, setValues, isSubmitting } = useFormikContext();

    const addNewHTMLContent = () => {
        setValues((prev) => ({
            ...prev,
            content: [
                ...prev.content,
                {
                    id: new Date().getTime(),
                    type: 'html',
                    order: (values?.content || []).length,
                    className: 'col-12',
                    notify: '',
                    html: '<h2 style="text-align: center;">clique para editar</h2>',
                },
            ],
        }));
    };

    const addNewProductDisplay = () => {
        setValues((prev) => ({
            ...prev,
            content: [
                ...prev.content,
                {
                    id: new Date().getTime(),
                    type: 'product-display',
                    order: (values?.content || []).length,
                    displayType: 'mat',
                    displayId: `pd-${new Date().getTime()}`,
                    className: 'justify-content-around col-12',
                    notify: '',
                    filter: {
                        by: 'aleatory',
                        limit: 1,
                        criteria: [10, 19, 18, 17, 16],
                        order: '',
                    },
                },
            ],
        }));
    };

    const addNewSubscriptionPlanDisplay = () => {
        setValues((prev) => ({
            ...prev,
            content: [
                ...prev.content,
                {
                    id: new Date().getTime(),
                    type: 'subscription-plan-display',
                    order: (values?.content || []).length,
                    displayType: 'default',
                    displayId: `pd-${new Date().getTime()}`,
                    className: 'justify-content-around col-12',
                    notify: '',
                    filter: {
                        plans: [],
                        by: 'id',
                    },
                },
            ],
        }));
    };

    const addNewFormDisplay = () => {
        const id = new Date().getTime();
        setValues((prev) => ({
            ...prev,
            content: [
                ...prev.content,
                {
                    id,
                    name: `Formulário ${id}`,
                    type: 'form',
                    order: (values?.content || []).length,
                    className: 'justify-content-around col-12',
                    notify: '',
                    fields: [],
                },
            ],
        }));
    };

    return (
        <div {...props} className={`col-12 d-flex flex-wrap dynamic-section-tools ${props?.className || ''}`}>
            <div className="p-1 col-12 col-md-6 col-lg-2">
                <Button
                    type="button"
                    title="Incluir novo conteúdo de texto"
                    className="col-12"
                    color="secondary"
                    disabled={isSubmitting || isLoading}
                    onClick={addNewHTMLContent}
                >
                    <FontAwesomeIcon icon={faCode} className="mx-1" />
                    TEXTO
                </Button>
            </div>
            <div className="p-1 col-12 col-md-6 col-lg-2">
                <Button
                    type="button"
                    title="Incluir novo expositor de produtos"
                    className="col-12"
                    color="secondary"
                    disabled={isSubmitting || isLoading}
                    onClick={addNewProductDisplay}
                >
                    <FontAwesomeIcon icon={faPallet} className="mx-1" />
                    PRODUTOS
                </Button>
            </div>
            <div className="p-1 col-12 col-md-6 col-lg-2">
                <Button
                    type="button"
                    title="Incluir novo formulário"
                    className="col-12"
                    color="secondary"
                    disabled={isSubmitting || isLoading}
                    onClick={addNewFormDisplay}
                >
                    <FontAwesomeIcon icon={faTable} className="mx-1" />
                    FORMULÁRIO
                </Button>
            </div>
            <div className="p-1 col-12 col-md-6 col-lg-2">
                <Button
                    type="button"
                    title="Incluir novo expositor de assinaturas"
                    className="col-12"
                    color="secondary"
                    disabled={isSubmitting || isLoading}
                    onClick={addNewSubscriptionPlanDisplay}
                >
                    <FontAwesomeIcon icon={faPen} className="mx-1" />
                    ASSINATURAS
                </Button>
            </div>
        </div>
    );
}

function DynamicSectionEditor({ isLoading, ...props }) {
    const {
        values: { content },
    } = useFormikContext();

    return (
        <div className="dynamic-section-editor d-flex flex-wrap mb-1 col-12 px-0" {...props}>
            <DynamicSectionTools isLoading={isLoading} />
            {(content || []).map((content, index) => (
                <ContentEditor key={index} content={content} />
            ))}
            <h3 className="field-group-title col-12 mt-3">&nbsp;</h3>
        </div>
    );
}
export default DynamicSectionEditor;
