import React, { useContext } from 'react';

import FormEditorContext from '../../FormEditorContext';
import './FieldPositionSelector.scss';

export default function FieldPositionSelector({ field }) {
    const { content, onApplyFieldChanges } = useContext(FormEditorContext);

    const onChangePosition = (e) => {
        const order = parseInt(e.target.value);
        onApplyFieldChanges({ ...field, order });
    };

    return (
        <div className="field-position-selector d-flex flex-wrap px-1">
            <select name="order" value={field.order} onChange={onChangePosition} className="col-12 flex-shrink-1">
                {(() => {
                    const options = [];
                    const { length } = content.fields;
                    content.fields.forEach((item, index) => {
                        const after = index === field.order ? '(atual)' : ` (${item.label})`;
                        options.push(
                            <option key={index} value={index}>
                                {index + 1}&ordf; posição {after}{' '}
                            </option>
                        );
                    });
                    if (field?.order === length)
                        options.push(
                            <option key={length} value={length}>
                                {length + 1}&ordf; posição
                            </option>
                        );
                    return options;
                })()}
            </select>
        </div>
    );
}
