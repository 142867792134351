import React, { useContext } from 'react';
import { Product } from '@app/components/Products';
import ProductDisplayContext from './ProductDisplayContext';

import './CardsDisplay.scss';

function CardsDisplay({ productClassName, ...props }) {
    const { products } = useContext(ProductDisplayContext);

    return (
        <div {...props} className={`product-display cards-display d-flex flex-wrap justify-content-center ${props?.className || ''}`}>
            {products.length > 0 ? (
                products?.map((product, id) => {
                    const show = !product?.user_has || (product?.user_has && !product?.extras?.hide_after_buy);
                    return (
                        show && (
                            <div key={id} className={`mb-2 ${productClassName || ''}`}>
                                <Product className="h-100" key={id} data={product} />
                            </div>
                        )
                    );
                })
            ) : (
                <h3 className="text-center align-self-center">Nenhum produto disponível</h3>
            )}
        </div>
    );
}
export default CardsDisplay;
