import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import { Form } from 'reactstrap';
import { useFetch } from '@app/hooks/useFetch';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';

import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import CheckPermission from '@app/components/CheckPermission';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toastConfirmation } from '@app/components/ui';
import SubscriptionPlansContext from './components/SubscriptionPlansContext';

import PlansGroupsTable from './tables/PlansGroupsTable';
import PlansGroupPopup from './popups/PlansGroupPopup';

function Plans() {
    const replaceParams = useReplaceParams;
    const history = useHistory();
    /**
     * Consulta dados de produtos
     */
    const [params, setParams] = useState({
        nocache: new Date().getTime(),
        name: '',
        pg: 1,
        orderBy: '',
        orderDir: 'asc',
    });
    const { data, error } = useFetch(replaceParams(URLs.admin.subscriptions.groups.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
    }, [data, error]);
    const updateRequest = () => setParams((prev) => ({ ...prev, nocache: new Date().getTime() }));

    /**
     * Filtros de pesquisa
     */
    const handleNameChange = (e) => setParams((prev) => ({ ...prev, ...{ name: e.target.value, pg: 1 } }));
    const handleOrderBy = (orderBy, orderDir) => setParams((prev) => ({ ...prev, ...{ orderBy, orderDir, pg: 1 } }));

    /**
     * GROUPS
     */
    const handlePlanGroupStateChange = (e) => {
        const url = replaceParams(URLs.admin.subscriptions.groups.status, { token: e.data.token });
        Api({ method: 'put', url, data: { status: e.data.checked ? 1 : 0 } })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status do produto.');
                    e.data.setStatus(!e.data.checked);
                } else {
                    toast.success(response?.data?.message);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status do produto!');
                e.data.setStatus(!e.data.checked);
            });
    };

    const handlePlanGroupDelete = (token, groupName = '') => {
        toastConfirmation(
            () => {
                const url = replaceParams(URLs.admin.subscriptions.groups.delete, { token });
                Api({ method: 'delete', url })
                    .then(({ data, status }) => {
                        if (!data || status !== 200) {
                            toast.error(data?.message || 'Erro ao tentar excluir o grupo.');
                        } else {
                            toast.success(data?.message);
                            updateRequest();
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response?.data?.message || 'Houve um erro ao tentar excluir o grupo!');
                    });
            },
            <span>Tem certeza que deseja excluir o grupo {groupName}?</span>
        );
    };

    const [modalParams, setModalParams] = useState({ modalIsOpen: false, group: {} });
    const openChangePlanGroupPopup = (group) => {
        setModalParams({ modalIsOpen: true, group });
    };
    const onNewGroup = (e) => {
        e.preventDefault();
        setModalParams({ modalIsOpen: true, group: {} });
    };
    const onCloseGroupModal = () => {
        setModalParams({ modalIsOpen: false, group: {} });
    };

    /**
     * PLANS
     */
    const handlePlanStateChange = (e) => {
        const url = replaceParams(URLs.admin.subscriptions.plans.status, { token: e.data.token });
        Api({ method: 'put', url, data: { status: e.data.checked ? 1 : 0 } })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status do produto.');
                    e.data.setStatus(!e.data.checked);
                } else {
                    toast.success(response?.data?.message);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status do produto!');
                e.data.setStatus(!e.data.checked);
            });
    };

    const handleDuplicatePlan = (token) => {
        const url = replaceParams(URLs.admin.subscriptions.plans.duplicate, { token });
        Api({ method: 'post', url })
            .then(({ data, status }) => {
                if (!data || status !== 200) {
                    toast.error(data?.message || 'Erro ao tentar duplicar o plano.');
                } else {
                    toast.success(data?.message);
                    history.push(`/admin/planos/${data?.group_id}/alterar/${data?.token}`);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar duplicar o plano!');
            });
    };

    const handleDeletePlan = (token, planName = '') => {
        toastConfirmation(
            () => {
                const url = replaceParams(URLs.admin.subscriptions.plans.delete, { token });
                Api({ method: 'delete', url })
                    .then(({ data, status }) => {
                        if (!data || status !== 200) {
                            toast.error(data?.message || 'Erro ao tentar excluir o plano.');
                        } else {
                            toast.success(data?.message);
                            updateRequest();
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response?.data?.message || 'Houve um erro ao tentar duplicar o plano!');
                    });
            },
            <span>Tem certeza que deseja excluir o plano {planName}?</span>
        );
    };

    return (
        <ContentWrapper>
            <FilterContainer title="Filtros">
                <FilterFields>
                    <Form className="d-flex mb-3 flex-wrap">
                        <fieldset className="col-3">
                            <label htmlFor="accessName">Nome</label>
                            <input type="text" name="accessName" value={params.name} onChange={handleNameChange} />
                        </fieldset>
                        <CheckPermission permission="create-group">
                            <fieldset className="">
                                <button type="button" className="btn btn-primary btn-medium btn-icon-left " onClick={onNewGroup}>
                                    <FontAwesomeIcon icon={faPlus} className="mx-2" />
                                    Novo Grupo
                                </button>
                            </fieldset>
                        </CheckPermission>
                        <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <SubscriptionPlansContext.Provider
                value={{
                    handlePlanGroupDelete,
                    handlePlanGroupStateChange,
                    handlePlanGroupChange: openChangePlanGroupPopup,

                    handlePlanStateChange,
                    handleDuplicatePlan,
                    handleDeletePlan,
                }}
            >
                <div className="page-content">
                    {(data || []).length > 0 ? (
                        (data || []).map((group, index) => <PlansGroupsTable key={index} data={group} handleOrderBy={handleOrderBy} />)
                    ) : (
                        <div className="col-12">
                            <p className="text-center">Nenhum grupo encontrado. Crie um novo grupo para seus planos de assinatura.</p>
                        </div>
                    )}
                </div>
                <PlansGroupPopup {...modalParams} closeModal={onCloseGroupModal} updateRequest={updateRequest} />
            </SubscriptionPlansContext.Provider>
        </ContentWrapper>
    );
}

export default Plans;
