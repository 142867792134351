import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUserManager } from '@app/hooks/useUserManager';

function PrivateRoute({ ...props }) {
    const UserManager = useUserManager();

    const { isLogged } = UserManager;
    return isLogged ? <Route {...props} params={props?.params} /> : <Redirect to="/login" />;
}

export default PrivateRoute;
