import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';
import { Link } from 'react-router-dom';

const getTextStatusColorClass = (status) => {
    switch (status) {
        case 'error':
            return 'text-error fw-bold';
        case 'processed':
            return 'text-success fw-bold';
        case 'ignored':
            return 'text-alert fw-bold';
        case 'postponed':
            return 'text-info fw-bold';
        default:
            return '';
    }
};

export default function EventsTable({ data, handleOrderBy }) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <Tr handleOrderBy={handleOrderBy || null}>
                    <Th param="status">Status</Th>
                    <Th param="created_at">Recebido em</Th>
                    <th>Contatos</th>
                    <th>Mensagens</th>
                    <th className="text-center">&nbsp;</th>
                </Tr>
            </thead>
            <tbody>
                {(data || [])?.length > 0 ? (
                    data.map((record, index) => (
                        <tr key={index}>
                            <td className={`${getTextStatusColorClass(record?.status || '')}`}>
                                {record?.status_label || ''}
                                {(record?.status_message || '') === '' ? null : (
                                    <>
                                        <br />
                                        <span className="text-red text-small fw-normal col-12">{record.status_message}</span>
                                    </>
                                )}
                            </td>
                            <td>{record?.created_at || ''}</td>
                            <td>
                                {(record?.contacts || []).map((contact) => (
                                    <span className="badge badge-primary">
                                        {contact.name} - {contact.wa_id}
                                    </span>
                                ))}
                            </td>
                            <td>
                                {(record?.messages || []).map((message) => (
                                    <span className="badge badge-primary">{message.type}</span>
                                ))}
                            </td>
                            <td align="center">
                                <Link to={`/admin/whatsapp/${record?.id || ''}`} className="text-primary" title="ver detalhes do evento">
                                    <FontAwesomeIcon icon={faEye} className="mr-2" />
                                </Link>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={5}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
