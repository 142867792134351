import { ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import { useFetch } from '@app/hooks/useFetch';
import { URLs } from '@app/constants';

function FormSliceSelectVideo({ isLoading }) {
    const { values, setValues } = useFormikContext();
    const videosData = useFetch(URLs.admin.products.videos);

    const handleProductVideoChange = (value) => {
        setValues((prev) => ({
            ...prev,
            private: {
                ...prev.private,
                video_url: value,
            },
        }));
    };

    return (
        <>
            <h3 className="field-group-title col-12 mt-3">Selecionar vídeo</h3>
            <div className="mb-1 px-1 col-12 col-sm-6">
                <select
                    name="private.video_url"
                    className="col-12"
                    title={values?.private?.video_url || ''}
                    value={values?.private?.video_url || ''}
                    onChange={(e) => handleProductVideoChange(e.target.value)}
                    disabled={isLoading}
                >
                    <option value="">Selecione um dos vídeos disponíveis</option>
                    {videosData?.data?.map &&
                        videosData.data.map((item, key) => (
                            <option key={key} value={item.uri}>
                                {item.name}
                            </option>
                        ))}
                </select>
                <ErrorMessage component="span" name="section_id" className="text-error text-small" />
            </div>
        </>
    );
}

export default FormSliceSelectVideo;
