import React, { useState, useEffect } from 'react';
import { Button, Form } from 'reactstrap';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import FilterContainer, { FilterFields, FilterTabs, FilterTab } from '@app/containers/FilterContainer/FilterContainer';
import { URLs, useReplaceParams } from '@app/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useUserManager } from '@app/hooks/useUserManager';
import Paginator from '@app/components/Paginator/Paginator';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import { useFetch } from '@app/hooks/useFetch';

import FileDownloader from '@app/components/FileDownloader/FileDownloader';
import UserProfilesForm from './popup/UserProfilesForm';
import UserProfilesTable from './table/UserProfilesTable';
// import UserProfilesBlock from "./popup/UserProfilesBlock";

function UserProfiles() {
    const replaceParams = useReplaceParams;
    const UserManager = useUserManager();

    const [params, setParams] = useState({
        name: '',
        status: '',
        nocache: new Date().getTime(),
        pg: 1,
        orderBy: '',
        orderDir: 'asc',
    });
    const { data, error, last_page } = useFetch(replaceParams(URLs.admin.user_profiles.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
    }, [data, error]);

    const [formModalIsOpen, setFormModalIsOpen] = useState(false);
    const [formModalTitle, setModalTitle] = useState('Cadastrar perfil de usuários');

    const [userProfileData, setUserProfileData] = useState({});

    const updateRequest = () => setParams({ ...params, ...{ nocache: new Date().getTime() } });

    /**
     * Configuração de busca
     */
    const onSearchByNameChange = (e) => setParams({ ...params, ...{ name: e.target.value, pg: 1 } });
    const handleOrderBy = (orderBy, orderDir) => setParams({ ...params, ...{ orderBy, orderDir, pg: 1 } });

    const handleNavigateFirst = () => setParams({ ...params, ...{ pg: 1 } });
    const handleNavigateLast = () => setParams({ ...params, ...{ pg: last_page } });
    const handleNavigateNext = () => setParams({ ...params, ...{ pg: data?.length > 0 ? params.pg + 1 : params.pg } });
    const handleNavigatePrevious = () => setParams({ ...params, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } });

    /**
     * Formulário de cadastro de registro
     */
    const openFormModal = () => {
        setFormModalIsOpen(true);
    };
    const closeFormModal = () => {
        setFormModalIsOpen(false);
    };
    const handleNewUserProfile = (e) => {
        e.preventDefault();
        setUserProfileData({});
        setModalTitle('Criar perfil de usuário');
        openFormModal();
    };
    const handleUpdateUserProfile = (userProfile) => {
        setUserProfileData(userProfile);
        setModalTitle('Editar perfil de usuário');
        openFormModal();
    };

    return (
        <ContentWrapper title="Tipos de Usuário">
            <FilterContainer showTitle={false} className="show-border">
                <FilterFields />
                <FilterTabs active="users">
                    <FilterTab key="usuarios" to="/usuarios">
                        Usuários
                    </FilterTab>
                    <FilterTab key="tipos" to="/usuarios/tipos">
                        Tipos
                    </FilterTab>
                </FilterTabs>
            </FilterContainer>
            <FilterContainer title="">
                <FilterFields className="col-12">
                    <Form className="login d-flex mb-3 col-12 ">
                        <fieldset className="col-4">
                            <label htmlFor="filter-name">Nome do perfil:</label>
                            <input type="text" name="filter-name" value={params.name} onChange={onSearchByNameChange} />
                        </fieldset>
                        <fieldset className="">
                            {!UserManager.checkPermission('user_profiles') ? (
                                ''
                            ) : (
                                <Button
                                    type="submit"
                                    onClick={handleNewUserProfile}
                                    outline
                                    color="custom"
                                    className="btn btn-primary btn-icon-left"
                                >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                    Novo Perfil
                                </Button>
                            )}
                        </fieldset>
                        <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <div className="page-content">
                <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                    {URLs.admin.user_profiles.export && (
                        <div className="d-flex">
                            <FileDownloader
                                url={replaceParams(URLs.admin.user_profiles.export, { ...params, ...{ ext: 'pdf' } })}
                                filename="perfis_de_usuarios"
                                label="Exportar PDF"
                                extension="pdf"
                            />
                            <FileDownloader
                                url={replaceParams(URLs.admin.user_profiles.export, { ...params, ...{ ext: 'csv' } })}
                                filename="perfis_de_usuarios"
                                label="Exportar CSV"
                                extension="csv"
                                className="mx-2"
                            />
                        </div>
                    )}
                </div>
                <UserProfilesTable
                    data={data}
                    permissions={UserManager.permissions}
                    handleOrderBy={handleOrderBy}
                    openModal={openFormModal}
                    handleNewRecord={handleNewUserProfile}
                    handleUpdateRecord={handleUpdateUserProfile}
                />
                <Paginator
                    pg={params.pg}
                    last_page={last_page}
                    hasPrevious={params.pg > 1}
                    hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                    handleNavigateFirst={handleNavigateFirst}
                    handleNavigateNext={handleNavigateNext}
                    handleNavigatePrevious={handleNavigatePrevious}
                    handleNavigateLast={handleNavigateLast}
                />
                <UserProfilesForm
                    updateRequest={updateRequest}
                    modalIsOpen={formModalIsOpen}
                    userProfileData={userProfileData}
                    closeModal={closeFormModal}
                    title={formModalTitle}
                />
            </div>
        </ContentWrapper>
    );
}

export default UserProfiles;
