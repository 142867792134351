import React, { useEffect, useMemo, useState } from 'react';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import './PlanDisplay.scss';
import DefaultPlanDisplay from './DefaultPlanDisplay';
import PlansDisplayContext from './PlansDisplayContext';
import PlanDisplayPlanRenderer from './Renderer/PlanDisplayPlanRenderer';

export default function PlanDisplay({
    filter = {},
    productClassName = '',
    type = '',
    Renderer = PlanDisplayPlanRenderer,
    blockControls = false,
    ...props
}) {
    const [plans, setPlans] = useState([]);
    const replaceParams = useReplaceParams;
    useEffect(() => {
        const { by, plans } = filter;
        const criteria = (plans || []).map((plan) => plan?.[by] || '').join(',');
        if (criteria.length === 0) {
            setPlans([]);
            return;
        }
        // Consulta os produtos da sessão que serão exibidos no expositor
        Api({ method: 'get', url: replaceParams(URLs.club.sections.subscriptions.plans.list, { by, criteria }) })
            .then((response) => {
                if (response.data && response.status === 200) {
                    setPlans(response.data);
                }
            })
            .catch(() => {});
    }, [filter, replaceParams]);

    const display = useMemo(() => {
        switch (type) {
            default: // default
                return <DefaultPlanDisplay {...props} planClassName={productClassName} />;
        }
    }, [type, productClassName, props]);

    return (
        <PlansDisplayContext.Provider
            value={{
                plans,
                blockControls,
                themes: (filter?.plans || []).map((plan) => plan?.theme || {}),
                Renderer,
            }}
        >
            {display}
        </PlansDisplayContext.Provider>
    );
}
