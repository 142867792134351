import React, { useContext } from 'react';
import ReactInputMask from 'react-input-mask';
import FormEditorFieldPopupContext from '../../FormEditorFieldPopupContext';

function MaskedFieldDefaultSelector() {
    const { values, setFieldDefaultValue } = useContext(FormEditorFieldPopupContext);

    return (
        <>
            <label className="text-small pl-1">Valor padrão:</label>
            <ReactInputMask
                name="default"
                type="text"
                className="col-12"
                value={values?.default || ''}
                onChange={({ target: { value } }) => {
                    setFieldDefaultValue(value);
                }}
                mask={values.mask}
            />
        </>
    );
}
export default MaskedFieldDefaultSelector;
