import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

function CertificateStatusLabel({ certificate }) {
    const style = {
        backgroundColor: certificate.status_color?.color ?? 'inherit',
        color: certificate.status_color?.contrast ?? 'inherit',
    };
    const content = useMemo(() => {
        switch (certificate.status) {
            case 0:
                return (
                    <NavLink
                        tag={Link}
                        to={{
                            pathname: `certificados/${certificate.token}/documentos`,
                        }}
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                    >
                        {certificate.status_label}
                    </NavLink>
                );
            case 2:
                return (
                    <NavLink
                        tag={Link}
                        to={{
                            pathname: `certificados/${certificate.token}/documentos`,
                        }}
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                    >
                        {certificate.status_label}
                    </NavLink>
                );
            case 3:
                return (
                    <NavLink
                        tag={Link}
                        to={{
                            pathname: `certificados/${certificate.token}/agendamento`,
                        }}
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                    >
                        {certificate.status_label}
                    </NavLink>
                );
            case 4:
                return (
                    <label style={{ color: 'inherit' }}>
                        {certificate.status_label} para {certificate?.calendar?.datetime || ''}
                    </label>
                );
            default:
                return <label style={{ color: 'inherit' }}>{certificate.status_label}</label>;
        }
    }, [certificate]);

    return (
        <h2 className="text-medium m-0 w-100 p-2 text-center" style={style}>
            {content}
        </h2>
    );
}
export default function Certificate({ certificate, ...props }) {
    return (
        <div className={`card col-12 text-medium p-1 outer-shadow ${props?.className}`}>
            <div className="card-body col-12 flex-row d-flex py-2 m-0">
                <div className="flex-col col-2 d-flex flex-column px-2 justify-content-center align-items-center">
                    <label className="text-center fw-bold">TIPO</label>
                    <label className="text-center fw-bold">A1</label>
                </div>
                <div className="flex-col col-5 d-flex flex-column px-2">
                    <label className="text-center fw-bold">{certificate?.name}</label>
                    <label className="text-small badge badge-secondary">{certificate?.cnpj}</label>
                </div>
                <div className="flex-col col-5 d-flex flex-column px-2">
                    <label className="text-center fw-bold">Solicitado em:</label>
                    <label className="text-small badge badge-secondary">{certificate?.request_date}</label>
                </div>
            </div>
            <div className="card-footer col-12 flex-row d-flex p-0 m-0">
                <CertificateStatusLabel certificate={certificate} />
            </div>
        </div>
    );
}
