import React from 'react';
import { Label } from 'reactstrap';
import { CurrencyInputField } from '@app/components/ui';

function FloatField({
    field,
    formikProps: {
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        disabled = false,
        hidden = false,
        required = false,
        label = '',
        placeholder = '',
    },
}) {
    return (
        <div className={`${field.style} ${hidden ? 'd-none' : ''}`} key={field.name}>
            <Label className="field-label" htmlFor="name">
                {label}
                {required ? <em>*</em> : ''}
            </Label>
            <CurrencyInputField
                name={field.name}
                type="text"
                placeholder={placeholder}
                className="col-12 text-right"
                disabled={disabled || isSubmitting}
                value={values?.[field.name] || ''}
                onAfterChange={({ target: { floatValue } }) => {
                    setFieldTouched(field.name);
                    setFieldValue(field.name, floatValue, true);
                }}
                onBlur={() => {
                    setFieldTouched(field.name);
                }}
            />
            {errors?.[field.name] && <span className="col-12 text-error text-small px-2">{errors?.[field.name]}</span>}
        </div>
    );
}

export default FloatField;
