import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import { URLs, useSetParams } from '@app/constants';
import Api from '@app/services/Api';
import TrafficContext from '../TrafficContext';

export default function TrafficRelationshipTable({ className = '' }) {
    const { selectedUrl, startDate, endDate } = useContext(TrafficContext);
    const setParams = useSetParams;
    const [data, setData] = useState(null);
    const [days] = useState(7);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedCol, setSelectedCol] = useState(null);

    const [queryParams, setQueryParams] = useState({
        start: moment(startDate).format('yyyy/MM/DD'),
        end: moment(endDate).format('yyyy/MM/DD'),
        orderBy: 'qtd',
        orderDir: 'desc',
    });

    useEffect(() => {
        setQueryParams((prev) => ({
            ...prev,
            start: moment(startDate).format('yyyy/MM/DD'),
            end: moment(endDate).format('yyyy/MM/DD'),
        }));
    }, [startDate, endDate, selectedUrl]);

    useEffect(() => {
        const url = setParams(URLs.admin.traffic.relationship, queryParams);
        Api({ method: 'get', url }).then(({ data }) => {
            setData(data);
        });
    }, [setParams, days, queryParams]);

    const matrix = useMemo(() => {
        if (!data || data.length === 0) return { max: 0, data: {} };
        const urls = data.map((item) => item.url);
        const response = { max: 0, data: {} };
        urls.forEach((url) => {
            response.data[url] = {};
            urls.forEach((url2) => {
                response.data[url][url2] = 0;
            });
        });
        data.forEach((item) => {
            item.related.forEach((related) => {
                response.max = Math.max(response.max, related.qtd);
                response.data[item.url][related.url] = item.url === related.url ? null : related.qtd;
            });
        });
        return response;
    }, [data]);

    const selectRow = (index) => {
        setSelectedRow((prev) => (prev === index ? null : index));
    };
    const selectCol = (index) => {
        setSelectedCol((prev) => (prev === index ? null : index));
    };

    return (
        <div className={className}>
            <h3 className="text-center">Fluxo entre páginas</h3>
            <Table bordered hover col="12" className="text-small table-responsive">
                <thead>
                    <tr>
                        <th colSpan={2}>&nbsp;</th>
                        <th colSpan={Object.keys(matrix.data).length} className="text-center">
                            DESTINO
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={2} className="text-center">
                            ORIGEM
                        </th>
                        {Object.keys(matrix.data).map((key, index) => (
                            <th
                                style={{ fontSize: '10px', maxWidth: '15px', cursor: 'pointer' }}
                                title={`Clique para selecionar a coluna ${key}`}
                                className={`text-xenter ${selectedCol === index ? 'bg-warning' : ''}`}
                                onClick={() => selectCol(index)}
                            >
                                #{index}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(matrix.data).map((rowKey, rowIndex) => {
                        const row = matrix.data[rowKey];
                        const rowBorder =
                            selectedRow === rowIndex ? { borderBottom: 'solid 2px #ffc107', borderTop: 'solid 2px #ffc107' } : {};
                        return (
                            <tr key={rowIndex} style={{ ...rowBorder }}>
                                <td data-title="Origem" className={`text-xenter ${selectedRow === rowIndex ? 'bg-warning' : ''}`}>
                                    <div role="button" tabIndex={0} onClick={() => selectRow(rowIndex)}>
                                        {rowKey}
                                    </div>
                                </td>
                                <td data-title="Índice" align="center" style={{ fontSize: '10px', maxWidth: '15px', cursor: 'default' }}>
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => selectCol(rowIndex)}
                                        style={{ cursor: 'pointer' }}
                                        title={`Clique para selecionar a coluna ${rowKey}`}
                                    >
                                        #{rowIndex}
                                    </div>
                                </td>
                                {Object.keys(row).map((colKey, colIndex) => {
                                    const opacity = row[colKey] / matrix.max;
                                    const title = `De: "${rowKey}"\nPara: "${colKey}"`;
                                    const diagonal = rowIndex === colIndex;
                                    const backgroundColor = diagonal ? '#2c536a' : `rgb(0 161 21 / ${opacity})`;
                                    const border =
                                        selectedCol === colIndex
                                            ? { borderRight: 'solid 2px #ffc107', borderLeft: 'solid 2px #ffc107' }
                                            : {};
                                    const fontSize = '10px';
                                    return (
                                        <td
                                            align="center"
                                            key={colIndex}
                                            title={title}
                                            data-title={colKey}
                                            style={{ backgroundColor, fontSize, ...border, cursor: 'default' }}
                                        >
                                            {diagonal ? '' : row[colKey]}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}
