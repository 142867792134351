import React from 'react';

import { useLocation, Link } from 'react-router-dom';
import { Navbar, NavbarBrand, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import transprolog_logo from '@app/assets/images/logo_transprolog.png';
import drc_logo from '@app/assets/images/drc_logo_escuro_interno.svg';

import { useUserManager } from '@app/hooks/useUserManager';

import { faHome, faArchive, faSignOutAlt, faShoppingCart, faBell } from '@fortawesome/free-solid-svg-icons';

import { useSelector } from 'react-redux';
import CartFloatingButton from './CartFloatingButton';

export default function AppHeader() {
    const UserManager = useUserManager();
    const context = UserManager.context === 'admin' ? '/admin' : '';
    const location = useLocation();

    const summary = useSelector((state) => state.summary);

    function handleLogout() {
        UserManager.logout();
    }

    return (
        <header>
            <Navbar color="" className="d-flex justify-content-between">
                <NavbarBrand href={context} className="mx-2">
                    <img src={drc_logo} alt="Doutor Caminhoneiro" />
                </NavbarBrand>
                <h1 className="my-0 text-large">
                    <strong>BEM-VINDOS AO SITE DO DOUTOR CAMINHONEIRO</strong>
                </h1>
                <div className="corp-brand-container">
                    <NavLink href={`${context}`} className="mx-2">
                        <img src={transprolog_logo} alt="Transprolog" />
                    </NavLink>
                </div>
            </Navbar>
            {UserManager.context === 'admin' && (
                <h1 className="text-small text-center bg-dark-brown mb-0 text-white col-12">{UserManager.type_label}</h1>
            )}
            {process.env.NODE_ENV === 'development' && (
                <h1 className="text-small text-center bg-dark-purple mb-0 text-white col-12">Desenvolvimento</h1>
            )}
            <div className="sub-navbar d-flex">
                {UserManager.context === 'club' && (
                    <>
                        {(summary?.cart?.count || 0) > 0 && <CartFloatingButton />}
                        <NavLink
                            tag={Link}
                            to="/home"
                            title="Página Inicial"
                            className={`px-2 ${location.pathname === '/home' ? 'active' : ''}`}
                        >
                            <FontAwesomeIcon icon={faHome} className="mx-1" />
                            <span>Home</span>
                        </NavLink>
                        <NavLink
                            tag={Link}
                            to="/carrinho"
                            title="Carrinho de compras"
                            style={{ position: 'relative' }}
                            className={`px-2 ${location.pathname === '/carrinho' ? 'active' : ''}`}
                        >
                            <FontAwesomeIcon icon={faShoppingCart} className="mx-1" />
                            <span>Carrinho</span>
                            {(summary?.cart?.count || 0) > 0 && (
                                <span
                                    className="cart-counter badge badge-danger"
                                    title={`Existem ${summary?.cart?.count || 0} itens no seu carrinho de compras`}
                                    style={{ position: 'absolute', bottom: '50%', right: 0 }}
                                >
                                    {summary?.cart?.count || 0}
                                </span>
                            )}
                        </NavLink>
                        <NavLink
                            tag={Link}
                            to="/pedidos"
                            title="Pedidos"
                            style={{ position: 'relative' }}
                            className={`px-2 ${location.pathname === '/pedidos' ? 'active' : ''}`}
                        >
                            <FontAwesomeIcon icon={faArchive} className="mx-1" />
                            <span>Pedidos</span>
                            {(summary?.purchases || 0) > 0 && (
                                <span
                                    className="cart-counter badge badge-danger"
                                    title={`Você possui ${summary?.purchases || 0 || 0} pedidos em aberto`}
                                    style={{ position: 'absolute', bottom: '50%', right: 0 }}
                                >
                                    {summary?.purchases || 0}
                                </span>
                            )}
                        </NavLink>
                    </>
                )}
                <NavLink
                    tag={Link}
                    to={`${context}/notificacoes`}
                    title="Notificações"
                    style={{ position: 'relative' }}
                    className={`px-2 ${location.pathname === '/notificacoes' ? 'active' : ''}`}
                >
                    <FontAwesomeIcon icon={faBell} className="mx-1" />
                    <span>Notificações</span>
                    {(summary?.notifications || 0) > 0 && (
                        <span className="cart-counter badge badge-danger" style={{ position: 'absolute', bottom: '50%', right: 0 }}>
                            {summary?.notifications || 0}
                        </span>
                    )}
                </NavLink>
                <NavLink tag={Link} to="#" title="Sair" role="button" onClick={() => handleLogout()} className="px-2">
                    <FontAwesomeIcon icon={faSignOutAlt} className="mx-1" />
                    <span>Sair</span>
                </NavLink>
            </div>
        </header>
    );
}
