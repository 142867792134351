import React, { useState } from 'react';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';

function OrderItem(props) {
    const item = props?.item || {};

    const priceArr = (item?.price || 0).toFixed(2).split('.');
    const priceWhole = priceArr[0];
    const priceFraction = priceArr[1];

    const [qtd] = useState(item?.qtd);

    return (
        <div key={item.id} className="order-item col-12 d-flex flex-row flex-wrap py-2">
            <div
                className="d-flex justify-content-center align-items-center px-2 col-12 col-sm-2 col-md-2"
                style={{ whiteSpace: 'nowrap' }}
            >
                {item?.product?.thumb && <img src={item?.product?.thumb?.url || ''} alt={item?.product?.description} className="col-10" />}
            </div>
            <div className="order-item-details col-12 col-sm-8 col-md-8 text-medium">
                <div className="d-flex flex-column mx-2 ">
                    <span className="order-item-name fw-bold">{item?.product?.name}</span>
                    <span className="order-item-description my-2">{parse(item?.details || item?.product?.description || '')}</span>
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center fw-bold order-item-qtd-input">
                            <label>Qtd.:</label>
                            <label className="mx-2">{qtd}</label>
                        </div>
                    </div>
                    {(item?.product?.has_shipping || false) && (
                        <label className="order-item-shipping-info mt-2">
                            <FontAwesomeIcon icon={faMailBulk} className="mx-1" />
                            Este produto possui serviço de postagem
                        </label>
                    )}
                </div>
            </div>
            <div className="order-item-price d-flex align-items-center justify-content-start flex-column px-2 col-12 col-sm-2 col-md-2">
                <div className="my-2 text-big d-flex align-items-start">
                    <span className="price-symbol" style={{ fontSize: '60%' }}>
                        R$
                    </span>
                    <span className="price-whole fw-bold ml-1 pt-1">{priceWhole}</span>
                    <span className="priceFraction" style={{ fontSize: '60%' }}>
                        {priceFraction}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default OrderItem;
