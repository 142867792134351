import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import './FilterContainer.scss';

function FilterContainer({ title = 'Filtros', showTitle = true, className = '', children = null }) {
    return (
        <div className={`filter-container ${className || ''}`}>
            {!showTitle ? '' : <div className="filter-title">{title}</div>}
            {children}
        </div>
    );
}

function FilterFields({ children = null, className = '' }) {
    return <div className={`filter-fields ${className || ''}`}>{children}</div>;
}

function FilterTabs({ children, className = '', props }) {
    return (
        <Nav className={`filter-tabs ${className}`} {...props}>
            {children}
        </Nav>
    );
}

function FilterTab({ active = false, disabled = false, className = '', onClick = () => {}, children, ...props }) {
    const handleItemClick = () => {
        if (disabled) return;
        onClick();
    };

    return (
        <div
            role="button"
            tabIndex={0}
            className={`filter-tab ${active ? 'active' : ''} ${disabled ? 'disabled' : ''} ${className}`}
            onClick={handleItemClick}
            {...props}
        >
            <label>{children}</label>
        </div>
    );
}

function FilterTabLink({ active = false, disabled = false, className = '', to = '', children, ...props }) {
    return (
        <NavItem className={`filter-tab ${active ? 'active' : ''} ${className}`} {...props}>
            <NavLink tag={Link} to={to} disabled={disabled}>
                {children}
            </NavLink>
        </NavItem>
    );
}

export { FilterFields, FilterTabs, FilterTab, FilterTabLink };
export default FilterContainer;
