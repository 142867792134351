import React, { useCallback, useEffect, useState } from 'react';
import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import { Field } from 'formik';
import { toast } from 'react-hot-toast';
import ButtonAddNewCard from '@app/components/UserCards/ButtonAddNewCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faPix } from '@fortawesome/free-brands-svg-icons';

import UserCardsList from './UserCardsList';

import InstallmentsSelector from './InstallmentsSelector';

export default function PaymentMethods({
    methods = ['credit_card'],
    paymentMethod = '',
    cardId = '',
    amount = 0,
    installments = 1,
    disabled = false,
    onChangePaymentMethod = () => {},
    onChangePaymentCard = () => {},
    onChangeInstallment = () => {},
    className = '',
}) {
    const replaceParams = useReplaceParams;

    const [userCards, setUserCards] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(paymentMethod);
    const [selectedCardId, setSelectedCardId] = useState(cardId);

    const loadUserCards = useCallback(
        (payment_method) => {
            const url = replaceParams(URLs.services.account.cards.list, { card_type: payment_method });
            Api({ method: 'get', url, data: { payment_method } })
                .then((response) => {
                    setUserCards(response.data);
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao tentar obter os meios de pagamento');
                });
        },
        [replaceParams]
    );

    const onChoosePaymentMethod = (e) => {
        const payment_method = e.target.value;
        setSelectedMethod(payment_method);
        onChangePaymentMethod(payment_method);

        if (payment_method === 'credit_card' || payment_method === 'debit_card') loadUserCards(payment_method);
    };

    const onChooseCard = (id) => {
        const card_id = parseInt(id);
        setSelectedCardId(card_id);
        onChangePaymentCard(card_id);
    };

    useEffect(() => {
        if (!['credit_card', 'debit_card'].includes(paymentMethod)) return;
        loadUserCards(paymentMethod);
    }, [paymentMethod, loadUserCards]);

    useEffect(() => {
        setSelectedCardId(cardId);
    }, [cardId]);

    return (
        <div className={`payment-method col-12 ${className}`}>
            <div className="col-12 mt-3 d-flex">
                <div className="d-flex flex-column col-12">
                    <h2 className="text-large text-center fw-bold">MÉTODO DE PAGAMENTO</h2>
                    {methods.includes('credit_card') && (
                        <>
                            <label htmlFor="payment_method_credit_card" className="payment-method-option">
                                <Field
                                    type="radio"
                                    id="payment_method_credit_card"
                                    name="payment_method"
                                    value="credit_card"
                                    onChange={onChoosePaymentMethod}
                                    disabled={disabled}
                                />
                                <FontAwesomeIcon icon={faCreditCard} />
                                <span>Cartão de Crédito</span>
                            </label>
                            {selectedMethod === 'credit_card' && (
                                <>
                                    <UserCardsList
                                        cards={userCards}
                                        selectedCardId={selectedCardId}
                                        onChooseCard={onChooseCard}
                                        disabled={disabled}
                                    />
                                    <ButtonAddNewCard
                                        updateRequest={() => {
                                            loadUserCards('credit_card');
                                        }}
                                        disabled={disabled}
                                    />
                                    <InstallmentsSelector
                                        className="col-12"
                                        amount={amount}
                                        installments={installments}
                                        onChooseInstallment={onChangeInstallment}
                                        disabled={disabled}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {methods.includes('debit_card') && (
                        <>
                            <label htmlFor="payment_method_debit_card" className="payment-method-option">
                                <Field
                                    type="radio"
                                    id="payment_method_debit_card"
                                    name="payment_method"
                                    value="debit_card"
                                    onChange={onChoosePaymentMethod}
                                    disabled={disabled}
                                />
                                <FontAwesomeIcon icon={faCreditCard} />
                                <span>Cartão de Débito</span>
                            </label>
                            {selectedMethod === 'debit_card' && (
                                <>
                                    <UserCardsList
                                        cards={userCards}
                                        selectedCardId={selectedCardId}
                                        onChooseCard={onChooseCard}
                                        disabled={disabled}
                                    />
                                    <ButtonAddNewCard
                                        updateRequest={() => {
                                            loadUserCards('credit_card');
                                        }}
                                        disabled={disabled}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {methods.includes('boleto') && (
                        <label htmlFor="payment_method_boleto" className="payment-method-option">
                            <Field
                                type="radio"
                                id="payment_method_boleto"
                                name="payment_method"
                                value="boleto"
                                onChange={onChoosePaymentMethod}
                                disabled={disabled}
                            />
                            <FontAwesomeIcon icon={faFileInvoiceDollar} />
                            <span>Boleto</span>
                        </label>
                    )}

                    {methods.includes('pix') && (
                        <label htmlFor="payment_method_pix" className="payment-method-option">
                            <Field
                                type="radio"
                                id="payment_method_pix"
                                name="payment_method"
                                value="pix"
                                onChange={onChoosePaymentMethod}
                                disabled={disabled}
                            />
                            <FontAwesomeIcon icon={faPix} />
                            <span>PIX</span>
                        </label>
                    )}
                </div>
            </div>
        </div>
    );
}
