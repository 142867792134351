import React, { useCallback, useEffect, useState } from 'react';
import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';

import { Button, Label } from 'reactstrap';

function secondsToTime(val) {
    const minutes = Math.floor(val / 60);
    const seconds = val - minutes * 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

function getMessageByChannel(channel) {
    switch (channel) {
        case 'whatsapp':
            return (
                <div className="message-sent-container my-1 py-2 d-flex flex-wrap col-12 text-medium">
                    Enviamos um código de confirmação para você via Whatsapp. Digite o código no campo abaixo e clique em
                    &quot;CONFIRMAR&quot; para prosseguir com o cancelamento da sua assinatura.
                </div>
            );
        case 'sms':
            return (
                <div className="message-sent-container my-1 py-2 d-flex flex-wrap col-12 text-medium">
                    Enviamos um código de confirmação para seu celular via mensagem SMS. Digite o código no campo abaixo e clique em
                    &quot;CONFIRMAR&quot; para prosseguir com o cancelamento da sua assinatura.
                </div>
            );
        default:
            return (
                <div className="message-sent-container my-1 py-2 d-flex flex-wrap col-12 text-medium">
                    <span className="col-12">
                        Enviamos um código de confirmação para você via E-mail. Cheque sua caixa de entrada para obter o código, digite-o no
                        campo abaixo e clique em &quot;CONFIRMAR&quot; para prosseguir com o cancelamento da sua assinatura.
                    </span>
                    <span className="col-12 text-error text-small my-1">
                        * Não se esqueça de checar sua caixa de spam caso não encontre o E-mail na sua caixa de entrada
                    </span>
                </div>
            );
    }
}

const reasons = [
    'Não quero informar um motivo',
    'Motivos pessoais',
    'Motivos financeiros',
    'Não atendeu às expectativas',
    'Lentidão no atendimento',
    'Não preciso mais dos produtos e serviços oferecidos',
    'Dificuldades em utilizar a plataforma',
    'Falta de assistência técnica',
    'Problemas com os meios de pagamento disponíveis',
    'Outro',
];

export default function CancelSubscriptionForm({ closeModal = () => {}, onConfirm = () => {}, token = null, description = '', ...props }) {
    const replaceParams = useReplaceParams;
    const [expiresAt, setExpiresAt] = useState(null);
    const [expirationCounter, setExpirationCounter] = useState(null);
    const [code, setCode] = useState('');
    const [messageType, setMessageType] = useState('e-mail');
    const [reason, setReason] = useState('');
    const [obs, setObs] = useState('');

    useEffect(() => {
        if (expirationCounter === 0 || expirationCounter === null) {
            setExpiresAt(null);
            setCode('');
            return;
        }

        const intervalId = setInterval(() => {
            setExpirationCounter((prev) => prev - 1);
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [expiresAt, expirationCounter, setExpirationCounter]);

    /**
     * expirado 3e952a
     * inválido 3e952b
     * válido 8c2797
     */
    const requestCancellation = useCallback(() => {
        const url = replaceParams(URLs.club.subscriptions.subscriptions.cancel.request, { token });
        const data = { message_type: messageType, reason, obs };
        Api({ method: 'post', url, data })
            .then(({ status, data }) => {
                if (data && status === 200) {
                    const seconds = Math.floor((new Date(data?.expires_at).getTime() - new Date().getTime()) / 1000);
                    setExpirationCounter(seconds);
                    setExpiresAt(data?.expires_at || null);
                } else {
                    toast.error(data?.message || 'Não foi popssível solicitar o cancelamento da assinatura');
                }
            })
            .catch(() => {
                toast.error('Houve um erro inesperado ao solicitar o cancelamento da assinatura!');
            });
    }, [replaceParams, token, messageType, reason, obs]);

    const confirmCancellation = useCallback(() => {
        const url = replaceParams(URLs.club.subscriptions.subscriptions.cancel.confirm, { token });
        const data = { code };
        Api({ method: 'post', url, data })
            .then(({ status, data }) => {
                if (data && status === 200) {
                    setExpirationCounter(0);
                    setExpiresAt(null);
                    onConfirm();
                } else {
                    toast.error(data?.message || 'Não foi popssível solicitar o cancelamento da assinatura');
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro inesperado ao solicitar o cancelamento da assinatura!');
            });
    }, [replaceParams, onConfirm, token, code]);

    // Funções de alteração dos dados do usuário
    return (
        <div className="col-12" {...props}>
            <p>
                Tem certeza que deseja solicitar o cancelamento do plano <strong>{description}</strong>?
            </p>
            <p className="text-small text-error">
                Ao cancelar sua assinatura você deixará de ter acesso aos benefícios exclusivos que o <strong>Doutor Caminhoneiro</strong>{' '}
                preparou especialmente para você.
            </p>
            <p className="text-small">
                Caso queira continuar, preencha os campos abaixo, selecione um canal para que o <strong>Doutor Caminhoneiro</strong> envie o
                código de confirmação para você e depois clique em &quot;RECEBER CÓDIGO&quot;.
            </p>
            <div className="mb-1 d-flex flex-wrap">
                <div className="col-12 d-flex flex-column">
                    <span className="col-12 text-center">Nos ajude a entender o que aconteceu</span>
                    <div className="col-12 d-flex flex-column">
                        <Label className="col-12" htmlFor="name">
                            Motivo:
                        </Label>
                        <select
                            name="reason"
                            value={reason}
                            onChange={(e) => {
                                setReason(e.target.value);
                                setObs('');
                            }}
                            disabled={expiresAt !== null}
                        >
                            <option value="" disabled>
                                &nbsp;
                            </option>
                            {reasons.map((value, index) => (
                                <option key={index} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12 py-1 d-flex flex-column">
                        <input
                            type="text"
                            name="obs"
                            value={obs}
                            onChange={(e) => setObs(e.target.value)}
                            className="col-12"
                            disabled={expiresAt !== null || reason !== 'Outro'}
                        />
                    </div>
                </div>
                <div className="col-12 col-sm-6 d-flex flex-column">
                    <Label htmlFor="name">Receber código por:</Label>
                    <select
                        name="comunication-channel"
                        value={messageType}
                        disabled={expiresAt !== null}
                        onChange={(e) => setMessageType(e.target.value)}
                    >
                        <option value="e-mail">E-mail</option>
                        <option value="whatsapp">Whatsapp</option>
                        <option value="sms">SMS</option>
                    </select>
                </div>
                <div className="col-12 col-sm-6 px-1 d-flex flex-column">
                    <Label htmlFor="name" className="mb-1">
                        &nbsp;
                    </Label>
                    <button type="button" className="btn btn-secondary" onClick={() => requestCancellation()} disabled={expiresAt !== null}>
                        RECEBER CÓDIGO
                    </button>
                </div>
            </div>

            {expiresAt !== null && getMessageByChannel(messageType)}

            <div className="my-3 col-12 d-flex justify-content-center ">
                <div className="col-12 col-md-6 d-flex flex-column">
                    <Label htmlFor="name" className="col-12 text-center">
                        CÓDIGO DE CONFIRMAÇÃO
                    </Label>
                    <input
                        type="text"
                        name="name"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className="col-12 text-center confirmation-code-input"
                        maxLength={6}
                        disabled={expiresAt === null}
                    />
                    <span className="col-12 py-1 text-center text-error text-small">
                        Expira em: {expiresAt} ({secondsToTime(expirationCounter)})
                    </span>
                </div>
            </div>
            <div className="d-flex">
                <div className="mb-3 px-1 col-6 text-center">
                    <Button type="submit" className="col-12" color="danger" disabled={false} onClick={closeModal}>
                        CANCELAR
                    </Button>
                </div>
                <div className="mb-3 px-1 col-6 text-center">
                    <Button
                        type="submit"
                        className="col-12"
                        color="success"
                        disabled={expiresAt === null || code.trim().length < 6}
                        onClick={() => confirmCancellation()}
                    >
                        CONFIRMAR
                    </Button>
                </div>
            </div>
        </div>
    );
}
