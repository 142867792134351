import React, { useContext, useState } from 'react';
import { SketchPicker } from 'react-color';

import PlanDisplayEditorContext from '../../PlanDisplayEditorContext';
import './PlanThemeSelector.scss';

import DefaultThemes from './DefaultThemes';

function rgbaToHex(rgba) {
    const rHex = (rgba?.r || 255).toString(16).padStart(2, '0');
    const gHex = (rgba?.g || 255).toString(16).padStart(2, '0');
    const bHex = (rgba?.b || 255).toString(16).padStart(2, '0');
    const aHex = Math.round((rgba?.a || 255) * 255)
        .toString(16)
        .padStart(2, '0');
    return `#${rHex}${gHex}${bHex}${aHex}`;
}
function ColorSelector({ colorId = '', label = 'Selecionar uma cor', color = '#ffffff00', onColorChanged = () => {} }) {
    const [showPicker, setShowPicker] = useState(false);
    const [selectedColor, setSelectedColor] = useState(color);

    const onSelectNewColor = (color) => {
        setSelectedColor(color);
    };
    const applySelectedColor = () => {
        onColorChanged(colorId, rgbaToHex(selectedColor.rgb));
        setShowPicker(false);
    };
    return (
        <div className="color-picker col-12 d-flex align-items-center">
            <button
                type="button"
                name="btn-open-picker"
                className="btn-open-picker mx-2"
                style={{ backgroundColor: color }}
                onClick={() => setShowPicker((prev) => !prev)}
            >
                &nbsp;
            </button>
            <label className="label-color-id col-12 flex-shrink-1" htmlFor="btn-open-picker">
                {label}
            </label>
            {showPicker ? (
                <div className="picker-popover" onMouseLeave={() => setShowPicker(false)}>
                    <SketchPicker color={selectedColor.rgb || selectedColor} onChangeComplete={onSelectNewColor} />
                    <div className="picker-popover-controls d-flex p-1">
                        <div className="px-1 col-6">
                            <button type="button" className="btn btn-success col-12" onClick={applySelectedColor}>
                                APLICAR
                            </button>
                        </div>
                        <div className="px-1 col-6">
                            <button type="button" className="btn btn-danger col-12" onClick={() => setShowPicker(false)}>
                                CANCELAR
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

function DefaultThemeSelector({ color, label = '', theme, onThemeSelect = () => {} }) {
    return (
        <div className="default-theme d-flex align-items-center">
            <button
                type="button"
                name="btn-open-picker"
                className="btn-open-picker mx-2"
                title={`Selecionar tema ${label}`}
                style={{ backgroundColor: color }}
                onClick={() => onThemeSelect(theme)}
            >
                &nbsp;
            </button>
        </div>
    );
}

export default function PlanThemeSelector({ plan }) {
    const { onApplyPlanChanges } = useContext(PlanDisplayEditorContext);

    const handleColorChanged = (colorId, color) => {
        const newTheme = {
            ...(plan?.theme || {}),
            [colorId]: color,
        };
        onApplyPlanChanges({ ...plan, theme: newTheme });
    };

    const handleDefaultThemeChanged = (theme) => {
        onApplyPlanChanges({ ...plan, theme });
    };

    return (
        <div className="plan-theme-selector d-flex flex-wrap px-1 col-12">
            <h3 className="col-12 text-center mt-1">Tema do plano</h3>
            <div className="d-flex flex-wrap col-8">
                <ColorSelector color={plan?.theme?.bg} colorId="bg" onColorChanged={handleColorChanged} label="Cor de fundo" />
                <ColorSelector color={plan?.theme?.dark} colorId="dark" onColorChanged={handleColorChanged} label="Cor escura" />
                <ColorSelector color={plan?.theme?.light} colorId="light" onColorChanged={handleColorChanged} label="Cor clara" />
                <ColorSelector color={plan?.theme?.title} colorId="title" onColorChanged={handleColorChanged} label="Cor do título" />
                <ColorSelector color={plan?.theme?.text} colorId="text" onColorChanged={handleColorChanged} label="Cor do texto" />
            </div>
            <div className="default-theme-selector d-flex flex-wrap col-4">
                {Object.keys(DefaultThemes).map((color) => (
                    <DefaultThemeSelector color={color} label="" theme={DefaultThemes[color]} onThemeSelect={handleDefaultThemeChanged} />
                ))}
            </div>
        </div>
    );
}
