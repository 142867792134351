import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { NavigateBackButton } from '@app/components/ui';
import { URLs, useReplaceParams } from '@app/constants';

import Api from '@app/services/Api';
import { toast } from 'react-hot-toast';
import ItemsTable from './table/ItemsTable';
import ChargesTable from './table/ChargesTable';

function SaleDetails(props) {
    const replaceParams = useReplaceParams;

    const [data, setData] = useState({});
    useEffect(() => {
        const loadSaleData = (token) => {
            const url = replaceParams(URLs.admin.sales.show, { token });
            Api({ method: 'get', url })
                .then(({ data, status }) => {
                    if (status === 200) {
                        setData(data);
                    } else {
                        toast.error(data?.message || 'Houve um erro ao obter os dados da venda');
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Houve um erro ao obter os dados da venda');
                });
        };

        loadSaleData(props?.match?.params?.token);
    }, [replaceParams, props?.match?.params?.token]);
    // let {data} = useFetch(replaceParams(URLs.admin.sales.show, params));

    return (
        <ContentWrapper>
            <div className="page-content">
                <div className="d-flex flex-wrap">
                    <div className="col-12 d-flex px-3">
                        <NavigateBackButton title="Retornar para a página de vendas" />
                    </div>
                    <div className="col-12 col-xs-6 col-sm-4 px-2 py-1">
                        <Label htmlFor="name">Total (R$): </Label>
                        <Label className="fw-bold mx-2">{(data?.amount || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</Label>
                    </div>
                    <div className="col-12 col-xs-6 col-sm-4 px-2 py-1">
                        <Label htmlFor="name">Dt. Compra: </Label>
                        <Label className="fw-bold mx-2">{data?.purchase_date || ''}</Label>
                    </div>
                    {data?.confirmation_date ? (
                        <div className="col-12 col-xs-6 col-sm-4 px-2 py-1">
                            <Label htmlFor="name">Dt. Pagamento: </Label>
                            <Label className="fw-bold mx-2">{data?.confirmation_date || ''}</Label>
                        </div>
                    ) : (
                        <div className="col-12 col-xs-6 col-sm-4 px-2 py-1">
                            <Label htmlFor="name">Expira Em: </Label>
                            <Label className="fw-bold mx-2">{data?.expiration_date || ''}</Label>
                        </div>
                    )}
                    <div className="col-12 col-xs-6 col-sm-4 px-2 py-1">
                        <Label htmlFor="name">Status: </Label>
                        <Label className="fw-bold mx-2">{data?.status_label || ''}</Label>
                    </div>
                    <div className="col-12 col-xs-6 col-sm-4 px-2 py-1">
                        <Label htmlFor="name">Tipo de Compra: </Label>
                        <Label className="fw-bold mx-2">{data?.type_label || ''}</Label>
                    </div>
                    <div className="col-12 col-xs-6 col-sm-4 px-2 py-1">
                        <Label htmlFor="name">Método de Pagamento: </Label>
                        <Label className="fw-bold mx-2">{data?.method_label || ''}</Label>
                    </div>

                    <div className="col-12 text-center badge badge-secondary px-2 py-1 my-2">Dados do cliente</div>
                    <div className="col-12 col-xs-6 col-sm-4 px-2 py-1">
                        <Label htmlFor="name">Nome: </Label>
                        <Label className="fw-bold mx-2">{data?.customer?.name || ''}</Label>
                    </div>
                    <div className="col-12 col-xs-6 col-sm-4 px-2 py-1">
                        <Label htmlFor="group_id">Email: </Label>
                        <Label className="fw-bold mx-2">{data?.customer?.email || ''}</Label>
                    </div>
                    <div className="col-12 col-xs-6 col-sm-4 px-2 py-1">
                        <Label htmlFor="group_id">Cadastro: </Label>
                        <Label className="fw-bold mx-2">{data?.customer?.created_at || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2 py-1">
                        <Label htmlFor="document">Telefone: </Label>
                        <Label className="fw-bold mx-2">{data?.customer?.phone || ''}</Label>
                    </div>
                </div>
                <div className="col-12 text-center badge badge-secondary px-2 py-1 my-2">Itens do pedido</div>
                <ItemsTable data={data?.items} />
                <div className="col-12 text-center badge badge-secondary px-2 py-1 my-2">Cobranças</div>
                <ChargesTable data={data?.charges} />
            </div>
        </ContentWrapper>
    );
}

export default SaleDetails;
