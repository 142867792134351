import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import HTMLEditor from './HTMLEditor';
import ProductDisplayEditor from './ProductDisplayEditor';
import FormEditor from './FormEditor';
import PlanDisplayEditor from './PlanDisplayEditor';

import ContentEditorContext from './ContentEditorContext';

export default function ContentEditor({ content, ...props }) {
    const { values, setValues } = useFormikContext();

    const [isChanging, setIsChanging] = useState(false);
    const dataType = useMemo(() => {
        switch (content.type) {
            case 'html':
                return 'Texto';
            case 'product-display':
                return 'Expositor';
            case 'subscription-plan-display':
                return 'Expositor';
            case 'form':
                return 'Formulário';
            default:
                return '';
        }
    }, [content]);

    const onDeleteContent = (id) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.filter((content) => content.id !== id),
        }));
    };

    const onApplyChanges = () => setIsChanging(false);
    const startChanging = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isChanging) return;
        setIsChanging(true);
    };

    return (
        <ContentEditorContext.Provider
            value={{
                isChanging,
                content,
                formik: { values, setValues },
                onApplyChanges,
                onDeleteContent,
            }}
        >
            <div
                {...props}
                data-type={isChanging ? '' : dataType}
                className={`dynamic-content-editor ${content?.className || ''} ${isChanging ? 'changing' : ''}`}
                onDoubleClick={startChanging}
            >
                {!isChanging && (
                    <button
                        type="button"
                        className="btn btn-primary content-editor-change-button"
                        title="Editar conteúdo"
                        onClick={startChanging}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                )}
                {(() => {
                    switch (content.type) {
                        case 'html':
                            return <HTMLEditor {...props} content={content} className={`dynamic-content ${content.className}`} />;
                        case 'product-display':
                            return (
                                <ProductDisplayEditor
                                    {...props}
                                    content={content}
                                    className={`dynamic-product-display ${content.className}`}
                                />
                            );
                        case 'subscription-plan-display':
                            return (
                                <PlanDisplayEditor
                                    {...props}
                                    content={content}
                                    className={`dynamic-product-display ${content.className}`}
                                />
                            );
                        case 'form':
                            return <FormEditor {...props} className={`dynamic-product-display ${content.className}`} />;
                        default:
                            return `Content - ${JSON.stringify(content.id)}`;
                    }
                })()}
            </div>
        </ContentEditorContext.Provider>
    );
}
