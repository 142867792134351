import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'reactstrap';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import FileDownloader from '@app/components/FileDownloader/FileDownloader';
import CheckPermission from '@app/components/CheckPermission';
import { URLs, useReplaceParams } from '../../../../constants';

function getColorByStatus(status) {
    switch (status) {
        case 1:
            return 'black';
        case 2:
            return 'hover-green';
        case 9:
            return 'dark-red';
        default:
            return 'black';
    }
}
function DocumentsTable(props) {
    const replaceParams = useReplaceParams;

    const data = useMemo(() => props?.data?.documents || [], [props?.data?.documents]);

    return (
        <Table striped bordered hover col="12" className="text-medium">
            <thead>
                <tr>
                    <th>Descrição</th>
                    <th className="text-center">Status</th>
                    <th>Observações</th>
                    <th className="text-center">Arquivos Enviados</th>
                </tr>
            </thead>
            <tbody>
                {data?.length > 0 ? (
                    data?.map((record, id) => {
                        const statusColor = getColorByStatus(record?.status || 1);
                        return (
                            <tr key={id}>
                                <td className="text-small">{record?.description}</td>
                                <td className={`text-center text-${statusColor} fw-bold`}>{record?.status_label}</td>
                                <td className="text-small">{record?.obs}</td>
                                <td className="text-center">
                                    <CheckPermission permission="review-documents">
                                        {(props?.data?.status || null) === 1 && ([1, 9].includes(record?.status) || false) && (
                                            <button
                                                type="button"
                                                className="btn btn-primary-outline"
                                                onClick={() => props?.handleDocumentReviewClick(record, record?.status)}
                                            >
                                                <FontAwesomeIcon icon={faEye} className="mx-2" />
                                                <span>REVISAR</span>
                                            </button>
                                        )}
                                    </CheckPermission>
                                    {(record?.status || 0) !== 0 && (
                                        <FileDownloader
                                            url={
                                                URLs.base() +
                                                replaceParams(URLs.admin.certificates.document.download, {
                                                    token: props?.token,
                                                    document_token: record?.token,
                                                })
                                            }
                                            label="Baixar arquivo"
                                            className="mx-2"
                                            filename={record?.filename}
                                            extension={record?.filetype}
                                        />
                                    )}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr key={0}>
                        <td colSpan={13} align="center">
                            Nenhum registro retornado
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default DocumentsTable;
