import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '@app/routes/PrivateRoute';
import CertificatesSchedule from './certificates_schedule';

import CertificatesHome from './certificates_home';
import CertificatesReview from './certificates_review';

function Certificates() {
    return (
        <Switch>
            <PrivateRoute exact path="/admin/certificados" component={CertificatesHome} />
            <PrivateRoute exact path="/admin/certificados/agenda" component={CertificatesSchedule} />
            <PrivateRoute exact path="/admin/certificados/:token/revisao" component={CertificatesReview} />
        </Switch>
    );
}

export default Certificates;
