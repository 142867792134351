import React, { useContext } from 'react';
import { Row, Col, Button } from 'reactstrap';

import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { LabelTip, CustomSwitch } from '@app/components/ui';

import Yup, { availableValidators } from '@app/validators';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import FormikDevTools from '@app/components/FormikDevTools';
import { PopupStyle } from '@app/constants';
import FormEditorFieldPopupContext from './FormEditorFieldPopupContext';
import FormEditorContext from '../../FormEditorContext';

import {
    StringFieldDefaultSelector,
    StatesFieldDefaultSelector,
    MaskedFieldDefaultSelector,
    DropdownFieldDefaultSelector,
    IntegerFieldDefaultSelector,
    FloatFieldDefaultSelector,
    BooleanFieldDefaultSelector,
} from './components/DefaultValueSelectors';
import {
    CheckboxFieldValidations,
    DateFieldValidations,
    DropdownFieldValidations,
    MaskedFieldValidations,
    NumberFieldValidations,
    StatesFieldValidations,
    StringFieldValidations,
} from './components/FieldValidations';

/*
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
*/

const stringToValidations = (validationsStr) => {
    const result = {};
    const validationsArr = validationsStr.split('|').map((item) => item.split(':'));

    validationsArr.forEach((item) => {
        if (item.length === 0 || item[0] === '') return;
        result[item[0]] = item.slice(1);
    });
    return result;
};

function FormEditorFieldPopup({ isOpen, field, ...props }) {
    const { content, onApplyFieldChanges, onCloseChangeFieldPopup } = useContext(FormEditorContext);

    const setFieldName = (name, { setValues }) => {
        const newName = name
            .replaceAll(' ', '_')
            .replace(/[^0-9a-zA-Z_]+/g, '')
            .toLowerCase();

        setValues((prev) => ({
            ...prev,
            name: newName,
        }));
    };

    const setFieldShow = (show, { setValues }) => {
        setValues((prev) => ({
            ...prev,
            show,
        }));
    };

    const setFieldType = (type, { values, setValues }) => {
        const name =
            (values?.id || null) === null && type === 'separator'
                ? `sep_${content.fields.filter((item) => item.type === 'separator').length + 1}`
                : values?.name || '';
        setValues((values) => ({
            ...values,
            name,
            type,
            mask: '',
            default: '',
            content: '',
            validations: {},
        }));
    };
    const setFieldMask = (mask, { setValues }) => {
        const validatorsKeys = Object.keys(availableValidators);
        setValues((prev) => {
            const newSet = mask === '' ? {} : JSON.parse(JSON.stringify(prev.validations));
            let validator = '';
            validatorsKeys.forEach((key) => {
                if (availableValidators[key].mask === mask) validator = { [key]: [], ...(availableValidators[key]?.definitions || {}) };
                if (newSet?.[key]) delete newSet[key];
            });
            return {
                ...prev,
                mask,
                validations: validator === '' ? newSet : { ...newSet, ...validator },
            };
        });
    };
    const setFieldDropdownItens = (itens, { setValues }) => {
        setValues((prev) => ({
            ...prev,
            content: itens,
        }));
    };

    const setFieldValidation = (value, params, { setValues }) => {
        setValues((prev) => {
            const newSet = JSON.parse(JSON.stringify(prev.validations));
            if (newSet?.[value] !== undefined) delete newSet[value];
            return {
                ...prev,
                validations: params === null ? newSet : { ...newSet, [value]: params },
            };
        });
    };

    const validationsToString = (validations) => {
        const result = [];
        Object.keys(validations).forEach((key) => {
            const params = validations?.[key] || [];
            result.push(`${key}${params.length > 0 ? `:${params.join(':')}` : ''}`);
        });
        return result.join('|');
    };

    const handleFieldSubmit = (values) => {
        onApplyFieldChanges({
            ...values,
            validations: validationsToString(values.validations),
        });
    };
    const afterOpenModal = () => {};

    /**
     * Inicialização e validação do formulário
     */
    const formValidations = Yup.object().shape({
        name: Yup.string()
            .min(3, 'O nome do campo deve ter no mínimo 3 dígitos.')
            .required('É necessário informar o nome do campo.')
            .notOneOf(
                (content?.fields || []).filter((item) => item.name !== field.name).map((item) => item.name),
                'Este campo já existe no formulário'
            ),
        type: Yup.string().required('Selecione o tipo do campo'),
        label: Yup.string()
            .when(['type'], {
                is: 'separator',
                then: Yup.string().notRequired(),
                otherwise: Yup.string().required('É necessário informar um título para o campo.'),
            })
            .min(3, 'O título do campo deve ter no mínimo 3 dígitos.')
            .max(100, 'O título do campo deve ter no máximo 100 dígitos.'),
    });

    const initialValues = {
        id: field?.id || null, // new Date().getTime(),
        name: field?.name || '',
        label: field?.label || '',
        type: field?.type || 'string',
        show: field?.show || false,
        validations: stringToValidations(field?.validations || ''),
        content: field?.content || '',
        default: field?.default || '',
        mask: field?.mask || '',
        ref: field?.ref || (content?.fields || []).filter((field) => field.type === 'states')[0]?.name || '',
        order: field?.order || content.fields.length,
        style: field?.style || 'px-1 col-6',
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseChangeFieldPopup}
            style={PopupStyle}
            contentLabel=""
            ariaHideApp={false}
            {...props}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">
                        {field?.id ? `ALTERAR CAMPO "${(field?.name || '').toUpperCase()}"` : 'ADICIONAR NOVO CAMPO'}
                    </h2>
                    <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={onCloseChangeFieldPopup}>
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    </Button>
                </Col>
            </Row>
            <div className="d-flex flex-wrap col-12">
                <Formik
                    initialValues={initialValues}
                    validationSchema={formValidations}
                    onSubmit={handleFieldSubmit}
                    enableReinitialize
                    render={(formikProps) => {
                        const { values } = formikProps;
                        return (
                            <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                                <FormEditorFieldPopupContext.Provider
                                    value={{
                                        values: formikProps.values,
                                        validations: values.validations,
                                        setFieldDefaultValue: (value) => formikProps.setFieldValue('default', value),
                                        setFieldValidation: (value, params) => setFieldValidation(value, params, formikProps),
                                    }}
                                >
                                    <div className="d-flex col-12 col-sm-6 flex-column p-1">
                                        <label className="text-small pl-1">Tipo de campo:</label>
                                        <Field
                                            as="select"
                                            name="type"
                                            title="Quantidade de itens"
                                            id="product-search-filter-limit"
                                            className="col-12"
                                            defaultValue={values?.type || ''}
                                            onChange={(e) => setFieldType(e.target.value, formikProps)}
                                        >
                                            <option value="string">Texto</option>
                                            <option value="masked">Texto Formatado</option>
                                            <option value="date">Data</option>
                                            <option value="states">Seletor de estado</option>
                                            <option value="cities">Busca por cidades</option>
                                            <option value="integer">Número inteiro</option>
                                            <option value="float">Número fracionário</option>
                                            <option value="dropdown">Seletor dropdown</option>
                                            <option value="boolean">Checkbox</option>
                                            <option value="" disabled style={{ borderBottom: 'solid 1px' }}>
                                                &nbsp;
                                            </option>
                                            <option value="separator">Separador</option>
                                        </Field>
                                    </div>
                                    <div className="d-flex col-12 col-sm-6 flex-column p-1">
                                        <label className="text-small pl-1">Nome:</label>
                                        <Field
                                            type="text"
                                            name="name"
                                            onChange={(e) => setFieldName(e.target.value, formikProps)}
                                            disabled={values?.type === 'separator'}
                                        />
                                        <ErrorMessage component="span" name="name" className="text-error text-small px-2" />
                                    </div>
                                    <div className="d-flex col-12 col-sm-6 flex-column p-1">
                                        <label className="text-small pl-1">Título:</label>
                                        <Field type="text" name="label" />
                                        <ErrorMessage component="span" name="label" className="text-error text-small px-2" />
                                    </div>
                                    {(values?.type || '') === 'masked' && (
                                        <div className="d-flex col-12 col-sm-6 flex-column p-1">
                                            <label className="text-small pl-1">Máscara:</label>
                                            <Field
                                                as="select"
                                                name="mask"
                                                title="Ordenar itens por"
                                                id="product-search-filter-order"
                                                className="col-12"
                                                defaultValue={values?.mask || ''}
                                                onChange={(e) => setFieldMask(e.target.value, formikProps)}
                                            >
                                                {Object.keys(availableValidators).map((validator, index) => (
                                                    <option key={index} value={availableValidators[validator].mask}>
                                                        {availableValidators[validator].label} - {availableValidators[validator].mask}
                                                    </option>
                                                ))}
                                            </Field>
                                        </div>
                                    )}
                                    {(values?.type || '') === 'dropdown' && (
                                        <div className="d-flex col-12 flex-column p-1">
                                            <label className="text-small pl-1">
                                                Itens:
                                                <LabelTip>
                                                    Digite todos os itens do seletor separando cada item por uma vírgula.
                                                    <br />
                                                    Ex.: Item 1, Item 2, Item 3...
                                                </LabelTip>
                                            </label>
                                            <Field
                                                type="text"
                                                name="dropdown-itens"
                                                title="Itens do seletor dropdown"
                                                placeholder="Ex.: Item 1,Item 2,Item 3,"
                                                id="dropdown-itens"
                                                className="col-12"
                                                defaultValue={values?.content || ''}
                                                onChange={(e) => setFieldDropdownItens(e.target.value, formikProps)}
                                            />
                                        </div>
                                    )}
                                    {(values?.type || '') === 'cities' && (
                                        <div className="d-flex col-12 col-sm-6 flex-column p-1">
                                            <label className="text-small pl-1">Seletor de estados relacionado:</label>
                                            <Field
                                                as="select"
                                                name="ref"
                                                title="Ordenar itens por"
                                                id="product-search-filter-order"
                                                className="col-12"
                                            >
                                                <option value="">Nenhum seletor</option>
                                                {(content?.fields || [])
                                                    .filter((field) => field.type === 'states')
                                                    .map((field, index) => (
                                                        <option key={index} value={field.name}>
                                                            {field.label}
                                                        </option>
                                                    ))}
                                            </Field>
                                        </div>
                                    )}

                                    <div className="d-flex col-12 flex-wrap">
                                        <div className="d-flex col-12 col-sm-6 flex-column p-1">
                                            <label className="text-small pl-1">Posição no formulário:</label>
                                            <Field as="select" name="order">
                                                {(() => {
                                                    const options = [];
                                                    const { length } = content.fields;
                                                    content.fields.forEach((item, index) => {
                                                        const after = index === field.order ? '(atual)' : ` (${item.label})`;
                                                        options.push(
                                                            <option key={index} value={index}>
                                                                {index + 1}&ordf; posição {after}{' '}
                                                            </option>
                                                        );
                                                    });
                                                    if (field?.order === length) {
                                                        options.push(
                                                            <option key={length} value={length}>
                                                                {length + 1}&ordf; posição
                                                            </option>
                                                        );
                                                    }
                                                    return options;
                                                })()}
                                            </Field>
                                        </div>
                                        <div className="d-flex col-12 col-sm-6 flex-column p-1">
                                            {values?.type === 'string' && <StringFieldDefaultSelector />}
                                            {values?.type === 'masked' && <MaskedFieldDefaultSelector />}
                                            {values?.type === 'integer' && <IntegerFieldDefaultSelector />}
                                            {['number', 'float'].includes(values?.type) && <FloatFieldDefaultSelector />}
                                            {values?.type === 'states' && <StatesFieldDefaultSelector />}
                                            {values?.type === 'dropdown' && <DropdownFieldDefaultSelector />}
                                            {values?.type === 'boolean' && <BooleanFieldDefaultSelector />}
                                        </div>
                                    </div>
                                    <div className="d-flex col-12 align-items-center p-1">
                                        <CustomSwitch
                                            name="show"
                                            className="justify-content-center"
                                            onChange={(e) => setFieldShow(e, formikProps)}
                                            checked={values?.show || false}
                                            isAsync={false}
                                            data={{ status: values?.show || false }}
                                        />
                                        <label className="field-label mx-1" htmlFor="field-required">
                                            Exibir este campo na tabela de visualização do formulário
                                        </label>
                                    </div>
                                    <h3 className="field-group-title col-12 mt-3">VALIDAÇÕES DO CAMPO</h3>
                                    <div className="d-flex flex-column p-1 col-12">
                                        {values?.type === 'string' && <StringFieldValidations />}
                                        {values?.type === 'masked' && <MaskedFieldValidations />}
                                        {values?.type === 'date' && <DateFieldValidations />}
                                        {['number', 'integer', 'float'].includes(values?.type) && <NumberFieldValidations />}
                                        {values?.type === 'boolean' && <CheckboxFieldValidations />}
                                        {values?.type === 'states' && <StatesFieldValidations />}
                                        {values?.type === 'dropdown' && <DropdownFieldValidations />}
                                    </div>
                                    <FormikDevTools />
                                    <div className="col-12 my-1 d-flex">
                                        <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                                            <button type="submit" className="btn btn-success col-10">
                                                APLICAR
                                            </button>
                                        </div>
                                    </div>
                                </FormEditorFieldPopupContext.Provider>
                            </Form>
                        );
                    }}
                />
            </div>
        </Modal>
    );
}

export default FormEditorFieldPopup;
