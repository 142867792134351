import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import ReactJson from 'react-json-view';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { NavigateBackButton } from '@app/components/ui';
import { URLs, useReplaceParams } from '@app/constants';

import Api from '@app/services/Api';
import { toast } from 'react-hot-toast';
import EventLogsTable from './tables/EventLogsTable';
import EventContactsTable from './tables/EventContactsTable';
import EventMessagesTable from './tables/EventMessagesTable';

const getTextStatusColorClass = (status) => {
    switch (status) {
        case 'error':
            return 'text-error fw-bold';
        case 'processed':
            return 'text-success fw-bold';
        case 'ignored':
            return 'text-alert fw-bold';
        case 'postponed':
            return 'text-info fw-bold';
        default:
            return '';
    }
};

export default function WhatsappEventDetails(props) {
    const replaceParams = useReplaceParams;

    const [data, setData] = useState({});
    useEffect(() => {
        const loadEventData = (id) => {
            const url = replaceParams(URLs.admin.whatsapp.show, { id });
            Api({ method: 'get', url })
                .then(({ data, status }) => {
                    if (status === 200) {
                        setData(data);
                    } else {
                        toast.error(data?.message || 'Houve um erro ao obter os dados do evento');
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Houve um erro ao obter os dados do evento');
                });
        };

        loadEventData(props?.match?.params?.token);
    }, [replaceParams, props?.match?.params?.token]);

    const processEvent = (id) => {
        const url = replaceParams(URLs.admin.whatsapp.process, { id });
        Api({ method: 'get', url })
            .then(({ data, status }) => {
                if (status === 200) {
                    toast.success('Evento em processamento');
                } else {
                    toast.error(data?.message || 'Houve um erro ao tentar processar o evento');
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar processar o evento');
            });
    };

    return (
        <ContentWrapper>
            <div className="page-content">
                <div className="d-flex flex-wrap">
                    <div className="col-12 d-flex px-3 mb-3">
                        <NavigateBackButton title="Retornar para a página de vendas" />
                    </div>
                    <div className="col-12 col-sm-6 px-2 py-1">
                        <Label htmlFor="name">Status: </Label>
                        <Label className={`${getTextStatusColorClass(data?.status || '')} fw-bold mx-2`}>{data?.status_label || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-6 px-2 py-1">
                        <Label htmlFor="name">Recebido Em: </Label>
                        <Label className="fw-bold mx-2">{data?.created_at || ''}</Label>
                    </div>
                    {(data?.status_message || '') !== '' && (
                        <div className="col-12 px-2 py-1">
                            <Label className="fw-bold mx-2 col-12 text-medium text-center text-error">{data?.status_message}</Label>
                        </div>
                    )}
                    <div className="d-flex col-12 px-2 py-0 justify-content-end">
                        <button type="button" className="btn btn-secondary" onClick={() => processEvent(data?.id || null)}>
                            PROCESSAR
                        </button>
                    </div>
                    <div className="col-12 text-center badge badge-secondary px-2 py-1 my-2">CONTATOS DO EVENTO</div>
                    <div className="col-12 px-1">
                        <EventContactsTable data={data?.contacts || []} />
                    </div>
                    <div className="col-12 text-center badge badge-secondary px-2 py-1 my-2">MENSAGENS DO EVENTO</div>
                    <div className="col-12 px-1">
                        <EventMessagesTable data={data?.messages || []} />
                    </div>
                    <div className="col-12 text-center badge badge-secondary px-2 py-1 my-2">DADOS DO EVENTO</div>
                    <div className="col-12 px-1">
                        <ReactJson
                            src={data?.data || {}}
                            theme="bright:inverted"
                            displayDataTypes={false}
                            style={{
                                fontSize: '0.7rem',
                                padding: '0.7rem',
                                backgroundColor: '#dfceb0',
                            }}
                            collapsed
                        />
                    </div>
                    <div className="col-12 text-center badge badge-secondary px-2 py-1 my-2">LOGS DO EVENTO</div>
                    <div className="col-12 px-1 my-2">
                        <EventLogsTable data={data?.logs || []} getTextStatusColorClass={getTextStatusColorClass} />
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
}
