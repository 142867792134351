import React from 'react';
import { Table } from 'reactstrap';

export default function EventContactsTable({ data }) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <tr>
                    <th>Nome</th>
                    <th>Telefone</th>
                    <th className="text-center">Cliente</th>
                    <th>Última Resposta</th>
                    <th>Data de Cadastro</th>
                </tr>
            </thead>
            <tbody>
                {(data || [])?.length > 0 ? (
                    data.map((record, index) => (
                        <tr key={index}>
                            <td>{record?.name || ''}</td>
                            <td>{record?.wa_id || ''}</td>
                            <td align="center">{(record?.user_id || null) !== null ? 'SIM' : 'NÃO'}</td>
                            <td>{record?.last_message_at || ''}</td>
                            <td>{record?.created_at || ''}</td>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={5}>Nenhum contato cadastrado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
