import React from 'react';
import { ErrorMessage, Formik } from 'formik';
import { Button, Form, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
// import { PasswordField } from '@app/components/ui';
import * as yup from 'yup';
import StyleguideHeader from './components/StyleguideHeader';

export default function StyleguideForms() {
    const handleSubmit = () => {};

    const validations = yup.object().shape({
        username: yup.string().phone('Formato de telefone inválido.').required('É necessário informar um endereço de email.'),
        password: yup
            .string()
            .min(8, 'A senha deve possuir ao menos 8 caracteres.')
            .required('A senha deve possuir ao menos 8 caracteres.'),
    });

    const initialValues = {
        username: '',
        password: '',
    };

    return (
        <>
            <StyleguideHeader active="forms" />
            <div className="page-content bg-white d-flex justify-content-evenly">
                <Formik initialValues={initialValues} validationSchema={validations} onSubmit={handleSubmit} enableReinitialize>
                    {({ values, setFieldValue, isLoading }) => (
                        <Form className="login" name="login">
                            <div className="justify-content-center mb-3 col-12">
                                <div className="col-12">
                                    <h2 className="text-center">Acesse sua conta</h2>
                                </div>
                            </div>
                            <div className="col-12 d-flex flex-column mb-3">
                                <Label className="field-label" htmlFor="phone">
                                    Celular / WhatsApp
                                </Label>
                                <InputMask
                                    name="username"
                                    type="text"
                                    className="col-12"
                                    mask="(99) 99999-9999"
                                    placeholder="(__) _____-____"
                                    value={values.username}
                                    disabled={isLoading}
                                    onChange={({ target: { value } }) => setFieldValue('username', value)}
                                />
                                <ErrorMessage component="span" name="username" className="text-error text-small" />
                            </div>
                            {/* <div className="col-12 d-flex flex-column mb-3">
                                <Label>Senha:</Label>
                                <Field name="password" component={PasswordField} className="login-field col-12 mb-1" />
                                <ErrorMessage component="span" name="password" className="text-error text-small" />
                            </div> */}
                            <div className="col-12 d-flex mb-3">
                                <div className="d-flex col-6 justify-content-center px-1">
                                    <Button type="submit" className="col-12" color="primary">
                                        ENTRAR
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
