import React from 'react';

export default function SubscriptionFuture({ subscription = null, className = '' }) {
    if (subscription === null) return null;
    return (
        <div className={`subscription-future d-flex h-100 justify-content-center col-12 ${className}`}>
            <div className="card no-border col-12">
                <h1 className="text-large text-center pt-3 fw-bold">ASSINATURA FUTURA</h1>
                <div className={`d-flex flex-1 col-12 p-2 flex-column align-items-center ${className}`}>
                    <p className="text-center">
                        Você possui atualmente a assinatura <strong>{subscription?.description}</strong> que encontra-se ativa com status de{' '}
                        <strong>&quot;Assinatura Futura&quot;</strong>, com data de início determinada para{' '}
                        <strong>{subscription?.start_at}</strong>.
                    </p>
                    <p className="text-center">Não é possível realizar uma troca de plano antes da data de início da assinatura atual.</p>
                    <p className="text-center">
                        Caso tenha dúvidas ou preocupações, leia nossa{' '}
                        <a href="/politica-de-assinaturas" target="_blank" title="Clique aqui para ler nossa Política de Assinaturas">
                            Política de Assinaturas
                        </a>{' '}
                        ou entre em contato conosco através de um dos canais abaixo, o time do <strong>Doutor Caminhoneiro</strong> estará
                        sempre à disposição.
                    </p>
                    <p className="col-12">
                        <ul>
                            <li>
                                E-mail:{' '}
                                <strong>
                                    <a href="mailto:contato@doutorcaminhoneiro.com.br">contato@doutorcaminhoneiro.com.br</a>
                                </strong>
                            </li>
                            <li>
                                E-mail:{' '}
                                <strong>
                                    <a href="mailto:contato@transprolog.com">contato@transprolog.com</a>
                                </strong>
                            </li>
                            <li>
                                Whatsapp:{' '}
                                <strong>
                                    <a href="https://web.whatsapp.com/send?phone=5519993142939">(19) 99314 2939</a>
                                </strong>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    );
}
