import React, { useCallback, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import Api from '../services/Api';
import { URLs, useSetParams } from '../constants';

const DRCAContext = React.createContext();
export function DRCAProvider({ children }) {
    const setParams = useSetParams;
    const screenRes = useRef(`${window.innerWidth}x${window.innerHeight}`);
    const previous = useRef({ url: '', token: '' });

    useEffect(() => {
        const handleResize = () => {
            screenRes.current = `${window.innerWidth}x${window.innerHeight}`;
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getData = (obj) => {
        const { scrollY } = window;
        return { sr: screenRes.current, pu: previous.current?.url || '', sy: scrollY, ...obj };
    };
    const action = useCallback(
        (actionType, extras = undefined) => {
            let xt = extras;
            if (xt !== undefined)
                xt = btoa(
                    JSON.stringify(xt)
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                );
            const url = setParams(
                URLs.services.traffic.index,
                getData({
                    a: 'action',
                    at: actionType,
                    u: previous?.current?.url || '',
                    s: previous?.current?.token || '',
                    xt,
                })
            );
            Api({ method: 'get', url });
        },
        [setParams]
    );

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (!previous.current) return;
    //         action('idle');
    //     }, 30000);
    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, [previous, action]);

    const pageview = (pageUrl, sectionToken) => {
        if (previous.current?.url === pageUrl) return;
        ReactGA.pageview(pageUrl);

        const setParams = useSetParams;
        const url = setParams(URLs.services.traffic.index, getData({ a: 'pageview', u: pageUrl, s: sectionToken }));
        Api({ method: 'get', url });
        previous.current = { url: pageUrl, token: sectionToken };
    };

    return (
        <DRCAContext.Provider
            value={{
                pageview,
                action,
            }}
        >
            {children}
        </DRCAContext.Provider>
    );
}

export default DRCAContext;
