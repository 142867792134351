import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Form } from 'reactstrap';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import { URLs, useReplaceParams } from '@app/constants';

import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';
import parse from 'html-react-parser';
import FormsTable from './table/FormsTable';

function Forms() {
    const replaceParams = useReplaceParams;

    const [contents, setContents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const loadFormsData = useCallback(
        (section) => {
            if (section === '') {
                setContents([]);
                return;
            }
            const url = replaceParams(URLs.admin.forms.list, { section });
            setIsLoading(true);
            Api({ method: 'get', url })
                .then(({ data }) => {
                    setIsLoading(false);
                    setContents(data);
                })
                .catch(() => {
                    toast.error('Houve um erro inesperado ao carregar o conteúdo da página!');
                });
        },
        [replaceParams]
    );

    const [sections, setSections] = useState([]);
    const loadSections = useCallback(() => {
        const url = replaceParams(URLs.admin.sections.basics, { context: 'club' });
        Api({ method: 'get', url }).then(({ data }) => {
            const getSectionTree = (destination, section, level) => {
                const label_prefix = Array(level).fill('&emsp;').join('');
                destination.push(
                    section.type === 'group' ? (
                        <option key={section.id} style={{ fontWeight: 'bold', color: 'black' }} value="" disabled>
                            {parse(label_prefix + section.name)}
                        </option>
                    ) : (
                        <option key={section.id} value={section.token}>
                            {parse(label_prefix + section.name)}
                        </option>
                    )
                );
                (section?.subsections || []).forEach((section) => {
                    getSectionTree(destination, section, level + 1);
                });
            };
            const newData = [];
            data.data.forEach((section) => {
                getSectionTree(newData, section, 0);
            });
            setSections(newData);
        });
    }, [replaceParams]);

    useEffect(() => {
        loadSections(null);
    }, [loadSections]);

    const sectionRef = useRef(null);
    const onSearchBySectionChange = (e) => loadFormsData(e.target.value);

    const content = useMemo(() => {
        if ((sectionRef?.current?.value || '') === '') {
            return <div className="col-12 text-center">Selecione uma seção para carregar os formulários relacionados</div>;
        }
        if ((contents?.length || 0) === 0) {
            return <div className="col-12 text-center">Nenhum formulário criado para esta seção</div>;
        }
        return contents.map((content, index) => (
            <div key={index} className="d-flex flex-column col-12">
                <h3 className="field-group-title">{content?.name || ''}</h3>
                {(content?.form_descriptors || []).map((descriptor, index) => (
                    <FormsTable descriptor={descriptor} key={index} />
                ))}
            </div>
        ));
    }, [contents]);

    return (
        <ContentWrapper>
            <FilterContainer title="">
                <FilterFields className="col-4">
                    <Form className="login d-flex mb-3 col-12 ">
                        <fieldset className="col-4">
                            <label htmlFor="orderBy">Seção:</label>
                            <select
                                name="section"
                                ref={sectionRef}
                                className="col-12"
                                onChange={onSearchBySectionChange}
                                disabled={isLoading}
                            >
                                <option value="">-- Selecione uma seção --</option>
                                {sections}
                            </select>
                        </fieldset>
                        <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <div className="page-content">{content}</div>
        </ContentWrapper>
    );
}

export default Forms;
