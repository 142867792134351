import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import { Label } from 'reactstrap';
import { CurrencyInputField } from '@app/components/ui';

export default function FormPriceStandard() {
    const { values } = useFormikContext();
    return (
        <>
            <div className="mb-1">
                <Label htmlFor="price_config.price">Preço (R$)</Label>
                <Field
                    type="text"
                    name="price_config.price"
                    value={values.price.toLocaleString('pt-BR', { maxFractionDigits: 2 })}
                    className="col-12 text-right"
                    disabled
                />
                <ErrorMessage component="span" name="price_config.min_qtd" className="text-error text-small" />
            </div>
            <div className="mb-1">
                <Label htmlFor="price_config.min_qtd">Qtd. Mínima</Label>
                <Field
                    name="price_config.min_qtd"
                    type="number"
                    min="0"
                    className="col-12 text-right"
                    disabled={!Number(values.max_offering).isNaN && values.max_offering === 1}
                />
                <ErrorMessage component="span" name="price_config.min_qtd" className="text-error text-small" />
            </div>
            <div className="mb-1">
                <Label htmlFor="price_config.max_qtd">Qtd. Máxima</Label>
                <Field
                    name="price_config.max_qtd"
                    type="number"
                    min="0"
                    className="col-12 text-right"
                    disabled={!Number(values.max_offering).isNaN && values.max_offering === 1}
                />
                <ErrorMessage component="span" name="price_config.max_qtd" className="text-error text-small" />
            </div>
            <div className="mb-1">
                <Label htmlFor="price_config.qtd">Qtd. Padrão</Label>
                <Field
                    name="price_config.qtd"
                    type="number"
                    min="0"
                    className="col-12 text-right"
                    disabled={!Number(values.max_offering).isNaN && values.max_offering === 1}
                />
                <ErrorMessage component="span" name="price_config.qtd" className="text-error text-small" />
            </div>
            <div className="mb-1">
                <Label htmlFor="amount">Total (R$)</Label>
                <Field name="amount" component={CurrencyInputField} className="col-12 text-right" />
                <ErrorMessage component="span" name="amount" className="text-error text-small" />
            </div>
        </>
    );
}
