import React, { useMemo } from 'react';
import { Product } from '@app/components/Products';

import './CourseItem.scss';

function CourseItem({ product = {}, productClassName = '', ...props }) {
    const show = useMemo(() => !product?.user_has || (product?.user_has && !product?.extras?.hide_after_buy), [product]);

    return (
        <div {...props} className={`course-item-display d-flex flex-wrap justify-content-center ${props?.className || ''}`}>
            {show && (
                <div className={`col-12 ${productClassName || ''}`}>
                    <Product className="h-100" data={product} />
                </div>
            )}
        </div>
    );
}
export default CourseItem;
