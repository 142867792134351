import React from 'react';
import { Table } from 'reactstrap';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';

function getRenewLabel(interval, interval_count) {
    const labels = {
        singular: {
            day: 'dia',
            week: 'semana',
            month: 'mês',
            year: 'ano',
        },
        plural: {
            day: 'dias',
            week: 'semanas',
            month: 'meses',
            year: 'anos',
        },
    };
    return `A cada ${interval_count} ${labels[interval_count === 1 ? 'singular' : 'plural'][interval]}`;
}

function getStatusColor(status) {
    switch (status) {
        case 'created':
            return 'text-primary';
        case 'processing':
            return 'text-info';
        case 'active':
            return 'text-success';
        case 'expired':
            return 'text-alert';
        case 'payment_failed':
            return 'text-error';
        case 'canceling':
            return 'text-info';
        default:
            return '';
    }
}

export default function SubscriptionsTable(props) {
    const floatToCurrency = useFloatToCurrency;
    return (
        <Table striped bordered hover col="12">
            <thead>
                <Tr handleOrderBy={props?.handleOrderBy || null}>
                    <th>Usuário</th>
                    <th>Assinatura</th>
                    <Th param="status">Status</Th>
                    <th>Valor</th>
                    <Th param="start_at">Início</Th>
                    <th>Removação</th>
                    <Th param="payment_method">Método de Pgto.</Th>
                </Tr>
            </thead>
            <tbody>
                {props?.data?.length > 0 ? (
                    props?.data.map((subscription, id) => (
                        <tr key={id}>
                            <td>{subscription?.user?.name || ''}</td>
                            <td>{subscription?.description || ''}</td>
                            <td className={`fw-bold ${getStatusColor(subscription?.status)}`}>{subscription?.status_label || ''}</td>
                            <td>R$ {floatToCurrency(subscription?.price) || ''}</td>
                            <td>{subscription?.start_at || ''}</td>
                            <td>{getRenewLabel(subscription?.interval, subscription.interval_count)}</td>
                            <td>{subscription?.payment_method_label || ''}</td>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={8}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
