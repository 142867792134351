import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-hot-toast';

import { Button, Form } from 'reactstrap';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';

import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';

import FormikDevTools from '@app/components/FormikDevTools';
import PaymentMethods from '@app/components/PaymentMethods';
import CheckoutContext from '../SubscriptionContext';

import './SubscriptionCheckoutForm.scss';

function SubmitButton({ label = 'EFETUAR PAGAMENTO', className = '' }) {
    const { values, isSubmitting } = useFormikContext();

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const validateSubmitEnabbling = useCallback(() => {
        setSubmitDisabled(
            values.payment_method === '' ||
                values.captcha_token === '' ||
                (values.payment_method === 'credit_card' && values.user_card_id === '') ||
                (values.payment_method === 'debit_card' && values.user_card_id === '') ||
                isSubmitting
        );
    }, [values, isSubmitting]);

    useEffect(() => {
        validateSubmitEnabbling();
    }, [validateSubmitEnabbling]);

    return (
        <div className={`col-12 my-3 px-2 d-flex justify-content-center ${className}`}>
            <Button
                type="submit"
                color="custom"
                className="btn btn-success col-10 col-md-8 col-lg-6 py-3 text-large text-nowrap"
                disabled={submitDisabled}
            >
                {label} {isSubmitting && <LoadingSpinner />}
            </Button>
        </div>
    );
}

export default function SubscriptionCheckoutForm({ className = '' }) {
    const { subscription, user, updateSubscriptionData } = useContext(CheckoutContext);
    const replaceParams = useReplaceParams;

    const captchaRef = useRef(null);
    const resetCaptchaToken = () => {
        captchaRef.current.reset();
    };

    const checkPendencies = useCallback(() => {
        // Checking billing address
        if ((user?.billing_address || null) === null) {
            toast.error('É necesssário informar um endereço de cobrança');
            return false;
        }
        return true;
    }, [user]);

    const handleFormSubmit = (values, { setSubmitting }) => {
        if (!checkPendencies()) {
            setSubmitting(false);
            return;
        }

        const url = replaceParams(URLs.club.subscriptions.subscriptions.close, {
            token: subscription?.token || '',
        });
        Api({ method: 'post', url, data: values })
            .then(({ status, data }) => {
                resetCaptchaToken();
                setSubmitting(false);

                if (status === 200) {
                    updateSubscriptionData();
                } else {
                    toast.error(data?.message || 'Erro inesperado ao finalizar o pedido!');
                    setSubmitting(false);
                }
                resetCaptchaToken();
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao finalizar o pedido!');
                setSubmitting(false);
                resetCaptchaToken();
            });
    };

    const handleCaptchaChange = (token, setFieldValue) => {
        // setFieldValue('captcha_token', `${token}ABCDEF`);
        setFieldValue('captcha_token', token);
    };

    const validations = yup.object().shape({
        payment_method: yup.string().required().oneOf(['credit_card', 'debit_card', 'boleto', 'pix']),
        user_card_id: yup.string().when('payment_method', {
            is: 'credit_card' || 'debit_card',
            then: yup.string().required('Selecione um meio de pagamento'),
        }),
        captcha_token: yup.string().required('Clique na cauxa de seleção "Não sou um robô"'),
    });

    const initialValues = {
        token: subscription?.token || '',
        name: subscription?.name || '',
        installments: 1,
        payment_method: 'credit_card',
        user_card_id: '',
        captcha_token: '',
    };

    return (
        <div className={`checkout-form col-12 ${className}`}>
            <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleFormSubmit}
                enableReinitialize
                render={({ values, setFieldValue, isSubmitting, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="form d-flex flex-wrap py-2">
                        <PaymentMethods
                            className="col-12 col-dm-6"
                            methods={subscription?.payment_methods || ['credit_card']}
                            paymentMethod={values?.payment_method || ''}
                            cardId={values?.user_card_id || ''}
                            amount={subscription?.amount || 0}
                            installments={values.installments}
                            onChangePaymentMethod={(method) => setFieldValue('payment_method', method)}
                            onChangePaymentCard={(id) => setFieldValue('user_card_id', parseInt(id))}
                            onChangeInstallment={(value) => setFieldValue('installments', parseInt(value))}
                        />
                        <div className="col-12 justify-content-center mt-3 d-flex">
                            <ReCAPTCHA
                                style={{ opacity: isSubmitting ? 0.5 : 1 }}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                ref={captchaRef}
                                onChange={(token) => handleCaptchaChange(token, setFieldValue)}
                            />
                        </div>
                        <SubmitButton label="EFETUAR ASSINATURA" />

                        <FormikDevTools />
                    </Form>
                )}
            />
        </div>
    );
}
