import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Api from '@app/services/Api';

function DropdownField({
    field,
    formikProps: {
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        disabled = false,
        hidden = false,
        required = false,
        label = '',
    },
}) {
    const [items, setItems] = useState([]);
    const [firstOption, setFirstOption] = useState('carregando itens...');

    useEffect(() => {
        const loadItems = (url) => {
            Api({ method: 'get', url })
                .then(({ data, status }) => {
                    if (data && status === 200) {
                        setItems(data);
                        setFirstOption('-- selecione um item --');
                    } else {
                        setFirstOption('');
                    }
                })
                .catch(() => {
                    setFirstOption('');
                });
        };

        if ((field?.content || '').indexOf('async') === 0) {
            const [, url] = field.content.split('=');
            loadItems(url);
        } else {
            setItems((field?.content || '').split(',').map((item) => item.trimStart()));
            setFirstOption('-- selecione um item --');
        }
    }, [field, setItems]);

    return (
        <div className={`${field.style} ${hidden ? 'd-none' : ''}`} key={field.name}>
            <Label className="field-label" htmlFor={field.name}>
                {label}
                {required ? <em>*</em> : ''}
            </Label>

            <select
                name={field.name}
                className="col-12"
                disabled={disabled || isSubmitting}
                value={values?.[field.name] || field.default || ''}
                onChange={({ target: { value } }) => {
                    setFieldTouched(field.name);
                    setFieldValue(field.name, value, true);
                }}
                onBlur={() => {
                    setFieldTouched(field.name);
                }}
            >
                <option key="" value="">
                    {firstOption}
                </option>
                {items.map((value, index) => (
                    <option key={index} value={value}>
                        {value}
                    </option>
                ))}
            </select>
            {errors?.[field.name] && <span className="text-error text-small px-2">{errors?.[field.name]}</span>}
        </div>
    );
}

export default DropdownField;
