import * as Yup from 'yup';
import {
    DocumentValidator,
    CPFValidator,
    CNPJValidator,
    useCPFValidator,
    useCNPJValidator,
    useCPFCNPJValidator,
} from './DocumentValidator';
import useAddressValidator from './AddressValidator';

Yup.setLocale({
    mixed: {
        default: 'Não é válido',
        required: 'Este campo é obrigatório',
        min: 'Deve ser maior que ${min}',
        max: 'Deve ser menor que ${max}',
    },
    number: {
        default: 'Valor inválido',
        required: 'Este campo é obrigatório',
        min: 'Deve ser maior que ${min}',
        max: 'Deve ser menor que ${max}',
    },
    date: {
        default: 'Formato de data inválido',
        required: 'Este campo é obrigatório',
        invalid: 'Formato de data inválido',
        min: 'Deve ser maior que ${min}',
        max: 'Deve ser menor que ${max}',
    },
    string: {
        default: 'Valor inválido',
        required: 'Este campo é obrigatório',
        email: 'Formato de email inválido',
        min: 'Este campo deve possuir no mínimo ${min} caracteres',
        max: 'Este campo deve possuir no máximo ${max} caracteres',
        length: 'Este campo deve possuir no máximo ${length} caracteres',
    },
});

Yup.addMethod(Yup.string, 'phone', function fn(message = '') {
    return this.test('phone', message, function fn(value) {
        const newValue = `${value}`.replace(/\D/g, '');
        if (newValue === '') return true;
        const { path, createError } = this;
        if (newValue.length !== 11) return createError({ path, message: message || 'Formato de telefone inválido' });
        return true;
    });
});

Yup.addMethod(Yup.string, 'cep', function fn(message = '') {
    return this.test('cep', message, function fn(value) {
        const newValue = `${value}`.replace(/\D/g, '');
        const { path, createError } = this;
        if (newValue.length !== 8) return createError({ path, message: message || 'Formato de CEP inválido' });
        return true;
    });
});

Yup.addMethod(Yup.string, 'cpf', function fn(message = '') {
    return this.test('cpf', message, function fn(value) {
        if (value === '' || !value) return true;
        const cpfValidator = useCPFValidator;
        const { path, createError } = this;
        const isValid = cpfValidator(value);
        return isValid ? true : createError({ path, message: 'CPF inválido' });
    });
});

Yup.addMethod(Yup.string, 'cnpj', function fn(message = '') {
    return this.test('cnpj', message, function fn(value) {
        if (value === '' || !value) return true;
        const cnpjValidator = useCNPJValidator;
        const { path, createError } = this;
        const isValid = cnpjValidator(value);
        return isValid ? true : createError({ path, message: 'CNPJ inválido' });
    });
});

Yup.addMethod(Yup.string, 'document', function fn(message = '') {
    return this.test('document', message, function fn(value) {
        if (value === '' || !value) return true;
        const documentValidator = useCPFCNPJValidator;
        const { path, createError } = this;
        const isValid = documentValidator(value);
        return isValid ? true : createError({ path, message: 'Documento inválido' });
    });
});

Yup.addMethod(Yup.object, 'file', function fn(message = '') {
    return this.test('file', message, function fn(value) {
        const { path, createError } = this;
        const isValid = (value?.key || null) !== null && (value?.name || null) !== null;
        return isValid ? true : createError({ path, message: 'Arquivo inválido' });
    });
});

Yup.addMethod(Yup.string, 'length', function fn(length, message = '') {
    return this.test('len', message, (val) => String(val).length === length);
});

Yup.addMethod(Yup.string, 'minLength', function fn(length, message = '') {
    return this.test('len', message, (val) => String(val).length >= length);
});

Yup.addMethod(Yup.string, 'float', (message = '') =>
    Yup.number()
        .transform((_value, originalValue) => Number(`${originalValue}`.replace(/,/, '.')))
        .typeError(message)
);

// ** Inicialização e validação do formulário ** //
Yup.addMethod(Yup.string, 'creditCardExpirationDate', function fn(errorMessage) {
    return this.test('test-exp-dates', errorMessage, function fn(value) {
        const { path, createError } = this;

        const arr = (value || '').split('/');
        const month = arr.length > 0 ? arr[0] : 0;
        const year = arr.length > 1 ? arr[1] : 0;
        const date = new Date();
        if (parseInt(month) > 12) return createError({ path, message: errorMessage });

        date.setMonth(parseInt(month));
        date.setYear(2000 + parseInt(year));
        const today = new Date();

        const isValid = date > today;
        return isValid ? true : createError({ path, message: errorMessage });
    });
});
Yup.addMethod(Yup.string, 'creditCardNumber', function fn(errorMessage) {
    return this.test('test-card-number', errorMessage, function fn(value) {
        const { path, createError } = this;
        const number = (value || '').replaceAll('_', '').replaceAll(' ', '');
        const isValid = number.length >= 13 && number.length <= 19;
        return isValid ? true : createError({ path, message: errorMessage });
    });
});

/**
 * O parâmetro definitions é utilizado para definir os validadores tanto no Yup quanto no Backend Laravel
 *  para os campos criados em formulários dinâmicos. O valor é um array pois poderá estar no formato {valor}:{mensagem de erro}
 */
const availableValidators = {
    '': { label: 'Nenhuma', mask: '' },
    cpf: { label: 'CPF', mask: '999.999.999-99', definitions: { min: [14], max: [14] } },
    cnpj: { label: 'CNPJ', mask: '99.999.999/9999-99', definitions: { min: [18], max: [18] } },
    phone: { label: 'Telefone', mask: '(99) 99999-9999', definitions: { min: [15], max: [15] } },
    cep: { label: 'CEP', mask: '99.999-999', definitions: { min: [10], max: [10] } },
};

const addrValidator = useAddressValidator;
const AddressValidator = addrValidator(Yup);
const useValidators = () => ({
    AddressValidator,
    DocumentValidator,
    CPFValidator,
    CNPJValidator,
});
export default Yup;
export { useValidators, availableValidators };
