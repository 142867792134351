import { useSelector } from 'react-redux';
import { URLs } from '../constants';
import Api from '../services/Api';

import { useUserManager } from './useUserManager';

export const useCartManager = () => {
    const UserManager = useUserManager();

    const summary = useSelector((state) => state.summary);

    const checkForSummaryUpdates = () => {
        if (new Date().getTime() - (summary?.lastUpdated || 0) < 5000) return;
        UserManager.updateSummary();
    };
    const addToCart = async ($items) => {
        const response = await Api({ method: 'post', url: URLs.club.cart.add, data: $items })
            .then((resp) => {
                const { data } = resp;
                if (data && resp.status === 200) {
                    UserManager.updateSummary();
                    return { ...data, ...{ status: resp.status } };
                }
                return { ...data, ...{ status: resp.status } };
            })
            .catch((err) => {
                if (err.response) {
                    return { ...err.response.data, ...{ status: err.response.status } };
                }
                return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar adicionar produtos ao carrinho.' };
            });
        return response;
    };

    const removeFromCart = async ($items) => {
        const response = await Api({ method: 'post', url: URLs.club.cart.remove, data: $items })
            .then((resp) => {
                const { data } = resp;
                if (data && resp.status === 200) {
                    UserManager.updateSummary();
                    return { ...data, ...{ status: resp.status } };
                }
                return { ...data, ...{ status: resp.status } };
            })
            .catch((err) => {
                if (err.response) {
                    return { ...err.response.data, ...{ status: err.response.status } };
                }
                return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar remover produtos do carrinho.' };
            });
        return response;
    };

    const updateOnCart = async ($items) => {
        const response = await Api({ method: 'post', url: URLs.club.cart.update, data: $items })
            .then((resp) => {
                const { data } = resp;
                if (data && resp.status === 200) {
                    UserManager.updateSummary();
                    return { ...data, ...{ status: resp.status } };
                }
                return { ...data, ...{ status: resp.status } };
            })
            .catch((err) => {
                if (err.response) {
                    return { ...err.response.data, ...{ status: err.response.status } };
                }
                return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar atualizar produtos no carrinho.' };
            });
        return response;
    };

    const closeCart = async () => {
        const response = await Api({ method: 'post', url: URLs.club.cart.close })
            .then((resp) => {
                const { data } = resp;
                if (data && resp.status === 200) {
                    UserManager.updateSummary();
                    return { ...data, ...{ status: resp.status } };
                }
                return { ...data, ...{ status: resp.status } };
            })
            .catch((err) => {
                if (err.response) {
                    return { ...err.response.data, ...{ status: err.response.status } };
                }
                return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar fechar o carrinho de compras.' };
            });
        return response;
    };

    return {
        checkForSummaryUpdates,
        addToCart,
        removeFromCart,
        updateOnCart,
        closeCart,
    };
};
