import React, { useMemo } from 'react';
import PlanContext from './PlanContext';
import { PlanBody, PlanFooter, PlanHeader } from './components';

export default function Plan({ data = {}, theme = {}, className = '', ...props }) {
    const planTheme = useMemo(
        () => ({
            bg: theme?.bg || data?.theme?.bg || '#c0c0c045',
            light: theme?.light || data?.theme?.light || '#d0b08d',
            dark: theme?.dark || data?.theme?.dark || '#8b705f',
            title: theme?.title || data?.theme?.title || '#2f2f2f',
            text: theme?.text || data?.theme?.text || '#0f0f0f',
        }),
        [data, theme]
    );

    return (
        <div className={`plan ${className}`} {...props} style={{ backgroundColor: planTheme.bg }}>
            <PlanContext.Provider
                value={{
                    plan: data,
                    themeColor: planTheme,
                }}
            >
                <PlanHeader />
                <PlanBody />
                <PlanFooter />
            </PlanContext.Provider>
        </div>
    );
}
