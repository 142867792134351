import React, { Suspense, lazy } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import CheckPermission from '@app/components/CheckPermission';

const CustomersChart = lazy(() => import('./components/Customers'));
const SalesChart = lazy(() => import('./components/Sales'));
const TrafficTable = lazy(() => import('./components/Traffic'));
const AbandonedCarts = lazy(() => import('./components/Cart'));

function Dashboard() {
    return (
        <ContentWrapper title="DASHBOARD">
            <div className="page-content d-flex flex-wrap">
                <CheckPermission section_url="/clientes" permission="list">
                    <div className="col-12 col-lg-6 p-1">
                        <Suspense fallback={<p>Carregando...</p>}>
                            <CustomersChart />
                        </Suspense>
                    </div>
                </CheckPermission>
                <CheckPermission section_url="/vendas" permission="list">
                    <div className="col-12 col-lg-6 p-1">
                        <Suspense fallback={<p>Carregando...</p>}>
                            <SalesChart />
                        </Suspense>
                    </div>
                </CheckPermission>
                <CheckPermission section_url="/vendas" permission="list">
                    <div className="col-12 p-1">
                        <Suspense fallback={<p>Carregando...</p>}>
                            <AbandonedCarts />
                        </Suspense>
                    </div>
                </CheckPermission>
                <CheckPermission section_url="/vendas" permission="list">
                    <div className="col-12 p-1">
                        <Suspense fallback={<p>Carregando...</p>}>
                            <TrafficTable />
                        </Suspense>
                    </div>
                </CheckPermission>
            </div>
        </ContentWrapper>
    );
}

export default Dashboard;
