import React from 'react';
import FieldData from './FieldData';

export default function FieldGroup({ templates = [], data = {}, className = '' }) {
    return (
        <div className={`form-content-group d-flex flex-wrap ${className}`}>
            {(templates || []).map((template, index) => (
                <FieldData key={index} template={template || {}} data={data?.[template?.col] || ''} />
            ))}
        </div>
    );
}
