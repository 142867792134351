import useSWR from 'swr';
import toast from 'react-hot-toast';
import Api from '../services/Api';
import { useUserManager } from './useUserManager';

export function useFetch(url, params = {}, callback = null) {
    const UserManager = useUserManager();
    const { token } = UserManager;

    const { data, error, mutate } = useSWR(
        url,
        async (url) => {
            const response = await Api.get(url, {
                headers: { Authorization: `Bearer ${token}` },
                params,
            }).catch((response) => {
                if (response.status === 405) {
                    toast.error(response?.data?.message || 'Operação não autorizada!');
                }
            });

            if (typeof callback === 'function') {
                callback(response.data);
            } else return response.data;
        },
        {
            // Propriedades de configuração do useSWR
            shouldRetryOnError: false,
        }
    );

    let last_page = data?.last_page || 0;
    last_page = last_page === 0 ? 1 : last_page;
    let current_page = data?.current_page || 0;
    current_page = current_page === 0 ? 1 : current_page;

    const extraKeys = [];
    if (data)
        (params?.extraKeys || []).forEach((key) => {
            if (key in data) extraKeys[key] = data[key];
        });

    const newData = data?.data || data;
    return { ...{ data: newData, error, mutate, last_page, current_page }, ...extraKeys };
}
