import React, { useCallback, useState } from 'react';
import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import DatePicker from 'react-datepicker';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import moment from 'moment';
import TrafficContext from './components/TrafficContext';
import TrafficPerPageTable from './components/TraficPerPageTable';
import UsersByUrl from './components/UsersByUrl';
import TrafficByUrl from './components/TrafficByUrl';
import TrafficRelationshipTable from './components/TrafficRelationshipTable';
import ActionsByUrl from './components/ActionsByUrl';

export default function Traffic() {
    const [isLoading] = useState(false);
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [url, setUrl] = useState('');
    const [selectedUrl, setSelectedUrl] = useState('');

    const onSearchByStartDateChange = (date) => {
        setStartDate(date);
        if (endDate && date > endDate) setEndDate(date);
    };
    const onSearchByEndDateChange = (date) => {
        setEndDate(date);
    };

    const onSelectedUrlChange = useCallback((url) => {
        setSelectedUrl((prev) => (prev === url ? '' : url));
    }, []);

    return (
        <ContentWrapper>
            <TrafficContext.Provider
                value={{
                    url,
                    startDate,
                    endDate,
                    selectedUrl,
                    onSelectedUrlChange,
                }}
            >
                <FilterContainer title="">
                    <FilterFields>
                        <form className="d-flex mb-3 flex-wrap">
                            <fieldset className="col-3">
                                <label htmlFor="accessName">Url</label>
                                <input type="text" name="accessName" value={url} onChange={(e) => setUrl(e.target.value)} />
                            </fieldset>
                            <fieldset className="col-12 col-sm-3">
                                <label htmlFor="orderBy">Data Inicial:</label>
                                <DatePicker
                                    className="col-12"
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    clearButtonTitle="Limpar"
                                    clearButtonClassName="px-1"
                                    selected={startDate}
                                    onChange={(date) => onSearchByStartDateChange(date)}
                                />
                            </fieldset>
                            <fieldset className="col-12 col-sm-3">
                                <label htmlFor="orderBy">Data Final:</label>
                                <DatePicker
                                    className="col-12"
                                    dateFormat="dd/MM/yyyy"
                                    isClearable
                                    clearButtonTitle="Limpar"
                                    clearButtonClassName="px-1"
                                    selected={endDate}
                                    minDate={startDate}
                                    onChange={(date) => onSearchByEndDateChange(date)}
                                />
                            </fieldset>
                            <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                        </form>
                    </FilterFields>
                </FilterContainer>
                <div className="page-content px-2">
                    <TrafficRelationshipTable className="col-12" />
                    <TrafficPerPageTable className="col-12" url={url} />
                    {selectedUrl !== '' && (
                        <>
                            <h3 className="text-center text-small bg-primary p-2 text-white d-flex align-items-center justify-content-center">
                                Análise da URL <span className="badge mx-2 px-2 badge-info">{selectedUrl}</span>
                            </h3>
                            <TrafficByUrl className="col-12" />
                            <ActionsByUrl className="col-12" />
                            <UsersByUrl className="col-12" />
                        </>
                    )}
                </div>
            </TrafficContext.Provider>
        </ContentWrapper>
    );
}
