import React, { useContext } from 'react';
import SizeSelector from '../../../CustomTools/SizeSelector';
import FormEditorContext from '../../FormEditorContext';

function FieldSizeSelector({ field, ...props }) {
    const { onApplyFieldChanges } = useContext(FormEditorContext);

    const onChangeColumns = (value, size) => {
        let newStyle = field.style
            .split(' ')
            .filter((item) => item.indexOf(`-${size}-`) === -1)
            .join(' ');
        newStyle += value !== '' ? ` col-${value}` : '';
        onApplyFieldChanges({ ...field, style: newStyle });
    };

    return (
        <div {...props} className={`columns-size-selector ${props?.className || ''}`}>
            {
                // <SizeSelector size={'sm'} contentClass={contentClass} id={id} />
            }
            <SizeSelector size="md" contentClass={field.style} onChangeColumns={onChangeColumns} />
            <SizeSelector size="lg" contentClass={field.style} onChangeColumns={onChangeColumns} />
        </div>
    );
}

export default FieldSizeSelector;
