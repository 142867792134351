import React, { useContext, useMemo } from 'react';
import { SectionContext } from '@app/contexts';

import { Link } from 'react-router-dom';
import { NavItem, NavLink, Nav } from 'reactstrap';
import { useUserManager } from '@app/hooks/useUserManager';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconClose from '@app/assets/images/sidebar-close-icon.svg';
import iconOpen from '@app/assets/images/sidebar-open-icon.svg';

function SideMenuLink({ context, section, currentSectionToken }) {
    return (
        <NavItem>
            <NavLink
                tag={Link}
                to={{
                    pathname: `${context}${section.url}`,
                    section: { name: '' },
                }}
                disabled={section.status === 0}
                // title={section.description}
                title={`${section.token} - ${currentSectionToken}`}
                className={currentSectionToken === section.token ? 'active' : ''}
            >
                <div className="side-menu-alt-content">
                    <div className="side-menu-icon">
                        <FontAwesomeIcon icon={fas[section.icon]} className="mx-2 my-1" />
                    </div>
                    <span className="side-menu-short-label">{section.short_label}</span>
                </div>
                <FontAwesomeIcon icon={fas[section.icon]} className="mx-2 my-1 side-menu-icon" />
                <span className="side-menu-label">{section.label}</span>
            </NavLink>
        </NavItem>
    );
}

function SideMenuGroup({ context, section, currentSectionToken }) {
    return (
        <>
            <div className="nav-sep d-flex nav-link-submenu">
                <span className="side-menu-label flex-grow-1 text-center p-0">{section.label}</span>
            </div>
            {section?.subsections.map((section, index) =>
                (section?.subsections || []).length === 0 ? (
                    <SideMenuLink key={index} context={context} section={section} currentSectionToken={currentSectionToken} />
                ) : (
                    <SideMenuGroup key={index} context={context} section={section} currentSectionToken={currentSectionToken} />
                )
            )}
            <div className="nav-sep" />
        </>
    );
}

function PrivateSidebar(props) {
    const context = useContext(SectionContext);

    const UserManager = useUserManager();
    const { isClosed, toggleSideBar } = props;

    const context_url = UserManager.context === 'admin' ? '/admin' : '';

    const menu = useMemo(
        () =>
            UserManager.sections
                .filter((section) => section?.menu || false)
                .map((section, index) =>
                    (section?.subsections || []).length === 0 ? (
                        <SideMenuLink
                            key={index}
                            context={context_url}
                            section={section}
                            currentSectionToken={context?.currentSection?.token}
                        />
                    ) : (
                        <SideMenuGroup
                            key={index}
                            context={context_url}
                            section={section}
                            currentSectionToken={context?.currentSection?.token}
                        />
                    )
                ),
        [UserManager, context_url, context]
    );

    return (
        <div className={`sidebar ${isClosed ? 'is-closed' : ''}`}>
            <div className="side-menu">
                <button
                    type="button"
                    name="menu-toggler"
                    color="custom"
                    className="btn btn-toggler d-flex"
                    title={`${isClosed ? 'Abrir Menu' : 'Fechar Menu'}`}
                    onClick={() => {
                        toggleSideBar();
                    }}
                >
                    <img src={isClosed ? iconOpen : iconClose} alt="" />
                </button>
                <div className="user-profile">
                    <div className="user-profile-icon">
                        <FontAwesomeIcon icon={fas.faUser} className="mx-2 my-1" />
                    </div>
                    <div className="user-profile-name">
                        <a href="/admin/conta" title="Minha conta">
                            {isClosed ? UserManager.name.split(' ')[0] : UserManager.name}
                        </a>
                    </div>
                </div>
                <Nav vertical className="list-unstyled pb-3">
                    {menu}
                </Nav>
            </div>
        </div>
    );
}

export default PrivateSidebar;
