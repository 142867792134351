import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { ErrorMessage, Field, Formik } from 'formik';
import { Button, Form, Label } from 'reactstrap';
import InputMask from 'react-input-mask';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import * as yup from 'yup';
import { useUserManager } from '@app/hooks/useUserManager';
import { useValidators } from '@app/validators';

import { toast } from 'react-hot-toast';

import AddressFields from '@app/components/AddressFields/AddressFields';
import { PasswordField } from '@app/components/ui';
import FormikDevTools from '@app/components/FormikDevTools';

function Account() {
    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    const { AddressValidator, CPFValidator, CNPJValidator } = useValidators();

    // Lista de vídeos disponíveis no VIMEO
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (userData?.id) return;

        // Carrega os dados do produto
        const loadUserData = () => {
            setIsLoading(true);
            const url = replaceParams(URLs.services.account.index, { nocache: new Date().getTime() });
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setUserData(response.data);
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch((err) => {
                    toast.error(err?.data?.message || 'Erro inesperado ao obter os dados do usuário');
                });
        };

        loadUserData();
    }, [replaceParams, token, userData]);

    // Faz o submit dos dados do usuário
    const handleUserSubmit = (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        const url = replaceParams(URLs.services.account.index, { nocache: new Date().getTime() });

        Api({ method: 'put', url, data: values })
            .then((response) => {
                setFieldValue('password_confirmation', '');
                if (response.data && response.status === 200) {
                    toast.success(response?.data?.message || 'Dados atualizados com sucesso');
                } else {
                    toast.error(response?.data?.message || 'Houve um erro ao tentar alterar os dados do usuário');
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                setSubmitting(false);
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados do usuário!');
            });
    };

    /**
     * Inicialização e validação do formulário
     */
    const validations = yup.object().shape({
        name: yup
            .string()
            .min(3, 'O nome deve ter entre 3 e 100 caracteres')
            .max(100, 'O nome deve ter entre 3 e 100 caracteres')
            .required('É necessário informar o nome'),
        email: yup.string().email('Formato de email inválido').required('É necessário informar um endereço de email.'),
        phone: yup.string().min(11, 'Informe o número de telefone com o DDD').required('É necessário informar um número de telefone.'),
        document_type: yup.string(),
        document: yup.string().when(['document_type'], {
            is: 'cpf',
            then: CPFValidator,
            otherwise: CNPJValidator,
        }),
        password_confirmation: yup
            .string()
            .min(8, 'A senha deve possuir ao menos 8 caracteres.')
            .required('A senha deve possuir ao menos 8 caracteres.'),
        address: AddressValidator,
        billing_address: AddressValidator,
        shipping_address: AddressValidator,
    });

    const initialValues = {
        id: userData?.id || '',
        name: userData?.name || '',
        email: userData?.email || '',
        phone: userData?.phone || '',
        document: userData?.document || '',
        document_type: userData?.document_type || 'cpf',
        entity_type: userData?.entity_type || 'individual',
        rntc: userData?.rntc || '',
        address: {
            address: userData?.address?.address || '',
            number: userData?.address?.number || '',
            complement: userData?.address?.complement || '',
            reference: userData?.address?.reference || '',
            zip: userData?.address?.zip || '',
            neighborhood: userData?.address?.neighborhood || '',
            state: userData?.address?.city?.state || '',
            city_id: userData?.address?.city?.id || '',
            city_name: userData?.address?.city?.name || '',
        },
        billing_address_id: userData?.billing_address_id || null,
        billing_address: {
            address: userData?.billing_address?.address || '',
            number: userData?.billing_address?.number || '',
            complement: userData?.billing_address?.complement || '',
            reference: userData?.billing_address?.reference || '',
            zip: userData?.billing_address?.zip || '',
            neighborhood: userData?.billing_address?.neighborhood || '',
            state: userData?.billing_address?.city?.state || '',
            city_id: userData?.billing_address?.city?.id || '',
            city_name: userData?.billing_address?.city?.name || '',
        },
        shipping_address_id: userData?.shipping_address_id || null,
        shipping_address: {
            address: userData?.shipping_address?.address || '',
            number: userData?.shipping_address?.number || '',
            complement: userData?.shipping_address?.complement || '',
            reference: userData?.shipping_address?.reference || '',
            zip: userData?.shipping_address?.zip || '',
            neighborhood: userData?.shipping_address?.neighborhood || '',
            state: userData?.shipping_address?.city?.state || '',
            city_id: userData?.shipping_address?.city?.id || '',
            city_name: userData?.shipping_address?.city?.name || '',
        },
        password_confirmation: '',
    };

    const document_formats = {
        cpf: { mask: '999.999.999-99', placeholder: '___.___.___-__' },
        cnpj: { mask: '99.999.999/9999-99', placeholder: '__.___.___/____-__' },
        individual: { mask: '999.999.999-99', placeholder: '___.___.___-__' },
        company: { mask: '99.999.999/9999-99', placeholder: '__.___.___/____-__' },
    };

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper className="users_form" hideTitle>
            <div className="d-flex px-3">
                <h1 className="content-title flex-shrink-1 col-12 text-center">MINHA CONTA</h1>
            </div>
            <div className="page-content">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validations}
                    onSubmit={handleUserSubmit}
                    enableReinitialize
                    render={(formikProps) => (
                        <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                            <Field type="hidden" name="id" />
                            <Field type="hidden" name="stripe_id" />
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="name">
                                    Nome<em>*</em>
                                </Label>
                                <Field name="name" type="text" placeholder="Nome do fornecedor" className="col-12" disabled={isLoading} />
                                <ErrorMessage component="span" name="name" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="email">
                                    Email<em>*</em>
                                </Label>
                                <Field
                                    name="email"
                                    type="email"
                                    placeholder="Ex: admin@gmail.com"
                                    className="col-12"
                                    disabled={isLoading}
                                />
                                <ErrorMessage component="span" name="email" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6 col-lg-3">
                                <Label className="field-label" htmlFor="phone">
                                    Telefone<em>*</em>
                                </Label>
                                <InputMask
                                    name="phone"
                                    type="text"
                                    className="col-12"
                                    mask="(99) 99999-9999"
                                    placeholder="(__) _____-____"
                                    value={formikProps.values.phone}
                                    onChange={({ target: { value } }) => {
                                        formikProps.setFieldTouched('phone');
                                        formikProps.setValues({
                                            ...formikProps.values,
                                            ...{
                                                phone: value.toUpperCase(),
                                            },
                                        });
                                    }}
                                />
                                <ErrorMessage component="span" name="phone" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6 col-lg-3">
                                <Label className="field-label" htmlFor="document_type">
                                    Tipo de documento
                                </Label>
                                <select
                                    name="document_type"
                                    className="col-12"
                                    value={formikProps.values?.document_type || ''}
                                    onChange={(e) => formikProps.setValues({ ...formikProps.values, ...{ document_type: e.target.value } })}
                                    disabled={isLoading}
                                >
                                    <option value="cpf">CPF</option>
                                    <option value="cnpj">CNPJ</option>
                                </select>
                                <ErrorMessage component="span" name="document_type" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6 col-lg-3">
                                <Label className="field-label" htmlFor="document">
                                    Documento<em>*</em>
                                </Label>
                                <InputMask
                                    name="document"
                                    type="text"
                                    className="col-12"
                                    mask={document_formats?.[formikProps.values.document_type]?.mask || ''}
                                    placeholder={document_formats?.[formikProps.values.document_type]?.placeholder || ''}
                                    value={formikProps.values.document}
                                    onChange={({ target: { value } }) => {
                                        formikProps.setFieldTouched('document');
                                        formikProps.setValues({
                                            ...formikProps.values,
                                            ...{
                                                document: value.toUpperCase(),
                                            },
                                        });
                                    }}
                                />
                                <ErrorMessage component="span" name="document" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6 col-lg-3">
                                <Label className="field-label" htmlFor="rntc">
                                    No. RNTRC (TAC/ETC/CTC)
                                </Label>
                                <Field name="rntc" type="text" placeholder="" className="col-12" disabled={isLoading} />
                                <ErrorMessage component="span" name="rntc" className="text-error text-small px-2" />
                            </div>

                            <h3 className="field-group-title col-12 mt-3">Endereço principal</h3>
                            <AddressFields field_context="address" errors={formikProps.errors?.address || {}} />
                            <h3 className="field-group-title col-12 mt-3">Endereço de cobrança</h3>
                            <AddressFields field_context="billing_address" errors={formikProps.errors?.billing_address || {}} />

                            <h3 className="field-group-title col-12 mt-3">Endereço de entrega</h3>
                            <AddressFields field_context="shipping_address" errors={formikProps.errors?.shipping_address || {}} />

                            <h3 className="field-group-title col-12 mt-3">Confirmação de senha</h3>
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Field name="disabled-password-2" type="password" style={{ display: 'none' }} autoComplete="password-1" />
                                <Field name="disabled-password-1" type="password" style={{ display: 'none' }} autoComplete="password-2" />
                                <Label className="field-label" htmlFor="password_confirmation">
                                    Informe sua senha<em>*</em>
                                </Label>
                                <Field
                                    name="password_confirmation"
                                    component={PasswordField}
                                    className="login-field col-12 mb-1"
                                    autoComplete="new-password"
                                    readOnly
                                    onFocus={(e) => e.target.removeAttribute('readonly')}
                                />
                                <ErrorMessage component="span" name="password_confirmation" className="text-error text-small px-2" />
                            </div>

                            <div className="col-12 mt-3 d-flex justify-content-center">
                                <div className="mb-3 col-6 text-center">
                                    <Button
                                        type="submit"
                                        className="col-10"
                                        color="success"
                                        disabled={formikProps.errors?.password_confirmation || formikProps.isSubmitting || isLoading}
                                    >
                                        SALVAR
                                    </Button>
                                </div>
                            </div>
                            <FormikDevTools />
                        </Form>
                    )}
                />
            </div>
        </ContentWrapper>
    );
}

export default Account;
