import React from 'react';
import AppFooter from '@app/pages/shared/AppFooter/AppFooter';

function Terms() {
    return (
        <section className="d-flex flex-column align-items-center">
            <div className="d-flex col-10 col-md-8" title="">
                <div className="page-content my-3">
                    <h1>
                        Termos de Uso - <strong>Doutor Caminhoneiro</strong>
                    </h1>

                    <p>
                        Bem-vindo ao Marketplace <strong>Doutor Caminhoneiro</strong>! Ao acessar, usar ou se cadastrar em nossa plataforma,
                        você concorda com os termos e condições descritos abaixo. Caso você não concorde com estes termos, não utilize a
                        nossa plataforma.
                    </p>

                    <h2>1. Definições</h2>
                    <p>
                        Nestes Termos de Uso, os termos &quot;nós&quot;, &quot;nosso&quot; e &quot;<strong>Doutor Caminhoneiro</strong>
                        &quot; referem-se à plataforma de Marketplace <strong>Doutor Caminhoneiro</strong> da <strong>TRANSPROLOG</strong>.
                        &quot;Você&quot; e &quot;seu&quot; referem-se ao usuário da plataforma.
                    </p>

                    <h2>2. Descrição do Serviço</h2>
                    <p>
                        O <strong>Doutor Caminhoneiro</strong> é um Marketplace que tem por objetivo oferecer cursos, serviços e produtos
                        destinados a pequenos e médios profissionais do ramo de transportes a fim de ajudá-los a iniciar, regularizar ou
                        melhorar seus métodos de trabalho. A plataforma oferece um espaço para a venda de produtos e serviços por parte de
                        terceiros e para a oferta de cursos ministrados por profissionais do ramo.
                    </p>

                    <h2>3. Cadastro na Plataforma</h2>
                    <p>
                        Para utilizar os serviços oferecidos pelo <strong>Doutor Caminhoneiro</strong> da <strong>TRANSPROLOG</strong>, é
                        necessário que o usuário realize o seu cadastro na plataforma. Ao se cadastrar, o usuário declara ser maior de idade
                        e fornecer informações precisas e verdadeiras. O <strong>Doutor Caminhoneiro</strong> da{' '}
                        <strong>TRANSPROLOG</strong> não se responsabiliza por informações incorretas ou incompletas fornecidas pelo
                        usuário.
                    </p>

                    <h2>4. Obrigações do Usuário</h2>
                    <p>
                        Ao utilizar a plataforma do <strong>Doutor Caminhoneiro</strong> da <strong>TRANSPROLOG</strong>, o usuário
                        compromete-se a:
                    </p>
                    <ul>
                        <li>Utilizar a plataforma em conformidade com a legislação brasileira;</li>
                        <li>
                            Não violar os direitos autorais ou de propriedade intelectual dos produtos e serviços oferecidos na plataforma;
                        </li>
                        <li>Não utilizar a plataforma para fins ilegais ou não autorizados;</li>
                        <li>Não realizar spam ou qualquer outra atividade que possa prejudicar a plataforma ou outros usuários;</li>
                        <li>
                            Não utilizar a plataforma para a comercialização de produtos ou serviços que não estejam de acordo com os
                            objetivos do Marketplace <strong>Doutor Caminhoneiro</strong>.
                        </li>
                    </ul>

                    <h2>5. Propriedade Intelectual</h2>
                    <p>
                        O conteúdo da plataforma do <strong>Doutor Caminhoneiro</strong>, incluindo textos, imagens, vídeos, logotipos e
                        marcas registradas, são de propriedade de <strong>TRANSPROLOG</strong> ASSESSORIA EM CONTABILIDADE, LOGÍSTICA,
                        TECNOLOGIA E PUBLICIDADE LTDA. e/ou de seus parceiros e fornecedores de conteúdo e está protegido pelas leis de
                        propriedade intelectual brasileiras e internacionais.
                    </p>

                    <h2>
                        6. Responsabilidade do <strong>Doutor Caminhoneiro</strong>
                    </h2>
                    <p>
                        O <strong>Doutor Caminhoneiro</strong> da <strong>TRANSPROLOG</strong> não se responsabiliza por eventuais danos
                        causados ao usuário ou a terceiros decorrentes do uso da plataforma, salvo nos casos previstos em lei. O{' '}
                        <strong>Doutor Caminhoneiro</strong> não garante que a plataforma estará sempre disponível e livre de erros,
                        interrupções ou falhas. O usuário reconhece que o uso da plataforma é de sua responsabilidade e risco.
                    </p>

                    <h2>7. Responsabilidade do Usuário</h2>
                    <p>
                        O usuário é responsável pelo uso adequado da plataforma e por garantir que suas atividades na plataforma estejam em
                        conformidade com as leis aplicáveis. O usuário é responsável por manter a confidencialidade de suas credenciais de
                        acesso e por todas as atividades que ocorrerem em sua conta.
                    </p>

                    <h2>8. Links Externos</h2>
                    <p>
                        A plataforma do <strong>Doutor Caminhoneiro</strong> pode conter links para outros sites que não são de propriedade
                        ou controlados pelo <strong>Doutor Caminhoneiro</strong> da <strong>TRANSPROLOG</strong>. O{' '}
                        <strong>Doutor Caminhoneiro</strong> da <strong>TRANSPROLOG</strong> não se responsabiliza pelo conteúdo, políticas
                        de privacidade ou práticas de sites de terceiros.
                    </p>

                    <h2>9. Modificações dos Termos de Uso</h2>
                    <p>
                        O <strong>Doutor Caminhoneiro</strong> da <strong>TRANSPROLOG</strong> se reserva o direito de modificar estes
                        Termos de Uso a qualquer momento, a seu exclusivo critério e sem aviso prévio ao usuário. O uso contínuo da
                        plataforma após a modificação dos Termos de Uso constitui aceitação dos termos modificados pelo usuário.
                    </p>

                    <h2>10. Lei Aplicável e Foro</h2>
                    <p>
                        Estes Termos de Uso serão regidos e interpretados de acordo com as leis brasileiras. Para a solução de quaisquer
                        controvérsias decorrentes do presente instrumento, as partes elegem o foro da Comarca de Valinhos, no estado de São
                        Paulo.
                    </p>

                    <p>
                        Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco através de um dos canais abaixo:
                        <br />
                        <br />
                        <ul>
                            <li>
                                E-mail:{' '}
                                <strong>
                                    <a href="mailto:contato@doutorcaminhoneiro.com.br">contato@doutorcaminhoneiro.com.br</a>
                                </strong>
                            </li>
                            <li>
                                E-mail:{' '}
                                <strong>
                                    <a href="mailto:contato@transprolog.com">contato@transprolog.com</a>
                                </strong>
                            </li>
                            <li>
                                Whatsapp:{' '}
                                <strong>
                                    <a href="https://web.whatsapp.com/send?phone=5519993142939">(19) 99314 2939</a>
                                </strong>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
            <AppFooter />
        </section>
    );
}
export default Terms;
