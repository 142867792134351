import React, { useState, useEffect, useCallback, useMemo } from 'react';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { URLs } from '@app/constants';

import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';
import FormsTable from './table/FormsTable';
import AccountTabsMenu from '../components/AccountTabsMenu';

function Forms() {
    const [descriptors, setDescriptors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const loadFormsData = useCallback((section) => {
        if (section === '') {
            setDescriptors([]);
            return;
        }
        const url = URLs.club.forms.list;
        setIsLoading(true);
        Api({ method: 'get', url })
            .then(({ data }) => {
                setIsLoading(false);
                setDescriptors(data);
            })
            .catch(() => {
                toast.error('Houve um erro inesperado ao carregar o conteúdo da página!');
            });
    }, []);

    useEffect(() => {
        loadFormsData();
    }, [loadFormsData]);

    const content = useMemo(() => {
        if (isLoading) return <LoadingSpinner />;
        if ((descriptors?.length || 0) === 0) {
            return <div className="col-12 text-center">Nenhum formulário criado para esta seção</div>;
        }
        return descriptors.map((descriptor, index) => (
            <div key={index} className="d-flex flex-column col-12">
                <h3 className="field-group-title">{descriptor?.content?.name || ''}</h3>
                <FormsTable descriptor={descriptor} key={index} />
            </div>
        ));
    }, [isLoading, descriptors]);

    return (
        <ContentWrapper title="">
            <AccountTabsMenu active="forms" />
            <div className="page-content">
                <h2 className="text-center col-12 bg-primary br-4 p-1 text-white text-large mt-1">MEUS FORMULÁRIOS</h2>
                <p className="my-2 text-center">
                    Abaixo você pode conferir todos os formulários enviados por você para a plataforma <strong>Doutor Caminhoneiro</strong>.
                </p>
                {content}
            </div>
        </ContentWrapper>
    );
}

export default Forms;
