import React, { useState } from 'react';
import { useUserManager } from '@app/hooks/useUserManager';

import './CustomerProfile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import CustomerFormPopup from './CustomerFormPopup';

export function CustomerPendenciesBanner({ onSave = () => {} }) {
    const UserManager = useUserManager();
    const { hasPendencies } = useSelector((state) => state.auth);
    const [popupIsOpen, setPopupIsOpen] = useState(false);

    if (!hasPendencies) return null;

    const openCustomerFormPopup = () => setPopupIsOpen(true);
    const closeCustomerFormPopup = () => setPopupIsOpen(false);

    return (
        <div className="p-3">
            <div
                role="button"
                tabIndex={0}
                onClick={() => openCustomerFormPopup(UserManager.id, UserManager.name)}
                className="d-flex flex-column align-items-center justify-content-center customer-pendencies-banner"
                title="Você possui pendências no seu cadastro"
            >
                <span className="banner-title d-flex align-items-center justify-content-center ">
                    Você possui pendências no seu cadastro
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mx-2 my-1" />
                </span>
                <p>
                    Clique aqui para completar seu cadastro e continuar aproveitando todos os benefícios que o{' '}
                    <strong>Doutor Caminhoneiro</strong> preparou especialmente para você.
                </p>
            </div>
            {popupIsOpen && <CustomerFormPopup isOpen={popupIsOpen} onClose={closeCustomerFormPopup} onSave={onSave} />}
        </div>
    );
}

export function CustomerPendenciesBadge({ showText = true }) {
    const { hasPendencies } = useSelector((state) => state.auth);

    if (!hasPendencies) return null;

    return (
        <div
            className="d-flex flex-shrink-1 align-items-center justify-content-center user-pendencies"
            title="Você possui pendências no seu cadastro"
        >
            {showText ? 'COMPLETE SEU CADASTRO' : ''}
            <FontAwesomeIcon icon={faExclamationTriangle} className="mx-2 my-1" />
        </div>
    );
}
