import React from 'react';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function EmoticonSelectorPopup({ isOpen = false, closeModal = () => {}, onSelectEmoticon = () => {}, ...props }) {
    const afterOpenModal = () => {};

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="SELECIONAR UM ÍCONE"
            ariaHideApp={false}
            {...props}
        >
            <EmojiPicker
                style={{ position: 'absolute' }}
                lazyLoad
                emojiStyle={EmojiStyle.FACEBOOK}
                width="100%"
                onEmojiClick={(emoji) => onSelectEmoticon(emoji)}
                searchDisabled
                previewConfig={{
                    showPreview: false,
                }}
            />
        </Modal>
    );
}
