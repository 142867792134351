import { useHistory, useLocation } from 'react-router';
import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import { useDispatch } from 'react-redux';
import { setSummary } from '@app/store/SummarySlice';
import { setHasPendencies } from '@app/store/AuthSlice';

export const useUserManager = () => {
    const replaceParams = useReplaceParams;
    const history = useHistory();
    const location = useLocation();
    const dispatcher = useDispatch();

    const data = JSON.parse(localStorage.getItem('user-data'));
    const token = data?.access_token || '';

    return {
        name: data?.user?.name || '',
        email: data?.user?.email || '',
        phone: data?.user?.phone || undefined,
        type_label: data?.user?.type_label || undefined,
        context: data?.user?.context || undefined,
        billing_address: data?.user?.billing_address || null,
        shipping_address: data?.user?.shipping_address || null,
        token,
        rpp: data?.config?.rpp || 30,
        rppi: data?.config?.rppi || 30,
        sections: data?.sections || null,
        isLogged: token !== null && token !== undefined && token.length > 0,
        hasPendencies: data?.user?.hasPendencies,

        getSectionByUrl: (url) => {
            const section = data?.sections.filter((section) => url.startsWith(section.url))[0];
            return section || null;
        },
        currentSection: () => {
            const url = location.pathname.replaceAll('/admin', '');
            const section = data?.sections.filter((section) => url.startsWith(section.url))[0];
            return section;
        },
        checkPermission: (permission, section_url = null) => {
            const url = section_url || location.pathname.replaceAll('/admin', '');
            const section = data?.sections.filter((section) => url.startsWith(section.url))[0] || null;
            return section !== null && ((section?.features || []).includes(permission) || permission === 'list');
        },
        getHomeSectionUrl: () => ((data?.sections || []).length > 0 ? data?.sections[0].url : '/not_found'),

        updateSummary: () => {
            const url = replaceParams(URLs.services.summary, { nocache: new Date().getTime() });
            Api({ method: 'get', url })
                .then((response) => {
                    if (response?.data && response?.status === 200) {
                        dispatcher(setSummary(response?.data));
                    }
                })
                .catch(() => {});
        },
        register: async (customer) =>
            Api.post(URLs.auth.register, customer)
                .then((resp) => {
                    const { data } = resp;
                    if (data) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { status: null, data: null, message: 'Erro ao efetuar cadastro!' };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.status } };
                    }
                    return { ...err?.data, ...{ status: err.status } };
                }),
        // return response;
        login: async (username, password) => {
            const response = await Api({ method: 'post', url: URLs.auth.login, data: { username, password } })
                .then((resp) => {
                    const { data } = resp;
                    if (data) {
                        const expiresIn = (data.expires_in - 5) * 60000;
                        const refreshTokenAfter = new Date().getTime() + expiresIn;

                        localStorage.setItem('user-data', JSON.stringify(data));
                        localStorage.setItem('refresh', refreshTokenAfter);
                        localStorage.removeItem('retrying');
                        dispatcher(setHasPendencies(data?.user?.hasPendencies || false));
                        history.push(`/${data?.user?.context === 'admin' ? 'admin' : ''}`);
                    } else {
                        return { status: null, data: null, message: 'Erro ao efetuar login!' };
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao efetuar login.' };
                });
            return response;
        },
        refresh: async () => {
            const response = await Api({ method: 'post', url: URLs.auth.refresh })
                .then(({ data }) => {
                    if (!data) return { status: null, data: null, message: 'Erro ao obter dados do usuário!' };
                    const expiresIn = (data.expires_in - 5) * 60000;
                    const refreshTokenAfter = new Date().getTime() + expiresIn;

                    localStorage.setItem('user-data', JSON.stringify(data));
                    localStorage.setItem('refresh', refreshTokenAfter);
                    localStorage.removeItem('retrying');
                    dispatcher(setHasPendencies(data?.user?.hasPendencies || false));
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao obter dados do usuário.' };
                });
            return response;
        },
        updatePendenciesStatus: () => {
            dispatcher(setHasPendencies(data?.user?.hasPendencies || false));
        },
        logout: async () => {
            Api.post(URLs.auth.logout)
                .then(() => {
                    history.push(data?.user?.context === 'admin' ? '/login/admin' : '/login');
                    localStorage.removeItem('user-data');
                    localStorage.removeItem('refresh');
                })
                .catch(() => {
                    history.push(data?.user?.context === 'admin' ? '/login/admin' : '/login');
                    localStorage.removeItem('user-data');
                    localStorage.removeItem('refresh');
                });
        },
        forgot: async (channel, value) => {
            const response = await Api.post(URLs.auth.forgot, { channel, value })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar recuperar a conta.' };
                });
            return response;
        },
        validateVerificationCode: async (code) => {
            const response = await Api.post(URLs.auth.validate_code, { code })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar recuperar a conta.' };
                });
            return response;
        },
        confirmPhoneNumber: async (code) => {
            const response = await Api.post(URLs.auth.confirm_phone, { code })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar confirmar o número de telefone.' };
                });
            return response;
        },
        reset: async (token, password) => {
            const response = await Api.post(URLs.auth.reset, { token, password })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar redefinir a senha.' };
                });
            return response;
        },
        resetPassword: async (token, password, new_password) => {
            const response = await Api.post(URLs.auth.reset_password, { token, password, new_password })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        localStorage.setItem('user-data', JSON.stringify(data));
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar redefinir a senha.' };
                });
            return response;
        },
        resetConfig: async (token, rpp, rppi) => {
            const response = await Api.post(
                URLs.auth.config,
                { rpp, rppi },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        // Atualizando cookie
                        const cookie = JSON.parse(localStorage.getItem('user-data'));
                        cookie.config.rpp = rpp;
                        cookie.config.rppi = rppi;
                        localStorage.setItem('user-data', JSON.stringify(cookie));

                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return (
                        err?.data || {
                            status: null,
                            data: null,
                            message: 'Erro inesperado ao tentar redefinir as configurações do usuário.',
                        }
                    );
                });
            return response;
        },
    };
};
