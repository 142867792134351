import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import FilterContainer, { FilterFields, FilterTab, FilterTabs } from '@app/containers/FilterContainer/FilterContainer';
import { Row, Form } from 'reactstrap';
import { useFetch } from '@app/hooks/useFetch';

import { useUserManager } from '@app/hooks';

import { ProductTypes, URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';

import Paginator from '@app/components/Paginator/Paginator';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import CheckPermission from '@app/components/CheckPermission';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProductsContext from './components/ProductsContext';

import ProductsTable from './tables/ProductsTable';
import { productTypeIcons } from './components/ProductTypeIcons';

function Products({ product_type = '' }) {
    const UserManager = useUserManager();
    const { token } = UserManager;

    const replaceParams = useReplaceParams;

    /**
     * Consulta dados de produtos
     */
    const [productsParams, setProductsParams] = useState({
        nocache: new Date().getTime(),
        name: '',
        type: product_type || '',
        pg: 1,
        orderBy: '',
        orderDir: 'asc',
    });
    const { data, error, last_page } = useFetch(replaceParams(URLs.admin.products.list, productsParams));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
    }, [data, error]);

    /**
     * Filtros de pesquisa
     */
    const handleProductTypeChange = (type) => setProductsParams((prev) => ({ ...prev, ...{ type, pg: 1 } }));
    const handleNameChange = (e) => setProductsParams((prev) => ({ ...prev, ...{ name: e.target.value, pg: 1 } }));
    const handleOrderBy = (orderBy, orderDir) => setProductsParams((prev) => ({ ...prev, ...{ orderBy, orderDir, pg: 1 } }));

    const handleNavigateFirst = () => setProductsParams((prev) => ({ ...prev, ...{ pg: 1 } }));
    const handleNavigateLast = () => setProductsParams((prev) => ({ ...prev, ...{ pg: last_page } }));
    const handleNavigateNext = () =>
        setProductsParams((prev) => ({ ...prev, ...{ pg: data?.length > 0 ? productsParams.pg + 1 : productsParams.pg } }));
    const handleNavigatePrevious = () =>
        setProductsParams((prev) => ({ ...prev, ...{ pg: productsParams.pg > 1 ? productsParams.pg - 1 : 1 } }));

    const handleProductStateChange = (e) => {
        const url = replaceParams(URLs.admin.products.status, { id: e.data.id });
        Api({
            method: 'put',
            url,
            data: {
                status: e.data.checked ? 1 : 0,
            },
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status do produto.');
                    e.data.setStatus(!e.data.checked);
                } else {
                    toast.success(response?.data?.message);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status do produto!');
                e.data.setStatus(!e.data.checked);
            });
    };

    return (
        <ContentWrapper title={false && ProductTypes[productsParams.type].title}>
            <FilterContainer showTitle={false} className="show-border">
                <FilterFields>
                    <Row />
                </FilterFields>
                <FilterTabs active="all">
                    <FilterTab key="all" active={productsParams.type === ''} to="/produtos" onClick={() => handleProductTypeChange('')}>
                        Todos
                    </FilterTab>
                    <FilterTab
                        key="object"
                        active={productsParams.type === 'object'}
                        to="/produtos"
                        onClick={() => handleProductTypeChange('object')}
                    >
                        Objetos
                    </FilterTab>
                    <FilterTab
                        key="video"
                        active={productsParams.type === 'video'}
                        to="/produtos"
                        onClick={() => handleProductTypeChange('video')}
                    >
                        Vídeos
                    </FilterTab>
                    <FilterTab
                        key="document"
                        active={productsParams.type === 'document'}
                        to="/produtos"
                        onClick={() => handleProductTypeChange('document')}
                    >
                        Documentos
                    </FilterTab>
                    <FilterTab
                        key="service"
                        active={productsParams.type === 'service'}
                        to="/produtos"
                        onClick={() => handleProductTypeChange('service')}
                    >
                        Serviços
                    </FilterTab>
                    <FilterTab
                        key="pack"
                        active={productsParams.type === 'pack'}
                        to="/pacotes"
                        onClick={() => handleProductTypeChange('pack')}
                    >
                        Pacotes
                    </FilterTab>
                </FilterTabs>
            </FilterContainer>
            <FilterContainer title="Filtros">
                <FilterFields>
                    <Form className="d-flex mb-3 flex-wrap">
                        <fieldset className="col-3">
                            <label htmlFor="accessName">Nome</label>
                            <input type="text" name="accessName" value={productsParams?.name} onChange={handleNameChange} />
                        </fieldset>
                        <CheckPermission permission="create">
                            <fieldset className="">
                                <a href="/admin/produtos/novo" className="btn btn-primary btn-medium btn-icon-left ">
                                    <FontAwesomeIcon icon={faPlus} className="mx-2" />
                                    Novo Produto
                                </a>
                            </fieldset>
                        </CheckPermission>
                        <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <ProductsContext.Provider
                value={{
                    handleProductStateChange,
                    productTypeIcons,
                }}
            >
                <div className="page-content">
                    <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                        <Paginator
                            pg={productsParams.pg}
                            last_page={last_page}
                            hasPrevious={productsParams.pg > 1}
                            hasNext={(last_page !== null && last_page > productsParams.pg) || (last_page === null && data?.length > 0)}
                            handleNavigateFirst={handleNavigateFirst}
                            handleNavigateNext={handleNavigateNext}
                            handleNavigatePrevious={handleNavigatePrevious}
                            handleNavigateLast={handleNavigateLast}
                        />
                    </div>
                    <ProductsTable type={productsParams.type} data={data} handleOrderBy={handleOrderBy} />
                    <Paginator
                        pg={productsParams.pg}
                        last_page={last_page}
                        hasPrevious={productsParams.pg > 1}
                        hasNext={(last_page !== null && last_page > productsParams.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                </div>
            </ProductsContext.Provider>
        </ContentWrapper>
    );
}

export default Products;
