import React, { useState } from 'react';
import { DropDown, DropdownItem } from '@app/components/Autocomplete';
import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import { toast } from 'react-hot-toast';
import { useFormikContext } from 'formik';

function SuppliersSearch({ name = '', onSuggestionSelected = null, ...props }) {
    const replaceParams = useReplaceParams;
    const { values, setValues } = useFormikContext();
    /**
     * Consulta por cidades
     */
    const [suggestions, setSuggestions] = useState([]);
    const onSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason !== 'input-changed') return;
        try {
            Api({
                method: 'get',
                url: replaceParams(URLs.admin.suppliers.basics, { name: value || '' }),
            })
                .then((response) => {
                    setSuggestions(response.data);
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao tentar consultar o fornecedor.');
                });
        } catch (error) {
            // console.log(error);
        }
    };

    const handleSuggestionSelected = (suggestion) => {
        setSuggestions([]);
        if (onSuggestionSelected !== null) {
            return onSuggestionSelected(suggestion);
        }
        setValues((prev) => ({
            ...prev,
            supplier_id: suggestion.id,
            supplier: {
                id: suggestion.id,
                name: suggestion.name,
                token: suggestion.token,
            },
            product_id: null,
            product: {
                id: null,
                name: '',
                token: '',
            },
        }));
        return suggestion.name;
    };

    // Render the suggested user list
    const renderSuggestions = (suggestion) => (
        <DropdownItem tag="label" className="suggestion-item" key={suggestion.id}>
            {suggestion.name}
        </DropdownItem>
    );
    return (
        <DropDown
            className="col-12"
            {...props}
            name="suppliers-search"
            placeholder="Buscar fornecedor"
            selectedValue={name}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            renderSuggestion={renderSuggestions}
            getSuggestionValue={(suggestion) => {
                handleSuggestionSelected(suggestion);
            }}
            formValues={values}
            onSetValues={setValues}
        />
    );
}

export default SuppliersSearch;
