import React, { useMemo } from 'react';
import { Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';

export default function DateField({
    field,
    formikProps: {
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        disabled = false,
        hidden = false,
        required = false,
        label = '',
    },
}) {
    const value = useMemo(() => {
        const dt = moment(values?.[field.name] || field.default, 'DD/MM/YYYY');
        return dt.isValid() ? dt.toDate() : '';
    }, [values, field]);

    return (
        <div className={`${field.style} ${hidden ? 'd-none' : ''}`} key={field.name}>
            <Label className="field-label" htmlFor="name">
                {label}
                {required ? <em>*</em> : ''}
            </Label>
            <DatePicker
                className="col-12 flex-shrink-1"
                dateFormat="dd/MM/yyyy"
                isClearable
                clearButtonTitle="Limpar"
                clearButtonClassName="px-1"
                selected={value}
                onChange={(value) => {
                    const dt = new Date(value);
                    const newValue = moment(dt).format('DD/MM/YYYY');
                    setFieldTouched(field.name);
                    setFieldValue(field.name, newValue, true);
                }}
                onBlur={() => {
                    setFieldTouched(field.name);
                }}
                disabled={disabled || isSubmitting}
            />
            {errors?.[field.name] && <span className="col-12 text-error text-small px-2">{errors?.[field.name]}</span>}
        </div>
    );
}
