import React, { useContext, useMemo } from 'react';
import parse from 'html-react-parser';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';
import { LabelTip } from '@app/components/ui';
import SubscriptionContext from '../SubscriptionContext';

import './SubscriptionDetails.scss';

function SubscriptionPrice({ amount = 0.0, description = '', ...props }) {
    return (
        <div className="subscription-plan-price col-11 col-sm-10 my-3 p-3 d-flex flex-wrap" {...props}>
            <div className="col-7">{description}</div>
            <div className="col-5 text-right">R$ {useFloatToCurrency(amount)}</div>
        </div>
    );
}
export default function SubscriptionDetails({ className = '' }) {
    const { subscription } = useContext(SubscriptionContext);

    const theme = useMemo(() => {
        const response = { container: {}, header: {}, body: {}, price: {} };
        if ((subscription?.theme?.light || null) !== null && (subscription?.theme?.title || null) !== null) {
            response.price = {
                backgroundColor: subscription?.theme?.light,
                color: subscription?.theme?.title,
                borderColor: subscription?.theme?.title,
            };
            response.header = { backgroundColor: subscription?.theme?.light, color: subscription?.theme?.title };
        }
        if ((subscription?.theme?.bg || null) !== null) response.container = { backgroundColor: subscription?.theme?.bg };
        if ((subscription?.theme?.text || null) !== null) response.body = { color: subscription?.theme?.text };

        return response;
    }, [subscription]);

    const items = useMemo(() => {
        if ((subscription?.items || []).length === 0) return null;
        return (
            <div className="col-12 d-flex flex-column px-3 text-primary">
                <h3>Itens da Assinatura</h3>
                {subscription.items.map((item, index) => (
                    <div key={index} className="d-flex flex-wrap border-bottom mb-1 col-12">
                        <span className="col-1">
                            <strong>{item.qtd}</strong>
                        </span>
                        <span className="col-11 col-md-9 flex-shrink-1">{item.product.name}</span>
                        <span className="d-flex col-12 col-md-2 text-small align-items-center">
                            {item.recurrence_type === 'unlimited'
                                ? 'recorrente'
                                : `${item.remaining_cycles} ciclo${item.remaining_cycles === 1 ? '' : 's'}`}
                        </span>
                    </div>
                ))}
            </div>
        );
    }, [subscription]);

    return (
        <div className={`subscription-plan-details d-flex ${className}`} style={theme.container}>
            <div className="col-12 mt-3 px-3 py-1 d-flex flex-column align-items-center">
                <div className="plan-details-header p-1 col-12" style={theme.header}>
                    <h2 className="text-center fw-bold">{subscription?.name}</h2>
                    <h3 className="text-center fw-bold">{subscription?.description}</h3>
                </div>
                <div className="col-12 p-3" style={theme.body}>
                    {parse(subscription?.details || '')}
                    {items}
                </div>
                {(subscription?.has_shipping || false) && (
                    <div className="col-12 px-3 my-0 text-small text-error">
                        * Este plano inclui itens que possuem serviço de postagem
                        <LabelTip className="mx-1">
                            Este plano inclui objetos físicos que demandam entrega via serviço de postagem.
                            <br />
                            Verifique seu endereço de entrega antes de prosseguir com a assinatura.
                        </LabelTip>
                    </div>
                )}
                <SubscriptionPrice amount={subscription.amount} description={subscription.price_description} style={theme.price} />
            </div>
        </div>
    );
}
