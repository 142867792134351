import React, { useCallback, useEffect, useState } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import { toastConfirmation } from '@app/components/ui';
import ButtonAddNewCard from '@app/components/UserCards/ButtonAddNewCard';

import toast from 'react-hot-toast';
import AccountTabsMenu from '../components/AccountTabsMenu';

import CardsTable from './table/CardsTable';

export default function Cards() {
    const replaceParams = useReplaceParams;
    const [isSubmitting, setSubmitting] = useState(false);
    const [cards, setCards] = useState([]);

    // Carrega os dados do produto
    const loadCardsData = useCallback(() => {
        const url = replaceParams(URLs.services.account.cards.list, {
            nocache: new Date().getTime(),
            card_type: 'credit_card,debit_card',
        });
        Api({ method: 'get', url })
            .then((response) => {
                if (response.data && response.status === 200) {
                    setCards(response.data);
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch((err) => {
                toast.error(err?.data?.message || 'Erro inesperado ao obter os dados do usuário');
                setSubmitting(false);
            });
    }, [replaceParams]);

    useEffect(() => {
        loadCardsData();
    }, [loadCardsData]);

    const onSetCardAsDefault = useCallback(
        (e) => {
            const { cardId, checked, setStatus } = e.data;

            setSubmitting(true);
            const url = replaceParams(URLs.services.account.cards.default, {
                nocache: new Date().getTime(),
                id: cardId,
            });

            Api({ method: 'post', url })
                .then(({ data, status }) => {
                    if (data && status === 200) {
                        loadCardsData();
                    } else {
                        toast.error(data?.message || 'Houve um erro ao definir o cartão como padrão');
                        setSubmitting(false);
                        setStatus(checked === 1 ? 0 : 1);
                    }
                })
                .catch((err) => {
                    toast.error(err?.data?.message || 'Erro inesperado ao definir o cartão como padrão');
                    setSubmitting(false);
                    setStatus(checked === 1 ? 0 : 1);
                });
        },
        [setSubmitting, replaceParams, loadCardsData]
    );

    const onDeleteCard = useCallback(
        (cardId, description) => {
            toastConfirmation(
                () => {
                    setSubmitting(true);
                    const url = replaceParams(URLs.services.account.cards.delete, {
                        nocache: new Date().getTime(),
                        id: cardId,
                    });

                    Api({ method: 'delete', url })
                        .then(({ data, status }) => {
                            if (data && status === 200) {
                                loadCardsData();
                                toast.success(data?.message || 'Cartão removido com sucesso');
                            } else {
                                toast.error(data?.message || 'Houve um erro ao tentar excluir o cartão');
                                setSubmitting(false);
                            }
                        })
                        .catch((err) => {
                            toast.error(err?.data?.message || 'Erro inesperado ao tentar excluir o cartão');
                            setSubmitting(false);
                        });
                },
                <span>
                    Tem certeza que deseja excluir o cartão <strong className="text-small">{description}</strong>?
                </span>
            );
        },
        [setSubmitting, replaceParams, loadCardsData]
    );

    return (
        <ContentWrapper className="users_form" hideTitle>
            <AccountTabsMenu active="cards" />
            <div className="page-content">
                <h2 className="text-center col-12 bg-primary br-4 p-1 text-white text-large mt-1">MEUS CARTÕES</h2>
                <CardsTable data={cards} isSubmitting={isSubmitting} onSetCardAsDefault={onSetCardAsDefault} onDeleteCard={onDeleteCard} />
                <div className="d-flex col-12 align-items-center">
                    <ButtonAddNewCard updateRequest={loadCardsData} />
                </div>
            </div>
        </ContentWrapper>
    );
}
