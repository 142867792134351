import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas, faTimes } from '@fortawesome/free-solid-svg-icons';

import './DataForm.scss';
import { useFormikContext } from 'formik';

import './ChooseIconPopup.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function ChooseIconPopup({ isOpen, closeModal, ...props }) {
    const { values, setFieldValue } = useFormikContext();

    const afterOpenModal = () => {};

    const selectIcon = (icon) => {
        setFieldValue('icon', icon);
        closeModal();
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="SELECIONAR UM ÍCONE"
            ariaHideApp={false}
            {...props}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">SELECIONE UM ÍCONE</h2>
                    <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={closeModal}>
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    </Button>
                </Col>
            </Row>
            <div className="d-flex col flex-wrap icon-list">
                {Object.keys(fas).map((icon, id) => {
                    const ico = fas[icon];
                    return (
                        <button
                            type="button"
                            key={id}
                            title={`{${icon}} ${ico.prefix}-${ico.iconName}`}
                            className={`${
                                values.icon === icon ? 'active' : ''
                            } icon d-flex flex-wrap justify-content-center align-items-center outer-shadow m-1`}
                            style={{ width: 40, height: 40, overflow: 'hidden' }}
                            onClick={() => setFieldValue('icon', icon)}
                            onDoubleClick={() => selectIcon(icon)}
                            onKeyDown={(key) => {
                                console.log('Key: ', key);
                            }}
                        >
                            <FontAwesomeIcon icon={fas[icon]} />
                        </button>
                    );
                })}
            </div>
        </Modal>
    );
}

export default ChooseIconPopup;
