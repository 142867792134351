import { faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import ProductsContext from '../ProductsContext';

function FormSliceGallery({ isLoading, className }) {
    const { values, setValues } = useFormikContext();
    const { setImageFiles } = useContext(ProductsContext);

    /**
     * Funções da galeria de imagens
     */
    //    let imageFiles = {};
    const handleProductGalleryImageSelect = (target, values, setValues) => {
        if (target.files.length > 0) {
            const fieldName = `image_${new Date().getTime()}`;
            const order = values.gallery.length;
            // Incluindo a imagem na galeria
            const newGallery = [
                ...JSON.parse(JSON.stringify(values?.gallery || [])),
                ...[
                    {
                        action: 'create',
                        fieldName,
                        token: '',
                        filename: target.files[0].name,
                        order,
                        url: URL.createObjectURL(target.files[0]),
                    },
                ],
            ];
            setValues((prev) => ({
                ...prev,
                ...{
                    gallery: newGallery,
                },
            }));
            // Inserindo arquivo na lista de arquivos a serem postados
            setImageFiles((prev) => ({
                ...prev,
                ...{
                    [`${fieldName}`]: target.files[0],
                },
            }));
        }
    };
    const deleteImageFromGallery = (imageIndex, values, setValues) => {
        // Removendo arquivo da lista
        if ((values.gallery[imageIndex]?.fieldName || null) !== null)
            setImageFiles((prev) => {
                const newSet = { ...prev };
                delete newSet.imageFiles[values.gallery[imageIndex].fieldName];
                return newSet;
            });

        // Removendo item da galeria
        setValues((prev) => {
            prev.gallery.splice(imageIndex, 1);
            return prev;
        });
    };

    return (
        <div className={`product-image-gallery mb-1 px-1 col-12 d-flex flex-wrap ${className}`}>
            <div className="col-12">
                <label className={`btn btn-secondary btn-icon-left ${values?.gallery?.length >= 6 || isLoading ? 'disabled' : ''}`}>
                    <FontAwesomeIcon icon={faUpload} className="mx-2" />
                    Selecionar uma imagem do computador
                    <input
                        type="file"
                        name="select-new-image"
                        accept="image/png, image/gif, image/jpeg"
                        style={{ display: 'none' }}
                        onChange={(e) => handleProductGalleryImageSelect(e.target, values, setValues)}
                        disabled={values?.gallery?.length >= 6 || isLoading}
                    />
                </label>
            </div>
            <div className="images-container col-12 d-flex flex-wrap p-3">
                {values?.gallery?.map((image, key) => (
                    <div key={key} className="image col-2 px-1">
                        <span
                            role="button"
                            tabIndex={0}
                            className="delete-image"
                            onClick={() => deleteImageFromGallery(key, values, setValues)}
                        >
                            <FontAwesomeIcon icon={faTimes} className="mx-2" />
                        </span>
                        <img src={image?.url || ''} className="col-12" style={{ height: '100%' }} alt="" />
                    </div>
                ))}
            </div>
        </div>
    );
}
export default FormSliceGallery;
