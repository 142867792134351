import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import { Form } from 'reactstrap';
import { useFetch } from '@app/hooks/useFetch';

import { useUserManager } from '@app/hooks';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';

import Paginator from '@app/components/Paginator/Paginator';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import CheckPermission from '@app/components/CheckPermission';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SuppliersContext from './components/SuppliersContext';
import SuppliersTable from './tables/SuppliersTable';

function Suppliers() {
    const UserManager = useUserManager();
    const { token } = UserManager;

    const replaceParams = useReplaceParams;

    /**
     * Consulta dados de mídias
     */
    const [params, setParams] = useState({
        nocache: new Date().getTime(),
        name: '',
        pg: 1,
        orderBy: '',
        orderDir: 'asc',
    });
    const { data, error, last_page } = useFetch(replaceParams(URLs.admin.suppliers.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
    }, [data, error]);

    /**
     * Filtros de pesquisa
     */
    const handleNameChange = (e) => setParams((prev) => ({ ...prev, ...{ name: e.target.value, pg: 1 } }));
    const handleOrderBy = (orderBy, orderDir) => setParams((prev) => ({ ...prev, ...{ orderBy, orderDir, pg: 1 } }));

    const handleNavigateFirst = () => setParams((prev) => ({ ...prev, ...{ pg: 1 } }));
    const handleNavigateLast = () => setParams((prev) => ({ ...prev, ...{ pg: last_page } }));
    const handleNavigateNext = () => setParams((prev) => ({ ...prev, ...{ pg: data?.length > 0 ? params.pg + 1 : params.pg } }));
    const handleNavigatePrevious = () => setParams((prev) => ({ ...prev, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } }));

    /**
     * Altera o status do fornecedor
     */
    const handleSupplierStateChange = (e) => {
        const url = replaceParams(URLs.admin.suppliers.status, { token: e.data.token });
        Api({ method: 'put', url, data: { status: e.data.checked ? 1 : 0 }, headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status do fornecedor.');
                    e.data.setStatus(!e.data.checked);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status do fornecedor!');
                e.data.setStatus(!e.data.checked);
            });
    };

    return (
        <ContentWrapper>
            <FilterContainer title="Filtros">
                <FilterFields>
                    <Form className="d-flex mb-3 flex-wrap">
                        <fieldset className="col-3">
                            <label htmlFor="accessName">Nome</label>
                            <input type="text" name="accessName" value={params?.name} onChange={handleNameChange} />
                        </fieldset>
                        <CheckPermission permission="create">
                            <fieldset className="">
                                <a href="/admin/fornecedores/novo" className="btn btn-primary btn-icon-left ">
                                    <FontAwesomeIcon icon={faPlus} className="mx-2" />
                                    Novo fornecedor
                                </a>
                            </fieldset>
                        </CheckPermission>
                        <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <SuppliersContext.Provider
                value={{
                    handleSupplierStateChange,
                }}
            >
                <div className="page-content">
                    <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                        <Paginator
                            pg={params.pg}
                            last_page={last_page}
                            hasPrevious={params.pg > 1}
                            hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                            handleNavigateFirst={handleNavigateFirst}
                            handleNavigateNext={handleNavigateNext}
                            handleNavigatePrevious={handleNavigatePrevious}
                            handleNavigateLast={handleNavigateLast}
                        />
                    </div>
                    <SuppliersTable type={params.type} data={data} canChange handleOrderBy={handleOrderBy} />
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                </div>
            </SuppliersContext.Provider>
        </ContentWrapper>
    );
}

export default Suppliers;
