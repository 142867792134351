import React, { useState, useEffect, useCallback } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { ErrorMessage, Field, Formik } from 'formik';
import { Button, Form, Label } from 'reactstrap';
import InputMask from 'react-input-mask';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import * as yup from 'yup';

import { toast } from 'react-hot-toast';

import { LabelTip, NavigateBackButton, CustomSwitch } from '@app/components/ui';
import {
    Editor,
    EditorProvider,
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnNumberedList,
    BtnRedo,
    BtnUnderline,
    BtnUndo,
    Separator,
    Toolbar,
} from 'react-simple-wysiwyg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import FormikDevTools from '@app/components/FormikDevTools';
import { removerTagsHTML } from '@app/utils/text.utils';
import ChooseIconPopup from './popup/ChooseIconPopup';
import DynamicSectionEditor from './components/DynamicSectionEditor';

function SectionsForm(props) {
    const replaceParams = useReplaceParams;

    // Lista de vídeos disponíveis no VIMEO
    const [isLoading, setIsLoading] = useState(false);
    const [sectionToken, setSectionToken] = useState('');
    const [sectionData, setSectionData] = useState({});

    const [groupSections, setGroupSections] = useState([]);
    const loadGroupSections = useCallback(
        (section_id) => {
            const url = replaceParams(URLs.admin.sections.basics, { context: 'club', type: 'group' });
            Api({ method: 'get', url }).then(({ data }) => {
                const getSectionTree = (destination, section, level) => {
                    if (section.id === section_id || section.type !== 'group') return;
                    const label_prefix = Array(level).fill('....  ').join('');
                    destination.push({ id: section.id, label: label_prefix + section.label, url: section.url });
                    (section?.subsections || []).forEach((section) => {
                        getSectionTree(destination, section, level + 1);
                    });
                };
                const newData = [];
                data.data.forEach((section) => {
                    getSectionTree(newData, section, 0);
                });
                setGroupSections(newData);
            });
        },
        [replaceParams]
    );

    useEffect(() => {
        if ((props?.match?.params?.token || '') !== '') {
            setSectionToken(props?.match?.params?.token);
        } else {
            loadGroupSections(null);
        }
    }, [loadGroupSections, props?.match?.params?.token]);

    // Carrega os dados da seção
    const loadSectionData = useCallback(
        (token) => {
            setIsLoading(true);
            const url = replaceParams(URLs.admin.sections.show, { nocache: new Date().getTime(), token });
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setSectionData(response.data);
                        loadGroupSections(response.data.id);
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados da sessão');
                });
        },
        [loadGroupSections, replaceParams]
    );

    useEffect(() => {
        if (sectionToken && sectionToken !== '') loadSectionData(sectionToken);
    }, [sectionToken, loadSectionData]);

    // Faz o submit dos dados da seção
    const handleSectionSubmit = (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        const method = (values?.token || '') !== '' ? 'put' : 'post';
        const url = replaceParams(URLs.admin.sections[method], { token: values.token ? values.token : '' });

        Api({ method, url, data: values })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    setSectionToken(response.data.token);
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados da seção!');
                if (response?.data?.errors) setErrors(response?.data?.errors || null);
                setSubmitting(false);
            });
    };

    /**
     * Inicialização e validação do formulário
     */
    const validations = yup.object().shape({
        name: yup.string().min(3, 'O nome da seção deve ter no mínimo 3 dígitos.').required('É necessário informar o nome da seção.'),
        type: yup.string(),
        label: yup
            .string()
            .min(3, 'O texto expandido do menu deve ter no mínimo 3 dígitos.')
            .required('É necessário informar um texto para o menu expandido.'),
        short_label: yup
            .string()
            .min(3, 'O texto colapsado do menu deve ter entre 3 e 10 dígitos.')
            .required('É necessário informar um texto para o menu colapsado.'),
        url: yup.string().when(['type'], {
            is: 'group',
            then: yup.string().notRequired(),
            otherwise: yup.string().min(2, 'A URL deve conter ao menos 2 caracteres.').required('É necessário informar uma URL válida.'),
        }),
    });

    const initialValues = {
        token: sectionData?.token || '',
        name: sectionData?.name || '',
        type: sectionData?.type || 'dynamic',
        order: sectionData?.order || null,
        context: sectionData?.context || 'club',
        description: sectionData?.description || '',
        label: sectionData?.label || '',
        short_label: sectionData?.short_label || '',
        icon: sectionData?.icon || '',
        section_id: sectionData?.section_id || '',
        url: sectionData?.url || '',
        base_url: sectionData?.group?.url || '',
        menu: sectionData?.menu || false,
        config: sectionData?.config || {},
        content: sectionData?.content || [],
    };

    const [iconModalIsOpen, setIconModalIsOpen] = useState(false);
    const handleCloseIconModal = () => setIconModalIsOpen(false);
    const handleOpenIconModal = () => setIconModalIsOpen(true);

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper className="suppliers_form" hideTitle>
            <div className="d-flex px-3">
                <NavigateBackButton title="Retornar para a página de fornecedores" />
                <h1 className="content-title flex-shrink-1 col-12 text-center">
                    {(sectionData?.token || '') === '' ? 'CADASTRAR NOVA SEÇÃO' : 'ALTERAR DADOS DA SEÇÃO'}
                </h1>
            </div>
            <div className="page-content">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validations}
                    onSubmit={handleSectionSubmit}
                    enableReinitialize
                    render={({ values, handleSubmit, setFieldValue, setFieldTouched, setValues, isSubmitting }) => (
                        <Form onSubmit={handleSubmit} className="form d-flex flex-wrap">
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="name">
                                    Nome<em>*</em>
                                </Label>
                                <Field name="name" type="text" placeholder="Nome da seção" className="col-12" disabled={isLoading} />
                                <ErrorMessage component="span" name="name" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="type">
                                    Tipo<em>*</em>
                                    <LabelTip>
                                        <b>Seção fixa: </b>Faz referência a um conteúdo estático que não pode ser alterado via cadastro.
                                        <br />
                                        <b>Seção editável: </b>Possui conteúdo editável definido pelo administrador via cadastro.
                                        <br />
                                        <b>Grupo: </b>Grupo de itens do menu, não possui conteúdo para ser exibido como página.
                                        <br />
                                    </LabelTip>
                                </Label>
                                <Field
                                    as="select"
                                    name="type"
                                    className="col-12"
                                    disabled={isLoading || values.token !== ''}
                                    onChange={(e) => {
                                        setValues((prev) => ({
                                            ...prev,
                                            type: e.target.value,
                                            config: {
                                                ...prev.config,
                                                open: e.target.value === 'group' ? 0 : undefined,
                                            },
                                        }));
                                    }}
                                >
                                    <option value="static">Seção fixa</option>
                                    <option value="dynamic">Seção editável</option>
                                    <option value="group">Grupo</option>
                                </Field>
                                <ErrorMessage component="span" name="type" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12">
                                <Label className="field-label" htmlFor="description">
                                    Descrição
                                    <LabelTip>Breve descrição sobre a sessão, exibida apenas para os administradores</LabelTip>
                                </Label>
                                <Field
                                    name="description"
                                    type="text"
                                    placeholder="Descrição da seção"
                                    className="col-12"
                                    disabled={isLoading}
                                />
                                <ErrorMessage component="span" name="description" className="text-error text-small px-2" />
                            </div>

                            <h3 className="field-group-title col-12 mt-3">CONFIGURAÇÕES DO MENU</h3>
                            {values.type === 'group' && (
                                <div className="col-12 d-flex align-items-center my-2">
                                    <CustomSwitch
                                        name="open-as-default"
                                        className="justify-content-center mx-2"
                                        checked={!!values.config?.open}
                                        isAsync={false}
                                        disabled={isSubmitting}
                                        data={{}}
                                        onChange={(e) => setFieldValue('config.open', e)}
                                    />
                                    <Label className="field-label" htmlFor="offer_once">
                                        Exibir aberto como padrão
                                    </Label>
                                </div>
                            )}
                            <div className="mb-1 col-12 px-1">
                                <Label className="field-label" htmlFor="label">
                                    Texto expandido<em>*</em>
                                    <LabelTip>Texto que será exibido no menu lateral quando este estiver expandido (aberto).</LabelTip>
                                </Label>
                                <EditorProvider>
                                    <Editor
                                        value={values.label}
                                        onChange={(e) => setFieldValue('label', e.target.value)}
                                        disabled={isLoading}
                                    >
                                        <Toolbar>
                                            <BtnUndo />
                                            <BtnRedo />
                                            <Separator />
                                            <BtnBold />
                                            <BtnItalic />
                                            <BtnUnderline />
                                            <Separator />
                                            <BtnNumberedList />
                                            <BtnBulletList />
                                            <Separator />
                                            <BtnClearFormatting />
                                        </Toolbar>
                                    </Editor>
                                </EditorProvider>
                                <ErrorMessage component="span" name="label" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-4">
                                <Label className="field-label" htmlFor="short_label">
                                    Texto colapsado<em>*</em>
                                    <LabelTip>Texto que será exibido no menu lateral quando este estiver colapsado (fechado).</LabelTip>
                                </Label>
                                <Field
                                    name="short_label"
                                    maxLength="10"
                                    type="text"
                                    placeholder="Texto colapsado"
                                    className="col-12"
                                    disabled={isLoading}
                                />
                                <ErrorMessage component="span" name="short_label" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-4">
                                <Label className="field-label" htmlFor="short_label">
                                    Icone
                                    <LabelTip>Ícone que será exibido no menu lateral quando este estiver colapsado (fechado).</LabelTip>
                                </Label>
                                <div className="col-12 d-flex align-items-center py-1">
                                    <button
                                        type="button"
                                        onClick={() => handleOpenIconModal()}
                                        className="btn btn-secondary-outline d-flex flex-wrap justify-content-center align-items-center outer-shadow px-2 py-1"
                                    >
                                        {(values?.icon || '') !== '' ? (
                                            <FontAwesomeIcon icon={fas[values.icon]} />
                                        ) : (
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon=""
                                                className="svg-inline--fa fa-w-16 "
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                            />
                                        )}
                                    </button>
                                    <label className="mx-2">clique para alterar</label>
                                </div>
                                <ErrorMessage component="span" name="short_label" className="text-error text-small px-2" />
                            </div>

                            <div className="col-12 d-flex flex-wrap">
                                <div className="mb-1 px-1 col-12 col-sm-4">
                                    <Label className="field-label" htmlFor="section_id">
                                        Grupo
                                        <LabelTip>Indica o grupo de menu dentro do qual o item será exibido.</LabelTip>
                                    </Label>
                                    <Field
                                        as="select"
                                        name="section_id"
                                        className="col-12"
                                        onChange={(e) => {
                                            const section_id = parseInt(e.target.value);
                                            const [section] = groupSections.filter((section) => section.id === section_id);
                                            const base_url = section ? section.url : '';
                                            setValues((values) => ({
                                                ...values,
                                                section_id,
                                                base_url,
                                                url: values.url.replace(values.base_url, base_url),
                                            }));
                                        }}
                                        disabled={isLoading}
                                    >
                                        <option value="">Exibir na raiz</option>
                                        {groupSections &&
                                            groupSections.map((section, sId) => (
                                                <option key={sId} value={section.id}>
                                                    {removerTagsHTML(section.label)}
                                                </option>
                                            ))}
                                    </Field>
                                    <ErrorMessage component="span" name="section_id" className="text-error text-small px-2" />
                                </div>
                                <div className="mb-1 px-1 col-12 col-sm-8">
                                    <Label className="field-label" htmlFor="name">
                                        URL<em>*</em>
                                        <LabelTip>URL de acesso à página.</LabelTip>
                                    </Label>
                                    <InputMask
                                        name="url"
                                        type="text"
                                        className="col-12"
                                        formatChars={{ '*': '[A-Za-z0-9\\-_/]' }}
                                        mask={`${values?.base_url || ''}/**************************************************************`}
                                        maskChar=""
                                        placeholder={`${values?.base_url || ''}/...`}
                                        value={values.url}
                                        onChange={({ target: { value } }) => {
                                            setFieldTouched('url');
                                            setValues({
                                                ...values,
                                                ...{
                                                    url: value.replaceAll(' ', ''),
                                                },
                                            });
                                        }}
                                    />

                                    <ErrorMessage component="span" name="url" className="text-error text-small px-2" />
                                </div>
                            </div>

                            {values.type === 'dynamic' && (
                                <>
                                    <h3 className="field-group-title col-12 mt-3">CONTEÚDO DA PÁGINA</h3>
                                    <DynamicSectionEditor isLoading={isLoading} />
                                </>
                            )}

                            <div className="col-12 mt-3 d-flex">
                                <div className="mb-3 px-3 col-6 text-center">
                                    <NavigateBackButton className="col-10" color="danger" disabled={isSubmitting || isLoading}>
                                        CANCELAR
                                    </NavigateBackButton>
                                </div>
                                <div className="mb-3 col-6 text-center">
                                    <Button type="submit" className="col-10" color="success" disabled={isSubmitting || isLoading}>
                                        SALVAR
                                    </Button>
                                </div>
                            </div>
                            <FormikDevTools />
                            <ChooseIconPopup isOpen={iconModalIsOpen} closeModal={handleCloseIconModal} />
                        </Form>
                    )}
                />
            </div>
        </ContentWrapper>
    );
}

export default SectionsForm;
