import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Table } from 'reactstrap';
import InputMask from 'react-input-mask';
import Yup from '@app/validators';

import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

import { PopupStyle } from '@app/constants';
import FormEditorContext from '../../FormEditorContext';

export default function NotificationContactsPopup({ isOpen, filter = {}, ...props }) {
    const { onCloseNotificationContactsPopup, onApplyNotificationContactsChange } = useContext(FormEditorContext);

    const [contacts, setContacts] = useState(filter?.notify || []);
    const [data, setData] = useState({ name: '', email: '', phone: '' });
    const [isValid, setIsValid] = useState(false);

    const validation = Yup.object().shape({
        name: Yup.string().required().min(3),
        email: Yup.string().email().required(),
        phone: Yup.string().phone(),
    });

    const onDataChanged = (property, value) => {
        setData((prev) => ({ ...prev, [property]: value }));
    };
    useEffect(() => {
        validation.isValid(data).then((response) => {
            setIsValid(response);
        });
    }, [data, validation]);

    const onAddNewRecord = () => {
        setContacts((prev) => [...prev, data]);
        setData({ name: '', email: '', phone: '' });
    };

    const onDeleteRecord = (email) => {
        setContacts((prev) => prev.filter((item) => item.email !== email));
    };

    const onApplyChangesClick = () => {
        onApplyNotificationContactsChange({ ...filter, notify: contacts });
    };

    const afterOpenModal = () => {
        setContacts(filter?.notify || []);
        setData({ name: '', email: '', phone: '' });
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseNotificationContactsPopup}
            style={PopupStyle}
            contentLabel=""
            ariaHideApp={false}
            {...props}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">ADICIONE OS CONTATOS QUE SERÃO NOTIFICADOS</h2>
                    <div className="d-flex align-items-start h-100">
                        <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={onCloseNotificationContactsPopup}>
                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                        </Button>
                    </div>
                </Col>
            </Row>
            <div className="d-flex flex-wrap col-12">
                <div className="d-flex flex-wrap col-12 py-2">
                    <div className="d-flex flex-column col-12 col-sm-4 mb-1">
                        <label className="col-12 field-label" htmlFor="name">
                            Nome
                        </label>
                        <input
                            type="text"
                            className="col-12"
                            name="name"
                            value={data.name}
                            onChange={(e) => onDataChanged('name', e.target.value)}
                        />
                    </div>
                    <div className="d-flex px-1 flex-column col-12 col-sm-4 mb-1">
                        <label className="col-12 field-label" htmlFor="email">
                            Email
                        </label>
                        <input
                            type="email"
                            className="col-12"
                            name="email"
                            value={data.email}
                            onChange={(e) => onDataChanged('email', e.target.value)}
                        />
                    </div>
                    <div className="d-flex flex-column col-12 col-sm-3 mb-1">
                        <label className="col-12 field-label" htmlFor="name">
                            Whatsapp
                        </label>
                        <InputMask
                            name="phone"
                            type="text"
                            className="col-12"
                            mask="(99) 99999-9999"
                            placeholder="(__) _____-____"
                            value={data?.phone || ''}
                            onChange={({ target: { value } }) => {
                                onDataChanged('phone', value.replace(/\D/g, ''));
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column col-12 col-sm-1">
                        <label className="col-12 field-label mb-1" htmlFor="btn-add-contact">
                            &nbsp;
                        </label>
                        <button
                            type="button"
                            name="btn-add-contact"
                            color="custom"
                            className="btn"
                            title="Adicionar contato"
                            disabled={!isValid}
                            onClick={onAddNewRecord}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                </div>
                <Table striped bordered hover className="sections-table ">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Whatsapp</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contacts.map((contact, index) => (
                            <tr key={index}>
                                <td>{contact?.name || ''}</td>
                                <td>{contact?.email || ''}</td>
                                <td>{contact?.phone || ''}</td>
                                <td align="center">
                                    <button
                                        type="button"
                                        className="btn"
                                        title="Remover contato"
                                        onClick={() => onDeleteRecord(contact.email)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="col-12 my-1 d-flex">
                    <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                        <button type="submit" className="btn btn-success col-10" onClick={onApplyChangesClick}>
                            SALVAR
                        </button>
                    </div>
                </div>
                <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>
        </Modal>
    );
}
