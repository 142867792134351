import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';
import { CardsDisplay, CourseDisplay, CarouselDisplay, MatDisplay, FeaturedDisplay } from '.';
import ProductDisplayContext from './ProductDisplayContext';

import './ProductDisplay.scss';

function ProductDisplay({ filter = {}, productClassName = '', type = '', blockControls = false, ...props }) {
    const [products, setProducts] = useState([]);
    const replaceParams = useReplaceParams;

    const loadProducts = useCallback(() => {
        // Consulta os produtos da sessão que serão exibidos no expositor
        Api({ method: 'get', url: replaceParams(URLs.club.sections.products, filter) })
            .then((response) => {
                if (response.data && response.status === 200) {
                    setProducts(response.data);
                }
            })
            .catch(() => {});
    }, [filter, replaceParams]);

    useEffect(() => {
        // // Consulta os produtos da sessão que serão exibidos no expositor
        // Api({ method: 'get', url: replaceParams(URLs.club.sections.products, filter) })
        //     .then((response) => {
        //         if (response.data && response.status === 200) {
        //             setProducts(response.data);
        //         }
        //     })
        //     .catch(() => {});
        console.log('Recarregando os produtos');
        loadProducts();
    }, [loadProducts]);

    const display = useMemo(() => {
        switch (type) {
            case 'cards':
                return <CardsDisplay {...props} productClassName={productClassName} />;
            case 'featured':
                return <FeaturedDisplay {...props} productClassName={productClassName} />;
            case 'carousel':
                return <CarouselDisplay {...props} productClassName={productClassName} />;
            case 'course':
                return <CourseDisplay {...props} productClassName={productClassName} />;
            default: // mat
                return <MatDisplay {...props} productClassName={productClassName} />;
        }
    }, [type, productClassName, props]);

    return (
        <ProductDisplayContext.Provider
            value={{
                products,
                blockControls,
                displayType: type,
                loadProducts,
            }}
        >
            {display}
        </ProductDisplayContext.Provider>
    );
}

export default ProductDisplay;
