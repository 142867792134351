import React from 'react';
import CheckboxValidation from './CheckboxValidation';

function DropdownFieldValidations() {
    return (
        <div className="d-flex align-items-center">
            <CheckboxValidation fieldName="required" label="Obrigatório" />
        </div>
    );
}
export default DropdownFieldValidations;
