import React, { useContext } from 'react';

import PlanDisplayEditorContext from '../../PlanDisplayEditorContext';

export default function PlanPositionSelector({ plan, className = '' }) {
    const { content, onApplyPlanChanges } = useContext(PlanDisplayEditorContext);

    const onChangePosition = (e) => {
        const order = parseInt(e.target.value);
        onApplyPlanChanges({ ...plan, order });
    };

    return (
        <div className={`d-flex flex-wrap px-1 ${className}`}>
            <select name="order" value={plan.order} onChange={onChangePosition} className="col-12">
                {(() => {
                    const options = [];
                    const { length } = content?.filter?.plans || [];
                    (content?.filter?.plans || []).forEach((item, index) => {
                        const after = index === plan?.order ? '(atual)' : ` (${item?.name || ''})`;
                        options.push(
                            <option key={index} value={index}>
                                {index + 1}&ordf; posição {after}{' '}
                            </option>
                        );
                    });
                    if (plan?.order === length) {
                        options.push(
                            <option key={length} value={length}>
                                {length + 1}&ordf; posição
                            </option>
                        );
                    }
                    return options;
                })()}
            </select>
        </div>
    );
}
