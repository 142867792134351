import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, Input } from 'reactstrap';
import './DropDown.scss';

function DropDown(props) {
    const data = props?.data || [];

    const [value, setValue] = useState(props?.value || '');
    const [suggestions, setSuggestions] = useState(props?.suggestions || []);
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const toggle = () => setDropDownOpen(!dropDownOpen);
    const onChange = (event, { newValue, method }) => {
        if (method === 'click' && method === 'enter' && 'clearOnSelect' in props) {
            setValue('');
            setSuggestions([]);
        } else {
            setValue(newValue || '');
        }
    };

    useEffect(() => {
        setValue(props?.selectedValue || '');
    }, [props?.selectedValue]);

    const getSuggestions = (value) => {
        const inputValue = `${value}`.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 || data?.length === 0 ? [] : data.filter((val) => val.toLowerCase().includes(inputValue));
    };
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = ({ value }) => setSuggestions(getSuggestions(value));
    const getSuggestionValue = (suggestion) => suggestion;
    const onSuggestionsClearRequested = () => setSuggestions([]);

    const inputProps = {
        placeholder: props?.placeholder || '...',
        value,
        onChange: props?.onChange || onChange,
        onBlur: () => {
            if ('clearOnSelect' in props) {
                setValue('');
                inputProps.value = '';
            }
        },
    };

    return (
        <Dropdown
            disabled={props?.disabled || false}
            isOpen={dropDownOpen}
            toggle={toggle}
            className={`suggest-with-trap ${props?.className}` || ''}
        >
            <Autosuggest
                suggestions={props?.suggestions || suggestions}
                onSuggestionsFetchRequested={props?.onSuggestionsFetchRequested || onSuggestionsFetchRequested}
                onSuggestionsClearRequested={props?.onSuggestionsClearRequested || onSuggestionsClearRequested}
                getSuggestionValue={props?.getSuggestionValue || getSuggestionValue}
                renderSuggestion={props?.renderSuggestion || ((suggestion) => <DropdownItem key={suggestion}>{suggestion}</DropdownItem>)}
                renderSuggestionsContainer={({ containerProps, children }) => (
                    <div {...containerProps}>
                        <DropdownMenu>{children || []}</DropdownMenu>
                    </div>
                )}
                renderInputComponent={(inputProps) => (
                    <DropdownToggle tag="div">
                        <InputGroup>
                            <Input {...inputProps} disabled={props?.disabled || false} />
                        </InputGroup>
                    </DropdownToggle>
                )}
                inputProps={inputProps}
            />
        </Dropdown>
    );
}

export default DropDown;
