import React, { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import FormEditorContext from '../../FormEditorContext';
import FieldSizeSelector from '../FieldSizeSelector';
import FieldPositionSelector from '../FieldPositionSelector';

function FieldEditorToolbar({ field, children }) {
    const { onChangeField, onDeleteField, onChangeFieldTableVisibility } = useContext(FormEditorContext);
    return (
        <div className="rsw-toolbar field-editor-toolbar d-flex flex-grow-1">
            <FieldSizeSelector field={field} className="d-flex flex-wrap" />
            <div className="d-flex flex-wrap">
                <button
                    type="button"
                    className="rsw-btn btn-change-field"
                    title={`Editar campo '${field.label}'`}
                    onClick={() => onChangeField(field)}
                >
                    <FontAwesomeIcon icon={faPencilAlt} />
                </button>
                <button
                    type="button"
                    className="rsw-btn btn-change-field"
                    title={`Excluir campo '${field.label}'`}
                    onClick={() => onDeleteField(field)}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
                {field.parent_id === null && (
                    <button
                        type="button"
                        style={{ opacity: field.show ? 1 : 0.3 }}
                        className="rsw-btn btn-change-field"
                        title={`${field.show ? 'Deixar de exibir' : 'Exibir'} o campo '${
                            field.label
                        }' na tabela de visualização dos dados enviados`}
                        onClick={() => onChangeFieldTableVisibility(field)}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </button>
                )}
            </div>
            <FieldPositionSelector field={field} />
            {children}
        </div>
    );
}

function FieldEditor({ field, children, customTools = '', ...props }) {
    const { isChanging } = useContext(FormEditorContext);
    return (
        <div {...props} className={`field-editor ${isChanging ? 'is-changing' : ''} ${field.style}`}>
            {isChanging && <FieldEditorToolbar field={field}>{customTools}</FieldEditorToolbar>}
            {children}
        </div>
    );
}

export default FieldEditor;
