import React, { useState, useEffect, useCallback } from 'react';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { URLs } from '@app/constants';

import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';
import AccountTabsMenu from '../components/AccountTabsMenu';
import SubscriptionsTable from './table/SubscriptionsTable';

export default function Subscriptions() {
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadSubscriptionsData = useCallback(() => {
        const url = URLs.club.subscriptions.subscriptions.list;
        setIsLoading(true);
        Api({ method: 'get', url })
            .then(({ status, data }) => {
                if (data && status === 200) {
                    setSubscriptions(data);
                } else {
                    toast.error(data?.message || 'Não foi popssível carregar suas assinaturas');
                }
                setIsLoading(false);
            })
            .catch(() => {
                toast.error('Houve um erro inesperado ao tentar carregar suas assinaturas!');
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        loadSubscriptionsData();
    }, [loadSubscriptionsData]);

    return (
        <ContentWrapper title="">
            <AccountTabsMenu active="subscriptions" />
            <div className="page-content">
                <h2 className="text-center col-12 bg-primary br-4 p-1 text-white text-large mt-1">MINHAS ASSINATURAS</h2>
                {isLoading ? <LoadingSpinner /> : <SubscriptionsTable data={subscriptions} />}
            </div>
        </ContentWrapper>
    );
}
