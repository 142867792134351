import React, { useContext } from 'react';
import { Field } from 'formik';
import FormEditorFieldPopupContext from '../../FormEditorFieldPopupContext';

function DropdownFieldDefaultSelector() {
    const { values } = useContext(FormEditorFieldPopupContext);
    return (
        <>
            <label className="text-small pl-1">Valor padrão:</label>
            <Field as="select" type="text" name="default" className="col-12">
                <option key="" value="">
                    -- nenhum valor padrão --
                </option>
                {(values?.content || '').split(',').map((value) => {
                    const newValue = value.trimStart();
                    return (
                        <option key={newValue} value={newValue}>
                            {newValue}
                        </option>
                    );
                })}
            </Field>
        </>
    );
}

export default DropdownFieldDefaultSelector;
