import React from 'react';
import { Table } from 'reactstrap';
import SplitTable from './SplitTable';

function ChargesTable({ data }) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Val. Total (R$)</th>
                    <th>Forma de Pagamento</th>
                    <th>Status</th>
                    <th>Ult. Atualização</th>
                </tr>
            </thead>
            <tbody>
                {data !== null && data?.length > 0 ? (
                    data.map((charge, id) => (
                        <>
                            <tr key={id}>
                                <td>{charge?.gateway_id || ''}</td>
                                <td>{(charge?.amount || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                                <td>{charge?.method_label || ''}</td>
                                <td>{charge?.status_message || ''}</td>
                                <td>{charge?.updated_at || ''}</td>
                            </tr>
                            {(charge?.split || []).length > 0 && (
                                <tr key={`split-${id}`}>
                                    <td colSpan="5">
                                        <SplitTable data={charge?.split || []} amount={charge.amount} />
                                    </td>
                                </tr>
                            )}
                        </>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={7}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default ChargesTable;
