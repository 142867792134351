import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'reactstrap';

function getFieldValue(field) {
    if (typeof field === 'object') return field?.name || '';
    return String(field);
}

export default function GroupFieldTable({ fields = [], records = [], onRemoveRecord = () => {} }) {
    return (
        <div className="col-12 pt-2 px-2">
            <Table striped bordered hover className="col-12 m-0">
                <thead>
                    <tr>
                        {fields.map((field, index) => (
                            <td key={index}>{field.label}</td>
                        ))}
                        <td>&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    {(records || []).length > 0 ? (
                        records.map((record, r_index) => (
                            <tr key={r_index}>
                                {fields.map((field, f_index) => (
                                    <td key={f_index}>{getFieldValue(record?.[field.name])}</td>
                                ))}
                                <td align="right" width="1%">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        name="btn-add-record"
                                        title="Adicionar dados do registro"
                                        onClick={() => onRemoveRecord(r_index)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td align="center" colSpan={(fields.length || 1) + 1}>
                                Nenhum registro cadastrado
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
}
