import {
    faFileAlt, // Document
    faVideo, // Video
    faBox, // Pack
    faDiceD6, // Object
    faCogs, // Service
} from '@fortawesome/free-solid-svg-icons';

export const productTypeIcons = {
    object: { icon: faDiceD6, title: 'Objeto' },
    video: { icon: faVideo, title: 'Vídeo' },
    document: { icon: faFileAlt, title: 'Documento' },
    service: { icon: faCogs, title: 'Serviço' },
    pack: { icon: faBox, title: 'Pacote' },
};
