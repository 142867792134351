import React from 'react';
import { Table } from 'reactstrap';

function SplitTable({ data, amount }) {
    return (
        <Table bordered col="12 my-0 py-0">
            <thead>
                <tr>
                    <th>Recebedor</th>
                    <th>Tipo</th>
                    <th className="text-right">Percentual (%)</th>
                    <th className="text-right">Valor (R$)</th>
                </tr>
            </thead>
            <tbody>
                {data !== null && data?.length > 0 ? (
                    data.map((split, id) => {
                        const { split_type } = split;
                        const split_amount = split.amount;
                        const percentage = split_type === 'percentage' ? split_amount : (split_amount / amount) * 100;
                        const value = split_type === 'percentage' ? (split_amount / 100) * amount : split_amount;
                        return (
                            <tr key={id}>
                                <td>{split?.supplier?.name || ''}</td>
                                <td>{split_type}</td>
                                <td align="right">
                                    {percentage.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </td>
                                <td align="right">
                                    {value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr key={0}>
                        <td colSpan={7}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default SplitTable;
