import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import DropDown from '@app/components/Autocomplete/DropDown';
import './styleguide_icons.scss';
import StyleguideHeader from './components/StyleguideHeader';

function StyleguideIcons() {
    const iconList = Object.keys(fas).map((icon) => icon);
    const [selected, setSelected] = useState('');

    const [iconsSuggestions, setIconsSuggestions] = useState([]);
    const onIconsSuggestionsFetchRequested = async ({ value }) => {
        setIconsSuggestions(iconList.filter((icon) => icon.toLowerCase().includes(value.toLowerCase())));
    };

    const handleIconSuggestionSelected = (suggestion) => {
        setSelected(suggestion);
        return suggestion;
    };
    return (
        <>
            <StyleguideHeader active="icons" />
            <FilterContainer showTitle={false} className="show-border pb-3 ">
                <FilterFields className="d-flex col-12">
                    <fieldset className="col-2">
                        <label htmlFor="searchBy">Buscar Ícone:</label>
                        <DropDown
                            placeholder="Buscar ícone"
                            selectedValue={selected}
                            suggestions={iconsSuggestions}
                            onSuggestionsFetchRequested={onIconsSuggestionsFetchRequested}
                            getSuggestionValue={(suggestion) => {
                                handleIconSuggestionSelected(suggestion);
                            }}
                        />
                    </fieldset>
                    {selected && (
                        <fieldset className="d-flex col-4 flex-row align-items-center justify-content-start">
                            <div
                                key="selected-icon"
                                title={`{${selected}} ${fas[selected].prefix}-${fas[selected].iconName}`}
                                className="icon d-flex flex-wrap justify-content-center align-items-center outer-shadow m-1"
                                style={{ width: 40, height: 40, overflow: 'hidden' }}
                            >
                                <FontAwesomeIcon icon={fas[selected]} />
                            </div>
                            <label className="text-medium">{`{${selected}} ${fas[selected].prefix}-${fas[selected].iconName}`}</label>
                        </fieldset>
                    )}
                </FilterFields>
            </FilterContainer>
            <div className="d-flex col flex-wrap">
                {Object.keys(fas).map((icon, id) => {
                    const ico = fas[icon];
                    return (
                        <div
                            key={id}
                            title={`{${icon}} ${ico.prefix}-${ico.iconName}`}
                            className={`${
                                selected === icon ? 'active' : ''
                            } icon d-flex flex-wrap justify-content-center align-items-center outer-shadow m-1`}
                            style={{ width: 40, height: 40, overflow: 'hidden' }}
                        >
                            <FontAwesomeIcon icon={fas[icon]} />
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default StyleguideIcons;
