import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Table } from 'reactstrap';

function CustomersTable({ descriptor }) {
    const columns = descriptor.show.split('|');

    const [data] = useState(descriptor?.data || []);

    return (
        <Table striped bordered hover col="12">
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={index}>{column}</th>
                    ))}
                    <th>Criado</th>
                    <th>Detalhes</th>
                </tr>
            </thead>
            <tbody>
                {data &&
                    data?.map((record, index) => (
                        <tr key={index}>
                            {columns.map((column, index) => (
                                <td key={index}>{record?.data?.[column] || ''}</td>
                            ))}
                            <td>{record?.created_at || ''}</td>
                            <td align="center">
                                <a href={`/conta/formularios/${descriptor?.token}/${record?.id}`} className="btn btn-secondary">
                                    <FontAwesomeIcon icon={faEye} title="Visualizar todos os dados enviados" />
                                </a>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    );
}

export default CustomersTable;
