import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import Api from '@app/services/Api';

import { useUserManager } from '@app/hooks/useUserManager';
import { clearAllMessages, selectAuth } from '@app/store/AuthSlice';

import { Container, Button, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import { CustomSwitch } from '@app/components/ui';
import FormikDevTools from '@app/components/FormikDevTools';

import { DropDown, DropdownItem } from '@app/components/Autocomplete';
import { URLs, StateAcronymsToName, useReplaceParams } from '@app/constants';
import { AuthFooter, AuthHeader, SideContent } from '../components';

import '@app/assets/styles/auth.scss';
import { RandomValuesButton } from '../../../components/FormikDevTools';
import { randomCustomerRegistration } from '../../../helpers/RandomValuesHelper';

export default function Register() {
    const replaceParams = useReplaceParams;
    const history = useHistory();
    const { messages } = useSelector(selectAuth);
    const dispatch = useDispatch();

    const UserManager = useUserManager();
    const [errorMessage, setErrorMessage] = useState(''); // useState(message.type==='error' ? message.text : '');
    const [successMessage] = useState(''); // useState(message.type==='success' ? message.text : '');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        messages.forEach(({ type, message }) => {
            switch (type) {
                case 'error':
                    toast.error(message);
                    break;
                case 'success':
                    toast.success(message);
                    break;
                default:
                    break;
            }
        });
        if (messages.length > 0) dispatch(clearAllMessages());
    }, [messages, dispatch]);

    const handleSubmit = (values, { setErrors }) => {
        setIsLoading(true);
        setErrorMessage('');
        UserManager.register(values)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Usuário cadastrado com sucesso!');
                    history.push('/confirmar-telefone');
                } else {
                    setErrors(response?.errors || {});
                    toast.error(response?.data?.message || 'Erro ao cadastrar usuário');
                    setIsLoading(false);
                }
            })
            .catch((response) => {
                setErrors(response?.errors || {});
                toast.error(response?.data?.message || 'Erro ao cadastrar usuário');
                setIsLoading(false);
            });
    };

    /**
     * Consulta por cidades
     */
    const [citiesSuggestions, setCitiesSuggestions] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const onCitiesSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason !== 'input-changed') return;
        const url = replaceParams(URLs.services.locations.cities.list, { name: value, state: selectedState || '' });
        try {
            Api({ method: 'get', url })
                .then(({ data }) => {
                    setCitiesSuggestions(data);
                })
                .catch((err) => {
                    toast.error(err?.data?.message || 'Erro inesperado ao tentar consultar a cidade.');
                });
        } catch (error) {
            // console.log(error);
        }
    };

    const handleStateChange = (state, { values, setFieldValue }) => {
        if (values === null || setFieldValue === null) return;
        setSelectedState(state);
        setFieldValue('state', state);
        setFieldValue('city_id', '');
        setFieldValue('city_name', '');

        setCitiesSuggestions([]);
    };
    // Adiciona o usuário à lista de acesso
    const handleCitySuggestionSelected = (suggestion, { values, setFieldValue }) => {
        if (values === null || setFieldValue === null) return;
        setFieldValue('city_id', suggestion.id);
        setFieldValue('city_name', suggestion.name);

        setCitiesSuggestions([]);
        return suggestion.name;
    };
    // Render the suggested user list
    const renderCitiesSuggestions = (suggestion) => (
        <DropdownItem tag="label" className="suggestion-item" key={suggestion.id}>
            {suggestion.name}
        </DropdownItem>
    );

    /**
     * Inicialização e validação do formulário
     */

    const validations = yup.object().shape({
        name: yup.string().min(5, 'O nome deve conter ao menos 5 caracteres').required('É necessário informar um nome.'),
        email: yup.string().email('Formato de email inválido').required('É necessário informar um endereço de email.'),
        phone: yup.string().min(11, 'Informe o número do celular com o DDD').required('É necessário informar um número do celular.'),
    });

    const initialValues = {
        name: '',
        email: '',
        phone: '',
        city_id: '',
        state: '',
        city: '',
        terms_agreement: false,
    };

    return (
        <div className="auth-screen">
            <Container className="col-10" style={{ maxWidth: '1200px' }}>
                <div className="col-12 justify-content-center">
                    <AuthHeader />
                    <div className="auth-container col-12">
                        <div className="auth-form-container col-12 col-sm-7">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validations}
                                onSubmit={handleSubmit}
                                render={(formikProps) => (
                                    <Form className="form d-flex flex-wrap">
                                        <div className="justify-content-center mb-1 col-12">
                                            <div className="col-12">
                                                <h2 className="text-center">Criar uma conta</h2>
                                            </div>
                                        </div>
                                        {errorMessage && <p className="mb-1 text-error text-small">{errorMessage}</p>}
                                        {successMessage && <p className="mb-1 text-success text-small">{successMessage}</p>}
                                        <div className="col-12 d-flex flex-column mb-1 px-1">
                                            <Label className="field-label" htmlFor="name">
                                                Nome<em>*</em>
                                            </Label>
                                            <Field
                                                type="text"
                                                name="name"
                                                placeholder="Nome completo"
                                                autoComplete="name"
                                                className="login-field col-12 mb-1"
                                                disabled={isLoading}
                                            />
                                            <ErrorMessage component="span" name="name" className="text-error text-small" />
                                        </div>
                                        <div className="col-12 d-flex flex-column mb-1 px-1">
                                            <Label className="field-label" htmlFor="email">
                                                Email<em>*</em>
                                            </Label>
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                autoComplete="email"
                                                className="login-field col-12 mb-1"
                                                disabled={isLoading}
                                            />
                                            <ErrorMessage component="span" name="email" className="text-error text-small" />
                                        </div>
                                        <div className="col-12 d-flex flex-column mb-3 px-1">
                                            <Label className="field-label" htmlFor="phone">
                                                Celular / WhatsApp<em>*</em>
                                            </Label>
                                            <InputMask
                                                name="phone"
                                                type="text"
                                                className="col-12"
                                                mask="(99) 99999-9999"
                                                placeholder="(__) _____-____"
                                                autoComplete="phone"
                                                value={formikProps.values.phone}
                                                disabled={isLoading}
                                                onChange={({ target: { value } }) =>
                                                    formikProps.setValues({
                                                        ...formikProps.values,
                                                        ...{
                                                            phone: value,
                                                        },
                                                    })
                                                }
                                            />
                                            <ErrorMessage component="span" name="phone" className="text-error text-small" />
                                        </div>
                                        <div className="col-12 col-sm-4 px-2">
                                            <Label htmlFor="state" title="Estado">
                                                Estado
                                            </Label>
                                            <select
                                                type="text"
                                                name="state"
                                                className="col-12"
                                                value={formikProps.values.state}
                                                onChange={(e) => handleStateChange(e.target.value, formikProps)}
                                                disabled={isLoading}
                                            >
                                                <option key="all_states" value="">
                                                    Selecione um estado
                                                </option>
                                                {Object.keys(StateAcronymsToName).map((acronym) => (
                                                    <option key={acronym} value={acronym}>
                                                        {StateAcronymsToName[acronym]}
                                                    </option>
                                                ))}
                                            </select>
                                            <ErrorMessage component="span" name="state" className="text-error text-small" />
                                        </div>
                                        <div className="col-12 col-sm-8 px-2 pb-3">
                                            <Label htmlFor="city_id">Cidade</Label>
                                            <DropDown
                                                className="col-12"
                                                disabled={formikProps.values.state === '' || isLoading || false}
                                                name="city_id"
                                                placeholder="Buscar cidade"
                                                selectedValue={formikProps.values.city_name}
                                                suggestions={citiesSuggestions}
                                                onSuggestionsFetchRequested={onCitiesSuggestionsFetchRequested}
                                                renderSuggestion={renderCitiesSuggestions}
                                                getSuggestionValue={(suggestion) => {
                                                    handleCitySuggestionSelected(suggestion, formikProps);
                                                }}
                                                formValues={formikProps.values}
                                                onSetValues={formikProps.setValues}
                                            />
                                            <ErrorMessage component="span" name="city_id" className="text-error text-small" />
                                        </div>

                                        <div className="col-12 mb-3 px-1 d-flex align-items-center">
                                            <CustomSwitch
                                                name="terms_agreement"
                                                className="justify-content-center mx-2"
                                                onChange={(e) =>
                                                    formikProps.setValues({ ...formikProps.values, ...{ terms_agreement: e } })
                                                }
                                                checked={formikProps.values?.terms_agreement || false}
                                                isAsync={false}
                                                disabled={isLoading}
                                                data={{ id: null, status: formikProps.values?.terms_agreement || false }}
                                            />
                                            <Label className="field-label" htmlFor="terms_agreement">
                                                Declaro que li e concordo com os termos da{' '}
                                                <Link to="/termos" target="_blank" className="text-dark-blue mx-1">
                                                    Política de Privacidade
                                                </Link>
                                            </Label>
                                        </div>
                                        <div className="col-12 d-flex mb-3 d-flex justify-content-center">
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Button
                                                    type="submit"
                                                    className="col-12"
                                                    color="primary"
                                                    disabled={isLoading || !formikProps.values?.terms_agreement}
                                                >
                                                    SALVAR
                                                </Button>
                                            </div>
                                        </div>
                                        <FormikDevTools style={{ color: '#040404' }}>
                                            <RandomValuesButton onClick={() => randomCustomerRegistration(formikProps.setValues)} />
                                        </FormikDevTools>
                                        <div className="col-12 text-center">
                                            <p>
                                                Já é cadastrado? Faça login{' '}
                                                <Link to="/login" className="text-dark-blue">
                                                    clicando aqui!
                                                </Link>
                                            </p>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                        <SideContent className="col-12 col-sm-5" />
                    </div>
                </div>
            </Container>
            <AuthFooter />
        </div>
    );
}
