import React, { useState } from 'react';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';
import CheckPermission from '@app/components/CheckPermission';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import { Row, Col, Button, Label } from 'reactstrap';

import { URLs, useReplaceParams } from '@app/constants';
import { useUserManager } from '@app/hooks/useUserManager';

import Api from '@app/services/Api';

import { useFetch } from '@app/hooks';

import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigateBackButton, WhatsappLink } from '@app/components/ui';
import DocumentsTable from './tables/DocumentsTable';
import DocumentReview from './popups/DocumentReview';
import CancelScheduleForm from './popups/CancelScheduleForm';
import RequireDocument from './popups/RequireDocument';
import CertificateHistory from './tables/CertificateHistory';

function CertificatesReview(props) {
    const history = useHistory();
    const certificate_token = props?.match?.params?.token;

    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    // Consulta de dados de sessões
    const [params, setParams] = useState({
        token: certificate_token,
        nocache: new Date().getTime(),
    });
    const certificate = useFetch(replaceParams(URLs.admin.certificates.index, params));

    const updateRequest = () => setParams({ ...params, ...{ nocache: new Date().getTime() } });

    const certificateFAddress = () => {
        if (!certificate?.data) return '';
        let res = '';
        res += certificate?.data?.address || '';
        res += certificate?.data?.number ? `,${certificate?.data?.number}` : '';
        res += certificate?.data?.complement ? `,${certificate?.data?.complement}` : '';
        res += certificate?.data?.reference ? `,${certificate?.data?.reference}` : '';
        return res;
    };

    const [documentReviewModalIsOpen, setDocumentReviewModalIsOpen] = useState(false);
    const [documentData, setDocumentData] = useState({ token: certificate_token, document_token: '' });

    const openFormModal = () => {
        setDocumentReviewModalIsOpen(true);
    };
    const closeFormModal = () => {
        setDocumentReviewModalIsOpen(false);
    };

    const [cancelScheduleModalIsOpen, setCancelScheduleModalIsOpen] = useState(false);
    const openCancelScheduleFormModal = () => {
        setCancelScheduleModalIsOpen(true);
    };
    const closeCancelScheduleFormModal = () => {
        setCancelScheduleModalIsOpen(false);
    };

    const [requireDocumentModalIsOpen, setRequireDocumentModalIsOpen] = useState(false);
    const openRequireDocumentFormModal = () => {
        setRequireDocumentModalIsOpen(true);
    };
    const closeRequireDocumentFormModal = () => {
        setRequireDocumentModalIsOpen(false);
    };

    // Atualiza o status do certificado e notifica o usuário sobre a emissão do certificado
    const [isUpdatingCertificate, setUpdatingCertificate] = useState(false);
    /**
     * Cancela o agendamento do certificado
     */
    const handleCancelInterviewSchedule = (justification = '') => {
        setUpdatingCertificate(true);
        const url = replaceParams(URLs.admin.certificates.cancel_schedule, { token: certificate_token });
        Api({ method: 'put', url, data: { justification }, headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (response.data && response.status === 200) {
                    history.push('/admin/certificados');
                    toast.success(response.data.message);
                    closeCancelScheduleFormModal();
                } else {
                    toast.error(response.data.message);
                }
                setUpdatingCertificate(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar cancelar o agendamento');
                setUpdatingCertificate(false);
            });
    };
    /**
     * Confirma a realização da entrevista
     */
    const handleConfirmInterview = () => {
        setUpdatingCertificate(true);
        const url = replaceParams(URLs.admin.certificates.confirm_interview, { token: certificate_token });
        Api({ method: 'put', url, headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (response.data && response.status === 200) {
                    history.push('/admin/certificados');
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
                setUpdatingCertificate(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar cancelar o agendamento');
                setUpdatingCertificate(false);
            });
    };
    /**
     * Solicita novo documento ao usuário
     */
    const handleRequireDocument = (description) => {
        setUpdatingCertificate(true);
        const url = replaceParams(URLs.admin.certificates.document.require, { token: certificate_token });
        Api({ method: 'post', url, data: { description }, headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    closeRequireDocumentFormModal();
                    updateRequest();
                } else {
                    toast.error(response.data.message);
                }
                setUpdatingCertificate(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar cancelar o agendamento');
                setUpdatingCertificate(false);
            });
    };

    const handleCertificateEndReview = () => {
        setUpdatingCertificate(true);
        const url = replaceParams(URLs.admin.certificates.end_review, { token: certificate_token });
        Api({ method: 'put', url, headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (response.data && response.status === 200) {
                    history.push('/admin/certificados');
                } else {
                    toast.error(response.data.message);
                }
                setUpdatingCertificate(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar cancelar o agendamento');
                setUpdatingCertificate(false);
            });
    };

    const handleNotifyCertificateEmission = () => {
        setUpdatingCertificate(true);
        const url = replaceParams(URLs.admin.certificates.emit, { token: certificate_token });
        Api({ method: 'put', url, headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    history.push('/admin/certificados');
                } else {
                    toast.error(response.data.message);
                }
                setUpdatingCertificate(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar enviar os dados');
                setUpdatingCertificate(false);
            });
    };

    const handleDocumentReviewClick = (document, status) => {
        setDocumentData((prev) => ({
            ...prev,
            ...{
                document_token: document.token,
                document_type: document.filetype,
                document_name: document.filename,
                status,
            },
        }));
        openFormModal();
    };

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper>
            <div className="d-flex px-3">
                <NavigateBackButton title="Retornar para a página anterior" />
            </div>
            <div className="page-content">
                <div className="d-flex flex-wrap">
                    <div className="col-12 text-center badge badge-secondary px-2 py-1">{certificate?.data?.status_label}</div>
                    <div className="col-12 col-sm-4 px-2 py-1">
                        <Label htmlFor="name">Nome: </Label>
                        <Label className="fw-bold mx-2">{certificate?.data?.name}</Label>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-2 px-2 py-1">
                        <Label htmlFor="group_id" title={props?.userData?.group?.id}>
                            Tipo:{' '}
                        </Label>
                        <Label className="fw-bold mx-2">{certificate?.data?.entity_label}</Label>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-2 px-2 py-1">
                        <Label htmlFor="document">CPF: </Label>
                        <Label className="fw-bold mx-2">{certificate?.data?.cpf}</Label>
                    </div>
                    <div className="col-12 col-sm-4 col-lg-3 px-2 py-1">
                        <Label htmlFor="document">CNPJ: </Label>
                        <Label className="fw-bold mx-2">{certificate?.data?.cnpj}</Label>
                    </div>

                    <div className="col-12 col-sm-4 px-2 py-1">
                        <Label htmlFor="email">Email: </Label>
                        <Label className="fw-bold mx-2">{certificate?.data?.email}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2 py-1">
                        <Label htmlFor="phone">Telefone: </Label>
                        <Label className="fw-bold mx-2">
                            <WhatsappLink number={certificate?.data?.phone} />
                        </Label>
                    </div>
                    <div className="col-12 col-sm-8 px-2 py-1">
                        <Label htmlFor="address">Endereço: </Label>
                        <Label className="fw-bold mx-2">{certificateFAddress()}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2 py-1">
                        <Label htmlFor="zip">CEP: </Label>
                        <Label className="fw-bold mx-2">{certificate?.data?.zip}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2 py-1">
                        <Label htmlFor="neighborhood">Bairro: </Label>
                        <Label className="fw-bold mx-2">{certificate?.data?.neighborhood}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2 py-1">
                        <Label htmlFor="neighborhood">Cidade/UF: </Label>
                        <Label className="fw-bold mx-2">
                            {certificate?.data?.city?.name}/{certificate?.data?.city?.state}
                        </Label>
                    </div>
                    <div className="col-12 col-sm-6 px-2 py-1">
                        {certificate?.data?.calendar && (
                            <>
                                <Label htmlFor="neighborhood">Agendamento: </Label>
                                <Label className="fw-bold mx-2">{certificate?.data?.calendar?.datetime}</Label>
                            </>
                        )}
                    </div>
                    {certificate?.data?.status === 1 && (
                        <CheckPermission permission="require-documents">
                            <div className="mb-1 col-12">
                                <Button
                                    type="button"
                                    className="mx-2 btn btn-icon-left"
                                    color="primary"
                                    title="Solicitar novo documento ao cliente"
                                    disabled={isUpdatingCertificate}
                                    onClick={openRequireDocumentFormModal}
                                >
                                    <FontAwesomeIcon icon={faFileImport} className="mx-1" />
                                    Solicitar novo documento
                                </Button>
                            </div>
                        </CheckPermission>
                    )}
                    <DocumentsTable
                        data={certificate?.data}
                        token={certificate_token}
                        handleDocumentReviewClick={handleDocumentReviewClick}
                    />

                    <div className="col-12 d-flex justify-content-center">
                        {certificate?.data?.status === 1 && (
                            <Row className="col-12">
                                <CheckPermission permission="notify-cert-emission">
                                    <Col className="mb-3 col-12 text-center">
                                        <Button
                                            type="button"
                                            className="col-12 col-sm-6 mx-2"
                                            color="success"
                                            disabled={isUpdatingCertificate}
                                            onClick={handleCertificateEndReview}
                                        >
                                            FINALIZAR REVISÃO
                                        </Button>
                                    </Col>
                                </CheckPermission>
                            </Row>
                        )}
                        {certificate?.data?.status === 4 && (
                            <>
                                <CheckPermission permission="cancel-schedule">
                                    <div className="mb-3 px-2 col-12 col-sm-6 text-center">
                                        <Button
                                            type="button"
                                            className="col-12 "
                                            color="danger"
                                            disabled={isUpdatingCertificate}
                                            onClick={openCancelScheduleFormModal}
                                        >
                                            CANCELAR AGENDAMENTO
                                        </Button>
                                    </div>
                                </CheckPermission>
                                <CheckPermission permission="confirm-interview">
                                    <div className="mb-3 px-2 col-12 col-sm-6 text-center">
                                        <Button
                                            type="submit"
                                            className="col-12 "
                                            color="success"
                                            disabled={isUpdatingCertificate}
                                            onClick={handleConfirmInterview}
                                        >
                                            ENTREVISTA REALIZADA
                                        </Button>
                                    </div>
                                </CheckPermission>
                            </>
                        )}
                        {certificate?.data?.status === 5 && (
                            <Row className="col-12">
                                <CheckPermission permission="notify-cert-emission">
                                    <Col className="mb-3 col-12 text-center">
                                        <Button
                                            type="button"
                                            className="col-12 col-sm-6 mx-2"
                                            color="primary"
                                            disabled={isUpdatingCertificate}
                                            onClick={handleNotifyCertificateEmission}
                                        >
                                            NOTIFICAR EMISSÃO DO CERTIFICADO
                                        </Button>
                                    </Col>
                                </CheckPermission>
                            </Row>
                        )}
                    </div>
                    <h3 className="field-group-title col-12 mt-3">Histórico da requisição</h3>
                    <CertificateHistory data={certificate?.data?.history || []} />
                </div>
            </div>
            <DocumentReview
                updateRequest={updateRequest}
                modalIsOpen={documentReviewModalIsOpen}
                data={documentData}
                closeModal={closeFormModal}
                title="REVISAR DOCUMENTO"
            />
            <CancelScheduleForm
                token={certificate_token}
                updateRequest={updateRequest}
                modalIsOpen={cancelScheduleModalIsOpen}
                closeModal={closeCancelScheduleFormModal}
                handleCancelInterviewSchedule={handleCancelInterviewSchedule}
                title="CANCELAR AGENDAMENTO"
            />
            <RequireDocument
                token={certificate_token}
                modalIsOpen={requireDocumentModalIsOpen}
                closeModal={closeRequireDocumentFormModal}
                handleRequireDocument={handleRequireDocument}
            />
        </ContentWrapper>
    );
}

export default CertificatesReview;
