import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import DefaultPlanDisplay from '@app/components/Plans/PlanDisplay/DefaultPlanDisplay';
import PlansDisplayContext from '@app/components/Plans/PlanDisplay/PlansDisplayContext';
import PlanDisplayPlanRenderer from '@app/components/Plans/PlanDisplay/Renderer/PlanDisplayPlanRenderer';

export default function FormSlicePreview() {
    const { values } = useFormikContext();

    const planBeforeSubscription = useMemo(
        () => ({
            ...values,
        }),
        [values]
    );

    const [displayType] = useState('default');

    const display = useMemo(() => <DefaultPlanDisplay planClassName="" />, []);

    return (
        <div className="mb-1 col-12 px-1 d-flex flex-wrap">
            <div className={`${displayType === 'course' ? 'col-12' : 'col-12'} px-1`}>
                <PlansDisplayContext.Provider
                    value={{
                        plans: [planBeforeSubscription],
                        blockControls: true,
                        Renderer: PlanDisplayPlanRenderer,
                    }}
                >
                    {display}
                </PlansDisplayContext.Provider>
            </div>
        </div>
    );
}
