import React from 'react';
import StyleguideHeader from '../components/StyleguideHeader';

export default function PageComponents() {
    return (
        <>
            <StyleguideHeader active="components" />
            <div className="page-content">
                <h2>Componentes Disponíveis</h2>
            </div>
        </>
    );
}
