import React from 'react';

import drc_logo from '@app/assets/images/drc_logo_escuro.svg';

function AuthHeader() {
    return (
        <div className="auth-header col-12 d-flex justify-content-center my-5">
            <img src={drc_logo} alt="Logo Doutor Caminhoneiro" className="logo outer-shadow" />
        </div>
    );
}

export default AuthHeader;
