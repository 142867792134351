import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Container } from 'reactstrap';
import Api from '@app/services/Api';
import { URLs } from '@app/constants';

import toast from 'react-hot-toast';
import { AuthFooter, AuthHeader } from '../components';

export default function EmailConfirmation() {
    const { token } = useParams();
    const [emailVerified, setEmailVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (emailVerified) return;
        const confirmEmail = () => {
            Api({
                method: 'post',
                url: URLs.auth.confirm_email,
                data: {
                    token,
                },
            })
                .then(({ data, status }) => {
                    if (status === 200) {
                        setEmailVerified(true);
                        setErrorMessage('');
                        toast.success(data?.message || 'Endereço de e-mail verificado com sucesso!');
                    } else {
                        setEmailVerified(false);
                        setErrorMessage(data?.message || 'Erro ao confirmar seu endereço de e-mail!');
                    }
                })
                .catch(({ response }) => {
                    setEmailVerified(false);
                    setErrorMessage(response?.data?.message || 'Erro inesperado ao confirmar seu endereço de e-mail!');
                });
        };
        if (token === '') return;
        confirmEmail();
    }, [token, emailVerified]);

    const content = useMemo(() => {
        if (!emailVerified && errorMessage === '')
            return <p className="my-2 text-medium text-center">Aguarde um momento, estamos verificando seu endereço de e-mail.</p>;

        if (errorMessage !== '') return <p className="my-2 text-medium text-center text-red">{errorMessage}</p>;

        return <p className="my-2 text-medium text-center text-success">Seu endereço de e-mail foi verificado com sucesso.</p>;
    }, [emailVerified, errorMessage]);

    return (
        <div className="auth-screen">
            <Container className="col-10" style={{ maxWidth: '1200px' }}>
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                    <AuthHeader />
                    <div className="auth-container col-12 col-sm-10 col-md-8 col-lg-6">
                        <div className="auth-form-container col-12">
                            <div className="justify-content-center mb-3 col-12">
                                <div className="col-12">
                                    <h2 className="text-center">Confirmação de E-mail</h2>
                                </div>
                            </div>
                            {content}
                            <div className="col-12 d-flex my-3 justify-content-center">
                                <div className="d-flex col-6 justify-content-center px-1">
                                    <Link to="/" className="btn btn-primary col-12">
                                        RETORNAR PARA O DOUTOR CAMINHONEIRO
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <AuthFooter />
        </div>
    );
}
