import React from 'react';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PurchaseItem({ data = {} }) {
    return (
        <div className="card-body d-flex flex-wrap justify-content-start">
            <div
                className="order-item-thumb d-flex justify-content-center align-items-center px-2 col-12 col-sm-2 col-md-2"
                style={{ whiteSpace: 'nowrap' }}
            >
                {data?.product?.thumb && (
                    <img
                        src={data?.product?.thumb?.url || ''}
                        alt={data?.product?.description}
                        title={data?.product?.description}
                        className="col-10"
                    />
                )}
            </div>
            <div className="order-item-details col-12 col-sm-8 col-md-8 text-medium">
                <div className="d-flex flex-column mx-2 ">
                    <span className="order-item-name fw-bold">{data?.product?.name}</span>
                    <span className="order-item-description my-2">{data?.product?.description}</span>
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center fw-bold order-item-qtd-input">
                            <label>Qtd.:</label>
                            <label className="mx-2">{data?.qtd}</label>
                        </div>
                    </div>
                    {(data?.product?.has_shipping || false) && (
                        <label className="order-item-shipping-info mt-2">
                            <FontAwesomeIcon icon={faMailBulk} className="mx-1" />
                            Este produto possui serviço de postagem
                        </label>
                    )}
                </div>
            </div>
        </div>
    );
}
export default function Purchase({ data = {} }) {
    return (
        <div className="order-item card col-12 mb-3">
            <div className="card-header order-header d-flex flex-wrap">
                <div className="d-flex col-12 col-md-4 p-2 ">
                    <div className="d-flex flex-column col-6 px-2">
                        <label className="text-medium fw-bold d-flex flex-column">
                            <label>{data?.status_label.toUpperCase()}</label>
                            <label className="text-small">{data?.purchase_date}</label>
                            <a href={`/pedido/${data?.token}`} target="_blank" className="text-small mt-2" rel="noreferrer">
                                ver pedido
                            </a>
                        </label>
                        {data?.status === 2 && data?.boleto_url && (
                            <a href={data?.boleto_url} className="btn text-small" target="_blank" rel="noreferrer">
                                Clique aqui para acessar seu boleto
                            </a>
                        )}
                    </div>
                    <div className="d-flex flex-column justify-content-start flex-wrap col-6 align-items-center">
                        <label className="text-medium fw-bold">TOTAL</label>
                        <label className="text-small">R$ {data?.total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</label>
                    </div>
                </div>
                {data?.billing_address && (
                    <div className="order-address-details d-flex flex-column p-2 col-12 col-sm-6 col-md-4">
                        <label className="col-12 text-medium fw-bold">ENDEREÇO DE COBRANÇA</label>
                        <span className="text-small">
                            {data?.billing_address?.address} {data?.billing_address?.number}
                        </span>
                        <span className="text-small">{data?.billing_address?.neighborhood}</span>
                        <span className="text-small">
                            {data?.billing_address?.city?.name}/{data?.billing_address?.city?.state}
                        </span>
                    </div>
                )}
                {data?.shipping_address && (
                    <div className="order-address-details d-flex flex-column p-2 col-12 col-sm-6 col-md-4">
                        <label className="col-12 text-medium fw-bold">ENDEREÇO DE ENTREGA</label>
                        <span className="text-small">
                            {data?.shipping_address?.address}, {data?.shipping_address?.number}
                        </span>
                        <span className="text-small">{data?.shipping_address?.neighborhood}</span>
                        <span className="text-small">
                            {data?.shipping_address?.city?.name}/{data?.shipping_address?.city?.state}
                        </span>
                    </div>
                )}
            </div>
            {(data?.items || []).map((item, index) => (
                <PurchaseItem key={index} data={item} />
            ))}
        </div>
    );
}
