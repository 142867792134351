import React from 'react';
import { Table } from 'reactstrap';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';

function SubscriptionIncrementRow({ data = {} }) {
    const floatToCurrency = useFloatToCurrency;

    return (
        <tr>
            <td>{data.description}</td>
            <td align="center">{data.cycles}</td>
            <td align="right">R$ {floatToCurrency(data.value || 0)}</td>
        </tr>
    );
}

export default function SubscriptionIncrementsTable({ items = [], className = '' }) {
    return (
        <div className={`col-12 ${className}`}>
            <h3 className="text-center mt-2">Incrementos da cobrança</h3>
            <Table striped bordered hover col="12">
                <thead>
                    <tr>
                        <th>Descrição</th>
                        <th className="text-center">Ciclos</th>
                        <th className="text-right">Valor</th>
                    </tr>
                </thead>
                <tbody>
                    {items?.map((item, index) => (
                        <SubscriptionIncrementRow key={index} data={item} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
