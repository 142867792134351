import React, { useCallback, useState } from 'react';
import { useReplaceParams } from '@app/constants';
import { toast } from 'react-hot-toast';
import { URLs } from '../../../constants';
import Api from '../../../services/Api';
import ContentWrapper from '../../shared/ContentWrapper/ContentWrapper';
import Product from '../../../components/Products/Product/Product';

import './ProductPage.scss';
import ProductDisplayContext from '../../../components/Products/ProductDisplays/ProductDisplayContext';

function ProductPage(props) {
    const replaceParams = useReplaceParams;

    const [product, setProduct] = useState(null);

    const loadProductData = useCallback(
        (token) => {
            const url = replaceParams(URLs.club.products.show, { token: token || '' });
            Api({ url })
                .then(({ data, status }) => {
                    if (status === 200) {
                        setProduct(data);
                    } else {
                        toast.error('Houve um erro ao carregar o conteúdo da página!');
                    }
                })
                .catch(() => {
                    toast.error('Houve um erro inesperado ao carregar o conteúdo da página!');
                });
        },
        [replaceParams]
    );

    useState(() => {
        if ((props?.match?.params?.token || '') === '') return;
        if (product !== null && product?.token === props?.match?.params?.token) return;
        loadProductData(props?.match?.params?.token);
    }, []);

    return (
        <ContentWrapper title="" {...props}>
            <div className="page-content product-page col-12 flex-shrink-1">
                <ProductDisplayContext.Provider
                    value={{
                        products: [],
                        blockControls: false,
                    }}
                >
                    {product && <Product data={product} />}
                </ProductDisplayContext.Provider>
            </div>
        </ContentWrapper>
    );
}

export default ProductPage;
