import React, { useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';

import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { PopupStyle } from '@app/constants';
import CustomerForm from './CustomerForm';

export default function CustomerFormPopup({ isOpen, onClose, onSave }) {
    const handleSave = useCallback(() => {
        onClose();
        if (typeof onSave === 'function') onSave();
    }, [onSave, onClose]);
    const afterOpenModal = () => {};

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={onClose}
            style={PopupStyle}
            className=""
            contentLabel="MEUS DADOS"
            ariaHideApp={false}
        >
            <div className="modal-content">
                <Row>
                    <Col className="col-12 d-flex align-items-center">
                        <h2 className="flex-grow-1">MEUS DADOS</h2>
                        <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                        </Button>
                    </Col>
                </Row>
                <CustomerForm onSave={handleSave} requireShippingAddress={false} />
            </div>
        </Modal>
    );
}
