import React, { useContext } from 'react';

import imgSubscriptionStatusCreated from '@app/assets/images/subscription-status-created.svg';
import imgSubscriptionStatusProcessing from '@app/assets/images/subscription-status-processing.svg';
import imgSubscriptionStatusActive from '@app/assets/images/subscription-status-active.svg';
import imgSubscriptionStatusCanceled from '@app/assets/images/subscription-status-canceled.svg';
import imgSubscriptionStatusFailed from '@app/assets/images/subscription-status-failed.svg';
import imgSubscriptionStatusExpired from '@app/assets/images/subscription-status-expired.svg';
import SubscriptionContext from '../SubscriptionContext';

export function ChargeStatusFuture() {
    const { subscription } = useContext(SubscriptionContext);
    return (
        <div className="d-flex flex-1 col-12 p-2 flex-column align-items-center">
            <div className="col-6 mb-3 p-1">
                <img src={imgSubscriptionStatusCreated} alt="Pagamento realizado com cartão de crédito" className="col-12" />
            </div>
            <h3 className="text-center mb-3">ASSINATURA FUTURA REALIZADA</h3>
            <p className="text-center">
                <strong>PARABÉNS!</strong> Sua assinatura foi realizada com sucesso. Sua nova assinatura entrará em vigor no dia{' '}
                {subscription.start_at} em substituição à sua assinatura atual.
            </p>
            <p className="text-center">Não se preocupe, os itens contemplados no seu novo plano serão disponibilizados em breve.</p>
            <p className="text-center">
                <strong>Isto pode levar alguns minutos.</strong>
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
                <br />
                Você será avisado assim que tivermos uma atualização do status da sua assinatura.
            </p>
            <p className="p-1 m-0 text-small">
                Ficou com dúvida?{' '}
                <a href="/politica-de-assinaturas" target="_blank" title="Clique aqui para ler nossa Política de Assinaturas">
                    Clique aqui
                </a>{' '}
                e leia nossa <strong>Política de Assinaturas</strong>.
            </p>
        </div>
    );
}

export function ChargeStatusClosed() {
    return (
        <div className="d-flex flex-1 col-12 p-2 flex-column align-items-center">
            <div className="col-6 mb-3 p-1">
                <img src={imgSubscriptionStatusCreated} alt="Pagamento realizado com cartão de crédito" className="col-12" />
            </div>
            <h3 className="text-center mb-3">ASSINATURA REALIZADA</h3>
            <p className="text-center">
                <strong>PARABÉNS!</strong> Sua assinatura foi realizada com sucesso, o <strong>Doutor Caminhoneiro</strong> está agora
                aguardando o processamento do pagamento.
            </p>
            <p className="text-center">
                <strong>Isto pode levar alguns minutos.</strong>
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
                <br />
                Você será avisado assim que tivermos uma atualização do status da sua assinatura.
            </p>
        </div>
    );
}

export function ChargeStatusProcessing() {
    return (
        <div className="d-flex flex-1 col-12 p-2 flex-column align-items-center">
            <div className="col-6 mb-3 p-1">
                <img src={imgSubscriptionStatusProcessing} alt="Pagamento realizado com cartão de crédito" className="col-12" />
            </div>
            <h3 className="text-center">PROCESSANDO PAGAMENTO</h3>
            <p className="text-center">
                O pagamento do seu pedido está sendo processado pela entidade responsável. O <strong>Doutor Caminhoneiro</strong> será
                notificado assim que houver uma atualização sobre o status do pagamento.
            </p>
            <p className="text-center">Em breve os itens e serviços contidos no plano serão disponibilizados para você.</p>
            <p className="text-center">
                <strong>Isto pode levar alguns minutos.</strong>
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
                <br />
                <span className="text-small text-primary">
                    Você será avisado assim que tivermos uma atualização do status da sua assinatura.
                </span>
            </p>
        </div>
    );
}
export function ChargeStatusActive({ start_at = '', next_billing_at = '' }) {
    return (
        <div className="d-flex flex-1 col-12 p-2 flex-column align-items-center">
            <div className="col-6 mb-3 p-1">
                <img src={imgSubscriptionStatusActive} alt="Pagamento realizado com cartão de crédito" className="col-12" />
            </div>
            <h3 className="text-center">ASSINATURA ATIVA</h3>
            <div className="d-flex flex-wrap px-2">
                <span className="col-12">
                    <strong>Data de início: </strong>
                    <span className="text-medium">{start_at}</span>
                </span>
                <span className="col-12">
                    <strong>Próxima cobrança: </strong>
                    <span className="text-medium">{next_billing_at}</span>
                </span>
            </div>
        </div>
    );
}
export function ChargeStatusExpired() {
    return (
        <div className="d-flex flex-1 col-12 p-2 flex-column align-items-center">
            <div className="col-6 mb-3 p-1">
                <img src={imgSubscriptionStatusExpired} alt="Pagamento realizado com cartão de crédito" className="col-12" />
            </div>
            <h3 className="text-center">ASSINATURA EXPIRADA</h3>
            <p className="text-center">Sua assinatura expirou.</p>
            <p className="text-center">
                <a href="/conta/assinaturas" title="Ver minha assinaturas" className="btn btn-primary">
                    VER MINHAS ASSINATURAS
                </a>
            </p>
        </div>
    );
}
export function ChargeStatusPaymentFailed() {
    return (
        <div className="d-flex flex-1 col-12 p-2 flex-column align-items-center">
            <div className="col-6 mb-3 p-1">
                <img src={imgSubscriptionStatusFailed} alt="Pagamento realizado com cartão de crédito" className="col-12" />
            </div>
            <h3 className="text-center">FALHA NO PAGAMENTO</h3>
            <p className="text-center">O pagamento da sua assinatura falhou.</p>
            <p className="text-center">
                Atualize a forma de pagamento escolhida para continuar aproveitando todo o conteúdo que o{' '}
                <strong>Doutor Caminhoneiro</strong> preparou para você.
            </p>
            <p className="text-center">
                <a href="/conta/assinaturas" title="Ver minha assinaturas" className="btn btn-primary">
                    VER MINHAS ASSINATURAS
                </a>
            </p>
        </div>
    );
}
export function ChargeStatusCanceled() {
    return (
        <div className="d-flex flex-1 col-12 p-2 flex-column align-items-center">
            <div className="col-6 mb-3 p-1">
                <img src={imgSubscriptionStatusCanceled} alt="Pagamento realizado com cartão de crédito" className="col-12" />
            </div>
            <h3 className="text-center">ASSINATURA CANCELADA</h3>
            <p className="text-center">Esta assinatura foi cancelada.</p>
            <p className="text-center">
                <a href="/conta/assinaturas" title="Ver minha assinaturas" className="btn btn-primary">
                    VER MINHAS ASSINATURAS
                </a>
            </p>
        </div>
    );
}

export function ChargeStatusLoading() {
    return (
        <div className="d-flex flex-1 col-12 p-2 h-100 flex-column align-items-center justify-content-center">
            <span className="text-center">Carregando...</span>
        </div>
    );
}
