import { URLs, useReplaceParams } from '../constants';
import Api from '../services/Api';

export const usePurchaseManager = () => {
    const replaceParams = useReplaceParams;

    const data = JSON.parse(localStorage.getItem('user-data'));
    const token = data?.access_token || '';

    return {
        /**
         * Cria uma nova compra com base em uma lista de produtos
         */
        createPurchase: async ($items) => {
            const response = await Api({
                method: 'post',
                url: URLs.club.purchases.create,
                data: { items: $items },
            })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar iniciar o pagamento.' };
                });
            return response;
        },
        /**
         * Solicita os dados do pedido
         */
        getPurchase: (token) =>
            Api({
                method: 'get',
                url: replaceParams(URLs.club.purchases.show, { token }),
            }),
        /**
         * Solicita o status do pedido
         */
        getPurchaseStatus: (token) =>
            Api({
                method: 'get',
                url: replaceParams(URLs.club.purchases.status, { token }),
            }),
        /**
         * Cancela a compra baseada nos itens do carrinho
         */
        closePurchase: async (token) => {
            const response = await Api({
                method: 'post',
                url: replaceParams(URLs.club.purchases.close, { token }),
            })
                .then((resp) => {
                    const { data, status } = resp;
                    if (data && status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch(({ response }) => {
                    if (response) {
                        return { ...response.data, ...{ status: response.status } };
                    }
                    return { status: null, data: null, message: 'Erro inesperado ao tentar fechar o pedido.' };
                });
            return response;
        },
        /**
         * Cancela a compra baseada nos itens do carrinho
         */
        finishPurchase: async ($token, $data) => {
            const response = await Api({
                method: 'post',
                url: replaceParams(URLs.club.purchases.finish, { token: $token }),
                data: $data,
            })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch(({ response }) => {
                    if (response) {
                        return { ...response.data, ...{ status: response.status } };
                    }
                    return { status: null, data: null, message: 'Erro inesperado ao tentar iniciar o pagamento.' };
                });
            return response;
        },
        /**
         * Cancela a compra baseada nos itens do carrinho
         */
        cancelPurchase: async ($token) => {
            const response = await Api({
                method: 'post',
                url: replaceParams(URLs.club.purchases.cancel, { token: $token }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    ContentType: 'application/json',
                },
            })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar iniciar o pagamento.' };
                });
            return response;
        },
        /**
         * Solicita a confirmação do pagamento de uma intenção de compra
         */
        refreshPurchaseStatus: async ($token) => {
            const response = await Api({
                method: 'post',
                url: replaceParams(URLs.club.purchases.status, { token: $token }),
            })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar confirmar o pagamento.' };
                });
            return response;
        },
        /**
         * Solicita a confirmação do pagamento de uma intenção de compra
         */
        applyCoupom: async ($token, $coupom) => {
            const response = await Api({
                method: 'post',
                url: replaceParams(URLs.club.purchases.coupom, { token: $token }),
                data: {
                    coupom: $coupom,
                },
            })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar confirmar o pagamento.' };
                });
            return response;
        },
    };
};
