import React, { useState } from 'react';
import {
    Editor,
    EditorProvider,
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnNumberedList,
    BtnRedo,
    BtnUnderline,
    BtnUndo,
    BtnLink,
    HtmlButton,
    createButton,
    createDropdown,
    BtnStrikeThrough,
    Separator,
    Toolbar,
} from 'react-simple-wysiwyg';
import { faAlignLeft, faAlignCenter, faAlignRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EmoticonSelectorPopup from './EmoticonSelector/EmoticonSelectorPopup';

/**
 * HTML WYSIWYG Editor
 * @param toolbarBefore Elementos da toolbar para serem exibidos antes dos elementos nativos
 * @param toolbarAfter Elementos da toolbar para serem exibidos depois dos elementos nativos
 * @param toolbarControls Elementos da toolbar para serem exibidos abaixo de todos os demais
 * @param html Texto html que está sendo editado
 * @param onChange Função de callback da aleração do texto HTML
 * @param config Objeto de configuração dos controles que deverão ser exibidos. default=true para todos
 */
export default function HTMLWWEditor({
    toolbarBefore = '',
    toolbarAfter = '',
    toolbarControls = '',
    html = '',
    onChange = () => {},
    disabled = false,
    config = {},
}) {
    const BtnAlignLeft = createButton('Alinhar à esquerda', <FontAwesomeIcon icon={faAlignLeft} className="mx-2" />, 'justifyLeft');
    const BtnAlignCenter = createButton('Alinhar ao centro', <FontAwesomeIcon icon={faAlignCenter} className="mx-2" />, 'justifyCenter');
    const BtnAlignRight = createButton('Alinhar à direita', <FontAwesomeIcon icon={faAlignRight} className="mx-2" />, 'justifyRight');
    const BtnTab = createButton('Inserir espaço', <FontAwesomeIcon icon={faArrowRight} className="mx-2" />, () => {
        document.execCommand('insertHTML', false, '&#09;');
    });

    const BtnStyles = createDropdown('Estilos', [
        ['Normal', 'formatBlock', 'DIV'],
        ['Título 1', 'formatBlock', 'H1'],
        ['Título 2', 'formatBlock', 'H2'],
        ['Título 3', 'formatBlock', 'H3'],
    ]);

    const [emoticonSelectorPopupConfig, setEmoticonSelectorPopupConfig] = useState({
        isOpen: false,
        closeModal: () => setEmoticonSelectorPopupConfig((prev) => ({ ...prev, isOpen: false })),
        onSelectEmoticon: () => {},
    });
    const closeEmoticonSelectorPopup = () => setEmoticonSelectorPopupConfig((prev) => ({ ...prev, isOpen: false }));
    const BtnEmoticons = createButton('Inserir emoji', <>&#x1F642;</>, () => {
        setEmoticonSelectorPopupConfig((prev) => ({
            ...prev,
            isOpen: true,
            onSelectEmoticon: (emoji) => {
                document.execCommand('insertHTML', false, emoji.emoji);
                closeEmoticonSelectorPopup();
            },
        }));
    });

    return (
        <EditorProvider>
            <Editor
                containerProps={{ style: { resize: 'vertical', width: '100%' } }}
                value={html}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
            >
                <Toolbar>
                    {toolbarBefore}
                    <div className="d-flex flex-wrap align-items-center">
                        {config?.btnUndo !== false && <BtnUndo />}
                        {config?.btnRedo !== false && <BtnRedo />}
                        {config?.btnUndo !== false || (config?.btnRedo !== false && <Separator />)}

                        {config?.btnBold !== false && <BtnBold />}
                        {config?.btnItalic !== false && <BtnItalic />}
                        {config?.btnUnderline !== false && <BtnUnderline />}
                        {config?.btnStrikeThrough !== false && <BtnStrikeThrough />}
                        {config?.btnStrikeThrough !== false && <BtnTab />}
                        {config?.btnBold !== false ||
                            config?.btnItalic !== false ||
                            config?.btnUnderline !== false ||
                            config?.btnTab !== false ||
                            (config?.btnStrikeThrough !== false && <Separator />)}

                        {config?.btnNumberedList !== false && <BtnNumberedList />}
                        {config?.btnBulletList !== false && <BtnBulletList />}
                        {config?.btnNumberedList !== false || (config?.btnBulletList !== false && <Separator />)}

                        {config?.btnAlignLeft !== false && <BtnAlignLeft />}
                        {config?.btnAlignCenter !== false && <BtnAlignCenter />}
                        {config?.btnAlignRight !== false && <BtnAlignRight />}
                        {config?.btnAlignLeft !== false ||
                            config?.btnAlignCenter !== false ||
                            (config?.btnAlignRight !== false && <Separator />)}

                        {config?.btnEmoticons !== false && <BtnEmoticons />}
                        {config?.btnEmoticons !== false && <Separator />}

                        {config?.btnClearFormatting !== false && <BtnClearFormatting />}
                        {config?.btnLink !== false && <BtnLink />}
                        {config?.btnStyles !== false && <BtnStyles />}
                        {config?.btnHtml !== false && <HtmlButton />}
                    </div>
                    {toolbarAfter}
                    {toolbarControls}
                </Toolbar>
            </Editor>
            <EmoticonSelectorPopup {...emoticonSelectorPopupConfig} />
        </EditorProvider>
    );
}
