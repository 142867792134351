import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from 'reactstrap';

import { AuthFooter, AuthHeader, SideContent, TipMessage } from '../components';

export default function Welcome() {
    return (
        <div className="auth-screen">
            <Container className="col-10" style={{ maxWidth: '1200px' }}>
                <div className="phone-confirmation col-12 justify-content-center">
                    <AuthHeader />
                    <div className="auth-container col-12">
                        <div className="auth-form-container col-12 col-sm-5">
                            <div className="justify-content-center mb-3 col-12">
                                <div className="col-12">
                                    <h2 className="text-center">Bem Vindo</h2>
                                </div>
                            </div>
                            <p className="my-2 text-medium">
                                Seja bem vindo ao site do <strong>Doutor Caminhoneiro!</strong>
                            </p>
                            <p className="my-2 text-medium">É uma alegria imensa ter você se juntando à nossa incrível comunidade!</p>
                            <p className="my-2 text-medium">
                                Nosso site ainda não está no ar, mas não poderíamos deixar de lhe dizer o quanto você é importante para nós.
                            </p>
                            <p className="my-2 text-medium">
                                Estamos trabalhando duro nos bastidores para lançar um site alucinante que vai revolucionar a vida dos
                                caminhoneiros Brasil afora! Sabemos que você está ansioso(a) para explorar nosso conteúdo exclusivo,
                                preparado especialmente para você.
                            </p>
                            <p className="my-2 text-medium">
                                Em breve, você receberá uma mensagem nossa com todos os detalhes para definir sua senha e acessar nosso
                                site.
                            </p>
                            <p className="my-2 text-medium">
                                Agradecemos de coração por fazer parte da família Doutor Caminhoneiro. Prepare-se para uma experiência
                                fantástica, porque estamos quase lá!
                            </p>
                            <p className="my-2 text-medium">
                                Forte abraço,
                                <br />A equipe <strong>Doutor Caminhoneiro</strong>
                            </p>
                            <div className="col-12 d-flex justify-content-center align-items-center mb-3">
                                <div className="d-flex col-6 px-1">
                                    <Link to="/login" className="btn btn-primary col-12">
                                        VOLTAR PARA LOGIN
                                    </Link>
                                </div>
                            </div>
                            <TipMessage className="text-small my-2">
                                Surgiu alguma dúvida? Entre em contato conosco através do email{' '}
                                <a className="text-light-blue" target="_blank" rel="noreferrer" href="mailto:contato@transprolog.com">
                                    contato@transprolog.com
                                </a>{' '}
                                ou pelo{' '}
                                <a
                                    className="text-light-blue"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://web.whatsapp.com/send?phone=5519993142939"
                                >
                                    Whatsapp
                                </a>
                                .
                            </TipMessage>
                        </div>
                        <SideContent className="col-12 col-sm-7" />
                    </div>
                </div>
            </Container>
            <AuthFooter />
        </div>
    );
}
