import React from 'react';
import { ErrorMessage, Formik } from 'formik';
import { Button, Form } from 'reactstrap';
import FilterContainer, { FilterFields, FilterTabLink, FilterTabs } from '@app/containers/FilterContainer/FilterContainer';
import * as yup from 'yup';

export default function StyleguideHeader({ active = '' }) {
    const validations = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().min(8).required(),
    });

    const initialValues = {
        email: '',
        password: '',
    };

    return (
        <FilterContainer className="d-flex col-12 flex-column">
            <FilterFields className="col-12 flex-shrink-0">
                <Formik initialValues={initialValues} onSubmit="" validationSchema={validations}>
                    <Form className="login d-flex mb-3 col-12 ">
                        <fieldset className="col-4">
                            <label htmlFor="year">Ano</label>
                            <select name="year" id="filter-year">
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                            </select>
                            <ErrorMessage component="span" name="email" className="login-error" />
                        </fieldset>
                        <fieldset className="col-4">
                            <label htmlFor="month">Mês</label>
                            <select name="month" id="filter-month">
                                <option value="01">Jan</option>
                                <option value="02">Fev</option>
                                <option value="03">Mar</option>
                                <option value="04">Abr</option>
                                <option value="05">Mai</option>
                                <option value="06">Jun</option>
                                <option value="07">Jul</option>
                                <option value="08">Ago</option>
                                <option value="09">Set</option>
                                <option value="10">Out</option>
                                <option value="11">Nov</option>
                                <option value="12">Dez</option>
                            </select>
                            <ErrorMessage component="span" name="email" className="login-error" />
                        </fieldset>
                        <fieldset className="col-4">
                            <Button type="submit" outline color="primary">
                                Filtrar
                            </Button>
                        </fieldset>
                    </Form>
                </Formik>
            </FilterFields>
            <div className="d-flex col-12 justify-content-end">
                <FilterTabs active="">
                    <FilterTabLink key="buttons" to="/admin/styleguide/buttons" active={active === 'buttons'}>
                        Botões
                    </FilterTabLink>
                    <FilterTabLink key="components" to="/admin/styleguide/components" active={active === 'components'}>
                        Componentes
                    </FilterTabLink>
                    <FilterTabLink key="forms" to="/admin/styleguide/forms" active={active === 'forms'}>
                        Formulários
                    </FilterTabLink>
                    <FilterTabLink key="colors" to="/admin/styleguide/colors" active={active === 'colors'}>
                        Cores
                    </FilterTabLink>
                    <FilterTabLink key="icons" to="/admin/styleguide/icons" active={active === 'icons'}>
                        Ícones
                    </FilterTabLink>
                </FilterTabs>
            </div>
        </FilterContainer>
    );
}
