import React from 'react';

export default function CancelSubscriptionConfirmation() {
    return (
        <div className="mb-1 col-12">
            <div className="my-1">
                O cancelamento da sua assinatura está sendo processado pelo <strong>Doutor Caminhoneiro</strong>. Não se preocupe, você será
                notificado assim que estiver tudo pronto.
            </div>
        </div>
    );
}
