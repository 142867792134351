import React, { useContext } from 'react';
import parse from 'html-react-parser';

import PlanContext from '../PlanContext';

export default function PlanBody({ props }) {
    const { plan, themeColor } = useContext(PlanContext);

    return (
        <div className="plan-body" {...props} style={themeColor?.text ? { color: themeColor.text } : {}}>
            {parse(plan?.details || '')}
        </div>
    );
}
