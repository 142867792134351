import React, { useContext, useEffect, useState } from 'react';
import parse from 'html-react-parser';

import { useFormikContext } from 'formik';
import HTMLWWEditor from '@app/components/HTMLEditor';
import { ContentSizeSelector, ContentPositionDropdown } from '../CustomTools';
import ContentEditorContext from '../ContentEditorContext';

export default function HTMLEditor({ content }) {
    const { isChanging, onApplyChanges, onDeleteContent } = useContext(ContentEditorContext);
    const { setValues } = useFormikContext();

    const [html, setHtml] = useState(content.html);
    useEffect(() => {
        setHtml(content.html);
        //        setIsChanging(false);
    }, [content]);

    const applyChanges = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => (item.id === content.id ? { ...item, html } : item)),
        }));
        onApplyChanges();
    };

    if (isChanging !== true) return parse(html);

    return (
        <HTMLWWEditor
            html={html}
            onChange={(val) => setHtml(val)}
            toolbarBefore={
                <>
                    <ContentSizeSelector id={content.id} contentClass={content.className} className="d-flex flex-wrap" />
                    <ContentPositionDropdown id={content.id} position={content.order} />
                </>
            }
            toolbarControls={
                <div className="col-12 my-1 d-flex">
                    <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                        <button type="button" className="btn btn-success col-10" onClick={applyChanges}>
                            APLICAR
                        </button>
                    </div>
                    <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                        <button type="button" className="btn btn-danger col-10" onClick={() => onDeleteContent(content.id)}>
                            EXCLUIR
                        </button>
                    </div>
                </div>
            }
        />
    );
}
