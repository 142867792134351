import React from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import AccountTabsMenu from '../components/AccountTabsMenu';
import ChangeUserPassword from './components/ChangeUserPassword';
import ChangeUserConfig from './components/ChangeUserConfig';

function Configurations() {
    return (
        <ContentWrapper>
            <AccountTabsMenu active="configurations" />
            <div className="page-content">
                <h2 className="text-center col-12 bg-primary br-4 p-1 text-white text-large mt-1">CONFIGURAÇÕES DA CONTA</h2>
                <ChangeUserConfig />
                <ChangeUserPassword />
            </div>
        </ContentWrapper>
    );
}

export default Configurations;
