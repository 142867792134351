import React, { useState, useEffect, useCallback } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { ErrorMessage, Field, Formik } from 'formik';
import { Button, Form, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import { CustomSwitch, NavigateBackButton } from '@app/components/ui';
import { useValidators } from '@app/validators';
import FormikDevTools from '@app/components/FormikDevTools';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import * as yup from 'yup';
import { useUserManager } from '@app/hooks/useUserManager';

import { toast } from 'react-hot-toast';

import AddressFields from '@app/components/AddressFields/AddressFields';
import BankSelector from '@app/components/BankSelector/BankSelector';

import './suppliers_form.scss';

export default function SuppliersForm(props) {
    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    const { AddressValidator, CPFValidator, CNPJValidator } = useValidators();

    // Lista de vídeos disponíveis no VIMEO
    const [isLoading, setIsLoading] = useState(false);
    const [supplierToken, setSupplierToken] = useState('');
    const [supplierData, setSupplierData] = useState({});

    useEffect(() => {
        if ((props?.match?.params?.token || '') === '') return;
        setSupplierToken(props?.match?.params?.token);
    }, [props?.match?.params?.token]);

    useEffect(() => {
        if (supplierToken === '') return;
        // Carrega os dados do produto
        const loadSupplierData = (token) => {
            setIsLoading(true);
            const url = replaceParams(URLs.admin.suppliers.show, { nocache: new Date().getTime(), token });
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setSupplierData(response.data);
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do fornecedor');
                });
        };

        loadSupplierData(supplierToken);
    }, [replaceParams, supplierToken]);

    // Faz o submit dos dados do produto
    const handleSupplierSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        const method = (values?.token || '') !== '' ? 'put' : 'post';
        const url = replaceParams(URLs.admin.suppliers[method], { token: values.token ? values.token : '' });

        Api({ method: 'post', url, data: values })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    setSupplierToken(response.data.token);
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados do fornecedor!');
                setSubmitting(false);
            });
    };

    /**
     * Bank Account Methods
     */
    // Faz o submit dos dados do produto
    const getBankNameByCode = (code, values, setValues) => {
        const url = replaceParams(URLs.services.banks.show, { code });
        Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (response.data && response.status === 200) {
                    const bank_name = `(${response.data?.code || ''}) ${response.data?.name || ''}`;
                    setValues({
                        ...values,
                        ...{
                            default_bank_account: {
                                ...values.default_bank_account,
                                ...{
                                    bank_name,
                                },
                            },
                        },
                    });
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao obter nome do banco!');
            });
    };

    const [loadingPagarme, setLoadingPagarme] = useState(false);
    const loadSupplierIDFromPagarme = (values, setValues) => {
        setLoadingPagarme(true);
        const pagarme_id = values?.pagarme_id || '';
        const url = replaceParams(URLs.admin.suppliers.pagarme, { nocache: new Date().getTime(), id: pagarme_id });
        Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (response.data && response.status === 200 && response.data?.receiver) {
                    const newValues = {
                        ...values,
                        ...{
                            default_bank_account: {
                                description: response.data.receiver.description || response.data.receiver.default_bank_account.holder_name,
                                holder_name: response.data.receiver.default_bank_account.holder_name || '',
                                bank: response.data.receiver.default_bank_account.bank || '',
                                bank_name: '',
                                branch_number: response.data.receiver.default_bank_account.branch_number || '',
                                branch_check_digit: response.data.receiver.default_bank_account.branch_check_digit || '',
                                account_number: response.data.receiver.default_bank_account.account_number || '',
                                account_check_digit: response.data.receiver.default_bank_account.account_check_digit || '',
                                holder_type: response.data.receiver.default_bank_account.holder_type || '',
                                holder_document: response.data.receiver.default_bank_account.holder_document || '',
                                type: response.data.receiver.default_bank_account.type || '',
                                extras: {
                                    pagarme_id,
                                    pagarme_account_id: response.data.receiver.default_bank_account.id || '',
                                    pagarme_status: response.data.receiver.status || '',
                                    transfer_enabled: response.data.receiver?.transfer_settings?.transfer_enabled || false,
                                    transfer_interval: response.data.receiver?.transfer_settings?.transfer_interval || '',
                                    transfer_day: response.data.receiver?.transfer_settings?.transfer_day || '',
                                },
                            },
                        },
                    };
                    getBankNameByCode(response.data.receiver.default_bank_account.bank, newValues, setValues);
                    toast.success('Usuário verificado no Pagar.me');
                    setLoadingPagarme(false);
                } else {
                    toast.error(response.data.message);
                    setLoadingPagarme(false);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do recebedor');
                setLoadingPagarme(false);
            });
    };

    const handleToggleHasPagarme = (choice, setValues) => {
        // Fornecedor já possui conta no pagar.me
        setValues((prev) => ({
            ...prev,
            ...{
                has_pagarme: choice,
                pagarme_id: supplierData?.default_bank_account?.pagarme_id || '',
                default_bank_account: {
                    description: '',
                    holder_name: '',
                    bank: '',
                    bank_name: '',
                    branch_number: '',
                    branch_check_digit: '',
                    account_number: '',
                    account_check_digit: '',
                    holder_type: 'individual',
                    holder_document: '',
                    type: 'checking',
                    extras: {
                        pagarme_id: '',
                        pagarme_account_id: '',
                        pagarme_status: '',
                        transfer_enabled: false,
                        transfer_interval: 'Daily',
                        transfer_day: '0',
                    },
                },
            },
        }));
    };

    const [accessMessage, setAccessMessage] = useState({ type: '', message: '' });
    const getUserDataByEmail = (values, setValues) => {
        const url = replaceParams(URLs.admin.users.basics, { nocache: new Date().getTime(), email: values.email });
        Api({ method: 'get', url })
            .then((response) => {
                if (response.data && response.status === 200) {
                    let user = null;
                    if (response.data.length === 0) {
                        // usuário não existe
                        setAccessMessage({
                            type: 'error',
                            message: `Nenhum usuário encontrado para o email "${values.email}". Um novo usuário será criado utilizando os mesmos dados do fornecedor.`,
                        });
                    } else if ((response.data[0]?.supplier || null) !== null && response.data[0]?.supplier?.email !== values.email) {
                        // usuário já está associado a outro fornecedor
                        setAccessMessage({ type: 'error', message: 'O usuário já existe e está associado à outro fornecedor.' });
                    } else {
                        [user] = response.data;
                        setAccessMessage({
                            type: 'success',
                            message: `O usuário '${user?.name || ''}' será associado à esta conta de fornecedor.`,
                        });
                    }
                    setValues((prev) => ({
                        ...prev,
                        ...{
                            has_access: true,
                            user_id: user?.id || null,
                            user,
                        },
                    }));
                } else {
                    toast.error('Houve um erro ao tentar localizar o usuário.');
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar localizar o usuário.');
            });
    };
    const handleToggleHasAccess = (choice, values, setValues) => {
        setAccessMessage({ type: '', message: '' });
        if (choice) {
            getUserDataByEmail(values, setValues);
        } else {
            // Fornecedor já possui conta no pagar.me
            setValues((prev) => ({
                ...prev,
                ...{
                    has_access: false,
                    user_id: '',
                    user: {},
                },
            }));
        }
    };

    /**
     * Inicialização e validação do formulário
     */
    const validations = yup.object().shape({
        name: yup
            .string()
            .min(3, 'O nome do fornecedor precisa ter ao menos 3 dígitos.')
            .required('É necessário informar o nome do fornecedor.'),
        email: yup.string().email('Formato de email inválido').required('É necessário informar um endereço de email.'),
        phone: yup.string().min(11, 'Informe o número de telefone com o DDD').required('É necessário informar um número de telefone.'),
        document_type: yup.string(),
        document: yup.string().when(['document_type'], {
            is: 'cpf',
            then: CPFValidator,
            otherwise: CNPJValidator,
        }),
        main_address: AddressValidator,
        default_bank_account: yup.object().shape({
            description: yup
                .string()
                .min(5, 'A descrição da conta deve conter ao menos 5 caracteres')
                .required('É necessário informar uma descrição para a conta.'),
            holder_name: yup
                .string()
                .min(5, 'O nome do titular deve conter ao menos 5 caracteres')
                .required('É necessário informar o nome do titular.'),
            bank: yup.string().required('É necessário selecionar um banco.'),
            branch_number: yup.string().required('É necessário informar o número da agência.'),
            account_number: yup.string().required('É necessário informar o número da conta.'),
            account_check_digit: yup.string().required('É necessário informar o dígito verificador da conta.'),
            holder_document: yup
                .string()
                .min(11, 'Formato de documento inválido')
                .document('Documento inválido.')
                .required('É necessário informar o documento do titular.'),
        }),
    });

    const initialValues = {
        token: supplierData?.token || '',
        name: supplierData?.name || '',
        email: supplierData?.email || '',
        phone: supplierData?.phone || '',
        document_type: supplierData?.document_type || 'cpf',
        document: supplierData?.document || '',
        has_access: (supplierData?.user_id || null) !== null || false,
        user_id: supplierData?.user_id || null,
        user: supplierData?.user || null,
        charge_processing_fee: supplierData?.charge_processing_fee || false,
        charge_remainder_fee: supplierData?.charge_remainder_fee || false,
        liable: supplierData?.liable || false,
        main_address: {
            address: supplierData?.main_address?.address || '',
            number: supplierData?.main_address?.number || '',
            complement: supplierData?.main_address?.complement || '',
            reference: supplierData?.main_address?.reference || '',
            zip: supplierData?.main_address?.zip || '',
            neighborhood: supplierData?.main_address?.neighborhood || '',
            state: supplierData?.main_address?.city?.state || '',
            city_id: supplierData?.main_address?.city?.id || '',
            city_name: supplierData?.main_address?.city?.name || '',
        },
        has_pagarme: (supplierData?.default_bank_account?.extras?.pagarme_id || '') !== '',
        pagarme_id: supplierData?.default_bank_account?.extras?.pagarme_id || '',
        default_bank_account: {
            description: supplierData?.default_bank_account?.description || '',
            holder_name: supplierData?.default_bank_account?.holder_name || '',
            bank: supplierData?.default_bank_account?.bank || '',
            bank_name: supplierData?.default_bank_account?.bank_name || '',
            branch_number: supplierData?.default_bank_account?.branch_number || '',
            branch_check_digit: supplierData?.default_bank_account?.branch_check_digit || '',
            account_number: supplierData?.default_bank_account?.account_number || '',
            account_check_digit: supplierData?.default_bank_account?.account_check_digit || '',
            holder_type: supplierData?.default_bank_account?.holder_type || '',
            holder_document: supplierData?.default_bank_account?.holder_document || '',
            type: supplierData?.default_bank_account?.type || '',
            extras: {
                pagarme_status: supplierData?.default_bank_account?.extras?.pagarme_status || '',
                pagarme_id: supplierData?.default_bank_account?.extras?.pagarme_id || '',
                pagarme_account_id: supplierData?.default_bank_account?.extras?.pagarme_account_id || '',
                transfer_enabled: supplierData?.default_bank_account?.extras?.transfer_enabled || false,
                transfer_interval: supplierData?.default_bank_account?.extras?.transfer_interval || 'Daily',
                transfer_day: supplierData?.default_bank_account?.extras?.transfer_day || '0',
            },
        },
        use_main_address: false,
    };
    const document_formats = {
        cpf: { mask: '999.999.999-99', placeholder: '___.___.___-__' },
        cnpj: { mask: '99.999.999/9999-99', placeholder: '__.___.___/____-__' },
        individual: { mask: '999.999.999-99', placeholder: '___.___.___-__' },
        company: { mask: '99.999.999/9999-99', placeholder: '__.___.___/____-__' },
    };
    const getOptions = useCallback((interval, anticipation_days) => {
        if ((anticipation_days?.interval || null) === null) return null;
        if (interval === 'Daily') return <option value={0}>0</option>;
        return Array.from(anticipation_days[interval].keys()).map((i, key) => (
            <option key={key} value={i + 1}>
                {anticipation_days[interval][i]}
            </option>
        ));
    }, []);
    const anticipation_days = {
        Daily: ['0'],
        Weekly: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta'],
        Monthly: Array.from(Array(31).keys()).map((i) => i + 1),
        getOptions: (interval) => getOptions(interval, anticipation_days),
    };

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper className="suppliers_form" hideTitle>
            <div className="d-flex px-3">
                <NavigateBackButton title="Retornar para a página de fornecedores" />
                <h1 className="content-title flex-shrink-1 col-12 text-center">
                    {(supplierData?.token || '') === '' ? 'CADASTRAR NOVO FORNECEDOR' : 'ALTERAR DADOS DO FORNECEDOR'}
                </h1>
            </div>
            <div className="page-content">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validations}
                    onSubmit={handleSupplierSubmit}
                    enableReinitialize
                    render={(formikProps) => (
                        <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                            <Field type="hidden" name="id" />
                            <Field type="hidden" name="stripe_id" />
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="name">
                                    Nome<em>*</em>
                                </Label>
                                <Field name="name" type="text" placeholder="Nome do fornecedor" className="col-12" disabled={isLoading} />
                                <ErrorMessage component="span" name="name" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="email">
                                    Email<em>*</em>
                                </Label>
                                <Field
                                    name="email"
                                    type="email"
                                    placeholder="Ex: admin@gmail.com"
                                    className="col-12"
                                    disabled={isLoading}
                                    onChange={(e) => {
                                        setAccessMessage({ type: '', message: '' });
                                        formikProps.setValues((prev) => ({
                                            ...prev,
                                            ...{
                                                email: e.target.value,
                                                has_access: false,
                                                user_id: null,
                                                user: {},
                                            },
                                        }));
                                    }}
                                />
                                <ErrorMessage component="span" name="email" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6 col-lg-4">
                                <Label className="field-label" htmlFor="phone">
                                    Telefone<em>*</em>
                                </Label>
                                <InputMask
                                    name="phone"
                                    type="text"
                                    className="col-12"
                                    mask="(99) 99999-9999"
                                    placeholder="(__) _____-____"
                                    value={formikProps.values.phone}
                                    onChange={({ target: { value } }) => {
                                        formikProps.setFieldTouched('phone');
                                        formikProps.setValues({
                                            ...formikProps.values,
                                            ...{
                                                phone: value.toUpperCase(),
                                            },
                                        });
                                    }}
                                />
                                <ErrorMessage component="span" name="phone" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6 col-lg-4">
                                <Label className="field-label" htmlFor="document_type">
                                    Tipo de documento
                                </Label>
                                <select
                                    name="document_type"
                                    className="col-12"
                                    value={formikProps.values?.document_type || ''}
                                    onChange={(e) => formikProps.setValues({ ...formikProps.values, ...{ document_type: e.target.value } })}
                                    disabled={isLoading}
                                >
                                    <option value="cpf">CPF</option>
                                    <option value="cnpj">CNPJ</option>
                                </select>
                                <ErrorMessage component="span" name="document_type" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6 col-lg-4">
                                <Label className="field-label" htmlFor="document">
                                    Documento<em>*</em>
                                </Label>
                                <InputMask
                                    name="document"
                                    type="text"
                                    className="col-12"
                                    mask={document_formats?.[formikProps.values.document_type]?.mask || ''}
                                    placeholder={document_formats?.[formikProps.values.document_type]?.placeholder || ''}
                                    value={formikProps.values.document}
                                    onChange={({ target: { value } }) => {
                                        formikProps.setFieldTouched('document');
                                        formikProps.setValues({
                                            ...formikProps.values,
                                            ...{
                                                document: value.toUpperCase(),
                                            },
                                        });
                                    }}
                                />
                                <ErrorMessage component="span" name="document" className="text-error text-small px-2" />
                            </div>

                            <h3 className="field-group-title col-12 mt-3">Acesso ao sistema</h3>
                            <div className="col-12 col-lg-6 d-flex flex-column mb-3 mt-1 px-1 ">
                                <div className="d-flex align-items-center mb-1">
                                    <CustomSwitch
                                        name="has_access"
                                        className="justify-content-center mx-2"
                                        onChange={(choice) => handleToggleHasAccess(choice, formikProps.values, formikProps.setValues)}
                                        checked={formikProps.values?.has_access}
                                        isAsync={false}
                                        disabled={isLoading || formikProps.values?.email === '' || formikProps.errors?.email}
                                        data={{ id: props?.data?.token, status: formikProps.values?.has_access }}
                                    />
                                    <Label className="field-label" htmlFor="offer_once">
                                        Permitir acesso do fornecedor ao sistema
                                    </Label>
                                </div>
                            </div>
                            <div className="col-12 d-flex flex-wrap px-1">
                                {accessMessage?.type !== '' && (
                                    <p className={`col-12 text-center text-${accessMessage.type}`}>{accessMessage.message}</p>
                                )}
                            </div>
                            {formikProps.values?.user && (
                                <div className="col-12 d-flex">
                                    <div className="mb-1 px-1 col-12 col-sm-6">
                                        <Label className="field-label" htmlFor="name">
                                            Nome do usuário
                                        </Label>
                                        <Field name="user.name" type="text" placeholder="Nome do fornecedor" className="col-12" disabled />
                                    </div>
                                    <div className="mb-1 px-1 col-12 col-sm-6">
                                        <Label className="field-label" htmlFor="name">
                                            Email de acesso
                                        </Label>
                                        <Field name="user.email" type="text" placeholder="Nome do fornecedor" className="col-12" disabled />
                                    </div>
                                </div>
                            )}

                            <h3 className="field-group-title col-12 mt-3">Endereço</h3>
                            <AddressFields
                                field_context="main_address"
                                onChange={(address) => {
                                    formikProps.setValues({ ...formikProps.values, ...{ main_address: address } });
                                }}
                                errors={formikProps.errors?.main_address || {}}
                            />

                            <h3 className="field-group-title col-12 mt-3">Dados bancários</h3>
                            <div className="col-12 col-lg-6 d-flex flex-column mb-3 mt-1 px-1 ">
                                <div className="d-flex align-items-center mb-1">
                                    <CustomSwitch
                                        name="has_pagarme"
                                        className="justify-content-center mx-2"
                                        onChange={(choice) => handleToggleHasPagarme(choice, formikProps.setValues)}
                                        checked={formikProps.values?.has_pagarme}
                                        isAsync={false}
                                        disabled={isLoading}
                                        data={{ id: props?.data?.token, status: formikProps.values?.has_pagarme }}
                                    />
                                    <Label className="field-label" htmlFor="offer_once">
                                        Utilizar conta existente na plataforma Pagar.me
                                    </Label>
                                </div>
                                <div className="d-flex align-items-center">
                                    <CustomSwitch
                                        name="has_pagarme"
                                        className="justify-content-center mx-2"
                                        onChange={(choice) => handleToggleHasPagarme(!choice, formikProps.setValues)}
                                        checked={!formikProps.values?.has_pagarme}
                                        isAsync={false}
                                        disabled={isLoading}
                                        data={{ id: props?.data?.token, status: !formikProps.values?.has_pagarme }}
                                    />
                                    <Label className="field-label" htmlFor="offer_once">
                                        Criar uma nova conta na plataforma Pagar.me
                                    </Label>
                                </div>
                            </div>
                            <div className="mb-1 px-1 col-12 col-lg-6 d-flex flex-wrap">
                                <Label className="field-label col-12" htmlFor="default_bank_account.pagarme_id">
                                    ID Pagar.me
                                </Label>
                                <div className="col-8">
                                    <Field
                                        name="pagarme_id"
                                        type="text"
                                        placeholder=""
                                        className="col-12"
                                        disabled={isLoading || loadingPagarme || !formikProps.values.has_pagarme}
                                    />
                                </div>
                                <Button
                                    type="button"
                                    className="col-3 mx-1"
                                    color="secondary"
                                    onClick={() => loadSupplierIDFromPagarme(formikProps.values, formikProps.setValues)}
                                    disabled={
                                        formikProps.isSubmitting ||
                                        isLoading ||
                                        !formikProps.values.has_pagarme ||
                                        (formikProps.values?.pagarme_id || '').length < 10
                                    }
                                >
                                    BUSCAR
                                </Button>
                                <ErrorMessage
                                    component="span"
                                    name="default_bank_account.pagarme_id"
                                    className="text-error text-small px-2"
                                />
                            </div>
                            <div className="d-flex flex-wrap">
                                <h3 className="text-center text-small py-1 my-1 col-12 bg-light-brown text-dark-gray">
                                    CONTA BANCÁRIA PRINCIPAL
                                </h3>
                                <div className="mb-1 px-1 col-12 col-md-6">
                                    <Label className="field-label" htmlFor="default_bank_account.description">
                                        Descrição<em>*</em>
                                    </Label>
                                    <Field
                                        name="default_bank_account.description"
                                        type="text"
                                        className="col-12"
                                        maxLength="256"
                                        disabled={isLoading}
                                    />
                                    <ErrorMessage
                                        component="span"
                                        name="default_bank_account.description"
                                        className="text-error text-small px-2"
                                    />
                                </div>
                                <div className="mb-1 px-1 col-12 col-sm-6">
                                    <Label className="field-label" htmlFor="default_bank_account.holder_name">
                                        Titular da conta<em>*</em>
                                    </Label>
                                    <Field
                                        name="default_bank_account.holder_name"
                                        type="text"
                                        className="col-12"
                                        maxLength="30"
                                        disabled={isLoading || formikProps.values?.has_pagarme}
                                    />
                                    <ErrorMessage
                                        component="span"
                                        name="default_bank_account.holder_name"
                                        className="text-error text-small px-2"
                                    />
                                </div>
                                <div className="mb-1 px-1 col-12 col-sm-6">
                                    <Label className="field-label" htmlFor="default_bank_account.bank">
                                        Banco<em>*</em>
                                    </Label>
                                    <BankSelector
                                        disabled={isLoading || formikProps.values?.has_pagarme}
                                        bank_name={formikProps.values?.default_bank_account?.bank_name || ''}
                                        onChange={(resp) => {
                                            formikProps.setValues({
                                                ...formikProps.values,
                                                ...{
                                                    default_bank_account: {
                                                        ...formikProps.values.default_bank_account,
                                                        ...{
                                                            bank: resp.bank,
                                                            bank_name: `(${resp.bank}) ${resp.bank_name}`,
                                                        },
                                                    },
                                                },
                                            });
                                        }}
                                    />
                                    <ErrorMessage
                                        component="span"
                                        name="default_bank_account.bank"
                                        className="text-error text-small px-2"
                                    />
                                </div>
                                <div className="mb-1 px-1 d-flex flex-wrap col-12 col-sm-6 col-lg-3">
                                    <div className="mb-1 px-1 col-8 col-sm-10">
                                        <Label className="field-label" htmlFor="default_bank_account.branch_number">
                                            Agência<em>*</em>
                                        </Label>
                                        <Field
                                            name="default_bank_account.branch_number"
                                            type="text"
                                            className="col-12"
                                            maxLength="4"
                                            disabled={isLoading || formikProps.values?.has_pagarme}
                                        />
                                    </div>
                                    <div className="mb-1 px-1 col-4 col-sm-2">
                                        <Label className="field-label" htmlFor="default_bank_account.branch_check_digit">
                                            Dígito
                                        </Label>
                                        <Field
                                            name="default_bank_account.branch_check_digit"
                                            type="text"
                                            className="col-12"
                                            maxLength="1"
                                            disabled={isLoading || formikProps.values?.has_pagarme}
                                        />
                                    </div>
                                    <ErrorMessage
                                        component="span"
                                        name="default_bank_account.branch_number"
                                        className="text-error text-small col-12 px-2"
                                    />
                                    <ErrorMessage
                                        component="span"
                                        name="default_bank_account.branch_check_digit"
                                        className="text-error text-small col-12 px-2"
                                    />
                                </div>
                                <div className="mb-1 px-1 d-flex flex-wrap col-12 col-sm-6 col-lg-3">
                                    <div className="mb-1 px-1 col-8 col-sm-10">
                                        <Label className="field-label" htmlFor="default_bank_account.account_number">
                                            Conta<em>*</em>
                                        </Label>
                                        <Field
                                            name="default_bank_account.account_number"
                                            type="text"
                                            className="col-12"
                                            maxLength="13"
                                            disabled={isLoading || formikProps.values?.has_pagarme}
                                        />
                                    </div>
                                    <div className="mb-1 px-1 col-4 col-sm-2">
                                        <Label className="field-label" htmlFor="default_bank_account.account_check_digit">
                                            Dígito<em>*</em>
                                        </Label>
                                        <Field
                                            name="default_bank_account.account_check_digit"
                                            type="text"
                                            className="col-12"
                                            maxLength="1"
                                            disabled={isLoading || formikProps.values?.has_pagarme}
                                        />
                                    </div>
                                    <ErrorMessage
                                        component="span"
                                        name="default_bank_account.account_number"
                                        className="text-error text-small px-2"
                                    />
                                    <ErrorMessage
                                        component="span"
                                        name="default_bank_account.account_check_digit"
                                        className="text-error text-small px-2"
                                    />
                                </div>
                                <div className="mb-1 px-1 col-12 col-sm-6 col-lg-3">
                                    <Label className="field-label" htmlFor="default_bank_account.holder_type">
                                        Tipo de titular
                                    </Label>
                                    <select
                                        name="default_bank_account.holder_type"
                                        className="col-12"
                                        value={formikProps.values?.default_bank_account?.holder_type || ''}
                                        onChange={(e) =>
                                            formikProps.setValues({
                                                ...formikProps.values,
                                                ...{
                                                    default_bank_account: {
                                                        ...formikProps.values.default_bank_account,
                                                        ...{ holder_type: e.target.value },
                                                    },
                                                },
                                            })
                                        }
                                        disabled={isLoading || formikProps.values?.has_pagarme}
                                    >
                                        <option value="individual">Pessoa física</option>
                                        <option value="company">Pessoa Jurídica</option>
                                    </select>
                                    <ErrorMessage
                                        component="span"
                                        name="default_bank_account.holder_type"
                                        className="text-error text-small px-2"
                                    />
                                </div>
                                <div className="mb-1 px-1 col-12 col-sm-6 col-lg-3">
                                    <Label className="field-label" htmlFor="default_bank_account.holder_document">
                                        Documento<em>*</em>
                                    </Label>
                                    <InputMask
                                        name="default_bank_account.holder_document"
                                        type="text"
                                        className="col-12"
                                        disabled={isLoading || formikProps.values?.has_pagarme}
                                        mask={document_formats?.[formikProps.values.default_bank_account.holder_type]?.mask || ''}
                                        placeholder={
                                            document_formats?.[formikProps.values.default_bank_account.holder_type]?.placeholder || ''
                                        }
                                        value={formikProps.values.default_bank_account.holder_document}
                                        onChange={(e) => {
                                            formikProps.setFieldTouched('default_bank_account.holder_document');
                                            formikProps.setValues({
                                                ...formikProps.values,
                                                ...{
                                                    default_bank_account: {
                                                        ...formikProps.values.default_bank_account,
                                                        ...{ holder_document: e.target.value },
                                                    },
                                                },
                                            });
                                        }}
                                    />
                                    <ErrorMessage
                                        component="span"
                                        name="default_bank_account.holder_document"
                                        className="text-error text-small px-2"
                                    />
                                </div>
                                <div className="mb-1 px-1 col-12 col-sm-6 col-lg-3">
                                    <Label className="field-label" htmlFor="default_bank_account.type">
                                        Tipo
                                    </Label>
                                    <select
                                        name="default_bank_account.type"
                                        className="col-12"
                                        value={formikProps.values?.default_bank_account?.type || ''}
                                        onChange={(e) =>
                                            formikProps.setValues({
                                                ...formikProps.values,
                                                ...{
                                                    default_bank_account: {
                                                        ...formikProps.values.default_bank_account,
                                                        ...{ type: e.target.value },
                                                    },
                                                },
                                            })
                                        }
                                        disabled={isLoading || formikProps.values?.has_pagarme}
                                    >
                                        <option value="checking">Conta corrente</option>
                                        <option value="savings">Conta Poupança</option>
                                    </select>
                                    <ErrorMessage
                                        component="span"
                                        name="default_bank_account.type"
                                        className="text-error text-small px-2"
                                    />
                                </div>

                                <div className="d-flex flex-wrap col-12">
                                    <div className="d-flex align-items-center col-12 py-1 mt-2">
                                        <CustomSwitch
                                            name="default_bank_account.extras.transfer_enabled"
                                            className="justify-content-center mx-2"
                                            onChange={(choice) =>
                                                formikProps.setValues((prev) => ({
                                                    ...prev,
                                                    ...{
                                                        default_bank_account: {
                                                            ...prev.default_bank_account,
                                                            ...{
                                                                extras: {
                                                                    ...prev.default_bank_account.extras,
                                                                    ...{ transfer_enabled: choice },
                                                                },
                                                            },
                                                        },
                                                    },
                                                }))
                                            }
                                            checked={formikProps.values?.default_bank_account?.extras?.transfer_enabled || false}
                                            isAsync={false}
                                            disabled={isLoading || formikProps.values?.has_pagarme}
                                            data={{
                                                id: props?.data?.token,
                                                status: formikProps.values?.default_bank_account?.extras?.transfer_enabled || false,
                                            }}
                                        />
                                        <Label className="field-label" htmlFor="offer_once">
                                            Ativar recebimento automático
                                        </Label>
                                    </div>
                                    <div className="mb-1 px-1 col-12 col-sm-6 col-lg-3">
                                        <Label className="field-label" htmlFor="default_bank_account.extras.transfer_interval">
                                            Frequência
                                        </Label>
                                        <select
                                            name="default_bank_account.extras.transfer_interval"
                                            className="col-12"
                                            value={formikProps.values?.default_bank_account?.extras?.transfer_interval || ''}
                                            onChange={(e) =>
                                                formikProps.setValues((prev) => ({
                                                    ...prev,
                                                    ...{
                                                        default_bank_account: {
                                                            ...prev.default_bank_account,
                                                            ...{
                                                                extras: {
                                                                    ...prev.default_bank_account.extras,
                                                                    ...{
                                                                        transfer_interval: e.target.value,
                                                                        transfer_day: e.target.value === 'Daily' ? '0' : '1',
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    },
                                                }))
                                            }
                                            disabled={isLoading || formikProps.values?.has_pagarme}
                                        >
                                            <option value="Daily">Diariamente</option>
                                            <option value="Weekly">Semanalmente</option>
                                            <option value="Monthly">Mensalmente</option>
                                        </select>
                                        <ErrorMessage
                                            component="span"
                                            name="default_bank_account.extras.transfer_interval"
                                            className="text-error text-small px-2"
                                        />
                                    </div>
                                    <div className="mb-1 px-1 col-12 col-sm-3 col-lg-2">
                                        <Label className="field-label" htmlFor="default_bank_account.extras.transfer_day">
                                            Dia
                                        </Label>
                                        <select
                                            name="default_bank_account.extras.transfer_day"
                                            className="col-12"
                                            value={formikProps.values?.default_bank_account?.extras?.transfer_day || ''}
                                            onChange={(e) =>
                                                formikProps.setValues((prev) => ({
                                                    ...prev,
                                                    ...{
                                                        default_bank_account: {
                                                            ...prev.default_bank_account,
                                                            ...{
                                                                extras: {
                                                                    ...prev.default_bank_account.extras,
                                                                    ...{ transfer_day: e.target.value },
                                                                },
                                                            },
                                                        },
                                                    },
                                                }))
                                            }
                                            disabled={
                                                isLoading ||
                                                formikProps.values?.has_pagarme ||
                                                formikProps.values?.default_bank_account?.extras?.transfer_interval === 'Daily'
                                            }
                                        >
                                            {anticipation_days.getOptions(
                                                formikProps.values?.default_bank_account?.extras?.transfer_interval
                                            )}
                                        </select>
                                        <ErrorMessage
                                            component="span"
                                            name="default_bank_account.extras.holder_type"
                                            className="text-error text-small px-2"
                                        />
                                    </div>
                                </div>
                            </div>

                            <h3 className="field-group-title col-12 mt-3">Configurações de Cobrança e Split</h3>
                            <div className="col-12 d-flex flex-column mb-3 mt-1 px-1 ">
                                <div className="col-12 d-flex align-items-center mb-1">
                                    <CustomSwitch
                                        name="charge_processing_fee"
                                        id="charge_processing_fee"
                                        className="justify-content-center mx-2"
                                        onChange={(choice) => formikProps.setFieldValue('charge_processing_fee', choice)}
                                        checked={formikProps.values?.charge_processing_fee || false}
                                        isAsync={false}
                                        disabled={isLoading}
                                        data={{ id: props?.data?.token, status: formikProps.values?.charge_processing_fee }}
                                    />
                                    <Label className="field-label" htmlFor="charge_processing_fee">
                                        O recebedor será cobrado pelas taxas da transação
                                    </Label>
                                </div>
                                <div className="col-12 d-flex align-items-center mb-1">
                                    <CustomSwitch
                                        name="charge_remainder_fee"
                                        id="charge_remainder_fee"
                                        className="justify-content-center mx-2"
                                        onChange={(choice) => formikProps.setFieldValue('charge_remainder_fee', choice)}
                                        checked={formikProps.values?.charge_remainder_fee || false}
                                        isAsync={false}
                                        disabled={isLoading}
                                        data={{ id: props?.data?.token, status: formikProps.values?.charge_remainder_fee }}
                                    />
                                    <Label className="field-label" htmlFor="charge_remainder_fee">
                                        O recebedor irá receber o restante dos recebíveis após uma divisão
                                    </Label>
                                </div>
                                <div className="col-12 d-flex align-items-center mb-1">
                                    <CustomSwitch
                                        name="liable"
                                        id="liable"
                                        className="justify-content-center mx-2"
                                        onChange={(choice) => formikProps.setFieldValue('liable', choice)}
                                        checked={formikProps.values?.liable || false}
                                        isAsync={false}
                                        disabled={isLoading}
                                        data={{ id: props?.data?.token, status: formikProps.values?.liable }}
                                    />
                                    <Label className="field-label" htmlFor="liable">
                                        O recebedor é responsável pela transação em caso de chargeback
                                    </Label>
                                </div>
                            </div>

                            <div className="col-12 mt-3 d-flex">
                                <div className="mb-3 px-3 col-6 text-center">
                                    <NavigateBackButton className="col-10" color="danger" disabled={formikProps.isSubmitting || isLoading}>
                                        CANCELAR
                                    </NavigateBackButton>
                                </div>
                                <div className="mb-3 col-6 text-center">
                                    <Button
                                        type="submit"
                                        className="col-10"
                                        color="success"
                                        disabled={formikProps.isSubmitting || isLoading}
                                    >
                                        SALVAR
                                    </Button>
                                </div>
                            </div>
                            <FormikDevTools />
                        </Form>
                    )}
                />
            </div>
        </ContentWrapper>
    );
}
