import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Table, Label } from 'reactstrap';
import { CustomSwitch } from '@app/components/ui';

import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { URLs, useSetParams } from '@app/constants';
import Api from '@app/services/Api';
import { toast } from 'react-hot-toast';
import ProductDisplayEditorContext from '../../ProductDisplayEditorContext';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function SelectProductsPopup({ isOpen, ...props }) {
    const setParams = useSetParams;
    const {
        content: { filter },
        onApplyProductsSelection,
        closeSelectProductsPopup,
    } = useContext(ProductDisplayEditorContext);

    const [criteria, setCriteria] = useState([]);
    const [products, setProducts] = useState([]);
    const [name, setName] = useState('');

    const loadProductData = useCallback(
        (name = '') => {
            const url = setParams(URLs.admin.products.basics, {
                fields: 'id,name,token,product_type,supplier_id',
                loads: 'supplier:id,name',
                orderBy: 'supplier_id',
                name,
            });
            Api({ url })
                .then(({ data, status }) => {
                    if (status === 200) {
                        setProducts(data || []);
                    } else {
                        toast.error(data?.message || 'Erro ao obter dados dos produtos');
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter dados dos produtos');
                });
        },
        [setParams]
    );

    useEffect(() => {
        loadProductData(name);
    }, [name, loadProductData]);

    const onChangeProductSelection = (checked, id) => {
        setCriteria((prev) => (checked ? [...prev, id] : prev.filter((item) => item !== id)));
    };

    const afterOpenModal = () => {
        setCriteria(filter?.criteria || []);
        loadProductData();
    };

    const applyProductsSelection = () => {
        onApplyProductsSelection(criteria);
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeSelectProductsPopup}
            style={customStyles}
            contentLabel=""
            ariaHideApp={false}
            {...props}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">SELECIONE OS PRODUTOS QUE DESEJA EXIBIR</h2>
                    <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={closeSelectProductsPopup}>
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    </Button>
                </Col>
            </Row>
            <div className="d-flex flex-wrap col-12 pr-1 mb-2">
                <div className="col-6 d-flex flex-wrap">
                    <Label className="field-label">Filtrar pelo nome do produto:</Label>
                    <input type="text" className="col-12" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
            </div>
            <div className="d-flex flex-wrap col-12 pr-1">
                <div
                    className="col-12"
                    style={{
                        paddingRight: '15px',
                        backgroundColor: '#DFCEB0',
                    }}
                >
                    <Table striped bordered hover className="col-12 m-0">
                        <thead>
                            <tr>
                                <th width="10%">&nbsp;</th>
                                <th width="50%">Nome</th>
                                <th width="20%">Tipo</th>
                                <th width="20%">Fornecedor</th>
                            </tr>
                        </thead>
                    </Table>
                </div>
                <div
                    className="col-12"
                    style={{
                        maxHeight: '300px',
                        overflowY: 'scroll',
                    }}
                >
                    <Table striped bordered hover className="col-12">
                        <tbody>
                            {products.map((item, index) => (
                                <tr width="10%" key={index}>
                                    <td data-title="Selecionar">
                                        <CustomSwitch
                                            name={`select-field-${index}`}
                                            className="justify-content-center"
                                            isAsync={false}
                                            checked={criteria.includes(item.id) ? 1 : 0}
                                            data={{ index }}
                                            disabled={item?.disabled}
                                            onChange={(checked) => onChangeProductSelection(checked, item.id)}
                                        />
                                    </td>
                                    <td width="50%" data-title="Nome">
                                        {item.name}
                                    </td>
                                    <td width="20%" data-title="Tipo">
                                        {item.product_type_label}
                                    </td>
                                    <td width="20%" data-title="Fornecedor">
                                        {item?.supplier?.name || ''}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="col-12 py-2 d-flex">
                    <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                        <button type="submit" className="btn btn-success col-10" onClick={applyProductsSelection}>
                            APLICAR
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default SelectProductsPopup;
