import React, { useEffect, useState, useContext } from 'react';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import CustomerAddress from '@app/components/CustomerAddress';
import { useFloatToCurrency } from '@app/helpers';
import CheckoutContext from '../SubscriptionContext';

import './CheckoutSummary.scss';

export default function SubscriptionSummary({ className = '', style = {}, updateRequest = () => {} }) {
    const floatToCurrency = useFloatToCurrency;
    const { isLoading, subscription } = useContext(CheckoutContext);

    const [values, setValues] = useState({
        amount: 0,
        increments: 0,
        freight: 0,
        total: 0,
    });
    useEffect(() => {
        const amount = subscription?.amount || 0;
        const increments = (subscription?.increments || []).reduce((total, item) => total + item.value, 0);
        const freight = subscription?.freight || 0;
        const purchases = (subscription?.purchases || []).reduce((total, purchase) => total + purchase.total, 0);

        const total = amount + increments + freight + purchases;
        setValues({
            amount: floatToCurrency(amount),
            increments: floatToCurrency(increments),
            freight: floatToCurrency(freight),
            purchases: floatToCurrency(purchases),
            total: floatToCurrency(total),
        });
    }, [subscription, floatToCurrency]);

    return (
        <div className={`d-flex col-12 flex-wrap justify-content-center payment-summary ${className || ''}`} style={style}>
            <div className="payment-values col-12 col-md-4 p-3 d-flex flex-column">
                <div className="col-12 d-flex flex-column flex-grow-1">
                    <div className="col-12 d-flex flex-row">
                        <label className="text-medium col-8">Plano:</label>
                        <label className="text-medium col-4 text-right">R$ {values.amount}</label>
                    </div>
                    <div className="col-12 d-flex flex-row">
                        <label className="text-medium col-8">Itens Adicionais:</label>
                        <label className="text-medium col-4 text-right">R$ {values.increments}</label>
                    </div>
                    <div className="col-12 d-flex flex-row">
                        <label className="text-medium col-8">Pedidos Associados:</label>
                        <label className="text-medium col-4 text-right">R$ {values.purchases}</label>
                    </div>
                    <div className="col-12 d-flex flex-row">
                        <label className="text-medium col-8">Frete e manuseio:</label>
                        <label className="text-medium col-4 text-right">R$ {values.freight}</label>
                    </div>
                </div>
                <div className="border-top py-1 col-12 d-flex flex-row">
                    <label className="text-medium col-8">Total:</label>
                    <label className="text-medium col-4 text-right">
                        <strong>R$ {values.total}</strong>
                    </label>
                </div>
                {isLoading && <LoadingSpinner />}
            </div>
            <div className="payment-shipping col-12 col-md-8 p-3 d-flex flex-wrap">
                <CustomerAddress
                    address_field="shipping_address"
                    title="Endereço de entrega"
                    address={subscription?.user?.shipping_address || {}}
                    required={subscription?.has_shipping || false}
                    onAfterChange={() => updateRequest()}
                    canChange
                />
                <CustomerAddress
                    address_field="billing_address"
                    title="Endereço de cobrança"
                    address={subscription?.user?.billing_address || {}}
                    onAfterChange={() => updateRequest()}
                    required
                    canChange
                />
            </div>
        </div>
    );
}
