import { configureStore } from '@reduxjs/toolkit';

import AuthReducer from './AuthSlice';
import SummaryReducer from './SummarySlice';

export default configureStore({
    reducer: {
        auth: AuthReducer,
        summary: SummaryReducer
    }
});
