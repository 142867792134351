import React, { useEffect, useState } from 'react';

import './CookieAlert.scss';

function CookieAlert() {
    const [showAlert, setShowAlert] = useState(false);
    useEffect(() => {
        const cookie_alert = localStorage.getItem('cookie_alert');
        if (cookie_alert === null) setShowAlert(true);
    }, []);

    const onAcceptClick = () => {
        localStorage.setItem('cookie_alert', 'accepted');
        setShowAlert(false);
    };
    if (!showAlert) return null;

    return (
        <div className="cookie-alert d-flex">
            <div className="col-2" />
            <div className="col-8">
                Com o objetivo de ofecer a melhor experiência possível aos seus usuários, o site do <strong>Doutor Caminhoneiro</strong>{' '}
                utiliza cookies ou tecnologias similares que armazenam dados em seu navegador de internet.
                <br />
                Ao utilizar nossos serviços, você estará concordando com a utilização de tais tecnologias.
                <br />
                Quer saber mais? Acesse nossa{' '}
                <a href="/politica" title="Política de privacidade Doutor Caminhoneiro">
                    Política de Privacidade
                </a>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-center">
                <button type="button" className="btn btn-success" onClick={onAcceptClick}>
                    ENTENDI
                </button>
            </div>
        </div>
    );
}
export default CookieAlert;
