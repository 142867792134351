import React from 'react';

import './ViewingProgress.scss';

function ViewingProgress({ progress, className = '', ...props }) {
    return (
        <div {...props} className={`product-viewing-progress ${className}`}>
            <div className="pvp-bar">
                <div className="bar" style={{ width: `${progress}%` }} />
            </div>
            <div className="pvp-value">{progress}%</div>
        </div>
    );
}
export default ViewingProgress;
