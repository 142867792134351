import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltV, faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';

import './OrderedTable.scss';

const HeaderContext = React.createContext({});

function Tr({ orderBy = '', orderDir = '', handleOrderBy = () => {}, children = null, ...props }) {
    const [params, setParams] = useState({
        orderBy: orderBy || '',
        orderDir: orderDir || 'desc',
    });

    const handleColumnClick = (orderBy) => {
        let { orderDir } = params;
        let newOrderBy = orderBy;
        if (newOrderBy !== params.orderBy) {
            orderDir = 'asc';
        } else if (orderDir === 'asc') {
            orderDir = 'desc';
        } else {
            newOrderBy = '';
            orderDir = 'asc';
        }
        setParams({ orderBy: newOrderBy, orderDir });
        if (handleOrderBy) handleOrderBy(orderBy, orderDir);
    };

    return (
        <HeaderContext.Provider
            value={{
                orderBy: params.orderBy,
                orderDir: params.orderDir,
                handleClick: handleColumnClick,
            }}
        >
            <tr {...props}>{children}</tr>
        </HeaderContext.Provider>
    );
}

function Th({ children = null, param = '', title = '', className = '', ...props }) {
    const { orderBy, orderDir, handleClick } = useContext(HeaderContext);

    const ttl0 = children;
    let ttl1 = '';
    let ttl2 = '';
    const lastIndex = ttl0.lastIndexOf(' ');
    if (ttl0.indexOf(' ') > -1) {
        ttl1 = ttl0.slice(0, lastIndex + 1);
        ttl2 = ttl0.slice(lastIndex + 1, ttl0.length);
    } else {
        ttl2 = ttl0;
    }

    return (
        <th
            {...props}
            className={`header-column ${className || ''} ${orderBy === param ? 'active' : ''}`}
            title={`Clique para ordenar por ${title || children}`}
            onClick={() => handleClick(param || 'none')}
        >
            <div className="d-flex align-items-center">
                <span className="text-center">
                    {ttl1}
                    {ttl2}
                </span>
                <span className="no-space-before-icon">
                    {orderBy === param ? (
                        <FontAwesomeIcon icon={orderDir === 'desc' ? faLongArrowAltDown : faLongArrowAltUp} />
                    ) : (
                        <FontAwesomeIcon icon={faArrowsAltV} />
                    )}
                </span>
            </div>
        </th>
    );
}

export { Tr, Th };
