import React, { useContext } from 'react';
import CheckoutContext from '../SubscriptionContext';
import Purchase from './Purchase';

export default function SubscriptionRelatedPurchases({ className = '' }) {
    const { subscription } = useContext(CheckoutContext);

    if ((subscription?.purchases || []).length === 0) return null;
    return (
        <div className={`col-12 mt-1 ${className}`}>
            <h3 className="m-0">PEDIDOS ASSOCIADOS À ASSINATURA</h3>
            {subscription?.purchases.map((purchase, index) => (
                <Purchase key={index} data={purchase} />
            ))}
        </div>
    );
}
