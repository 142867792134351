import React, { useEffect, useState } from 'react';
import { URLs, useReplaceParams, CertificatesScheduleConfig } from '@app/constants';
import { useFetch } from '@app/hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

import ScheduleTable from './ScheduleTable';

const padTo2Digits = (num) => num.toString().padStart(2, '0');

function Schedule({ handleScheduleDatetimeClick = () => {} }) {
    const replaceParams = useReplaceParams;

    const config = CertificatesScheduleConfig;
    const [calendar, setCalendar] = useState({});

    // Definindo data de início e fim do calendário
    let today = new Date();
    today.setHours(config.startHour, config.startMinutes, 0, 0);
    const diffMillis = today.getDay() * 24 * 60 * 60 * 1000;

    const [datesInterval, setDatesInterval] = useState({
        fromDate: new Date(today.getTime() - diffMillis),
        toDate: new Date(today.getTime() - diffMillis + 6 * 24 * 60 * 60 * 1000),
    });
    const [params, setParams] = useState({
        from: `${padTo2Digits(datesInterval.fromDate.getDate())}${padTo2Digits(
            datesInterval.fromDate.getMonth() + 1
        )}${datesInterval.fromDate.getFullYear()}`,
        to: `${padTo2Digits(datesInterval.toDate.getDate())}${padTo2Digits(
            datesInterval.toDate.getMonth() + 1
        )}${datesInterval.toDate.getFullYear()}`,
        nocache: new Date().getTime(),
    });
    useEffect(() => {
        // Definindo parâmetros de busca
        setParams({
            from: `${padTo2Digits(datesInterval.fromDate.getDate())}${padTo2Digits(
                datesInterval.fromDate.getMonth() + 1
            )}${datesInterval.fromDate.getFullYear()}`,
            to: `${padTo2Digits(datesInterval.toDate.getDate())}${padTo2Digits(
                datesInterval.toDate.getMonth() + 1
            )}${datesInterval.toDate.getFullYear()}`,
            nocache: new Date().getTime(),
        });
    }, [datesInterval]);

    // Montando o vetor de horários do dia
    const endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), config.endHour, config.endMinutes, 0);
    const dayHours = [];
    let cicles = 0;
    while (endDateTime > today && cicles < 100) {
        dayHours.push({
            label: `${padTo2Digits(today.getHours())}:${padTo2Digits(today.getMinutes())}`,
            index: `${padTo2Digits(today.getHours())}${padTo2Digits(today.getMinutes())}`,
        });
        today = new Date(today.getTime() + config.stepSize * 60000);
        cicles += 1;
    }

    // Montando o vetor de dias da semana
    const weekDates = [];
    let date = new Date(datesInterval.fromDate.getTime());
    while (date <= datesInterval.toDate) {
        weekDates.push({
            title: `${padTo2Digits(date.getDate())}/${padTo2Digits(date.getMonth() + 1)}`,
            label: `${padTo2Digits(date.getDate())}/${padTo2Digits(date.getMonth() + 1)}/${date.getFullYear()}`,
            weekDay: date.getDay(),
            index: `${padTo2Digits(date.getDate())}${padTo2Digits(date.getMonth() + 1)}${date.getFullYear()}`,
            today: date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate(),
        });
        date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
    }

    // Navega para a semana anterior
    const handleNavigateLeft = () => {
        setDatesInterval({
            fromDate: new Date(datesInterval.fromDate.getTime() - 7 * 24 * 60 * 60 * 1000),
            toDate: new Date(datesInterval.toDate.getTime() - 7 * 24 * 60 * 60 * 1000),
        });
    };
    // Navega para a próxima semana
    const handleNavigateRight = () => {
        setDatesInterval({
            fromDate: new Date(datesInterval.fromDate.getTime() + 7 * 24 * 60 * 60 * 1000),
            toDate: new Date(datesInterval.toDate.getTime() + 7 * 24 * 60 * 60 * 1000),
        });
    };

    // Requisitando dados do calendário
    const { data, error } = useFetch(replaceParams(URLs.club.certificates.calendar.list, params));
    useEffect(() => {
        const cldr = {};
        if (data && data.map) {
            data.forEach((datetime) => {
                if (!(datetime?.date in cldr)) cldr[datetime?.date] = {};
                cldr[datetime?.date][datetime?.hour] = {
                    status: datetime?.status || 0,
                    certificate: datetime?.certificate || null,
                };
            });
        }
        setCalendar(cldr);
    }, [data, error]);

    // Funções de alteração dos dados do usuário
    return (
        <>
            <div className="col-12 d-flex align-items-center justify-content-between my-2">
                <Button
                    type="button"
                    className="btn btn-primary-outline btn-icon-left"
                    color="custom"
                    disabled={false}
                    onClick={handleNavigateLeft}
                >
                    <FontAwesomeIcon icon={faCaretLeft} className="" />
                </Button>
                <h4 className="text-center">
                    {`De ${datesInterval.fromDate.getDate()}/${
                        datesInterval.fromDate.getMonth() + 1
                    }/${datesInterval.fromDate.getFullYear()}`}
                    {` Até ${datesInterval.toDate.getDate()}/${datesInterval.toDate.getMonth() + 1}/${datesInterval.toDate.getFullYear()}`}
                </h4>
                <Button
                    type="button"
                    className="btn btn-primary-outline btn-icon-left"
                    color="custom"
                    disabled={false}
                    onClick={handleNavigateRight}
                >
                    <FontAwesomeIcon icon={faCaretRight} className="" />
                </Button>
            </div>
            <ScheduleTable
                weekDates={weekDates}
                dayHours={dayHours}
                calendar={calendar}
                handleScheduleDatetimeClick={handleScheduleDatetimeClick}
            />
        </>
    );
}

export default Schedule;
