import { Field } from 'formik';
import React from 'react';

function StringFieldDefaultSelector() {
    return (
        <>
            <label className="text-small pl-1">Valor padrão:</label>
            <Field type="text" name="default" />
        </>
    );
}
export default StringFieldDefaultSelector;
