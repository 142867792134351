import React, { useContext } from 'react';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import ProductsContext from '../ProductsContext';

function FormSliceSelectDocument({ isLoading }) {
    const { values, errors, setValues } = useFormikContext();
    const { setDocument } = useContext(ProductsContext);

    const handleProductDocumentChange = (target) => {
        if (target.files.length === 0) {
            setDocument(null);
            setValues((prev) => ({
                ...prev,
                document_name: '',
                document_format: '',
            }));
        } else {
            setDocument(target.files[0]);
            setValues((prev) => ({
                ...prev,
                document_name: target.files[0].name,
                document_format: target.files[0].type,
            }));
        }
    };

    return (
        <>
            <h3 className="field-group-title col-12 mt-3">Selecionar documento</h3>
            <div className="mb-1 px-1 col-12 d-flex flex-wrap">
                <label className="btn btn-secondary btn-icon-left">
                    <FontAwesomeIcon icon={faUpload} className="mx-2" />
                    Selecionar um documento do computador
                    <input
                        type="file"
                        name="document"
                        accept="image/png, image/gif, image/jpeg, file/pdf, application/pdf, application/vnd.ms-excel"
                        style={{ display: 'none' }}
                        onChange={(e) => handleProductDocumentChange(e.target, values, setValues)}
                        disabled={isLoading}
                    />
                </label>
                {(values?.document_name || '') !== '' && (
                    <div className="selected-document-name my-1" data-format={values?.document_format || ''}>
                        {values?.document_name}
                    </div>
                )}
                {errors?.document_name && <span className="col-12 my-1 text-error text-small">{errors?.document_name}</span>}
            </div>
        </>
    );
}

export default FormSliceSelectDocument;
