import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { ErrorMessage, Field, Formik } from 'formik';
import { Button, Form, Label } from 'reactstrap';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import * as yup from 'yup';
import { useUserManager } from '@app/hooks/useUserManager';

import { toast } from 'react-hot-toast';

import { NavigateBackButton } from '@app/components/ui';
import FormikDevTools from '@app/components/FormikDevTools';
import FormSliceAccesses from './components/slices/FormSliceAccesses';

function UserTypesForm(props) {
    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    // Lista de vídeos disponíveis no VIMEO
    const [isLoading, setIsLoading] = useState(false);
    const [userTypeId, setUserTypeId] = useState('');
    const [userTypeData, setUserTypeData] = useState({});

    useEffect(() => {
        if ((props?.match?.params?.id || '') === '') return;
        setUserTypeId(props?.match?.params?.id);
    }, [props?.match?.params?.id]);

    useEffect(() => {
        if (userTypeId === '') return;
        // Carrega os dados do produto
        const loadUserTypeData = (id) => {
            setIsLoading(true);
            const url = replaceParams(URLs.admin.user_types.show, { nocache: new Date().getTime(), id });
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setUserTypeData(response.data);
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do tipo de usuário');
                });
        };

        loadUserTypeData(userTypeId);
    }, [userTypeId, replaceParams, token]);

    // Carrega as labels das features de sessões
    const [featureLabels, setFeatureLabels] = useState(null);
    useEffect(() => {
        if (featureLabels !== null) return;
        const loadFeatureLabels = () => {
            const url = URLs.admin.sections.features;
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setFeatureLabels(response.data);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do tipo de usuário');
                });
        };
        loadFeatureLabels();
    }, [replaceParams, featureLabels, token]);

    // Faz o submit dos dados do usuário
    const handleUserTypeSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        const method = (values?.id || '') !== '' ? 'put' : 'post';
        const url = replaceParams(URLs.admin.user_types[method], { id: values.id ? values.id : '' });

        Api({ method, url, data: values })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    setUserTypeId(response.data.id);
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados do tipo de usuário!');
                setSubmitting(false);
            });
    };

    /**
     * Inicialização e validação do formulário
     */
    const validations = yup.object().shape({
        name: yup.string().min(3, 'O nome da mídia precisa ter ao menos 3 dígitos.').required('É necessário informar o nome do tipo.'),
    });

    const initialValues = {
        id: userTypeData?.id || '',
        name: userTypeData?.name || '',
        role: userTypeData?.role || 'admin',
        sections: userTypeData?.sections || [],
    };

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper className="users_form" hideTitle>
            <div className="d-flex px-3">
                <NavigateBackButton title="Retornar para a página de tipos de usuários" />
                <h1 className="content-title flex-shrink-1 col-12 text-center">
                    {(userTypeData?.id || '') === '' ? 'CADASTRAR NOVO TIPO DE USUÁRIO' : 'ALTERAR DADOS DO TIPO DE USUÁRIO'}
                </h1>
            </div>
            <div className="page-content">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validations}
                    onSubmit={handleUserTypeSubmit}
                    enableReinitialize
                    render={(formikProps) => (
                        <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                            <Field type="hidden" name="id" />
                            <Field type="hidden" name="stripe_id" />
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="name">
                                    Nome<em>*</em>
                                </Label>
                                <Field name="name" type="text" placeholder="Nome do tipo" className="col-12" disabled={isLoading} />
                                <ErrorMessage component="span" name="name" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-4">
                                <Label className="field-label" htmlFor="role">
                                    Papel<em>*</em>
                                </Label>
                                <Field
                                    name="role"
                                    as="select"
                                    placeholder="Papel do tipo de usuário"
                                    className="col-12"
                                    disabled={isLoading}
                                >
                                    <option value="admin">Administrador</option>
                                    <option value="supplier">Fornecedor</option>
                                </Field>
                                <ErrorMessage component="span" name="name" className="text-error text-small px-2" />
                            </div>

                            <h3 className="field-group-title col-12 mt-3">Acessos do tipo de usuário</h3>
                            <FormSliceAccesses isLoading={isLoading} featureLabels={featureLabels} />

                            <div className="col-12 mt-3 d-flex">
                                <div className="mb-3 px-3 col-6 text-center">
                                    <NavigateBackButton className="col-10" color="danger" disabled={formikProps.isSubmitting || isLoading}>
                                        CANCELAR
                                    </NavigateBackButton>
                                </div>
                                <div className="mb-3 col-6 text-center">
                                    <Button
                                        type="submit"
                                        className="col-10"
                                        color="success"
                                        disabled={formikProps.isSubmitting || isLoading}
                                    >
                                        SALVAR
                                    </Button>
                                </div>
                            </div>
                            <FormikDevTools />
                        </Form>
                    )}
                />
            </div>
        </ContentWrapper>
    );
}

export default UserTypesForm;
