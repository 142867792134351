import React, { useContext } from 'react';
import OrderContext from '../OrderContext';

export default function OrderStatusExpired() {
    const { purchase } = useContext(OrderContext);
    console.log(purchase);

    return (
        <div className="checkout-resume d-flex justify-content-center col-12">
            <div className="checkout-form col-12 col-sm-10 col-md-8 col-lg-6">
                <h3>ESTE PEDIDO EXPIROU</h3>
            </div>
        </div>
    );
}
