import React, { useState } from 'react';
import { Label } from 'reactstrap';
import { LabelTip } from '@app/components/ui';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function FormSliceTags({ isLoading }) {
    const { values, setFieldValue } = useFormikContext();

    const [tag, setTag] = useState('');
    const onSetNewTag = (e) => {
        if (e.keyCode !== 13) return;
        e.preventDefault();
        e.stopPropagation();

        const tagsArr = `${values?.tags || ''},${tag}`.split(',').filter((tag) => tag !== '');
        const tags = Array.from(new Set(tagsArr)).join(',');
        setFieldValue('tags', tags);
        setTag('');
    };

    const onRemoveTag = (target) => {
        const tagsArr = (values?.tags || '').split(',').filter((tag) => tag !== '' && tag !== target);
        const tags = Array.from(new Set(tagsArr)).join(',');
        setFieldValue('tags', tags);
    };

    return (
        <div className="mb-1 col-12 px-1">
            <div className="col-12 col-sm-6 col-md-4 d-flex flex-wrap p-1">
                <Label className="field-label" htmlFor="tags">
                    Tags de busca
                    <LabelTip className="mx-1">
                        Lista de tags utilizadas na busca por produtos.
                        <br />
                        Informe a tag e pressione ENTER para incluí-la
                    </LabelTip>
                </Label>
                <input
                    type="text"
                    name="find-tag"
                    maxLength={20}
                    placeholder="Digite uma tag e pressione enter"
                    className="col-12"
                    disabled={isLoading}
                    value={tag}
                    onChange={(e) => setTag(e.target.value.replace(/[^a-zA-Z0-9,]/g, ''))}
                    onKeyDownCapture={onSetNewTag}
                />
            </div>
            <div className="col-12 d-flex flex-wrap p-1">
                {(values?.tags || '')
                    .split(',')
                    .filter((tag) => tag !== '')
                    .map((tag, index) => (
                        <label key={index} className="badge m-1">
                            <span className="mr-1">{tag}</span>
                            <FontAwesomeIcon icon={faTimes} className="ml-1" title="Remover tag" onClick={() => onRemoveTag(tag)} />
                        </label>
                    ))}
            </div>
        </div>
    );
}

export default FormSliceTags;
