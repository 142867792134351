import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';
import moment from 'moment';
import { URLs, useSetParams } from '@app/constants';
import Api from '@app/services/Api';
import TrafficContext from '../TrafficContext';

export default function UsersByUrl({ className = '' }) {
    const { selectedUrl, startDate, endDate } = useContext(TrafficContext);
    const setParams = useSetParams;
    const [data, setData] = useState(null);
    const [days] = useState(7);

    const [queryParams, setQueryParams] = useState({
        url: selectedUrl,
        start: moment(startDate).format('yyyy/MM/DD'),
        end: moment(endDate).format('yyyy/MM/DD'),
        orderBy: 'qtd',
        orderDir: 'desc',
    });

    const setOrderBy = useCallback(
        (orderBy, orderDir) => {
            setQueryParams((prev) => ({ ...prev, orderBy, orderDir }));
        },
        [setQueryParams]
    );

    useEffect(() => {
        setQueryParams((prev) => ({
            ...prev,
            url: selectedUrl,
            start: moment(startDate).format('yyyy/MM/DD'),
            end: moment(endDate).format('yyyy/MM/DD'),
        }));
    }, [startDate, endDate, selectedUrl]);

    useEffect(() => {
        // if (data !== null) return;
        // const start = moment().subtract(days, 'days').format('yyyy/mm/dd');
        // const end = moment().format('yyyy/mm/dd');
        const url = setParams(URLs.admin.traffic.users, queryParams);
        Api({ method: 'get', url }).then(({ data }) => {
            setData(data?.data);
        });
    }, [setParams, days, queryParams]);

    return (
        <div className={className}>
            <h3 className="text-center">Usuários que acessaram no período</h3>
            <Table striped bordered hover col="12" className="text-medium table-responsive">
                <thead>
                    <Tr handleOrderBy={setOrderBy}>
                        <Th param="url">Usuário</Th>
                        <Th param="qtd" className="text-center">
                            Acessos
                        </Th>
                    </Tr>
                </thead>
                <tbody>
                    {(data || []).map((record, index) => (
                        <tr key={index}>
                            <td>{record?.user?.name || ''}</td>
                            <td align="center">{record?.qtd || ''}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
