import React from 'react';

function TipMessage({ children = null, className = '' }) {
    if (!children) return null;

    return (
        <div className={`mb-3 text-dark-red text-center d-flex justify-content-center ${className || ''}`}>
            <p className="mb-0 tip-message">{children}</p>
        </div>
    );
}
export default TipMessage;
