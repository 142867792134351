import React, { useContext, useMemo } from 'react';
import parse from 'html-react-parser';
import { NavLink, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import ViewingProgress from './components/ViewingProgress';
import ProductContext from './ProductContext';

function ProductHeader({ className = '', children, ...props }) {
    const { product, blockControls, onExpandContentClick, expanded } = useContext(ProductContext);
    const href = useMemo(() => {
        if (blockControls) return '#';
        return product?.custom_details_url || `/produto/${product?.token || ''}`;
    }, [blockControls, product]);

    return (
        <div className={`product-header ${className}`} {...props}>
            <div className="product-thumbnail">
                <img src={product?.thumb?.url} alt="" />
            </div>
            <h3 className="product-title">
                <NavLink
                    tag={Link}
                    to={{
                        pathname: href,
                        section: { name: '' },
                    }}
                    className="text-description"
                    title="Ver mais informações"
                >
                    {parse(product?.name || '')}
                </NavLink>
                <ViewingProgress progress={product?.progress?.progress || 0} />
                <Button
                    type="button"
                    title="Exibir conteúdo"
                    className="product-expand-button"
                    color="custom"
                    onClick={onExpandContentClick}
                >
                    <FontAwesomeIcon icon={expanded ? faCaretUp : faCaretDown} className="mx-2" />
                </Button>
            </h3>
            {children}
        </div>
    );
}
export default ProductHeader;
