import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessage, Formik, Form } from 'formik';

import { useUserManager } from '@app/hooks/useUserManager';

import { Container, Button, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import Yup from '@app/validators';
import FormikDevTools from '@app/components/FormikDevTools';

import { AuthFooter, AuthHeader, SideContent, TipMessage } from '../components';

import './ForgotPassword.scss';

export default function ConfirmationCode(props) {
    const UserManager = useUserManager();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [channel, setChannel] = useState('');
    useEffect(() => {
        setChannel(props?.history?.location?.state?.channel || '');
    }, [props]);

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        // setSubmitting(true);
        UserManager.validateVerificationCode(values.code).then((response) => {
            const { status, message, url } = response;
            if (status === 200) {
                window.location.href = url;
            } else {
                setSubmitting(false);
                setErrorMessage(message);
                setSuccessMessage('');
            }
        });
    };

    const validations = Yup.object().shape({
        code: Yup.string().length(6, 'Formato de código inválido'),
    });

    const [contentMessage, footerMessage] = useMemo(() => {
        switch (channel) {
            case 'whatsapp':
                return [
                    <p className="my-2 text-medium">
                        Um código de confirmação foi enviado para seu Whatsapp. Insira o código abaixo e clique em &quot;ENVIAR&quot; para
                        prosseguir com a redefinição de sua senha.
                    </p>,
                    'Verifique a conta de Whatsapp informada no momento do cadastro',
                ];
            case 'sms':
                return [
                    <p className="my-2 text-medium">
                        Um código de confirmação foi enviado por SMS para seu Telefone. Insira o código abaixo e clique em
                        &quot;ENVIAR&quot; para prosseguir com a redefinição de sua senha.
                    </p>,
                    'Verifique o telefone informado no momento do cadastro',
                ];
            default:
                return [
                    <p className="my-2 text-medium">
                        Um link foi enviado para seu endereço de e-mail. Acesse sua caixa de entrada para prosseguir com a redefinição de
                        sua senha.
                    </p>,
                    'Você pode ter que checar sua caixa de spam caso não encontre o e-mail enviado.',
                ];
        }
    }, [channel]);

    const initialValues = {
        code: '',
    };

    return (
        <div className="auth-screen">
            <Container className="col-10" style={{ maxWidth: '1200px' }}>
                <div className="forgot-password col-12 justify-content-center">
                    <AuthHeader />
                    <div className="auth-container col-12">
                        <div className="auth-form-container col-12 col-sm-5">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={validations}
                                render={(formikProps) => (
                                    <Form className="login">
                                        <div className="justify-content-center mb-3 col-12">
                                            <div className="col-12">
                                                <h2 className="text-center">Recuperação de senha</h2>
                                            </div>
                                        </div>
                                        {contentMessage}
                                        {['whatsapp', 'sms'].includes(channel) && (
                                            <div className="col-12 d-flex flex-column mb-3">
                                                <Label>Código de confirmação:</Label>
                                                <InputMask
                                                    name="code"
                                                    type="text"
                                                    className="confirmation-code-input col-12 text-center"
                                                    mask="******"
                                                    placeholder="______"
                                                    value={formikProps.values.code}
                                                    onChange={({ target: { value } }) => {
                                                        formikProps.setFieldTouched('code');
                                                        formikProps.setValues({
                                                            ...formikProps.values,
                                                            ...{ code: value.replaceAll('_', '') },
                                                        });
                                                    }}
                                                />
                                                <ErrorMessage component="span" name="code" className="text-error text-small" />
                                            </div>
                                        )}
                                        {errorMessage && <p className="mb-3 message-error text-small">{errorMessage}</p>}
                                        {successMessage && <p className="mb-3 message-success text-small">{successMessage}</p>}
                                        <div className="col-12 d-flex mb-3">
                                            {['whatsapp', 'sms'].includes(channel) && (
                                                <div className="d-flex col-6 justify-content-center px-1">
                                                    <Button
                                                        type="submit"
                                                        className="col-12"
                                                        color="primary"
                                                        disabled={formikProps.isSubmitting}
                                                    >
                                                        ENVIAR
                                                    </Button>
                                                </div>
                                            )}
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Link to="/login" className="btn btn-primary-outline col-12">
                                                    EFETUAR LOGIN
                                                </Link>
                                            </div>
                                        </div>
                                        <TipMessage>
                                            <em>***</em> {footerMessage}
                                        </TipMessage>
                                        <FormikDevTools />
                                    </Form>
                                )}
                            />
                        </div>
                        <SideContent className="col-12 col-sm-7" />
                    </div>
                </div>
            </Container>
            <AuthFooter />
        </div>
    );
}
