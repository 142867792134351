import * as yup from 'yup';

/**
 * Valida CPF
 */
const validaCPF = (cpf) => {
    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; i < cpf.length; i += 1) if (cpf[i - 1] !== cpf[i]) aux = true;
    if (aux === false) return false;

    for (let i = 0, p = 10; i < cpf.length - 2; i += 1, p -= 1) v1 += cpf[i] * p;
    v1 = (v1 * 10) % 11;
    if (v1 === 10) v1 = 0;
    if (v1 !== cpf[9]) return false;

    for (let i = 0, p = 11; i < cpf.length - 1; i += 1, p -= 1) v2 += cpf[i] * p;
    v2 = (v2 * 10) % 11;
    if (v2 === 10) v2 = 0;

    if (v2 !== cpf[10]) {
        return false;
    }
    return true;
};

/**
 * Valilda CNPJ
 */
const validaCNPJ = (cnpj) => {
    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; i < cnpj.length; i += 1) if (cnpj[i - 1] !== cnpj[i]) aux = true;
    if (aux === false) return false;

    for (let i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i += 1, p1 -= 1, p2 -= 1)
        if (p1 >= 2) {
            v1 += cnpj[i] * p1;
        } else {
            v1 += cnpj[i] * p2;
        }
    v1 %= 11;
    if (v1 < 2) {
        v1 = 0;
    } else {
        v1 = 11 - v1;
    }
    if (v1 !== cnpj[12]) return false;

    for (let i = 0, p1 = 6, p2 = 14; i < cnpj.length - 1; i += 1, p1 -= 1, p2 -= 1)
        if (p1 >= 2) {
            v2 += cnpj[i] * p1;
        } else {
            v2 += cnpj[i] * p2;
        }
    v2 %= 11;
    if (v2 < 2) {
        v2 = 0;
    } else {
        v2 = 11 - v2;
    }

    if (v2 !== cnpj[13]) {
        return false;
    }
    return true;
};

const getSplitedValue = (val) => {
    let newVal = val;
    newVal = String(newVal || '');
    newVal = newVal.trim();
    newVal = newVal.replace(/\./g, '');
    newVal = newVal.replace('-', '');
    newVal = newVal.replace('/', '');
    newVal = newVal.split('').map((x) => parseInt(x));
    return newVal;
};

const useCPFCNPJValidator = (val) => {
    let newVal = val;
    newVal = getSplitedValue(newVal);
    // Validando CPF
    if (newVal.length === 11) {
        return validaCPF(newVal);
    }
    if (newVal.length === 14) {
        return validaCNPJ(newVal);
    }
    return false;
};

const useCPFValidator = (val) => {
    let newVal = val;
    newVal = getSplitedValue(newVal);

    // Validando CPF
    if (newVal.length !== 11) return false;
    return validaCPF(newVal);
};

const useCNPJValidator = (val) => {
    let newVal = val;
    newVal = getSplitedValue(newVal);

    // Validando CNPJ
    if (newVal.length !== 14) return false;
    return validaCNPJ(newVal);
};

const DocumentValidator = yup
    .string()
    .min(14, 'Formato de documento inválido')
    // .required('É necessário informar o documento.')
    .test('test-document', function (value) {
        if (value === '' || !value) return true;
        const { path, createError } = this;
        const isValid = useCPFCNPJValidator(value);
        return isValid ? true : createError({ path, message: 'Documento inválido.' });
    });

const CPFValidator = yup
    .string()
    // .required('É necessário informar o CPF.')
    .min(11, 'Formato de CPF inválido')
    .test('test-cpf', function (value) {
        if (value === '' || !value) return true;
        const { path, createError } = this;
        const isValid = useCPFValidator(value);
        return isValid ? true : createError({ path, message: 'CPF inválido' });
    });

const CNPJValidator = yup
    .string()
    .min(14, 'Formato de CNPJ inválido')
    // .required('É necessário informar o CNPJ.')
    .test('test-cnpj', function (value) {
        if (value === '' || !value) return true;
        const { path, createError } = this;
        const isValid = useCNPJValidator(value);
        return isValid ? true : createError({ path, message: 'CNPJ inválido.' });
    });

export { DocumentValidator, CPFValidator, CNPJValidator, useCPFValidator, useCNPJValidator, useCPFCNPJValidator };
