import React from 'react';
import { Field } from 'formik';
import { StateAcronymsToName } from '@app/constants';

function StatesFieldDefaultSelector() {
    return (
        <>
            <label className="text-small pl-1">Valor padrão:</label>
            <Field as="select" type="text" name="default" className="col-12">
                <option key="" value="">
                    -- nenhum valor padrão --
                </option>
                {Object.keys(StateAcronymsToName).map((acronym) => (
                    <option key={acronym} value={acronym}>
                        {StateAcronymsToName[acronym]}
                    </option>
                ))}
            </Field>
        </>
    );
}

export default StatesFieldDefaultSelector;
