import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

const weekDays = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];

function ScheduleTable(props) {
    const weekDates = props?.weekDates || [];
    const dayHours = props?.dayHours || [];
    const [calendar, setCalendar] = useState(props?.calendar || {});
    useEffect(() => {
        setCalendar(props?.calendar || {});
    }, [props]);

    return (
        <table className="col-12 table table-bordered text-medium">
            <thead className="thead-light">
                <tr>
                    <th>&nbsp;</th>
                    {weekDates.map((day, index) => (
                        <th key={index} className={`text-center ${day.today ? 'text-primary' : ''}`}>
                            {day.title}
                            <br />
                            {weekDays[day.weekDay]}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {dayHours.map((hour, index) => (
                    <tr key={index}>
                        <th className="text-center">{hour.label}</th>
                        {weekDates.map((day, index) => {
                            const title = `${day.label} às ${hour.label}, clique para agendar nesta esta e hora.`;
                            const status = calendar?.[day.index]?.[hour.index]?.status || 0;
                            return (
                                <td key={index} width="12.5%" className="text-center" title={`${title}`}>
                                    {status === 1 && (
                                        <Button
                                            type="button"
                                            className="btn btn-primary btn-icon-left"
                                            color="custom"
                                            disabled={false}
                                            onClick={() => props?.handleScheduleDatetimeClick({ date: day.label, hour: hour.label })}
                                        >
                                            <FontAwesomeIcon icon={faCalendarCheck} className="mx-2" />
                                        </Button>
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
export default ScheduleTable;
