import React, { useCallback, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { faCcVisa, faCcMastercard, faCcAmex, faCcJcb, faCcDinersClub, faCcDiscover } from '@fortawesome/free-brands-svg-icons';

const card_brand_icons = {
    elo: faCreditCard,
    mastercard: faCcMastercard,
    visa: faCcVisa,
    amex: faCcAmex,
    jcb: faCcJcb,
    aura: faCreditCard,
    hipercard: faCreditCard,
    diners: faCcDinersClub,
    discover: faCcDiscover,
};

function UserCardsList({ cards = [], selectedCardId = '', onChooseCard = () => {}, disabled = false }) {
    const getCardBrandIcon = useCallback((brand) => card_brand_icons?.[brand] || faCreditCard, []);

    const content = useMemo(() => {
        if (cards === null) return <label className="text-center">carregando meios de pagamento</label>;
        if ((cards || []).length === 0) <label className="text-center">nenhum meio de pagamento cadastrado</label>;
        return cards.map((card, index) => (
            <label htmlFor={`user-card-${card.id}`} className="user-card" key={index}>
                <input
                    type="radio"
                    id={`user-card-${card.id}`}
                    name="user_card_id"
                    value={String(card.id)}
                    checked={card.id === selectedCardId}
                    disabled={disabled}
                    onChange={(e) => onChooseCard(e.target.value)}
                />
                <FontAwesomeIcon icon={getCardBrandIcon(card.brand)} />
                <span>{card.description}</span>
            </label>
        ));
    }, [cards, getCardBrandIcon, disabled, onChooseCard, selectedCardId]);

    return <div className="payment-method-cards-list d-flex flex-column">{content}</div>;
}

export default UserCardsList;
