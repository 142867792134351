import React, { useCallback, useContext, useRef, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Api from '@app/services/Api';
import { URLs, PopupStyle, useSetParams } from '@app/constants';

import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-hot-toast';
import FormEditorContext from '../../FormEditorContext';

// import FormEditorContext from '../../FormEditorContext';

export default function PartnerLogoPopup({ isOpen, filter = {}, ...props }) {
    const { onClosePartnerLogoPopup, onApplyPartnerLogoChange } = useContext(FormEditorContext);
    const setParams = useSetParams;

    const imageContainerRef = useRef(null);
    const [isLoadingDocument, setIsLoadingDocument] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const downloadAndShowFile = useCallback(
        (filename) => {
            const url = setParams(URLs.admin.sections.partner_logo, { filename });
            setIsLoadingDocument(true);
            Api({
                method: 'get',
                url,
                responseType: 'blob',
            })
                .then((blob) => {
                    const $container = imageContainerRef.current;
                    $container.innerHtml = '';
                    const image = document.createElement('img');
                    const reader = new FileReader();
                    reader.addEventListener('loadend', () => {
                        const contents = reader.result;
                        image.src = contents;
                        image.style.maxWidth = '100%';
                        image.style.height = 'fit-content';
                        $container.appendChild(image);
                    });
                    reader.readAsDataURL(new Blob([blob?.data]));

                    setIsLoadingDocument(false);
                })
                .catch(() => {
                    toast.error('Não foi possível fazer o download do documento!');
                    setIsLoadingDocument(false);
                });
        },
        [setParams]
    );

    const [selectedFile, setSelectedFile] = useState(null);
    const onFileUploadChanged = (el) => {
        const selectedFileName = el.files[0].name.replaceAll(' ', '_');
        setSelectedFile({
            file: el.files[0],
            name: selectedFileName,
        });
        // Exibindo o preview do arquivo
        const container = imageContainerRef.current;
        container.innerHTML = '';
        const image = document.createElement('img');
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
            const contents = reader.result;
            image.src = contents;
            image.style.maxWidth = '100%';
            image.style.height = 'fit-content';
            container.appendChild(image);
        });
        reader.readAsDataURL(el.files[0]);
    };

    const onApplyChangesClick = () => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append('logo', selectedFile.file);

        const url = setParams(URLs.admin.sections.partner_logo, { filename: selectedFile.name });
        Api({
            method: 'post',
            url,
            data: formData,
        })
            .then((response) => {
                if (response.data && response.status === 200) {
                    onApplyPartnerLogoChange({ ...filter, partner_logo: selectedFile.name });
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar enviar o arquivo!');
                setSubmitting(false);
            });
    };

    const afterOpenModal = () => {
        setSelectedFile(null);
        if (filter?.partner_logo === '') return;
        downloadAndShowFile(filter?.partner_logo);
    };

    const afterCloseModal = () => {
        setSelectedFile(null);
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onAfterClose={afterCloseModal}
            onRequestClose={onClosePartnerLogoPopup}
            style={PopupStyle}
            contentLabel={props?.title}
            className=""
            ariaHideApp={false}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">{props?.title}</h2>
                    <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={onClosePartnerLogoPopup}>
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    </Button>
                </Col>
            </Row>
            <div
                className="col-12 my-2 p-2 border d-flex justify-content-center"
                ref={imageContainerRef}
                style={{ minHeight: 300, maxHeight: 400, overflowY: 'auto' }}
            >
                {isLoadingDocument === true && <LoadingSpinner />}
                <pre>{JSON.stringify(filter, null, 2)}</pre>
            </div>
            <div className="col-12 d-flex align-items-center">
                <label
                    className="btn btn-primary col-4"
                    htmlFor="partner-logo-input"
                    title="Fazer upload do arquivo"
                    style={{ opacity: isSubmitting ? 0.3 : 1 }}
                >
                    <FontAwesomeIcon icon={faFileUpload} className="mx-2" />
                    Selecionar arquivo
                    <input
                        className="d-none"
                        name="partner-logo-input"
                        id="partner-logo-input"
                        type="file"
                        onChange={(e) => onFileUploadChanged(e.target)}
                        disabled={isSubmitting}
                    />
                </label>
                <label className="col-8 px-2 text-small">{selectedFile?.name || ''}</label>
            </div>
            <div className="col-12 mt-3 d-flex">
                <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                    <button
                        type="submit"
                        className="btn btn-success col-12"
                        onClick={onApplyChangesClick}
                        disabled={selectedFile === null || isSubmitting}
                    >
                        SALVAR
                    </button>
                </div>
            </div>
        </Modal>
    );
}
