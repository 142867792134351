import React from 'react';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'reactstrap';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';

function UserTypesTable(props) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <Tr key="title" handleOrderBy={props?.handleOrderBy || null}>
                    <Th param="name">Perfil</Th>
                    <th>Permissões</th>
                    <th colSpan="1" className="text-center">
                        Ações
                    </th>
                </Tr>
            </thead>
            <tbody>
                {props?.data?.length > 0 ? (
                    props?.data?.map((record, id) => {
                        const access_list = [];
                        Object.keys(record?.access).forEach((key) => {
                            if (record?.access[key]?.enabled) access_list.push(record?.access[key]?.label);
                        });

                        const permissions_list = [];
                        Object.keys(record?.permissions).forEach((key) => {
                            if (record?.permissions[key].enabled) permissions_list.push(record?.permissions[key].label);
                        });
                        return (
                            <tr key={id}>
                                <td>{record?.name || ''}</td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    <strong>VISUALIZAR:</strong> {access_list.length > 0 ? access_list.join(', ') : 'NÃO'}
                                    <br />
                                    <strong>ESCREVER:</strong> {permissions_list.length > 0 ? permissions_list.join(', ') : 'NÃO'}
                                    <br />
                                    <strong>EXPORTAR:</strong> {record?.export ? 'SIM' : 'NÃO'}
                                </td>
                                <td align="center">
                                    {!props?.permissions?.user_profiles ? (
                                        ''
                                    ) : (
                                        <button
                                            type="button"
                                            name="edit-user"
                                            className="btn btn-primary btn-icon-small"
                                            title="Editar perfil de usuário"
                                            onClick={() => {
                                                props?.handleUpdateRecord(record);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faUserEdit} className="mr-2" />
                                        </button>
                                    )}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr key={0}>
                        <td colSpan={3}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default UserTypesTable;
