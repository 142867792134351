import { Field } from 'formik';
import React from 'react';
import { CurrencyInputField } from '@app/components/ui';

function FloatFieldDefaultSelector() {
    return (
        <>
            <label className="text-small pl-1">Valor padrão:</label>
            <Field name="default" component={CurrencyInputField} className="col-12 text-right" />
        </>
    );
}
export default FloatFieldDefaultSelector;
