import React, { useContext } from 'react';
import { Table } from 'reactstrap';
import CheckPermission from '@app/components/CheckPermission';
import { Tr } from '@app/components/OrderedTable/OrderedTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { CustomSwitch } from '@app/components/ui';
import PlansTable from './PlansTable';
import SubscriptionPlansContext from '../components/SubscriptionPlansContext';

import './PlansGroupsTable.scss';

function PlansGroupsTable({ data = {}, handleOrderBy = () => {} }) {
    const context = useContext(SubscriptionPlansContext);
    const { handlePlanGroupChange, handlePlanGroupDelete, handlePlanGroupStateChange } = context;

    return (
        <Table striped hover col="12" className="text-small table-responsive plans-groups-table">
            <thead>
                <Tr handleOrderBy={handleOrderBy}>
                    <th className="plans-group-name">{data?.name}</th>
                    <th className="text-center">
                        Ativo
                        <CheckPermission
                            permission="change-group-status"
                            allowed={
                                <CustomSwitch
                                    name={`product-status-${data?.id}`}
                                    className="justify-content-center"
                                    onChange={handlePlanGroupStateChange}
                                    checked={data?.status === 1 || false}
                                    isAsync
                                    data={{ token: data?.token, status: data?.status }}
                                />
                            }
                            notAllowed={
                                <label className={data.status === 1 ? 'text-success' : 'text-error'}>
                                    {data.status === 1 ? 'ATIVO' : 'INATIVO'}
                                </label>
                            }
                        />
                    </th>
                    <CheckPermission permission="edit-group">
                        <td className="text-center ">
                            <button
                                type="button"
                                name="edit-group"
                                className="btn btn-primary-outline"
                                title="Editar o grupo"
                                onClick={() => handlePlanGroupChange(data)}
                            >
                                <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                            </button>
                        </td>
                    </CheckPermission>
                    <CheckPermission permission="delete-group">
                        <td className="text-center ">
                            <button
                                type="button"
                                name="delete-group"
                                className="btn btn-primary-outline"
                                title="Excluir o grupo"
                                onClick={() => handlePlanGroupDelete(data.token, data.name)}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                            </button>
                        </td>
                    </CheckPermission>
                    <CheckPermission permission="create">
                        <td className="text-center ">
                            <a
                                name="create-plans"
                                className="btn btn-primary text-nowrap text-medium py-2"
                                title="Cadastrar novo plano neste grupo"
                                href={`/admin/planos/${data?.token || ''}/novo-plano`}
                            >
                                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                                <span>Novo Plano</span>
                            </a>
                        </td>
                    </CheckPermission>
                </Tr>
            </thead>
            <tbody>
                <td colSpan="5">
                    <PlansTable data={data?.plans || []} handleOrderBy={handleOrderBy} />
                </td>
            </tbody>
        </Table>
    );
}

export default PlansGroupsTable;
