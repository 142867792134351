import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { URLs, useSetParams } from '@app/constants';
import Api from '@app/services/Api';
import ReactApexChart from 'react-apexcharts';
import TrafficContext from '../TrafficContext';

export default function TrafficByUrl({ className = '', height = 300 }) {
    const { selectedUrl, startDate, endDate } = useContext(TrafficContext);
    const setParams = useSetParams;
    const [data, setData] = useState(null);
    const [days] = useState(7);

    const [queryParams, setQueryParams] = useState({
        url: selectedUrl,
        start: moment(startDate).format('yyyy/MM/DD'),
        end: moment(endDate).format('yyyy/MM/DD'),
        orderBy: 'qtd',
        orderDir: 'desc',
    });

    useEffect(() => {
        setQueryParams((prev) => ({
            ...prev,
            url: selectedUrl,
            start: moment(startDate).format('yyyy/MM/DD'),
            end: moment(endDate).format('yyyy/MM/DD'),
        }));
    }, [startDate, endDate, selectedUrl]);

    useEffect(() => {
        const url = setParams(URLs.admin.traffic.totals, queryParams);
        Api({ method: 'get', url }).then(({ data }) => {
            setData(data);
        });
    }, [setParams, days, queryParams]);

    const keys = useMemo(() => {
        if (!data) return [];
        return data.map((item) => item.date);
    }, [data]);

    const series = useMemo(() => {
        if (!data) return [];
        return [
            {
                data: data.map((item) => ({
                    x: item.date,
                    y: item.qtd,
                })),
            },
        ];
    }, [data]);

    const colors = ['#FFC107', '#4682B4', '#FFFF45', '#B22222', '#cacaca'];
    const idealColumnWidth = `${20 + 60 / (1 + 30 * Math.exp(-(data?.length || 0) / 3))}%`;
    const options = {
        colors,
        chart: {
            type: 'bar',
            height: 350,
            stackType: '100%',
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: true,
            formatter(val) {
                return parseInt(val);
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
        grid: {
            borderColor: '#d0b08d',
        },
        responsive: [
            {
                breakpoint: 400,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
            },
        ],
        xaxis: {
            categories: keys,
        },
        yaxis: {
            labels: {
                formatter(val) {
                    return `${parseInt(val)} acessos`;
                },
            },
        },
        tooltip: {
            y: {
                formatter(val) {
                    return `${parseInt(val)} acessos`;
                },
                title: {
                    formatter: () => '',
                },
            },
        },
        /*
        fill: {
            opacity: 1
        },
        */
        legend: {
            show: true,
            position: 'bottom',
        },
        plotOptions: {
            columnWidth: '10%',
            bar: {
                columnWidth: idealColumnWidth,
                dataLabels: {
                    position: 'top',
                },
            },
        },
    };

    return (
        <div className={className}>
            <h3 className="text-center">Acessos por dia</h3>
            <ReactApexChart options={options} series={series} type="bar" height={height} width="100%" />
        </div>
    );
}
