import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import { Button } from 'reactstrap';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import { URLs, useReplaceParams } from '@app/constants';
import CustomerAddress from '@app/components/CustomerAddress';

import OrderContext from '../OrderContext';

import './OrderSummary.scss';

export default function OrderSummary({ className = '', style = {}, onApplyCoupom = () => {} }) {
    const replaceParams = useReplaceParams;
    const { purchase, isLoading, updatePurchaseData } = useContext(OrderContext);

    const changeAddressUrl = useMemo(() => {
        if ((purchase?.token || null) === null) return null;
        return replaceParams(URLs.club.purchases.change_address, { token: purchase.token });
    }, [purchase, replaceParams]);

    const [freight, setFreight] = useState(0);
    const [amount, setAmount] = useState(0);
    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    useEffect(() => {
        const amountValue = purchase?.amount || 0;
        const discountValue = purchase?.discount || 0;
        const freightValue = purchase?.freight || 0;

        const val = amountValue - (discountValue + freightValue);
        setTotal(val);
        setAmount(amountValue);
        setDiscount(discountValue);
        setFreight(freightValue);
    }, [purchase]);

    const coupomRef = useRef(null);

    return (
        <div className={`d-flex col-12 flex-wrap payment-summary ${className || ''}`} style={style}>
            <div className="payment-values col-12 col-lg-4 p-3 d-flex flex-column">
                <div className="col-12 d-flex flex-row">
                    <label className="text-medium col-8">Itens ({purchase?.items?.length || 0}):</label>
                    <label className="text-medium col-4 text-right">
                        R$ {amount.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    </label>
                </div>
                <div className="col-12 d-flex flex-row">
                    <label className="text-medium col-8">Frete e manuseio:</label>
                    <label className="text-medium col-4 text-right">
                        R$ {freight?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    </label>
                </div>
                <div className="col-12 d-flex flex-row">
                    <label className="text-medium col-8">Desconto:</label>
                    <label className="text-medium col-4 text-right">
                        R$ {discount?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    </label>
                </div>
                <div className="border-top py-1 col-12 d-flex flex-row">
                    <label className="text-medium col-8">Total:</label>
                    <label className="text-medium col-4 text-right">
                        <strong>R$ {total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</strong>
                    </label>
                </div>
                {((purchase?.status === 'created' && !isLoading) || false) && (
                    <div className="border-top col-12 d-flex align-items-center py-1">
                        <label className="text-medium">Aplicar cupom:</label>
                        <input type="text" ref={coupomRef} className="mx-2 col-12 flex-shrink-1" maxLength="30" />
                        <Button
                            name="apply-coupom"
                            color="custom"
                            className="btn btn-secondary"
                            onClick={() => onApplyCoupom(coupomRef.current.value)}
                        >
                            APLICAR
                        </Button>
                    </div>
                )}
                {isLoading && <LoadingSpinner />}
            </div>
            <div className="payment-shipping col-12 col-lg-8 p-3 d-flex flex-wrap">
                <CustomerAddress
                    url={changeAddressUrl}
                    title="Endereço de entrega"
                    address_field="shipping_address"
                    address={purchase?.shipping_address || {}}
                    required={purchase?.has_shipping || false}
                    canChange={purchase?.status === 'created' && !isLoading}
                    autoUpdateProfile={false}
                    onAfterChange={() => updatePurchaseData()}
                />
                <CustomerAddress
                    url={changeAddressUrl}
                    title="Endereço de cobrança"
                    address_field="billing_address"
                    address={purchase?.billing_address || {}}
                    required
                    canChange={purchase?.status === 'created' && !isLoading}
                    autoUpdateProfile={false}
                    onAfterChange={() => updatePurchaseData()}
                />
            </div>
        </div>
    );
}
