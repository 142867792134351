import React from 'react';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function OrderListItem({ item = {} }) {
    const billingAddress = item?.purchase?.billing_address || null;
    const shippingAddress = item?.purchase?.shipping_address || null;

    return (
        <div key={item.id} className="order-item card col-12 mb-3">
            <div className="card-header order-header d-flex flex-wrap">
                <div className="d-flex col-12 col-md-4 p-2 ">
                    <div className="d-flex flex-column col-6 px-2">
                        <label className="text-medium fw-bold d-flex flex-column">
                            <label>{item?.purchase?.status_label.toUpperCase()}</label>
                            <label className="text-small">{item?.purchase?.purchase_date}</label>
                            <a href={`pedido/${item?.purchase?.token}`} className="text-small mt-2">
                                ver pedido
                            </a>
                        </label>
                        {item?.purchase?.status === 2 && item?.purchase?.boleto_url && (
                            <a href={item?.purchase?.boleto_url} className="btn text-small" target="_blank" rel="noreferrer">
                                Clique aqui para acessar seu boleto
                            </a>
                        )}
                    </div>
                    <div className="d-flex flex-column justify-content-start flex-wrap col-6 align-items-center">
                        <label className="text-medium fw-bold">TOTAL</label>
                        <label className="text-small">R$ {item?.amount.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</label>
                    </div>
                </div>
                {billingAddress && (
                    <div className="order-address-details d-flex flex-column p-2 col-12 col-sm-6 col-md-4">
                        <label className="col-12 text-medium fw-bold">ENDEREÇO DE COBRANÇA</label>
                        <span className="text-small">
                            {billingAddress?.address} {billingAddress?.number}
                        </span>
                        <span className="text-small">{billingAddress?.neighborhood}</span>
                        <span className="text-small">
                            {billingAddress?.city?.name}/{billingAddress?.city?.state}
                        </span>
                    </div>
                )}
                {shippingAddress && (
                    <div className="order-address-details d-flex flex-column p-2 col-12 col-sm-6 col-md-4">
                        <label className="col-12 text-medium fw-bold">ENDEREÇO DE ENTREGA</label>
                        <span className="text-small">
                            {shippingAddress?.address}, {shippingAddress?.number}
                        </span>
                        <span className="text-small">{shippingAddress?.neighborhood}</span>
                        <span className="text-small">
                            {shippingAddress?.city?.name}/{shippingAddress?.city?.state}
                        </span>
                    </div>
                )}
            </div>
            <div className="card-body d-flex flex-wrap justify-content-start">
                <div
                    className="order-item-thumb d-flex justify-content-center align-items-center px-2 col-12 col-sm-2 col-md-2"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {item?.product?.thumb && (
                        <img
                            src={item?.product?.thumb?.url || ''}
                            alt={item?.product?.description}
                            title={item?.product?.description}
                            className=""
                        />
                    )}
                </div>
                <div className="order-item-details col-12 col-sm-8 col-md-8 text-medium">
                    <div className="d-flex flex-column mx-2 ">
                        <span className="order-item-name fw-bold">{item?.product?.name}</span>
                        <span className="order-item-description my-2">{item?.product?.description}</span>
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center fw-bold order-item-qtd-input">
                                <label>Qtd.:</label>
                                <label className="mx-2">{item?.qtd}</label>
                            </div>
                        </div>
                        {(item?.product?.has_shipping || false) && (
                            <label className="order-item-shipping-info mt-2">
                                <FontAwesomeIcon icon={faMailBulk} className="mx-1" />
                                Este produto possui serviço de postagem
                            </label>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderListItem;
