import React, { useCallback, useEffect, useState } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import { NavigateBackButton } from '@app/components/ui';
import AccountTabsMenu from '../components/AccountTabsMenu';
import DetailsBody from './components/DetailsBody';

export default function FormDetails(props) {
    const replaceParams = useReplaceParams;

    const [descriptor, setDescriptor] = useState({});

    const loadFormData = useCallback(
        (descriptor_id, content_id) => {
            const url = replaceParams(URLs.club.forms.show, { descriptor: descriptor_id, content: content_id });
            Api({ method: 'get', url }).then(({ data }) => {
                setDescriptor(data);
            });
        },
        [replaceParams]
    );

    useEffect(() => {
        const descriptor_id = props?.match?.params?.descriptor || undefined;
        const data_id = props?.match?.params?.id || undefined;

        if (!descriptor_id || !data_id) return;
        loadFormData(descriptor_id, data_id);
    }, [loadFormData, props?.match?.params]);

    return (
        <ContentWrapper title="">
            <AccountTabsMenu active="forms" />
            <div className="d-flex px-3 py-1">
                <NavigateBackButton title="Retornar para Meus Formulários" />
                <h2 className="content-title flex-shrink-1 col-12 text-center">
                    CADASTRO EFETUADO EM {descriptor?.data?.[0]?.created_at || ''}
                </h2>
            </div>
            <div className="page-content">
                <DetailsBody template={descriptor?.template} data={descriptor?.data?.[0] || {}} />
            </div>
        </ContentWrapper>
    );
}
