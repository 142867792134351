import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faFileDownload, faRedo } from '@fortawesome/free-solid-svg-icons';
import { useUserManager } from '../../hooks/useUserManager';
import Api from '../../services/Api';

import './FileDownloader.scss';

function FileDownloader({
    extension = 'pdf',
    url = '',
    method = 'get',
    filename = 'dados.pdf',
    hideLabel = false,
    label = '',
    className = '',
}) {
    const UserManager = useUserManager();
    const { token } = UserManager;

    const contentType = extension === 'csv' ? 'text/csv' : 'application/pdf';
    const [isLoading, setIsLoading] = useState(false);

    const handleDownloadFileClick = (e) => {
        if (!url || url === '') return;
        setIsLoading(true);
        Api({
            method: method || 'get',
            url,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': contentType,
            },
            responseType: 'blob',
        })
            .then((blob) => {
                let newFilename = `${filename || 'dados.pdf'}`;
                const cd = blob?.headers['content-disposition'] || `attachment; filename="${newFilename}"`;
                const cdArr = cd.split('filename=');
                newFilename = cdArr.length === 2 ? cdArr[1] : newFilename;
                newFilename = newFilename.replaceAll('"', '');

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob?.data], { type: contentType }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', newFilename);

                // Append to html link element page
                e.target.parentNode.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.status === 405) {
                    toast.error('Operação não autorizada!');
                } else {
                    toast.error(err?.data?.message || 'Erro inesperado ao tentar baixar o arquivo!');
                }
                setIsLoading(false);
            });
    };

    const icon = useMemo(() => {
        if (isLoading) return faRedo;
        if (extension === 'csv') return faFileCsv;
        return faFileDownload;
    }, [isLoading, extension]);

    return (
        <Button
            type="button"
            className={`btn-file-downloader btn-icon-left btn-success ${className}`}
            color="custom"
            onClick={handleDownloadFileClick}
        >
            <FontAwesomeIcon icon={icon} className={`mx-2 ${isLoading ? 'downloading-spinner' : ''} `} />
            {(hideLabel || false) === false && (label || 'Baixar PDF')}
        </Button>
    );
}

export default FileDownloader;
