import React, { useContext } from 'react';
import parse from 'html-react-parser';

import './MatDisplay.scss';
import ProductDisplayContext from './ProductDisplayContext';
import Product from '../Product/Product';

function MatDisplay({ productClassName, html = '', ...props }) {
    const { products } = useContext(ProductDisplayContext);

    if (products.length === 0) return <h3 className="text-center align-self-center">Nenhum produto disponível</h3>;

    return (
        <div {...props} className={`product-display mat-display ${props?.className || ''}`}>
            {html !== '' && <div className="col-12">{parse(html)}</div>}
            {products?.map((product) => {
                const show = !product?.user_has || (product?.user_has && !product?.extras?.hide_after_buy);
                if (!show) return null;
                return (
                    <div key={product.token} className={`mb-2 ${productClassName || ''}`}>
                        <Product className="h-100" data={product} />
                    </div>
                );
            })}
        </div>
    );
}
export default MatDisplay;
