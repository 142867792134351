import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

export default function CartFloatingButton() {
    return (
        <div className="cart-floating-button">
            <NavLink
                tag={Link}
                to={{
                    pathname: '/carrinho',
                    section: { name: '' },
                }}
                title="Ver carrinho de compras"
            >
                <FontAwesomeIcon icon={faShoppingCart} className="mx-1" />
            </NavLink>
        </div>
    );
}
