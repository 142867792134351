import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { URLs, useReplaceParams } from '@app/constants';

import Api from '@app/services/Api';

import SubscriptionContext from './components/SubscriptionContext';
import SubscriptionSummary from './components/SubscriptionSummary';
import SubscriptionDetails from './components/SubscriptionDetails';
import SubscriptionCheckoutForm from './components/SubscriptionCheckoutForm';
import SubscriptionCheckoutResume from './components/SubscriptionCheckoutResume';
import SubscriptionFuture from './components/SubscriptionFuture';
import SubscriptionRelatedPurchases from './components/SubscriptionRelatedPurchases';

export default function SubscriptionCheckout(props) {
    const replaceParams = useReplaceParams;

    const [isLoading, setIsLoading] = useState(false);
    const [planParams, setPlanParams] = useState({ token: '', model: '' });
    const [subscriptionData, setSubscriptionData] = useState({});

    useEffect(() => {
        if ((props?.match?.params?.token || '') === '') return;
        setPlanParams({
            token: props?.match?.params?.token || '',
            model: props?.match?.params?.model || '',
        });
    }, [props?.match?.params]);

    const loadSubscriptionData = useCallback(
        (params) => {
            setIsLoading(true);
            const url = replaceParams(URLs.club.subscriptions.subscriptions.show, { ...params, nocache: new Date().getTime() });
            Api({ method: 'get', url })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setSubscriptionData(response.data);
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do produto');
                });
        },
        [replaceParams]
    );

    useEffect(() => {
        if (planParams?.token === '') return;
        loadSubscriptionData(planParams);
    }, [planParams, loadSubscriptionData]);

    const updateSubscriptionData = () => {
        loadSubscriptionData(planParams);
    };

    const content = useMemo(() => {
        // O plano selecionado já está assinado
        if (['processing', 'pending', 'active', 'closed', 'future'].includes(subscriptionData.status))
            return <SubscriptionCheckoutResume className="col-12" />;
        // Já existe uma assinatura futura aguardando para ser iniciada e portanto a troca ainda está bloqueada
        if (subscriptionData?.future !== null && subscriptionData?.future?.status === 'future')
            return <SubscriptionFuture className="col-12" subscription={subscriptionData?.current} />;
        // Formulário de pagamento da assinatura
        return <SubscriptionCheckoutForm className="col-12" />;
    }, [subscriptionData]);

    return (
        <ContentWrapper className="subscription-checkout col-12 flex-shrink-1">
            <SubscriptionContext.Provider
                value={{
                    subscription: subscriptionData || {},
                    price: (subscriptionData?.plan?.prices || [])?.[0] || {},
                    user: subscriptionData?.user || {},
                    isLoading,
                    updateSubscriptionData,
                }}
            >
                <SubscriptionSummary className="mb-2" style={{ borderBottom: 'inherit' }} updateRequest={updateSubscriptionData} />
                <div className="d-flex flex-wrap col-12">
                    <div className="col-12 col-md-6 flex-shrink-1 p-1">
                        <SubscriptionDetails className="col-12" />
                    </div>
                    <div className="col-12 col-md-6 flex-shrink-1 p-1">{content}</div>
                </div>
                <SubscriptionRelatedPurchases />
            </SubscriptionContext.Provider>
        </ContentWrapper>
    );
}
