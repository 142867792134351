import React from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';

export default function CopyToClipboardField({ value = '', className = '' }) {
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(value);
            toast.success('O link foi copiado para a área de transferência');
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <div className={`copy-to-clipboard-field-container ${className || 'col-12'}`}>
            <input
                type="text"
                name="copy-to-clipboard-field"
                className="copy-to-clipboard-field text-medium fw-normal"
                value={value}
                disabled
            />
            <div
                role="button"
                className="copy-to-clipboard-icon"
                title="Copiar para a área de transferência"
                onClick={copyToClipboard}
                onKeyPress={() => {}}
                tabIndex={0}
            >
                <FontAwesomeIcon icon={faCopy} className="mx-2" />
            </div>
        </div>
    );
}
