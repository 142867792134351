import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';

import parse from 'html-react-parser';
import ProductDisplay from '@app/components/Products/ProductDisplays';
import FormDisplay from '@app/components/FormDisplay';
import PlanDisplay from '@app/components/Plans/PlanDisplay';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import DRCAContext from '@app/contexts/DRCAContext';

function HTMLContent({ content, className = '', ...props }) {
    const DRCA = useContext(DRCAContext);
    const contentRef = useRef(null);

    const handleClick = useCallback(
        (e) => {
            // e.preventDefault();
            e.stopPropagation();
            if (!DRCA.action) return;
            if (e.target.tagName === 'A') {
                e.stopPropagation();
                DRCA.action('click', {
                    component: 'A',
                    href: String(e.target.getAttribute('href')),
                    target: String(e.target.getAttribute('target')),
                    title: String(e.target.getAttribute('title')),
                    id: String(e.target.getAttribute('data-id') || ''),
                });
            }
        },
        [DRCA]
    );

    useEffect(() => {
        contentRef.current.addEventListener('click', handleClick);
    }, [contentRef, handleClick]);

    return (
        <div ref={contentRef} {...props} className={`${className} ${content.className}`}>
            {parse(content.html)}
        </div>
    );
}

function DynamicSection({ section, ...props }) {
    const replaceParams = useReplaceParams;

    const [sectionContent, setSectionContent] = useState([]);
    const loadSectionContent = useCallback(
        (content_token) => {
            const url_key = content_token ? 'show' : 'list';
            const url = replaceParams(URLs.club.sections.content[url_key], { token: section?.token || '', id: content_token || '' });
            Api({ url })
                .then(({ data, status }) => {
                    if (status === 200) {
                        if (content_token && data) {
                            setSectionContent((prev) => prev.map((content) => (content.token === content_token ? data : content)));
                        } else {
                            setSectionContent(data);
                        }
                    } else {
                        toast.error('Houve um erro ao carregar o conteúdo da página!');
                    }
                })
                .catch(() => {
                    toast.error('Houve um erro inesperado ao carregar o conteúdo da página!');
                });
        },
        [section, replaceParams]
    );

    useEffect(() => {
        loadSectionContent();
    }, [loadSectionContent]);

    return (
        <ContentWrapper className="home" title="" {...props}>
            <div className="page-content">
                <div className="d-flex flex-wrap">
                    {(sectionContent || []).map((content, index) => {
                        switch (content.type) {
                            case 'html':
                                return <HTMLContent key={index} content={content} />;
                            case 'product-display':
                                return (
                                    <ProductDisplay
                                        key={index}
                                        type={content.displayType}
                                        filter={content.filter}
                                        className={content.className}
                                        html={content?.html || ''}
                                        productClassName=""
                                        blockControls={false}
                                    />
                                );
                            case 'form':
                                return (
                                    <FormDisplay
                                        key={index}
                                        className={content.className}
                                        relatedProduct={content?.product || null}
                                        fields={content.fields}
                                        sectionContent={content.token}
                                        descriptor={content?.descriptor?.token || null}
                                        updateContent={loadSectionContent}
                                    />
                                );
                            case 'subscription-plan-display':
                                return (
                                    <PlanDisplay
                                        key={index}
                                        type={content.displayType}
                                        filter={content.filter}
                                        className={content.className}
                                        productClassName=""
                                        blockControls={false}
                                    />
                                );
                            default:
                                break;
                        }
                        return null;
                    })}
                </div>
            </div>
        </ContentWrapper>
    );
}

export default DynamicSection;
