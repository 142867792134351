import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense, useState } from 'react';
import { Button } from 'reactstrap';
import PopupAddNewCard from './PopupAddNewCard';

export default function ButtonAddNewCard({ updateRequest = () => {}, disabled = false }) {
    const [newCardModalIsOpen, setNewCardModalIsOpen] = useState(false);
    const openNewCardModal = () => setNewCardModalIsOpen(true);
    const closeNewCardModal = () => setNewCardModalIsOpen(false);

    const onUpdateRequest = () => {
        updateRequest();
    };

    return (
        <div className="d-flex justify-content-center">
            <Button type="button" color="success" onClick={openNewCardModal} disabled={disabled}>
                <FontAwesomeIcon className="mx-2" icon={faPlus} />
                ADICIONAR NOVO
            </Button>
            <Suspense>
                <PopupAddNewCard modalIsOpen={newCardModalIsOpen} closeModal={closeNewCardModal} updateRequest={onUpdateRequest} />
            </Suspense>
        </div>
    );
}
