import React from 'react';
import { useFormikContext } from 'formik';
import SizeSelector from './SizeSelector';

function ContentSizeSelector({ id, contentClass, ...props }) {
    const { setValues } = useFormikContext();

    const onChangeColumns = (value, size) => {
        setValues((prev) => {
            const index = prev.content.findIndex((item) => item.id === id);
            let newClass = prev.content[index].className
                .split(' ')
                .filter((item) => item.indexOf(`-${size}-`) === -1)
                .join(' ');
            newClass += value !== '' ? ` col-${value}` : '';
            return {
                ...prev,
                content: prev.content.map((item) => (item.id === id ? { ...item, className: newClass } : item)),
            };
        });
    };

    return (
        <div {...props} className={`columns-size-selector ${props?.className || ''}`}>
            <SizeSelector size="md" contentClass={contentClass} onChangeColumns={onChangeColumns} />
            <SizeSelector size="lg" contentClass={contentClass} onChangeColumns={onChangeColumns} />
        </div>
    );
}

export default ContentSizeSelector;
