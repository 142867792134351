import React from 'react';

function Separator({ field, formikProps: { hidden } }) {
    return (
        <h3 className={`field-group-title my-2 ${field.style}  ${hidden ? 'd-none' : ''}`} key={field.name}>
            {field.label}
        </h3>
    );
}

export default Separator;
