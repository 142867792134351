import React, { useContext, useState } from 'react';
import FormDisplay from '@app/components/FormDisplay';

import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCheck, faImage, faPlusSquare, faTasks } from '@fortawesome/free-solid-svg-icons';
import FormEditorFieldPopup from './popups/ChangeFieldPopup';
import ImportFieldsPopup from './popups/ImportFieldsPopup';

import { ContentSizeSelector, ContentPositionDropdown } from '../CustomTools';
import { useFormEditorFieldRenderer } from './components';
import ContentEditorContext from '../ContentEditorContext';
import FormEditorContext from './FormEditorContext';
import RelatedProductSelector from './components/RelatedProductSelector';
import NotificationContactsPopup from './popups/NotificationContactsPopup';
import PartnerLogoPopup from './popups/PartnerLogoPopup';

function FormEditorToolbar({ ...props }) {
    const {
        content,
        onAddNewField,
        onImportFields,
        onChangeNotificationContacts,
        onChangePartnerLogo,
        formik: { setValues },
    } = useContext(FormEditorContext);

    const onFormTitleChange = (value) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => (item.id === content.id ? { ...item, name: value } : item)),
        }));
    };

    const onFormRelatedProductChange = (suggestion) => {
        const product_id = suggestion?.token || null;
        const product_name = suggestion?.name || '';
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => {
                if (item.id === content.id)
                    return {
                        ...item,
                        product_id,
                        product: {
                            id: product_id,
                            name: product_name,
                        },
                    };
                return item;
            }),
        }));
    };

    return (
        <div className="toolbar d-flex flex-wrap col-12" {...props}>
            <ContentSizeSelector id={content.id} contentClass={content.className} className="d-flex flex-wrap" />
            <ContentPositionDropdown id={content.id} position={content.order} />
            <button type="button" className="rsw-btn btn-secondary" title="Adicionar novo campo" onClick={onAddNewField}>
                <FontAwesomeIcon icon={faPlusSquare} />
            </button>
            <button type="button" className="rsw-btn btn-secondary" title="Importar campos pré configurados" onClick={onImportFields}>
                <FontAwesomeIcon icon={faTasks} />
            </button>
            <button
                type="button"
                className="rsw-btn btn-badge btn-secondary"
                title="Alterar contatos para notificação de preenchimento do formulário"
                onClick={onChangeNotificationContacts}
            >
                <FontAwesomeIcon icon={faBell} />
                {(content?.filter?.notify || []).length > 0 && (
                    <span className="btn-counter-badge badge badge-success">{(content?.filter?.notify || []).length}</span>
                )}
            </button>
            <button
                type="button"
                className="rsw-btn btn-badge btn-secondary"
                title="Carregar logo de empresa parceira"
                onClick={onChangePartnerLogo}
            >
                <FontAwesomeIcon icon={faImage} />
                {(content?.filter?.partner_logo || '') !== '' && (
                    <span className="btn-counter-badge badge badge-success">
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                )}
            </button>
            <div className="d-flex flex-wrap col-12 p-1">
                <div className="d-flex flex-wrap p-1 col-12 align-items-center">
                    <div className="col-12 col-sm-2 flex-shrink-1">
                        <label className="col-12 text-medium">Título do Formulário: </label>
                    </div>
                    <input
                        type="text"
                        className="col-12 col-sm-10 flex-shrink-1"
                        name="content-name"
                        placeholder="Digite um título para o formulário"
                        value={content?.name || ''}
                        onChange={(e) => onFormTitleChange(e.target.value)}
                    />
                </div>
                <RelatedProductSelector
                    className="d-flex flex-wrap p-1 col-12 align-items-center"
                    onRelatedProductChange={onFormRelatedProductChange}
                    value={content?.product?.name || ''}
                />
            </div>
            {props.children}
        </div>
    );
}

export default function FormEditor() {
    const context = useContext(ContentEditorContext);
    const { content, isChanging, onApplyChanges, onDeleteContent } = context;

    const { setValues } = useFormikContext();

    // Configurações da popup de alteração do campo
    const [changeFieldPopupConfig, setChangeFieldPopupConfig] = useState({
        isOpen: false,
        field: {},
    });
    const onCloseChangeFieldPopup = () => {
        setChangeFieldPopupConfig((prev) => ({ ...prev, isOpen: false, field: {} }));
    };

    /*
     * Popup de alteração dos contatos de notificação
     */
    const [notificationContactsPopupConfig, setNotificationContactsPopupConfig] = useState({
        isOpen: false,
        filter: {},
    });
    const onCloseNotificationContactsPopup = () => {
        setNotificationContactsPopupConfig((prev) => ({ ...prev, isOpen: false, filter: {} }));
    };
    // Abre a popup para a alteração dos contatos de notificação
    const onChangeNotificationContacts = () => {
        setNotificationContactsPopupConfig((prev) => ({
            ...prev,
            ...{
                isOpen: true,
                filter: content?.filter || {},
            },
        }));
    };
    const onApplyNotificationContactsChange = (filter) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((content_item) => {
                if (content_item.id === content.id) {
                    return {
                        ...content_item,
                        filter,
                    };
                }
                return content_item;
            }),
        }));
        onCloseNotificationContactsPopup();
    };

    /*
     * Popup de alteração do logo do parceiro
     */
    const [partnerLogoPopupConfig, setPartnerLogoPopupConfig] = useState({
        isOpen: false,
        filter: {},
    });
    const onClosePartnerLogoPopup = () => {
        setPartnerLogoPopupConfig((prev) => ({ ...prev, isOpen: false, filter: {} }));
    };
    // Abre a popup para a alteração dos contatos de notificação
    const onChangePartnerLogo = () => {
        setPartnerLogoPopupConfig((prev) => ({
            ...prev,
            ...{
                isOpen: true,
                filter: content?.filter || {},
            },
        }));
    };
    const onApplyPartnerLogoChange = (filter) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((content_item) => {
                if (content_item.id === content.id) {
                    return {
                        ...content_item,
                        filter,
                    };
                }
                return content_item;
            }),
        }));
        onClosePartnerLogoPopup();
    };

    const onApplyFieldChanges = (field) => {
        const newField = field;
        newField.id = newField.id === null ? new Date().getTime() : newField.id;
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => {
                if (item.id === content.id) {
                    const newSet = JSON.parse(JSON.stringify(item.fields));
                    const fieldIndex = newSet.findIndex((item) => item.id === newField.id);
                    if (fieldIndex >= 0) newSet.splice(fieldIndex, 1);
                    newSet.splice(newField.order, 0, newField);
                    return {
                        ...item,
                        fields: newSet.map((item, index) => ({ ...item, order: index })),
                    };
                }
                return item;
            }),
        }));
        onCloseChangeFieldPopup();
    };

    // Abre a popup para a inserção de um novo campo
    const onAddNewField = () => {
        setChangeFieldPopupConfig((prev) => ({
            ...prev,
            ...{
                isOpen: true,
                field: {
                    name: '',
                    label: '',
                    type: 'string',
                    show: false,
                    validations: '',
                    order: content.fields.length,
                    style: 'px-1 col-12',
                },
            },
        }));
    };
    // Remove um campo existente
    const onDeleteField = (field) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => {
                if (item.id === content.id) {
                    return {
                        ...item,
                        fields: item.fields.filter((item) => item.name !== field.name),
                    };
                }
                return item;
            }),
        }));
    };

    const [importFieldsPopupConfig, setImportFieldsPopupConfig] = useState({ isOpen: false });
    const onImportFields = (field) => {
        setImportFieldsPopupConfig((prev) => ({
            ...prev,
            ...{
                isOpen: true,
                field,
            },
        }));
    };
    const onCloseImportFieldsPopup = () => {
        setImportFieldsPopupConfig((prev) => ({ ...prev, isOpen: false }));
    };
    const onApplyFieldsImport = (fields) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => {
                if (item.id === content.id) {
                    const newSet = [...JSON.parse(JSON.stringify(item.fields)), ...fields];
                    return {
                        ...item,
                        fields: newSet.map((item, index) => ({ ...item, order: index })),
                    };
                }
                return item;
            }),
        }));
        onCloseImportFieldsPopup();
    };

    // Abre a popup para a alteração de um campo existente
    const onChangeField = (field) => {
        setChangeFieldPopupConfig((prev) => ({
            ...prev,
            ...{
                isOpen: true,
                field,
            },
        }));
    };

    // Mostra/Esconde o campo na tabela de visualização dos dados preenchidos pelo usuário
    const onChangeFieldTableVisibility = (field) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((content_item) => {
                if (content_item.id === content.id) {
                    return {
                        ...content_item,
                        fields: content_item.fields.map((field_item) =>
                            field_item.name === field.name ? { ...field_item, show: !field_item.show } : field_item
                        ),
                    };
                }
                return content_item;
            }),
        }));
    };

    return (
        <FormEditorContext.Provider
            value={{
                ...context,
                onApplyFieldChanges,
                onAddNewField,
                onImportFields,
                onCloseImportFieldsPopup,
                onApplyFieldsImport,
                onChangeField,
                onChangeFieldTableVisibility,
                onDeleteField,
                onCloseChangeFieldPopup,

                onChangeNotificationContacts,
                onCloseNotificationContactsPopup,
                onApplyNotificationContactsChange,

                onChangePartnerLogo,
                onClosePartnerLogoPopup,
                onApplyPartnerLogoChange,
            }}
        >
            {isChanging === true && content && (
                <FormEditorToolbar>
                    <div className="col-12 my-1 d-flex">
                        <div className="col-5 text-center" style={{ maxWidth: '200px' }}>
                            <button type="button" className="btn btn-success col-10" onClick={onApplyChanges}>
                                APLICAR
                            </button>
                        </div>
                        <div className="col-5 text-center" style={{ maxWidth: '200px' }}>
                            <button type="button" className="btn btn-danger col-10" onClick={() => onDeleteContent(content.id)}>
                                EXCLUIR
                            </button>
                        </div>
                    </div>
                </FormEditorToolbar>
            )}
            <FormDisplay
                className={content.className}
                fields={content.fields}
                section_content_id={content.id}
                form_content_descriptor_id={content?.form_descriptor?.id || null}
                renderer={useFormEditorFieldRenderer}
                style={{ width: '100%' }}
                blockControls
            />

            <FormEditorFieldPopup {...changeFieldPopupConfig} />
            <ImportFieldsPopup {...importFieldsPopupConfig} />
            <NotificationContactsPopup {...notificationContactsPopupConfig} />
            <PartnerLogoPopup {...partnerLogoPopupConfig} />
        </FormEditorContext.Provider>
    );
}
