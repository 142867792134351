import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';

import { Form, Formik } from 'formik';
import FormikDevTools from '@app/components/FormikDevTools';

import { toast } from 'react-hot-toast';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';

import FieldsDisplay from './FieldsDisplay';
import { useFormDisplayFieldRenderer } from './components/useFormDisplayFieldRenderer';
import useFormDisplayValidations from './components/useFormDisplayValidations';
import useFormDisplayInitialValues from './components/useFormDisplayInitialValues';
import SubmitScreen from './components/SubmitScreen';

export default function FormDisplay({
    className = '',
    blockControls = false,
    fields = [],
    sectionContent = null,
    relatedProduct = null,
    descriptor = null,
    renderer = useFormDisplayFieldRenderer,
    updateContent = () => {},
    ...props
}) {
    const history = useHistory();
    const replaceParams = useReplaceParams;
    const formDisplayValidations = useFormDisplayValidations;
    const formDisplayInitialValues = useFormDisplayInitialValues;

    /**
     * File Upload
     */
    const [files, setFiles] = useState({});
    const addNewFile = useCallback((key, fieldName, fieldLabel, fileName, fileType, file) => {
        setFiles((prev) => ({ ...prev, [key]: { key, fieldName, fieldLabel, fileName, fileType, file } }));
    }, []);
    const removeFile = useCallback((fieldName) => setFiles((prev) => ({ ...prev, [fieldName]: undefined })), []);

    /**
     * Submitting Modal
     */
    const [submittingModalConfig, setSubmittingModalConfig] = useState({
        visible: false,
        currentStep: 0,
        steps: [],
    });

    /**
     * Prepara a lista de etapas para a submissão dos dados
     * @param object values Dados a serem submetidos
     * @return int Quantidade de etapas
     */
    const prepareSubmitting = (values) => {
        const steps = [{ keyName: 'form-data', message: 'Enviando Dados de Cadastro', status: '' }];
        const fileSteps = [];
        const strValues = JSON.stringify(values);
        Object.keys(files).forEach((fileId) => {
            if (strValues.includes(fileId)) {
                steps.push({
                    keyName: fileId,
                    message: `Enviando Arquivo "${files[fileId].fieldLabel}"`,
                    status: '',
                });

                fileSteps.push({
                    fileId,
                    fileName: files[fileId].fileName,
                    fileType: files[fileId].fileType,
                    description: files[fileId].fieldLabel,
                });
            }
        });
        setSubmittingModalConfig((prev) => ({ ...prev, visible: true, currentStep: 0, steps }));
        return fileSteps;
    };

    /**
     * Atualiza o status de um estágio da submissão dos dados
     * @param string keyName Chave do estágio
     * @param string status Novo status para o estágio
     * @return void
     */
    const updateSubmittingStep = (keyName, status) => {
        setSubmittingModalConfig((prev) => ({
            ...prev,
            currentStep: prev.currentStep + 1,
            steps: prev.steps.map((step) => (step.keyName === keyName ? { ...step, status } : step)),
        }));
    };

    /**
     * Envia os dados do formulário para o servidor
     */
    const submitFormData = async (values, fileList) =>
        new Promise((resolve) => {
            const url = replaceParams(URLs.club.forms.index, { content: sectionContent || null, descriptor: descriptor || null });
            const data = {
                data: values,
                files: fileList.map((file) => ({
                    key: file.fileId,
                    filename: file.fileName,
                    filetype: file.fileType,
                    description: file.description,
                })),
            };

            Api({ method: 'post', url, data })
                .then(({ data, status }) => {
                    if (data && status === 200) {
                        resolve({ success: true, dataId: data?.dataId || null, message: '' });
                    } else {
                        resolve({
                            success: false,
                            dataId: null,
                            message: data?.message || 'Erro ao enviar os dados do formulário!',
                        });
                    }
                })
                .catch(({ response }) => {
                    resolve({
                        success: false,
                        dataId: null,
                        message: response?.data?.message || 'Erro inesperado ao tentar enviar os dados do formulário!',
                    });
                });
        });

    /**
     * Envia os dados do arquivo para o servidor
     */
    const submitFile = async (fileId, data, dataId) =>
        new Promise((resolve) => {
            const url = replaceParams(URLs.club.forms.file, { dataId });
            Api({ method: 'post', url, data })
                .then(({ data, status }) => {
                    if (data && status === 200) {
                        resolve({ success: true, message: '' });
                    } else {
                        resolve({
                            success: false,
                            message: data?.message || 'Erro ao enviar os dados do formulário!',
                        });
                    }
                })
                .catch(({ response }) => {
                    resolve({
                        success: false,
                        message: response?.data?.message || 'Erro inesperado ao tentar enviar os dados do formulário!',
                    });
                });
        });

    /**
     * Informa o fim dos envios ao servidor
     */
    const finishSubmitting = (dataId, { updateContent, setSubmitting }) => {
        const url = replaceParams(URLs.club.forms.finish, { dataId });
        Api({ method: 'post', url })
            .then(({ data, status }) => {
                if (data && status === 200) {
                    updateContent();
                    toast.success('TODOS OS ENVIOS FORAM CONCLUÍDOS!');
                    if ((data?.onfinish?.redirect || null) !== null) history.push(data?.onfinish?.redirect);
                } else {
                    toast.error(data?.message || 'Erro ao finalizar os envios do formulário!');
                }
                setSubmittingModalConfig({
                    visible: false,
                    currentStep: 0,
                    steps: [],
                });
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar enviar os dados do formulário!');
            });
    };

    /**
     * Envia o próximo arquivo da lista
     */
    const submitNext = (dataId, currentStep, steps, formikProps) => {
        if (currentStep >= steps.length) {
            finishSubmitting(dataId, formikProps);
            return;
        }
        const step = steps[currentStep];
        updateSubmittingStep(step.fileId, 'submiting');

        submitFile(step.fileId, files[step.fileId], dataId).then(({ success, message }) => {
            if (!success) {
                toast.error(message);
                updateSubmittingStep(step.fileId, 'error');
            } else {
                updateSubmittingStep(step.fileId, 'done');
            }
            // checkSubmittingStatus();
            submitNext(dataId, currentStep + 1, steps, formikProps);
        });
    };

    // Faz o submit dos dados do formulário
    const handleFormSubmit = async (values, { setSubmitting /* , setErrors, resetForm */ }) => {
        setSubmitting(true);
        const fileSteps = prepareSubmitting(values);

        // Enviando dados do formulário
        const { success, dataId, message } = await submitFormData(values, fileSteps);
        if (!success) {
            toast.error(message);
            setSubmitting(false);
            return;
        }
        updateSubmittingStep('form-data', 'done');

        submitNext(dataId, 0, fileSteps, { updateContent, setSubmitting });

        if (values) return;
        updateContent();
        finishSubmitting();
    };

    // const validations = useMemo(() => formDisplayValidations(fields), [fields, formDisplayValidations]);
    const initialValues = useMemo(() => formDisplayInitialValues(fields), [fields, formDisplayInitialValues]);

    const formDisabled = useMemo(() => {
        if (relatedProduct === null)
            return {
                disabled: false,
                content: '',
            };
        const disabled = (relatedProduct?.user_has?.remaining || 0) <= 0;
        return {
            disabled,
            content: disabled ? (
                <div className="col-12 text-error">Você não possui documentos para emitir</div>
            ) : (
                <div className="col-12 text-success">Você tem {relatedProduct?.user_has?.remaining || 0} documentos para emitir</div>
            ),
        };
    }, [relatedProduct]);

    const onFormValidate = async (values) =>
        new Promise((resolve) => {
            const validations = formDisplayValidations(fields);
            const errors = {};
            validations
                .validate(values, { stripUnknown: false, abortEarly: false })
                .then(() => {
                    resolve({});
                })
                .catch((validationError) => {
                    validationError.inner.forEach((error) => {
                        errors[error.path] = error.errors.join(', ');
                    });
                    resolve(errors);
                });
        });

    return (
        <div {...props} className={`form-display ${className}`} style={{ position: 'relative' }}>
            <Formik
                initialValues={initialValues}
                // validationSchema={validations}
                validate={onFormValidate}
                validateOnChange={false}
                onSubmit={handleFormSubmit}
                enableReinitialize
                render={(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                        {formDisabled?.content}
                        <FieldsDisplay
                            fields={fields}
                            renderer={renderer}
                            disabled={formDisabled.disabled}
                            props={{ ...formikProps, addNewFile, removeFile }}
                        />
                        <div className="col-12 mt-3 d-flex">
                            <div className="mb-3 col-6 text-center">
                                <Button
                                    type="submit"
                                    className="col-10"
                                    color="success"
                                    disabled={formDisabled.disabled || blockControls || formikProps.isSubmitting}
                                >
                                    ENVIAR
                                </Button>
                            </div>
                        </div>
                        <FormikDevTools>
                            <pre className="col-12">{JSON.stringify(files, null, 2)}</pre>
                        </FormikDevTools>
                    </Form>
                )}
            />
            <SubmitScreen {...submittingModalConfig} />
        </div>
    );
}
