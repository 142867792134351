import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'reactstrap';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { URLs, useReplaceParams } from '@app/constants';
import FileDownloader from '@app/components/FileDownloader/FileDownloader';

function DocumentsTable(props) {
    const replaceParams = useReplaceParams;

    const data = props?.data;
    const [selectedFiles] = useState({});
    useEffect(() => {
        data?.forEach((file) => {
            selectedFiles[file.token] = '';
        });
    }, [data, selectedFiles]);

    const onFileUploadChanged = (el, token) => {
        props?.handleFileUploadChange(el.files[0], token);
        document.querySelector(`#filename_${token}`).innerHTML = el.files[0].name;
    };

    return (
        <Table col="12" className="text-medium">
            <thead>
                <tr>
                    <th>Descrição</th>
                    <th className="text-center">Status</th>
                    <th>Observações</th>
                    <th className="text-center">Arquivos Enviados</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {data?.length > 0 ? (
                    data?.map((record, id) => {
                        let statusColor = 'black';
                        switch (record?.status || 1) {
                            case 1:
                                statusColor = 'black';
                                break;
                            case 2:
                                statusColor = 'hover-green';
                                break;
                            case 9:
                                statusColor = 'dark-red';
                                break;
                            default:
                                statusColor = 'black';
                                break;
                        }
                        return (
                            <tr key={id}>
                                <td data-title="Descrição" className="text-small">
                                    {record?.description}
                                </td>
                                <td data-title="Status" className={`text-center text-${statusColor} fw-bold`}>
                                    {record?.status_label}
                                </td>
                                <td data-title="Observações" className="text-small">
                                    {record?.obs}
                                </td>
                                <td data-title="Arquivo Enviado" className="text-center">
                                    {[1, 2, 9].includes(record?.status) && (
                                        <FileDownloader
                                            className="mx-2"
                                            url={
                                                URLs.base() +
                                                replaceParams(URLs.club.certificates.document, {
                                                    token: props?.token,
                                                    document_token: record?.token,
                                                })
                                            }
                                            label="Baixar arquivo"
                                            filename={record?.filename}
                                            extension={record?.filetype}
                                        />
                                    )}
                                </td>
                                <td data-title="Enviar Arquivo" className="text-center">
                                    {[0, 1, 9].includes(record?.status) && (
                                        <>
                                            <label
                                                className={`btn btn-primary btn-icon-left mx-2 ${
                                                    (selectedFiles[record?.token || null] || '') !== '' ? 'text-dark-green' : ''
                                                }`}
                                                htmlFor={record?.token}
                                                title="Fazer upload do arquivo"
                                            >
                                                <FontAwesomeIcon icon={faFileUpload} className="mx-2" />
                                                Selecionar arquivo
                                                <input
                                                    className="d-none"
                                                    name={record?.token}
                                                    id={record?.token}
                                                    type="file"
                                                    onChange={(e) => onFileUploadChanged(e.target, record?.token)}
                                                />
                                            </label>
                                            <label className="col-12 my-1 text-small" id={`filename_${record?.token}`} />
                                        </>
                                    )}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr key={0}>
                        <td colSpan={13} align="center">
                            Nenhum registro retornado
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default DocumentsTable;
