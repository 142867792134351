import React, { useState } from 'react';
import { DropDown, DropdownItem } from '@app/components/Autocomplete';
import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import { toast } from 'react-hot-toast';
import { useFormikContext } from 'formik';

function ProductSearch({ name = '', ...props }) {
    const replaceParams = useReplaceParams;
    const { values, setValues } = useFormikContext();
    /**
     * Consulta por cidades
     */
    const [suggestions, setSuggestions] = useState([]);
    const onSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason !== 'input-changed') return;
        const url_params = {
            token: values?.supplier?.token || '',
            name: value,
        };
        if (url_params.token === '') return;
        const url = replaceParams(URLs.admin.suppliers.products.basics, url_params);
        try {
            Api({ method: 'get', url })
                .then(({ data, status }) => {
                    if (status === 200) {
                        setSuggestions(data || []);
                    } else {
                        toast.error(data?.message || 'Erro ao obter dados dos produtos');
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao tentar consultar o produto.');
                });
        } catch (error) {
            // console.log(error);
        }
    };

    const handleSuggestionSelected = (suggestion) => {
        if (values === null || setValues === null) return;
        setValues((prev) => ({
            ...prev,
            product_id: suggestion.id,
            product: {
                id: suggestion.id,
                name: suggestion.name,
            },
        }));
        setSuggestions([]);
        return suggestion.name;
    };
    // Render the suggested user list
    const renderSuggestions = (suggestion) => (
        <DropdownItem tag="label" className="suggestion-item" key={suggestion.id}>
            {suggestion.name}
        </DropdownItem>
    );
    return (
        <DropDown
            className="col-12"
            {...props}
            name="suppliers-search"
            placeholder="Buscar produto"
            selectedValue={name}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            renderSuggestion={renderSuggestions}
            getSuggestionValue={(suggestion) => {
                handleSuggestionSelected(suggestion);
            }}
            formValues={values}
            onSetValues={setValues}
        />
    );
}

export default ProductSearch;
