import React from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import CustomerForm from '@app/components/CustomerProfile/CustomerForm';
import AccountTabsMenu from '../components/AccountTabsMenu';

function AccountData() {
    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper className="users_form" hideTitle>
            <AccountTabsMenu active="data" />
            <div className="page-content">
                <h2 className="text-center col-12 bg-primary br-4 p-1 text-white text-large mt-1">MEUS DADOS</h2>
                <CustomerForm />
            </div>
        </ContentWrapper>
    );
}

export default AccountData;
