import React, { useState } from 'react';
import { Row, Col, Button, Form, InputGroup } from 'reactstrap';
import { URLs, useReplaceParams } from '@app/constants';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';

import Modal from 'react-modal';
import { ErrorMessage, Field, Formik } from 'formik';

import * as yup from 'yup';
import { useUserManager } from '@app/hooks/useUserManager';
import Api from '@app/services/Api';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { Document, Page, pdfjs } from 'react-pdf';

import './DocumentReview.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
function DocumentReview(props) {
    const history = useHistory();
    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    const [documentData, setDocumentData] = useState({});
    const [fileContent, setFileContent] = useState(null);

    const [isLoadingDocument, setIsLoadingDocument] = useState(false);

    const downloadAndShowFile = (data) => {
        if (data === null) return;

        const url =
            URLs.base() +
            replaceParams(URLs.admin.certificates.document.review, { token: data?.token, document_token: data?.document_token });
        setIsLoadingDocument(true);
        Api({
            method: 'get',
            url,
            responseType: 'blob',
        })
            .then((blob) => {
                const $container = document.querySelector('#document_preview_container');
                $container.innerHtml = '';
                const image = document.createElement('img');
                const reader = new FileReader();
                reader.addEventListener('loadend', () => {
                    let contents = reader.result;
                    if (data?.document_type === 'pdf') {
                        contents = contents.replace('data:application/octet-stream;base64', 'data:application/pdf;base64');
                        setFileContent(contents);
                    } else {
                        image.src = contents;
                        image.style.maxWidth = '100%';
                        image.style.height = 'fit-content';
                        $container.appendChild(image);
                    }
                });
                reader.readAsDataURL(new Blob([blob?.data]));

                setIsLoadingDocument(false);
            })
            .catch(() => {
                toast.error('Não foi possível fazer o download do documento!');
                setIsLoadingDocument(false);
            });
    };

    const [isSubmitting, setSubmitting] = useState(false);
    const updateDocumentState = (url, justification) => {
        setSubmitting(true);
        Api({
            method: 'put',
            url,
            data: {
                justification,
            },
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    props?.updateRequest();
                    props?.closeModal();
                    if (response?.data?.remaining === 0) history.push('/admin/certificados');
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados do contrato!');
                setSubmitting(false);
            });
    };

    const handleRefuseDocument = (justification) => {
        const url = replaceParams(URLs.admin.certificates.document.refuse, {
            token: props?.data?.token,
            document_token: props?.data?.document_token,
        });
        updateDocumentState(url, justification);
    };
    const handleAproveDocument = () => {
        const url = replaceParams(URLs.admin.certificates.document.aprove, {
            token: props?.data?.token,
            document_token: props?.data?.document_token,
        });
        updateDocumentState(url, '');
    };

    const afterOpenModal = () => {
        setDocumentData(props?.data);
        downloadAndShowFile(props?.data || null);
    };

    const afterCloseModal = () => {
        setDocumentData({});
        setFileContent(null);
    };

    const contractValidations = yup.object().shape({});

    const contractInitialValues = {
        justification: '',
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={props?.modalIsOpen}
            onAfterOpen={afterOpenModal}
            onAfterClose={afterCloseModal}
            onRequestClose={props?.closeModal}
            style={customStyles}
            contentLabel={props?.title}
            className=""
            ariaHideApp={false}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">{props?.title}</h2>
                    <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={props?.closeModal}>
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    </Button>
                </Col>
            </Row>
            <Formik
                initialValues={contractInitialValues}
                validationSchema={contractValidations}
                render={(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <Field type="hidden" name="id" />
                        <div
                            className="col-12 my-2 border d-flex justify-content-center"
                            id="document_preview_container"
                            style={{ minHeight: 300, maxHeight: 400, overflowY: 'auto' }}
                        >
                            {documentData?.document_type === 'pdf' && fileContent !== null && (
                                <Document
                                    file={fileContent}
                                    options={{ workerSrc: 'pdf.worker.js' }}
                                    loading="Carregando arquivo PDF"
                                    error="Erro ao abrir arquivo PDF"
                                >
                                    <Page pageNumber={1} />
                                </Document>
                            )}
                            {isLoadingDocument === true && <LoadingSpinner />}
                        </div>
                        {[1, 9].includes(props?.data?.status) && (
                            <>
                                <InputGroup className="mb-3">
                                    <Field name="justification" type="text" placeholder="Justificativa" className="col-12" />
                                    <ErrorMessage component="span" name="justification" className="text-error text-small" />
                                </InputGroup>
                                <Row className="col-12">
                                    <Col className="mb-3 col-12 col-sm-6 text-center">
                                        <Button
                                            type="button"
                                            className="col-12 mx-2"
                                            color="danger"
                                            disabled={isSubmitting || formikProps.values.justification.length < 10}
                                            onClick={() => handleRefuseDocument(formikProps.values.justification)}
                                        >
                                            RECUSAR
                                        </Button>
                                    </Col>
                                    <Col className="mb-3 col-12 col-sm-6 text-center">
                                        <Button
                                            type="submit"
                                            className="col-12 mx-2"
                                            color="success"
                                            disabled={isSubmitting}
                                            onClick={handleAproveDocument}
                                        >
                                            APROVAR
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                )}
            />
        </Modal>
    );
}

export default DocumentReview;
