const randomNumber = (length = 1) => {
    const max = 10 ** length - 1;
    const min = 10 ** (length - 1);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const randomPhoneNumber = () => `(${randomNumber(2)}) ${randomNumber(5)}-${randomNumber(4)}`;

export const randomCustomerRegistration = (setValues) => {
    const randomNumber = Math.round(10000 + Math.random() * 9999);
    setValues((prev) => ({
        ...prev,
        name: `Usuário ${randomNumber}`,
        email: `allexrm+${randomNumber}@gmail.com`,
        phone: randomPhoneNumber(),
        state: 'SP',
        city_id: 3509502,
        city_name: 'Campinas',
        terms_agreement: true,
    }));
};

export const randomAddress = (setValues, addressKey = 'address') => {
    const randomNumber = Math.round(10000 + Math.random() * 9999);
    setValues((prev) => ({
        ...prev,
        [addressKey]: {
            address: `Rua ${randomNumber}`,
            number: `${randomNumber}`,
            complement: `Ap ${randomNumber}`,
            reference: `Ao lado da casa ${randomNumber + 1}`,
            zip: `${randomNumber}-123`,
            neighborhood: `Jardim ${randomNumber}`,
            state: 'SC',
            city_id: '4205407',
            city_name: 'Florianópolis',
        },
    }));
};
