import React, { useRef, useState } from 'react';
import { URLs, useReplaceParams, PopupStyle } from '@app/constants';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';

import { Button, Label, Form } from 'reactstrap';

import Modal from 'react-modal';
import { ErrorMessage, Field, Formik } from 'formik';

import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function PlansGroupPopup({ closeModal = () => {}, updateRequest = () => {}, modalIsOpen = false, group = {}, ...props }) {
    const replaceParams = useReplaceParams;

    const nameRef = useRef(null);
    const [title, setTitle] = useState('CADASTRAR NOVO GRUPO');
    const afterOpenModal = () => {
        setTitle(group?.token || undefined ? 'ALTERAR GRUPO' : 'CADASTRAR NOVO GRUPO');
        if (nameRef?.current) nameRef.current.focus();
    };

    const handleFormSubmit = (values) => {
        const method = group?.token || undefined ? 'put' : 'post';
        const url = replaceParams(URLs.admin.subscriptions.groups[method], { token: group?.token });
        Api({ method, url, data: values })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status do produto.');
                    return;
                }
                toast.success(response?.data?.message);
                updateRequest();
                closeModal();
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status do produto!');
            });
    };

    const validations = yup.object().shape({
        name: yup
            .string()
            .min(3, 'O nome do grupo deve conter ao menos 3 caracteres')
            .max(100, 'O nome do grupo deve conter no máximo 100 caracteres')
            .required('É necessário informar um nome para o grupo.'),
    });

    const initialValues = {
        token: group?.token || '',
        name: group?.name || '',
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{ ...PopupStyle, ...{ content: { ...PopupStyle.content, ...{ maxWidth: '400px' } } } }}
            contentLabel={title}
            className=""
            ariaHideApp={false}
        >
            <div className="col-12 mb-2 d-flex align-items-center">
                <h2 className="flex-grow-1 text-center text-large my-0">{props?.data?.product?.name || ''}</h2>
                <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={closeModal}>
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                </Button>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleFormSubmit}
                enableReinitialize
                render={(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                        <div className="col-12">
                            <div className="mb-1">
                                <Label htmlFor="name">Nome do grupo</Label>
                                <Field type="text" name="name" className="col-12" innerRef={nameRef} />
                                <ErrorMessage component="span" name="name" className="text-error text-small" />
                            </div>
                            <div className="d-flex">
                                <div className="mb-3 px-1 col-6 text-center">
                                    <Button
                                        type="submit"
                                        className="col-12"
                                        color="danger"
                                        disabled={props?.isSubmitting}
                                        onClick={closeModal}
                                    >
                                        CANCELAR
                                    </Button>
                                </div>
                                <div className="mb-3 px-1 col-6 text-center">
                                    <Button type="submit" className="col-12" color="success" disabled={props?.isSubmitting}>
                                        APLICAR
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            />
        </Modal>
    );
}

export default PlansGroupPopup;
