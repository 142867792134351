import React, { useState, useEffect } from 'react';
import { CustomSwitch } from '@app/components/ui';
import CheckPermission from '@app/components/CheckPermission';

import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const weekDays = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];

function ScheduleTable(props) {
    const weekDates = props?.weekDates || [];
    const dayHours = props?.dayHours || [];
    const [calendar, setCalendar] = useState(props?.calendar || {});
    useEffect(() => {
        setCalendar(props?.calendar || {});
    }, [props]);

    return (
        <table className="col-12 table table-bordered text-medium">
            <thead className="thead-light">
                <tr>
                    <th>&nbsp;</th>
                    {weekDates.map((day, index) => (
                        <th key={index} className={`text-center ${day.today ? 'text-warning' : ''}`}>
                            <div className="col-12 d-flex flex-wrap align-items-end">
                                <div className="flex-grow-1">
                                    {day.title}
                                    <br />
                                    {weekDays[day.weekDay]}
                                </div>
                                <CheckPermission permission="edit-calendar">
                                    <div className="d-flex flex-column">
                                        <FontAwesomeIcon
                                            icon={faToggleOff}
                                            title="Desmarcar todos os horários deste dia"
                                            onClick={() => props?.handleSelectAllAvailableTimes(day, 0)}
                                        />
                                        <FontAwesomeIcon
                                            icon={faToggleOn}
                                            title="Marcar todos os horários deste dia"
                                            onClick={() => props?.handleSelectAllAvailableTimes(day, 1)}
                                        />
                                    </div>
                                </CheckPermission>
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {dayHours.map((hour, index) => (
                    <tr key={index}>
                        <th className="text-center">
                            <div className="col-12 d-flex flex-wrap align-items-center">
                                <div className="flex-grow-1">{hour.label}</div>
                                <CheckPermission permission="edit-calendar">
                                    <div className="d-flex flex-column">
                                        <FontAwesomeIcon
                                            icon={faToggleOff}
                                            className="mr-1"
                                            title="Desmarcar este horário em todos os dias da semana"
                                            onClick={() => props?.handleSelectAllAvailableDays(hour, 0)}
                                        />
                                        <FontAwesomeIcon
                                            icon={faToggleOn}
                                            title="Marcar este horário em todos os dias da semana"
                                            onClick={() => props?.handleSelectAllAvailableDays(hour, 1)}
                                        />
                                    </div>
                                </CheckPermission>
                            </div>
                        </th>
                        {weekDates.map((day, index) => {
                            const title = `${day.label} às ${hour.label}`;
                            const status = calendar?.[day.index]?.[hour.index]?.status || 0;
                            return (
                                <td
                                    key={index}
                                    width="12.5%"
                                    className="text-center"
                                    style={{ backgroundColor: status === 2 ? '#ece50c4f' : 'none' }}
                                    title={`${title}`}
                                >
                                    {status === 2 ? (
                                        <label className="text-small">
                                            Agendado para:
                                            <br />
                                            <a
                                                href={`/admin/certificados/${calendar[day.index][hour.index]?.certificate?.token}/revisao`}
                                                title="Abrir dados da solicitação"
                                            >
                                                {calendar[day.index][hour.index]?.certificate?.name}
                                            </a>
                                        </label>
                                    ) : (
                                        <CheckPermission
                                            permission="edit-calendar"
                                            allowed={
                                                <CustomSwitch
                                                    name={`${day.index}-${hour.index}`}
                                                    className="justify-content-center"
                                                    onChange={props?.handleTableCellStateChange}
                                                    checked={status === 1 || false}
                                                    isAsync
                                                    disabled={props?.isSubmitting || false}
                                                    data={{ date: day.index, hour: hour.index, status }}
                                                />
                                            }
                                            notAllowed={
                                                <label className={status === 1 ? 'text-success' : 'text-error'}>
                                                    {status === 1 ? 'DISPONÍVEL' : 'INDISPONÍVEL'}
                                                </label>
                                            }
                                        />
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
export default ScheduleTable;
