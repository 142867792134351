import React, { useRef, useContext, useMemo } from 'react';
import { Button } from 'reactstrap';
// import { Link } from 'react-router-dom';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBox } from '@fortawesome/free-solid-svg-icons';
import { URLs, useReplaceParams } from '@app/constants';
import CustomerAddress from '@app/components/CustomerAddress';

import PurchaseStatus from '@app/constants/PurchaseStatus.constant';
import OrderContext from '../OrderContext';

import './OrderSummary.scss';

export default function OrderSummary({ purchaseIsOpen = true, className = '', style = {}, onApplyCoupom = () => {} }) {
    const replaceParams = useReplaceParams;
    const { purchase, isLoading, updateRequest } = useContext(OrderContext);

    const changeAddressUrl = useMemo(() => {
        if ((purchase?.token || null) === null) return null;
        return replaceParams(URLs.club.purchases.change_address, { token: purchase.token });
    }, [purchase, replaceParams]);

    const coupomRef = useRef(null);

    return (
        <div className={`d-flex col-12 flex-wrap payment-summary ${className || ''}`} style={style}>
            <div className="payment-values col-12 col-lg-4 p-3 d-flex flex-column">
                <div className="col-12 d-flex flex-row">
                    <label className="text-medium col-8">Itens ({purchase?.items?.length || 0}):</label>
                    <label className="text-medium col-4 text-right">
                        R$ {(purchase?.amount || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    </label>
                </div>
                <div className="col-12 d-flex flex-row">
                    <label className="text-medium col-8">Frete e manuseio:</label>
                    <label className="text-medium col-4 text-right">
                        R$ {(purchase?.freight || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    </label>
                </div>
                <div className="col-12 d-flex flex-row">
                    <label className="text-medium col-8">Desconto:</label>
                    <label className="text-medium col-4 text-right">
                        R$ {(purchase?.discount || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    </label>
                </div>
                <div className="border-top py-1 col-12 d-flex flex-row">
                    <label className="text-medium col-8">Total:</label>
                    <label className="text-medium col-4 text-right">
                        <strong>R$ {(purchase?.total || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</strong>
                    </label>
                </div>
                {(purchaseIsOpen || false) && (
                    <div className="border-top col-12 d-flex align-items-center py-1">
                        <label className="text-medium">Aplicar cupom:</label>
                        <input type="text" ref={coupomRef} className="mx-2 col-12 flex-shrink-1" maxLength="30" />
                        <Button
                            name="apply-coupom"
                            color="custom"
                            className="btn btn-secondary"
                            onClick={() => onApplyCoupom(coupomRef.current.value)}
                        >
                            APLICAR
                        </Button>
                    </div>
                )}
                {isLoading && <LoadingSpinner />}
            </div>
            <div className="payment-shipping col-12 col-lg-8 p-3 d-flex flex-wrap">
                <CustomerAddress
                    url={changeAddressUrl}
                    title="Endereço de entrega"
                    address_field="shipping_address"
                    address={purchase?.shipping_address || {}}
                    required={purchase?.has_shipping || false}
                    canChange={purchase?.status === PurchaseStatus.CREATED || false}
                    autoUpdateProfile={false}
                    onAfterChange={() => updateRequest()}
                />
                <CustomerAddress
                    url={changeAddressUrl}
                    title="Endereço de cobrança"
                    address_field="billing_address"
                    address={purchase?.billing_address || {}}
                    required
                    canChange={purchase?.status === PurchaseStatus.CREATED || false}
                    autoUpdateProfile={false}
                    onAfterChange={() => updateRequest()}
                />
            </div>
        </div>
    );
}
