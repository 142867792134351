const PurchaseStatus = {
    CREATED: 'created',
    CLOSED: 'closed',
    FAILED: 'failed',
    PENDING: 'pending',
    EXPIRED: 'expired',
    PAYMENT_FAILED: 'payment_failed',
    PROCESSING: 'processing',
    UNDERPAID: 'underpaid',
    REFUNDED: 'refunded',
    PAID: 'paid',
    CANCELED: 'canceled',
};
export default PurchaseStatus;
