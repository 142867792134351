import React, { useState } from 'react';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';

export default function InstallmentsSelector({
    amount = 0,
    installments = 1,
    installment = 1,
    disabled = false,
    className = '',
    onChooseInstallment = () => {},
}) {
    const floatToCurrency = useFloatToCurrency;
    const [selectedInstallment, setSelectedInstallment] = useState(installment);
    if (installments <= 1) return null;

    return (
        <div className={`d-flex flex-wrap col-12 p-2 ${className}`}>
            <select
                name="installments"
                className="col-12"
                disabled={disabled}
                onChange={(e) => {
                    const installment = parseInt(e.target.value);
                    setSelectedInstallment(installment);
                    onChooseInstallment(installment);
                }}
                value={selectedInstallment}
            >
                {Array(installments)
                    .fill(0)
                    .map((installment, index) => {
                        const installment_amount = (amount || 0) / (index + 1);
                        return (
                            <option key={index} value={index + 1}>
                                {index + 1} parcela{index > 0 ? 's' : ''} de R$ {floatToCurrency(installment_amount)} sem juros
                            </option>
                        );
                    })}
            </select>
        </div>
    );
}
