import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { URLs, useReplaceParams } from '@app/constants';

import Api from '@app/services/Api';
import { Formik } from 'formik';
import { Form } from 'reactstrap';
import * as yup from 'yup';

import FormikDevTools from '@app/components/FormikDevTools';
import { useHistory } from 'react-router';
import SubscriptionPlanDetails from './components/SubscriptionPlanDetails';
import SubscriptionAditionalItems from './components/SubscriptionAditionalItems';
import SubscriptionSummary from './components/SubscriptionSummary';
import SubscriptionUpdateAgreement from './components/SubscriptionUpdateAgreement';

export default function Subscription(props) {
    const replaceParams = useReplaceParams;
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [planParams, setPlanParams] = useState({ token: '', model: '' });
    const [planData, setPlanData] = useState({});

    useEffect(() => {
        if ((props?.match?.params?.token || '') === '') return;
        setPlanParams({
            token: props?.match?.params?.token || '',
            model: props?.match?.params?.model || '',
        });
    }, [props?.match?.params]);

    const loadPlanData = useCallback(
        (params) => {
            setIsLoading(true);
            const url = replaceParams(URLs.club.subscriptions.plans.show, { ...params, nocache: new Date().getTime() });
            Api({ method: 'get', url })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setPlanData(response.data);
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do produto');
                });
        },
        [replaceParams]
    );

    useEffect(() => {
        if (planParams?.token === '') return;
        loadPlanData(planParams);
    }, [planParams, loadPlanData]);

    const handleFormSubmit = (values, { setSubmitting }) => {
        const url = replaceParams(URLs.club.subscriptions.plans.subscribe, {
            token: planParams?.token || '',
            model: planParams?.model || '',
        });

        Api({
            method: 'post',
            url,
            data: values,
        })
            .then(({ status, data }) => {
                setSubmitting(false);

                if (status === 200) {
                    history.push(`/assinatura/checkout/${data.subscription}`);
                } else {
                    toast.error(data?.message || 'Erro inesperado ao finalizar a assinatura!');
                    setSubmitting(false);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao finalizar a assinatura!');
                setSubmitting(false);
            });
    };

    const validations = yup.object().shape({
        update_agreement: yup.bool().oneOf([true], 'Você precisa concordar com a troca do seu plano atual'),
    });

    const initialValues = useMemo(() => {
        if (!planData?.plan) return {};
        const price = planData.plan.prices?.[0] || {};
        const price_id = price?.id || null;
        return {
            token: planData?.plan?.token || '',
            name: planData?.plan?.name || '',
            price_id,
            price,
            update_agreement: (planData?.plan?.current_subscription || null) === null ? 1 : 0,
            current_subscription: planData?.plan?.current_subscription?.token || null,
            items: (planData?.plan?.items || []).map((item) => ({
                description: item.description || item.product.description,
                product_id: item.product_id,
                item_type: item.item_type,
                product_name: item.product.name,
                price_type: item.price_type,
                price_config: item?.price_config || {},
                qtd: item?.qtd || 0,
                price: item?.price || 0.0,
                discount: item?.discount || 0.0,
                amount: item?.amount || 0.0,
                thumb: item?.product?.thumb?.url || '',
            })),
        };
    }, [planData]);

    return (
        <ContentWrapper className="subscription-checkout col-12 flex-shrink-1">
            <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleFormSubmit}
                enableReinitialize
                render={({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="form d-flex flex-wrap py-2">
                        {/* <SubscriptionSummary className="mb-2" style={{ borderBottom: 'inherit' }} updateRequest={updatePlanData} /> */}
                        <div className="d-flex flex-wrap col-12">
                            <div className="col-12 col-md-6 flex-shrink-1 p-1">
                                <SubscriptionPlanDetails
                                    plan={planData?.plan || {}}
                                    price={planData?.plan?.prices?.[0] || {}}
                                    isLoading={isLoading}
                                    className="col-12"
                                />
                            </div>
                            <div className="col-12 col-md-6 flex-shrink-1 p-1">
                                <SubscriptionAditionalItems item_type="optional" className="col-12 br-10" isLoading={isLoading}>
                                    <h3 className="text-center p-2">COMPLEMENTE SEU PLANO</h3>
                                    <p className="text-medium text-center">
                                        Turbine seu plano com os produtos e serviços abaixo, escolhidos especialmente para você.
                                    </p>
                                </SubscriptionAditionalItems>
                                <SubscriptionAditionalItems item_type="bump" className="col-12 br-10 mt-2" isLoading={isLoading}>
                                    <h3 className="text-center p-2">APROVEITE E COMPRE JUNTO</h3>
                                </SubscriptionAditionalItems>
                                <SubscriptionUpdateAgreement subscription={planData?.plan?.current_subscription} />
                                <SubscriptionSummary className="col-12 mt-2 br-10" isLoading={isLoading} />
                            </div>
                        </div>

                        <FormikDevTools />
                    </Form>
                )}
            />
        </ContentWrapper>
    );
}
