import React, { useCallback } from 'react';
import { Table } from 'reactstrap';
import { CustomSwitch } from '@app/components/ui';

import { faCreditCard, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard, faCcAmex, faCcJcb, faCcDinersClub, faCcDiscover } from '@fortawesome/free-brands-svg-icons';

const card_brand_icons = {
    elo: faCreditCard,
    mastercard: faCcMastercard,
    visa: faCcVisa,
    amex: faCcAmex,
    jcb: faCcJcb,
    aura: faCreditCard,
    hipercard: faCreditCard,
    diners: faCcDinersClub,
    discover: faCcDiscover,
};

function DefaultButton({ className = '', isDefault = 0, cardId = null, isSubmitting = false, onChange = () => {} }) {
    return (
        <div className={`user-card-default d-flex justify-content-center ${className}`}>
            <CustomSwitch
                name="set-card-as-default"
                className="justify-content-center"
                title="Usar este cartão como padrão"
                onChange={onChange}
                checked={isDefault === 1 || false}
                isAsync
                disabled={isDefault || isSubmitting || false}
                data={{ isDefault, cardId }}
            />
        </div>
    );
}

export default function CardsTable({ data = null, isSubmitting = false, onSetCardAsDefault = () => {}, onDeleteCard = () => {} }) {
    const getCardBrandIcon = useCallback((brand) => card_brand_icons?.[brand] || faCreditCard, []);

    return (
        <Table striped bordered hover col="12">
            <thead>
                <tr>
                    <th>Cartão</th>
                    <th className="text-center">Padrão</th>
                    <th className="text-center">Excluir</th>
                </tr>
            </thead>
            <tbody>
                {(data || []).length > 0 ? (
                    data?.map((card, index) => (
                        <tr key={index}>
                            <td>
                                <FontAwesomeIcon className="mx-2 user-card-brand" icon={getCardBrandIcon(card.brand)} />
                                <span>{card.description}</span>
                            </td>
                            <td>
                                <DefaultButton
                                    className="col-12"
                                    isDefault={card.default}
                                    cardId={card.id}
                                    isSubmitting={isSubmitting}
                                    onChange={onSetCardAsDefault}
                                />
                            </td>
                            <td align="center">
                                <button
                                    type="button"
                                    className="user-card-delete btn"
                                    onClick={() => onDeleteCard(card.id, card.description)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="3">{data === null ? 'Carregando dados dos cartões' : 'Nenhum cartão encontrado'}</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
