import React, { useMemo } from 'react';
import { Table, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

function getRenewLabel(interval, interval_count) {
    const labels = {
        singular: {
            days: 'dia',
            weeks: 'semana',
            months: 'mês',
            years: 'ano',
        },
        plural: {
            days: 'dias',
            weeks: 'semanas',
            months: 'meses',
            years: 'anos',
        },
    };
    return `A cada ${interval_count} ${labels[interval_count === 1 ? 'singular' : 'plural'][interval]}`;
}

function getStatusColor(status) {
    switch (status) {
        case 'created':
            return 'text-primary';
        case 'processing':
            return 'text-info';
        case 'active':
            return 'text-success';
        case 'expired':
            return 'text-alert';
        case 'payment_failed':
            return 'text-error';
        case 'canceled':
            return 'text-error';
        case 'canceling':
            return 'text-info';
        case 'future':
            return 'text-info';
        default:
            return '';
    }
}

function SubscriptionIncrements({ increments = [] }) {
    const floatToCurrency = useFloatToCurrency;

    const title = useMemo(() => {
        const response = [];
        increments.forEach((increment) => {
            response.push(`${increment.description}: ${increment.cycles}x R$ ${floatToCurrency(increment.value)}`);
        });
        return response.join('\n');
    }, [increments, floatToCurrency]);

    if (increments.length === 0) return 'Nenhum Incremento';

    return (
        <div className="d-flex flex-column" title={title}>
            {increments.map((increment) => (
                <span>
                    {increment.cycles}x R$ ${floatToCurrency(increment.value)}
                </span>
            ))}
        </div>
    );
}
function SubscriptionsTableRow({ subscription = {} }) {
    const floatToCurrency = useFloatToCurrency;

    return (
        <tr>
            <td>{subscription?.description || ''}</td>
            <td className={`fw-bold ${getStatusColor(subscription?.status)}`}>{subscription?.status_label || ''}</td>
            <td>R$ {floatToCurrency(subscription?.amount) || ''}</td>
            <td>
                <SubscriptionIncrements increments={subscription?.increments || []} />
            </td>
            <td align="center">{subscription.current_cycle}</td>
            <td>{subscription?.start_at || ''}</td>
            <td>{getRenewLabel(subscription?.interval, subscription.interval_count)}</td>
            <td>{subscription?.payment_method_label || ''}</td>
            <td align="center">
                <NavLink
                    tag={Link}
                    to={{
                        pathname: `/conta/assinaturas/${subscription.token}`,
                        section: { name: '' },
                    }}
                    className="btn btn-primary py-1 px-2"
                    title="Visualizar detalhes da assinatura"
                >
                    <FontAwesomeIcon icon={faEye} />
                </NavLink>
            </td>
        </tr>
    );
}
export default function SubscriptionsTable({ data }) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <tr>
                    <th>Assinatura</th>
                    <th>Status</th>
                    <th>Valor</th>
                    <th>Incrementos</th>
                    <th>Ciclo Atual</th>
                    <th>Início</th>
                    <th>Renovação</th>
                    <th>Forma de Pagamento</th>
                    <th>Detalhes</th>
                </tr>
            </thead>
            <tbody>
                {(data || []).length > 0 ? (
                    data?.map((subscription, index) => <SubscriptionsTableRow key={index} subscription={subscription} />)
                ) : (
                    <tr>
                        <td colSpan="9" align="center">
                            Nenhuma assinatura encontrada
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
