import React, { useState } from 'react';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import { Button, Form, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import { DropDown, DropdownItem } from '@app/components/Autocomplete';
import { useValidators } from '@app/validators';

import { Field, Formik } from 'formik';
import * as yup from 'yup';

import { URLs, StateAcronymsToName, useReplaceParams } from '@app/constants';
import { useUserManager } from '@app/hooks/useUserManager';

import Api from '@app/services/Api';
import FormikDevTools from '@app/components/FormikDevTools';

function ErrorMessage({ error = undefined, className = '' }) {
    if (error) return <span className={className}>{error}</span>;
    return null;
}

function CertificatesForm(props) {
    const history = useHistory();
    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    const { CPFValidator, CNPJValidator } = useValidators();

    const [modalErrorMessage, setModalErrorMessage] = useState('');
    const [modalSuccessMessage, setModalSuccessMessage] = useState('');

    const handleCertificateSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        const url = URLs.club.certificates.request;
        Api({
            method: 'post',
            url,
            data: values,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    history.push(replaceParams('/certificados/{token}/documentos', { token: response?.data?.cert }));
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar enviar os dados');
                setSubmitting(false);
            });
    };

    /**
     * Consulta por cidades
     */
    const [citiesSuggestions, setCitiesSuggestions] = useState([]);
    const [city_name, setCityName] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const onCitiesSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason !== 'input-changed') return;
        try {
            Api({
                method: 'get',
                url: replaceParams(URLs.services.locations.cities.list, { name: value, state: selectedState }),
            })
                .then((response) => {
                    setCitiesSuggestions(response.data);
                })
                .catch(({ response }) => {
                    const msg = response?.data?.message || 'Erro inesperado ao tentar consultar a cidade.';
                    setModalSuccessMessage('');
                    setModalErrorMessage(msg);
                });
        } catch (error) {
            // console.log(error);
        }
    };

    const handleStateChange = (state, values, setValues) => {
        if (values === null || setValues === null) return;
        setSelectedState(state);
        setValues({ ...values, ...{ city_id: '' } });
        setCityName('');
        setCitiesSuggestions([]);
    };
    // Adiciona o usuário à lista de acesso
    const handleCitySuggestionSelected = (suggestion, values, setValues) => {
        if (values === null || setValues === null) return;
        setValues({ ...values, ...{ city_id: suggestion.id } });
        setCityName(suggestion.name);
        setCitiesSuggestions([]);
        return suggestion.name;
    };
    // Render the suggested user list
    const renderCitiesSuggestions = (suggestion) => (
        <DropdownItem tag="label" className="suggestion-item" key={suggestion.id}>
            {suggestion.name}
        </DropdownItem>
    );

    /**
     * Inicialização e validação do formulário
     */
    const setRandomInitialValues = (values, setValues) => {
        if (values === null || setValues === null) return;
        setSelectedState('SP');
        setCityName('Campinas');
        setValues((prev) => ({
            ...prev,
            ...{
                product_key: 'certificado_a1',
                name: 'Fulano de Tal',
                entity_type: 'company',
                cpf: '799.562.126-44',
                cnpj: '37.136.940/0001-12',
                email: 'allexrm@gmail.com',
                phone: '(19) 98854-4546',
                address: 'Rodovia João Paulo',
                number: '678',
                complement: 'AP 203',
                zip: '88030-300',
                neighborhood: 'João Paulo',
                city_id: '3509502',
            },
        }));
    };

    const certificateValidations = yup.object().shape({
        name: yup.string().min(5, 'O nome deve conter ao menos 5 caracteres').required('É necessário informar um nome.'),
        entity_type: yup.string().required('É necessário informar o tipo de entidade.'),
        cpf: CPFValidator,
        cnpj: yup.string().when(['entity_type'], {
            is: 'company',
            then: CNPJValidator,
            otherwise: yup.string().notRequired(),
        }),
        email: yup.string().email('Formato de email inválido').required('É necessário informar um endereço de email.'),
        phone: yup.string().min(11, 'Informe o número de telefone com o DDD').required('É necessário informar um número de telefone.'),
        address: yup.string().min(5, 'O endereço deve conter ao menos 5 caracteres').required('É necessário informar um endereço.'),
        number: yup.string().required('É necessário informar o número.'),
        zip: yup.string().min(8, 'O CEP deve conter ao menos 8 caracteres').required('É necessário informar o CEP.'),
        neighborhood: yup.string().min(5, 'O bairro deve conter ao menos 5 caracteres').required('É necessário informar o bairro.'),
        city_id: yup.string().required('É necessário selecionar uma cidade.'),
    });

    const certificateInitialValues = {
        product_key: 'certificado_a1',
        name: '',
        entity_type: 'company',
        cpf: '',
        cnpj: '',
        email: '',
        phone: '',
        address: '',
        number: '',
        complement: '',
        zip: '',
        neighborhood: '',
        city_id: '',
    };

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper title="EMITIR NOVO CERTIFICADO">
            <div className="page-content">
                <Formik
                    initialValues={certificateInitialValues}
                    validationSchema={certificateValidations}
                    onSubmit={handleCertificateSubmit}
                    render={(formikProps) => (
                        <Form
                            onSubmit={(e) => {
                                formikProps.handleSubmit(e);
                            }}
                        >
                            {modalErrorMessage && <p className="mb-1 text-error text-small">{modalErrorMessage}</p>}
                            {modalSuccessMessage && <p className="mb-1 text-success text-small">{modalSuccessMessage}</p>}
                            <Field type="hidden" name="product_key" />
                            <div className="d-flex flex-wrap">
                                <div className="col-12 col-sm-6 px-2 py-1">
                                    <Label htmlFor="name">Nome</Label>
                                    <Field name="name" type="text" placeholder="Nome" className="col-12" />
                                    <ErrorMessage error={formikProps?.errors?.name} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-6 px-2 py-1">
                                    <Label htmlFor="email">Email</Label>
                                    <Field name="email" type="email" placeholder="Email" className="col-12" />
                                    <ErrorMessage error={formikProps?.errors?.email} className="text-error text-small" />
                                </div>

                                <div className="col-12 col-sm-6 col-md-3 px-2 py-1">
                                    <Label htmlFor="group_id" title={props?.userData?.group?.id}>
                                        Tipo
                                    </Label>
                                    <Field as="select" name="entity_type" className="col-12" disabled>
                                        <option key="individual" value="individual">
                                            Pessoa Física
                                        </option>
                                        <option key="company" value="company">
                                            Pessoa Jurídica
                                        </option>
                                    </Field>
                                    <ErrorMessage error={formikProps?.errors?.entity_type} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 px-2 py-1">
                                    <Label htmlFor="cpf">CPF</Label>
                                    <InputMask
                                        name="cpf"
                                        type="text"
                                        className="col-12"
                                        mask="999.999.999-99"
                                        placeholder="___.___.___-__"
                                        value={formikProps.values.cpf}
                                        onChange={({ target: { value } }) =>
                                            formikProps.setValues({ ...formikProps.values, ...{ cpf: value } })
                                        }
                                    />
                                    <ErrorMessage error={formikProps?.errors?.cpf} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 px-2 py-1">
                                    <Label htmlFor="cnpj">CNPJ</Label>
                                    <InputMask
                                        name="cnpj"
                                        type="text"
                                        className="col-12"
                                        mask="99.999.999/9999-99"
                                        placeholder="__.___.___/____-__"
                                        value={formikProps.values.cnpj}
                                        onChange={({ target: { value } }) =>
                                            formikProps.setValues({ ...formikProps.values, ...{ cnpj: value } })
                                        }
                                    />
                                    <ErrorMessage error={formikProps?.errors?.cnpj} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 px-2 py-1">
                                    <Label htmlFor="phone">Telefone</Label>
                                    <InputMask
                                        name="phone"
                                        type="text"
                                        className="col-12"
                                        mask="(99) 99999-9999"
                                        placeholder="(__) _____-____"
                                        value={formikProps.values.phone}
                                        onChange={({ target: { value } }) =>
                                            formikProps.setValues({ ...formikProps.values, ...{ phone: value } })
                                        }
                                    />
                                    <ErrorMessage error={formikProps?.errors?.phone} className="text-error text-small" />
                                </div>

                                <div className="col-12 col-sm-8 px-2 py-1">
                                    <Label htmlFor="address">Endereço</Label>
                                    <Field name="address" type="text" placeholder="Endereço" className="col-12" />
                                    <ErrorMessage error={formikProps?.errors?.address} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="number">Número</Label>
                                    <Field name="number" type="text" placeholder="Número" className="col-12" />
                                    <ErrorMessage error={formikProps?.errors?.number} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 px-2 py-1">
                                    <Label htmlFor="complement">Complemento</Label>
                                    <Field name="complement" type="text" placeholder="Complemento" className="col-12" />
                                    <ErrorMessage error={formikProps?.errors?.complement} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 px-2 py-1">
                                    <Label htmlFor="reference">Referência</Label>
                                    <Field name="reference" type="text" placeholder="Referência" className="col-12" />
                                    <ErrorMessage error={formikProps?.errors?.reference} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 px-2 py-1">
                                    <Label htmlFor="zip">CEP</Label>
                                    <InputMask
                                        name="zip"
                                        type="text"
                                        className="col-12"
                                        mask="99 999-999"
                                        placeholder="__ ___-___"
                                        value={formikProps.values.zip}
                                        onChange={({ target: { value } }) =>
                                            formikProps.setValues({ ...formikProps.values, ...{ zip: value } })
                                        }
                                    />
                                    <ErrorMessage error={formikProps?.errors?.zip} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-6 col-md-8 col-lg-4 px-2 py-1">
                                    <Label htmlFor="neighborhood">Bairro</Label>
                                    <Field name="neighborhood" type="text" placeholder="Bairro" className="col-12" />
                                    <ErrorMessage error={formikProps?.errors?.neighborhood} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 px-2 py-1">
                                    <Label htmlFor="group_id" title="Estado">
                                        Estado
                                    </Label>
                                    <Field
                                        as="select"
                                        name="state"
                                        className="col-12"
                                        onChange={(e) => handleStateChange(e.target.value, formikProps.values, formikProps.setValues)}
                                    >
                                        <option key="all_states" value="">
                                            Selecione um estado
                                        </option>
                                        {Object.keys(StateAcronymsToName).map((acronym) => (
                                            <option key={acronym} value={acronym}>
                                                {StateAcronymsToName[acronym]}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage error={formikProps?.errors?.state} className="text-error text-small" />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 px-2 py-1 pb-3">
                                    <Label htmlFor="users">Cidade</Label>
                                    <DropDown
                                        className="col-12"
                                        disabled={selectedState === ''}
                                        name="city_id"
                                        placeholder="Buscar cidade"
                                        selectedValue={city_name}
                                        suggestions={citiesSuggestions}
                                        onSuggestionsFetchRequested={onCitiesSuggestionsFetchRequested}
                                        renderSuggestion={renderCitiesSuggestions}
                                        getSuggestionValue={(suggestion) => {
                                            handleCitySuggestionSelected(suggestion, formikProps.values, formikProps.setValues);
                                        }}
                                        formValues={formikProps.values}
                                        onSetValues={formikProps.setValues}
                                    />
                                    {formikProps?.errors?.city_id && (
                                        <span className="text-error text-small">{formikProps?.errors?.city_id}</span>
                                    )}
                                    <ErrorMessage error={formikProps?.errors?.city_id} className="text-error text-small" />
                                </div>
                                <div className="col-12 mt-3 d-flex">
                                    <div className="mb-3 px-3 col-6 text-center">
                                        <a href="/certificados" className="btn btn-danger mx-2 col-12">
                                            Cancelar
                                        </a>
                                    </div>
                                    <div className="mb-3 col-6 text-center">
                                        <Button type="submit" className="mx-2 col-12" color="primary" disabled={formikProps.isSubmitting}>
                                            Enviar
                                        </Button>
                                    </div>
                                </div>
                                <FormikDevTools>
                                    <div className="col-12 mt-3 d-flex justify-content-center">
                                        <div className="mb-3 px-3 col-12 col-sm-6 col-md-4 text-center">
                                            <Button
                                                type="button"
                                                className="col-12"
                                                color="secondary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setRandomInitialValues(formikProps.values, formikProps.setValues);
                                                }}
                                                disabled={formikProps.isSubmitting}
                                            >
                                                RANDOMIZAR DADOS
                                            </Button>
                                        </div>
                                    </div>
                                </FormikDevTools>
                            </div>
                        </Form>
                    )}
                />
            </div>
        </ContentWrapper>
    );
}

export default CertificatesForm;
