import React from 'react';
import { Row } from 'reactstrap';
import FilterContainer, { FilterFields, FilterTabLink, FilterTabs } from '@app/containers/FilterContainer/FilterContainer';

export default function SubscriptionsTabsMenu({ active = '', children, ...props }) {
    return (
        <FilterContainer showTitle={false} className="show-border" {...props}>
            <FilterFields>
                <Row>{children}</Row>
            </FilterFields>
            <FilterTabs active="subscriptions">
                <FilterTabLink className="px-2" key="subscriptions" active={active === 'subscriptions'} to="/admin/assinaturas">
                    Assinaturas
                </FilterTabLink>
                <FilterTabLink className="px-2" key="plans" active={active === 'plans'} to="/admin/assinaturas/planos">
                    Planos
                </FilterTabLink>
            </FilterTabs>
        </FilterContainer>
    );
}
