import React, { useMemo } from 'react';

import AditionalItemStandardPrice from './AditionalItemStandardPrice';
import AditionalItemListPrice from './AditionalItemListPrice';

export default function SubscriptionAditionalItem({ item = {}, className = '', showThumb = false }) {
    const priceContent = useMemo(() => {
        if (item.price_type === 'standard') {
            return <AditionalItemStandardPrice productId={item.product_id} item={item} amount={item.amount} />;
        }
        if (item.price_type === 'list') {
            return <AditionalItemListPrice productId={item.product_id} item={item} amount={item.amount} />;
        }
    }, [item]);

    return (
        <div className={`col-12 p-2 ${className}`}>
            <div className="d-flex flex-wrap col-12">
                {showThumb && (
                    <div className="d-flex align-items-center justify-content-center p-1 col-12 col-md-2 col-lg-1 product-thumbnail">
                        <img src={item?.thumb || ''} className="col-4 col-md-12" alt="" />
                    </div>
                )}
                <div className="col-12 col-md-10 col-lg-11 product-content">
                    <div>
                        <strong>{item.product_name}</strong>
                    </div>
                    <div className="text-small py-1">{item.description}</div>
                </div>
            </div>
            {priceContent}
        </div>
    );
}
