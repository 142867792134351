import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { usePurchaseManager } from '@app/hooks/';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router';

import { Button, Form } from 'reactstrap';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';

import FormikDevTools from '@app/components/FormikDevTools';
import PaymentMethods from '@app/components/PaymentMethods';
import { CustomerPendenciesBanner } from '@app/components/CustomerProfile';
import { useUserManager } from '@app/hooks/useUserManager';

import OrderContext from '../OrderContext';

import './CheckoutForm.scss';

function SubmitButton({ className = '' }) {
    const { values, isSubmitting } = useFormikContext();

    const [submitDisabled, setSubmitDisabled] = useState(true);
    useEffect(() => {
        setSubmitDisabled(
            values.payment_method === '' ||
                values.captcha_token === '' ||
                (values.payment_method === 'credit_card' && values.user_card_id === '') ||
                (values.payment_method === 'debit_card' && values.user_card_id === '') ||
                isSubmitting
        );
    }, [values, isSubmitting]);

    return (
        <div className={`col-12 my-3 px-2 d-flex justify-content-center ${className}`}>
            <Button
                type="submit"
                color="custom"
                className="btn btn-success col-10 col-md-8 col-lg-6 py-3 text-large text-nowrap"
                disabled={submitDisabled}
            >
                FINALIZAR PEDIDO {isSubmitting && <LoadingSpinner />}
            </Button>
        </div>
    );
}

export default function CheckoutForm({ className = '' }) {
    const history = useHistory();
    const { purchase, setIsLoading, updatePurchaseData } = useContext(OrderContext);
    const PurchaseManager = usePurchaseManager();
    const UserManager = useUserManager();

    const captchaRef = useRef(null);
    const handleCaptchaChange = (token, setFieldValue) => {
        setFieldValue('captcha_token', token);
    };
    const resetCaptchaToken = () => {
        captchaRef.current.reset();
    };

    const checkPendencies = useCallback(() => {
        if (UserManager.hasPendencies) {
            toast.error('Você possui pendências no seu cadastro');
            return false;
        }
        // Checking billing address
        if ((purchase?.billing_address || null) == null) {
            toast.error('É necesssário informar um endereço de cobrança');
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            return false;
        }
        // Checking shipping address
        if ((purchase?.shipping_address || null) == null && purchase.has_shipping) {
            toast.error('É necesssário informar um endereço de entrega');
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            return false;
        }
        return true;
    }, [purchase, UserManager]);

    const handleFormSubmit = (values, { setSubmitting }) => {
        if ((purchase || null) === null) return;
        if (!checkPendencies()) {
            setSubmitting(false);
            return;
        }
        setIsLoading(true);
        PurchaseManager.finishPurchase(values.token, values)
            .then((response) => {
                if (response.status === 200) {
                    history.push('/pedido-realizado');
                } else {
                    toast.error(response?.message || 'Erro inesperado ao finalizar o pedido!');
                    setIsLoading(false);
                    setSubmitting(false);
                    resetCaptchaToken();
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao finalizar o pedido!');
                setIsLoading(false);
                setSubmitting(false);
                resetCaptchaToken();
            });
    };

    const validations = yup.object().shape({
        payment_method: yup.string().required().oneOf(['credit_card', 'debit_card', 'boleto', 'pix']),
        user_card_id: yup.string().when('payment_method', {
            is: 'credit_card' || 'debit_card',
            then: yup.string().required('Selecione um meio de pagamento'),
        }),
        captcha_token: yup.string().required('Clique na cauxa de seleção "Não sou um robô"'),
    });

    const initialValues = {
        token: purchase.token || '',
        payment_method: 'credit_card',
        installments: 1,
        user_card_id: '',
        captcha_token: '',
    };

    return (
        <div className={`d-flex justify-content-center ${className}`}>
            <div className="checkout-form col-12 ">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validations}
                    onSubmit={handleFormSubmit}
                    enableReinitialize
                    render={({ handleSubmit, values, setFieldValue }) => (
                        <Form onSubmit={handleSubmit} className="form d-flex flex-wrap py-2">
                            <PaymentMethods
                                className="col-12 col-dm-6"
                                methods={['credit_card', 'debit_card', 'boleto', 'pix']}
                                paymentMethod={values.payment_method || ''}
                                cardId={values.user_card_id || ''}
                                amount={purchase.amount || 0}
                                onChangePaymentMethod={(method) => {
                                    setFieldValue('payment_method', method);
                                    setFieldValue('user_card_id', '');
                                }}
                                onChangePaymentCard={(id) => setFieldValue('user_card_id', parseInt(id))}
                            />
                            <CustomerPendenciesBanner onSave={updatePurchaseData} />
                            <div className="col-12 justify-content-center mt-3 d-flex">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    ref={captchaRef}
                                    onChange={(token) => handleCaptchaChange(token, setFieldValue)}
                                />
                            </div>
                            <SubmitButton />

                            <FormikDevTools />
                        </Form>
                    )}
                />
            </div>
        </div>
    );
}
