import React, { useContext } from 'react';
import { Table, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, fas } from '@fortawesome/free-solid-svg-icons';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';
import { CustomSwitch } from '@app/components/ui';
import CheckPermission from '@app/components/CheckPermission';

import UserTypesContext from '../components/UserTypesContext';

function UserTypesTable({ handleOrderBy, featureLabels, data }) {
    const context = useContext(UserTypesContext);
    return (
        <Table striped bordered hover col="12">
            <thead>
                <Tr handleOrderBy={handleOrderBy || null}>
                    <Th param="name">Nome</Th>
                    <th>Acessos</th>
                    <Th param="created_at">Data de cadastro</Th>
                    <Th param="status">Ativo</Th>
                    <CheckPermission permission="edit">
                        <th className="text-center">Editar</th>
                    </CheckPermission>
                </Tr>
            </thead>
            <tbody>
                {data?.length > 0 ? (
                    data.map((record, id) => (
                        <tr key={id}>
                            <td data-title="Nome">{record?.name || ''}</td>
                            <td data-title="Acessos">
                                {(record?.sections || []).map((item, index) => (
                                    <Label key={index} className="badge badge-purple mx-1 mb-1" title={item.description}>
                                        <span className="mx-2">{item.name}</span>
                                        {(item?.features || []).map((feature, index) => (
                                            <FontAwesomeIcon
                                                key={index}
                                                icon={fas[featureLabels?.[feature]?.icon || 'faQuestion']}
                                                title={featureLabels?.[feature]?.title || ''}
                                                className="mx-1 text-regular-yellow"
                                            />
                                        ))}
                                    </Label>
                                ))}
                            </td>
                            <td data-title="Data de cadastro">{record?.created_at || ''}</td>
                            <td data-title="Ativo" align="center">
                                <CheckPermission
                                    permission="change-status"
                                    allowed={
                                        <CustomSwitch
                                            name={`user-status-${record?.id}`}
                                            className="justify-content-center"
                                            onChange={context?.handleUserTypeStatusChange}
                                            checked={record?.status === 1 || false}
                                            isAsync
                                            data={{ id: record?.id, status: record?.status }}
                                        />
                                    }
                                    notAllowed={
                                        <label className={record.status === 1 ? 'text-success' : 'text-error'}>
                                            {record.status === 1 ? 'ATIVO' : 'INATIVO'}
                                        </label>
                                    }
                                />
                            </td>
                            <CheckPermission permission="edit">
                                <td data-title="Editar" align="center">
                                    <a
                                        name="edit-product"
                                        href={`/admin/tipos_usuario/alterar/${record?.id}`}
                                        className="btn btn-primary-outline"
                                        title="Editar tipo de usuário"
                                    >
                                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                    </a>
                                </td>
                            </CheckPermission>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={7}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default UserTypesTable;
