/* eslint-disable no-new-func */
import React, { useEffect, useMemo } from 'react';
import { Label } from 'reactstrap';
// import parse from 'html-react-parser';

export default function FunctionField({
    field,
    formikProps: { values, errors, setFieldValue, hidden = false, required = false, label = '' },
}) {
    const fn = useMemo(() => new Function('values', field?.content || 'return null;'), [field]);

    useEffect(() => {
        const newValue = fn(values);
        if (values?.[field.name] !== newValue) setFieldValue(field.name, newValue || undefined);
    }, [fn, values, field, setFieldValue]);

    return (
        <div className={`${field.style} ${hidden ? 'd-none' : ''}`} key={field.name}>
            <Label className="field-label" htmlFor="name">
                {label}
                {required ? <em>*</em> : ''}
            </Label>
            <div className="col-12">{fn(values)}</div>

            {errors?.[field.name] && <span className="col-12 text-error text-small px-2">{errors?.[field.name]}</span>}
        </div>
    );
}
