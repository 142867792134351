import React from 'react';
import CheckboxValidation from './CheckboxValidation';
import DateValidation from './DateValidation';

export default function DateFieldValidations() {
    return (
        <>
            <div className="d-flex align-items-center">
                <CheckboxValidation fieldName="required" label="Obrigatório" />
            </div>
            <div className="d-flex align-items-center">
                <DateValidation fieldName="min" label="Min:" className="d-flex align-items-center p-1" />
                <DateValidation fieldName="max" label="Max:" className="d-flex align-items-center p-1" />
            </div>
        </>
    );
}
