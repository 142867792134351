import React from 'react';
import { Label } from 'reactstrap';
import { useFormikContext } from 'formik';
import { CustomSwitch } from '@app/components/ui';

function FormSlicePaymentMethods({ className = '', isLoading = false }) {
    const { values, errors, setFieldValue } = useFormikContext();

    const handlePaymentMethodsChange = (checked, option) => {
        let payment_methods = values?.payment_methods || [];
        payment_methods = checked ? [...payment_methods, option] : payment_methods.filter((value) => value !== option);
        payment_methods = Array.from(new Set(payment_methods));
        setFieldValue('payment_methods', payment_methods);
    };

    return (
        <div className={`col-12 pt-2 px-1 d-flex flex-wrap align-items-center ${className}`}>
            <Label className="col-12 field-label" htmlFor="private.button_label">
                Métodos de pagamento disponíveis
            </Label>
            <div className="col-12 col-sm-6 pt-2 px-1 d-flex align-items-center">
                <CustomSwitch
                    name="credit_card"
                    className="justify-content-center mx-2"
                    onChange={(e) => handlePaymentMethodsChange(e, 'credit_card')}
                    checked={(values?.payment_methods || []).includes('credit_card')}
                    isAsync={false}
                    disabled={isLoading}
                    data={{ id: values?.token, status: values?.credit_card || false }}
                />
                <Label className="field-label" htmlFor="credit_card">
                    Cartão de crédito.
                </Label>
            </div>
            <div className="col-12 col-sm-6 pt-2 px-1 d-flex align-items-center">
                <CustomSwitch
                    name="boleto"
                    className="justify-content-center mx-2"
                    onChange={(e) => handlePaymentMethodsChange(e, 'boleto')}
                    checked={(values?.payment_methods || []).includes('boleto')}
                    isAsync={false}
                    disabled={isLoading}
                    data={{ id: values?.token, status: values?.boleto || false }}
                />
                <Label className="field-label" htmlFor="boleto">
                    Boleto bancário.
                </Label>
            </div>
            {errors?.payment_methos && <span className="error-message">{errors?.payment_methos}</span>}
        </div>
    );
}
export default FormSlicePaymentMethods;
