import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Field } from 'formik';
import { Button } from 'reactstrap';

import './ui.scss';

import { faEye, faEyeSlash, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export { default as toastConfirmation } from './ToastConfirmation';
export { default as CopyToClipboardField } from './CopyToClipboardField';

/**
 * Retorna o número do telefone solicitado com o link para abertura do whatsapp web com 1 click
 */
export function WhatsappLink({ number }) {
    const cleanNumber = (number || '').replace(/\D/g, '');
    if (cleanNumber === '') return number ?? '';

    const url = `https://web.whatsapp.com/send?phone=${cleanNumber}`;
    return (
        <a href={url} className="whatsapp-link" title="Abrir para conversa no Whats App web" target="_blank" rel="noreferrer">
            {number}
        </a>
    );
}

/**
 * Campos texto com formatação para moeda
 */
export function CurrencyInputField({ field = null, form = null, value = null, onAfterChange = null, ...props }) {
    return (
        <input
            type="text"
            className="text-right"
            {...props}
            value={(field?.value || value || 0).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}
            onFocus={(e) => {
                e.target.select();
            }}
            onChange={(e) => {
                const v = e.target.value.replace(/\D/g, '');
                let floatVal = 0;
                if (v !== '') {
                    floatVal = parseInt(v) / 100;
                    e.target.value = floatVal.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });
                    e.target.floatValue = floatVal;
                }
                if (field !== null && form !== null) form.setFieldValue(field?.name, floatVal);
                if (onAfterChange !== null) onAfterChange(e);
            }}
        />
    );
}
/**
 * Botão de navegação de retorno. Retorna à página anterior
 */
export function NavigateBackButton({ children = null, forceUrl = '', ...props }) {
    const history = useHistory();

    const handleNavigateBackClick = (e) => {
        e.preventDefault();
        if (forceUrl !== '') {
            history.push(forceUrl);
        } else {
            history.goBack();
        }
    };

    return (
        <Button type="button" color="primary" {...props} onClick={handleNavigateBackClick}>
            {children || <FontAwesomeIcon icon={faArrowLeft} className="my-1" />}
        </Button>
    );
}

export function PasswordField({ field, ...props }) {
    const [isVisible, setIsVisible] = useState(false);
    return (
        <div className={`password-field-container ${props?.className || ''}`}>
            <input {...field} {...props} className="password-field" type={isVisible ? 'text' : 'password'} />
            <div
                role="button"
                className="toggle-show-password"
                title={isVisible ? 'Esconder a senha' : 'Exibir a senha'}
                onClick={() => setIsVisible((prev) => !prev)}
                onKeyPress={() => {}}
                tabIndex={0}
            >
                <FontAwesomeIcon icon={isVisible ? faEyeSlash : faEye} className="mx-2" />
            </div>
        </div>
    );
}

export function CustomSwitch({
    name = '',
    data = {},
    checked = false,
    isAsync = false,
    disabled = 0,
    text = '',
    className = '',
    ...props
}) {
    const id = name || `switch-${new Date().getMilliseconds()}`;
    const [isChecked, setIsChecked] = useState(checked || false);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked, id]);

    const setStatus = (val) => {
        setIsChecked(val);
    };
    const onChange = (e) => {
        const newState = !isChecked;
        setIsChecked(newState);
        if (props?.onChange) {
            if (isAsync === true) {
                props?.onChange({
                    ...e,
                    ...{
                        data: { ...data, ...{ checked: newState ? 1 : 0, setStatus } },
                    },
                });
            } else {
                props?.onChange(newState ? 1 : 0);
            }
        }
    };

    return (
        <div
            className={`ui-custom-switch d-flex align-items-center justify-content-center ${className} ${disabled ? 'disabled' : ''}`}
            {...props}
        >
            <label className={`ui-cs-switch ${props?.className || ''} ${isChecked ? 'checked' : ''}`} onClick={disabled ? null : onChange}>
                {text}
            </label>
        </div>
    );
}

export function CustomRadio({ selected, name, value, data = {}, onChange = () => {}, isAsync = false, className = '' }) {
    const [isSelected, setSelected] = useState(selected === value || false);

    const setStatus = (val) => {
        setSelected(val);
    };

    useEffect(() => {
        setSelected(selected === value);
    }, [selected, value]);

    const handleChange = (e) => {
        if (isAsync === true) {
            onChange({
                ...e,
                ...{
                    data: { ...data, ...{ value: value || '', setStatus } },
                },
            });
        } else {
            onChange(value || '');
        }
    };

    return (
        <label className={`ui-custom-radio d-flex ${className} ${isSelected ? 'selected' : ''}`} htmlFor={`${name}_${value}`}>
            <input type="radio" name={name} id={`${name}_${value}`} value={value} onChange={handleChange} />
        </label>
    );
}

export function SelectableInput(props) {
    const [checked, setChecked] = useState(props?.checked || false);
    useEffect(() => {
        setChecked(props?.checked);
    }, [props?.checked]);

    const [disabled, setDisabled] = useState(props?.disabled || false);
    useEffect(() => {
        setDisabled(props?.disabled);
    }, [props?.disabled]);

    const onSelectChange = () => {
        setChecked(!checked);
        if (props?.onSelectChange)
            props?.onSelectChange({
                ...{
                    checked,
                    name: props?.name || 'unknown',
                },
                ...(props?.data || null),
            });
    };

    const fieldHint = useMemo(() => {
        if ((props?.hint || null) === null) return;
        if (typeof props?.hint === 'string') {
            return (
                <span title="" className="si-input-hint">
                    {props?.hint || ''}
                </span>
            );
        }
        return (
            <span
                title={Object.keys(props?.hint || {})
                    .map((hint) => `${hint} = ${props?.hint[hint]}`)
                    .join('\n')}
                className="si-input-hint"
            >
                Múltiplos itens...
            </span>
        );
    }, [props]);

    return (
        <div
            className={`ui-selectable-input d-flex p-0 ${props?.className} ${checked ? 'si-input-selected' : ''} ${
                disabled ? 'disabled' : ''
            }`}
        >
            <label className="si-checkbox">
                <input type="checkbox" name={`checkbox-${props?.name || 'unknown'}`} checked={checked} onChange={onSelectChange} />
            </label>
            <div className="si-label col-3 col-sm-2">
                <label>{props?.label || ''}</label>
            </div>
            <div className="si-input col-9 col-sm-10 flex-shrink-1 d-flex align-items-center">
                <Field
                    className={`col-12 ${checked ? '' : 'si-input-disabled'}`}
                    name={`${props?.name || 'unknown'}`}
                    type="text"
                    placeholder={`${props?.placeholder || ''}`}
                    disabled={!checked}
                />
                {fieldHint}
            </div>
        </div>
    );
}

export function LabelTip({ children, className = '', ...props }) {
    const containerRef = useRef(null);
    const tipRef = useRef(null);

    useEffect(() => {
        //        showTip();
    }, []);
    const handleHideTip = () => {
        tipRef.current.style.display = 'none';
    };
    const showTip = () => {
        tipRef.current.style.display = 'block';
        const tipRect = tipRef.current.getBoundingClientRect();
        const left = parseInt(tipRef.current.style.left) || 0;
        const posX = tipRect.x + tipRect.width + 50 <= window.innerWidth ? left : window.innerWidth - (tipRect.x + tipRect.width + 50);
        tipRef.current.style.left = `${posX}px`;
    };
    const handleShowTip = () => {
        showTip();
    };

    return (
        <div
            ref={containerRef}
            onMouseEnter={handleShowTip}
            onMouseLeave={handleHideTip}
            className={`label-tip ${className || ''}`}
            {...props}
        >
            <span className="label-tip-icon">?</span>
            <span ref={tipRef} className="label-tip-text">
                {children}
            </span>
        </div>
    );
}

export function QuantitySelector({
    onChange = () => {},
    data = {},
    value = 0,
    min = null,
    max = null,
    step = 1,
    disabled = false,
    className = '',
}) {
    const onDecrease = () => onChange({ ...data, value: value - step });
    const onIncrease = () => onChange({ ...data, value: value + step });
    return (
        <div className={`quantity-selector d-flex ${className}`}>
            <button
                type="button"
                className="btn btn-primary btn-decrease"
                name="btn-decrease"
                onClick={onDecrease}
                disabled={disabled || (min !== null && value === min)}
            >
                -
            </button>
            <div className="quantity-selector-value">{value}</div>
            <button
                type="button"
                className="btn btn-primary btn-increase"
                name="btn-decrease"
                onClick={onIncrease}
                disabled={disabled || (max !== null && value === max)}
            >
                +
            </button>
        </div>
    );
}
