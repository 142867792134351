import React from 'react';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import ProductDisplay from '@app/components/Products/ProductDisplays';

import Certificates from './components/Certificates';

function CertificatesHome() {
    const product_filter = {
        by: 'tokens',
        criteria: 'PRODcertificado_a1',
        limit: 1,
        order: '',
    };

    return (
        <ContentWrapper>
            <div className="page-content">
                <div className="d-flex flex-wrap justify-content-center">
                    <h2 className="text-center col-12 bg-primary br-4 p-1 text-white text-large">CERTIFICADO DIGITAL TIPO A1</h2>
                    <div className="col-12 col-md-6 aligm-items-center d-flex flex-column">
                        <p>
                            O Certificado Digital é a identidade digital da pessoa física e jurídica no meio eletrônico, tem a mesma
                            autenticidade e validade jurídica da assinatura em papel e ele permite que diversos serviços sejam realizados
                            sem a necessidade da presença física, o que significa: agilidade nos processos, sustentabilidade e redução de
                            custos.
                        </p>
                        <h3 className="">É muito fácil!</h3>
                        <ol>
                            <li>Adquira seu certificado digital aqui mesmo no Doutor Caminhoneiro.</li>
                            <li>Envie seus dados e fotos dos documentos necessários.</li>
                            <li>Agende uma entrevista com um de nossos avaliadores.</li>
                        </ol>
                        <h3 className="">PRONTO!</h3>
                        <p>Certificado Digital rápido, fácil e barato é squi no Doutor Caminhoneiro.</p>
                        <ProductDisplay
                            type="cards"
                            filter={product_filter}
                            className="justify-content-center gx-3"
                            productClassName="px-1"
                            blockControls={false}
                        />
                    </div>
                    <Certificates className="col-12 col-md-6 aligm-items-center d-flex flex-column" />
                </div>
            </div>
        </ContentWrapper>
    );
}

export default CertificatesHome;
