import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessage, Formik, Form } from 'formik';

import { useUserManager } from '@app/hooks/useUserManager';

import { Container, Button, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import Yup from '@app/validators';
import FormikDevTools from '@app/components/FormikDevTools';

import { AuthFooter, AuthHeader, SideContent, TipMessage } from '../components';

import './PhoneConfirmation.scss';

export default function PhoneConfirmation() {
    const UserManager = useUserManager();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        // setSubmitting(true);
        UserManager.confirmPhoneNumber(values.code).then((response) => {
            const { status, message, url } = response;
            if (status === 200) {
                window.location.href = url;
            } else {
                setSubmitting(false);
                setErrorMessage(message);
                setSuccessMessage('');
            }
        });
    };

    const validations = Yup.object().shape({
        code: Yup.string().length(6, 'Formato de código inválido'),
    });

    const initialValues = {
        code: '',
    };

    return (
        <div className="auth-screen">
            <Container className="col-10" style={{ maxWidth: '1200px' }}>
                <div className="phone-confirmation col-12 justify-content-center">
                    <AuthHeader />
                    <div className="auth-container col-12">
                        <div className="auth-form-container col-12 col-sm-5">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={validations}
                                render={(formikProps) => (
                                    <Form className="login">
                                        <div className="justify-content-center mb-3 col-12">
                                            <div className="col-12">
                                                <h2 className="text-center">Confirmação de Telefone</h2>
                                            </div>
                                        </div>
                                        <p className="my-2 text-medium">
                                            Obrigado por se cadastrar no site do <strong>Doutor Caminhoneiro</strong>. Para prosseguir com
                                            seu cadastro precisamos confirmar seu número de telefone. Este procedimento serve para garantir
                                            a segurança dos seus dados.
                                        </p>
                                        <p className="my-2 text-medium">
                                            Um código de confirmação foi enviado para seu celular via Whatsapp e SMS. Verifique seu aparelho
                                            de celular para obter o código, insira-o abaixo e clique em &quot;ENVIAR&quot; para prosseguir
                                            com seu cadastro.
                                        </p>
                                        <div className="col-12 d-flex flex-column mb-3">
                                            <Label>Código de confirmação:</Label>
                                            <InputMask
                                                name="code"
                                                type="text"
                                                className="confirmation-code-input col-12 text-center"
                                                mask="******"
                                                placeholder="______"
                                                value={formikProps.values.code}
                                                onChange={({ target: { value } }) => {
                                                    formikProps.setFieldTouched('code');
                                                    formikProps.setValues({
                                                        ...formikProps.values,
                                                        ...{ code: value.replaceAll('_', '') },
                                                    });
                                                }}
                                            />
                                            <ErrorMessage component="span" name="code" className="text-error text-small" />
                                        </div>
                                        {errorMessage && <p className="mb-3 message-error text-small">{errorMessage}</p>}
                                        {successMessage && <p className="mb-3 message-success text-small">{successMessage}</p>}
                                        <div className="col-12 d-flex mb-3">
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Button
                                                    type="submit"
                                                    className="col-12"
                                                    color="primary"
                                                    disabled={formikProps.isSubmitting}
                                                >
                                                    ENVIAR
                                                </Button>
                                            </div>
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Link to="/login" className="btn btn-primary-outline col-12">
                                                    VOLTAR PARA LOGIN
                                                </Link>
                                            </div>
                                        </div>
                                        <TipMessage className="text-small my-2">
                                            Surgiu alguma dúvida? Entre em contato conosco através do email{' '}
                                            <a
                                                className="text-light-blue"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="mailto:contato@transprolog.com"
                                            >
                                                contato@transprolog.com
                                            </a>{' '}
                                            ou pelo{' '}
                                            <a
                                                className="text-light-blue"
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://web.whatsapp.com/send?phone=5519993142939"
                                            >
                                                Whatsapp
                                            </a>
                                            .
                                        </TipMessage>
                                        <FormikDevTools />
                                    </Form>
                                )}
                            />
                        </div>
                        <SideContent className="col-12 col-sm-7" />
                    </div>
                </div>
            </Container>
            <AuthFooter />
        </div>
    );
}
