import React from 'react';
import { Table } from 'reactstrap';

function CertificateHistory(props) {
    const { data } = props;
    if (!data || data === null) return null;

    return (
        <Table striped bordered hover col="12" className="text-medium">
            <thead>
                <tr>
                    <th>Data/Hora</th>
                    <th>Responsável</th>
                    <th>Descrição</th>
                    <th>Observações</th>
                </tr>
            </thead>
            <tbody>
                {(data || [])?.length > 0 ? (
                    (data || []).map((record, id) => (
                        <tr key={id}>
                            <td className="text-small">{record?.created_at}</td>
                            <td align="left">{record?.user?.name || ''}</td>
                            <td align="left">{record?.description}</td>
                            <td className="text-small">{record?.obs}</td>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={3} align="center">
                            Nenhum registro encontrado
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default CertificateHistory;
