import React from 'react';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import ContentWrapper from '../shared/ContentWrapper/ContentWrapper';

function Loading() {
    return (
        <ContentWrapper>
            <div className="page-content">
                <div className="d-flex my-3 h-100 col-12 align-items-center justify-content-center">
                    <LoadingSpinner />
                </div>
                <p className="my-3 text-center">
                    Aguarde, o <strong>Doutor Caminhoneiro</strong> está carregando seu conteúdo.
                </p>
            </div>
        </ContentWrapper>
    );
}
export default Loading;
