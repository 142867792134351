import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

export default function StyleguideGeneral() {
    // Código de cores
    const styleColors = ['primary', 'secondary', 'success', 'danger', 'warning'];
    // Código de tamanhos
    const styleSizes = ['large', 'medium', 'small', 'icon-left', 'icon-small'];
    // Código de variações
    const styleVariations = ['', 'outline'];

    return (
        <table className="col-12">
            <thead>
                <tr>
                    <th>Componente</th>
                    <th>Descrição</th>
                    <th>Classes CSS</th>
                </tr>
            </thead>
            <tbody>
                {styleColors.map((color, cId) => (
                    <>
                        {styleSizes.map((size, sId) =>
                            styleVariations.map((variation, vId) => {
                                const text = variation === '' ? color : variation;
                                let content = '';
                                switch (size) {
                                    case 'icon-small':
                                        content = <FontAwesomeIcon icon={faBars} />;
                                        break;
                                    case 'icon-left':
                                        content = (
                                            <>
                                                {' '}
                                                <FontAwesomeIcon icon={faArrowCircleRight} />
                                                {text.toUpperCase()}{' '}
                                            </>
                                        );
                                        break;
                                    default:
                                        content = text.toUpperCase();
                                }
                                //                                        const content = !size.includes('icon') ? text.toUpperCase() : <FontAwesomeIcon icon={ faBars } />;
                                const variationClass = variation === '' ? '' : `-${variation}`;
                                const cssClass = `badge badge-${color}${variationClass} badge-${size}`;
                                return (
                                    <tr key={`${cId}-${sId}-${vId}`}>
                                        <td>
                                            <label className={cssClass}>{content}</label>
                                        </td>
                                        <td>
                                            {color + variationClass} {size}
                                        </td>
                                        <td>{cssClass}</td>
                                    </tr>
                                );
                            })
                        )}
                        <tr key={`${cId}-icon`}>
                            <td>
                                <label className={`badge badge-medium badge-${color}`}>
                                    <FontAwesomeIcon icon={faBars} />
                                </label>
                            </td>
                            <td>{color} medium icon</td>
                            <td>{`badge badge-medium badge-${color}`}</td>
                        </tr>
                        <tr key={`${cId}-icon-outline`}>
                            <td>
                                <label className={`badge badge-medium badge-${color}-outline`}>
                                    <FontAwesomeIcon icon={faBars} />
                                </label>
                            </td>
                            <td>{color}-outline medium icon</td>
                            <td>{`badge badge-medium badge-${color}-outline`}</td>
                        </tr>
                    </>
                ))}
            </tbody>
        </table>
    );
}
