import React, { useContext } from 'react';
import ContentEditorContext from '../ContentEditorContext';

export default function ContentPositionDropdown({ ...props }) {
    const {
        content,
        formik: { values, setValues },
    } = useContext(ContentEditorContext);

    const onChangeColumns = (e) => {
        const newPosition = parseInt(e.target.value) - 1;
        //        const direction = value > position ? 0 : -1;
        setValues((prev) => {
            const newSet = JSON.parse(JSON.stringify(prev.content));
            const contentIndex = newSet.findIndex((item) => item.id === content.id);
            if (contentIndex >= 0) {
                const newContent = newSet.splice(contentIndex, 1)[0];
                newSet.splice(newPosition, 0, newContent);
            }
            return {
                ...prev,
                content: newSet.map((item, index) => ({ ...item, order: index })),
            };
            /*
            const fromIndex = prev.content.findIndex(item => item.id===id);
            for(let p=value+direction; p<prev.content.length; p+=1)
                prev.content[p].order+=1;
            prev.content[fromIndex].order = value;
            prev.content.sort((a,b) => a.order > b.order ? 1 : -1);
            return {
                ...prev,
                content: prev.content
                    .map((item,index) => { return {...item, order: index+1}; })
            };
            */
        });
    };

    return (
        <div className="d-flex align-items-center p-1" title="Posição do conteúdo" {...props}>
            <select name="position" onChange={onChangeColumns} value={content.order + 1} title={`Estou na posição ${content.order}!`}>
                {(values?.content || []).map((item, index) => (
                    <option key={index} value={index + 1}>
                        {index + 1}&ordf; posição ({item.type})
                    </option>
                ))}
            </select>
        </div>
    );
}
