import React, { useState, useEffect, useContext } from 'react';
import { Button, Label, Table } from 'reactstrap';

import { DropdownItem, DropDown } from '@app/components/Autocomplete';

import { toast } from 'react-hot-toast';

import { useUserManager } from '@app/hooks/useUserManager';
import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import ProductsContext from '../ProductsContext';
import ChangePricePopup from '../popups/ChangePricePopup';

function TableHead() {
    return (
        <thead>
            <tr>
                <th className="text-center">Tipo</th>
                <th>Nome</th>
                <th className="text-right">Preço (R$)</th>
                <th className="text-center">Qtd.</th>
                <th className="text-right">Total (R$)</th>
                <th>&nbsp;</th>
                <th>Vendido por</th>
                <th className="text-center">Status</th>
                <th className="text-center">Remover</th>
            </tr>
        </thead>
    );
}

function TableFoot(props) {
    const [productPrice, setProductPrice] = useState(props?.productPrice || 0);
    useEffect(() => {
        setProductPrice(props?.productPrice || 0);
    }, [props]);

    return (
        <tfoot>
            <tr>
                <th className="text-right" colSpan="4">
                    Total (R$)
                </th>
                <th className="text-right">{productPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</th>
                <th colSpan="4">&nbsp;</th>
            </tr>
        </tfoot>
    );
}

function FormSlicePack({ isLoading }) {
    const { values, setValues } = useFormikContext();
    const context = useContext(ProductsContext);
    const replaceParams = useReplaceParams;
    const UserManager = useUserManager();
    const { token } = UserManager;

    // Remove um produto da lista
    const handleRemoveProductClick = (token) => {
        const newList = values.pack?.filter((item) => item.product_id !== token) || [];
        let totalPrice = 0;
        newList?.forEach((item) => {
            totalPrice += item.price;
        });
        setValues((prev) => ({
            ...prev,
            ...{
                pack: newList,
                price: totalPrice,
            },
        }));
    };
    // Inclui um produto na lista
    const handleAddNewProduct = (product) => {
        // Calculando o valor do pacote
        let totalPrice = 0;
        if (
            (values?.pack || []).filter((item) => {
                totalPrice += item.price;
                return item.product_id === product.token;
            }).length > 0
        )
            return;
        totalPrice += product.price;

        setValues((prev) => ({
            ...prev,
            ...{
                pack: [
                    ...prev.pack,
                    ...[
                        {
                            product_id: product?.token,
                            qtd: 1,
                            price: product?.price || 0,
                            product,
                        },
                    ],
                ],
                price: totalPrice,
            },
        }));
    };
    const handleProductPriceChange = (price) => {
        setValues((prev) => {
            const newList = prev?.pack || [];
            let totalPrice = 0;
            newList.forEach((item, index) => {
                if (item.product_id === price.token) {
                    newList[index].qtd = price.qtd;
                    newList[index].price = parseFloat(price.total);
                }
                totalPrice += newList[index].price;
            });
            return {
                ...prev,
                ...{
                    price: totalPrice,
                    pack: newList,
                },
            };
        });
    };

    /**
     * Consulta por produtos
     */
    const params = { type: '', pg: 1, orderBy: 'name', orderDir: 'asc' };
    const [suggestions, setSuggestions] = useState([]);
    const onSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason !== 'input-changed') return;
        try {
            Api({
                method: 'get',
                url: replaceParams(URLs.admin.products.list, {
                    ...params,
                    ...{ name: value, except: values?.token || '', nocache: new Date().getTime() },
                }),
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) => {
                    setSuggestions(response.data?.data || []);
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao tentar consultar o produto.');
                });
        } catch (error) {
            // console.log(error);
        }
    };

    // Adiciona o usuário à lista de acesso
    const handleSuggestionSelected = (suggestion, values, setValues) => {
        if (values === null || setValues === null) return;
        handleAddNewProduct(suggestion);
        setSuggestions([]);
        return suggestion.name;
    };
    // Render the suggested user list
    const renderSuggestions = (suggestion) => (
        <DropdownItem tag="label" className="suggestion-item" key={suggestion.id}>
            {suggestion.name}
        </DropdownItem>
    );

    /**
     * Controles da popup de alteração de preço do produto
     */
    const [selectedProduct, setSelectedProduct] = useState({});
    const [pricePopupIsOpen, setPricePopupIsOpen] = useState(false);
    const openPricePopup = () => {
        setPricePopupIsOpen(true);
    };
    const closePricePopup = () => {
        setPricePopupIsOpen(false);
    };
    const handleChangeProductPrice = (token) => {
        values.pack?.forEach((item) => {
            if (item.product_id === token) {
                setSelectedProduct(Object.assign(item));
                openPricePopup();
            }
        });
    };

    return (
        <>
            <h3 className="field-group-title col-12 mt-3">Produtos do pacote / curso</h3>
            <div className="mb-1 px-1 col-12 d-flex flex-wrap">
                <div className="col-12">
                    <div className="col-12 col-sm-8 py-1 pb-3">
                        <Label htmlFor="city_id">Buscar produto</Label>
                        <DropDown
                            className="col-12"
                            disabled={isLoading}
                            name="product_name"
                            placeholder=""
                            selectedValue=""
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            renderSuggestion={renderSuggestions}
                            getSuggestionValue={(suggestion) => {
                                handleSuggestionSelected(suggestion, values, setValues);
                            }}
                            formValues={values}
                            onSetValues={setValues}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <Table striped hover col="12" className="text-small table-responsive">
                        <TableHead />
                        <tbody>
                            {values.pack.length > 0 ? (
                                values.pack?.map((record, id) => {
                                    const price = record?.product?.price || 0;
                                    const qtd = record?.qtd || 1;
                                    const total = record?.price || 0;
                                    return (
                                        <tr key={id}>
                                            <td className="text-dark-brown" data-title="Tipo">
                                                <div className="d-flex col-12 justify-content-center">
                                                    <FontAwesomeIcon
                                                        className="mr-2"
                                                        title={context.productTypeIcons[record?.product?.product_type]?.title}
                                                        icon={context.productTypeIcons[record?.product?.product_type]?.icon}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ whiteSpace: 'nowrap' }} data-title="Nome">
                                                {record?.product?.name}
                                            </td>
                                            <td align="right" data-title="Preço (R$)">
                                                {price.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                            </td>
                                            <td align="center" data-title="Quantidade">
                                                {qtd}
                                            </td>
                                            <td align="right" data-title="Total (R$)">
                                                {total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                            </td>
                                            <td>
                                                <Button
                                                    name="remove-product"
                                                    color="custom"
                                                    className="btn btn-primary-outline"
                                                    title="Alterar qtd e valor total do produto"
                                                    onClick={() => {
                                                        handleChangeProductPrice(record?.product_id);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                                </Button>
                                            </td>
                                            <td data-title="Fornecedores">{record?.product?.supplier?.name || ''}</td>
                                            <td align="center" data-title="Status">
                                                {record?.status === 1 ? 'ATIVO' : 'INATIVO'}
                                            </td>
                                            <td align="center" data-title="Remover">
                                                <Button
                                                    name="remove-product"
                                                    color="custom"
                                                    className="btn btn-primary-outline"
                                                    title="Remover produto do pacote"
                                                    onClick={() => {
                                                        handleRemoveProductClick(record?.product_id);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr key={0}>
                                    <td colSpan={13} align="center">
                                        Nenhum produto no pacote
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <TableFoot productPrice={(values?.pack || []).length > 0 ? values?.price || 0 : 0} />
                    </Table>
                </div>
            </div>
            <ChangePricePopup
                modalIsOpen={pricePopupIsOpen}
                closeModal={closePricePopup}
                data={selectedProduct}
                onSubmit={handleProductPriceChange}
            />
        </>
    );
}
export default FormSlicePack;
