import React, { useState, useEffect, useContext } from 'react';
import ProductGallery from '@app/components/Products/ProductGallery/ProductGallery';
import Vimeo from '@u-wave/react-vimeo';
import ProductContext from './ProductContext';

function ProductContent({ ...props }) {
    const { product, updateViewingProgress } = useContext(ProductContext);

    const [showVideo, setShowVideo] = useState(false);
    useEffect(() => {
        // console.log('Iniciando o vídeo  em:', product?.progress?.cue || 0);
        // console.log('Progress:', product?.progress);
        setShowVideo(
            product?.product_type === 'video' &&
                product?.user_has &&
                product?.user_has?.extras &&
                (product?.user_has?.extras?.video_url || '') !== ''
        );
    }, [product]);

    const [lastUpdated, setLastUpdated] = useState(null);
    const onVideoPlay = (e) => {
        // console.log('onVideoPlay: ', e);
        setLastUpdated(new Date().getTime());
        updateViewingProgress(e.seconds, e.percent);
    };
    const onVideoEnd = (e) => {
        // console.log('onVideoEnd: ', e);
        updateViewingProgress(e.seconds, e.percent);
    };
    const onVideoSeeked = (e) => {
        // console.log('onSeeked: ', e);
        updateViewingProgress(e.seconds, e.percent);
    };
    const onVideoTimeUpdate = (e) => {
        // console.log('onTimeUpdate: ', e, lastUpdated);
        if (!lastUpdated) return;
        setLastUpdated((current) => {
            const now = new Date().getTime();
            const dif = now - current;
            // console.log('Time difference: ', dif);
            if (dif >= 3000) {
                // console.log('Salvando...');
                updateViewingProgress(e.seconds, e.percent);
                return now;
            }
            return current;
        });
        // updateViewingProgress(e.seconds, e.percent);
    };
    if (showVideo === true)
        return (
            <div className="product-video-container" {...props}>
                <Vimeo
                    className="text-center col-12 product-video-player"
                    video={product?.user_has?.extras?.video_url}
                    start={product?.progress?.cue || 0}
                    onPlay={onVideoPlay}
                    onEnd={onVideoEnd}
                    onSeeked={onVideoSeeked}
                    onTimeUpdate={onVideoTimeUpdate}
                    responsive
                    style={{}}
                />
            </div>
        );
    return <ProductGallery thumb={product?.thumb} gallery={product?.gallery || []} />;
}

export default ProductContent;
