import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import parse from 'html-react-parser';

import { URLs, useReplaceParams } from '@app/constants';

import { SectionContext } from '@app/contexts';
import Api from '@app/services/Api';
import ProductContext from './ProductContext';

import ProductContent from './ProductContent';
import ProductControls from './ProductControls';
import ProductDisplayContext from '../ProductDisplays/ProductDisplayContext';
import ProductHeader from './ProductHeader';

function Product({ data, className = '' }) {
    const { currentSection } = useContext(SectionContext);
    const { blockControls, displayType } = useContext(ProductDisplayContext);
    const replaceParams = useReplaceParams;

    //    const product = data || null;
    const [product, setProduct] = useState(data || {});
    const [expanded, setExpanded] = useState(false);

    const productBodyRef = useRef(null);

    useEffect(() => {
        setProduct(data);
    }, [data]);

    const showPaymentControls = useMemo(
        () =>
            product.price !== 0 &&
            (product?.pack_only || null) === null &&
            ((product?.max_offering || null) === null || parseInt(product?.max_offering) > 0) &&
            (!product?.user_has || (product?.user_has && !product?.offer_once)),
        [product]
    );

    const showFileDownloader = useMemo(
        () =>
            product?.product_type === 'document' &&
            (product?.free_offer || false) &&
            (product?.user_has?.extras || null) !== null &&
            (product?.user_has?.extras?.filename || '') !== '',
        [product]
    );

    const showProductButtonLink = useMemo(() => {
        const remaining = parseInt(product?.user_has?.remaining || 0);
        return (
            (Number(remaining).isNaN || remaining > 0 || product?.free_offer) &&
            (product?.user_has || null) !== null &&
            (product?.user_has?.extras || null) !== null &&
            (product?.user_has?.extras?.url || '') !== ''
        );
    }, [product]);

    const showFooterCustomComponents = useMemo(() => !!product?.custom_components?.footer, [product]);

    const supplierName = useMemo(() => {
        if ((product?.supplier?.name || '') !== '') {
            let supplier_name = product?.custom_supplier_name || product.supplier.name.substring(0, 300);
            supplier_name += supplier_name.length > 200 ? '...' : '';
            return (
                <label className="product-supplier" title={supplier_name}>
                    Vendido por:
                    <span>{supplier_name}</span>
                </label>
            );
        }
    }, [product]);

    const updateProductData = useCallback(() => {
        if (!product?.token) return;
        // Obtém os dados do produto
        const url = replaceParams(URLs.club.sections.products, {
            token: currentSection?.token,
            product: product.token,
            nocache: new Date().getTime(),
        });
        Api({ method: 'get', url }).then((response) => {
            if (response.data && response.status === 200 && (response?.data?.data || []).length > 0) setProduct(response.data.data[0]);
        });
    }, [product, currentSection?.token, replaceParams]);

    const updateViewingProgress = (cue, percent) => {
        if (!product?.token) return;
        // Atualiza progresso de visualização do produto
        const url = replaceParams(URLs.club.products.progress, {
            token: product.token,
            nocache: new Date().getTime(),
        });
        Api({
            method: 'put',
            url,
            data: {
                cue,
                percent: Math.round(percent * 100),
            },
        }).then(({ data, status }) => {
            if (data && status === 200)
                setProduct((prev) => ({
                    ...prev,
                    progress: {
                        cue: data?.cue || 0,
                        progress: data?.progress || 0,
                    },
                }));
        });
    };

    const handleExpandContentClick = () => setExpanded(!expanded);

    return (
        <ProductContext.Provider
            value={{
                product,
                blockControls,
                displayType,

                onExpandContentClick: handleExpandContentClick,
                expanded,
                updateViewingProgress,
                updateRequest: updateProductData,
            }}
        >
            <div
                key={product?.token}
                className={`product product-generic product-type-${product?.product_type} ${className}  ${expanded ? 'expanded' : ''}`}
                style={{ borderColor: 'red' }}
            >
                <ProductHeader>{supplierName}</ProductHeader>

                <div className="product-body" ref={productBodyRef}>
                    <div className="product-description">
                        <p className="text-medium text-description">
                            {product?.description?.substring(0, 200)}
                            {product?.description?.length > 200 ? '...' : ''}
                        </p>
                        <div className="text-medium text-details">{parse(product?.details || '')}</div>
                        {supplierName}
                        <ProductControls
                            product={product}
                            showPaymentControls={showPaymentControls}
                            showFileDownloader={showFileDownloader}
                            showProductButtonLink={showProductButtonLink}
                        />
                    </div>
                    <div className="product-content">
                        <ProductContent />
                    </div>
                </div>
                {showPaymentControls || showFileDownloader || showProductButtonLink || showFooterCustomComponents ? (
                    <div className="product-footer">
                        <ProductControls
                            product={product}
                            showPaymentControls={showPaymentControls}
                            showFileDownloader={showFileDownloader}
                            showProductButtonLink={showProductButtonLink}
                        />
                    </div>
                ) : null}
            </div>
        </ProductContext.Provider>
    );
}

export default Product;
