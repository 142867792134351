import React, { useState, useEffect, useMemo, useCallback } from 'react';
import toast from 'react-hot-toast';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import { useFetch } from '@app/hooks/useFetch';

import { useCartManager } from '@app/hooks/';

import { URLs, useReplaceParams } from '@app/constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateSummary } from '@app/store/SummarySlice';
import ProductDisplay from '@app/components/Products/ProductDisplays';

import { useHistory } from 'react-router';
import CartSummary from './CartSummary';
import CartItems from './CartItems';
import './cart.scss';

function Cart(props) {
    const { cart } = useSelector((state) => state.summary);

    const history = useHistory();
    const replaceParams = useReplaceParams;

    const CartManager = useCartManager();

    const cartDispatch = useDispatch();

    const [params, setParams] = useState({
        name: '',
        nocache: new Date().getTime(),
        pg: 1,
        orderBy: '',
        orderDir: 'asc',
    });

    // Consulta dados do carrinho de compras
    const { data, error, last_page } = useFetch(replaceParams(URLs.club.cart.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
        if (data) cartDispatch(updateSummary({ cart: { qtd: data?.items?.length || 0, amount: data?.amount || 0 } }));
    }, [data, error, cartDispatch]);

    const updateRequest = useCallback(() => setParams((prev) => ({ ...prev, ...{ nocache: new Date().getTime() } })), []);
    useEffect(() => {
        updateRequest();
    }, [cart, updateRequest]);

    /**
     * Inicia pagamento dos itens do carrinho
     */
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const handleCloseCart = () => {
        setIsLoadingPayment(true);
        CartManager.closeCart()
            .then((response) => {
                const { status, message } = response;
                if (status === 200) {
                    history.push(`/checkout/${response?.purchase_id}`);
                } else {
                    toast.error(message || 'Erro ao fechar carrinho de compras!');
                }
                setIsLoadingPayment(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao fechar carrinho de compras!');
                setIsLoadingPayment(false);
            });
    };

    const OrderBump = useMemo(() => {
        if (!data || (data?.items || []).length === 0) return;
        const items = data.items.map((item) => item.product_id);
        return (
            <ProductDisplay
                type="mat"
                filter={{
                    by: 'bump',
                    limit: 5,
                    criteria: items,
                }}
                className="col-12 justify-content-start"
                html={'<h3 class="col-12 mt-3">Produtos que você também pode gostar</h3>'}
                productClassName="mr-1"
                blockControls={false}
            />
        );
    }, [data]);

    const handleNavigateFirst = () => setParams({ ...params, ...{ pg: 1 } });
    const handleNavigateLast = () => setParams({ ...params, ...{ pg: last_page } });
    const handleNavigateNext = () => setParams({ ...params, ...{ pg: data?.items?.length > 0 ? params.pg + 1 : params.pg } });
    const handleNavigatePrevious = () => setParams({ ...params, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } });

    return (
        <ContentWrapper className="cart col-8">
            <h2 className="text-center col-12 bg-primary br-4 p-1 text-white text-large mt-1">MEU CARRINHO DE COMPRAS</h2>
            <CartSummary
                style={{ borderBottom: 'inherit' }}
                payment_url={data?.purchase?.extras?.payment_url || null}
                isLoadingPayment={isLoadingPayment || props?.isLoading || false}
                handleCloseCart={handleCloseCart}
            />
            <CartItems
                isLoading={isLoading}
                isClosed={data?.status === 'closed'}
                items={data?.items}
                updateRequest={updateRequest}
                navigation={{ pg: params?.pg, last_page }}
                handleNavigateFirst={handleNavigateFirst}
                handleNavigateLast={handleNavigateLast}
                handleNavigateNext={handleNavigateNext}
                handleNavigatePrevious={handleNavigatePrevious}
            />
            {OrderBump}
        </ContentWrapper>
    );
}

export default Cart;
