import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Table } from 'reactstrap';
import { CustomSwitch } from '@app/components/ui';

import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { URLs, useSetParams } from '@app/constants';
import Api from '@app/services/Api';
import { toast } from 'react-hot-toast';
import PlanDisplayEditorContext from '../../PlanDisplayEditorContext';

const customStyles = {
    overlay: {
        zIndex: 200,
    },
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function SelectPlansPopup({ isOpen, ...props }) {
    const setParams = useSetParams;
    const {
        content: { filter },
        closeSelectPlansPopup,
        onApplyPlansSelection,
    } = useContext(PlanDisplayEditorContext);

    const [plans, setPlans] = useState([]);

    const loadPlanData = useCallback(() => {
        const params = {
            fields: 'id,name,token,group_id,status,description',
            loads: 'group:token,name',
            orderBy: 'group_id',
        };
        const url = setParams(URLs.admin.subscriptions.plans.basics, params);
        Api({ url })
            .then(({ data, status }) => {
                if (status === 200) {
                    const newPlans = (data || [])
                        .map((item) => {
                            const itemIndex = (filter?.plans || []).findIndex((plan) => plan.id === item.id);
                            return {
                                ...item,
                                selected: itemIndex >= 0,
                                theme: itemIndex >= 0 ? filter.plans[itemIndex].theme : {},
                                order: itemIndex >= 0 ? filter.plans[itemIndex].order : null,
                            };
                        })
                        .sort((a, b) => (a.order === null || a.order > b.order ? 1 : -1));
                    setPlans(newPlans);
                } else {
                    toast.error(data?.message || 'Erro ao obter dados dos planos');
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao obter dados dos planos');
            });
    }, [setParams, filter?.plans]);

    useEffect(() => {
        loadPlanData();
    }, [loadPlanData]);

    const onChangePlanSelection = (checked, id) => {
        setPlans((prev) => prev.map((item) => (item.id === id ? { ...item, selected: checked } : item)));
    };

    const afterOpenModal = () => {
        setPlans(filter?.plans || []);
        loadPlanData();
    };

    const applyPlansSelection = () => {
        const newPlansSet = plans
            .filter((plan) => plan.selected)
            .map((plan, order) => ({
                id: plan.id,
                name: plan.name,
                order,
                theme: plan?.theme || {},
            }));
        onApplyPlansSelection(newPlansSet);
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeSelectPlansPopup}
            style={customStyles}
            contentLabel=""
            ariaHideApp={false}
            {...props}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">SELECIONE OS PRODUTOS QUE DESEJA EXIBIR</h2>
                    <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={closeSelectPlansPopup}>
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    </Button>
                </Col>
            </Row>
            <div className="d-flex flex-wrap col-12 pr-1">
                <div
                    className="col-12"
                    style={{
                        paddingRight: '15px',
                        backgroundColor: '#DFCEB0',
                    }}
                >
                    <Table striped bordered hover className="col-12 m-0">
                        <thead>
                            <tr>
                                <th width="10%">&nbsp;</th>
                                <th width="20%">Nome</th>
                                <th width="40%">Descrição</th>
                                <th width="20%">Grupo</th>
                                <th width="10%">Status</th>
                            </tr>
                        </thead>
                    </Table>
                </div>
                <div
                    className="col-12"
                    style={{
                        maxHeight: '300px',
                        overflowY: 'scroll',
                    }}
                >
                    <Table striped bordered hover className="col-12">
                        <tbody>
                            {plans.map((item, index) => (
                                <tr width="10%" key={index}>
                                    <td data-title="Selecionar">
                                        <CustomSwitch
                                            name={`select-field-${index}`}
                                            className="justify-content-center"
                                            isAsync={false}
                                            checked={item?.selected || false}
                                            data={{ index }}
                                            disabled={item?.disabled}
                                            onChange={(checked) => onChangePlanSelection(checked, item.id)}
                                        />
                                    </td>
                                    <td width="20%" data-title="Nome">
                                        {item.name}
                                    </td>
                                    <td width="40%" data-title="Tipo">
                                        {item?.description}
                                    </td>
                                    <td width="20%" data-title="Fornecedor">
                                        {item?.group?.name}
                                    </td>
                                    <td width="10%" data-title="Status">
                                        {item?.status || false ? 'ATIVO' : 'INATIVO'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="col-12 py-2 d-flex">
                    <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                        <button type="submit" className="btn btn-success col-10" onClick={applyPlansSelection}>
                            APLICAR
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
