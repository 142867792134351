import { ErrorMessage, useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import Api from '@app/services/Api';
import { URLs } from '@app/constants';

import toast from 'react-hot-toast';
import { Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import './PhoneNumberConfirmation.scss';

export default function PhoneNumberConfirmation({ disabled = false }) {
    const { values, isSubmitting, setFieldValue, setFieldTouched } = useFormikContext();

    const checkPhoneNumber = useCallback(() => {
        const url = URLs.club.account.check_phone_changing;
        const data = {
            phone: values.phone,
        };
        Api({ method: 'post', url, data })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response?.data?.message || 'Código enviado, verifique seu celular');
                } else {
                    toast.error(response?.data?.message || 'Houve um erro ao tentar enviar o código de confirmação');
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar enviar o código de confirmação!');
            });
    }, [values]);

    return (
        <div className="account-phone-number-confirmation d-flex flex-wrap justify-content-center p-1">
            <p className="text-medium">
                Detectamos que você está tentando alterar seu número de telefone. Para prosseguir com a alteração precisamos confirmar que
                este número realmente pertence a você. Se deseja prosseguir, clique no botão <strong>&quot;RECEBER CÓDIGO&quot;</strong>{' '}
                abaixo e depois informe o código de confirmação enviado para seu telefone via Whatsapp ou SMS.
            </p>
            <div className="d-flex flex-wrap col-12 col-sm-6 mb-1 ">
                <div className="px-1 d-flex flex-column align-tems-center justify-content-center col-12 col-sm-6">
                    <button
                        type="button"
                        className="btn btn-primary btn-medium"
                        name="send-phone-confirmation-code"
                        onClick={() => {
                            setFieldValue('confirmation_code', '');
                            checkPhoneNumber();
                        }}
                        disabled={disabled || isSubmitting}
                    >
                        RECEBER CÓDIGO
                    </button>
                </div>
                <div className="px-1 col-12 col-sm-6">
                    <Label className="field-label" htmlFor="confirmation_code">
                        Código de Confirmação<em>*</em>
                    </Label>
                    <InputMask
                        name="code"
                        type="text"
                        className="confirmation-code-input col-12 text-center"
                        mask="******"
                        placeholder="______"
                        value={values.confirmation_code}
                        onChange={({ target: { value } }) => {
                            setFieldTouched('code');
                            setFieldValue('confirmation_code', value.replaceAll('_', ''));
                        }}
                    />
                    <ErrorMessage component="span" name="confirmation_code" className="text-error text-small px-2" />
                </div>
            </div>
            <p className="text-small text-red mt-1">
                <strong>ATENÇÃO:</strong> Ao alterar seu número de telefone você estará alterando também a forma como você faz login no site
                do <strong>Doutor Caminhoneiro</strong>.
            </p>
        </div>
    );
}
