import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { DropDown, DropdownItem } from '@app/components/Autocomplete';

import { toast } from 'react-hot-toast';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

function CitiesField({
    field,
    formikProps: { values, errors, setFieldValue, isSubmitting, disabled = false, hidden = false, required = false, label = '' },
}) {
    const replaceParams = useReplaceParams;

    const [refValue, setRefValue] = useState('');
    useEffect(() => {
        if ((field?.ref || '') === '') return;
        const newValue = values?.[field.ref];
        if (newValue === refValue) return;
        setRefValue(newValue);
        setFieldValue(`${field.name}_id`, '');
        setFieldValue(field.name, '');
    }, [values, field, refValue, setFieldValue]);

    /**
     * Consulta por cidades
     */
    const [citiesSuggestions, setCitiesSuggestions] = useState([]);
    const onCitiesSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason !== 'input-changed') return;
        const selectedState = values?.[field?.ref || ''] || '';
        try {
            Api({
                method: 'get',
                url: replaceParams(URLs.services.locations.cities.list, { name: value, state: selectedState || '' }),
            })
                .then((response) => {
                    setCitiesSuggestions(response.data);
                })
                .catch((err) => {
                    toast.error(err?.data?.message || 'Erro inesperado ao tentar consultar a cidade.');
                });
        } catch (error) {
            toast.error('Erro inesperado ao tentar consultar a cidade.');
        }
    };

    // Adiciona o usuário à lista de acesso
    const handleCitySuggestionSelected = (suggestion) => {
        if (values === null || setFieldValue === null) return;
        setFieldValue(`${field.name}_id`, suggestion.id);
        setFieldValue(field.name, suggestion.name);

        setCitiesSuggestions([]);
        return suggestion.name;
    };
    // Render the suggested user list
    const renderCitiesSuggestions = (suggestion) => (
        <DropdownItem tag="label" className="suggestion-item" key={suggestion.id}>
            {suggestion.name}
            {refValue === '' ? ` (${suggestion.state})` : ''}
        </DropdownItem>
    );

    return (
        <div className={`${field.style} ${hidden ? 'd-none' : ''}`} key={field.name}>
            <Label className="field-label" htmlFor={field.name}>
                {label}
                {field?.ref && values?.[field.ref] ? ` em ${values?.[field.ref]}` : ''}
                {required ? <em>*</em> : ''}
            </Label>
            <DropDown
                className="col-12"
                disabled={disabled || (field?.ref && (refValue || '') === '') || isSubmitting || false}
                name={`${field.name}_id`}
                placeholder="Buscar cidade"
                selectedValue={values?.[field.name] || ''}
                suggestions={citiesSuggestions}
                onSuggestionsFetchRequested={onCitiesSuggestionsFetchRequested}
                renderSuggestion={renderCitiesSuggestions}
                getSuggestionValue={(suggestion) => {
                    handleCitySuggestionSelected(suggestion);
                }}
                formValues={values}
            />
            {errors?.[field.name] && <span className="text-error text-small px-2">{errors?.[field.name]}</span>}
        </div>
    );
}

export default CitiesField;
