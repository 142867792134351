import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { URLs, useReplaceParams, CertificatesScheduleConfig } from '@app/constants';
import { useFetch } from '@app/hooks';
import Api from '@app/services/Api';

import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'reactstrap';

import ScheduleTable from './ScheduleTable';

const padTo2Digits = (num) => num.toString().padStart(2, '0');

function Schedule() {
    const replaceParams = useReplaceParams;

    const config = CertificatesScheduleConfig;
    const [calendar, setCalendar] = useState({});

    // Definindo data de início e fim do calendário
    let today = new Date();
    today.setHours(config.startHour, config.startMinutes, 0, 0);
    const diffMillis = today.getDay() * 24 * 60 * 60 * 1000;

    const [datesInterval, setDatesInterval] = useState({
        fromDate: new Date(today.getTime() - diffMillis),
        toDate: new Date(today.getTime() - diffMillis + 6 * 24 * 60 * 60 * 1000),
    });
    const [params, setParams] = useState({
        from: `${padTo2Digits(datesInterval.fromDate.getDate())}${padTo2Digits(
            datesInterval.fromDate.getMonth() + 1
        )}${datesInterval.fromDate.getFullYear()}`,
        to: `${padTo2Digits(datesInterval.toDate.getDate())}${padTo2Digits(
            datesInterval.toDate.getMonth() + 1
        )}${datesInterval.toDate.getFullYear()}`,
        nocache: new Date().getTime(),
    });
    useEffect(() => {
        // Definindo parâmetros de busca
        setParams({
            from: `${padTo2Digits(datesInterval.fromDate.getDate())}${padTo2Digits(
                datesInterval.fromDate.getMonth() + 1
            )}${datesInterval.fromDate.getFullYear()}`,
            to: `${padTo2Digits(datesInterval.toDate.getDate())}${padTo2Digits(
                datesInterval.toDate.getMonth() + 1
            )}${datesInterval.toDate.getFullYear()}`,
            nocache: new Date().getTime(),
        });
    }, [datesInterval]);

    // Montando o vetor de horários do dia
    const endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), config.endHour, config.endMinutes, 0);
    const dayHours = [];
    let cicles = 0;
    while (endDateTime > today && cicles < 100) {
        dayHours.push({
            label: `${padTo2Digits(today.getHours())}:${padTo2Digits(today.getMinutes())}`,
            index: `${padTo2Digits(today.getHours())}${padTo2Digits(today.getMinutes())}`,
        });
        today = new Date(today.getTime() + config.stepSize * 60000);
        cicles += 1;
    }

    // Montando o vetor de dias da semana
    const weekDates = [];
    let date = new Date(datesInterval.fromDate.getTime());
    while (date <= datesInterval.toDate) {
        weekDates.push({
            title: `${padTo2Digits(date.getDate())}/${padTo2Digits(date.getMonth() + 1)}`,
            label: `${padTo2Digits(date.getDate())}/${padTo2Digits(date.getMonth() + 1)}/${date.getFullYear()}`,
            weekDay: date.getDay(),
            index: `${padTo2Digits(date.getDate())}${padTo2Digits(date.getMonth() + 1)}${date.getFullYear()}`,
            today: date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate(),
        });
        date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
    }

    // Navega para a semana anterior
    const handleNavigateLeft = () => {
        setDatesInterval({
            fromDate: new Date(datesInterval.fromDate.getTime() - 7 * 24 * 60 * 60 * 1000),
            toDate: new Date(datesInterval.toDate.getTime() - 7 * 24 * 60 * 60 * 1000),
        });
    };
    // Navega para a próxima semana
    const handleNavigateRight = () => {
        setDatesInterval({
            fromDate: new Date(datesInterval.fromDate.getTime() + 7 * 24 * 60 * 60 * 1000),
            toDate: new Date(datesInterval.toDate.getTime() + 7 * 24 * 60 * 60 * 1000),
        });
    };

    // Requisitando dados do calendário
    const { data, error } = useFetch(replaceParams(URLs.admin.certificates.calendar.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
        const cldr = {};
        if (data && data.map) {
            data.forEach((datetime) => {
                if (!(datetime?.date in cldr)) cldr[datetime?.date] = {};
                cldr[datetime?.date][datetime?.hour] = {
                    status: datetime?.status || 0,
                    certificate: datetime?.certificate || null,
                };
            });
        }
        setCalendar(cldr);
    }, [data, error]);

    const updateRequest = () => setParams({ ...params, ...{ nocache: new Date().getTime() } });

    const [isSubmitting, setSubmitting] = useState(false);
    const handleTableCellStateChange = (e) => {
        setSubmitting(true);

        const url = URLs.admin.certificates.calendar.index;
        Api({
            method: 'put',
            url,
            data: {
                date: e.data.date,
                hour: e.data.hour,
                state: e.data.checked ? 1 : 0,
            },
        })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status do horário.');
                    e.data.setStatus(!e.data.checked);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar enviar os dados');
                setSubmitting(false);
                e.data.setStatus(!e.data.checked);
            });
    };

    /**
     * Solicita a seleção de todos os horários em um determinado dia
     */
    const handleSelectAllAvailableTimes = (day, state = 1) => {
        setSubmitting(true);

        const url = URLs.admin.certificates.calendar.select_times;
        Api({ method: 'post', url, data: { day: day.index, state, hours: dayHours } })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status do horário.');
                } else {
                    updateRequest();
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar enviar os dados');
                setSubmitting(false);
            });
    };
    /**
     * Solicita a seleção de um determinado horário em todos os dias do período
     */
    const handleSelectAllAvailableDays = (hour, state = 1) => {
        setSubmitting(true);

        const url = URLs.admin.certificates.calendar.select_days;
        Api({ method: 'post', url, data: { hour: hour.index, state, days: weekDates } })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status do horário.');
                } else {
                    updateRequest();
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar enviar os dados');
                setSubmitting(false);
            });
    };

    // Funções de alteração dos dados do usuário
    return (
        <>
            <div className="col-12 d-flex align-items-center justify-content-between my-2">
                <Button
                    type="button"
                    className="btn btn-primary-outline btn-icon-left"
                    color="custom"
                    disabled={isLoading}
                    onClick={handleNavigateLeft}
                >
                    <FontAwesomeIcon icon={faCaretLeft} className="" />
                </Button>
                <h4 className="text-center text-medium p-0 m-0">
                    {`De ${datesInterval.fromDate.getDate()}/${
                        datesInterval.fromDate.getMonth() + 1
                    }/${datesInterval.fromDate.getFullYear()}`}
                    {` Até ${datesInterval.toDate.getDate()}/${datesInterval.toDate.getMonth() + 1}/${datesInterval.toDate.getFullYear()}`}
                </h4>
                <Button
                    type="button"
                    className="btn btn-primary-outline btn-icon-left"
                    color="custom"
                    disabled={isLoading}
                    onClick={handleNavigateRight}
                >
                    <FontAwesomeIcon icon={faCaretRight} className="" />
                </Button>
            </div>
            <ScheduleTable
                weekDates={weekDates}
                dayHours={dayHours}
                calendar={calendar}
                isSubmitting={isSubmitting}
                handleTableCellStateChange={handleTableCellStateChange}
                handleSelectAllAvailableTimes={handleSelectAllAvailableTimes}
                handleSelectAllAvailableDays={handleSelectAllAvailableDays}
            />
        </>
    );
}

export default Schedule;
