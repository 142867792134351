import React from 'react';
import { Table } from 'reactstrap';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';
import CheckPermission from '@app/components/CheckPermission';
import CustomerTableRow from './CustomerstableRow';

export default function CustomersTable({
    data,
    handleOrderBy,
    handleCustomerStateChange,
    handleCustomerResetPassword,
    handleCustomerConfirmPhone,
    handleCustomerConfirmEmail,
    handleDeleteCustomer,
}) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <Tr handleOrderBy={handleOrderBy || null}>
                    <Th param="name">Nome</Th>
                    <th>Cidade/Estado</th>
                    <Th param="email">Email</Th>
                    <Th param="email_verified_at">Email verificado</Th>
                    <Th param="phone">Telefone</Th>
                    <Th param="phone_verified_at">Telefone verificado</Th>
                    <Th param="created_at">Data de cadastro</Th>
                    <Th param="status">Status</Th>
                    <CheckPermission permission="reset-password">
                        <th className="text-center">Resetar senha</th>
                    </CheckPermission>
                    <CheckPermission permission="delete">
                        <th className="text-center">Deletar cliente</th>
                    </CheckPermission>
                </Tr>
            </thead>
            <tbody>
                {data?.length > 0 ? (
                    data.map((record, id) => (
                        <CustomerTableRow
                            key={id}
                            customer={record}
                            handleCustomerResetPassword={handleCustomerResetPassword}
                            handleCustomerConfirmEmail={handleCustomerConfirmEmail}
                            handleCustomerConfirmPhone={handleCustomerConfirmPhone}
                            handleCustomerStateChange={handleCustomerStateChange}
                            handleDeleteCustomer={handleDeleteCustomer}
                        />
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={6}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
