import React, { useCallback, useMemo } from 'react';

import { useFormikContext } from 'formik';
import { QuantitySelector } from '@app/components/ui';
import AditionalItemPriceValue from './AditionalItemPriceValue';

export default function AditionalItemStandardPrice({ productId = null, item = {}, amount = 0.0 }) {
    const { setValues } = useFormikContext();

    const price_config = useMemo(() => item?.price_config || {}, [item]);

    const onQuantityChange = useCallback(
        (data) => {
            setValues((prev) => ({
                ...prev,
                items: prev.items.map((item) => {
                    if (item.product_id !== data.productId) return item;
                    let qtd =
                        item.price_config.max_qtd === null || data.value <= item.price_config.max_qtd ? data.value : item.price_config.qtd;
                    qtd =
                        item.price_config.min_qtd === null || data.value >= item.price_config.min_qtd ? data.value : item.price_config.qtd;
                    const discountAfter = item.price_config.discount_after === null ? qtd : item.price_config.discount_after;
                    const discount = item.price_config.discount === null ? 0 : item.price_config.discount;
                    const withDiscount = Math.max(0, qtd - discountAfter);
                    const withoutDiscount = qtd - withDiscount;
                    const { price } = item.price_config;
                    // console.log('Discount After: ', discountAfter);
                    // console.log('Discount: ', discount);
                    // console.log('Price: ', price);
                    // console.log('With Discount: ', withDiscount);
                    // console.log('Without Discount: ', withoutDiscount);

                    const amount = price * withoutDiscount + (price - price * discount) * withDiscount;
                    // console.log('Amount: ', amount);

                    return {
                        ...item,
                        qtd,
                        amount,
                    };
                }),
            }));
        },
        [setValues]
    );
    return (
        <div className="col-12 d-flex flex-wrap align-items-center aditional-item-price_container px-2 pt-2 border br-8 bg-light-blue text-white">
            <AditionalItemPriceValue value={item.price} unitLabel="(un)" className="col-6 col-sm-4 mb-2" />
            <div className="d-flex aditional-item-qtd-selector align-items-center justify-content-center col-6 col-sm-4 mb-2">
                <QuantitySelector
                    data={{ productId }}
                    value={item.qtd}
                    onChange={onQuantityChange}
                    min={price_config.min_qtd}
                    max={price_config.max_qtd}
                />
            </div>
            <AditionalItemPriceValue value={amount} className="justify-content-end col-12 col-sm-4 mb-1" />
        </div>
    );
}
