import React from 'react';
import { Col } from 'reactstrap';
import StyleguideHeader from './components/StyleguideHeader';

function Color({ color, variation }) {
    const textClassName = ['text', variation, color].filter((e) => e !== null).join('-');
    const bgClassName = ['bg', variation, color].filter((e) => e !== null).join('-');

    return (
        <div className="d-flex justify-content-around">
            <label className={`col-4 text-medium ${textClassName}`}>{textClassName}</label>
            <div className={`col-4 ${bgClassName}`} style={{ minHeight: '20px' }} />
        </div>
    );
}
function StyleguideColors() {
    const colorGroups = {
        basics: { colors: ['primary', 'secondary', 'success', 'alert', 'error', 'info', 'black', 'white'], variations: null },
        theme: {
            colors: ['green', 'blue', 'yellow', 'red', 'brown', 'gray', 'orange', 'purple'],
            variations: ['dark', 'regular', 'light', 'hover'],
        },
    };

    return (
        <>
            <StyleguideHeader active="colors" />
            <div className="page-content bg-white d-flex justify-content-evenly">
                {Object.keys(colorGroups).map((title, tIndex) => {
                    const group = colorGroups[title].colors;
                    const { variations } = colorGroups[title];
                    return (
                        <Col key={tIndex} xs="12" sm="6" md="6" lg="4" className="flex-wrap pr-2 pb-2">
                            <h5>{title}</h5>
                            <div className="outer-shadow mr-2 mb-2">
                                {group.map((color, cIndex) => {
                                    if (variations === null) {
                                        return <Color key={cIndex} color={color} variation={null} />;
                                    }
                                    return (
                                        <>
                                            {variations.map((variation, vIndex) => (
                                                <Color key={vIndex} color={color} variation={variation} />
                                            ))}
                                            <br />
                                        </>
                                    );
                                })}
                            </div>
                        </Col>
                    );
                })}
            </div>
        </>
    );
}

export default StyleguideColors;
