import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Button, Label } from 'reactstrap';
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik';
import { CustomSwitch } from '@app/components/ui';
import FormikDevTools from '@app/components/FormikDevTools';
import toast from 'react-hot-toast';
import Api from '@app/services/Api';
import { URLs } from '@app/constants';

function HasChannelObserver() {
    const { values, setFieldValue, setFieldTouched } = useFormikContext();
    useEffect(() => {
        setFieldTouched('hasChannel');
        setFieldValue('hasChannel', values.email_notification || values.whatsapp_notification || values.sms_notification);
    }, [values, setFieldValue, setFieldTouched]);
    return null;
}
export default function ChangeUserConfig() {
    const [configData, setConfigData] = useState(null);
    useEffect(() => {
        const loadConfigdata = () => {
            Api({
                method: 'get',
                url: URLs.club.account.config,
            })
                .then(({ data }) => {
                    setConfigData(data);
                })
                .catch(({ data }) => {
                    toast.error(data?.message || 'Erro ao obter configurações do usuário');
                });
        };
        if (configData) return;
        loadConfigdata();
    }, [configData]);

    /** *****************
     * Save Config
     ****************** */
    const handleConfigSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        Api({
            method: 'put',
            url: URLs.club.account.config,
            data: values,
        })
            .then(({ data, status }) => {
                if (status === 200) {
                    toast.success('Configurações salvas com sucesso!');
                } else {
                    toast.error(data?.message || 'Erro ao salvar dados das configurações!');
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao salvar dados das configurações!');
                setSubmitting(false);
            });
    };

    /** ************************************************************
     * Envia um link de confirmação de endereço de email ao usuário
     ****************** ******************************************** */
    const sendEmailConfirmationLink = useCallback(() => {
        Api({
            method: 'post',
            url: URLs.club.account.send_email_confirmation,
        })
            .then(({ data, status }) => {
                if (status === 200) {
                    toast.success(data?.message || 'Um link de confirmação foi enviado para o seu endereço de e-mail!');
                } else {
                    toast.error(data?.message || 'Erro ao enviar link de confirmação de e-mail!');
                }
            })
            .catch(({ data }) => {
                toast.error(data?.message || 'Erro inesperado ao enviar link de confirmação de e-mail!');
            });
    }, []);

    const passwordValidations = yup.object().shape({
        hasChannel: yup.boolean().isTrue('Ao menos um canal de comunicação deve ser ativado.'),
    });
    const initialConfigValues = {
        email_verified_at: configData?.email_verified_at || null,
        phone_verified_at: configData?.phone_verified_at || null,
        hasChannel: false,
        rpp: configData?.rpp || 30,
        rppi: configData?.rppi || 30,
        email_notification: configData?.email_verified_at !== null && (configData?.email_notification || 0),
        whatsapp_notification: configData?.phone_verified_at !== null && (configData?.whatsapp_notification || 0),
        sms_notification: configData?.sms_notification || 0,
    };

    return (
        <div className="d-flex flex-wrap col-12 justify-content-start mb-3">
            <h3 className="field-group-title col-12">CANAIS DE COMUNICAÇÃO</h3>
            <div className="col-12">
                <Formik
                    initialValues={initialConfigValues}
                    onSubmit={handleConfigSubmit}
                    validationSchema={passwordValidations}
                    enableReinitialize
                >
                    {({ values, setFieldValue, setFieldTouched, isValid, isSubmitting }) => (
                        <Form className="login">
                            <HasChannelObserver />
                            <div className="d-flex flex-wrap mb-3 col-12 align-items-center">
                                <CustomSwitch
                                    name="email_notification"
                                    className="justify-content-center"
                                    onChange={(value) => {
                                        setFieldTouched('email_notification');
                                        setFieldValue('email_notification', value, true);
                                    }}
                                    checked={values.email_notification || false}
                                    isAsync={false}
                                    data={{ status: values.email_notification || false }}
                                    disabled={values.email_verified_at === null || isSubmitting}
                                />
                                <Label htmlFor="email_notification" className="mx-1">
                                    Desejo me comunicar com o site do <strong>Doutor Caminhoneiro</strong> via e-mail.
                                </Label>
                                {values.email_verified_at === null && (
                                    <div className="border p-2 br-10 card">
                                        <p className="mx-1 text-red text-small">
                                            Seu endereço de e-mail ainda não foi verificado. Para ativar as comunicações via e-mail, clique
                                            no botão <strong>&quot;VERIFICAR E-MAIL&quot;</strong> abaixopara receber um link de
                                            verificação.
                                        </p>
                                        <div className="col-12 d-flex-align-items-center justify-content-center">
                                            <Button
                                                type="button"
                                                color="custom"
                                                className="btn btn-secondary"
                                                disabled={isSubmitting}
                                                onClick={() => sendEmailConfirmationLink()}
                                            >
                                                VERIFICAR E-MAIL
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="d-flex flex-wrap mb-3 col-12 align-items-center">
                                <CustomSwitch
                                    name="whatsapp_notification"
                                    className="justify-content-center"
                                    onChange={(value) => {
                                        setFieldTouched('whatsapp_notification');
                                        setFieldValue('whatsapp_notification', value, true);
                                    }}
                                    checked={values.whatsapp_notification || false}
                                    isAsync={false}
                                    data={{ status: values.whatsapp_notification || false }}
                                    disabled={values.phone_verified_at === null || isSubmitting}
                                />
                                <Label htmlFor="whatsapp_notification" className="mx-1">
                                    Desejo me comunicar com o site do <strong>Doutor Caminhoneiro</strong> via Whatsapp{' '}
                                    <strong>(RECOMENDADO)</strong>.
                                </Label>
                            </div>
                            <div className="d-flex flex-wrap mb-1 col-12 align-items-center">
                                <CustomSwitch
                                    name="sms_notification"
                                    className="justify-content-center"
                                    onChange={(value) => {
                                        setFieldTouched('sms_notification');
                                        setFieldValue('sms_notification', value, true);
                                    }}
                                    checked={values.sms_notification || false}
                                    isAsync={false}
                                    data={{ status: values.sms_notification || false }}
                                    disabled={values.phone_verified_at === null || isSubmitting}
                                />
                                <Label htmlFor="sms_notification" className="mx-1">
                                    Desejo me comunicar com o site do <strong>Doutor Caminhoneiro</strong> via SMS.
                                </Label>
                            </div>
                            <div className="col-12">
                                <ErrorMessage component="span" name="hasChannel" className="text-error text-small" />
                            </div>
                            <div className="mb-3 col-12">
                                <Button type="submit" color="custom" className="btn btn-primary" disabled={isSubmitting || !isValid}>
                                    Aplicar Alterações
                                </Button>
                            </div>
                            <FormikDevTools />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
