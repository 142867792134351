import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import StyleguideHeader from './components/StyleguideHeader';

export default function StyleguideButtons() {
    // Código de cores
    const styleColors = ['primary', 'secondary', 'green', 'red', 'yellow', 'blue', 'gray'];
    // Código de tamanhos
    const styleSizes = ['large', 'medium', 'small', 'icon-left', 'icon-small'];
    // Código de variações
    const styleVariations = ['', 'outline'];

    return (
        <>
            <StyleguideHeader active="buttons" />
            <div className="page-content">
                <table className="col-12">
                    <thead>
                        <tr>
                            <th>Componente</th>
                            <th>Descrição</th>
                            <th>Classes CSS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {styleColors.map((color, cId) => (
                            <>
                                {styleSizes.map((size, sId) =>
                                    styleVariations.map((variation, vId) => {
                                        const text = variation === '' ? color : variation;
                                        let content = '';
                                        switch (size) {
                                            case 'icon-small':
                                                content = <FontAwesomeIcon icon={faBars} />;
                                                break;
                                            case 'icon-left':
                                                content = (
                                                    <>
                                                        <FontAwesomeIcon icon={faArrowCircleRight} />
                                                        <span>{text.toUpperCase()}</span>
                                                    </>
                                                );
                                                break;
                                            default:
                                                content = text.toUpperCase();
                                        }
                                        //                                        const content = !size.includes('icon') ? text.toUpperCase() : <FontAwesomeIcon icon={ faBars } />;
                                        const variationClass = variation === '' ? '' : `-${variation}`;
                                        const cssClass = `btn btn-${color}${variationClass} btn-${size}`;
                                        return (
                                            <tr key={`${cId}-${sId}-${vId}`}>
                                                <td>
                                                    <button type="button" className={cssClass}>
                                                        {content}
                                                    </button>
                                                </td>
                                                <td>
                                                    {color + variationClass} {size}
                                                </td>
                                                <td>{cssClass}</td>
                                            </tr>
                                        );
                                    })
                                )}
                                <tr key={`${cId}-icon`}>
                                    <td>
                                        <button type="button" className={`btn btn-medium btn-${color}`}>
                                            <FontAwesomeIcon icon={faBars} />
                                        </button>
                                    </td>
                                    <td>{color} medium icon</td>
                                    <td>{`btn btn-medium btn-${color}`}</td>
                                </tr>
                                <tr key={`${cId}-icon-outline`}>
                                    <td>
                                        <button type="button" className={`btn btn-medium btn-${color}-outline`}>
                                            <FontAwesomeIcon icon={faBars} />
                                        </button>
                                    </td>
                                    <td>{color}-outline medium icon</td>
                                    <td>{`btn btn-medium btn-${color}-outline`}</td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
