import React, { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import FormEditorContext from '../../FormEditorContext';
import FieldSizeSelector from '../FieldSizeSelector';
import FieldPositionSelector from '../FieldPositionSelector';

function GroupEditorToolbar({ field, children }) {
    const { onChangeField, onDeleteField } = useContext(FormEditorContext);
    return (
        <div className="rsw-toolbar field-editor-toolbar d-flex">
            <FieldSizeSelector field={field} className="d-flex flex-wrap" />
            <FieldPositionSelector field={field} />
            <div className="d-flex flex-wrap">
                {field.type !== 'group' && (
                    <button
                        type="button"
                        className="rsw-btn btn-change-field"
                        title={`Editar campo '${field.label}'`}
                        onClick={() => onChangeField(field)}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                )}
                <button
                    type="button"
                    className="rsw-btn btn-change-field"
                    title={`Excluir campo '${field.label}'`}
                    onClick={() => onDeleteField(field)}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
            </div>
            {children}
        </div>
    );
}

export default function GroupEditor({ field, children, customTools = '', ...props }) {
    const { isChanging } = useContext(FormEditorContext);
    return (
        <div {...props} className={`field-editor ${isChanging ? 'is-changing' : ''} ${field.style}`}>
            {isChanging && <GroupEditorToolbar field={field}>{customTools}</GroupEditorToolbar>}
            {children}
        </div>
    );
}
