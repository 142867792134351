import React from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';

const getTextStatusColorClass = (status) => {
    switch (status) {
        case 'error':
            return 'text-error fw-bold';
        case 'processed':
            return 'text-success fw-bold';
        case 'ignored':
            return 'text-alert fw-bold';
        case 'postponed':
            return 'text-info fw-bold';
        default:
            return '';
    }
};

export default function EventsTable({ data, handleOrderBy }) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <Tr handleOrderBy={handleOrderBy || null}>
                    <Th param="event_type">Evento</Th>
                    <Th param="status">Status</Th>
                    <Th param="gateway_created_at">Criado em</Th>
                    <Th param="created_at">Recebido em</Th>
                    <th className="text-center">&nbsp;</th>
                </Tr>
            </thead>
            <tbody>
                {(data || [])?.length > 0 ? (
                    data.map((record, index) => (
                        <tr key={index}>
                            <td>
                                <a href={`/admin/gateway/${record?.id || ''}`} className="text-primary" title="ver detalhes do evento">
                                    {record?.event_type || ''}
                                </a>
                            </td>
                            <td className={`${getTextStatusColorClass(record?.status || '')}`}>{record?.status_label || ''}</td>
                            <td>{record?.gateway_created_at || ''}</td>
                            <td>{record?.created_at || ''}</td>
                            <td align="center">
                                <a href={`/admin/gateway/${record?.id || ''}`} className="text-primary" title="ver detalhes do evento">
                                    <FontAwesomeIcon icon={faEye} className="mr-2" />
                                </a>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={5}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
