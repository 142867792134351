import React from 'react';
import { LabelTip } from '@app/components/ui';
import { useFormDisplayFieldRenderer } from '@app/components/FormDisplay/components/useFormDisplayFieldRenderer';
import FieldEditor from './FieldEditor';
import GroupEditor from './GroupEditor';

const useFormEditorFieldRenderer = {
    newGroupField: (field, props) => {
        const { style } = field;
        return (
            <GroupEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newGroupField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </GroupEditor>
        );
    },
    newStringField: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newStringField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newHtmlField: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newHtmlField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newNumberField: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newNumberField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newIntegerField: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newIntegerField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newFloatField: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newFloatField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newBooleanField: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newBooleanField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newMaskedField: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newMaskedField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newDateField: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newDateField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newStatesField: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newStatesField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newDropdownField: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newDropdownField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newCitiesField: (field, props) => {
        const { style, ref } = field;
        const refAlert = ref ? (
            ''
        ) : (
            <label className="text-error text-small col-12 px-1">
                Este campo não está vinculado a um seletor de estados
                <LabelTip className="px-1">
                    Um busca de cidades precisa estar vinculado a um seletor de estados para que a busca seja filtrada corretamente. Clique
                    no botão &quot;Editar Campo&quot;, selecione um seletor de estados e depois clique em &quot;Aplicar&quot;.
                </LabelTip>
            </label>
        );
        return (
            <FieldEditor field={field} key={field.name} customTools={refAlert}>
                {useFormDisplayFieldRenderer.newCitiesField({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
    newSeparator: (field, props) => {
        const { style } = field;
        return (
            <FieldEditor field={field} key={field.name}>
                {useFormDisplayFieldRenderer.newSeparator({ ...field, style: `${style} col-12 col-sm-12 col-md-12 col-lg-12` }, props)}
            </FieldEditor>
        );
    },
};
export default useFormEditorFieldRenderer;
