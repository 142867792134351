import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import ProductDisplayEditorContext from '../PlanDisplayEditorContext';
import { ContentSizeSelector, ContentPositionDropdown, PlanDisplayTypeDropdown } from '../../CustomTools';

export default function PlanDisplayEditorToolbar({ content, ...props }) {
    const { openSelectPlansPopup } = useContext(ProductDisplayEditorContext);

    return (
        <div className="toolbar d-flex flex-wrap col-12" {...props}>
            <ContentSizeSelector id={content.id} contentClass={content.className} className="d-flex flex-wrap" />
            <ContentPositionDropdown id={content.id} position={content.order} />
            <div className="col-12 d-flex flex-wrap">
                <div className="d-flex flex-column p-1">
                    <label className="text-small pl-1">Expositor:</label>
                    <PlanDisplayTypeDropdown id={content.id} />
                </div>
                <div className="d-flex flex-column p-1">
                    <label className="text-small pl-1 mb-1">&nbsp;</label>
                    <div className="d-flex">
                        <button type="button" className="btn btn-secondary text-medium" onClick={openSelectPlansPopup}>
                            <FontAwesomeIcon icon={faTasks} className="mr-1" />
                            Selecionar Planos
                        </button>
                    </div>
                </div>
            </div>
            {props.children}
        </div>
    );
}
