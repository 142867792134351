import React, { useContext } from 'react';
import { SectionContext } from '@app/contexts';

const PermissionContext = React.createContext();

function CheckPermission({ section_url = null, permission = '', children = null, allowed = null, notAllowed = null }) {
    const CurrentSectionContext = useContext(SectionContext);
    const hasPermission = CurrentSectionContext.checkPermission(permission, section_url);

    return (
        <PermissionContext.Provider
            value={{
                allowed: hasPermission,
            }}
        >
            {hasPermission && children}
            {hasPermission && (allowed || null)}
            {!hasPermission && (notAllowed || null)}
        </PermissionContext.Provider>
    );
}

export default CheckPermission;
