import React from 'react';
import { Table } from 'reactstrap';

const getTextPriorityColorClass = (priority) => {
    switch (priority) {
        case 'high':
            return 'text-error fw-bold';
        case 'medium':
            return 'text-alert fw-bold';
        case 'low':
            return 'text-info fw-bold';
        default:
            return '';
    }
};

export default function EventLogsTable({ data }) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <tr>
                    <th>Data e Hora</th>
                    <th>Prioridade</th>
                    <th>Descrição</th>
                    <th>Detalhes</th>
                </tr>
            </thead>
            <tbody>
                {(data || [])?.length > 0 ? (
                    data.map((record, index) => (
                        <tr key={index}>
                            <td>{record.created_at}</td>
                            <td className={`${getTextPriorityColorClass(record.priority || '')} fw-bold text-center`}>
                                {String(record.priority).toUpperCase()}
                            </td>
                            <td>
                                <span className="col-12 d-flex">{record.description}</span>
                                <span className="badge badge-secondary py-1 px-3" style={{ fontSize: '0.5rem' }}>
                                    {record.source}
                                </span>
                            </td>
                            <td>{record.details}</td>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={5}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
