const URLs = {
    env: 'local',
    base: () => process.env.REACT_APP_API_URL,
    auth: {
        refresh: 'auth/refresh',
        login: 'auth/login',
        logout: 'auth/logout',
        invalidate: 'auth/invalidate',
        forgot: 'auth/forgot',
        validate_code: 'auth/code/validate',
        confirm_phone: 'auth/confirm_number',
        confirm_email: 'auth/confirm_email',
        reset: 'auth/reset',
        reset_password: 'auth/changepassword',
        config: 'auth/config',
        register: 'auth/register',
    },

    admin: {
        dashboard: {
            customers: 'admin/customers/dashboard',
            sales: {
                totals: 'admin/sales/dashboard/totals',
            },
            traffic: {
                totals: 'admin/traffic/dashboard/totals',
                users: 'admin/traffic/users',
            },
            cart: {
                abandoned: 'admin/cart/dashboard',
            },
        },
        traffic: {
            dashboard: 'admin/traffic/dashboard/totals',
            users: 'admin/traffic/users',
            totals: 'admin/traffic/totals',
            actions: 'admin/traffic/actions',
            relationship: 'admin/traffic/relationship',
        },
        users: {
            index: 'admin/users',
            basics: 'admin/users/basics?{nocache}&email={email}&name={name}',
            list: 'admin/users?_{nocache}&name={name}&type={type}&status={status}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            post: 'admin/users',
            put: 'admin/users/{id}',
            export: 'admin/users/export?_{nocache}&name={name}&status={status}&ext={ext}&orderBy={orderBy}&orderDir={orderDir}',
            status: 'admin/users/{id}/status',
            reset: 'admin/users/{id}/reset',
            show: 'admin/users/{id}?{nocache}',
        },
        user_types: {
            index: 'admin/user_types/',
            basics: 'admin/user_types/basics?{nocache}',
            list: 'admin/user_types?_{nocache}&name={name}&status={status}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            post: 'admin/user_types',
            put: 'admin/user_types/{id}',
            status: 'admin/user_types/{id}/status',
            show: 'admin/user_types/{id}?{nocache}',
        },
        gateway: {
            list: 'admin/gateway/events?_{nocache}&status={status}&startDate={startDate}&endDate={endDate}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            show: 'admin/gateway/events/{id}?_{nocache}',
            process: 'admin/gateway/events/{id}/process?_{nocache}',
        },
        whatsapp: {
            list: 'admin/whatsapp/events?_{nocache}&status={status}&startDate={startDate}&endDate={endDate}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            show: 'admin/whatsapp/events/{id}?_{nocache}',
            process: 'admin/whatsapp/events/{id}/process?_{nocache}',
        },
        sections: {
            index: 'admin/sections/{token}',
            list: 'admin/sections?{nocache}&name={name}&context={context}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            post: 'admin/sections',
            put: 'admin/sections/{token}',
            show: 'admin/sections/{token}',
            basics: 'admin/sections/basics?{nocache}&context={context}&type={type}',
            products: 'products?{nocache}&section={section}&by={by}&limit={limit}&criteria={criteria}&order={order}',
            status: 'admin/sections/{token}/status',
            showing: 'admin/sections/{token}/showing',
            reorder: 'admin/sections/{token}/reorder',
            features: 'admin/sections/features',
            partner_logo: 'admin/sections/partner_logo?filename={filename}',
        },
        subscriptions: {
            groups: {
                list: 'admin/plans/groups?{nocache}&name={name}',
                show: 'admin/plans/group/{token}',
                delete: 'admin/plans/group/{token}',
                post: 'admin/plans/groups',
                put: 'admin/plans/group/{token}',
                status: 'admin/plans/group/{token}/status',
            },
            plans: {
                basics: 'admin/plans/basics?name={name}',
                delete: 'admin/plan/{token}',
                post: 'admin/plans',
                put: 'admin/plan/{token}',
                status: 'admin/plan/{token}/status',
                duplicate: 'admin/plan/{token}/duplicate',
                show: 'admin/plan/{token}',
            },
            subscriptions: {
                list: 'admin/subscriptions?_{nocache}&status={status}&startDate={startDate}&endDate={endDate}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
                export: 'admin/subscriptions/export?_{nocache}&status={status}&startDate={startDate}&endDate={endDate}&ext={ext}&orderBy={orderBy}&orderDir={orderDir}',
            },
        },
        products: {
            list: 'admin/products?{nocache}&name={name}&except={except}&type={type}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            basics: 'admin/products/basics',
            post: 'admin/products',
            put: 'admin/product/{token}',
            show: 'admin/product/{token}',
            download: 'admin/product/{token}/download',
            videos: 'admin/products/videos/basics',
            status: 'admin/product/{id}/status',
        },
        suppliers: {
            list: 'admin/suppliers?{nocache}&name={name}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            basics: 'admin/suppliers/basics?{nocache}&name={name}',
            post: 'admin/suppliers',
            put: 'admin/supplier/{token}',
            show: 'admin/supplier/{token}',
            main: 'admin/suppliers/main?{nocache}',
            status: 'admin/supplier/{token}/status',
            pagarme: 'admin/suppliers/pagarme/{id}?{nocache}',
            products: {
                list: 'admin/supplier/{token}/products?{nocache}&name={name}',
                basics: 'admin/supplier/{token}/products/basics?{nocache}&name={name}',
            },
        },
        taxation: {
            list: 'admin/taxation/list',
            post: 'admin/taxation/rules',
            put: 'admin/taxation/rule/{id}',
            delete: 'admin/taxation/rule/{id}',
            status: 'admin/taxation/rule/{id}/status',
            reorder: 'admin/taxation/rule/{id}/reorder',
        },
        purchases: {
            simulate: 'admin/purchases/simulate',
        },
        forms: {
            list: 'admin/forms/{section}',
            show: 'admin/form/{descriptor}?pg={pg}',
            export: 'admin/form/{descriptor}/export?ext={ext}',
        },
        customers: {
            index: 'admin/customers',
            list: 'admin/customers?_{nocache}&name={name}&status={status}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            show: 'admin/customer/{id}',
            delete: 'admin/customer/{id}',
            export: 'admin/customers/export?_{nocache}&name={name}&status={status}&ext={ext}&orderBy={orderBy}&orderDir={orderDir}',
            status: 'admin/customer/{id}/status',
            reset: 'admin/customer/{id}/reset',
            confirm_channel: 'admin/customer/{id}/confirm/{channel}',
        },
        sales: {
            index: 'admin/sales',
            list: 'admin/sales?_{nocache}&status={status}&startDate={startDate}&endDate={endDate}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            export: 'admin/sales/export?_{nocache}&status={status}&startDate={startDate}&endDate={endDate}&ext={ext}&orderBy={orderBy}&orderDir={orderDir}',
            show: 'admin/sale/{token}?_{nocache}',
        },
        certificates: {
            index: 'admin/certificate/{token}?{nocache}',
            list: 'admin/certificates?{nocache}&status={status}&startDate={startDate}&endDate={endDate}&searchBy={searchBy}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            end_review: 'admin/certificate/{token}/review?{nocache}',
            cancel_schedule: 'admin/certificate/{token}/schedule/cancel?{nocache}',
            confirm_interview: 'admin/certificate/{token}/interview/confirm?{nocache}',
            emit: 'admin/certificate/{token}/notify/emission?{nocache}',
            document: {
                review: 'admin/certificate/{token}/document/{document_token}',
                aprove: 'admin/certificate/{token}/document/{document_token}/aprove',
                refuse: 'admin/certificate/{token}/document/{document_token}/refuse',
                require: 'admin/certificate/{token}/documents/require',
                download: 'admin/certificate/{token}/document/{document_token}/download',
            },
            calendar: {
                index: 'admin/certificates/calendar',
                select_times: 'admin/certificates/calendar/select_times',
                select_days: 'admin/certificates/calendar/select_days',
                list: 'admin/certificates/calendar?{nocache}&from={from}&to={to}',
            },
        },
    },

    club: {
        account: {
            index: 'account',
            check_phone_changing: 'account/phone_changing',
            change_address: 'account/change_address',
            change_password: 'account/change_password',
            config: 'account/config',
            send_email_confirmation: 'account/email_confirmation',
            cards: {
                index: 'account/cards',
                default: 'account/cards/{id}/default',
                delete: 'account/cards/{id}',
                list: 'account/cards?card_type={card_type}',
            },
        },
        config: {
            index: 'config/',
        },
        sections: {
            index: 'sections',
            content: {
                list: 'section/{token}/content',
                show: 'section/{token}/content/{id}',
            },
            products: 'products?{nocache}&section={section}&by={by}&limit={limit}&criteria={criteria}&order={order}',
            subscriptions: {
                plans: {
                    list: 'subscriptions/plans?{nocache}&section={section}&by={by}&criteria={criteria}',
                },
                subscriptions: {},
            },
        },
        subscriptions: {
            plans: {
                show: 'subscriptions/plan/{token}/{model}?{nocache}',
                subscribe: 'subscriptions/plan/{token}/{model}/subscribe',
            },
            subscriptions: {
                close: 'subscriptions/{token}/close',
                status: 'subscriptions/{token}/status',
                list: 'account/subscriptions',
                show: 'account/subscriptions/{token}',
                change_card: 'account/subscriptions/{token}/card/change',
                cancel: {
                    request: 'subscriptions/{token}/cancel/request',
                    confirm: 'subscriptions/{token}/cancel/confirm',
                },
            },
        },
        forms: {
            index: 'form/{content}/descriptor/{descriptor}',
            file: 'forms/data/{dataId}/file',
            finish: 'forms/data/{dataId}/finish',
            list: 'forms',
            show: 'forms/show/{descriptor}/{content}',
        },
        cart: {
            list: 'cart?{nocache}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
            add: 'cart/add',
            remove: 'cart/remove',
            update: 'cart/update',
            close: 'cart/close',
        },
        products: {
            list: 'products?{nocache}&section={section}&by={by}&limit={limit}&criteria={criteria}&order={order}',
            show: 'product/{token}?{nocache}',
            pack: 'product/{token}/pack?{nocache}',
            download: 'product/{token}/download',
            progress: 'product/{token}/progress',
        },
        purchases: {
            list: 'purchases?{nocache}&status={status}&year={year}',
            show: 'purchase/{token}/show',
            create: 'purchase/create',
            close: 'purchase/{token}/close',
            finish: 'purchase/{token}/finish',
            status: 'purchase/{token}/status',
            cancel: 'purchase/{token}/cancel',
            coupom: 'purchase/{token}/coupom',
            change_address: 'purchase/{token}/change_address',
        },
        certificates: {
            list: 'certificates?{nocache}&key={key}',
            documents: 'certificates/{token}/documents?{nocache}',
            document: 'certificates/{token}/document/{document_token}',
            request: 'certificates/request',
            calendar: {
                index: 'certificates/calendar',
                list: 'certificates/calendar?{nocache}&from={from}&to={to}',
            },
        },
    },

    services: {
        traffic: {
            index: 'traffic',
        },
        summary: 'summary?{nocache}',
        notifications: {
            list: 'notifications/list?{nocache}&pg={pg}&orderBy={orderBy}&orderDir={orderDir}',
        },
        account: {
            index: 'account?{nocache}',
            cards: {
                index: 'account/cards',
                default: 'account/cards/{id}/default',
                delete: 'account/cards/{id}',
                list: 'account/cards?card_type={card_type}',
            },
            change_address: 'account/change_address',
        },
        locations: {
            states: {
                index: 'services/ufs/',
                list: 'services/ufs/list',
                by_id: 'services/ufs/{state_id}',
                by_acronym: 'services/ufs/{acronym}',
            },
            cities: {
                list: 'services/cities?state={state}&name={name}',
                show: 'services/cities/{id}',
            },
        },
        banks: {
            list: 'services/banks?{nocache}&name={name}',
            show: 'services/bank/{code}',
        },
    },
};

export function useReplaceParams(url, params) {
    let newUrl = url;
    const urlParams = newUrl.match(/(?<=\{).+?(?=\})/g);
    urlParams?.forEach((param) => {
        newUrl = newUrl.replaceAll(`{${param}}`, (params?.[param] || '') !== '' ? params[param] : '');
    });
    return newUrl.replaceAll('?&', '?');
}

export function useSetParams(url, params) {
    const [base_url] = url.split('?');
    const response = `${base_url}?${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`;
    return response.replaceAll('?&', '?');
}

const ProductTypes = {
    object: { label: 'Produtos', title: 'Objetos' },
    media: { label: 'Mídias', title: 'Produtos Digitais' },
    service: { label: 'Serviços', title: 'Serviços' },
};
const MediaTypes = {
    video: 'Vídeo',
    document: 'Documento PDF',
};

const StateAcronymsToName = {
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins',
    DF: 'Distrito Federal',
};

// Nomes dos estados Brasileiros + DF
const StateNames = {
    1: 'Acre',
    2: 'Alagoas',
    3: 'Amapá',
    4: 'Amazonas',
    5: 'Bahia',
    6: 'Ceará',
    7: 'Espírito Santo',
    8: 'Goiás',
    9: 'Maranhão',
    10: 'Mato Grosso',
    11: 'Mato Grosso do Sul',
    12: 'Minas Gerais',
    13: 'Pará',
    14: 'Paraíba',
    15: 'Paraná',
    16: 'Pernambuco',
    17: 'Piauí',
    18: 'Rio de Janeiro',
    19: 'Rio Grande do Norte',
    20: 'Rio Grande do Sul',
    21: 'Rondônia',
    22: 'Roraima',
    23: 'Santa Catarina',
    24: 'São Paulo',
    25: 'Sergipe',
    26: 'Tocantins',
    27: 'Distrito Federal',
};

// Siglas dos estados Brasileiros + DF
const StateAcronyms = {
    1: 'AC',
    2: 'AL',
    3: 'AP',
    4: 'AM',
    5: 'BA',
    6: 'CE',
    7: 'ES',
    8: 'GO',
    9: 'MA',
    10: 'MT',
    11: 'MS',
    12: 'MG',
    13: 'PA',
    14: 'PB',
    15: 'PR',
    16: 'PE',
    17: 'PI',
    18: 'RJ',
    19: 'RN',
    20: 'RS',
    21: 'RO',
    22: 'RR',
    23: 'SC',
    24: 'SP',
    25: 'SE',
    26: 'TO',
    27: 'DF',
};

const MonthNames = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
};

const CertificatesScheduleConfig = {
    startHour: 8, // Horas de início
    startMinutes: 0, // Minutos de início
    endHour: 20, // Horas de término
    endMinutes: 0, // Minutos de término
    stepSize: 60, // Minutos
};

export { URLs, StateNames, StateAcronyms, StateAcronymsToName, MonthNames, ProductTypes, MediaTypes, CertificatesScheduleConfig };
export { default as PopupStyle } from './PopupStyle.contant';
