import React, { useState } from 'react';
import Yup, { useValidators } from '@app/validators';
import Api from '@app/services/Api';
import { URLs, PopupStyle } from '@app/constants';
import { toast } from 'react-hot-toast';

import { Button, Label, Form } from 'reactstrap';

import Modal from 'react-modal';
import { ErrorMessage, Field, Formik } from 'formik';
import InputMask from 'react-input-mask';
import { CustomRadio } from '@app/components/ui';
import FormikDevTools from '@app/components/FormikDevTools';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function PopupAddNewCard({ modalIsOpen, closeModal, updateRequest }) {
    const { CPFValidator, CNPJValidator } = useValidators();

    const afterOpenModal = () => {};

    const [isSubmitting, setSubmitting] = useState(false);
    const handleFormSubmit = (values) => {
        setSubmitting(true);
        const url = URLs.services.account.cards.index;
        Api({ method: 'post', url, data: values })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    closeModal();
                    updateRequest();
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados do fornecedor!');
                setSubmitting(false);
            });
    };

    const validations = Yup.object().shape({
        description: Yup.string()
            .min(5, 'A identificação do cartão deve conter ao menos 5 caracteres')
            .required('É necessário informar uma identificação para o cartão'),
        holder_name: Yup.string()
            .min(5, 'O nome do titular do cartão deve conter ao menos 5 caracteres')
            .required('É necessário informar o nome do titular do cartão'),
        holder_document_type: Yup.string(),
        holder_document: Yup.string().when(['holder_document_type'], {
            is: 'cpf',
            then: CPFValidator,
            otherwise: CNPJValidator,
        }),
        number: Yup.string().creditCardNumber('Formato de cartão inválido').required('É necessário informar o número do cartão'),
        brand: Yup.string().required('É necessário informar a bandeira do cartão'),
        exp_date: Yup.string()
            .creditCardExpirationDate('Data de vencimento inválida')
            .required('É necessário informar o mês de vencimento do cartão'),
        cvv: Yup.string().length(3, 'Informe o código de segurança').required('Informe o código de segurança'),
    });

    const initialValues = {
        description: '',
        card_type: 'credit_card',
        holder_name: '',
        holder_document_type: 'cpf',
        holder_document: '',
        number: '',
        exp_date: '',
        cvv: '',
        brand: '',
    };

    const generateRandomValues = (setValues) => {
        setValues((prev) => ({
            ...prev,
            ...{
                description: 'Cartão VISA - Sucesso',
                card_type: 'credit_card',
                holder_name: 'ALLEX M CAMPOS',
                holder_document_type: 'cpf',
                holder_document: '311.648.048-63',
                number: '4000 0000 0000 0010',
                exp_date: '01/24',
                cvv: '123',
                brand: 'visa',
            },
        }));
    };

    const document_formats = {
        cpf: { mask: '999.999.999-99', placeholder: '___.___.___-__' },
        cnpj: { mask: '99.999.999/9999-99', placeholder: '__.___.___/____-__' },
        individual: { mask: '999.999.999-99', placeholder: '___.___.___-__' },
        company: { mask: '99.999.999/9999-99', placeholder: '__.___.___/____-__' },
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
                ...PopupStyle,
                ...{
                    content: {
                        ...PopupStyle.content,
                        ...{
                            maxWidth: '700px',
                        },
                    },
                },
            }}
            contentLabel="CADASTRAR NOVO CARTÃO"
            className=""
            ariaHideApp={false}
        >
            <div className="col-12 mb-2 d-flex align-items-center">
                <h2 className="flex-grow-1 text-center text-large my-0">CADASTRAR NOVO CARTÃO</h2>
                <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={closeModal}>
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                </Button>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleFormSubmit}
                enableReinitialize
                render={(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                        <div className="col-12 mt-3 d-flex flex-wrap">
                            <div className="mb-1 px-1 col-12">
                                <Label className="field-label" htmlFor="description">
                                    Identificação do cartão<em>*</em>
                                </Label>
                                <Field
                                    name="description"
                                    type="text"
                                    placeholder="Ex: Cartão VISA Bradesco"
                                    className="col-12"
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage component="span" name="description" className="text-error text-small px-2" />
                            </div>
                            <div className="py-2 px-1 col-12 d-flex flex-wrap align-items-center mb-1">
                                <div className="col-4 d-flex align-items-center">
                                    <CustomRadio
                                        name="card_type"
                                        value="credit_card"
                                        selected={formikProps.values.card_type}
                                        onChange={(card_type) => formikProps.setFieldValue('card_type', card_type)}
                                        className="justify-content-center mx-2"
                                        disabled={isSubmitting}
                                        data={{ status: formikProps.values?.card_type }}
                                    />
                                    <Label className="field-label" htmlFor="card_type_credit_card">
                                        Crédito
                                    </Label>
                                </div>
                                <div className="col-4 d-flex align-items-center">
                                    <CustomRadio
                                        name="card_type"
                                        value="debit_card"
                                        selected={formikProps.values.card_type}
                                        onChange={(card_type) => formikProps.setFieldValue('card_type', card_type)}
                                        className="justify-content-center mx-2"
                                        disabled={isSubmitting}
                                        data={{ status: formikProps.values?.card_type }}
                                    />
                                    <Label className="field-label" htmlFor="card_type_debit_card">
                                        Débito
                                    </Label>
                                </div>
                                <div className="col-4 d-flex align-items-center">
                                    <CustomRadio
                                        name="card_type"
                                        value="credit_debit_card"
                                        selected={formikProps.values.card_type}
                                        onChange={(card_type) => formikProps.setFieldValue('card_type', card_type)}
                                        className="justify-content-center mx-2"
                                        disabled={isSubmitting}
                                        data={{ status: formikProps.values?.card_type }}
                                    />
                                    <Label className="field-label" htmlFor="card_type_credit_debit_card">
                                        Crédito e Débito
                                    </Label>
                                </div>
                            </div>
                            <div className="mb-1 px-1 col-12">
                                <Label className="field-label" htmlFor="holder_name">
                                    Nome como está no cartão<em>*</em>
                                </Label>
                                <Field
                                    name="holder_name"
                                    type="text"
                                    placeholder="Nome do titular"
                                    className="col-12"
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage component="span" name="holder_name" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="holder_document_type">
                                    Tipo de documento
                                </Label>
                                <select
                                    name="holder_document_type"
                                    className="col-12"
                                    value={formikProps.values?.holder_document_type || ''}
                                    onChange={(e) =>
                                        formikProps.setValues({ ...formikProps.values, ...{ holder_document_type: e.target.value } })
                                    }
                                    disabled={isSubmitting}
                                >
                                    <option value="cpf">CPF</option>
                                    <option value="cnpj">CNPJ</option>
                                </select>
                                <ErrorMessage component="span" name="holder_document_type" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="holder_document">
                                    Documento do titular<em>*</em>
                                </Label>
                                <InputMask
                                    name="holder_document"
                                    type="text"
                                    className="col-12"
                                    mask={document_formats?.[formikProps.values.holder_document_type]?.mask || ''}
                                    placeholder={document_formats?.[formikProps.values.holder_document_type]?.placeholder || ''}
                                    value={formikProps.values.holder_document}
                                    onChange={({ target: { value } }) => {
                                        formikProps.setFieldTouched('holder_document');
                                        formikProps.setFieldValue('holder_document', value);
                                    }}
                                />
                                <ErrorMessage component="span" name="holder_document" className="text-error text-small px-2" />
                            </div>

                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="number">
                                    Número do cartão<em>*</em>
                                </Label>
                                <InputMask
                                    name="number"
                                    type="text"
                                    className="col-12"
                                    mask="9999 9999 9999 9999"
                                    placeholder="____ ____ ____ ____"
                                    value={formikProps.values.number}
                                    onChange={({ target: { value } }) => {
                                        formikProps.setFieldTouched('number');
                                        formikProps.setFieldValue('number', value);
                                    }}
                                />
                                <ErrorMessage component="span" name="number" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6">
                                <Label className="field-label" htmlFor="brand">
                                    Bandeira<em>*</em>
                                </Label>
                                <select
                                    name="brand"
                                    className="col-12"
                                    value={formikProps.values?.brand || ''}
                                    onChange={(e) => formikProps.setFieldValue('brand', e.target.value)}
                                    disabled={isSubmitting}
                                >
                                    <option value="">&nbsp;</option>
                                    <option value="elo">Elo</option>
                                    <option value="mastercard">Mastercard</option>
                                    <option value="visa">Visa</option>
                                    <option value="amex">American Express</option>
                                    <option value="jcb">JCB</option>
                                    <option value="hipercard">Hipercard</option>
                                    <option value="diners">Diners Club</option>
                                    <option value="discover">Discover</option>
                                </select>
                                <ErrorMessage component="span" name="brand" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-4">
                                <Label className="field-label col-12">
                                    Validade<em>*</em>
                                </Label>
                                <InputMask
                                    name="exp_date"
                                    type="text"
                                    className="col-12"
                                    mask="99/99"
                                    placeholder="MM/YY"
                                    value={formikProps.values.exp_date}
                                    onChange={({ target: { value } }) => {
                                        formikProps.setFieldTouched('exp_date');
                                        formikProps.setFieldValue('exp_date', value);
                                    }}
                                />
                                <ErrorMessage component="span" name="exp_date" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-4">
                                <Label className="field-label col-12">
                                    CVV<em>*</em>
                                </Label>
                                <InputMask
                                    name="cvv"
                                    type="text"
                                    className="col-12"
                                    mask="999"
                                    placeholder="___"
                                    value={formikProps.values.cvv}
                                    onChange={({ target: { value } }) => {
                                        formikProps.setFieldTouched('cvv');
                                        formikProps.setFieldValue('cvv', value);
                                    }}
                                />
                                <ErrorMessage component="span" name="cvv" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-2 col-10 text-small">
                                <strong>
                                    *CVV é o código de segurança do cartão, um código único de 3 digitos encontrado na parte traseira do seu
                                    cartão.
                                </strong>
                            </div>
                        </div>
                        <div className="col-12 mt-3 d-flex flex-wrap">
                            <div className="mb-3 px-1 col-12 col-sm-6 text-center">
                                <Button className="col-10" color="danger" disabled={isSubmitting} onClick={closeModal}>
                                    CANCELAR
                                </Button>
                            </div>
                            <div className="mb-3 px-1 col-12 col-sm-6 text-center">
                                <Button type="submit" className="col-10" color="success" disabled={isSubmitting}>
                                    SALVAR
                                </Button>
                            </div>
                        </div>
                        <FormikDevTools>
                            <div className="col-12 mt-3 d-flex flex-wrap justify-content-center">
                                <div className="mb-3 px-1 col-4 text-center">
                                    <Button
                                        className="col-12"
                                        color="secondary"
                                        disabled={isSubmitting}
                                        onClick={() => generateRandomValues(formikProps.setValues)}
                                    >
                                        RANDOMIZAR
                                    </Button>
                                </div>
                            </div>
                        </FormikDevTools>
                    </Form>
                )}
            />
        </Modal>
    );
}
