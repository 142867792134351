import React, { useEffect, useState } from 'react';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import './CourseItems.scss';
import CourseItem from './CourseItem';

function CourseItems({ courseId = '', ...props }) {
    const replaceParams = useReplaceParams;
    const [products, setProducts] = useState([]);
    useEffect(() => {
        // Consulta os itens do curso
        Api({ method: 'get', url: replaceParams(URLs.club.products.pack, { token: courseId }) })
            .then((response) => {
                if (response.data && response.status === 200) {
                    setProducts(response.data);
                }
            })
            .catch(() => {});
    }, [courseId, replaceParams]);

    return (
        <div className="col-12 d-flex flex-column course-items">
            <h3 className="course-items-title">Conteúdo do Curso</h3>
            {(products || []).map((item, index) => (
                <CourseItem key={index} product={item} {...props} />
            ))}
        </div>
    );
}

export default CourseItems;
