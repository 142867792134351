import React, { useContext, useMemo } from 'react';

import SubscriptionContext from '../SubscriptionContext';
import {
    ChargeStatusActive,
    ChargeStatusCanceled,
    ChargeStatusClosed,
    ChargeStatusExpired,
    ChargeStatusFuture,
    ChargeStatusLoading,
    ChargeStatusPaymentFailed,
    ChargeStatusProcessing,
} from './SubscritionChargeStatus';

export default function SubscriptionCheckoutResume({ className = '' }) {
    const { subscription } = useContext(SubscriptionContext);

    const content = useMemo(() => {
        switch (subscription.status) {
            case 'closed':
                return <ChargeStatusClosed />;
            case 'processing':
                return <ChargeStatusProcessing />;
            case 'active':
                return <ChargeStatusActive start_at={subscription.start_at} next_billing_at={subscription.next_billing_at} />;
            case 'expired':
                return <ChargeStatusExpired />;
            case 'payment_failed':
                return <ChargeStatusPaymentFailed />;
            case 'canceled':
                return <ChargeStatusCanceled />;
            case 'future':
                return <ChargeStatusFuture />;
            default:
                return <ChargeStatusLoading />;
        }
    }, [subscription]);

    return (
        <div className={`checkout-resume d-flex h-100 justify-content-center col-12 ${className}`}>
            <div className="checkout-form col-12">{content}</div>
        </div>
    );
}
