import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import './FormikDevTools.scss';
import ReactJson from 'react-json-view';
import { Button } from 'reactstrap';

export default function FormikDevTools({ className = '', ...props }) {
    const { values, errors } = useFormikContext();
    const [showChildren, setShowChildren] = useState(false);

    if (process.env.NODE_ENV !== 'development') return null;

    return (
        <div {...props} className={`formik-dev-tools px-1 col-12 col-sm-12 ${className}`}>
            <h4 className="title">
                Formik Dev Tools - <span>DEVELOPMENT MODE</span>
            </h4>

            {props?.children && (
                <>
                    <h4 role="presentation" onClick={() => setShowChildren((prev) => !prev)} style={{ cursor: 'pointer' }}>
                        Other tools
                        <FontAwesomeIcon icon={showChildren ? faAngleUp : faAngleDown} className="mx-2" />
                    </h4>
                    <div className="col-12 flex-wrap" style={{ display: showChildren ? 'flex' : 'none' }}>
                        {props.children}
                    </div>
                </>
            )}
            <ReactJson
                src={values || {}}
                theme="bright:inverted"
                displayDataTypes={false}
                name="values"
                collapsed
                style={{
                    fontSize: '0.7rem',
                    padding: '0.7rem',
                    backgroundColor: '#dfceb0',
                    borderBottomWidth: 2,
                    borderBottomColor: '#f0f0f0',
                    borderBottomStyle: 'solid',
                }}
            />
            <ReactJson
                src={errors || {}}
                theme="bright:inverted"
                displayDataTypes={false}
                name="errors"
                collapsed
                style={{
                    fontSize: '0.7rem',
                    padding: '0.7rem',
                    backgroundColor: '#dfceb0',
                }}
            />
        </div>
    );
}

export function RandomValuesButton({ isSubmitting = false, onClick = () => {} }) {
    return (
        <div className="col-12 mt-3 d-flex flex-wrap justify-content-center">
            <div className="mb-3 px-1 col-4 text-center">
                <Button className="col-12" color="secondary" disabled={isSubmitting} onClick={onClick}>
                    RANDOMIZAR DADOS
                </Button>
            </div>
        </div>
    );
}
