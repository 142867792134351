import React, { useContext, useMemo } from 'react';
import { Button } from 'reactstrap';

import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import SubscriptionPlansContext from '../../../components/SubscriptionPlansContext';
import ProductPriceStandardCols from './ProductPriceStandardCols';
import ProductPriceListCols from './ProductPriceListCols';

export default function ProductRow({ data = {}, onChangePriceClick = () => {} }) {
    const { values, setValues } = useFormikContext();
    const { productTypeIcons } = useContext(SubscriptionPlansContext);

    // Remove um produto da lista
    const handleRemoveProductClick = (token) => {
        const newList = values.items?.filter((item) => item.product_id !== token) || [];
        let amount = 0;
        newList?.forEach((item) => {
            amount += item.amount;
        });
        setValues((prev) => ({
            ...prev,
            ...{
                items: newList,
                // prices: recalculatePrices(amount, prev.interval, prev.interval_count, prev.prices),
                price: amount,
            },
        }));
    };

    const PriceRows = useMemo(() => {
        switch (data.price_type) {
            case 'standard':
                return <ProductPriceStandardCols {...(data?.price_config || {})} />;
            default:
                return <ProductPriceListCols prices={data?.price_config || []} />;
        }
    }, [data]);

    const priceTypeLabel = useMemo(() => {
        switch (data.price_type) {
            case 'standard':
                return 'Padrão';
            case 'list':
                return 'Lista';
            default:
                return 'Fixo';
        }
    }, [data]);

    return (
        <tr>
            <td className="text-dark-brown" data-title="Tipo">
                <div className="d-flex col-12 justify-content-center">
                    <FontAwesomeIcon
                        className="mr-2"
                        title={productTypeIcons[data?.product?.product_type]?.title}
                        icon={productTypeIcons[data?.product?.product_type]?.icon}
                    />
                </div>
            </td>
            <td style={{ whiteSpace: 'nowrap' }} data-title="Nome">
                {data?.product?.name}
            </td>
            <td data-title="Nome">{priceTypeLabel}</td>
            {PriceRows}
            <td>
                <Button
                    name="remove-product"
                    color="custom"
                    className="btn btn-primary-outline"
                    title="Alterar qtd e valor total do produto"
                    onClick={() => {
                        onChangePriceClick(data?.product_id);
                    }}
                >
                    <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                </Button>
            </td>
            <td data-title="Fornecedores">{data?.product?.supplier?.name || ''}</td>
            <td align="center" data-title="Status">
                {data?.product?.status === 1 ? 'ATIVO' : 'INATIVO'}
            </td>
            <td align="center" data-title="Remover">
                <Button
                    name="remove-product"
                    color="custom"
                    className="btn btn-primary-outline"
                    title="Remover produto do pacote"
                    onClick={() => {
                        handleRemoveProductClick(data?.product_id);
                    }}
                >
                    <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                </Button>
            </td>
        </tr>
    );
}
