import Yup from '@app/validators';
import * as moment from 'moment';

function conditionalRequired(shape, behavior, message = 'Este campo é obrigatório') {
    let response = shape;
    const behaviors = Object.keys(behavior).filter((key) => ['disabled', 'hidden'].includes(key));
    if (!behaviors.length) return shape.required(message);
    behaviors.forEach((key) => {
        const { field, op, value } = behavior[key];
        switch (op) {
            case '==':
                response = response.when(field, (fieldValue, schema) => {
                    if (fieldValue === value) return schema;
                    return schema.required(message);
                });
                break;
            case '!=':
                response = response.when(field, (fieldValue, schema) => {
                    if (fieldValue !== value) return schema;
                    return schema.required(message);
                });
                break;
            case 'in':
                response = response.when(field, (fieldValue, schema) => {
                    if (Array.isArray(value) && [...value].includes(fieldValue)) return schema;
                    return schema.required(message);
                });
                break;
            case 'nIn':
                response = response.when(field, (fieldValue, schema) => {
                    if (Array.isArray(value) && ![...value].includes(fieldValue)) return schema;
                    return schema.required(message);
                });
                break;
            default:
                break;
        }
    });
    return response;
}

export default function useFormDisplayValidations(fields) {
    const response = {};
    fields.forEach((field) => {
        switch (field.type) {
            case 'mixed':
                response[field.name] = Yup.mixed();
                break;
            case 'file':
                response[field.name] = Yup.object();
                break;
            case 'string':
                response[field.name] = Yup.string();
                break;
            case 'masked':
                response[field.name] = Yup.string();
                break;
            case 'states':
                response[field.name] = Yup.string();
                break;
            case 'dropdown':
                response[field.name] = Yup.string();
                break;
            case 'cities':
                response[field.name] = Yup.string();
                break;
            case 'number':
                response[field.name] = Yup.number();
                break;
            case 'integer':
                response[field.name] = Yup.number();
                break;
            case 'float':
                response[field.name] = Yup.number();
                break;
            case 'boolean':
                response[field.name] = Yup.boolean();
                break;
            case 'date':
                response[field.name] = Yup.date().transform((value, originalValue) =>
                    originalValue ? moment(originalValue, 'DD/MM/YYYY').toDate() : value
                );
                break;
            case 'array':
                response[field.name] = Yup.array();
                break;
            case 'group':
                response[field.name] = Yup.array();
                break;
            case 'object':
                response[field.name] = Yup.object();
                break;
            default:
                response[field.name] = null;
        }
        if (response[field.name] !== null) {
            // const behavior = getBehavior(field?.behavior || {});
            const validations = (field?.validations || '').split('|');
            validations.forEach((item) => {
                const [rule, ...params] = item.split(':');
                if (rule in response[field.name]) {
                    if (rule === 'required') {
                        response[field.name] = conditionalRequired(
                            response[field.name], // current shape
                            field?.behavior || {}, // behaviors
                            params[0] ?? undefined // Message
                        );
                    } else if (params.length > 0) {
                        response[field.name] = response[field.name][rule](...params.map((val) => parseInt(val) || val));
                    } else {
                        response[field.name] = response[field.name][rule]();
                    }
                }
            });
        }
    });
    return Yup.object().shape(response);
}
