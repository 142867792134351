import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import { Form, Label } from 'reactstrap';

import { Field, Formik } from 'formik';
import * as yup from 'yup';

import { URLs, useReplaceParams } from '@app/constants';
import { useUserManager } from '@app/hooks/useUserManager';

import Api from '@app/services/Api';

import { useFetch } from '../../../hooks';
import Schedule from './schedule/Schedule';
import ScheduleConfirmation from './popups/ScheduleConfirmation';

function CertificatesScheduling(props) {
    const history = useHistory();
    const certificate_token = props?.match?.params?.token;

    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    const documentsData = new FormData();

    // Consulta de dados de sessões
    const [params, setParams] = useState({ token: certificate_token, nocache: new Date().getTime() });
    const certificate = useFetch(replaceParams(URLs.club.certificates.documents, params));

    const updateRequest = () => setParams({ ...params, ...{ nocache: new Date().getTime() } });

    const certificateFAddress = () => {
        if (!certificate?.data) return '';
        let res = '';
        res += certificate?.data?.address || '';
        res += certificate?.data?.number ? `,${certificate?.data?.number}` : '';
        res += certificate?.data?.complement ? `,${certificate?.data?.complement}` : '';
        res += certificate?.data?.reference ? `,${certificate?.data?.reference}` : '';
        return res;
    };

    const handleCertificateDocumentsSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);

        const url = replaceParams(URLs.club.certificates.documents, { token: certificate_token });
        Api({
            method: 'post',
            url,
            data: documentsData,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (response.data && response.status === 200) {
                    if (response?.data?.remaining === 0) {
                        toast.success('Documentos enviados com sucesso. Aguarde a revisão dos documentos.');
                        history.push('/certificados');
                    } else {
                        toast.success(response.data.message);
                        updateRequest();
                    }
                } else {
                    toast.error(response.data.message || 'Houve um erro ao tentar enviar o arquivo!');
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar enviar o arquivo');
                setSubmitting(false);
            });
    };

    /**
     * Inicialização e validação do formulário
     */
    const certificateDocumentsValidations = yup.object().shape({});

    const certificateDocumentsInitialValues = {};

    /**
     * Confirmação do agendamento
     */
    const [scheduleData, setScheduleData] = useState({
        token: certificate_token,
        name: certificate?.data?.name,
        document: certificate?.data?.document,
        phone: certificate?.data?.phone,
        date: '',
        hour: '',
    });
    const [formModalIsOpen, setFormModalIsOpen] = useState(false);
    const openFormModal = useCallback(() => setFormModalIsOpen(true), []);
    const closeFormModal = useCallback(() => setFormModalIsOpen(false), []);
    const handleScheduleDatetimeClick = (data) => {
        setScheduleData({
            ...scheduleData,
            ...{
                name: certificate?.data?.name,
                document: certificate?.data?.document,
                phone: certificate?.data?.phone,
                date: data.date,
                hour: data.hour,
            },
        });
        openFormModal();
    };

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper title="AGENDAMENTO DA ENTREVISTA">
            <div className="page-content">
                <Formik
                    initialValues={certificateDocumentsInitialValues}
                    validationSchema={certificateDocumentsValidations}
                    onSubmit={handleCertificateDocumentsSubmit}
                    render={(formikProps) => (
                        <Form
                            onSubmit={(e) => {
                                formikProps.handleSubmit(e);
                            }}
                        >
                            <Field type="hidden" name="token" />
                            <div className="d-flex flex-wrap">
                                <div className="col-12 col-sm-6 px-2 py-1">
                                    <Label htmlFor="name">Nome: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.name}</Label>
                                </div>
                                <div className="col-12 col-sm-6 px-2 py-1">
                                    <Label htmlFor="group_id" title={props?.userData?.group?.id}>
                                        Tipo:{' '}
                                    </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.entity_label}</Label>
                                </div>

                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="document">Documento: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.document}</Label>
                                </div>
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="email">Email: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.email}</Label>
                                </div>
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="phone">Telefone: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.phone}</Label>
                                </div>
                                <div className="col-12 col-sm-8 px-2 py-1">
                                    <Label htmlFor="address">Endereço: </Label>
                                    <Label className="fw-bold mx-2">{certificateFAddress()}</Label>
                                </div>
                                <div className="col-12 col-sm-4 px-2 py-1">
                                    <Label htmlFor="zip">CEP: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.zip}</Label>
                                </div>
                                <div className="col-12 col-sm-6 px-2 py-1">
                                    <Label htmlFor="neighborhood">Bairro: </Label>
                                    <Label className="fw-bold mx-2">{certificate?.data?.neighborhood}</Label>
                                </div>
                                <div className="col-12 col-sm-6 px-2 py-1">
                                    <Label htmlFor="neighborhood">Cidade/UF: </Label>
                                    <Label className="fw-bold mx-2">
                                        {certificate?.data?.city?.name}/{certificate?.data?.city?.state}
                                    </Label>
                                </div>
                                <div className="col-12 border" />
                                <div className="col-12">
                                    <Schedule handleScheduleDatetimeClick={handleScheduleDatetimeClick} />
                                </div>
                            </div>
                        </Form>
                    )}
                />
            </div>
            <ScheduleConfirmation
                modalIsOpen={formModalIsOpen}
                data={scheduleData}
                closeModal={closeFormModal}
                title="CONFIRMAR AGENDAMENTO"
            />
        </ContentWrapper>
    );
}

export default CertificatesScheduling;
