import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '@app/routes/PrivateRoute';

import CertificatesHome from './certificates_home';
import CertificatesForm from './certificates_form';
import CertificatesDocuments from './certificates_documents';
import CertificatesScheduling from './certificates_scheduling';

function Certificates({ section, ...props }) {
    return (
        <Switch>
            <PrivateRoute exact path="/certificados" render={() => <CertificatesHome section={section} {...props} />} />
            <PrivateRoute exact path="/certificados/novo" render={() => <CertificatesForm section={section} {...props} />} />
            <PrivateRoute
                exact
                path="/certificados/:token/documentos"
                render={() => <CertificatesDocuments section={section} {...props} />}
            />
            <PrivateRoute
                exact
                path="/certificados/:token/agendamento"
                render={() => <CertificatesScheduling section={section} {...props} />}
            />
        </Switch>
    );
}

export default Certificates;
