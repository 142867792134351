import { useMemo } from 'react';
import useCheckFieldBehavior from '../useCheckFieldBehavior';

export default function BaseField({ renderer, field, props }) {
    const checkFieldBehavior = useCheckFieldBehavior;
    const { values, setFieldValue, disabled } = props;

    const isDisabled = useMemo(
        () => disabled || checkFieldBehavior('disabled', field?.behavior || {}, values),
        [field, values, disabled, checkFieldBehavior]
    );
    const hiddenField = useMemo(() => field?.behavior?.hidden?.field || null, [field]);

    const hidden = useMemo(() => {
        if (hiddenField === null) return false;
        const response = checkFieldBehavior('hidden', field?.behavior || {}, values);
        if (response) setFieldValue(field.name, undefined);
        return response;
    }, [field, values, hiddenField, setFieldValue, checkFieldBehavior]);

    const required = useMemo(() => {
        if (hidden || isDisabled) return false;
        return (field?.validations || '').indexOf('required') >= 0;
    }, [field, hidden, isDisabled]);

    const label = useMemo(
        () => checkFieldBehavior('label', field.behavior || {}, values) || field.label,
        [field, values, checkFieldBehavior]
    );

    const placeholder = useMemo(
        () => checkFieldBehavior('placeholder', field.behavior || {}, values) || field?.placeholder || '',
        [field, values, checkFieldBehavior]
    );

    switch (field.type) {
        case 'function':
            return renderer.newFunctionField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'string':
            return renderer.newStringField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'html':
            return renderer.newHtmlField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'number':
            return renderer.newNumberField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'integer':
            return renderer.newIntegerField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'float':
            return renderer.newFloatField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'boolean':
            return renderer.newBooleanField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'masked':
            return renderer.newMaskedField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'group':
            return renderer.newGroupField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'date':
            return renderer.newDateField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'states':
            return renderer.newStatesField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'dropdown':
            return renderer.newDropdownField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'cities':
            return renderer.newCitiesField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'file':
            return renderer.newFileField(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        case 'separator':
            return renderer.newSeparator(field, { ...props, required, hidden, disabled: isDisabled, label, placeholder });
        default:
            return '';
    }
}
