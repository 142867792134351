import React, { useCallback, useMemo } from 'react';

import { useFormikContext } from 'formik';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';
import AditionalItemPriceValue from './AditionalItemPriceValue';

export default function AditionalItemListPrice({ productId = null, item = [], amount = 0.0 }) {
    const { setValues } = useFormikContext();
    const floatToCurrency = useFloatToCurrency;
    const price_config = useMemo(() => item?.price_config || {}, [item]);

    const onPriceSelected = useCallback(
        (e) => {
            setValues((prev) => ({
                ...prev,
                items: prev.items.map((item) => {
                    if (item.product_id !== productId) return item;

                    const qtd = parseInt(e.target.value);
                    const selectedItemIndex = item.price_config.findIndex((item) => item.qtd === qtd);
                    if (selectedItemIndex < 0) return item;
                    const amount = item.price_config[selectedItemIndex].price * qtd;
                    const price = amount / qtd || 0;

                    return {
                        ...item,
                        qtd,
                        price,
                        amount,
                    };
                }),
            }));
        },
        [setValues, productId]
    );
    return (
        <div className="col-12 d-flex flex-wrap align-items-center aditional-item-price_container px-2 pt-2 border br-8 bg-light-blue text-white">
            <div className="d-flex aditional-item-qtd-selector align-items-center justify-content-center col-12 col-sm-8 mb-2">
                <select name="aditional-item-price-selector" className="col-12 text-medium" onChange={onPriceSelected}>
                    {price_config.map((price, index) => (
                        <option key={index} value={price.qtd}>
                            {(price?.label || null) !== null ? price.label : `${price.qtd} por R$ ${floatToCurrency(price.price)}`}
                        </option>
                    ))}
                </select>
            </div>
            <AditionalItemPriceValue value={amount} className="justify-content-end col-12 col-sm-4 mb-1" />
        </div>
    );
}
