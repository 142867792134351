import React from 'react';
import parse from 'html-react-parser';

export default function HtmlField({ field, formikProps: { hidden = false } }) {
    return (
        <div className={`${field.style} ${hidden ? 'd-none' : ''}`} key={field.name}>
            <div className="col-12">{parse(field.content || '')}</div>
        </div>
    );
}
