import React from 'react';
import { CustomSwitch } from '@app/components/ui';
import { ErrorMessage, useFormikContext } from 'formik';
import { Label } from 'reactstrap';
import { Link } from 'react-router-dom';

import './SubscriptionUpdateAgreement.scss';

export default function SubscriptionUpdateAgreement({ subscription = null }) {
    const { values, setFieldValue, isLoading } = useFormikContext();

    if (subscription === null) return null;

    return (
        <div className="subscription-update-agreement col-12 p-3">
            <div className="update-agreement-container d-flex flex-wrap col-12 p-3">
                <p className="p-1 m-0">
                    Atualmente você já assina o plano <strong>{subscription.name}</strong> que não pode ser assinado juntamente com este.
                </p>
                <p className="p-1 m-0">Marcando a opção abaixo você estará concordando com a troca do plano anterior pelo plano atual.</p>
                <p className="p-1 m-0">
                    Os itens contemplados no plano anterior estarão disponíveis até o dia <strong>{subscription.next_billing_at}</strong>.
                </p>
                <p className="p-1 m-0">
                    Ficou com dúvida?{' '}
                    <a href="/politica-de-assinaturas" target="_blank" title="Clique aqui para ler nossa Política de Assinaturas">
                        Clique aqui
                    </a>{' '}
                    e leia nossa <strong>Política de Assinaturas</strong>.
                </p>
                <div className="col-12 mt-1 pt-3 d-flex align-items-center border-top">
                    <CustomSwitch
                        name="update_agreement"
                        className="justify-content-center mx-2"
                        onChange={(e) => setFieldValue('update_agreement', e)}
                        checked={values?.update_agreement || false}
                        isAsync={false}
                        disabled={isLoading}
                        data={{ id: null, status: values?.update_agreement || false }}
                    />
                    <Label className="update-agreement-field-label d-inline-block" htmlFor="update_agreement">
                        Declaro que li e concordo com a
                        <Link to="/politica-de-assinaturas" target="_blank" className="mx-1">
                            Política de Assinaturas
                        </Link>
                    </Label>
                </div>
                <ErrorMessage component="span" name="update_agreement" className="col-12 text-error text-small" />
            </div>
        </div>
    );
}
