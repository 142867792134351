import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import PurchaseStatus from '@app/constants/PurchaseStatus.constant';

import { usePurchaseManager } from '@app/hooks';
import OrderContext from './components/OrderContext';
import OrderSummary from './components/OrderSummary';
import CheckoutForm from './components/CheckoutForm';
import CheckoutResume from './components/CheckoutResume';
import OrderStatusExpired from './components/OrderStatus/OrderStatusExpired';
import OrderItem from './components/OrderItem';

export default function OrderCheckout(props) {
    const history = useHistory();
    const PurchaseManager = usePurchaseManager();

    /**
     * Carregando os dados do pedido
     */
    const purchaseToken = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [purchaseData, setPurchaseData] = useState(null);

    const loadPurchaseData = useCallback(
        (token) => {
            setIsLoading(true);
            PurchaseManager.getPurchase(token)
                .then(({ data, status }) => {
                    if (data && status === 200) {
                        if (!['created', 'pending'].includes(data.status)) {
                            history.push(`/pedido/${data.token}`);
                        } else {
                            setPurchaseData(data);
                            setIsLoading(false);
                        }
                    } else {
                        toast.error(data?.message || 'Não foi possível carregar os dados do pedido');
                    }
                })
                .catch(({ response }) => {
                    const { data, status } = response;
                    if (status === 404) {
                        toast.error('Pedido não encontrado');
                        history.push('/pedidos');
                    } else {
                        toast.error(data?.message || 'Erro inesperado ao obter os dados do pedido');
                    }
                    setIsLoading(false);
                });
        },
        [PurchaseManager, history]
    );

    useEffect(() => {
        if ((props?.match?.params?.token || '') === '') return;
        if (purchaseToken.current !== null) return;
        purchaseToken.current = props?.match?.params?.token;

        loadPurchaseData(purchaseToken.current);
    }, [purchaseData, loadPurchaseData, props?.match?.params?.token]);

    const updatePurchaseData = () => {
        loadPurchaseData(purchaseData.token);
    };

    const content = useMemo(() => {
        if ((purchaseData || null) === null) return <LoadingSpinner />;
        switch (purchaseData?.status) {
            case PurchaseStatus.CREATED:
                return <CheckoutForm className="col-12 col-md-6 pl-1" />;
            case PurchaseStatus.PENDING:
                return <CheckoutResume className="col-12 col-md-6 pl-1" />;
            default:
                return <OrderStatusExpired className="col-12 col-md-6 pl-1" />;
        }
    }, [purchaseData]);

    return (
        <ContentWrapper>
            <OrderContext.Provider
                value={{
                    purchase: purchaseData,
                    isLoading,
                    setIsLoading,
                    updatePurchaseData,
                }}
            >
                <div className="d-flex flex-wrap payment col-12">
                    <OrderSummary className="mb-2" style={{ borderBottom: 'inherit' }} />
                    <div className="col-12 col-md-6 card no-border br-10">
                        <div className="d-flex flex-wrap">
                            <h3 className="col-12 text-center p-2 m-0">ITENS DO PEDIDO</h3>
                            <div className="order-items col-12 m-0 mt-1 p-0">
                                {purchaseData?.items?.length > 0 ? (
                                    purchaseData?.items?.map((item, id) => (
                                        <OrderItem key={id} item={item} updateRequest={props?.updateRequest} />
                                    ))
                                ) : (
                                    <div className="d-flex order-product col-12 text-center p-0 m-0" key={0}>
                                        <p className="col-12 text-center p-2 m-0">
                                            <strong>Não existem produtos neste pedido.</strong>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {content}
                </div>
            </OrderContext.Provider>
        </ContentWrapper>
    );
}
