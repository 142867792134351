import { createSlice } from '@reduxjs/toolkit';

export const summarySlice = createSlice({
    name: 'summary',
    initialState: {
        cart: {
            count: 0,
            amount: 0,
            products: [],
        },
        notifications: 0,
        purchases: 0,
        lastUpdated: null,
    },
    reducers: {
        updateSummary(state) {
            return {
                ...state,
                ...{
                    lastUpdated: new Date().getTime(),
                },
            };
        },
        setSummary(state, { payload }) {
            return {
                ...state,
                ...{
                    ...payload,
                    ...{
                        lastUpdated: new Date().getTime(),
                    },
                },
            };
        },
        clearCartSummary(state) {
            return {
                ...state,
                cart: {
                    count: 0,
                    amount: 0,
                    products: [],
                },
            };
        },
        clearNotificationsSummary(state) {
            return {
                ...state,
                notifications: 0,
            };
        },
        clearPurchasesSummary(state) {
            return {
                ...state,
                purchases: 0,
            };
        },
    },
});

// export const { addItem, removeItem, clearCart, updateSummary, setSummary } = slice.actions;
export const { clearCartSummary, clearNotificationsSummary, clearPurchasesSummary, updateSummary, setSummary } = summarySlice.actions;
export const selectSummary = (state) => state.summary;

export default summarySlice.reducer;
