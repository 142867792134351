import React, { useContext, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Row, Col, Button, Form, Label, Table } from 'reactstrap';
import { URLs } from '@app/constants';

import Modal from 'react-modal';
import { Formik } from 'formik';

import * as yup from 'yup';
import Api from '@app/services/Api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

import FormikDevTools from '@app/components/FormikDevTools';

import ProductSearch from '@app/components/SearchFields/ProductSearch';
import TaxationContext from '../../components/TaxationContext';

import './OrderSimulationPopup.scss';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'initial',
    },
};

function SimulationTable({ simulation }) {
    return (
        <Table hover className="col-12 m-0 table-split">
            <thead>
                <tr>
                    <th colSpan="2">
                        Regra aplicada: <strong>{simulation?.rule || ''}</strong>
                    </th>
                </tr>
            </thead>
            <tbody>
                {simulation.split.length > 0 ? (
                    simulation.split.map((item, index) => (
                        <tr key={index}>
                            <td>{item?.name || ''}</td>
                            <td align="right">{(item?.amount || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="2" align="center">
                            Nenhum fornecedor encontrado
                        </td>
                    </tr>
                )}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="2" />
                </tr>
            </tfoot>
        </Table>
    );
}

function SplitTable({ split }) {
    const total = useMemo(() => split.reduce((total, item) => total + item.amount, 0), [split]);
    return (
        <Table striped bordered hover className="col-12 mb-2">
            <thead>
                <tr>
                    <th>Recebedor</th>
                    <th align="right">Valor</th>
                </tr>
            </thead>
            <tbody>
                {split.map((item, index) => (
                    <tr key={index}>
                        <td>{item?.name || ''}</td>
                        <td align="right">{(item?.amount || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td align="right">Total: </td>
                    <td align="right">{(total || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                </tr>
            </tfoot>
        </Table>
    );
}

function OrderSimulationPopup({ isOpen }) {
    const { onCloseOrderSimulationPopup } = useContext(TaxationContext);

    const [splitResume, setSplitResume] = useState([]);
    const handleSimulationSubmit = (values, { setSubmitting, setValues }) => {
        setSubmitting(true);
        const url = URLs.admin.purchases.simulate;

        Api({ method: 'post', url, data: values })
            .then(({ data }) => {
                setSplitResume(Object.keys(data?.split || []).map((key) => data?.split[key]));
                setValues((prev) => ({
                    ...prev,
                    items: prev.items.map((item) => ({
                        ...item,
                        simulation: (data?.detailed || []).filter((simulation) => simulation.product.id === item.id)[0] || null,
                    })),
                }));
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados da regra!');
                setSubmitting(false);
            });
    };

    const onAddNewItem = (newItem, setValues) => {
        setValues((prev) => {
            const newSet = [...prev.items.filter((item) => item.id !== newItem.id), newItem].map((item) => {
                const newItem = item;
                delete newItem.simulation;
                return newItem;
            });
            return {
                ...prev,
                items: newSet,
                amount: newSet.reduce((total, item) => total + item.price * item.qtd, 0),
            };
        });
        setSplitResume([]);
    };

    const onDeleteItem = (product_id, setValues) => {
        setValues((prev) => {
            const newSet = prev.items
                .filter((item) => item.id !== product_id)
                .map((item) => {
                    const newItem = item;
                    delete newItem.simulation;
                    return newItem;
                });
            return {
                ...prev,
                items: newSet,
                amount: newSet.reduce((total, item) => total + item.price * item.qtd, 0),
            };
        });
        setSplitResume([]);
    };

    const contractValidations = yup.object().shape({
        items: yup.array().min(1, 'É necessário selecionar ao menos 1 produto para realizar a simulação'),
    });

    const [item, setItem] = useState({});
    const initialValues = {
        items: [],
        amount: 0,
    };

    const afterOpenModal = () => {
        setSplitResume([]);
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseOrderSimulationPopup}
            style={customStyles}
            className=""
            contentLabel="SIMULADOR DE PEDIDOS"
            ariaHideApp={false}
        >
            <div className="modal-content">
                <Row>
                    <Col className="col-12 d-flex align-items-center">
                        <h2 className="flex-grow-1">SIMULADOR DE PEDIDOS</h2>
                        <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={onCloseOrderSimulationPopup}>
                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                        </Button>
                    </Col>
                </Row>
                <Formik
                    initialValues={initialValues}
                    validationSchema={contractValidations}
                    onSubmit={handleSimulationSubmit}
                    render={({ values, setValues, isSubmitting, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} className="form d-flex flex-wrap order-simulation-popup-form">
                            <div className="p-1 d-flex flex-wrap col-12">
                                <div className="col-12 col-md-6">
                                    <Label htmlFor="product-search" className="field-label">
                                        Produto
                                    </Label>
                                    <ProductSearch
                                        name="product-search"
                                        search_params={{ fields: ['id', 'name', 'token', 'price', 'offer_once', 'max_offering'] }}
                                        value={item?.name || ''}
                                        onChange={(suggestion) =>
                                            setItem((item) => ({
                                                ...item,
                                                id: suggestion.id,
                                                name: suggestion.name,
                                                token: suggestion.token,
                                                offer_once: suggestion.offer_once,
                                                price: suggestion.price,
                                                qtd: 1,
                                                max_offering: suggestion.max_offering || null,
                                            }))
                                        }
                                    />
                                </div>
                                <div className="px-1 col-12 col-md-2">
                                    <Label htmlFor="price" className="field-label">
                                        Valor
                                    </Label>
                                    <input
                                        type="text"
                                        name="price"
                                        className="col-12"
                                        disabled
                                        value={(item?.price || 10).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                    />
                                </div>
                                <div className="col-12 col-md-2">
                                    <Label htmlFor="qtd" className="field-label">
                                        Qtd
                                    </Label>
                                    <input
                                        type="text"
                                        name="qtd"
                                        className="col-12"
                                        value={item?.qtd}
                                        disabled={item?.offer_once || false}
                                        onChange={(e) =>
                                            setItem((item) => {
                                                let qtd = parseInt(e.target.value || 0);
                                                qtd = item?.max_offering && qtd > item?.max_offering ? item?.max_offering : qtd;
                                                return {
                                                    ...item,
                                                    qtd,
                                                };
                                            })
                                        }
                                    />
                                </div>
                                <div className="p-1 d-flex flex-column justify-content-end col-12 col-md-2">
                                    <Button
                                        type="button"
                                        className="col-12"
                                        color="secondary"
                                        onClick={() => {
                                            onAddNewItem(item, setValues);
                                            setItem({});
                                        }}
                                        disabled={(item?.id || null) == null || (item?.qtd || 0) === 0}
                                    >
                                        INSERIR
                                    </Button>
                                </div>
                            </div>
                            <div className="table-container d-flex col-12">
                                <Table bordered hover className="col-12 mb-0">
                                    <thead>
                                        <tr>
                                            <th>Produto</th>
                                            <th className="text-right">Valor</th>
                                            <th className="text-right">Qtd</th>
                                            <th className="text-right">Total</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {values.items.length > 0 ? (
                                            values.items.map((item, index) => {
                                                const total = (item?.price || 0) * item.qtd;
                                                return (
                                                    <>
                                                        <tr key={index} className="product-row">
                                                            <td>
                                                                <strong>{item?.name || ''}</strong>
                                                            </td>
                                                            <td align="right">
                                                                {(item?.price || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                                            </td>
                                                            <td align="right">{item?.qtd}</td>
                                                            <td align="right">
                                                                {total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                                            </td>
                                                            <td align="center" width="5%">
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    title="Excluir condição"
                                                                    onClick={() => onDeleteItem(item.id, setValues)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                        {item?.simulation && (
                                                            <tr key={`simulation-${index}`}>
                                                                <td colSpan="5">
                                                                    <SimulationTable simulation={item.simulation} />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="5" align="center">
                                                    Nenhum item no pedido
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="3" className="text-right">
                                                Total:{' '}
                                            </td>
                                            <td className="text-right">
                                                {values.amount.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                            </td>
                                            <td />
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                            {splitResume.length > 0 && (
                                <>
                                    <h3 className="field-group-title col-12">Resumo do split</h3>
                                    <div className="table-container d-flex col-12">
                                        <SplitTable split={splitResume} />
                                    </div>
                                </>
                            )}
                            <div className="col-12 mt-3 d-flex justify-content-center">
                                <div className="mb-1 col-6 text-center">
                                    <Button type="submit" className="col-10" color="secondary" disabled={isSubmitting}>
                                        SIMULAR SPLIT
                                    </Button>
                                </div>
                            </div>

                            <FormikDevTools />
                        </Form>
                    )}
                />
            </div>
        </Modal>
    );
}

export default OrderSimulationPopup;
