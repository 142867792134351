import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Label } from 'reactstrap';

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

export default function FileField({
    field,
    formikProps: {
        addNewFile,
        removeFile,
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        disabled = false,
        hidden = false,
        required = false,
        label = '',
    },
}) {
    const [fileKey, setFileKey] = useState(new Date().getTime());

    const onFileUploadChanged = async (el) => {
        if (!el.files || !el.files[0] || !el.files[0].name) return;
        // const base64FileContent = await toBase64(el.files[0]);
        setFieldTouched(field.name);
        // Removendo arquivo anterior caso exista
        removeFile(fileKey);

        const key = new Date().getTime();
        setFileKey(key);

        const { name, size, type } = el.files[0];
        const cleanedName = String(name).replaceAll(/[^a-zA-Z0-9.]+/g, '_');
        const file = await toBase64(el.files[0]);
        setFieldValue(field.name, { key, name: cleanedName, size, type }, true);
        addNewFile(key, field.name, field.label, cleanedName, type, file);
    };

    return (
        <div className={`${field.style} ${hidden ? 'd-none' : ''}`} key={field.name}>
            <Label className="field-label col-12" htmlFor="name">
                {label}
                {required ? <em>*</em> : ''}
            </Label>
            <div className="d-flex flex-wrap col-12 align-items-center">
                <label className="btn btn-primary btn-icon-left" htmlFor={field.name} title={field.label}>
                    <FontAwesomeIcon icon={faFileUpload} className="mx-2" />
                    Selecionar arquivo
                    <input
                        className="d-none"
                        name={field.name}
                        disabled={disabled || isSubmitting}
                        id={field.name}
                        type="file"
                        accept={field?.content || ''}
                        onChange={(e) => onFileUploadChanged(e.target)}
                    />
                </label>
                <label className="px-1 text-small" id={`filename_${field.name}`}>
                    {values?.[field.name]?.name || ''}
                </label>
            </div>
            {errors?.[field.name] && <span className="col-12 text-error text-small px-2">{errors?.[field.name]}</span>}
        </div>
    );
}
