import { useFormikContext } from 'formik';
import React, { useMemo, useRef } from 'react';

export default function ProductDisplayTypeDropdown({ id, ...props }) {
    const { values, setValues } = useFormikContext();

    const displayTypes = [
        ['cards', 'Cartão'],
        ['featured', 'Destaque'],
        ['carousel', 'Carrossel'],
        ['course', 'Curso'],
        ['mat', 'Esteira'],
    ];

    const displayType = useMemo(() => {
        const [content] = values.content.filter((item) => item.id === id);
        return content ? content.displayType : '';
    }, [values, id]);

    const onChangeColumns = (e) => {
        const { value } = e.target;
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => (item.id === id ? { ...item, displayType: value } : item)),
        }));
    };

    const selectRef = useRef(null);
    return (
        <div className="d-flex align-items-center p-1" title="Tipo de expositor">
            <select ref={selectRef} name="columns" id="columns" {...props} onChange={onChangeColumns} defaultValue={displayType}>
                {displayTypes.map((type, index) => (
                    <option key={index} value={type[0]}>
                        {type[1]}
                    </option>
                ))}
            </select>
        </div>
    );
}
