import React from 'react';
import { Button, Label, Form } from 'reactstrap';

import Modal from 'react-modal';
import { ErrorMessage, Field, Formik } from 'formik';

import * as yup from 'yup';
import { PopupStyle } from '@app/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CurrencyInputField } from '../../../../../components/ui';

function ChangePricePopup(props) {
    const afterOpenModal = () => {};

    const handleChangeQtd = (values, setValues, qtd) => {
        let newQtd = Number(parseInt(qtd)).isNaN ? 0 : parseInt(qtd);
        const max_offering = parseInt(values.max_offering);
        newQtd = !Number(max_offering).isNaN && newQtd > max_offering ? max_offering : newQtd;

        setValues((prev) => ({
            ...prev,
            ...{
                qtd: newQtd,
                total: parseFloat(values.price) * newQtd,
            },
        }));
    };

    const handleFormSubmit = (values) => {
        props?.onSubmit(values);
        props?.closeModal();
    };

    const validations = yup.object().shape({
        price: yup
            .number()
            .typeError('Informe uma quantidade válida')
            .transform((_value, originalValue) => Number(`${originalValue}`.replace(/,/, '.')))
            .required('É necessário informar uma quantidade.'),
        total: yup
            .number()
            .typeError('Informe um total válido')
            .transform((_value, originalValue) => Number(`${originalValue}`.replace(/,/, '.')))
            .required('É necessário informar um valor total.'),
    });

    const initialValues = {
        token: props?.data?.product_id || '',
        price: props?.data?.product?.price,
        max_offering: parseInt(props?.data?.product?.max_offering),
        qtd: props?.data?.qtd || 1,
        total: props?.data?.price || 0,
        currency: 13.5,
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={props?.modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={props?.closeModal}
            style={{ ...PopupStyle, ...{ content: { ...PopupStyle.content, ...{ maxWidth: '400px' } } } }}
            contentLabel={props?.title}
            className=""
            ariaHideApp={false}
        >
            <div className="col-12 mb-2 d-flex align-items-center">
                <h2 className="flex-grow-1 text-center text-large my-0">{props?.data?.product?.name || ''}</h2>
                <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={props?.closeModal}>
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                </Button>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleFormSubmit}
                enableReinitialize
                render={(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                        <div className="col-12">
                            <div className="mb-1">
                                <Label htmlFor="name">Preço (R$)</Label>
                                <Field
                                    type="text"
                                    name="price"
                                    value={formikProps.values.price.toLocaleString('pt-BR', { maxFractionDigits: 2 })}
                                    className="col-12 text-right"
                                    disabled
                                />
                            </div>
                            <div className="mb-1">
                                <Label htmlFor="name">Quantidade</Label>
                                <Field
                                    name="qtd"
                                    type="number"
                                    min="0"
                                    className="col-12 text-right"
                                    onChange={(e) => handleChangeQtd(formikProps.values, formikProps.setValues, e.target.value)}
                                    disabled={!Number(formikProps.values.max_offering).isNaN && formikProps.values.max_offering === 1}
                                />
                                <ErrorMessage component="span" name="qtd" className="text-error text-small" />
                            </div>
                            <div className="mb-1">
                                <Label htmlFor="name">Total (R$)</Label>
                                <Field name="total" component={CurrencyInputField} className="col-12 text-right" />
                                <ErrorMessage component="span" name="total" className="text-error text-small" />
                            </div>
                            <div className="d-flex">
                                <div className="mb-3 px-1 col-6 text-center">
                                    <Button
                                        type="submit"
                                        className="col-12"
                                        color="danger"
                                        disabled={props?.isSubmitting}
                                        onClick={props?.closeModal}
                                    >
                                        CANCELAR
                                    </Button>
                                </div>
                                <div className="mb-3 px-1 col-6 text-center">
                                    <Button type="submit" className="col-12" color="success" disabled={props?.isSubmitting}>
                                        APLICAR
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            />
        </Modal>
    );
}

export default ChangePricePopup;
