import React, { useContext } from 'react';
import FormEditorFieldPopupContext from '../../FormEditorFieldPopupContext';

function NumberValidation({ fieldName, label, ...props }) {
    const { validations, setFieldValidation } = useContext(FormEditorFieldPopupContext);

    return (
        <div {...props}>
            <label className="mr-1 pl-1">{label}</label>
            <input
                type="text"
                value={validations?.[fieldName] || ''}
                style={{ maxWidth: '100px' }}
                onChange={(e) => setFieldValidation(fieldName, parseInt(e.target?.value) ? [parseInt(e.target?.value)] : null)}
            />
        </div>
    );
}
export default NumberValidation;
