import React, { useMemo } from 'react';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import { Field, useFormikContext } from 'formik';
import { useFloatToCurrency } from '@app/helpers';

import './CheckoutSummary.scss';

export default function SubscriptionSummary({ className = '', style = {}, isLoading = false }) {
    const { values } = useFormikContext();
    const floatToCurrency = useFloatToCurrency;

    const totals = useMemo(() => {
        const response = {
            price: values.price?.price || 0,
            freight: 0,
            complements: 0,
            bump: 0,
            amount: 0,
        };
        // Calculando itens adicionais
        (values?.items || []).forEach((item) => {
            if (item.item_type === 'optional') response.complements += item?.amount || 0;
            if (item.item_type === 'bump') response.bump += item?.amount || 0;
        });
        response.amount = response.price + response.freight + response.complements + response.bump;
        return response;
    }, [values]);

    return (
        <div className={`d-flex col-12 flex-wrap justify-content-start payment-summary ${className || ''}`} style={style}>
            <h3 className="col-12 m-0 p-2 border-bottom text-center">SUBTOTAL</h3>
            <div className="payment-values col-12 col-md-8 p-3 d-flex flex-column">
                <div className="col-12 d-flex flex-column flex-grow-1">
                    <div className="col-12 d-flex flex-row mb-1">
                        <label className="text-medium col-8">Plano:</label>
                        <label className="text-medium col-4 text-right">R$ {floatToCurrency(totals?.price || 0)}</label>
                    </div>
                    <div className="col-12 d-flex flex-row mb-1">
                        <label className="text-medium col-8">Complementos:</label>
                        <label className="text-medium col-4 text-right">R$ {floatToCurrency(totals?.complements || 0)}</label>
                    </div>
                    <div className="col-12 d-flex flex-row mb-1">
                        <label className="text-medium col-8">Itens Adicionais:</label>
                        <label className="text-medium col-4 text-right">R$ {floatToCurrency(totals?.bump || 0)}</label>
                    </div>
                    <div className="col-12 d-flex flex-row mb-1">
                        <label className="text-medium col-8">Frete e manuseio:</label>
                        <label className="text-medium col-4 text-right">R$ {floatToCurrency(totals?.freight || 0)}</label>
                    </div>
                </div>
                <div className="border-top py-1 col-12 d-flex flex-row">
                    <label className="text-medium col-8">
                        <strong>Total:</strong>
                    </label>
                    <label className="text-medium col-4 text-right">
                        <strong>R$ {floatToCurrency(totals?.amount || 0)}</strong>
                    </label>
                </div>
                {isLoading && <LoadingSpinner />}
            </div>
            <div className="payment-values col-12 col-md-4 p-3 d-flex align-items-center justify-content-center">
                <Field type="submit" color="custom" className="btn btn-primary col-10 " value="FINALIZAR" />
            </div>
        </div>
    );
}
