import React, { useMemo } from 'react';
import { Label } from 'reactstrap';
import { Field, useFormikContext } from 'formik';
import { CustomSwitch, LabelTip } from '../../../../../components/ui';

function FormSliceButtonAfterBuy({ isLoading }) {
    const { values, errors, setValues } = useFormikContext();

    const handleShowButtonLinkChange = (value, values, setValues) => {
        const privateAttr = values.private;
        if (!value) {
            privateAttr.button_label = '';
            privateAttr.button_target = '';
            privateAttr.url = '';
        }
        setValues((prev) => ({
            ...prev,
            ...{
                show_button_link: value,
                private: privateAttr,
            },
        }));
    };
    const handleButtonTargetChange = (value, values, setValues) => {
        setValues({ ...values, ...{ private: { ...values.private, ...{ button_target: value ? '_blank' : '_self' } } } });
    };

    const isDisabled = useMemo(() => isLoading || ['document', 'object'].includes(values.product_type), [values, isLoading]);

    return (
        <>
            <div className="col-12 col-sm-3 pt-2 px-1 d-flex align-items-center">
                <CustomSwitch
                    name="show_button_link"
                    className="justify-content-center mx-2"
                    onChange={(e) => handleShowButtonLinkChange(e, values, setValues)}
                    checked={values?.show_button_link || false}
                    isAsync={false}
                    disabled={isDisabled}
                    data={{ id: values?.token, status: values?.show_button_link || false }}
                />
                <Label className="field-label" htmlFor="show_button_link">
                    Exibir link de acesso após a compra.
                    <LabelTip className="mx-1">
                        Exibe um botão para acesso a uma seção do site ou página externa após a compra do produto. Caso o produto seja do
                        tipo <b>&quot;Documento&quot;</b>, será exibido o botão para download do documento.
                    </LabelTip>
                </Label>
            </div>
            <div className="mb-1  px-1 col-12 col-sm-3">
                <Label className="field-label" htmlFor="private.button_label">
                    Texto do botão
                    <LabelTip className="mx-1">Texto a ser exibido no botão de acesso a seção do site ou página externa.</LabelTip>
                </Label>
                <Field
                    name="private.button_label"
                    type="text"
                    placeholder="Ex: Acessar o produto"
                    className="col-12"
                    disabled={isDisabled || !values.show_button_link}
                />
                {errors?.private && errors?.private?.button_label && <span className="text-error text-small">{errors?.private?.url}</span>}
            </div>
            <div className="mb-1 px-1 col-12 col-sm-3">
                <Label className="field-label" htmlFor="private.url">
                    URL
                    <LabelTip className="mx-1">URL para redirecionamento do cliente após clicar no botão.</LabelTip>
                </Label>
                <Field
                    name="private.url"
                    type="text"
                    placeholder="Ex: /home"
                    className="col-12"
                    disabled={isDisabled || !values.show_button_link}
                />
                {errors?.private && errors?.private?.url && <span className="text-error text-small">{errors?.private?.url}</span>}
            </div>
            <div className="col-12 col-sm-3 pt-2 px-1 d-flex align-items-center">
                <CustomSwitch
                    name="private.button_target"
                    className="justify-content-center mx-2"
                    onChange={(e) => handleButtonTargetChange(e, values, setValues)}
                    checked={values?.private?.button_target === '_blank' || false}
                    isAsync={false}
                    disabled={isDisabled || !values.show_button_link}
                    data={{ id: values?.token, status: values?.private?.button_target || false }}
                />
                <Label className="field-label" htmlFor="show_button_link">
                    Abrir em uma nova aba.
                    <LabelTip className="mx-1">
                        <strong>Marcado: </strong>Abre o link em uma nova aba do navegador.
                        <br />
                        <strong>Marcado: </strong>Abre o link na aba atual do navegador.
                    </LabelTip>
                </Label>
            </div>
        </>
    );
}
export default FormSliceButtonAfterBuy;
