import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { CurrencyInputField } from '@app/components/ui';
import SuppliersSearch from '../SuppliersSearch';

const defaultSplitValue = {
    supplier_id: null,
    supplier: { id: null, name: '' },
    split_type: 'percentage',
    amount: 0,
    charge_processing_fee: false,
    charge_remainder_fee: false,
    liable: false,
};
function FormSliceSplit() {
    const { values, setValues } = useFormikContext();

    const [split, setSplit] = useState(defaultSplitValue);

    const onSupplierSelected = (suggestion) => {
        setSplit((prev) => ({
            ...prev,
            supplier_id: suggestion?.id || null,
            supplier: {
                id: suggestion?.id || null,
                name: suggestion?.name || null,
            },
        }));
        return suggestion?.name || '';
    };

    const onChangeValue = (e) => {
        setSplit((prev) => ({
            ...prev,
            amount: e.target.floatValue,
        }));
    };

    const onChangeType = (e) => {
        setSplit((prev) => ({
            ...prev,
            split_type: e.target.value,
        }));
    };

    const onAddSplit = () => {
        setValues((prev) => ({
            ...prev,
            split: [
                ...prev.split,
                {
                    ...split,
                    amount: split.split_type === 'percentage' ? split.amount / 100 : split.amount,
                },
            ],
        }));
        setSplit(defaultSplitValue);
    };

    const onDeleteSplit = (supplier_id) => {
        setValues((prev) => ({
            ...prev,
            split: prev.split.filter((item) => item.supplier_id !== supplier_id),
        }));
    };

    return (
        <>
            <div className="d-flex flex-wrap col-12 text-small mb-2">
                <div className="col-12 col-md-6">
                    <SuppliersSearch name={split?.supplier?.name || ''} onSuggestionSelected={onSupplierSelected} />
                </div>
                <select name="split_type" placeholder="Tipo" className="col-12 col-md-1" onChange={onChangeType} value={split.split_type}>
                    <option value="flat">R$</option>
                    <option value="percentage">%</option>
                </select>
                <div className="col-12 col-md-3">
                    <CurrencyInputField value={split?.amount || 0} onAfterChange={onChangeValue} className="col-12" />
                </div>
                <Button className="col-12 col-md-2" color="secondary" onClick={onAddSplit} disabled={false}>
                    ADICIONAR
                </Button>
            </div>
            <Table striped bordered hover className="col-12 mb-0">
                <thead>
                    <tr>
                        <th>Fornecedor / Recebedor</th>
                        <th className="text-right">Valor a receber</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {values.split.length > 0 ? (
                        values.split.map((split, index) => {
                            const value =
                                split.split_type === 'flat'
                                    ? `R$ ${split.amount.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                    : `${split.amount * 100}%`;
                            return (
                                <tr key={index}>
                                    <td>{split?.supplier?.name || ''}</td>
                                    <td align="right">{value}</td>
                                    <td align="center" width="5%">
                                        <Button
                                            type="button"
                                            color="primary"
                                            title="Excluir condição"
                                            onClick={() => onDeleteSplit(split.supplier.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="4" align="center">
                                Qualquer condição
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
}
export default FormSliceSplit;
