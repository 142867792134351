import React from 'react';

import imgCardStatusPending from '@app/assets/images/card-status-pending.svg';
import imgCardStatusPaid from '@app/assets/images/card-status-paid.svg';
import imgCardStatusCanceled from '@app/assets/images/card-status-canceled.svg';
import imgCardStatusProcessing from '@app/assets/images/card-status-processing.svg';
import imgCardStatusFailed from '@app/assets/images/card-status-failed.svg';
import imgCardStatusOverpaid from '@app/assets/images/card-status-overpaid.svg';
import imgCardStatusUnderpaid from '@app/assets/images/card-status-underpaid.svg';
import imgCardStatusChargedback from '@app/assets/images/card-status-chargedback.svg';

function CardStatusPending(props) {
    const { charge } = props;
    return (
        <>
            <img src={imgCardStatusPending} alt="Pagamento realizado com cartão de crédito" className="col-6 mb-3" />
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">Tudo certo com seu pagamento, em breve os itens do seu pedido serão disponibilizados.</p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function CardStatusPaid(props) {
    const { charge } = props;
    return (
        <>
            <img src={imgCardStatusPaid} alt="Pagamento realizado com cartão de crédito" className="col-6 mb-3" />
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">Tudo certo com seu pagamento, em breve os itens do seu pedido serão disponibilizados.</p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function CardStatusCanceled(props) {
    const { charge } = props;
    return (
        <>
            <img src={imgCardStatusCanceled} alt="Pagamento realizado com cartão de crédito" className="col-6 mb-3" />
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">O pagamento do seu pedido foi cancelado.</p>
            <p className="text-center">
                Você pode continuar navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para você.
            </p>
        </>
    );
}
function CardStatusProcessing(props) {
    const { charge } = props;
    return (
        <>
            <img src={imgCardStatusProcessing} alt="Pagamento realizado com cartão de crédito" className="col-6 mb-3" />
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido está sendo processado pelo gateway. O <strong>Doutor Caminhoneiro</strong> será notificado assim
                que houver uma atualização sobre o status do pagamento.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function CardStatusFailed(props) {
    const { charge } = props;
    return (
        <>
            <img src={imgCardStatusFailed} alt="Pagamento realizado com cartão de crédito" className="col-6 mb-3" />
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido falhou. Você pode realizar este pedido novamente a qualquer momento. Fique atento à forma de
                pagamento escolhida e às datas de expiração da cobrança.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function CardStatusOverpaid(props) {
    const { charge } = props;
    return (
        <>
            <img src={imgCardStatusOverpaid} alt="Pagamento realizado com cartão de crédito" className="col-6 mb-3" />
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">Tudo certo com seu pagamento, em breve os itens do seu pedido serão disponibilizados.</p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function CardStatusUnderpaid(props) {
    const { charge } = props;
    return (
        <>
            <img src={imgCardStatusUnderpaid} alt="Pagamento realizado com cartão de crédito" className="col-6 mb-3" />
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido foi realizado de forma parcial. Assim que o <strong>Doutor Caminhoneiro</strong> verificar o
                pagamento total do valor do pedido os itens solicitados serão disponibilizados.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function CardStatusChargedback(props) {
    const { charge } = props;
    return (
        <>
            <img src={imgCardStatusChargedback} alt="Pagamento realizado com cartão de crédito" className="col-6 mb-3" />
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido foi extornado. Você pode realizar este pedido novamente a qualquer momento. Fique atento à forma
                de pagamento escolhida e às datas de expiração da cobrança.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}

function ChargeCard({ method, charge }) {
    let content = 'carregando';
    switch (charge.status) {
        case 'pending':
            content = <CardStatusPending charge={charge} />;
            break;
        case 'paid':
            content = <CardStatusPaid charge={charge} />;
            break;
        case 'canceled':
            content = <CardStatusCanceled charge={charge} />;
            break;
        case 'processing':
            content = <CardStatusProcessing charge={charge} />;
            break;
        case 'failed':
            content = <CardStatusFailed charge={charge} />;
            break;
        case 'overpaid':
            content = <CardStatusOverpaid charge={charge} />;
            break;
        case 'underpaid':
            content = <CardStatusUnderpaid charge={charge} />;
            break;
        case 'chargedback':
            content = <CardStatusChargedback charge={charge} />;
            break;
        default:
            content = 'carregando...';
    }
    return (
        <div className="charge col-12 p-3 d-flex flex-column align-items-center">
            <h1 className="text-larges">{method.toUpperCase()}</h1>
            {content}
        </div>
    );
}

export default ChargeCard;
