import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { Product } from '@app/components/Products';

import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductDisplayContext from './ProductDisplayContext';

import './CarouselDisplay.scss';

function CarouselDisplay({ productClassName, ...props }) {
    const { products } = useContext(ProductDisplayContext);

    const sliderContainerRef = useRef(null);
    const sliderContentRef = useRef(null);

    const [sliderPosition, setSliderPosition] = useState(0);
    const [sliderDif, setSliderDif] = useState(-1);
    const stepSize = 400;

    useEffect(() => {
        const containerWidth = sliderContainerRef.current.offsetWidth;
        const sliderWidth = sliderContentRef.current.scrollWidth;
        setSliderDif(sliderWidth - containerWidth);
    }, []);

    // Ajusta a rolagem após o evento resize do carrossel
    const onCarouselResize = useCallback(() => {
        const containerWidth = sliderContainerRef.current.offsetWidth;
        const sliderWidth = sliderContentRef.current.scrollWidth;
        const sliderRect = sliderContentRef.current.getBoundingClientRect();
        const posX = Math.abs(sliderRect.x);
        const visible = sliderWidth - posX;
        if (visible > containerWidth) {
            setSliderDif(sliderWidth - posX - containerWidth);
        } else {
            const x = Math.max(0, posX - (containerWidth - visible));
            setSliderPosition(x);
        }
    }, []);
    const resizeDetector = useResizeDetector({ refreshMode: 'debounce', refreshRate: 500, onResize: onCarouselResize });

    // Rola o carrossel para a esquerda
    const navigateLeft = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const containerWidth = sliderContainerRef.current.offsetWidth;
        const sliderWidth = sliderContentRef.current.scrollWidth;
        const step = containerWidth > stepSize ? stepSize : 202;
        const x = Math.max(sliderPosition - step, 0);
        setSliderDif(sliderWidth - x - containerWidth);
        setSliderPosition(x);
    };
    // Rola o carrossel para a direita
    const navigateRight = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const containerWidth = sliderContainerRef.current.offsetWidth;
        const sliderWidth = sliderContentRef.current.scrollWidth;
        const dif = sliderWidth - containerWidth;
        if (dif <= 0) return;
        const step = containerWidth > stepSize ? stepSize : 202;
        const x = Math.min(sliderPosition + step, dif);
        setSliderDif(sliderWidth - x - containerWidth);
        setSliderPosition(x);
    };

    return (
        <div {...props} className={`product-display carousel-display ${props?.className || ''}`} ref={resizeDetector.ref}>
            <div className="carousel-container col-12" ref={sliderContainerRef}>
                <button type="button" className="btn product-carousel_nav nav-left" onClick={navigateLeft} disabled={sliderPosition <= 0}>
                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="mx-2 my-1" />
                </button>
                <button type="button" className="btn product-carousel_nav nav-right" onClick={navigateRight} disabled={sliderDif <= 0}>
                    <FontAwesomeIcon icon={faAngleDoubleRight} className="mx-2 my-1" />
                </button>

                <div className="carousel-slider" style={{ left: `-${sliderPosition}px` }} ref={sliderContentRef}>
                    {products &&
                        products?.map((product, id) => {
                            const show = !product?.user_has || (product?.user_has && !product?.extras?.hide_after_buy);
                            return (
                                show && (
                                    <div key={id} className={`${productClassName || ''}`}>
                                        <Product className="h-100" key={id} data={product} />
                                    </div>
                                )
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
export default CarouselDisplay;
