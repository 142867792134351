import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';

import { toast } from 'react-hot-toast';

import { NavigateBackButton } from '@app/components/ui';
import { Label } from 'reactstrap';

function Customer(props) {
    const replaceParams = useReplaceParams;

    const [customerId, setCustomerId] = useState('');
    const [customerData, setCustomerData] = useState({});

    useEffect(() => {
        if ((props?.match?.params?.id || '') === '') return;
        setCustomerId(props?.match?.params?.id);
    }, [props?.match?.params?.id]);

    useEffect(() => {
        if (customerId === '') return;
        // Carrega os dados do produto
        const loadCustomerData = (id) => {
            const url = replaceParams(URLs.admin.customers.show, { nocache: new Date().getTime(), id });
            Api({ method: 'get', url })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setCustomerData(response.data);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do cliente');
                });
        };

        loadCustomerData(customerId);
    }, [customerId, replaceParams]);

    return (
        <ContentWrapper className="products_form" hideTitle>
            <div className="d-flex px-3">
                <NavigateBackButton title="Retornar para a página de produtos" />
            </div>
            <div className="page-content">
                <div className="col-12 d-flex flex-wrap">
                    <div className="px-2 px-1 col-12 col-sm-6">
                        <Label className="field-label">Nome</Label>
                        <Label className="field-value col-12">{customerData?.name || ''}</Label>
                    </div>
                    <div className="px-2 px-1 col-12 col-sm-6">
                        <Label className="field-label" htmlFor="email">
                            Email
                        </Label>
                        <Label className="field-value col-12">{customerData?.email || ''}</Label>
                    </div>
                    <div className="px-2 px-1 col-12 col-sm-6 col-lg-4">
                        <Label className="field-label" htmlFor="phone">
                            Telefone
                        </Label>
                        <Label className="field-value col-12">{customerData?.phone || ''}</Label>
                    </div>
                    <div className="px-2 px-1 col-12 col-sm-6 col-lg-4">
                        <Label className="field-label" htmlFor="document_type">
                            Tipo de documento
                        </Label>
                        <Label className="field-value col-12">{(customerData?.document_type || '').toUpperCase()}</Label>
                    </div>
                    <div className="px-2 py-1 col-12 col-sm-6 col-lg-4">
                        <Label className="field-label" htmlFor="document">
                            Documento
                        </Label>
                        <Label className="field-value col-12">{customerData?.document || ''}</Label>
                    </div>

                    <h3 className="field-group-title col-12 mt-3">Endereço Principal</h3>
                    <div className="col-12 col-sm-8 px-2">
                        <Label className="field-label">Endereço</Label>
                        <Label className="field-value col-12">{customerData?.address?.address || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2">
                        <Label className="field-label">Número</Label>
                        <Label className="field-value col-12">{customerData?.address?.number || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-6 px-2">
                        <Label className="field-label">Complemento</Label>
                        <Label className="field-value col-12">{customerData?.address?.complement || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-6 px-2">
                        <Label className="field-label">Referência</Label>
                        <Label className="field-value col-12">{customerData?.address?.reference || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2">
                        <Label className="field-label">CEP</Label>
                        <Label className="field-value col-12">{customerData?.address?.zip || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-8 px-2">
                        <Label className="field-label">Bairro</Label>
                        <Label className="field-value col-12">{customerData?.address?.neighborhood || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2">
                        <Label className="field-label">Estado</Label>
                        <Label className="field-value col-12">{customerData?.address?.city?.state || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-8 px-2 pb-3">
                        <Label className="field-label">Cidade</Label>
                        <Label className="field-value col-12">{customerData?.address?.city?.name || ''}</Label>
                    </div>

                    <h3 className="field-group-title col-12 mt-3">Endereço de Cobrança</h3>
                    <div className="col-12 col-sm-8 px-2">
                        <Label className="field-label">Endereço</Label>
                        <Label className="field-value col-12">{customerData?.billing_address?.address || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2">
                        <Label className="field-label">Número</Label>
                        <Label className="field-value col-12">{customerData?.billing_address?.number || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-6 px-2">
                        <Label className="field-label">Complemento</Label>
                        <Label className="field-value col-12">{customerData?.billing_address?.complement || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-6 px-2">
                        <Label className="field-label">Referência</Label>
                        <Label className="field-value col-12">{customerData?.billing_address?.reference || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2">
                        <Label className="field-label">CEP</Label>
                        <Label className="field-value col-12">{customerData?.billing_address?.zip || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-8 px-2">
                        <Label className="field-label">Bairro</Label>
                        <Label className="field-value col-12">{customerData?.billing_address?.neighborhood || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2">
                        <Label className="field-label">Estado</Label>
                        <Label className="field-value col-12">{customerData?.billing_address?.city?.state || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-8 px-2 pb-3">
                        <Label className="field-label">Cidade</Label>
                        <Label className="field-value col-12">{customerData?.billing_address?.city?.name || ''}</Label>
                    </div>

                    <h3 className="field-group-title col-12 mt-3">Endereço de Entrega</h3>
                    <div className="col-12 col-sm-8 px-2">
                        <Label className="field-label">Endereço</Label>
                        <Label className="field-value col-12">{customerData?.shipping_address?.address || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2">
                        <Label className="field-label">Número</Label>
                        <Label className="field-value col-12">{customerData?.shipping_address?.number || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-6 px-2">
                        <Label className="field-label">Complemento</Label>
                        <Label className="field-value col-12">{customerData?.shipping_address?.complement || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-6 px-2">
                        <Label className="field-label">Referência</Label>
                        <Label className="field-value col-12">{customerData?.shipping_address?.reference || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2">
                        <Label className="field-label">CEP</Label>
                        <Label className="field-value col-12">{customerData?.shipping_address?.zip || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-8 px-2">
                        <Label className="field-label">Bairro</Label>
                        <Label className="field-value col-12">{customerData?.shipping_address?.neighborhood || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-4 px-2">
                        <Label className="field-label">Estado</Label>
                        <Label className="field-value col-12">{customerData?.shipping_address?.city?.state || ''}</Label>
                    </div>
                    <div className="col-12 col-sm-8 px-2 pb-3">
                        <Label className="field-label">Cidade</Label>
                        <Label className="field-value col-12">{customerData?.shipping_address?.city?.name || ''}</Label>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
}
export default Customer;
