import React, { useMemo } from 'react';
import Paginator from '@app/components/Paginator/Paginator';
import CartItem from './CartItem';

function CartItems({
    isLoading = false,
    isClosed = false,
    items = [],
    updateRequest = () => null,
    navigation = {},
    handleNavigateFirst = () => null,
    handleNavigateNext = () => null,
    handleNavigatePrevious = () => null,
    handleNavigateLast = () => null,
}) {
    const PaginatorComponent = useMemo(() => {
        if ((items || []).length === 0) return null;
        return (
            <Paginator
                pg={navigation?.pg}
                last_page={navigation?.last_page}
                hasPrevious={navigation?.pg > 1}
                hasNext={
                    (navigation?.last_page !== null && navigation?.last_page > navigation?.pg) ||
                    (navigation?.last_page === null && items?.length > 0)
                }
                handleNavigateFirst={handleNavigateFirst}
                handleNavigateNext={handleNavigateNext}
                handleNavigatePrevious={handleNavigatePrevious}
                handleNavigateLast={handleNavigateLast}
            />
        );
    }, [items, navigation, handleNavigateFirst, handleNavigateNext, handleNavigatePrevious, handleNavigateLast]);

    return (
        <div className="page-content cart-content col-12 mx-0 px-0">
            {PaginatorComponent}
            <div className="cart-items">
                {isLoading || items.length === 0 ? (
                    <div className="d-flex cart-product col-12 text-center p-0 m-0" key={0}>
                        <p className="col-12 text-center p-2 m-0">
                            {isLoading ? (
                                <strong>Carregando dados do seu carrinho de compras...</strong>
                            ) : (
                                <strong>O seu carrinho de compras está vazio.</strong>
                            )}
                        </p>
                    </div>
                ) : (
                    items?.map((item, id) => <CartItem key={id} item={item} updateRequest={updateRequest} isClosed={isClosed || false} />)
                )}
            </div>
            {PaginatorComponent}
        </div>
    );
}

export default CartItems;
