import React, { useCallback, useState } from 'react';
import { PopupStyle } from '@app/constants';
import { Button } from 'reactstrap';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import CancelSubscriptionConfirmation from './CancelSubscriptionConfirmation';
import CancelSubscriptionForm from './CancelSubscriptionForm';
import './CancelSubscriptionPopup.scss';

export default function CancelSubscriptionPopup({
    closeModal = () => {},
    updateRequest = () => {},
    modalIsOpen = false,
    token = null,
    description = '',
}) {
    const [cancelConfirmation, setCancelConfirmation] = useState(false);

    const afterOpenModal = () => {
        setCancelConfirmation(false);
    };

    const onCancellationConfirm = useCallback(() => {
        updateRequest();
        setCancelConfirmation(true);
    }, [updateRequest]);

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{ ...PopupStyle, ...{ content: { ...PopupStyle.content, ...{ maxWidth: '800px' } } } }}
            contentLabel="CANCELAR ASSINATURA"
            className="cancel-subscription-popup"
            ariaHideApp={false}
        >
            <div className="col-12 mb-2 d-flex flex-wrap align-items-center border-bottom p-3">
                <h2 className="flex-grow-1 text-center text-large my-0">
                    CANCELAR ASSINATURA <strong>{description || ''}</strong>
                </h2>
                <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={closeModal}>
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                </Button>
            </div>
            <div className="col-12 d-flex p-3">
                {cancelConfirmation === true ? (
                    <CancelSubscriptionConfirmation />
                ) : (
                    <CancelSubscriptionForm
                        closeModal={closeModal}
                        onConfirm={onCancellationConfirm}
                        token={token}
                        description={description}
                    />
                )}
            </div>
        </Modal>
    );
}
