import React from 'react';
import { Button, Form, Label } from 'reactstrap';

import Modal from 'react-modal';
import { Formik } from 'formik';
import * as yup from 'yup';

import { PopupStyle } from '@app/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AddressFields from '@app/components/AddressFields/AddressFields';
import FormikDevTools from '@app/components/FormikDevTools';
import { CustomSwitch } from '@app/components/ui';

function ChangeAddressPopup({
    title = '',
    address_field = 'billingAddress',
    address = {},
    onSubmit = () => {},
    modalIsOpen = false,
    closeModal = () => {},
    autoUpdateProfile = true,
}) {
    const afterOpenModal = () => {};

    const handleFormSubmit = (values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
    };

    const validations = yup.object().shape({
        address: yup.object().shape({
            address: yup.string().min(5, 'O endereço deve conter ao menos 5 caracteres').required('É necessário informar um endereço.'),
            number: yup.string().required('É necessário informar o número.'),
            zip: yup.string().min(8, 'O CEP deve conter ao menos 8 caracteres').required('É necessário informar o CEP.'),
            neighborhood: yup.string().min(5, 'O bairro deve conter ao menos 5 caracteres').required('É necessário informar o bairro.'),
            state: yup.string().required('É necessário selecionar um estado.'),
            city_id: yup.string().required('É necessário selecionar uma cidade.'),
        }),
    });

    const initialValues = {
        address_field: address_field || '',
        update_profile: autoUpdateProfile ? 1 : 0,
        address: {
            address: address?.address || '',
            number: address?.number || '',
            complement: address?.complement || '',
            reference: address?.reference || '',
            zip: address?.zip || '',
            neighborhood: address?.neighborhood || '',
            state: address?.city?.state || '',
            city_id: address?.city?.id || '',
            city_name: address?.city?.name || '',
        },
    };

    const generateRandomValues = (setValues) => {
        const randomNumber = Math.round(10000 + Math.random() * 9999);
        setValues((prev) => ({
            ...prev,
            ...{
                address: {
                    address: `Rua ${randomNumber}`,
                    number: `${randomNumber}`,
                    complement: `Ap ${randomNumber}`,
                    reference: `Ao lado da casa ${randomNumber + 1}`,
                    zip: `${randomNumber}-123`,
                    neighborhood: `Jardim ${randomNumber}`,
                    state: 'SC',
                    city_id: '4205407',
                    city_name: 'Florianópolis',
                },
            },
        }));
    };

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={PopupStyle}
            contentLabel={title}
            className=""
            ariaHideApp={false}
        >
            <div className="col-12 mb-2 d-flex align-items-center">
                <h2 className="flex-grow-1 text-center text-large my-0">{title || 'ALTERAÇÃO DE ENDEREÇO'}</h2>
                <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={closeModal}>
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                </Button>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleFormSubmit}
                enableReinitialize
                render={({ values, errors, isSubmitting, handleSubmit, setValues, setFieldValue }) => (
                    <Form onSubmit={handleSubmit} className="form d-flex flex-wrap">
                        <AddressFields
                            field_context="address"
                            onChange={(address) => {
                                setValues({ ...values, ...{ address } });
                            }}
                            errors={errors?.address || {}}
                        />
                        {!autoUpdateProfile && (
                            <div className="col-12 mt-3 d-flex">
                                <div className="d-flex align-items-center mb-1">
                                    <CustomSwitch
                                        name="update_profile"
                                        className="justify-content-center mx-2"
                                        onChange={(choice) => setFieldValue('update_profile', choice)}
                                        checked={values?.update_profile}
                                        isAsync={false}
                                        disabled={isSubmitting}
                                        data={{ token: values.token, status: values?.update_profile }}
                                    />
                                    <Label className="field-label" htmlFor="update_profile">
                                        Sempre utilizar este endereço como meu endereço de{' '}
                                        {values.address_field === 'billing_address' ? 'cobrança' : 'entrega'}.
                                    </Label>
                                </div>
                            </div>
                        )}
                        <div className="col-12 mt-3 d-flex flex-wrap">
                            <div className="mb-3 px-1 col-12 col-sm-6 text-center">
                                <Button className="col-10" color="danger" disabled={isSubmitting} onClick={closeModal}>
                                    CANCELAR
                                </Button>
                            </div>
                            <div className="mb-3 px-1 col-12 col-sm-6 text-center">
                                <Button type="submit" className="col-10" color="success" disabled={isSubmitting}>
                                    SALVAR
                                </Button>
                            </div>
                        </div>
                        <FormikDevTools>
                            <div className="col-12 mt-3 d-flex flex-wrap justify-content-center">
                                <div className="mb-3 px-1 col-4 text-center">
                                    <Button
                                        className="col-12"
                                        color="secondary"
                                        disabled={isSubmitting}
                                        onClick={() => generateRandomValues(setValues)}
                                    >
                                        RANDOMIZAR
                                    </Button>
                                </div>
                            </div>
                        </FormikDevTools>
                    </Form>
                )}
            />
        </Modal>
    );
}

export default ChangeAddressPopup;
