import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { ErrorMessage, Field, Formik } from 'formik';
import { Button, Form, Label } from 'reactstrap';
import InputMask from 'react-input-mask';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import * as yup from 'yup';
import { useUserManager } from '@app/hooks/useUserManager';

import { toast } from 'react-hot-toast';

// import './suppliers_form.scss';
import { NavigateBackButton } from '@app/components/ui';
import FormikDevTools from '@app/components/FormikDevTools';
import FormSliceTypeFields from './components/slices/FormSliceTypeFields';
import FormSlicePersonalAccess from './components/slices/FormSlicePersonalAccess';

function UsersForm(props) {
    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    // Lista de vídeos disponíveis no VIMEO
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if ((props?.match?.params?.id || '') === '') return;
        setUserId(props?.match?.params?.id);
    }, [props?.match?.params?.id]);

    useEffect(() => {
        if (userId === '') return;
        // Carrega os dados do produto
        const loadUserData = (id) => {
            setIsLoading(true);
            const url = replaceParams(URLs.admin.users.show, { nocache: new Date().getTime(), id });
            Api({ method: 'get', url })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setUserData(response.data);
                        setIsLoading(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do usuário');
                });
        };

        loadUserData(userId);
    }, [userId, replaceParams]);

    // Carrega as labels das features de sessões
    const [featureLabels, setFeatureLabels] = useState(null);
    useEffect(() => {
        if (featureLabels !== null) return;

        const loadFeatureLabels = () => {
            const url = URLs.admin.sections.features;
            Api({ method: 'get', url })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setFeatureLabels(response.data);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do usuário');
                });
        };

        loadFeatureLabels();
    }, [featureLabels]);

    // Faz o submit dos dados do usuário
    const handleUserSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        const method = (values?.id || '') !== '' ? 'put' : 'post';
        const url = replaceParams(URLs.admin.users[method], { id: values.id ? values.id : '' });

        Api({
            method,
            url,
            data: values,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    setUserId(response.data.id);
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados do usuário!');
                setSubmitting(false);
            });
    };

    const formatUsername = (value) =>
        value
            .replace(/[^a-zA-Z0-9\s]/g, ' ')
            .replace(/\s+/g, '_')
            .replace(/\s+/g, ' ')
            .trim();

    /**
     * Inicialização e validação do formulário
     */
    const validations = yup.object().shape({
        name: yup.string().min(3, 'O nome do usuário precisa ter ao menos 3 dígitos.').required('É necessário informar o nome do usuário.'),
        email: yup.string().email('Formato de email inválido').required('É necessário informar um endereço de email.'),
        username: yup.string().required('É necessário informar um nome de usuário.'),
        phone: yup.string().min(11, 'Informe o número de telefone com o DDD').required('É necessário informar um número de telefone.'),
        user_type_id: yup.string().required('É necessário selecionar um tipo de usuário.'),
    });

    const initialValues = {
        id: userData?.id || '',
        name: userData?.name || '',
        email: userData?.email || '',
        username: userData?.username || '',
        phone: userData?.phone || '',
        admin: 1,
        status: userData?.status || 1,
        user_type_id: userData?.user_type_id || '',
        type: userData?.type || null,
        personal_access: userData?.personal_access || [],
        supplier: userData?.supplier || null,
    };

    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper className="users_form" hideTitle>
            <div className="d-flex px-3">
                <NavigateBackButton title="Retornar para a página de usuários" />
                <h1 className="content-title flex-shrink-1 col-12 text-center">
                    {(userData?.id || '') === '' ? 'CADASTRAR NOVO USUÁRIO' : 'ALTERAR DADOS DO USUÁRIO'}
                </h1>
            </div>
            <div className="page-content">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validations}
                    onSubmit={handleUserSubmit}
                    enableReinitialize
                    render={(formikProps) => (
                        <Form onSubmit={formikProps.handleSubmit} className="form d-flex flex-wrap">
                            <Field type="hidden" name="id" />
                            <Field type="hidden" name="stripe_id" />
                            <div className="mb-1 px-1 col-12 col-md-6">
                                <Label className="field-label" htmlFor="name">
                                    Nome<em>*</em>
                                </Label>
                                <Field name="name" type="text" placeholder="" className="col-12" disabled={isLoading} />
                                <ErrorMessage component="span" name="name" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-md-6">
                                <Label className="field-label" htmlFor="email">
                                    Email<em>*</em>
                                </Label>
                                <Field name="email" type="email" placeholder="" className="col-12" disabled={isLoading} />
                                <ErrorMessage component="span" name="email" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-md-4">
                                <Label className="field-label" htmlFor="email">
                                    Login<em>*</em>
                                </Label>
                                <Field
                                    name="username"
                                    type="text"
                                    placeholder=""
                                    className="col-12"
                                    disabled={isLoading}
                                    onChange={(e) => {
                                        formikProps.setFieldValue('username', formatUsername(e.target.value));
                                    }}
                                />
                                <ErrorMessage component="span" name="email" className="text-error text-small px-2" />
                            </div>
                            <div className="mb-1 px-1 col-12 col-sm-6 col-lg-4">
                                <Label className="field-label" htmlFor="phone">
                                    Telefone<em>*</em>
                                </Label>
                                <InputMask
                                    name="phone"
                                    type="text"
                                    className="col-12"
                                    mask="(99) 99999-9999"
                                    placeholder="(__) _____-____"
                                    value={formikProps.values.phone}
                                    onChange={({ target: { value } }) => {
                                        formikProps.setFieldTouched('phone');
                                        formikProps.setValues({
                                            ...formikProps.values,
                                            ...{
                                                phone: value.toUpperCase(),
                                            },
                                        });
                                    }}
                                />
                                <ErrorMessage component="span" name="phone" className="text-error text-small px-2" />
                            </div>

                            <h3 className="field-group-title col-12 mt-3">Acessos por tipo de usuário</h3>
                            <FormSliceTypeFields isLoading={isLoading} featureLabels={featureLabels} />
                            <h3 className="field-group-title col-12 mt-3">Acessos exclusivos do usuário</h3>
                            <FormSlicePersonalAccess isLoading={isLoading} featureLabels={featureLabels} />

                            <div className="col-12 mt-3 d-flex">
                                <div className="mb-3 px-3 col-6 text-center">
                                    <NavigateBackButton className="col-10" color="danger" disabled={formikProps.isSubmitting || isLoading}>
                                        CANCELAR
                                    </NavigateBackButton>
                                </div>
                                <div className="mb-3 col-6 text-center">
                                    <Button
                                        type="submit"
                                        className="col-10"
                                        color="success"
                                        disabled={formikProps.isSubmitting || isLoading}
                                    >
                                        SALVAR
                                    </Button>
                                </div>
                            </div>
                            <FormikDevTools />
                        </Form>
                    )}
                />
            </div>
        </ContentWrapper>
    );
}

export default UsersForm;
