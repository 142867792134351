import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import SubscriptionAditionalItem from './components/SubscriptionAditionalItem';

import './SubscriptionConfigForm.scss';

export default function SubscriptionAditionalItems({ children = null, item_type = '', className = '' }) {
    const { values } = useFormikContext();

    const items = useMemo(() => (values?.items || []).filter((item) => item.item_type === item_type), [values, item_type]);

    if (items.length > 0)
        return (
            <div className={`subscription-config-form col-12 p-2 ${className}`}>
                {items.length > 0 && (
                    <div className={`subscription-aditional-items col-12 ${className}`}>
                        {children}
                        {items.map((item, index) => (
                            <SubscriptionAditionalItem key={index} item={item} showThumb={item_type === 'bump'} className="border-top" />
                        ))}
                    </div>
                )}
            </div>
        );
    return null;
}
