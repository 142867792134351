import React from 'react';

function ContentWrapper({ className = '', hideTitle = false, ...props }) {
    return (
        <div className={`content-wrapper ${className}`} {...props}>
            <div className="flex-col direction-column">
                {(props?.title || '') !== '' && !hideTitle && <h1 className="content-title">{props.title}</h1>}
                <div className="content">{props.children}</div>
            </div>
        </div>
    );
}

export default ContentWrapper;
