import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';
import { LabelTip } from '@app/components/ui';

import './SubscriptionPlanDetails.scss';

function SubscriptionPlanPrice({ price = {}, ...props }) {
    return (
        <div className="subscription-plan-price col-11 col-sm-10 my-3 p-3 d-flex flex-wrap" {...props}>
            <div className="col-7">{price?.name}</div>
            <div className="col-5 text-right">R$ {useFloatToCurrency(price?.price)}</div>
            <p className="plan-price-description col-12 text-center text-small p-0 m-0 fw-bold">{price?.description}</p>
        </div>
    );
}
export default function SubscriptionPlanDetails({ plan = {}, price = {}, className = '' }) {
    const theme = useMemo(
        () => ({
            container: plan?.theme?.bg ? { backgroundColor: plan?.theme?.bg } : {},
            header: plan?.theme?.light && plan?.theme?.title ? { backgroundColor: plan?.theme?.light, color: plan?.theme?.title } : {},
            body: plan?.theme?.text ? { color: plan?.theme?.text } : {},
            price:
                plan?.theme?.light && plan?.theme?.title
                    ? { backgroundColor: plan?.theme?.light, color: plan?.theme?.title, borderColor: plan?.theme?.title }
                    : {},
        }),
        [plan]
    );

    return (
        <div className={`subscription-plan-details d-flex ${className}`} style={theme.container}>
            <div className="col-12 mt-3 px-3 py-1 d-flex flex-column align-items-center">
                <div className="plan-details-header p-1 col-12" style={theme.header}>
                    <h2 className="text-center fw-bold">{plan?.name}</h2>
                    <h3 className="text-center fw-bold">{plan?.description}</h3>
                </div>
                <div className="col-12 p-3" style={theme.body}>
                    {parse(plan?.details || '')}
                </div>
                {(plan?.has_shipping || false) && (
                    <div className="col-12 px-3 my-0 text-small text-error">
                        * Este plano inclui itens que possuem serviço de postagem
                        <LabelTip className="mx-1">
                            Este plano inclui objetos físicos que demandam entrega via serviço de postagem.
                            <br />
                            Verifique seu endereço de entrega antes de prosseguir com a assinatura.
                        </LabelTip>
                    </div>
                )}
                <SubscriptionPlanPrice price={price} style={theme.price} />
            </div>
        </div>
    );
}
