import React, { useContext } from 'react';
import { Table } from 'reactstrap';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';
import CheckPermission from '@app/components/CheckPermission';

import UsersContext from '../components/UsersContext';
import UsersTableRow from './UsersTableRow';

function UsersTable() {
    const { data, handleOrderBy } = useContext(UsersContext);
    return (
        <Table striped bordered hover col="12">
            <thead>
                <Tr handleOrderBy={handleOrderBy || null}>
                    <Th param="name">Nome</Th>
                    <Th param="group">Tipo</Th>
                    <Th param="username">Usuário</Th>
                    <Th param="email">Email</Th>
                    <Th param="email_verified_at">Email verificado</Th>
                    <Th param="phone">Telefone</Th>
                    <Th param="phone_verified_at">Telefone verificado</Th>
                    <Th param="created_at">Data de cadastro</Th>
                    <Th param="status">Status</Th>
                    <CheckPermission permission="reset-password">
                        <th className="text-center">Resetar senha</th>
                    </CheckPermission>
                    <CheckPermission permission="edit">
                        <th className="text-center">Editar</th>
                    </CheckPermission>
                    <CheckPermission permission="delete">
                        <th className="text-center">Deletar usuário</th>
                    </CheckPermission>
                </Tr>
            </thead>
            <tbody>
                {data?.length > 0 ? (
                    data.map((record, id) => (
                        <UsersTableRow key={id} user={record} />

                        // <tr key={id}>
                        //     <td>{record?.name || ''}</td>
                        //     <td>{record?.email || ''}</td>
                        //     <td>{record?.type_label || ''}</td>
                        //     <td>{record?.created_at || ''}</td>
                        //     <td align="center">
                        //         <CheckPermission
                        //             permission="change-status"
                        //             allowed={
                        //                 <CustomSwitch
                        //                     name={`user-status-${record?.id}`}
                        //                     className="justify-content-center"
                        //                     onChange={context?.handleUserStatusChange}
                        //                     checked={record?.status === 1 ? 1 : 0}
                        //                     isAsync
                        //                     data={{ id: record?.id, status: record?.status }}
                        //                 />
                        //             }
                        //             notAllowed={
                        //                 <label className={record.status === 1 ? 'text-success' : 'text-error'}>
                        //                     {record.status === 1 ? 'ATIVO' : 'INATIVO'}
                        //                 </label>
                        //             }
                        //         />
                        //     </td>
                        //     <CheckPermission permission="edit">
                        //         <td align="center">
                        //             <a
                        //                 name="edit-product"
                        //                 href={`/admin/usuarios/alterar/${record?.id}`}
                        //                 className="btn btn-primary-outline"
                        //                 title="Editar usuário"
                        //             >
                        //                 <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                        //             </a>
                        //         </td>
                        //     </CheckPermission>
                        //     <CheckPermission permission="reset-password">
                        //         <td align="center">
                        //             <Button
                        //                 name="reset-password"
                        //                 color="custom"
                        //                 onClick={() => context?.handleUserResetPassword(record?.id)}
                        //                 className="btn btn-primary-outline"
                        //                 title="Resetar a senha do usuário"
                        //             >
                        //                 <FontAwesomeIcon icon={faUserLock} className="mr-2" />
                        //             </Button>
                        //         </td>
                        //     </CheckPermission>
                        // </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={7}>Nenhum registro retornado</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default UsersTable;
