import React, { useEffect, useRef } from 'react';
import { Table } from 'reactstrap';
import { WhatsappLink } from '@app/components/ui';
import { Th, Tr } from '@app/components/OrderedTable/OrderedTable';

function CertificatesTable(props) {
    const data = props?.data;

    const selectedFiles = useRef({});
    useEffect(() => {
        (data || [])?.forEach((file) => {
            selectedFiles.current[file.token] = '';
        });
    }, [data, selectedFiles]);

    return (
        <Table striped bordered hover col="12" className="text-medium">
            <thead>
                <Tr handleOrderBy={props?.handleOrderBy || null}>
                    <Th param="name">Nome</Th>
                    <th>Telefone</th>
                    <Th param="status">Status</Th>
                    <Th param="schedule">Agendamento</Th>
                    <Th param="request_date">Requisição</Th>
                </Tr>
            </thead>
            <tbody>
                {data?.length > 0 ? (
                    data?.map((record, id) => (
                        <tr key={id}>
                            <td className="text-small">
                                <a href={`certificados/${record?.token}/revisao`} title="Abrir esta requisição">
                                    {record?.name}
                                </a>
                            </td>
                            <td>
                                <WhatsappLink number={record?.phone} />
                            </td>
                            <td align="left">
                                <div className="col-12 d-flex align-items-center">
                                    <span className="badge mr-1" style={{ backgroundColor: record?.status_color?.color || '' }}>
                                        &nbsp;
                                    </span>
                                    <label htmlFor="" className="flex-grow-1">
                                        {record?.status_label}
                                    </label>
                                </div>
                            </td>
                            <td className="text-small">{record?.calendar?.datetime}</td>
                            <td className="text-small">{record?.request_date}</td>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={13} align="center">
                            Nenhum registro retornado
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default CertificatesTable;
