import React from 'react';

import {
    HtmlField,
    BooleanField,
    CitiesField,
    DateField,
    DropdownField,
    FloatField,
    GroupField,
    IntegerField,
    MaskedField,
    NumberField,
    Separator,
    StatesField,
    StringField,
    FunctionField,
    FileField,
} from '../Fields';

export const useFormDisplayFieldRenderer = {
    newFunctionField: (field, props) => <FunctionField key={field.name} field={field} formikProps={props} />,

    newStringField: (field, props) => <StringField key={field.name} field={field} formikProps={props} />,

    newHtmlField: (field, props) => <HtmlField key={field.name} field={field} formikProps={props} />,

    newNumberField: (field, props) => <NumberField key={field.name} field={field} formikProps={props} />,

    newIntegerField: (field, props) => <IntegerField key={field.name} field={field} formikProps={props} />,

    newFloatField: (field, props) => <FloatField key={field.name} field={field} formikProps={props} />,

    newBooleanField: (field, props) => <BooleanField key={field.name} field={field} formikProps={props} />,

    newMaskedField: (field, props) => <MaskedField key={field.name} field={field} formikProps={props} />,

    newGroupField: (field, props) => <GroupField key={field.name} field={field} formikProps={props} />,

    newDateField: (field, props) => <DateField key={field.name} field={field} formikProps={props} />,

    newStatesField: (field, props) => <StatesField key={field.name} field={field} formikProps={props} />,

    newDropdownField: (field, props) => <DropdownField key={field.name} field={field} formikProps={props} />,

    newCitiesField: (field, props) => <CitiesField key={field.name} field={field} formikProps={props} />,

    newFileField: (field, props) => <FileField key={field.name} field={field} formikProps={props} />,

    newSeparator: (field, props) => <Separator key={field.name} field={field} formikProps={props} />,
};
