export default function useCheckFieldBehavior(key = '', behavior = {}, values = {}) {
    if (!behavior[key]) return false;
    if (typeof behavior[key] === 'boolean') return behavior[key];

    const { field, op, value } = behavior[key];
    const fieldValue = values?.[field] || undefined;
    switch (op) {
        case '==':
            return fieldValue === value ? behavior[key]?.on_true || true : behavior[key]?.on_false || false;
        case '!=':
            return fieldValue !== value ? behavior[key]?.on_true || true : behavior[key]?.on_false || false;
        case 'in':
            return Array.isArray(value) && [...value].includes(fieldValue)
                ? behavior[key]?.on_true || true
                : behavior[key]?.on_false || false;
        case 'nIn':
            return Array.isArray(value) && ![...value].includes(fieldValue)
                ? behavior[key]?.on_true || true
                : behavior[key]?.on_false || false;
        default:
            return false;
    }
}
