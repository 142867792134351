import React, { useContext, useEffect, useState } from 'react';
import { CustomSwitch } from '@app/components/ui';
import FormEditorFieldPopupContext from '../../FormEditorFieldPopupContext';

function CheckboxValidation({ fieldName, label }) {
    const { validations, setFieldValidation } = useContext(FormEditorFieldPopupContext);

    const [checked, setChecked] = useState(validations?.[fieldName] || false);

    useEffect(() => {
        if (!validations) return;
        setChecked(validations?.[fieldName] !== undefined);
    }, [validations, setChecked, fieldName]);

    return (
        <div className="d-flex align-items-center p-1">
            <CustomSwitch
                name={`field-${fieldName}`}
                className="justify-content-center"
                onChange={(e) => setFieldValidation(fieldName, e ? '' : null)}
                checked={checked}
                isAsync={false}
                data={{ status: checked }}
            />
            <label className="field-label mx-1" htmlFor="field-required">
                {label}
            </label>
        </div>
    );
}
export default CheckboxValidation;
