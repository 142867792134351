import React, { useContext } from 'react';
import DatePicker from 'react-datepicker';

import FormEditorFieldPopupContext from '../../FormEditorFieldPopupContext';

export default function DateValidation({ fieldName, label, ...props }) {
    const { validations, setFieldValidation } = useContext(FormEditorFieldPopupContext);

    return (
        <div {...props}>
            <label className="mr-1 pl-1">{label}</label>
            <DatePicker
                className="col-12 flex-shrink-1"
                dateFormat="dd/MM/yyyy"
                isClearable
                clearButtonTitle="Limpar"
                clearButtonClassName="px-1"
                selected={validations?.[fieldName] || ''}
                onChange={(date) => setFieldValidation(fieldName, date)}
            />
        </div>
    );
}
