import React from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import FilterContainer, { FilterFields, FilterTabLink, FilterTabs } from '@app/containers/FilterContainer/FilterContainer';
import Schedule from './schedule/Schedule';

function CertificatesSchedule() {
    // Funções de alteração dos dados do usuário
    return (
        <ContentWrapper>
            <FilterContainer showTitle={false} className="show-border">
                <FilterFields />
                <FilterTabs>
                    <FilterTabLink key="certificados" to="/admin/certificados">
                        Certificados
                    </FilterTabLink>
                    <FilterTabLink key="agenda" to="/admin/certificados/agenda" active>
                        Agenda
                    </FilterTabLink>
                </FilterTabs>
            </FilterContainer>
            <div className="page-content">
                <div className="col-12">
                    <Schedule />
                </div>
            </div>
        </ContentWrapper>
    );
}

export default CertificatesSchedule;
