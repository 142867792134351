import React, { useContext } from 'react';

import PlansDisplayContext from '../PlansDisplayContext';

import './DefaultPlanDisplay.scss';

export default function DefaultPlanDisplay({ className = '', planClassName = '', ...props }) {
    const { plans, themes, Renderer } = useContext(PlansDisplayContext);
    return (
        <div className={`plan-display default-plan-display ${className}`} {...props}>
            {Renderer !== undefined && plans.length > 0 ? (
                plans?.map((plan, index) => (
                    <div key={`${plan.token}.${index}`} className={`mb-2 width-min ${planClassName || ''}`}>
                        <Renderer plan={plan} theme={themes?.[index] || {}} />
                    </div>
                ))
            ) : (
                <h3 className="text-center align-self-center">Nenhum plano disponível</h3>
            )}
        </div>
    );
}
