import React from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { Redirect, Route, Switch } from 'react-router';
import StyleguideGeneral from './styleguide_general';
import StyleguideButtons from './styleguide_buttons';
import StyleguideColors from './styleguide_colors';
import StyleguideIcons from './styleguide_icons';
import StyleguideForms from './styleguide_forms';
import PageComponents from './PageComponents';

function Styleguide() {
    return (
        <ContentWrapper className="styleguide" title="Guia de estilos">
            <Switch>
                <Route exact path="/admin/styleguide">
                    <Redirect to="/admin/styleguide/buttons" />
                </Route>
                <Route path="/admin/styleguide/general" component={StyleguideGeneral} />
                <Route path="/admin/styleguide/buttons" component={StyleguideButtons} />
                <Route path="/admin/styleguide/components" component={PageComponents} />
                <Route path="/admin/styleguide/forms" component={StyleguideForms} />
                <Route path="/admin/styleguide/colors" component={StyleguideColors} />
                <Route path="/admin/styleguide/icons" component={StyleguideIcons} />
            </Switch>
        </ContentWrapper>
    );
}

export default Styleguide;
