import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessage, Formik, Form } from 'formik';
import InputMask from 'react-input-mask';

import { useUserManager } from '@app/hooks/useUserManager';

import { Container, Button, Label } from 'reactstrap';
import Yup from '@app/validators';
import FormikDevTools from '@app/components/FormikDevTools';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import toast from 'react-hot-toast';
import { AuthFooter, AuthHeader, SideContent, TipMessage } from '../components';

import './ForgotPassword.scss';

export default function ForgotPassword() {
    const history = useHistory();
    const UserManager = useUserManager();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        UserManager.forgot(values.channel, values.value).then((response) => {
            const { status, message, url } = response;
            if (status === 200) {
                setErrorMessage('');
                setSuccessMessage(message);
                if (values.channel !== 'e-mail')
                    history.push({
                        pathname: '/recuperar/confirmacao',
                        state: { channel: values.channel },
                    });
            } else if (url) {
                if (message) toast.error(message || 'Erro tentar recuperar a senha');
                history.push({
                    pathname: url,
                });
            } else {
                setSubmitting(false);
                setErrorMessage(message);
                setSuccessMessage('');
            }
        });
    };

    const validations = Yup.object().shape({
        value: Yup.string().required('É necessário informar um endereço de email.'),
    });

    const initialValues = {
        value: '',
        channel: 'e-mail',
    };

    return (
        <div className="auth-screen">
            <Container className="col-10" style={{ maxWidth: '1200px' }}>
                <div className="forgot-password col-12 justify-content-center">
                    <AuthHeader />
                    <div className="auth-container col-12">
                        <div className="auth-form-container col-12 col-sm-5">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={validations}
                                render={(formikProps) => (
                                    <Form className="login">
                                        <div className="justify-content-center mb-3 col-12">
                                            <div className="col-12">
                                                <h2 className="text-center">Recuperação de senha</h2>
                                            </div>
                                        </div>
                                        <p className="mb-1 text-medium">
                                            Esqueceu a senha? Informe seu endereço de email para receber o link de redefinição de senha.
                                        </p>
                                        <p className="my-2 text-small text-dark-gray">
                                            Você pode ter que checar sua caixa de spam caso não encontre o email enviado.
                                        </p>
                                        {/* <div className="col-12 d-flex flex-column mb-1">
                                            <Label>Endereço de e-mail:</Label>
                                            <Field
                                                type="text"
                                                name="value"
                                                placeholder=""
                                                className="login-field col-12 mb-1"
                                                disabled={formikProps.isSubmitting}
                                            />
                                            <ErrorMessage component="span" name="value" className="text-error text-small" />
                                        </div> */}
                                        <div className="col-12 d-flex flex-column mb-3">
                                            <Label className="field-label" htmlFor="phone">
                                                Celular / WhatsApp
                                            </Label>
                                            <InputMask
                                                name="username"
                                                type="text"
                                                className="col-12"
                                                mask="(99) 99999-9999"
                                                placeholder="(__) _____-____"
                                                value={formikProps.values.username}
                                                disabled={formikProps.isLoading}
                                                onChange={({ target: { value } }) => formikProps.setFieldValue('value', value)}
                                            />
                                            <ErrorMessage component="span" name="value" className="text-error text-small" />
                                        </div>
                                        <div className="col-12 d-flex flex-column mb-1">
                                            <Label>Escolha um canal para receber o código de verificação:</Label>
                                            <select
                                                name="channel"
                                                value={formikProps.values.channel}
                                                disabled={formikProps.isSubmitting}
                                                onChange={({ target: { value } }) => {
                                                    setErrorMessage('');
                                                    setSuccessMessage('');
                                                    formikProps.setFieldValue('channel', value);
                                                    formikProps.setFieldValue('code', '');
                                                }}
                                            >
                                                <option value="e-mail">Receber link por E-mail</option>
                                                <option value="whatsapp">Receber código por Whatsapp</option>
                                                <option value="sms">Receber código por SMS</option>
                                            </select>
                                        </div>
                                        {errorMessage && <p className="mb-3 message-error text-small">{errorMessage}</p>}
                                        {successMessage && <p className="mb-3 message-success text-small">{successMessage}</p>}

                                        <div className="col-12 d-flex mb-3">
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Button
                                                    type="submit"
                                                    className="col-12"
                                                    color="primary"
                                                    disabled={formikProps.isSubmitting}
                                                >
                                                    ENVIAR {formikProps.values.channel === 'e-mail' ? 'LINK' : 'CÓDIGO'}
                                                </Button>
                                            </div>
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Link to="/login" className="btn btn-primary-outline col-12">
                                                    EFETUAR LOGIN
                                                </Link>
                                            </div>
                                        </div>
                                        <TipMessage>
                                            <em>***</em> Por favor, certifique-se de informando o mesmo e-email que usou para se cadastrar.
                                        </TipMessage>
                                        <FormikDevTools />
                                    </Form>
                                )}
                            />
                        </div>
                        <SideContent className="col-12 col-sm-7" />
                    </div>
                </div>
            </Container>
            <AuthFooter />
        </div>
    );
}
