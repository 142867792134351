import React, { useState } from 'react';
import { DropDown, DropdownItem } from '@app/components/Autocomplete';

import { toast } from 'react-hot-toast';

import { useUserManager } from '@app/hooks/useUserManager';
import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

function BankSelector({ disabled = false, bank_name = '', onChange = () => {} }) {
    const replaceParams = useReplaceParams;
    const UserManager = useUserManager();
    const { token } = UserManager;

    /**
     * Consulta por bancos
     */
    const [banksSuggestions, setBanksSuggestions] = useState([]);
    const onBanksSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason !== 'input-changed') return;
        try {
            Api({
                method: 'get',
                url: replaceParams(URLs.services.banks.list, { name: value || '' }),
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) => {
                    setBanksSuggestions(response.data);
                })
                .catch((err) => {
                    toast.error(err?.data?.message || 'Erro inesperado ao tentar consultar o banco.');
                });
        } catch (error) {
            toast.error(error || 'Erro inesperado ao tentar consultar o banco.');
        }
    };

    // Seleciona um banco da lista
    const handleBankSuggestionSelected = (suggestion, setValues) => {
        if (setValues === null) return;
        setValues({
            bank: suggestion.code,
            bank_name: suggestion.name,
        });
        setBanksSuggestions([]);
        return suggestion.name;
    };
    // Render the suggested user list
    const renderBanksSuggestions = (suggestion) => (
        <DropdownItem tag="label" className="suggestion-item" key={suggestion.code}>
            {suggestion.name}
        </DropdownItem>
    );

    return (
        <DropDown
            className="col-12"
            disabled={disabled || false}
            name="bank"
            placeholder="Buscar banco"
            selectedValue={bank_name || ''}
            suggestions={banksSuggestions}
            onSuggestionsFetchRequested={onBanksSuggestionsFetchRequested}
            renderSuggestion={renderBanksSuggestions}
            getSuggestionValue={(suggestion) => {
                handleBankSuggestionSelected(suggestion, onChange);
            }}
        />
    );
}

export default BankSelector;
