import React, { useState, useEffect } from 'react';
import { Form } from 'reactstrap';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import { useFetch } from '@app/hooks/useFetch';

import { URLs, useReplaceParams } from '@app/constants';
import { useDispatch } from 'react-redux';
import { updateSummary } from '@app/store/SummarySlice';

import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import Paginator from '@app/components/Paginator/Paginator';

import OrderListItem from './components/OrderListItem';
import './orders.scss';

function OrderList() {
    const replaceParams = useReplaceParams;

    const summaryDispatch = useDispatch();

    let startYear = new Date().getFullYear() - 5;
    const yearList = Array(parseInt(new Date().getFullYear() + 1) - startYear)
        .fill()
        .map(() => {
            startYear += 1;
            return startYear;
        });

    /**
     * Carregando os dados do pedido
     */
    const [params, setParams] = useState({
        status: 'all',
        year: 'all',
        pg: 1,
        nocache: new Date().getTime(),
    });
    const { data, error, last_page } = useFetch(replaceParams(URLs.club.purchases.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
        if (data) summaryDispatch(updateSummary({ cart: { qtd: data?.items?.length || 0, amount: data?.amount || 0 } }));
    }, [data, error, summaryDispatch]);

    /**
     * Configuração de busca
     */
    const onSearchByYearChange = (e) => setParams({ ...params, ...{ year: e.target.value, pg: 1 } });
    const onSearchByStatusChange = (e) => setParams({ ...params, ...{ status: e.target.value, pg: 1 } });

    /**
     * Configurações de navegação
     */
    const handleNavigateFirst = () => setParams({ ...params, ...{ pg: 1 } });
    const handleNavigateLast = () => setParams({ ...params, ...{ pg: last_page } });
    const handleNavigateNext = () => setParams({ ...params, ...{ pg: data?.length > 0 ? params.pg + 1 : params.pg } });
    const handleNavigatePrevious = () => setParams({ ...params, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } });

    return (
        <ContentWrapper className="payment col-8">
            <h2 className="text-center col-12 bg-primary br-4 p-1 text-white text-large mt-1">MEUS PEDIDOS</h2>
            <FilterContainer title="" showTitle={false}>
                <FilterFields className="col-12 col-md-6 col-lg-4">
                    <Form className="login d-flex flex-wrap mb-3 col-12 ">
                        <fieldset className="col-12 col-md-6 col-lg-3">
                            <label htmlFor="orderBy">Status:</label>
                            <select name="status" id="filter-status" onChange={onSearchByStatusChange}>
                                <option value="all" data-order-dir="asc">
                                    Todos
                                </option>
                                <option value="created" data-order-dir="asc">
                                    Novos Pedidos
                                </option>
                                <option value="paid" data-order-dir="asc">
                                    Pedidos Concluídos
                                </option>
                                <option value="pending" data-order-dir="asc">
                                    Aguardando Pagamento
                                </option>
                                <option value="canceled" data-order-dir="asc">
                                    Pedidos Cancelados
                                </option>
                            </select>
                        </fieldset>
                        <fieldset className="col-12 col-md-6 col-lg-3">
                            <label htmlFor="year">Ano</label>
                            <select name="year" id="filter-year" defaultValue={startYear} onChange={onSearchByYearChange}>
                                <option value="all" data-order-dir="asc">
                                    Todos
                                </option>
                                {yearList.map((year, key) => (
                                    <option key={key} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </fieldset>
                        <fieldset className="col-12 col-lg-1 text-center">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <div className="page-content order-items col-12 mx-0 px-2">
                <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center p-2">
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                </div>
                <div className="d-flex flex-wrap">
                    {data?.length > 0 ? (
                        data?.map((item, id) => <OrderListItem key={id} item={item} />)
                    ) : (
                        <div className="d-flex payment-product col-12 text-center p-0 m-0" key={0}>
                            <p className="col-12 text-center p-2 m-0">
                                <strong>Você ainda não possui nenhum pedido.</strong>
                            </p>
                        </div>
                    )}
                </div>
                <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center p-2">
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                </div>
            </div>
        </ContentWrapper>
    );
}

export default OrderList;
