import React from 'react';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';

export default function ProductPriceListCols({ prices = [] }) {
    const floatToCurrency = useFloatToCurrency;

    return (
        <td align="left" colSpan="3" data-title="Preço Un.(R$)">
            <span className="fw-bold col-12 text-center">Opções Disponíveis</span>
            <br />
            {prices.map((option, index) => (
                <div key={index} className="col-12 border mb-1 p-1">
                    Opção: <span className="fw-bold ml-1">{option.label}</span>
                    <br />
                    Qtd.:<span className="fw-bold ml-1">{option.qtd}</span>
                    <br />
                    Preço Unitário: <span className="fw-bold ml-1">R$ {floatToCurrency(option.price)}</span>
                </div>
            ))}
        </td>
    );
}
