import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'auth',
    initialState: {
        messages: [
            //      {type: 'error', message: 'Primeiro Teste de mensagem!'},
            //      {type: 'success', message: 'Segundo Teste de mensagem!'}
        ],
        isRefreshing: false,
        hasPendencies: false,
    },
    reducers: {
        setIsRefreshing(state, { payload }) {
            return { ...state, isRefreshing: payload };
        },
        addMessage(state, { payload }) {
            const arr = JSON.parse(JSON.stringify(state.messages));
            arr.push(payload);
            return { ...state, messages: arr };
        },
        removeLastMessage(state) {
            const arr = JSON.parse(JSON.stringify(state.messages));
            arr.pop();
            return { ...state, messages: arr };
        },
        clearAllMessages(state) {
            return { ...state, messages: [] };
        },
        setHasPendencies(state, { payload }) {
            return { ...state, hasPendencies: payload };
        },
    },
});

export const { addMessage, removeLastMessage, clearAllMessages, setHasPendencies } = slice.actions;
export const selectAuth = (state) => state.auth;

export default slice.reducer;
