import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Label, Table } from 'reactstrap';

import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, useFormikContext } from 'formik';
import { LabelTip, CurrencyInputField } from '@app/components/ui';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';
import SubscriptionPlansContext from '../../components/SubscriptionPlansContext';

function TableHead() {
    return (
        <thead>
            <tr>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Intervalo</th>
                <th>Parcelamento</th>
                <th className="text-right">Valor (R$)</th>
                <th className="text-right">Desconto (%)</th>
                <th className="text-right">Remover</th>
            </tr>
        </thead>
    );
}

function TableRow({ price = {}, onPriceDelete = () => {}, ...props }) {
    const { priceIntervals } = useContext(SubscriptionPlansContext);

    const intervalLabel = useMemo(() => {
        const count = price?.interval_count || 1;
        const interval = price?.interval || 'month';
        return `Cobrar a cada ${count} ${priceIntervals(count, interval).label}`;
    }, [price, priceIntervals]);

    return (
        <tr {...props}>
            <td>{price.name}</td>
            <td>{price.description}</td>
            <td>{intervalLabel}</td>
            <td>
                Em até {price.installments || 1}x de R$&nbsp;{useFloatToCurrency(price.price / (price.installments || 1))}
            </td>
            <td align="right">{useFloatToCurrency(price.price)}</td>
            <td align="right" className={price.discount < 0 ? 'text-error' : ''}>
                {useFloatToCurrency(price.discount)}
            </td>
            <td align="right" data-title="Remover">
                <Button
                    name="remove-product"
                    color="custom"
                    className="btn btn-primary-outline"
                    title="Remover produto do pacote"
                    onClick={() => onPriceDelete(price)}
                >
                    <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                </Button>
            </td>
        </tr>
    );
}

function FormSlicePrices({ isLoading = false }) {
    const { priceIntervals } = useContext(SubscriptionPlansContext);
    const { values, setValues } = useFormikContext();
    const [errors, setErrors] = useState({});

    const [name, setName] = useState('');
    const nameRef = useRef(null);
    const [description, setDescription] = useState('');
    const [intervalCount, setIntervalCount] = useState(1);
    const [interval, setIntervalValue] = useState('month');
    const [totalPrice, setTotalPrice] = useState(0.0);
    const [price, setPrice] = useState(0.0);
    const [discount, setDiscount] = useState(0.0);
    const [installments, setInstallments] = useState(1);

    useEffect(() => {
        const interval_reference = priceIntervals(values?.interval_count || 1, values?.interval || 'month');
        const price_interval = priceIntervals(intervalCount, interval);
        const multiplier = intervalCount * (price_interval.multiplier / interval_reference.multiplier);
        const result = (values?.price || 0) * multiplier;
        setPrice(result);
        setTotalPrice(result);
        setDiscount(0.0);
    }, [values, intervalCount, interval, priceIntervals]);

    const applyPriceValue = (price) => {
        const newDiscount = (1 - price / totalPrice) * 100;
        setPrice(price);
        setDiscount(newDiscount);
    };

    const setDiscountValue = (discount) => {
        const newPrice = totalPrice - (discount / 100) * totalPrice;
        setPrice(newPrice);
        setDiscount(discount);
    };

    // Remove um produto da lista
    const handleDeletePriceClick = (price) => {
        const newPrices =
            values.prices?.filter((item) => item.interval_count !== price.interval_count || item.interval !== price.interval) || [];
        setValues((prev) => ({
            ...prev,
            ...{
                prices: newPrices,
            },
        }));
    };
    // Inclui um preço na lista
    const handleAddNewPrice = (e) => {
        e.preventDefault();

        const errors = {};
        if (name.replaceAll(' ', '') === '') errors.name = 'É necessário informar um nome';
        if (Number(intervalCount).isNaN || intervalCount === 0) errors.interval = 'O interval de cobrança deve ser maior do que 0';
        if (Number(price).isNaN || price === 0) errors.price = 'O valor do plano deve ser maior do que 0';
        if (Number(installments).isNaN || installments === 0) errors.installments = 'A quantidade de parcelas deve ser maior do que 0';
        setErrors(errors);
        if (Object.keys(errors).length > 0) return;

        setValues((prev) => {
            const prices = prev.prices.filter((item) => item.interval !== interval || item.interval_count !== intervalCount);
            return {
                ...prev,
                ...{
                    prices: [
                        ...prices,
                        {
                            name,
                            description,
                            interval_count: intervalCount,
                            interval,
                            installments,
                            price,
                            discount,
                        },
                    ],
                },
            };
        });
        setName('');
        setDescription('');
        setIntervalValue('month');
        setIntervalCount(1);
        setInstallments(1);
        setPrice(values?.price || 0.0);
        setDiscount(0.0);
        nameRef.current.focus();
    };

    return (
        <>
            <h3 className="field-group-title col-12 mt-3">Opções de preços por período</h3>
            <div className="mb-1 px-1 col-12 d-flex flex-wrap">
                <div className="col-12 d-flex flex-wrap">
                    <div className="d-flex flex-wrap px-1 py-1 col-12 col-sm-4">
                        <Label className="field-label" htmlFor="name">
                            Nome
                            <LabelTip className="mx-1">
                                Nome que identifica o período de cobrança.
                                <br />
                                <strong>Ex.:</strong>Plano Mensal
                            </LabelTip>
                        </Label>
                        <input
                            ref={nameRef}
                            type="text"
                            maxLength={20}
                            placeholder=""
                            className="col-12"
                            disabled={isLoading}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <span className="col-12 text-error text-small">{errors?.name || <>&nbsp;</>}</span>
                    </div>
                    <div className="d-flex flex-wrap px-1 py-1 col-12 col-sm-4 d-flex">
                        <Label className="col-12 field-label" htmlFor="description">
                            Cobrar a cada
                        </Label>
                        <div className="col-12 d-flex">
                            <input
                                type="number"
                                name="interval_count"
                                placeholder=""
                                className="col-4 text-right"
                                disabled={isLoading}
                                defaultValue={intervalCount}
                                min={1}
                                onChange={(e) => setIntervalCount(parseInt(e.target.value) || 1)}
                            />
                            <select
                                name="interval"
                                className="col-8 flex-shrink-1 ml-1"
                                defaultValue={interval}
                                onChange={(e) => setIntervalValue(e.target.value)}
                            >
                                <option value="day">Dia(s)</option>
                                <option value="week">Semana(s)</option>
                                <option value="month">Mes(es)</option>
                                <option value="year">Ano(s)</option>
                            </select>
                        </div>
                        <span className="col-12 text-error text-small">{errors?.interval || <>&nbsp;</>}</span>
                    </div>
                    <div className="d-flex flex-wrap px-1 py-1 col-12 col-sm-2 d-flex">
                        <Label className="field-label" htmlFor="description">
                            Valor (R$)
                        </Label>
                        <CurrencyInputField
                            name="interval_count"
                            className="col-12"
                            disabled={isLoading}
                            value={price}
                            onAfterChange={(e) => applyPriceValue(e.target.floatValue)}
                        />
                        <span className="text-error text-small">{errors?.price || <>&nbsp;</>}</span>
                    </div>
                    <div className="d-flex flex-wrap px-1 py-1 col-12 col-sm-2 d-flex">
                        <Label className="field-label" htmlFor="description">
                            Desconto (%)
                        </Label>
                        <CurrencyInputField
                            name="interval_count"
                            className="col-12"
                            disabled={isLoading}
                            value={discount}
                            onAfterChange={(e) => {
                                setDiscountValue(e.target.floatValue);
                            }}
                        />
                        <span className="text-error text-small">&nbsp;</span>
                    </div>
                    <div className="d-flex flex-wrap px-1 py-1 col-12 col-sm-2">
                        <Label className="field-label" htmlFor="description">
                            Parcelamento
                            <LabelTip className="mx-1">Informe a quantidade máxima de parcelas.</LabelTip>
                        </Label>
                        <input
                            type="number"
                            min={1}
                            max={12}
                            placeholder=""
                            className="col-12 text-right"
                            disabled={isLoading}
                            value={installments}
                            onChange={(e) => setInstallments(e.target.value)}
                        />
                        <span className="col-12 text-error text-small">{errors?.installments || <>&nbsp;</>}</span>
                    </div>

                    <div className="d-flex flex-wrap px-1 py-1 col-12 col-sm-8">
                        <Label className="field-label" htmlFor="description">
                            Descrição
                            <LabelTip className="mx-1">
                                Descrição adicional do preço.
                                <br />
                                <strong>Ex.:</strong>Ganhe 5% de desconto no Plano Mensal
                            </LabelTip>
                        </Label>
                        <input
                            type="text"
                            maxLength={300}
                            placeholder=""
                            className="col-12"
                            disabled={isLoading}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <span className="col-12 text-error text-small">{errors?.description || <>&nbsp;</>}</span>
                    </div>
                    <div className="d-flex flex-wrap px-1 py-1 col-12 col-sm-2 d-flex">
                        <Label className="field-label col-12" htmlFor="">
                            &nbsp;
                        </Label>
                        <button
                            type="button"
                            className="btn btn-primary mb-1 col-12"
                            name="btn-add-price"
                            title="Adicionar preço ao plano"
                            onClick={handleAddNewPrice}
                        >
                            <FontAwesomeIcon icon={faPlus} className="mr-1" />
                            Adicionar
                        </button>
                        <span className="text-error text-small col-12">&nbsp;</span>
                    </div>
                </div>
                <div className="col-12">
                    <Table striped hover col="12" className="text-small table-responsive">
                        <TableHead />
                        <tbody>
                            {values.prices.length > 0 ? (
                                values.prices?.map((price, index) => (
                                    <TableRow key={index} price={price} onPriceDelete={handleDeletePriceClick} />
                                ))
                            ) : (
                                <tr key={0}>
                                    <td colSpan={13} align="center">
                                        Nenhum preço cadastrado para o plano
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <ErrorMessage component="span" name="prices" className="col-12 text-error text-small" />
            </div>
        </>
    );
}
export default FormSlicePrices;
