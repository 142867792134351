import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SectionContext } from '@app/contexts';
import parse from 'html-react-parser';

import { Link } from 'react-router-dom';
import { NavItem, NavLink, Nav } from 'reactstrap';
import { useUserManager } from '@app/hooks/useUserManager';
import { fas, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconClose from '@app/assets/images/sidebar-close-icon.svg';
import iconOpen from '@app/assets/images/sidebar-open-icon.svg';
import UserAccountLink from './components/UserAccountLink';

function SideMenuLink({ context, section, currentSectionToken }) {
    return (
        <NavLink
            tag={Link}
            to={{
                pathname: `${context}${section.url}`,
                section: { name: '' },
            }}
            disabled={!section.enabled}
            title={section.description}
            className={currentSectionToken === section.token ? 'active' : ''}
        >
            <div className="side-menu-alt-content">
                <div className="side-menu-icon">
                    <FontAwesomeIcon icon={fas[section.icon]} className="mx-2 my-1" />
                </div>
                <span className="side-menu-short-label">{section.short_label}</span>
            </div>
            <span className="side-menu-label">{parse(section.label)}</span>
        </NavLink>
    );
}

function SideMenuGroup({ context, section, currentSectionToken }) {
    const [isOpen, setIsOpen] = useState(!!section.config?.open);
    return (
        <>
            <button type="button" className="nav-link d-flex nav-link-submenu" onClick={() => setIsOpen((prev) => !prev)}>
                <span className="side-menu-label flex-grow-1">{parse(section.label)}</span>
                <FontAwesomeIcon className="mx-2" icon={isOpen ? faAngleUp : faAngleDown} />
            </button>
            <div className="side-menu-group-container" style={{ display: isOpen ? '' : 'none' }}>
                {section?.subsections
                    .filter((section) => !!section?.menu || false)
                    .map((section, index) =>
                        (section?.subsections || []).length === 0 ? (
                            <SideMenuLink key={index} context={context} section={section} currentSectionToken={currentSectionToken} />
                        ) : (
                            <SideMenuGroup key={index} context={context} section={section} currentSectionToken={currentSectionToken} />
                        )
                    )}
            </div>
        </>
    );
}

export default function PublicSidebar(props) {
    const { sections, currentSection } = useContext(SectionContext);

    const menu = useMemo(
        () =>
            sections
                .filter((section) => !!section?.menu || false)
                .map((section, id) => (
                    <NavItem key={id}>
                        {(section?.subsections || []).length === 0 ? (
                            <SideMenuLink context="" section={section} currentSectionToken={currentSection?.token} />
                        ) : (
                            <SideMenuGroup context="" section={section} currentSectionToken={currentSection?.token} />
                        )}
                    </NavItem>
                )),
        [sections, currentSection]
    );

    const UserManager = useUserManager();
    const { isClosed, toggleSideBar } = props;

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 770 && !isClosed) toggleSideBar();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isClosed, toggleSideBar]);

    return (
        <div className={`sidebar public-sidebar ${isClosed ? 'is-closed' : ''}`}>
            <div className="side-menu">
                <button
                    type="button"
                    name="menu-toggler"
                    color="custom"
                    className="btn btn-toggler"
                    title={`${isClosed ? 'Abrir Menu' : 'Fechar Menu'}`}
                    onClick={() => {
                        toggleSideBar();
                    }}
                >
                    <img src={isClosed ? iconOpen : iconClose} alt="Abrir/Fechar menu de navegação" />
                </button>
                <UserAccountLink isClosed={isClosed} userName={UserManager.name} />
                <Nav vertical className="list-unstyled pb-3">
                    {menu}
                </Nav>
            </div>
        </div>
    );
}
