import React from 'react';
import truck from '@app/assets/images/volvo-fh-540-crepusculo.jpg';

const containerStyle = {
    backgroundImage: `url(${truck})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
};

export default function SideContent(props) {
    return (
        <div className={`auth-content-container text-center ${props?.className || ''}`} style={containerStyle}>
            <div>
                <p className="mb-0 p-1">
                    Se você é <strong>Caminhoneiro, MEI ou Transportadora Pequena / Média</strong> aqui você acha o que precisa para seu
                    trabalho diário: Contabilidade (MEI, Simples, Lucro Presumido), Seguros de Cargas e Veículos, Licenças ANTT,
                    Certificados Digitais, Emissão de CTes e NFes, Cursos, Softwares de Transportes e muito mais...
                    <br />
                    <br />
                    <strong>Participe para Economizar ou Seja um Parceiro de Serviços e Produtos</strong>
                </p>
            </div>
        </div>
    );
}
