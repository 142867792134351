import React from 'react';
import AppFooter from '@app/pages/shared/AppFooter/AppFooter';

export default function SubscriptionsPolicy() {
    return (
        <section className="d-flex flex-column align-items-center">
            <div className="d-flex col-10 col-md-8" title="">
                <div className="page-content my-3">
                    <h1 className="text-center mb-3">Política de Assinaturas</h1>
                    <p>
                        O site do <strong>Doutor Caminhoneiro</strong> da <strong>TRANSPROLOG</strong> é uma plataforma que oferece uma
                        série de produtos e serviços destinados a caminhoneiros, pequenas e médias empresas de transportes.
                    </p>
                    <p>
                        Os planos de assinatura disponibilizados pelo site do <strong>Doutor Caminhoneiro</strong> estão sujeitos a
                        melhorias constantes que visam oferecer os melhores serviços e produtos aos nossos clientes e parceiros.
                    </p>
                    <p>
                        Tais mudanças são referentes apenas a novas assinaturas e não tem efeito sobre produtos e serviços de assinaturas
                        vigentes.
                    </p>

                    <h3 className="fw-bold">Política de Cancelamento</h3>
                    <p>
                        Os produtos e serviços adquiridos no site do <strong>Doutor Caminhoneiro</strong> estão sujeitos ao{' '}
                        <strong>Direito de Arrependimento</strong> previsto no Código de Defesa do Consumidor (CDC – Lei nº 8.078 de 11 de
                        Setembro de 1990) e podem ser cancelados no prazo de até 7 dias após sua aquisição, sendo seu valor restituído
                        integralmente.
                    </p>

                    <h3 className="fw-bold">Prazo para restituição dos valores</h3>
                    <p>
                        O Cliente é informado que em casos de exercício do Direito de Arrependimento, o reembolso dos valores está sujeito à
                        aprovação da operadora de cartão de crédito e será influenciado pela data de fechamento da fatura do Cliente. O
                        reembolso poderá ser efetuado em até duas faturas subsequentes. Se o pagamento foi realizado por meio de boleto
                        bancário, a devolução dos valores poderá ocorrer no prazo de até 20 (vinte) dias úteis, a contar da data em que o
                        Cliente fornecer suas informações bancárias ao site do <strong>Doutor Caminhoneiro</strong>. É importante ressaltar
                        que esse período está sujeito à política do emissor do cartão e pode variar conforme as normas da instituição
                        bancária.
                    </p>
                    <p>
                        O reembolso pode ocorrer de 3 formas, a depender da operadora do cartão:
                        <ul>
                            <li>
                                O banco tem a possibilidade de estornar o valor integral na fatura atual, aumentando o limite disponível. As
                                parcelas remanescentes permanecerão visíveis.
                            </li>
                            <li>
                                O banco também pode optar por cobrar todas as parcelas e efetuar um estorno completo apenas na última fatura
                                de cobrança.
                            </li>
                            <li>Outra opção é o banco realizar o estorno de forma parcelada, simultaneamente às cobranças das parcelas.</li>
                        </ul>
                    </p>

                    <h3 className="fw-bold">Após 7 dias da assinatura</h3>
                    <p>
                        O Cliente que não deseja mais obter os produtos e serviços contemplados no plano de assinatura realizado tem a
                        possibilidade de solicitar o cancelamento do mesmo. Contudo, neste caso, não haverá mais a possibilidade de
                        restituição do valor e os serviços oferecidos continuarão disponíveis até o final do período de contratação do
                        plano.
                        <br />
                        <br />
                        <strong>Exemplos:</strong>
                        <p>
                            <i>
                                Cancelamento de planos anuais: Um plano assinado no dia 01/02 poderá ser cancelado com restituição integral
                                dos valores, segundo previsto no Código de Defesa do Consumidor, até o dia 08/02 do mesmo ano. Caso o
                                cancelamento seja solicitado após esta data, o valor não será restituído, porém os produtos e serviços
                                contemplados no plano estarão disponíveis até 01/02 do ano seguinte.
                            </i>
                        </p>
                        <p>
                            <i>
                                Cancelamento de planos mensais: Um plano assinado no dia 01/02 poderá ser cancelado com restituição integral
                                dos valores, segundo previsto no Código de Defesa do Consumidor, até o dia 08/02 do mesmo ano. Caso o
                                cancelamento seja solicitado após esta data, o valor não será restituído, porém os produtos e serviços
                                contemplados no plano estarão disponíveis até 01/03 do mesmo ano.
                            </i>
                        </p>
                    </p>

                    <h3 className="fw-bold">Como solicitar o cancelamento de uma assinatura</h3>
                    <p>
                        Para solicitar o cancelamento de uma assinatura vigente, o Cliente deverá seguir os passos abaixo:
                        <ol>
                            <li>
                                Acesse o site do <strong>Doutor Caminhoneiro</strong>;
                            </li>
                            <li>Efetue login em sua conta utilizando usuário e senha;</li>
                            <li>Acesse a página de seu perfil através do menu lateral clicando em seu nome;</li>
                            <li>
                                Na tela que aparece, clique na aba <strong>Assinaturas</strong> no canto superior direito da página;
                            </li>
                            <li>
                                Identifique a assinatura para a qual deseja solicitar o cancelamento e clique no botão{' '}
                                <strong>Visualizar detalhes da assinatura</strong> para acessar a página de detalhes da assinatura;
                            </li>
                            <li>
                                Uma vez nesta página, basta rolar até o final e clicar no botão <strong>CANCELAR ASSINATURA</strong>
                            </li>
                        </ol>
                    </p>
                    <p>
                        Para sua segurança, o site do <strong>Doutor Caminhoneiro</strong> solicita um procedimento de verificação em dois
                        fatores, por isso, na popup que aparece, siga o procedimento abaixo:
                    </p>
                    <p>
                        <ol>
                            <li>
                                Selecione o motivo pelo qual deseja solicitar o cancelamento. Este passo é opcional, mas nós do{' '}
                                <strong>Doutor Caminhoneiro</strong> ficaríamos muito felizes em ter o seu feedback para que possamos
                                melhorar nossos atendimento cada vez mais;
                            </li>
                            <li>
                                Escolha um canal para receber o código de confirmação do cancelamento. As opções disponíveis são{' '}
                                <strong>E-mail</strong>, <strong>Whatsapp</strong> e <strong>SMS</strong>;
                            </li>
                            <li>
                                Clique no botão <strong>RECEBER CÓDIGO</strong>;
                            </li>
                            <li>
                                Verifique o canal escolhido, copie o código enviado e cole no campo <strong>CÓDIGO DE CONFIRMAÇÃO</strong>;
                            </li>
                            <li>
                                Agora basta clicar no botão <strong>CONFIRMAR</strong> e pronto, o cancelamento da sua assinatura será
                                realizado.
                            </li>
                        </ol>
                    </p>

                    <h3 className="fw-bold">Por quê utilizamos a confirmação em dois fatores para realizar o cancelamento</h3>
                    <p>
                        O <strong>Doutor Caminhoneiro</strong> da <strong>TRANSPROLOG</strong> oferece uma série de produtos e serviços para
                        profissionais da área de transportes. Tais produtos e serviços são fundamentais para o correto funcionamento das
                        empresas de nossos Clientes de modo que o cancelamento não intencional da assinatura acarretaria uma interrupção do
                        oferecimento destes serviços.
                    </p>
                    <p>
                        A confirmação em dois fatores é uma camada de segurança a mais para garantir que a solicitação do cancelamento da
                        assinatura seja feita de forma intencional e pela pessoa responsável pela conta.
                    </p>

                    <h3 className="fw-bold">Alterações de planos</h3>
                    <p>
                        Embora o <strong>Doutor Caminhoneiro</strong> ofereça uma diversidade de planos de assinatura para seviços e
                        produtos diferentes, alguns planos não podem ser realizados simultaneamente. O site do{' '}
                        <strong>Doutor Caminhoneiro</strong> avisará sempre que um plano que esteja sendo contratado acarretar no
                        cancelamento de uma assinatura vigente. Neste caso, os produtos e serviços serão disponibilizados imediatamente,
                        porém, a primeira cobrança será agendada para a data de vencimento do plano vigente. O cancelamento do plano vigente
                        será realizado automaticamente e todos os produtos e serviços contemplados no plano vigente estarão disponíveis até
                        o prazo final previsto em sua contratação.
                    </p>
                    <p>
                        Uma vez realizada a alteração de um plano vigente para outro, o Cliente somente poderá realizar uma nova alteração
                        após a data da primeira cobrança do novo plano.
                        <br />
                        <br />
                        <strong>Exemplo:</strong>
                        <p>
                            <i>
                                Caso o plano vigente tenha sido assinado no dia 01/02, a data da próxima cobrança será no dia 01/03 do mesmo
                                ano. Se o Cliente solicitar a alteração deste plano para um novo, o novo plano terá como data da sua
                                primeira cobrança o dia 01/03 do mesmo ano. Deste modo, caso o cliente queira alterar novamente o plano
                                assinado, ele deverá aguardar até o dia 01/03 do mesmo ano para fazê-lo.
                            </i>
                        </p>
                    </p>
                    <p>
                        <i>
                            *** O <strong>Doutor Caminhoneiro</strong> reserva o direito de aplicar novas regras para alterações de planos
                            de assinatura, que serão disponibilizadas ao Cliente no momento da solicitação, de forma objetiva e
                            transparente.
                        </i>
                    </p>

                    <h3 className="fw-bold">Início das prestações de serviços</h3>
                    <p>
                        Alguns serviços oferecidos pelo <strong>Doutor Caminhoneiro</strong>, como serviços de contabilidade, emissão de
                        documentos, pagamento de guias, etc... estão sujeitos a prazos e datas determinados pelos orgãos reguladores dos
                        mesmos. Deste modo, a data de contratação de um plano de assinatura que contemple tais serviços não garante a
                        prestação do serviço para o mês corrente. Caso tenha qualquer dúvida, não hesite em encontrar em contato conosco
                        através de um dos canais disponibilizados abaixo.
                    </p>

                    <h3 className="fw-bold">Contato</h3>
                    <p>
                        Se você tiver dúvidas ou preocupações sobre nossa política de assinaturas, entre em contato conosco através de um
                        dos canais abaixo:
                        <br />
                        <br />
                        <ul>
                            <li>
                                E-mail:{' '}
                                <strong>
                                    <a href="mailto:contato@doutorcaminhoneiro.com.br">contato@doutorcaminhoneiro.com.br</a>
                                </strong>
                            </li>
                            <li>
                                E-mail:{' '}
                                <strong>
                                    <a href="mailto:contato@transprolog.com">contato@transprolog.com</a>
                                </strong>
                            </li>
                            <li>
                                Whatsapp:{' '}
                                <strong>
                                    <a href="https://web.whatsapp.com/send?phone=5519993142939">(19) 99314 2939</a>
                                </strong>
                            </li>
                        </ul>
                    </p>
                    <p>
                        <i>Atualizado em 27/09/2023</i>
                    </p>
                </div>
            </div>
            <AppFooter />
        </section>
    );
}
