import React from 'react';
import { Table } from 'reactstrap';

export default function EventMessagesTable({ data }) {
    return (
        <Table striped bordered hover col="12">
            <thead>
                <tr>
                    <th>Tipo</th>
                    <th>Contato</th>
                    <th>Data de Envio</th>
                    <th>Redirecionado Para</th>
                    <th>Data do Redirecionamento</th>
                    <th>Data de Cadastro</th>
                </tr>
            </thead>
            <tbody>
                {(data || [])?.length > 0 ? (
                    data.map((record, index) => (
                        <tr key={index}>
                            <td>{record?.type || ''}</td>
                            <td>{[record?.contact?.name || '', record?.contact?.wa_id || ''].join(' - ')}</td>
                            <td>{record?.sent_at || ''}</td>
                            <td>{record?.redirected_to || ''}</td>
                            <td>{record?.redirected_at || ''}</td>
                            <td>{record?.created_at || ''}</td>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={6}>Nenhuma mensagem cadastrada</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
