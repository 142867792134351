import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useCartManager } from '@app/hooks/useCartManager';

import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

function CartItem({ item = {}, updateRequest = () => null, isClosed = false }) {
    const CartManager = useCartManager();

    const priceArr = (item?.price || 0).toFixed(2).split('.');
    const priceWhole = priceArr[0];
    const priceFraction = priceArr[1];

    const priceExpiration = useMemo(() => {
        if (item?.has_changed || false)
            return (
                <label className="priceHasChanged" title="O valor deste produto sofreu variações">
                    O valor deste produto sofreu variações
                </label>
            );
        if (!item?.expiration_date) return null;
        return (
            <label className="priceExpiration" title={`O valor informado está protegido de variações até ${item?.expiration_date}`}>
                Preço garantido até: <br />
                {item?.expiration_date}
            </label>
        );
    }, [item]);

    const [isLoading, setIsLoading] = useState(true);

    const [qtd, setQtd] = useState(item?.qtd);
    const handleUpdateItem = (newQtd) => {
        setIsLoading(true);
        CartManager.updateOnCart([{ id: item?.product?.token, qtd: newQtd }])
            .then((resp) => {
                const { data } = resp;
                setIsLoading('');
                if (data && resp.status === 200) {
                    toast.success(data?.message || 'Produto removido do carrinho!');
                    updateRequest();
                } else {
                    toast.error(data?.message || 'Erro remover produto do carrinho!');
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao remover produto do carrinho!');
            });
    };

    const [showQtdInput, setShowQtdInput] = useState(item?.qtd > 10 || false);
    useEffect(() => {
        setShowQtdInput(false);
    }, [item]);

    const handleQtdChangeFromSelect = (e) => {
        const val = e.target.value;
        if (val === '+') {
            setShowQtdInput(true);
        } else {
            setShowQtdInput(false);
            setQtd(val);
            handleUpdateItem(val);
        }
    };

    return (
        <div key={item.id} className="cart-item col-12 d-flex flex-row flex-wrap py-2">
            <div
                className="cart-item-thumb d-flex justify-content-center align-items-center px-2 col-12 col-sm-2 col-md-2"
                style={{ whiteSpace: 'nowrap' }}
            >
                {item?.product?.thumb && <img src={item?.product?.thumb?.url || ''} alt={item?.product?.description} className="col-10" />}
            </div>
            <div className="cart-item-details col-12 col-sm-7">
                <div className="d-flex flex-column mx-2 ">
                    <span className="cart-item-name fw-bold">{item?.product?.name}</span>
                    <span className="cart-item-description my-2">{item?.product?.description}</span>
                    <div className="d-flex flex-column">
                        {item?.qtd > 10 || showQtdInput ? (
                            <div className="d-flex cart-item-qtd-input">
                                <input
                                    type="number"
                                    name="item_qtd_input"
                                    className="col-6"
                                    value={qtd}
                                    onChange={(e) => setQtd(e.target.value)}
                                />
                                <Button
                                    type="button"
                                    color="secondary"
                                    title="Atualizar a quantidade do produto"
                                    className="btn btn-width-flex col-6 mx-2"
                                    onClick={() => handleUpdateItem(qtd)}
                                >
                                    <FontAwesomeIcon icon={faRedoAlt} />
                                </Button>
                            </div>
                        ) : (
                            <select
                                name="item_qtd"
                                disabled={isLoading === item?.id || item?.product?.max_offering === 1 || isClosed}
                                className="text-medium cart-item-qtd-select"
                                value={item?.qtd}
                                onChange={handleQtdChangeFromSelect}
                            >
                                <option value={0}>0 (Excluir)</option>
                                <option disabled value="" className="px-0" style={{ borderBottom: 'solid 1px #000000' }}>
                                    ---------
                                </option>
                                {Array.from({ length: 10 }).map((item, i) => (
                                    <option key={i + 1} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                                {item?.product?.max_offering > 10 && (
                                    <>
                                        <option disabled value="" className="px-0" style={{ borderBottom: 'solid 1px #000000' }}>
                                            ---------
                                        </option>
                                        <option value="+">10+</option>
                                    </>
                                )}
                            </select>
                        )}
                        {isLoading === item?.id && <LoadingSpinner />}
                    </div>
                    {item?.product?.has_shipping === true && (
                        <label className="cart-item-shipping-info mt-2">Este produto possui serviço de postagem</label>
                    )}
                </div>
            </div>
            <div className="cart-item-price d-flex align-items-center justify-content-start flex-column px-2 col-12 col-sm-3">
                <div className="my-2 text-big d-flex align-items-start">
                    <span className="price-symbol" style={{ fontSize: '60%' }}>
                        R$
                    </span>
                    <span className="price-whole fw-bold ml-1 pt-1">{priceWhole}</span>
                    <span className="priceFraction" style={{ fontSize: '60%' }}>
                        {priceFraction}
                    </span>
                </div>
                {priceExpiration}
                {!isClosed && (
                    <a
                        href={0}
                        className="text-small my-2 pointer"
                        title="Remover este item do carrinho de compras"
                        onClick={(e) => {
                            e.preventDefault();
                            handleUpdateItem(0);
                        }}
                    >
                        Remover do carrinho
                    </a>
                )}
            </div>
        </div>
    );
}

export default CartItem;
