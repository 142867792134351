import React, { useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import './SubmitScreen.scss';

function SubmitStatus({ fileId = '', status = '', message = '' }) {
    const statusIcon = useMemo(() => {
        if (status === 'done') return <FontAwesomeIcon icon={faCheck} className="text-success mx-2" />;
        if (status === 'submiting') return <LoadingSpinner />;
        return <FontAwesomeIcon icon={faClock} className="mx-2" />;
    }, [status]);
    return (
        <div className={`d-flex step-${fileId} align-items-center justify-content-between ${status === 'done' ? 'text-success' : ''}`}>
            <span>{message}</span>
            {statusIcon}
        </div>
    );
}
export default function SubmitScreen({ visible = false, steps = [] }) {
    return (
        <div
            className="form-display-submit-screen p-3 flex-column align-items-center justify-content-center"
            style={{ display: visible ? 'flex' : 'none' }}
        >
            <h3 className="mb-3">Aguarde enquanto seus dados são enviados</h3>
            <div className="d-flex flex-column col-10 col-sm-8 col-md-6">
                {steps.map((step, index) => (
                    <SubmitStatus key={index} {...step} />
                ))}
            </div>
        </div>
    );
}
