import { useFormikContext } from 'formik';
import React from 'react';
import HTMLWWEditor from '@app/components/HTMLEditor';

function FormSliceDetails({ isLoading }) {
    const { values, setFieldValue } = useFormikContext();

    const handlePlanDetailsChange = (val) => {
        setFieldValue('details', val);
    };

    return <HTMLWWEditor html={values.details} onChange={handlePlanDetailsChange} disabled={isLoading} />;
}
export default FormSliceDetails;
