import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import { useUserManager } from '@app/hooks/useUserManager';
import { addMessage } from '@app/store/AuthSlice';

import { Container, Button, Label } from 'reactstrap';

import SideContent from '../components/SideContent';
import { PasswordField } from '../../../components/ui';
import { AuthFooter, AuthHeader } from '../components';

function ResetPassword() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { token } = useParams();
    const UserManager = useUserManager();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [enabled, setEnabled] = useState(true);

    const handleSubmit = (values) => {
        setEnabled(false);
        UserManager.reset(values.token, values.password).then((response) => {
            const { status, message } = response;
            if (status === 200) {
                dispatch(addMessage({ type: 'success', message }));
                setErrorMessage('');
                history.push('/login');
            } else {
                setEnabled(true);
                setErrorMessage(message);
                setSuccessMessage('');
            }
        });
    };

    const validations = yup.object().shape({
        token: yup.string().required('Token inválido'),
        password: yup
            .string()
            .min(8, 'A senha deve possuir ao menos 8 caracteres.')
            .required('A senha deve possuir ao menos 8 caracteres.'),
        confirmPassword: yup
            .string()
            .min(8, 'A senha deve possuir ao menos 8 caracteres.')
            .required('A confirmação de senha deve ser igual à senha informada.')
            .oneOf([yup.ref('password'), null], 'A confirmação de senha deve ser igual à senha informada.'),
    });

    const initialValues = {
        token,
        password: '',
        confirmPassword: '',
    };

    return (
        <div className="auth-screen">
            <Container className="col-10" style={{ maxWidth: '1200px' }}>
                <div className="col-12 justify-content-center">
                    <AuthHeader />
                    <div className="auth-container col-12">
                        <div className="auth-form-container col-12 col-sm-5">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validations}
                                onSubmit={handleSubmit}
                                enableReinitialize
                                render={() => (
                                    <Form className="login">
                                        <Field type="hidden" name="token" value={token} />
                                        <div className="justify-content-center mb-3 col-12">
                                            <div className="col-12">
                                                <h2 className="text-center">Definição de senha</h2>
                                            </div>
                                        </div>
                                        <p className="mb-2 text-medium">
                                            Digite uma nova senha para sua conta. A senha deve possuir ao menos 8 caracteres.
                                        </p>
                                        <div className="col-12 d-flex flex-column mb-3">
                                            <Label>Senha:</Label>
                                            <Field
                                                name="password"
                                                component={PasswordField}
                                                className="login-field col-12 mb-1"
                                                disabled={!enabled}
                                            />
                                            <ErrorMessage component="span" name="password" className="text-error text-small" />
                                        </div>
                                        <div className="col-12 d-flex flex-column mb-3">
                                            <Label>Confirmação de senha:</Label>
                                            <Field
                                                name="confirmPassword"
                                                component={PasswordField}
                                                className="login-field col-12 mb-1"
                                                disabled={!enabled}
                                            />
                                            <ErrorMessage component="span" name="confirmPassword" className="text-error text-small" />
                                        </div>
                                        {errorMessage && <p className="mb-1 text-error text-small">{errorMessage}</p>}
                                        {successMessage && <p className="mb-1 text-success text-small">{successMessage}</p>}
                                        <div className="col-12 d-flex mb-3">
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Button type="submit" className="col-12" color="primary">
                                                    Redefinir senha
                                                </Button>
                                            </div>
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Link to="/login" className="btn btn-primary-outline col-12">
                                                    Efetuar login
                                                </Link>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                        <SideContent className="col-12 col-sm-7" />
                    </div>
                </div>
            </Container>
            <AuthFooter />
        </div>
    );
}

export default ResetPassword;
