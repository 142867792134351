import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import { useUserManager } from '@app/hooks/useUserManager';
import { clearAllMessages, selectAuth } from '@app/store/AuthSlice';

import { Container, Button, Label } from 'reactstrap';

import '@app/assets/styles/auth.scss';

import { PasswordField } from '@app/components/ui';
import { TipMessage, AuthFooter, AuthHeader } from '../components';

export default function LoginAdmin() {
    const { messages } = useSelector(selectAuth);
    const dispatch = useDispatch();

    const UserManager = useUserManager();
    const [errorMessage, setErrorMessage] = useState(''); // useState(message.type==='error' ? message.text : '');

    useEffect(() => {
        if (!messages) return;
        messages.forEach(({ type, message }) => {
            switch (type) {
                case 'error':
                    toast.error(message);
                    break;
                case 'success':
                    toast.success(message);
                    break;
                default:
                    break;
            }
        });
        if (messages.length > 0) dispatch(clearAllMessages());
    }, [messages, dispatch]);

    const handleSubmit = (values) => {
        UserManager.login(values.username, values.password)
            .then((response) => {
                setErrorMessage(response?.message);
            })
            .catch(() => {
                // console.log('ERRO: ', error);
            });
    };

    const validations = yup.object().shape({
        username: yup.string().required('É necessário informar um endereço de email.'),
        password: yup
            .string()
            .min(8, 'A senha deve possuir ao menos 8 caracteres.')
            .required('A senha deve possuir ao menos 8 caracteres.'),
    });

    const initialValues = {
        username: '',
        password: '',
    };

    return (
        <div className="auth-screen">
            <Container className="col-10" style={{ maxWidth: '1200px' }}>
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                    <AuthHeader />
                    <div className="auth-container col-12 col-sm-10 col-md-8 col-lg-6">
                        <div className="auth-form-container col-12">
                            <Formik initialValues={initialValues} validationSchema={validations} onSubmit={handleSubmit} enableReinitialize>
                                {() => (
                                    <Form className="login" name="login">
                                        <div className="justify-content-center mb-3 col-12">
                                            <div className="col-12">
                                                <h2 className="text-center">Acesso de Administrador</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex flex-column mb-3">
                                            <Label>Usuário:</Label>
                                            <Field
                                                type="text"
                                                name="username"
                                                id="username"
                                                placeholder=""
                                                autoComplete="username"
                                                className="login-field col-12 mb-1"
                                            />
                                            <ErrorMessage component="span" name="username" className="text-error text-small" />
                                        </div>
                                        <div className="col-12 d-flex flex-column mb-3">
                                            <Label>Senha:</Label>
                                            <Field
                                                name="password"
                                                component={PasswordField}
                                                autoComplete="password"
                                                className="login-field col-12 mb-1"
                                            />
                                            <ErrorMessage component="span" name="password" className="text-error text-small" />
                                        </div>
                                        {errorMessage && <p className="mb-3 message-error text-small">{errorMessage}</p>}
                                        <div className="col-12 d-flex mb-3">
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Button type="submit" className="col-12" color="primary">
                                                    ENTRAR
                                                </Button>
                                            </div>
                                            <div className="d-flex col-6 justify-content-center px-1">
                                                <Link to="/recuperar" className="btn btn-primary-outline col-12">
                                                    ESQUECI A SENHA
                                                </Link>
                                            </div>
                                        </div>
                                        <br />
                                        <TipMessage>
                                            <em>***</em> Por favor, certifique-se de estar usando o mesmo e-email e senha que usou para se
                                            cadastrar.
                                        </TipMessage>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Container>
            <AuthFooter />
        </div>
    );
}
