import React, { useState } from 'react';
import * as yup from 'yup';
import { Button, Label } from 'reactstrap';
import { ErrorMessage, Form, Formik } from 'formik';
import { PasswordField } from '@app/components/ui';
import FormikDevTools from '@app/components/FormikDevTools';
import toast from 'react-hot-toast';
import Api from '@app/services/Api';
import { URLs } from '@app/constants';

export default function ChangeUserPassword() {
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

    /** *****************
     * Reset Password
     ****************** */
    const handlePasswordSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        Api({
            method: 'post',
            url: URLs.club.account.change_password,
            data: values,
        })
            .then(({ data, status }) => {
                if (status === 200) {
                    setPasswordErrorMessage('');
                    toast.success(data?.message || 'Senha alterada com sucesso!');
                } else {
                    setPasswordErrorMessage(data?.message || 'Erro alterar a senha!');
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                setPasswordErrorMessage(response?.data?.message || 'Erro inesperado alterar a senha!');
                setSubmitting(false);
            });
    };

    const passwordValidations = yup.object().shape({
        password: yup
            .string()
            .min(8, 'A senha deve possuir ao menos 8 caracteres.')
            .required('A senha deve possuir ao menos 8 caracteres.'),
        new_password: yup
            .string()
            .min(8, 'A senha deve possuir ao menos 8 caracteres.')
            .required('A senha deve possuir ao menos 8 caracteres.'),
        confirm_password: yup
            .string()
            .min(8, 'A confirmação de senha deve ser igual à senha informada.')
            .required('A confirmação de senha deve ser igual à senha informada.')
            .oneOf([yup.ref('new_password'), null], 'A confirmação de senha deve ser igual à senha informada.'),
    });
    const initialPasswordValues = {
        password: '',
        new_password: '',
        confirm_password: '',
    };

    return (
        <div className="d-flex flex-wrap col-12 justify-content-start mb-3">
            <h3 className="field-group-title col-12">REDEFINIÇÃO DE SENHA</h3>
            <div className="col-12 col-sm-8 col-md-6">
                <Formik initialValues={initialPasswordValues} onSubmit={handlePasswordSubmit} validationSchema={passwordValidations}>
                    {({ values, setFieldValue, setFieldTouched, isValid, isSubmitting }) => (
                        <Form className="login">
                            <p className="mb-2 text-medium">
                                Digite sua senha atual e uma nova senha para sua conta. A senha deve possuir ao menos 8 caracteres.
                            </p>
                            {passwordErrorMessage && <p className="mb-1 text-error text-small">{passwordErrorMessage}</p>}
                            <div className="mb-3 col-12">
                                <Label for="password">Senha atual</Label>
                                <PasswordField
                                    type="password"
                                    name="password"
                                    autoComplete="password"
                                    className="login-field col-12 mb-1"
                                    value={values.password}
                                    onChange={(e) => {
                                        setFieldTouched('password');
                                        setFieldValue('password', e.target.value);
                                    }}
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage component="span" name="password" className="text-error text-small" />
                            </div>
                            <div className="mb-3 col-12">
                                <Label for="new_password">Nova senha</Label>
                                <PasswordField
                                    type="password"
                                    name="new_password"
                                    className="login-field col-12 mb-1"
                                    value={values.new_password}
                                    onChange={(e) => {
                                        setFieldTouched('new_password');
                                        setFieldValue('new_password', e.target.value);
                                    }}
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage component="span" name="new_password" className="text-error text-small" />
                            </div>
                            <div className="mb-3 col-12">
                                <Label for="confirm_password">Confirmação da senha</Label>
                                <PasswordField
                                    type="password"
                                    name="confirm_password"
                                    className="login-field col-12 mb-1"
                                    value={values.confirm_password}
                                    onChange={(e) => {
                                        setFieldTouched('confirm_password');
                                        setFieldValue('confirm_password', e.target.value);
                                    }}
                                    disabled={isSubmitting}
                                />
                                <ErrorMessage component="span" name="confirm_password" className="text-error text-small" />
                            </div>
                            <div className="mb-3 col-12">
                                <Button type="submit" color="custom" className="btn btn-primary" disabled={isSubmitting || !isValid}>
                                    Redefinir senha
                                </Button>
                            </div>
                            <FormikDevTools />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
