import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';
import PlanContext from '../PlanContext';

function PlanPrices({ prices = [], planToken = '' }) {
    const floatToCurrency = useFloatToCurrency;
    return (
        <div className="plan-prices col-12 d-flex flex-column">
            {prices.map((price) => {
                const amount = floatToCurrency(price.price);
                const [priceWhole, priceFraction] = amount.split(',');
                return (
                    <NavLink
                        tag={Link}
                        key={price.id}
                        to={{
                            pathname: `/assinatura/${planToken}/${price?.id || ''}`,
                            section: { name: '' },
                        }}
                        className="btn btn-primary-outline col-12 px-2 subscription-button"
                    >
                        <span className="subscription-button-title">{price?.name || ''}</span>
                        <span className="subscription-button-price">
                            <span className="price-symbol">R$</span>
                            <span className="price-whole">{priceWhole}</span>
                            <span className="price-fraction">{priceFraction}</span>
                        </span>
                    </NavLink>
                );
            })}
        </div>
    );
}
function PlanSubscriptedAdvice() {
    return <div className="subscripted-advice col-12 text-center">Você já assina este plano atualmente</div>;
}
export default function PlanFooter({ props }) {
    const { plan } = useContext(PlanContext);

    return (
        <div className="plan-footer" {...props}>
            {plan?.subscripted === null ? (
                <PlanPrices prices={plan?.prices || []} planToken={plan?.token || ''} />
            ) : (
                <PlanSubscriptedAdvice />
            )}
        </div>
    );
}
