import React from 'react';
import transprolog_logo from '@app/assets/images/logo_transprolog_footer.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function AuthFooter() {
    return (
        <div className="auth-footer d-flex flex-wrap col-12 mt-3 py-3 justify-content-end">
            <div className="col-12 col-sm-6 d-flex flex-column align-items-center">
                <img src={transprolog_logo} alt="Logo Transprolog" width="150" className="mb-3" />
                <div className="footer-links d-flex">
                    <a href="/politica" className="mx-2" target="_blank" title="Política de privacidade">
                        Política de Privacidade
                    </a>
                    <a href="/termos" className="mx-2" target="_blank" title="Termos de uso">
                        Termos de Uso
                    </a>
                </div>
            </div>
            <div className="col-12 col-sm-3 d-flex text-small">
                <div className="px-2">
                    <h4 className="col-12 pr-2 text-right text-white">Contatos:</h4>
                </div>
                <div className="col-6">
                    <div className="col-12 d-flex py-1">
                        <FontAwesomeIcon icon={faPhone} className="text-white" />
                        <a href="tel:+551932444720" className="mx-2 text-white" title="Telefone de Contato">
                            (19) 3244-4720
                        </a>
                    </div>
                    <div className="col-12 d-flex py-1">
                        <FontAwesomeIcon icon={faPhone} className="text-white" />
                        <a href="tel:+551932444721" className="mx-2 text-white" title="Telefone de Contato">
                            (19) 3244-4721
                        </a>
                    </div>
                    <div className="col-12 d-flex py-1">
                        <FontAwesomeIcon icon={faWhatsapp} className="text-success" />
                        <a
                            href="https://web.whatsapp.com/send?phone=5519993157647"
                            className="mx-2 text-white"
                            target="_blank"
                            title="Entrar em Contato via Whatsapp"
                            rel="noreferrer"
                        >
                            (19) 99315-7647
                        </a>
                    </div>
                    <div className="col-12 d-flex py-1">
                        <FontAwesomeIcon icon={faEnvelope} className="text-white" />
                        <a
                            href="mailto:contato@transprolog.com"
                            className="mx-2 text-white"
                            target="_blank"
                            title="Entrar em Contato via E-mail"
                            rel="noreferrer"
                        >
                            contato@transprolog.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuthFooter;
