import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Button, Label, Table } from 'reactstrap';
import { toast } from 'react-hot-toast';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';
import { useUserManager } from '@app/hooks/useUserManager';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CustomSwitch } from '@app/components/ui';

function FormSliceAccesses({ isLoading, featureLabels }) {
    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    const { values, setValues } = useFormikContext();

    // Carrega os dados das sessões
    const [sections, setSections] = useState([]);
    useEffect(() => {
        if (sections.length !== 0) return;

        const loadSections = () => {
            const url = replaceParams(URLs.admin.sections.basics, { nocache: new Date().getTime(), context: 'admin' });
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setSections(response.data?.data || []);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados das sessões');
                });
        };
        loadSections();
    }, [replaceParams, sections, token]);

    /**
     * Adiciona nova seção à lista
     */
    const addSectionToAccess = (e) => {
        const section_id = sections[e.target.value].id;
        const find = (values?.sections || []).filter((section) => section.pivot.section_id === section_id);
        if (find.length !== 0) return;
        setValues((prev) => ({
            ...prev,
            ...{
                sections: [
                    ...prev.sections,
                    ...[
                        {
                            ...sections[e.target.value],
                            ...{
                                pivot: {
                                    section_id: sections[e.target.value].id,
                                    features: [],
                                },
                            },
                        },
                    ],
                ],
            },
        }));
    };
    /**
     * Remove uma seção da lista de acessos do usuário
     */
    const removeSectionFromAccess = (token) => {
        setValues((prev) => ({
            ...prev,
            ...{
                sections: prev.sections.filter((item) => item.token !== token),
            },
        }));
    };
    const handleSectionFeatureChanged = (status, token, feature) => {
        const sections = (values?.sections || []).map((item) => {
            const newItem = item;
            if (newItem.token === token) {
                const newFeatures = newItem.pivot.features.filter((item) => item !== feature);
                if (status) newFeatures.push(feature);
                newItem.pivot.features = newFeatures;
            }
            return newItem;
        });
        setValues((prev) => ({
            ...prev,
            ...{ sections },
        }));
    };

    return (
        <>
            <div className="col-12 mb-1 d-flex">
                <div className="px-1 col-12 col-sm-6 col-lg-4">
                    <select name="user_type_id" className="col-12" value="" onChange={addSectionToAccess} disabled={isLoading}>
                        <option value="">Selecione uma seção para adicioná-la</option>
                        {(sections || [])?.map((item, index) => (
                            <option key={index} value={index}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="px-1 col-12 col-sm-6 col-lg-8 d-flex justify-content-center align-items-center">
                    {(values?.personal_access || []).length !== 0 && (
                        <p className="text-danger text-medium text-center m-0 p-0">
                            ATENÇÃO: Os acessos concedidos ao usuário sobrescreverão os acessos herdados do seu tipo para a seção!
                        </p>
                    )}
                </div>
            </div>
            <div className="mb-1 px-1 col-12">
                <Table striped bordered hover col="12">
                    <thead>
                        <tr>
                            <th>Seção</th>
                            <th>Recursos da seção</th>
                            <th colSpan="2" className="text-center">
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(values?.sections || []).length > 0 ? (
                            (values?.sections || []).map((item, index) => (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>
                                        <div className="col-12 d-flex flex-column">
                                            {(item?.features || []).map((feature, fKey) => (
                                                <div key={fKey} className="col-12 d-flex align-items-center mb-1">
                                                    <CustomSwitch
                                                        name={`section-${item.id}-action-${feature}`}
                                                        className="justify-content-center mx-2"
                                                        checked={(item?.pivot?.features || []).includes(feature)}
                                                        isAsync={false}
                                                        disabled={isLoading}
                                                        data={{ token: item.token }}
                                                        onChange={(e) => handleSectionFeatureChanged(e, item.token, feature)}
                                                    />
                                                    <Label className="field-label" htmlFor="offer_once">
                                                        {false && (
                                                            <FontAwesomeIcon
                                                                key={index}
                                                                icon={fas[featureLabels?.[feature]?.icon || 'faQuestion']}
                                                                title={featureLabels?.[feature]?.title || ''}
                                                                className="mx-1 text-primary"
                                                            />
                                                        )}
                                                        {featureLabels?.[feature]?.title || ''}
                                                    </Label>
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                    <td align="center">
                                        <Button
                                            name="remove-section"
                                            color="custom"
                                            className="btn btn-primary-outline"
                                            title="Remover seção"
                                            onClick={() => {
                                                removeSectionFromAccess(item.token);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr key={0}>
                                <td colSpan={7} align="center">
                                    Nenhuma seção selecionada
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
}
export default FormSliceAccesses;
