import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import FilterContainer, { FilterFields, FilterTab, FilterTabs } from '@app/containers/FilterContainer/FilterContainer';

import CheckPermission from '@app/components/CheckPermission';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Row, Form } from 'reactstrap';
import { useFetch } from '@app/hooks/useFetch';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';

import Paginator from '@app/components/Paginator/Paginator';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import SectionsTable from './tables/SectionsTable';
import SectionsContext from './components/SectionContext';

function Sections(props) {
    const replaceParams = useReplaceParams;
    const [submitting, setSubmitting] = useState(false);

    /**
     * Consulta dados de sessões
     */
    const [params, setParams] = useState({
        nocache: new Date().getTime(),
        name: '',
        context: 'club',
        type: props?.product_type || '',
        pg: 1,
        orderBy: 'order',
        orderDir: 'asc',
    });
    const { data, error, last_page } = useFetch(replaceParams(URLs.admin.sections.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
    }, [data, error]);

    /**
     * Filtros de pesquisa
     */
    const updateRequest = () => setParams((prev) => ({ ...prev, ...{ nocache: new Date().getTime() } }));
    const handleNameChange = (e) => setParams((prev) => ({ ...prev, ...{ name: e.target.value, pg: 1 } }));
    const handleSectionContextChange = (context) => setParams((prev) => ({ ...prev, ...{ context, pg: 1 } }));
    const handleOrderBy = (orderBy, orderDir) => setParams((prev) => ({ ...prev, ...{ orderBy, orderDir, pg: 1 } }));

    const handleNavigateFirst = () => setParams((prev) => ({ ...prev, ...{ pg: 1 } }));
    const handleNavigateLast = () => setParams((prev) => ({ ...prev, ...{ pg: last_page } }));
    const handleNavigateNext = () => setParams((prev) => ({ ...prev, ...{ pg: data?.length > 0 ? params.pg + 1 : params.pg } }));
    const handleNavigatePrevious = () => setParams((prev) => ({ ...prev, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } }));

    const handleSectionStateChange = (e) => {
        const url = replaceParams(URLs.admin.sections.status, { token: e.data.token });
        Api({ method: 'put', url, data: { status: e.data.checked ? 1 : 0 } })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status da seção.');
                    e.data.setStatus(!e.data.checked);
                } else {
                    toast.success(response?.data?.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status da seção!');
                setSubmitting(false);
                e.data.setStatus(!e.data.checked);
            });
    };

    const handleSectionShowingChange = (e) => {
        const url = replaceParams(URLs.admin.sections.showing, { token: e.data.token });
        Api({ method: 'put', url, data: { menu: e.data.checked ? 1 : 0 } })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status de exibição da seção.');
                    e.data.setStatus(!e.data.checked);
                } else {
                    toast.success(response?.data?.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status de exibição da seção!');
                setSubmitting(false);
                e.data.setStatus(!e.data.checked);
            });
    };

    const handleSectionOrderChange = (token, from, to) => {
        const url = replaceParams(URLs.admin.sections.reorder, { token });
        Api({ method: 'put', url, data: { from, to } })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar a ordem de exibição da seção.');
                } else {
                    updateRequest();
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar a ordem de exibição da seção!');
                setSubmitting(false);
            });
    };

    return (
        <ContentWrapper>
            <FilterContainer showTitle={false} className="show-border">
                <FilterFields>
                    <Row />
                </FilterFields>
                <FilterTabs active="club">
                    <FilterTab
                        key="club"
                        active={params.context === 'club'}
                        to="/sessoes"
                        onClick={() => handleSectionContextChange('club')}
                    >
                        Clientes
                    </FilterTab>
                    <FilterTab
                        key="admin"
                        active={params.context === 'admin'}
                        to="/sessoes"
                        onClick={() => handleSectionContextChange('admin')}
                    >
                        Administradores
                    </FilterTab>
                </FilterTabs>
            </FilterContainer>
            <FilterContainer title="Filtros">
                <FilterFields>
                    <Form className="d-flex mb-3 flex-wrap">
                        <fieldset className="col-3">
                            <label htmlFor="accessName">Nome</label>
                            <input type="text" name="accessName" value={params?.name} onChange={handleNameChange} />
                        </fieldset>
                        <CheckPermission permission="create">
                            <fieldset className="">
                                <a href="/admin/sessoes/nova" className="btn btn-primary btn-medium btn-icon-left ">
                                    <FontAwesomeIcon icon={faPlus} className="mx-2" />
                                    Nova Seção
                                </a>
                            </fieldset>
                        </CheckPermission>
                        <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <div className="page-content">
                <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                </div>
                <SectionsContext.Provider
                    value={{
                        submitting,
                        handleOrderBy,
                        handleSectionStateChange,
                        handleSectionShowingChange,
                        handleSectionOrderChange,
                    }}
                >
                    <SectionsTable sections={data} />
                </SectionsContext.Provider>
                <Paginator
                    pg={params.pg}
                    last_page={last_page}
                    hasPrevious={params.pg > 1}
                    hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                    handleNavigateFirst={handleNavigateFirst}
                    handleNavigateNext={handleNavigateNext}
                    handleNavigatePrevious={handleNavigatePrevious}
                    handleNavigateLast={handleNavigateLast}
                />
            </div>
        </ContentWrapper>
    );
}

export default Sections;
