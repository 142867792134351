import React from 'react';
import { Table } from 'reactstrap';

export default function VariablesTable() {
    const variablesKey = Object.keys(process.env);

    return (
        <Table striped bordered hover col="12">
            <thead>
                <tr>
                    <th>Variável</th>
                    <th>Valor</th>
                </tr>
            </thead>
            <tbody>
                {variablesKey.map((key, id) =>
                    process.env[key] === undefined ? null : (
                        <tr key={id}>
                            <td>{key}</td>
                            <td>{process.env[key]}</td>
                        </tr>
                    )
                )}
            </tbody>
        </Table>
    );
}
