import React, { useContext } from 'react';
import { URLs, useReplaceParams } from '@app/constants';
import { Table } from 'reactstrap';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckPermission from '@app/components/CheckPermission';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';

import { CustomSwitch } from '@app/components/ui';
import FileDownloader from '@app/components/FileDownloader/FileDownloader';
import ProductsContext from '../components/ProductsContext';
import { productTypeIcons } from '../components/ProductTypeIcons';

function ProductsTable({ type = 'all', handleOrderBy = () => {}, data = null }) {
    const replaceParams = useReplaceParams;
    const context = useContext(ProductsContext);

    return (
        <Table striped hover col="12" className="text-small table-responsive">
            <thead>
                <Tr handleOrderBy={handleOrderBy || null}>
                    {type === 'all' && <Th param="product_type">Tipo</Th>}
                    <Th param="name">Nome</Th>
                    <Th param="price" align="right">
                        Preço atual (R$)
                    </Th>
                    <Th param="old_price">Preço anterior (R$)</Th>
                    <th>Expira após</th>
                    <th>Vendido por</th>
                    <Th param="stock">Estoque</Th>
                    <Th param="max_offering">Max. / Compra</Th>
                    <Th param="free_offer">Gratuito</Th>
                    <Th param="offer_once">Compra única</Th>
                    <Th param="remove_from_cart">Remover do carrinho</Th>
                    <th>Imagens</th>
                    {(type === 'document' || type === 'all') && <th>Documento</th>}
                    <Th param="status" className="text-center">
                        Ativo
                    </Th>
                    <CheckPermission permission="edit">
                        <th>Editar</th>
                    </CheckPermission>
                </Tr>
            </thead>
            <tbody>
                {data?.length > 0 ? (
                    data?.map((record, id) => (
                        <tr key={id}>
                            {type === 'all' && (
                                <td align="center" className="text-dark-brown">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        title={productTypeIcons[record?.product_type].title}
                                        icon={productTypeIcons[record?.product_type].icon}
                                    />
                                </td>
                            )}
                            <td style={{ whiteSpace: 'nowrap' }}>{parse(record?.name)}</td>
                            <td align="right">{record?.price.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                            <td align="right">
                                {record?.old_price ? record?.old_price.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : ''}
                            </td>
                            <td align="center">{record?.expiration_label}</td>
                            <td className="text-small">{record?.supplier?.name || ''}</td>
                            <td align="right">{record?.stock}</td>
                            <td align="right">{record?.max_offering}</td>
                            <td align="center">{record?.free_offer ? 'SIM' : 'NÃO'}</td>
                            <td align="center">{record?.offer_once ? 'SIM' : 'NÃO'}</td>
                            <td align="center">{record?.remove_from_cart ? 'SIM' : 'NÃO'}</td>
                            <td align="center">{record?.gallery_count || 0}</td>
                            {(type === 'document' || type === 'all') && (
                                <td align="center">
                                    {record?.product_type === 'document' && (record?.private?.filename || '') !== '' && (
                                        <FileDownloader
                                            method="post"
                                            url={replaceParams(URLs.admin.products.download, { token: record?.token })}
                                            label="DOWNLOAD"
                                            hideLabel
                                            filename={record?.private?.filename}
                                            extension={record?.private?.format}
                                            className="product-file-downloader"
                                        />
                                    )}
                                </td>
                            )}
                            <td align="center">
                                <CheckPermission
                                    permission="change-status"
                                    allowed={
                                        <CustomSwitch
                                            name={`product-status-${record?.id}`}
                                            className="justify-content-center"
                                            onChange={context?.handleProductStateChange}
                                            checked={record?.status === 1 || false}
                                            isAsync
                                            data={{ id: record?.token, status: record?.status }}
                                        />
                                    }
                                    notAllowed={
                                        <label className={record.status === 1 ? 'text-success' : 'text-error'}>
                                            {record.status === 1 ? 'ATIVO' : 'INATIVO'}
                                        </label>
                                    }
                                />
                            </td>
                            <CheckPermission permission="edit">
                                <td className="text-center ">
                                    <a
                                        name="edit-product"
                                        href={`/admin/produtos/alterar/${record?.token}`}
                                        className="btn btn-primary-outline"
                                        title="Editar produto"
                                    >
                                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                    </a>
                                </td>
                            </CheckPermission>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={14} align="center">
                            Nenhum produto disponível
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default ProductsTable;
