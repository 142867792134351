import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import './LoadingSpinner.scss';

function LoadingSpinner(props) {
    return (
        <div className={`loading-spinner ${props?.className || ''}`} title={props?.title || 'Carregando dados'}>
            <FontAwesomeIcon icon={faRedo} />
        </div>
    );
}

export default LoadingSpinner;
