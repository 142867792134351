import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-hot-toast';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import { usePurchaseManager } from '@app/hooks/';

import './orders.scss';

import OrderContext from './components/OrderContext';
import OrderSummary from './components/OrderSummary';
import OrderItem from './components/OrderItem';

function Order(props) {
    const history = useHistory();
    const PurchaseManager = usePurchaseManager();

    const purchaseToken = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [purchaseData, setPurchaseData] = useState(null);

    const loadPurchaseData = useCallback(
        (token) => {
            setIsLoading(true);
            PurchaseManager.getPurchase(token)
                .then(({ data, status }) => {
                    if (data && status === 200) {
                        setPurchaseData(data);
                        setIsLoading(false);
                    } else {
                        toast.error(data?.message || 'Não foi possível carregar os dados do pedido');
                    }
                })
                .catch(({ response }) => {
                    const { data, status } = response;
                    if (status === 404) {
                        toast.error('Pedido não encontrado');
                        history.push('/pedidos');
                    } else {
                        toast.error(data?.message || 'Erro inesperado ao obter os dados do pedido');
                    }
                    setIsLoading(false);
                });
        },
        [PurchaseManager, history]
    );

    useEffect(() => {
        if ((props?.match?.params?.token || '') === '') return;
        if (purchaseToken.current !== null) return;
        purchaseToken.current = props?.match?.params?.token;
        loadPurchaseData(purchaseToken.current);
        // setPurchaseToken(props?.match?.params?.token);
    }, [purchaseData, loadPurchaseData, props?.match?.params?.token]);

    const updateRequest = useCallback(() => loadPurchaseData(purchaseData.token), [loadPurchaseData, purchaseData]);

    const checkPendencies = useCallback(() => {
        // Checking billing address
        if ((purchaseData?.billing_address || null) == null) {
            toast.error('É necesssário informar um endereço de cobrança');
            return false;
        }
        // Checking shipping address
        if ((purchaseData?.shipping_address || null) == null && purchaseData.has_shipping) {
            toast.error('É necesssário informar um endereço de entrega');
            return false;
        }
        return true;
    }, [purchaseData]);

    // Solicita a finalização do pedido e encaminha o usuário para a tela de pagamento
    const handleClosePurchase = () => {
        if (purchaseData === null) return;
        if (!checkPendencies()) return;
        PurchaseManager.closePurchase(purchaseData.token)
            .then((response) => {
                if (response.data && response.status === 200) {
                    history.push(`/checkout/${purchaseData.token}`);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(({ response }) => {
                const { data, status } = response;
                if (status === 404) {
                    toast.error('Pedido não encontrado');
                    history.push('/pedidos');
                } else {
                    toast.error(data?.message || 'Erro inesperado ao fechar pedido');
                }
            });
    };

    /**
     * Solicitando o cancelamento do pedido
     */
    const handleCancelPurchase = () => {
        PurchaseManager.cancelPurchase(purchaseData.token)
            .then((response) => {
                const { status } = response;
                if (status === 200) {
                    toast.success(response?.data?.message || 'Seu pedido foi cancelado com sucesso!');
                    loadPurchaseData(purchaseData.token);
                } else {
                    toast.error(response?.data?.message || 'Erro ao cancelar pedido!');
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao cancelar pedido!');
            });
    };

    const handleApplyCoupom = (coupom) => {
        PurchaseManager.applyCoupom(purchaseData.token, coupom)
            .then((response) => {
                const { status, message } = response;
                if (status === 200) {
                    toast.success(message || 'Desconto aplicado com sucesso!');
                    loadPurchaseData(purchaseData.token);
                } else {
                    toast.error(message || 'Erro ao tentar aplicar o desconto!');
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar aplicar o desconto!');
            });
    };

    return (
        <ContentWrapper className="payment col-8">
            <h2 className="text-large fw-bold py-1 m-0">Detalhes do pedido</h2>
            <OrderContext.Provider
                value={{
                    purchase: purchaseData,
                    isLoading,
                    handleClosePurchase,
                    handleCancelPurchase,
                    handleApplyCoupom,
                    updateRequest,
                }}
            >
                <OrderSummary
                    className="mb-2"
                    style={{ borderBottom: 'inherit' }}
                    isLoading={isLoading || props?.isLoading || false}
                    purchaseIsOpen={purchaseData?.status === 'created'}
                    onApplyCoupom={handleApplyCoupom}
                />
                <h2 className="text-large fw-bold p-0 mb-0 mt-3">Itens do pedido</h2>
                <div className="d-flex flex-wrap">
                    <div className="page-content order-items col-12 m-0 mt-1 p-0">
                        {purchaseData?.items?.length > 0 ? (
                            purchaseData?.items?.map((item, id) => <OrderItem key={id} item={item} updateRequest={props?.updateRequest} />)
                        ) : (
                            <div className="d-flex order-product col-12 text-center p-0 m-0" key={0}>
                                <p className="col-12 text-center p-2 m-0">
                                    <strong>Não existem produtos neste pedido.</strong>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </OrderContext.Provider>
        </ContentWrapper>
    );
}

export default Order;
