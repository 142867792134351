const useFormatterHelper = () => {
    // Formata uma string em Camel Case. Ex: some word = someWord
    const camelize = (str, separator = ' ', glue = '') =>
        str
            .replace(separator, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
            .replace(' ', glue);

    return {
        camelize,
    };
};

export const useFloatToCurrency = (value) => {
    let val = parseFloat(value);
    val = Number(val).isNaN ? 0 : val;
    return val.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const secondsToTimeStr = (val = 0) => {
    if (Number(val).isNaN) return '00:00:00';
    let seconds = val;
    let minutes = Math.round(seconds / 60);
    seconds -= minutes * 60;
    const hours = Math.round(minutes / 60);
    minutes -= hours * 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export default useFormatterHelper;
