import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '@app/routes/PrivateRoute';
import AccountData from './data';
import Cards from './cards';
import Forms from './forms';
import FormDetails from './forms/form_details';
import Configurations from './configurations/configurations';
import Subscriptions from './subscriptions';
import SubscriptionDetails from './subscriptions/subscription_details';

function Account() {
    // Funções de alteração dos dados do usuário
    return (
        <Switch>
            <PrivateRoute exact path="/conta" component={AccountData} />
            <PrivateRoute exact path="/conta/configuracoes" component={Configurations} />
            <PrivateRoute exact path="/conta/cartoes" component={Cards} />
            <PrivateRoute exact path="/conta/assinaturas" component={Subscriptions} />
            <PrivateRoute exact path="/conta/assinaturas/:token" component={SubscriptionDetails} />
            <PrivateRoute exact path="/conta/formularios" component={Forms} />
            <PrivateRoute path="/conta/formularios/:descriptor/:id" component={FormDetails} />
        </Switch>
    );
}

export default Account;
