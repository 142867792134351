import React, { useMemo, useState } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

import PrivateRoute from '@app/routes/PrivateRoute';

import AppHeader from '@app/pages/shared/AppHeader/AppHeader';
import { PrivateSidebar } from '@app/pages/shared/SideBar';
import AppFooter from '@app/pages/shared/AppFooter/AppFooter';

import { useUserManager, useCartManager } from '@app/hooks';
import { SectionContext } from '@app/contexts';
import Sections from './sections/sections';
import Certificates from './certificates/certificates';

import Products from './products/products';
import ProductsForm from './products/products_form';

import Suppliers from './suppliers/suppliers';
import SuppliersForm from './suppliers/suppliers_form';

import Users from './users/users';
import UsersForm from './users/users_form';

import Customers from './customers/customers';
import Customer from './customers/customer';

import Sales from './sales/sales';
import SaleDetails from './sales/sale_details';

import Styleguide from './styleguide/styleguide';
import UserProfiles from './user_profiles/user_profiles';
import NotFound from '../not_found/not_fund';
import Dashboard from './dashboard';

import Notifications from '../shared/Notifications/Notifications';
import Account from '../shared/Account/Account';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@app/assets/styles/app.scss';
import UserTypes from './user_types/user_types';
import UserTypesForm from './user_types/user_types_form';
import SectionsForm from './sections/sections_form';
import Forms from './forms/forms';
import Taxation from './taxation/taxation';
import Gateway from './gateway';
import EventDetails from './gateway/event_details';

import Subscriptions from './subscriptions';
import SubscriptionPlans from './subscription_plans/plans';
import SubscriptionPlansForm from './subscription_plans/plans_form';

import Traffic from './traffic/traffic';
import Whatsapp from './whatsapp';
import WhatsappEventDetails from './whatsapp/whatsapp_event_details';
import Configurations from './configurations/configurations';

function MainAdmin() {
    const history = useHistory();
    const UserManager = useUserManager();

    // Atualizando o sumário de notificações e carrinho de comprasa
    useCartManager().checkForSummaryUpdates();

    const [sideBarIsClosed, setSideBarIsClosed] = useState(window.screen.width < 992);
    const toggleSideBar = () => {
        setSideBarIsClosed(!sideBarIsClosed);
    };

    const location = useLocation();
    const section = UserManager.currentSection();

    const currentSection = useMemo(() => {
        const filterSections = (sections, url) => {
            let response = null;
            sections.forEach((section) => {
                if (section.subsections.length > 0) {
                    response = filterSections(section.subsections, url) || response;
                } else if (url.startsWith(section.url)) {
                    response = section;
                }
            });
            return response;
        };
        const url = location.pathname.replaceAll('/admin', '');
        const section = filterSections(UserManager.sections, url);
        return section;
    }, [UserManager.sections, location.pathname]);

    // Checando se o usuário está tentando acessar uma página a qual ele não possui acesso
    //    if (!section) history.push('/admin');
    // Checando se o usuário é administrador
    if (UserManager.context !== 'admin') history.push('/');

    return (
        <SectionContext.Provider
            value={{
                section,
                currentSection,
                pathname: location.pathname,
                checkPermission: UserManager.checkPermission,
                sideBarIsClosed,
            }}
        >
            <AppHeader toggleSideBar={toggleSideBar} sideBarIsClosed={sideBarIsClosed} />
            <section className={`sidebar-${sideBarIsClosed ? 'closed' : 'open'}`}>
                <PrivateSidebar toggleSideBar={toggleSideBar} isClosed={sideBarIsClosed} />
                <Switch>
                    <PrivateRoute exact path="/admin">
                        <Redirect to={`/admin${UserManager.getHomeSectionUrl()}`} />
                    </PrivateRoute>
                    <PrivateRoute exact path="/admin/notificacoes" render={() => <Notifications />} />
                    <PrivateRoute exact path="/admin/conta" render={() => <Account />} />

                    <PrivateRoute exact path="/admin/produtos" render={(props) => <Products {...props} />} />
                    <PrivateRoute exact path="/admin/produtos/novo" render={(props) => <ProductsForm {...props} />} />
                    <PrivateRoute path="/admin/produtos/alterar/:token" render={(props) => <ProductsForm {...props} />} />

                    <PrivateRoute exact path="/admin/fornecedores" render={(props) => <Suppliers {...props} />} />
                    <PrivateRoute exact path="/admin/fornecedores/novo" render={(props) => <SuppliersForm {...props} />} />
                    <PrivateRoute path="/admin/fornecedores/alterar/:token" render={(props) => <SuppliersForm {...props} />} />

                    <PrivateRoute exact path="/admin/taxacao" render={(props) => <Taxation {...props} />} />

                    <PrivateRoute path="/admin/certificados" component={Certificates} />

                    <PrivateRoute exact path="/admin/sessoes" component={Sections} />
                    <PrivateRoute exact path="/admin/sessoes/nova" component={SectionsForm} />
                    <PrivateRoute path="/admin/sessoes/alterar/:token" component={SectionsForm} />

                    {/* PLANOS DE ASSINATURA */}
                    <PrivateRoute exact path="/admin/assinaturas" component={Subscriptions} />
                    <PrivateRoute exact path="/admin/planos" component={SubscriptionPlans} />
                    <PrivateRoute exact path="/admin/planos/:group/novo-plano" component={SubscriptionPlansForm} />
                    <PrivateRoute exact path="/admin/planos/:group/alterar/:token" component={SubscriptionPlansForm} />

                    <PrivateRoute exact path="/admin/tipos_usuario" component={UserTypes} />
                    <PrivateRoute exact path="/admin/tipos_usuario/novo" render={(props) => <UserTypesForm {...props} />} />
                    <PrivateRoute exact path="/admin/tipos_usuario/alterar/:id" render={(props) => <UserTypesForm {...props} />} />

                    <PrivateRoute exact path="/admin/usuarios" component={Users} />
                    <PrivateRoute exact path="/admin/usuarios/novo" render={(props) => <UsersForm {...props} />} />
                    <PrivateRoute exact path="/admin/usuarios/alterar/:id" render={(props) => <UsersForm {...props} />} />

                    <PrivateRoute exact path="/admin/usuarios/tipos" component={UserProfiles} />

                    <PrivateRoute exact path="/admin/clientes" component={Customers} />
                    <PrivateRoute exact path="/admin/clientes/:id" component={Customer} />

                    <PrivateRoute exact path="/admin/formularios" component={Forms} />

                    <PrivateRoute exact path="/admin/vendas" component={Sales} />
                    <PrivateRoute exact path="/admin/vendas/:token" component={SaleDetails} />

                    <PrivateRoute exact path="/admin/trafego" component={Traffic} />

                    <PrivateRoute exact path="/admin/gateway" component={Gateway} />
                    <PrivateRoute exact path="/admin/gateway/:token" component={EventDetails} />

                    <PrivateRoute exact path="/admin/whatsapp" component={Whatsapp} />
                    <PrivateRoute exact path="/admin/whatsapp/:token" component={WhatsappEventDetails} />

                    <PrivateRoute exact path="/admin/configuracoes" component={Configurations} />

                    <PrivateRoute exact path="/admin/home" render={(props) => <Dashboard section={section?.token} {...props} />} />

                    <PrivateRoute path="/admin/styleguide" component={Styleguide} />
                    <PrivateRoute component={NotFound} />
                </Switch>
            </section>
            <AppFooter sideBarIsClosed={sideBarIsClosed} />
        </SectionContext.Provider>
    );
}

export default MainAdmin;
