import React from 'react';

import './DetailsBody.scss';
import FieldData from './FieldData';

export default function DetailsBody({ template = [], data = {}, className = '' }) {
    return (
        <div className={`form-content-details col-12 d-flex flex-wrap ${className}`}>
            {template?.map((field, index) => (
                <FieldData key={index} template={field} data={data?.data?.[field.col] || ''} />
            ))}
        </div>
    );
}
