import React, { useCallback, useContext, useRef } from 'react';

import logoPix from '@app/assets/images/logoPix.svg';
import { usePurchaseManager } from '@app/hooks';
import OrderContext from '../OrderContext';

function PixStatusPending(props) {
    const { purchase, updatePurchaseData } = useContext(OrderContext);
    const PurchaseManager = usePurchaseManager();

    const { charge } = props;
    const qr_code_url = charge?.transaction?.pix_qr_code_url || '';
    const expires_at = new Date(charge?.transaction?.pix_expires_at);

    let expires_at_label = `${String(expires_at.getDate()).padStart(2, '0')}/`;
    expires_at_label += `${String(expires_at.getMonth()).padStart(2, '0')}/`;
    expires_at_label += `${expires_at.getFullYear()} às `;
    expires_at_label += `${String(expires_at.getHours()).padStart(2, '0')}:`;
    expires_at_label += String(expires_at.getMinutes()).padStart(2, '0');

    const timeoutId = useRef(null);
    const getPurchaseStatus = useCallback(() => {
        if (!purchase) return;
        clearTimeout(timeoutId.current);
        PurchaseManager.getPurchaseStatus(purchase.token).then(({ data }) => {
            if (data?.status !== 'pending') {
                updatePurchaseData();
            } else {
                timeoutId.current = setTimeout(() => {
                    getPurchaseStatus();
                }, 3000);
            }
        });
    }, [PurchaseManager, purchase, updatePurchaseData]);

    getPurchaseStatus();

    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <div className="py-3 qr-code-container d-flex justify-content-center">
                {qr_code_url !== '' && <img src={qr_code_url} alt="PIX QR Code" />}
            </div>
            <p>Leia o QR Code acima para realizar o pagamento da fatura.</p>
            <p>Page até: {expires_at_label}</p>
        </>
    );
}
function PixStatusPaid(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">Tudo certo com seu pagamento, em breve os itens do seu pedido serão disponibilizados.</p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function PixStatusCanceled(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">O pagamento do seu pedido foi cancelado.</p>
            <p className="text-center">
                Você pode continuar navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para você.
            </p>
        </>
    );
}
function PixStatusProcessing(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido está sendo processado pelo gateway. O <strong>Doutor Caminhoneiro</strong> será notificado assim
                que houver uma atualização sobre o status do pagamento.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function PixStatusFailed(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido falhou. Você pode realizar este pedido novamente a qualquer momento. Fique atento à forma de
                pagamento escolhida e às datas de expiração da cobrança.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function PixStatusOverpaid(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">Tudo certo com seu pagamento, em breve os itens do seu pedido serão disponibilizados.</p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function PixStatusUnderpaid(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido foi realizado de forma parcial. Assim que o <strong>Doutor Caminhoneiro</strong> verificar o
                pagamento total do valor do pedido os itens solicitados serão disponibilizados.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}
function PixStatusChargedback(props) {
    const { charge } = props;
    return (
        <>
            <h3 className="text-center">{charge.status_message.toUpperCase()}</h3>
            <p className="text-center">
                O pagamento do seu pedido foi extornado. Você pode realizar este pedido novamente a qualquer momento. Fique atento à forma
                de pagamento escolhida e às datas de expiração da cobrança.
            </p>
            <p className="text-center">
                Enquanto isso, continue navegando e aproveitando todo o conteúdo que o <strong>Doutor Caminhoneiro</strong> preparou para
                você.
            </p>
        </>
    );
}

export default function ChargePix({ charge }) {
    let content = 'carregando';
    switch (charge.status) {
        case 'pending':
            content = <PixStatusPending charge={charge} />;
            break;
        case 'paid':
            content = <PixStatusPaid charge={charge} />;
            break;
        case 'canceled':
            content = <PixStatusCanceled charge={charge} />;
            break;
        case 'processing':
            content = <PixStatusProcessing charge={charge} />;
            break;
        case 'failed':
            content = <PixStatusFailed charge={charge} />;
            break;
        case 'overpaid':
            content = <PixStatusOverpaid charge={charge} />;
            break;
        case 'underpaid':
            content = <PixStatusUnderpaid charge={charge} />;
            break;
        case 'chargedback':
            content = <PixStatusChargedback charge={charge} />;
            break;
        default:
            content = 'carregando...';
    }

    return (
        <div className="charge col-12 p-3 d-flex flex-column align-items-center">
            <img src={logoPix} alt="Logo PIX" className="col-6 mb-3" />
            {content}
        </div>
    );
}
