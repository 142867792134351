import React, { useState, useEffect, useMemo } from 'react';
import { URLs, useReplaceParams } from '@app/constants';
import FileDownloader from '@app/components/FileDownloader/FileDownloader';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import PaymentControls from './components/PaymentControls';

function ProductControls({
    showPaymentControls = false,
    showFileDownloader = false,
    showProductButtonLink = false,
    updateRequest = () => {},
    ...props
}) {
    const replaceParams = useReplaceParams;

    const [product, setProduct] = useState({});
    useEffect(() => {
        setProduct(props.product);
    }, [props.product]);

    const customComponents = useMemo(() => {
        if (!product.custom_components?.footer) return null;
        return parse(product.custom_components?.footer);
    }, [product]);

    return (
        <>
            {showPaymentControls && (
                <PaymentControls
                    product={{
                        id: product?.token,
                        cart: product?.cart || null,
                        price: product?.price,
                        old_price: product?.old_price,
                        max_offering: product?.max_offering,
                        stock: product?.stock,
                        extras: product?.user_has || {},
                    }}
                    updateRequest={updateRequest}
                />
            )}
            <div className="product-button-links">
                {/* Link para download do documento */}
                {showFileDownloader && (
                    <FileDownloader
                        method="post"
                        url={replaceParams(URLs.club.products.download, { token: product?.token })}
                        label={'DOWNLOAD' || product?.user_has?.extras?.button_label || product?.name}
                        filename={product?.user_has?.extras?.filename}
                        extension={product?.user_has?.extras?.format}
                        className="product-file-downloader"
                    />
                )}
                {/* Link para acesso ao serviço */}
                {showProductButtonLink === true && (
                    <Link
                        to={{
                            pathname: product?.user_has?.extras?.url,
                            section: { name: '' },
                        }}
                        className="btn-go-to-product btn btn-success col-12 text-medium"
                        target={product?.user_has?.extras?.button_target || ''}
                    >
                        {replaceParams(product?.user_has?.extras?.button_label || 'Acessar o produto.', {
                            qtd: product?.user_has?.remaining,
                        })}
                    </Link>
                )}
                {/* Componentes customizados */}
                {customComponents}
            </div>
        </>
    );
}

export default ProductControls;
