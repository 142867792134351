import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import { Form } from 'reactstrap';
import { useFetch } from '@app/hooks/useFetch';

import { useUserManager } from '@app/hooks';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';

import Paginator from '@app/components/Paginator/Paginator';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CheckPermission from '@app/components/CheckPermission';
import UsersTable from './tables/UserTypesTable';
import UsersContext from './components/UserTypesContext';

function UserTypes() {
    const UserManager = useUserManager();
    const { token } = UserManager;

    const replaceParams = useReplaceParams;

    // Carrega as labels das features de sessões
    const [featureLabels, setFeatureLabels] = useState(null);
    useEffect(() => {
        if (featureLabels !== null) return;

        const loadFeatureLabels = () => {
            const url = URLs.admin.sections.features;
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setFeatureLabels(response.data);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados do usuário');
                });
        };

        loadFeatureLabels();
    }, [featureLabels, token]);

    /**
     * Consulta dados de sessões
     */
    const [params, setParams] = useState({
        nocache: new Date().getTime(),
        name: '',
        status: 'all',
        pg: 1,
        orderBy: 'order',
        orderDir: 'asc',
    });
    const { data, error, last_page } = useFetch(replaceParams(URLs.admin.user_types.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
    }, [data, error]);

    /**
     * Altera o status de um tipo de usuário
     */
    const handleUserTypeStatusChange = (e) => {
        const url = replaceParams(URLs.admin.user_types.status, { id: e.data.id });
        Api({ method: 'put', url, data: { status: e.data.checked ? 1 : 0 }, headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao alterar o status do tipo de usuário.');
                    e.data.setStatus(!e.data.checked);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status do tipo de usuário!');
                e.data.setStatus(!e.data.checked);
            });
    };

    /**
     * Filtros de pesquisa
     */
    const onSearchByNameChange = (e) => setParams((prev) => ({ ...prev, ...{ name: e.target.value, pg: 1 } }));
    const onSearchByStatusChange = (e) => setParams((prev) => ({ ...prev, ...{ status: e.target.value, pg: 1 } }));
    const handleOrderBy = (orderBy, orderDir) => setParams((prev) => ({ ...prev, ...{ orderBy, orderDir, pg: 1 } }));

    const handleNavigateFirst = () => setParams((prev) => ({ ...prev, ...{ pg: 1 } }));
    const handleNavigateLast = () => setParams((prev) => ({ ...prev, ...{ pg: last_page } }));
    const handleNavigateNext = () => setParams((prev) => ({ ...prev, ...{ pg: data?.length > 0 ? params.pg + 1 : params.pg } }));
    const handleNavigatePrevious = () => setParams((prev) => ({ ...prev, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } }));

    return (
        <ContentWrapper>
            <FilterContainer title="">
                <FilterFields className="col-4">
                    <Form className="login d-flex mb-3 col-12 ">
                        <fieldset className="col-4">
                            <label htmlFor="searchBy">Nome:</label>
                            <input type="text" name="searchBy" value={params.name} onChange={onSearchByNameChange} />
                        </fieldset>
                        <fieldset className="col-2">
                            <label htmlFor="orderBy">Status:</label>
                            <select name="status" id="filter-status" onChange={onSearchByStatusChange}>
                                <option value="" data-order-dir="asc">
                                    Todos
                                </option>
                                <option value="1" data-order-dir="asc">
                                    Apenas ativos
                                </option>
                                <option value="0" data-order-dir="asc">
                                    Apenas inativos
                                </option>
                            </select>
                        </fieldset>
                        <fieldset className="">
                            <CheckPermission permission="create">
                                <a href="/admin/tipos_usuario/novo" className="btn btn-primary btn-icon-left ">
                                    <FontAwesomeIcon icon={faPlus} className="mx-2" />
                                    Novo tipo de usuário
                                </a>
                            </CheckPermission>
                        </fieldset>
                        <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <UsersContext.Provider
                value={{
                    handleUserTypeStatusChange,
                }}
            >
                <div className="page-content">
                    <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                        <Paginator
                            pg={params.pg}
                            last_page={last_page}
                            hasPrevious={params.pg > 1}
                            hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                            handleNavigateFirst={handleNavigateFirst}
                            handleNavigateNext={handleNavigateNext}
                            handleNavigatePrevious={handleNavigatePrevious}
                            handleNavigateLast={handleNavigateLast}
                        />
                    </div>
                    <UsersTable data={data} handleOrderBy={handleOrderBy} featureLabels={featureLabels} />
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                </div>
            </UsersContext.Provider>
        </ContentWrapper>
    );
}

export default UserTypes;
