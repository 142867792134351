import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';
import moment from 'moment';
import { URLs, useSetParams } from '@app/constants';
import Api from '@app/services/Api';
import { secondsToTimeStr } from '@app/helpers/useFormatterHelper';
import TrafficContext from '../TrafficContext';

export default function TrafficPerPageTable({ className = '' }) {
    const { url, startDate, endDate, selectedUrl, onSelectedUrlChange } = useContext(TrafficContext);
    const setParams = useSetParams;
    const [data, setData] = useState(null);
    const [days] = useState(7);

    const [queryParams, setQueryParams] = useState({
        url,
        start: moment(startDate).format('yyyy/MM/DD'),
        end: moment(endDate).format('yyyy/MM/DD'),
        orderBy: 'qtd',
        orderDir: 'desc',
    });

    const setOrderBy = useCallback(
        (orderBy, orderDir) => {
            setQueryParams((prev) => ({ ...prev, orderBy, orderDir }));
        },
        [setQueryParams]
    );

    useEffect(() => {
        setQueryParams((prev) => ({
            ...prev,
            url,
            start: moment(startDate).format('yyyy/MM/DD'),
            end: moment(endDate).format('yyyy/MM/DD'),
        }));
    }, [startDate, endDate, url]);

    useEffect(() => {
        const url = setParams(URLs.admin.dashboard.traffic.totals, queryParams);
        Api({ method: 'get', url }).then(({ data }) => {
            setData(data);
            if (selectedUrl !== '' && data.findIndex((item) => item.url === selectedUrl) < 0) onSelectedUrlChange('');
        });
    }, [setParams, days, queryParams, selectedUrl, onSelectedUrlChange]);

    return (
        <div className={className}>
            <h3 className="text-center">Acessos por página</h3>
            <p className="m-0 text-small">
                <span className="text-error mr-1">*</span>Clique na rota para ver mais detalhes
            </p>
            <Table striped bordered hover col="12" className="text-small table-responsive">
                <thead>
                    <Tr handleOrderBy={setOrderBy}>
                        <Th param="url">URL</Th>
                        <Th param="qtd" className="text-center">
                            Acessos
                        </Th>
                        <th>Tempo médio na página</th>
                    </Tr>
                </thead>
                <tbody>
                    {(data || []).map((record, index) => (
                        <tr key={index} className={selectedUrl === record.url ? 'bg-warning' : ''}>
                            <td>
                                <div role="button" onClick={() => onSelectedUrlChange(record?.url)} tabIndex={0}>
                                    {record?.url || ''}
                                </div>
                            </td>
                            <td align="center">{record?.qtd || ''}</td>
                            <td align="center">{secondsToTimeStr(record?.avg_idle || 0)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
