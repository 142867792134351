import React, { useContext } from 'react';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckPermission from '@app/components/CheckPermission';
import { faPencilAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';

import { CustomSwitch } from '@app/components/ui';
import SuppliersContext from '../components/SuppliersContext';

function SuppliersTable(props) {
    const context = useContext(SuppliersContext);
    const data = props?.data;

    return (
        <Table striped hover col="12" className="text-medium table-responsive">
            <thead>
                <Tr handleOrderBy={props?.handleOrderBy || null}>
                    <th width="20px">&nbsp;</th>
                    <Th param="name">Nome</Th>
                    <Th param="description">Email</Th>
                    <th>Usuário</th>
                    <th>Telefone</th>
                    <Th param="price">Documento</Th>
                    <Th param="status">Ativo</Th>
                    <CheckPermission permission="edit">
                        <th>Editar</th>
                    </CheckPermission>
                </Tr>
            </thead>
            <tbody>
                {data?.length > 0 ? (
                    data?.map((record, id) => (
                        <tr key={id}>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                {record?.main === true && (
                                    <span className="text-regular-orange mx-2" title="Fornecedor principal">
                                        <FontAwesomeIcon icon={faStar} className="mr-2" />
                                    </span>
                                )}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>{record?.name}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{record?.email}</td>
                            <td>
                                <CheckPermission
                                    section_url="/usuarios"
                                    permission="list"
                                    allowed={<a href={`/admin/usuarios/${record?.user?.id}`}>{record?.user?.name || ''}</a>}
                                    notAllowed={<label>{record?.user?.name || ''}</label>}
                                />
                                <br />
                                <span className="text-small" style={{ whiteSpace: 'nowrap' }}>
                                    {record?.user?.email || ''}
                                </span>
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>{record?.phone}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{record?.document}</td>
                            <td>
                                <CheckPermission
                                    permission="change-status"
                                    allowed={
                                        <CustomSwitch
                                            name={`supplier-status-${record?.id}`}
                                            className="justify-content-start"
                                            onChange={context?.handleSupplierStateChange}
                                            checked={record?.status === 1 || false}
                                            isAsync
                                            data={{ token: record?.token, status: record?.status }}
                                        />
                                    }
                                    notAllowed={
                                        <label className={record.status === 1 ? 'text-success' : 'text-error'}>
                                            {record.status === 1 ? 'ATIVO' : 'INATIVO'}
                                        </label>
                                    }
                                />
                            </td>
                            <CheckPermission permission="edit">
                                <td className="text-center ">
                                    <a
                                        name="edit-supplier"
                                        href={`/admin/fornecedores/alterar/${record?.token}`}
                                        className="btn btn-primary-outline"
                                        title="Editar fornecedor"
                                    >
                                        <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                    </a>
                                </td>
                            </CheckPermission>
                        </tr>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={13} align="center">
                            Nenhum registro retornado
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default SuppliersTable;
