import React, { useMemo } from 'react';
import { Table } from 'reactstrap';

function SubscriptionItemRow({ data = {} }) {
    const recurrence = useMemo(() => {
        if (data.recurrence_type === 'unlimited') return 'Recorrente';
        if (data.remaining_cycles === 0) return 'Nenhum ciclo restante';

        const plural = data.remaining_cycles === 1 ? '' : 's';
        return `${data.remaining_cycles} ciclo${plural} restante${plural}`;
    }, [data]);

    return (
        <tr>
            <td>{data?.product?.name || data?.details || ''}</td>
            <td>{recurrence}</td>
            <td align="center">{data?.qtd || 0}</td>
        </tr>
    );
}

export default function SubscriptionItemsTable({ items = [], className = '' }) {
    return (
        <div className={`col-12 ${className}`}>
            <h3 className="text-center mt-2">Itens da assinatura</h3>
            <Table striped bordered hover col="12">
                <thead>
                    <tr>
                        <th>Descrição</th>
                        <th>Recorrência</th>
                        <th className="text-center">Qtd. / Ciclo</th>
                    </tr>
                </thead>
                <tbody>
                    {(items || []).length > 0 ? (
                        items?.map((item, index) => <SubscriptionItemRow key={index} data={item} />)
                    ) : (
                        <tr>
                            <td colSpan="7" align="center">
                                Nenhum item na assinatura
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
}
