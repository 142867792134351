import React, { useContext } from 'react';
import { Table } from 'reactstrap';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckPermission from '@app/components/CheckPermission';
import { faCopy, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Tr, Th } from '@app/components/OrderedTable/OrderedTable';

import { CustomSwitch } from '@app/components/ui';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';
import SubscriptionPlansContext from '../components/SubscriptionPlansContext';
import './PlansTable.scss';

function RowProduct({ items = {}, className = '', ...props }) {
    const floatToCurrency = useFloatToCurrency;
    return (
        <Table col="12" className={`text-small table-responsive plans-table-products ${className}`} {...props}>
            <thead>
                <tr>
                    <th>Produto</th>
                    <th className="text-right">Quantidade</th>
                    <th className="text-right">Preço</th>
                    <th className="text-right">Total</th>
                </tr>
            </thead>
            <tbody>
                {(items || []).map((item, index) => (
                    <tr key={index}>
                        <td>{item?.product?.name || ''}</td>
                        <td align="right">{item?.qtd || 0}</td>
                        <td align="right">{floatToCurrency(item?.price)}</td>
                        <td align="right">{floatToCurrency(item?.amount)}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="3" align="right">
                        Total:&nbsp;
                    </td>
                    <td colSpan="3" align="right">
                        {floatToCurrency((items || []).reduce((total, item) => total + item.amount, 0))}
                    </td>
                </tr>
            </tfoot>
        </Table>
    );
}

function PlansTable({ data = [], handleOrderBy = () => {} }) {
    const { handlePlanStateChange, handleDuplicatePlan, handleDeletePlan } = useContext(SubscriptionPlansContext);
    const floatToCurrency = useFloatToCurrency;

    return (
        <Table striped hover col="12" className="text-small table-responsive plans-table">
            <thead>
                <Tr handleOrderBy={handleOrderBy || null}>
                    <Th param="name">Nome</Th>
                    <th>Descrição</th>
                    <th>Modelo</th>
                    <th>Opções de Preços</th>
                    <Th param="status" className="text-center">
                        Ativo
                    </Th>
                    <CheckPermission permission="edit">
                        <th>Editar</th>
                    </CheckPermission>
                    <CheckPermission permission="create">
                        <th>Duplicar</th>
                    </CheckPermission>
                    <CheckPermission permission="delete">
                        <th>Excluir</th>
                    </CheckPermission>
                </Tr>
            </thead>
            <tbody>
                {data?.length > 0 ? (
                    data?.map((record, id) => (
                        <>
                            <tr key={id}>
                                <td style={{ whiteSpace: 'nowrap' }}>{record?.name}</td>
                                <td>{parse(record?.description)}</td>
                                <td>{record?.billing_type_label}</td>
                                <td>
                                    <div className="prices">
                                        {(record?.prices || []).map((price, index) => (
                                            <div key={index} className="price">
                                                <span className="name">{price.name}</span>
                                                <span className="amount">
                                                    R$&nbsp;
                                                    {floatToCurrency(price?.price)}
                                                </span>
                                                <span className="installments">{price.installments}x</span>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td align="center">
                                    <CheckPermission
                                        permission="change-status"
                                        allowed={
                                            <CustomSwitch
                                                name={`product-status-${record?.id}`}
                                                className="justify-content-center"
                                                onChange={handlePlanStateChange}
                                                checked={record?.status === 1 || false}
                                                isAsync
                                                data={{ token: record?.token, status: record?.status }}
                                            />
                                        }
                                        notAllowed={
                                            <label className={record.status === 1 ? 'text-success' : 'text-error'}>
                                                {record.status === 1 ? 'ATIVO' : 'INATIVO'}
                                            </label>
                                        }
                                    />
                                </td>
                                <CheckPermission permission="edit">
                                    <td className="text-center ">
                                        <a
                                            name="edit-plan"
                                            href={`/admin/planos/${record?.group_id}/alterar/${record?.token}`}
                                            className="btn btn-primary-outline"
                                            title="Editar plano"
                                        >
                                            <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                        </a>
                                    </td>
                                </CheckPermission>
                                <CheckPermission permission="create">
                                    <td className="text-center ">
                                        <button
                                            type="button"
                                            name="duplicate-plan"
                                            className="btn btn-primary-outline"
                                            title="Duplicar plano"
                                            onClick={() => handleDuplicatePlan(record?.token || '')}
                                        >
                                            <FontAwesomeIcon icon={faCopy} className="mr-2" />
                                        </button>
                                    </td>
                                </CheckPermission>
                                <CheckPermission permission="delete">
                                    <td className="text-center ">
                                        <button
                                            type="button"
                                            name="delete-plan"
                                            className="btn btn-primary-outline"
                                            title="Excluir plano"
                                            onClick={() => handleDeletePlan(record?.token || '', record?.name || '')}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                                        </button>
                                    </td>
                                </CheckPermission>
                            </tr>
                            <tr key={id} className="row-products">
                                <td colSpan="8">
                                    <RowProduct items={record?.items || []} />
                                </td>
                            </tr>
                        </>
                    ))
                ) : (
                    <tr key={0}>
                        <td colSpan={14} align="center">
                            Nenhum plano cadastrado neste grupo
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default PlansTable;
