import React, { useContext } from 'react';
import { CustomSwitch } from '@app/components/ui';
import FormEditorFieldPopupContext from '../../FormEditorFieldPopupContext';

function BooleanFieldDefaultSelector() {
    const { values, setFieldDefaultValue } = useContext(FormEditorFieldPopupContext);
    const checked = (values?.default || false) === true;

    return (
        <div className="d-flex col-12 pt-3 align-items-center h-100">
            <CustomSwitch
                name="default"
                className="justify-content-center"
                onChange={(value) => {
                    setFieldDefaultValue(value);
                }}
                checked={checked}
                isAsync={false}
                data={{ status: checked }}
            />
            <label className="text-small pl-1">Opção padrão</label>
        </div>
    );
}
export default BooleanFieldDefaultSelector;
