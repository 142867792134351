import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import CheckPermission from '@app/components/CheckPermission';
import { CustomSwitch } from '@app/components/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEnvelope, faTrash, faUserLock } from '@fortawesome/free-solid-svg-icons';

export default function CustomerTableRow({
    customer = undefined,
    handleCustomerResetPassword = () => {},
    handleCustomerConfirmEmail = () => {},
    handleCustomerConfirmPhone = () => {},
    handleCustomerStateChange = () => {},
    handleDeleteCustomer = () => {},
}) {
    const email_verified = useMemo(() => {
        if (customer.email_verified_at) return customer.email_verified_at;
        return (
            <>
                <label className="col-12 text-center text-small text-error">NÃO VERIFICADO</label>
                <Button
                    name="verify-email"
                    color="custom"
                    onClick={() => handleCustomerConfirmEmail(customer?.id)}
                    className="btn btn-primary-outline"
                    title="Enviar link de confirmação de email"
                >
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                </Button>
            </>
        );
    }, [customer, handleCustomerConfirmEmail]);

    const phone_verified = useMemo(() => {
        if (customer.phone_verified_at) return customer.phone_verified_at;
        return (
            <>
                <label className="col-12 text-center text-small text-error">NÃO VERIFICADO</label>
                <Button
                    name="verify-email"
                    color="custom"
                    onClick={() => handleCustomerConfirmPhone(customer?.id)}
                    className="btn btn-primary-outline"
                    title="Enviar link de confirmação do Whatsapp"
                >
                    <FontAwesomeIcon icon={faComment} className="mr-2" />
                </Button>
            </>
        );
    }, [customer, handleCustomerConfirmPhone]);

    const city = useMemo(() => {
        if (!customer?.city || !customer?.city?.name) return '';
        return `${customer.city.name}/${customer.city.state}`;
    }, [customer]);

    return (
        <tr>
            <td>
                <a href={`/admin/clientes/${customer.id}`} title="Visualizar dados deste cliente">
                    {customer?.name || ''}
                </a>
            </td>
            <td>{city}</td>
            <td>{customer?.email || ''}</td>
            <td align="center">{email_verified}</td>
            <td>{customer?.phone || ''}</td>
            <td align="center">{phone_verified}</td>
            <td>{customer?.created_at || ''}</td>
            <td align="center">
                <CheckPermission
                    permission="change-status"
                    allowed={
                        <CustomSwitch
                            name={`customer-status-${customer?.id}`}
                            className="justify-content-center"
                            onChange={handleCustomerStateChange}
                            checked={customer?.status === 1 || false}
                            isAsync
                            data={{ id: customer?.id, status: customer?.status }}
                        />
                    }
                    notAllowed={
                        <label className={customer.status === 1 ? 'text-success' : 'text-error'}>
                            {customer.status === 1 ? 'ATIVO' : 'INATIVO'}
                        </label>
                    }
                />
            </td>
            <CheckPermission permission="reset-password">
                <td align="center">
                    <Button
                        name="reset-password"
                        color="custom"
                        onClick={() =>
                            handleCustomerResetPassword(
                                customer?.id,
                                customer?.name,
                                !!customer?.phone_verified_at || false,
                                !!customer?.email_verified_at || false
                            )
                        }
                        className="btn btn-primary-outline"
                        title="Resetar a senha do usuário"
                    >
                        <FontAwesomeIcon icon={faUserLock} className="mr-2" />
                    </Button>
                </td>
            </CheckPermission>
            <CheckPermission permission="delete">
                <td align="center">
                    <Button
                        name="delete"
                        color="custom"
                        onClick={() => handleDeleteCustomer(customer?.id)}
                        className="btn btn-primary-outline"
                        title="Apagar usuário"
                    >
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    </Button>
                </td>
            </CheckPermission>
        </tr>
    );
}
