import { URLs, useReplaceParams } from '../constants';
import Api from '../services/Api';

export const usePaymentManager = () => {
    const replaceParams = useReplaceParams;

    const data = JSON.parse(localStorage.getItem('user-data'));
    const token = data?.access_token || '';

    return {
        /**
         * Cria uma nova compra com base em uma lista de produtos
         */
        createPurchase: async ($items) => {
            const response = await Api({
                method: 'post',
                url: URLs.club.purchases.create,
                headers: {
                    Authorization: `Bearer ${token}`,
                    ContentType: 'application/json',
                },
                data: { items: $items },
            })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar iniciar o pagamento.' };
                });
            return response;
        },
        /**
         * Cancela a compra baseada nos itens do carrinho
         */
        cancelPurchase: async ($token) => {
            const response = await Api({
                method: 'post',
                url: replaceParams(URLs.club.purchases.cancel, { token: $token }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    ContentType: 'application/json',
                },
            })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar iniciar o pagamento.' };
                });
            return response;
        },
        /**
         * Solicita a confirmação do pagamento de uma intenção de compra
         */
        confirmPurchasePayment: async ($token) => {
            const response = await Api({
                method: 'post',
                url: replaceParams(URLs.club.purchases.confirm, { token: $token }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    ContentType: 'application/json',
                },
            })
                .then((resp) => {
                    const { data } = resp;
                    if (data && resp.status === 200) {
                        return { ...data, ...{ status: resp.status } };
                    }
                    return { ...data, ...{ status: resp.status } };
                })
                .catch((err) => {
                    if (err.response) {
                        return { ...err.response.data, ...{ status: err.response.status } };
                    }
                    return err?.data || { status: null, data: null, message: 'Erro inesperado ao tentar iniciar o pagamento.' };
                });
            return response;
        },
    };
};
