import React, { useState } from 'react';
import { DropDown, DropdownItem } from '@app/components/Autocomplete';
import { URLs, useSetParams } from '@app/constants';
import Api from '@app/services/Api';
import { toast } from 'react-hot-toast';

function ProductSearch({ value = '', base_url = URLs.admin.products.basics, search_params = {}, onChange = null, ...props }) {
    const setParams = useSetParams;
    /**
     * Consulta por produtos
     */
    const [suggestions, setSuggestions] = useState([]);
    const onSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason !== 'input-changed') return;
        const url_params = { ...search_params, name: value };

        const url = setParams(base_url, url_params);
        try {
            Api({ method: 'get', url })
                .then(({ data, status }) => {
                    if (status === 200) {
                        setSuggestions(data || []);
                    } else {
                        toast.error(data?.message || 'Erro ao obter dados dos produtos');
                    }
                })
                .catch((err) => {
                    toast.error(err?.data?.message || 'Erro inesperado ao tentar encontrar o produto.');
                });
        } catch ({ response }) {
            toast.error(response?.data?.message || 'Erro inesperado ao tentar encontrar o produto.');
        }
    };

    const handleSuggestionSelected = (suggestion) => {
        if (onChange !== null) onChange(suggestion);
        setSuggestions([]);
        return suggestion.name;
    };
    // Render the suggested user list
    const renderSuggestions = (suggestion) => (
        <DropdownItem tag="label" className="suggestion-item" key={suggestion.id}>
            {suggestion.name}
        </DropdownItem>
    );
    return (
        <DropDown
            className="col-12"
            {...props}
            placeholder="Buscar produto"
            selectedValue={value}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            renderSuggestion={renderSuggestions}
            getSuggestionValue={(suggestion) => {
                handleSuggestionSelected(suggestion);
            }}
        />
    );
}

export default ProductSearch;
