import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

// import CheckPermission from '@app/components/CheckPermission';
import VariablesTable from './table/VariablesTable';

export default function Configurations() {
    return (
        <ContentWrapper>
            <div className="page-content">
                {/* <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                    <CheckPermission permission="export">
                        <div className="d-flex mb-1">
                            <FileDownloader
                                url={replaceParams(URLs.admin.sales.export, { ...params, ...{ ext: 'csv' } })}
                                filename="vendas"
                                label="Exportar CSV"
                                extension="csv"
                                className=""
                            />
                        </div>
                    </CheckPermission>
                </div> */}
                <VariablesTable data={null} />
            </div>
        </ContentWrapper>
    );
}
