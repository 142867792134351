import React, { useContext, useState } from 'react';

import './TaxationRule.scss';
import { Label, Table } from 'reactstrap';
import { faAngleDown, faAngleUp, faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomSwitch } from '@app/components/ui';
import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import { toast } from 'react-hot-toast';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import TaxationContext from '../TaxationContext';

function TaxationRuleSplitTable({ split }) {
    return (
        <Table striped bordered hover className="taxation-rule-split-table">
            <thead>
                <tr>
                    <th>Recebedor</th>
                    <th width="20%">Valor a receber</th>
                </tr>
            </thead>
            <tbody>
                {split.map((record, index) => {
                    const tax =
                        record.split_type === 'flat'
                            ? `R$ ${record.amount.toLocaleString()}`
                            : `${(record.amount * 100).toLocaleString()}%`;
                    return (
                        <tr key={index}>
                            <td>{record?.supplier?.name || ''}</td>
                            <td>{tax}</td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

function TaxationRuleSubrules({ rules, parent_id = null, parent_description = '' }) {
    const [isOpen, setIsOpen] = useState(true);
    const droppableId = `${parent_id}`;
    return (
        <div className="rule-subrules">
            <h3 className="d-flex">
                <div role="button" tabIndex={0} onKeyDown={() => {}} onClick={() => setIsOpen(!isOpen)} className="col-12">
                    <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} className="mx-2" />
                    <span className="flex-grow-1">
                        sub-regras <span className="counter">(&nbsp;{rules?.length || 0}&nbsp;)</span>
                    </span>
                </div>
            </h3>
            <div style={{ display: isOpen ? '' : 'none' }}>
                {rules.length > 0 ? (
                    <Droppable droppableId={droppableId} type={droppableId} key={droppableId}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {rules.map((rule, index) => (
                                    <Draggable key={`section-${rule.id}`} draggableId={`${rule.id}`} id={rule.id} index={index}>
                                        {(provided, snapshot) => {
                                            const draggingClass = snapshot.isDragging ? 'row-dragging' : '';
                                            return (
                                                <div
                                                    key={index}
                                                    className={`rule-subrule d-flex ${draggingClass}`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className="rule-subrule-order d-flex">{rule.order + 1}</div>
                                                    <TaxationRule
                                                        rule={rule}
                                                        parent_id={parent_id}
                                                        parent_description={parent_description}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                ) : (
                    <p className="col-12 m-0 p-0 text-center">Nenhuma sub-regra cadastrada</p>
                )}
            </div>
        </div>
    );
}

function TaxationRule({ rule, parent_id = null, parent_description = '' }) {
    const replaceParams = useReplaceParams;
    const { onAddNewRule, onDeleteRule, onChangeRule, updateRequest } = useContext(TaxationContext);

    const handleRuleStateChange = (e) => {
        const url = replaceParams(URLs.admin.taxation.status, { id: rule.id });
        Api({ method: 'put', url, data: { status: e.data.checked ? 1 : 0 } })
            .then(({ data, status }) => {
                if (status !== 200) {
                    toast.error(data?.message || 'Erro ao alterar o status da regra.');
                    e.data.setStatus(!e.data.checked);
                } else {
                    updateRequest();
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status da regra!');
                e.data.setStatus(!e.data.checked);
            });
    };

    return (
        <div className="taxation-rule d-flex flex-column col-12">
            <div className="rule-header d-flex col-12 pt-1">
                <label htmlFor="" className="description">
                    {rule.description}
                </label>
                <label htmlFor="" className="supplier">
                    Fornecedor: <span>{rule?.supplier?.name || 'Todos'}</span>
                </label>
                <label htmlFor="" className="product">
                    Produto: <span>{rule?.product?.name || 'Todos'}</span>
                </label>
                <button
                    type="button"
                    className="btn btn-secondary"
                    title="Adicionar sub-regra à esta regra"
                    onClick={() => onAddNewRule(rule.id, rule.description)}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    title="Editar esta regra"
                    onClick={() => onChangeRule(rule, parent_id, parent_description)}
                >
                    <FontAwesomeIcon icon={faPencilAlt} />
                </button>
                {!rule.main && (
                    <button
                        type="button"
                        className="btn btn-danger"
                        title="Excluir esta regra"
                        onClick={() => onDeleteRule(rule.id, rule.description)}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                )}
            </div>
            <div className="rule-conditions d-flex col-12">
                <label className="col-12 flex-shrink-1">
                    Condições: <span className="mx-2 fw-normal">{rule?.conditions_label || 'Qualquer condição'}</span>
                </label>
                {!rule.main && (
                    <div className="d-flex align-items-center">
                        <Label className="field-label fw-normal text-small mx-2" htmlFor="all_suppliers">
                            {rule.main ? 'Desativar' : 'Ativar'}
                        </Label>
                        <CustomSwitch
                            name="all_suppliers"
                            className="justify-content-center"
                            onChange={handleRuleStateChange}
                            checked={rule.status}
                            isAsync
                            data={{ id: rule?.id, status: rule?.status }}
                            disabled={false}
                        />
                    </div>
                )}
            </div>
            <div className="rule-split">
                <TaxationRuleSplitTable split={rule.split} />
                {(rule?.rules || []).length > 0 && (
                    <TaxationRuleSubrules rules={rule.rules} parent_id={rule.id} parent_description={rule.description} />
                )}
            </div>
        </div>
    );
}
export default TaxationRule;
