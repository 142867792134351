import React, { useMemo } from 'react';
import { Button, Label, Form } from 'reactstrap';

import Modal from 'react-modal';
import { Field, Formik } from 'formik';

import * as yup from 'yup';
import { PopupStyle } from '@app/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import FormikDevTools from '@app/components/FormikDevTools';
import FormPriceStandard from './FormPriceStandard';

const getStandardPriceDefaultValues = (config) => ({
    price: config?.price || 0,
    qtd: config?.qtd || 0,
    min_qtd: config?.min_qtd || 1,
    max_qtd: config?.max_qtd || null,
    discount: config?.discount || 0.0,
    discount_type: config?.discount_type || 'percentage',
    discount_after: config?.discount_after || null,
});

const getListPriceDefaultValues = (options) => {
    const response = (options || []).map((option) => ({
        price: option?.price || 0,
        qtd: option?.qtd || 0,
        label: option?.min_qtd || 1,
    }));
    if (response.length === 0) {
        response.push({
            qtd: 0,
            label: 'Não quero aproveitar',
            price: 0,
        });
    }
    return response;
};

export default function ChangeOptionalPricePopup({
    onSubmit = () => {},
    closeModal = () => {},
    modalIsOpen = false,
    title = 'Alterar Preço',
    isSubmitting = false,
    data = {},
}) {
    const afterOpenModal = () => {};

    // const handleChangeQtd = (values, setValues, qtd) => {
    //     let newQtd = Number(parseInt(qtd)).isNaN ? 0 : parseInt(qtd);
    //     const max_offering = parseInt(values.max_offering);
    //     newQtd = !Number(max_offering).isNaN && newQtd > max_offering ? max_offering : newQtd;

    //     setValues((prev) => ({
    //         ...prev,
    //         ...{
    //             qtd: newQtd,
    //             total: parseFloat(values.price) * newQtd,
    //         },
    //     }));
    // };

    const handleFormSubmit = (values) => {
        onSubmit(values);
        closeModal();
    };

    const validations = yup.object().shape({
        price: yup
            .number()
            .typeError('Informe uma quantidade válida')
            .transform((_value, originalValue) => Number(`${originalValue}`.replace(/,/, '.')))
            .required('É necessário informar uma quantidade.'),
        total: yup
            .number()
            .typeError('Informe um total válido')
            .transform((_value, originalValue) => Number(`${originalValue}`.replace(/,/, '.')))
            .required('É necessário informar um valor total.'),
    });

    const onChangePriceType = (value, values, setValues) => {
        let price_config = {};
        switch (value) {
            case 'list':
                price_config = getListPriceDefaultValues([]);
                break;
            default:
                price_config = getStandardPriceDefaultValues({});
                break;
        }
        setValues((prev) => ({
            ...prev,
            price_type: value,
            price_config,
        }));
    };

    const initialValues = useMemo(() => {
        let price_config = {};
        switch (data?.price_type) {
            case 'list':
                price_config = getListPriceDefaultValues(data?.price_config || []);
                break;
            default:
                price_config = getStandardPriceDefaultValues(data?.price_config || {});
                break;
        }
        return {
            token: data?.product_id || '',
            price_type: data?.price_type || 'standard',
            price_config,
            price: data?.product?.price,
            max_offering: parseInt(data?.product?.max_offering),
            qtd: data?.qtd || 1,
            total: data?.price || 0,
            currency: 13.5,
        };
    }, [data]);

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{ ...PopupStyle, ...{ content: { ...PopupStyle.content, ...{ maxWidth: '600px' } } } }}
            contentLabel={title}
            className=""
            ariaHideApp={false}
        >
            <div className="col-12 mb-2 d-flex align-items-center">
                <h2 className="flex-grow-1 text-center text-large my-0">{data?.product?.name || ''}</h2>
                <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={closeModal}>
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                </Button>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleFormSubmit}
                enableReinitialize
                render={({ values, setValues, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="form d-flex flex-wrap">
                        <div className="col-12">
                            <div className="mb-1">
                                <Label htmlFor="name">Tipo de precificação</Label>
                                <Field
                                    as="select"
                                    name="price_type"
                                    className="col-12"
                                    onChange={(e) => {
                                        onChangePriceType(e.target.value, values, setValues);
                                    }}
                                >
                                    <option value="standard">Padrão</option>
                                    <option value="list">Lista de Opções</option>
                                </Field>
                            </div>
                            {values.price_type === 'standard' && <FormPriceStandard />}
                            <div className="d-flex">
                                <div className="mb-3 px-1 col-6 text-center">
                                    <Button type="submit" className="col-12" color="danger" disabled={isSubmitting} onClick={closeModal}>
                                        CANCELAR
                                    </Button>
                                </div>
                                <div className="mb-3 px-1 col-6 text-center">
                                    <Button type="submit" className="col-12" color="success" disabled={isSubmitting}>
                                        APLICAR
                                    </Button>
                                </div>
                            </div>
                            <FormikDevTools />
                        </div>
                    </Form>
                )}
            />
        </Modal>
    );
}
