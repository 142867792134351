import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row } from 'reactstrap';

import './Paginator.scss';

function Paginator({
    pg = 1,
    handleNavigateFirst = () => {},
    handleNavigatePrevious = () => {},
    handleNavigateNext = () => {},
    handleNavigateLast = () => {},
    hasNext = false,
    hasPrevious = false,
    props,
}) {
    const [last_page, setLastPage] = useState(pg);

    useEffect(() => {
        setLastPage(props?.last_page || last_page);
    }, [props?.last_page, last_page]);

    return (
        <Row className="justify-content-end mx-1 my-1 paginator flex-row">
            <Col xs="auto" md="auto" className="px-0">
                <Button
                    name="navigate-first"
                    color="custom"
                    disabled={!hasPrevious}
                    className="btn btn-primary-outline btn-icon-small"
                    title="Primeira página"
                    onClick={handleNavigateFirst}
                >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} className="mr-2" />
                </Button>
            </Col>
            <Col xs="auto" md="auto" className="px-1">
                <Button
                    name="navigate-prior"
                    color="custom"
                    disabled={!hasPrevious}
                    className="btn btn-primary-outline btn-icon-small"
                    title="Página anterior"
                    onClick={handleNavigatePrevious}
                >
                    <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                </Button>
            </Col>
            <Col xs="auto" md="auto" className="px-1">
                <div>
                    {pg}
                    {last_page ? ` de ${last_page}` : ''}
                </div>
            </Col>
            <Col xs="auto" md="auto" className="px-1">
                <Button
                    name="navigate-next"
                    color="custom"
                    disabled={!hasNext}
                    className="btn btn-primary-outline btn-icon-small"
                    title="Próxima página"
                    onClick={handleNavigateNext}
                >
                    <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                </Button>
            </Col>
            <Col xs="auto" md="auto" className="px-0">
                <Button
                    name="navigate-last"
                    color="custom"
                    disabled={!hasNext}
                    className="btn btn-primary-outline btn-icon-small"
                    title="Última página"
                    onClick={handleNavigateLast}
                >
                    <FontAwesomeIcon icon={faAngleDoubleRight} className="mr-2" />
                </Button>
            </Col>
        </Row>
    );
}

export default Paginator;
