import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Label, Table } from 'reactstrap';

import { DropdownItem, DropDown } from '@app/components/Autocomplete';

import { toast } from 'react-hot-toast';

import { useUserManager } from '@app/hooks/useUserManager';
import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';

import { ErrorMessage, Field, useFormikContext } from 'formik';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';
import SubscriptionPlansContext from '../../../components/SubscriptionPlansContext';
import ChangePricePopup from '../../popups/ChangePricePopup';
import ProductRow from './ProductRow';

function TableHead() {
    return (
        <thead>
            <tr>
                <th className="text-center">Tipo</th>
                <th>Nome</th>
                <th className="text-right">Preço (R$)</th>
                <th className="text-right">Qtd.</th>
                <th className="text-right">Desconto</th>
                <th className="text-right">Total (R$)</th>
                <th className="text-center">&nbsp;</th>
                <th>Vendido por</th>
                <th className="text-center">Status</th>
                <th className="text-center">Remover</th>
            </tr>
        </thead>
    );
}

function TableFoot(props) {
    const [productPrice, setProductPrice] = useState(props?.productPrice || 0);
    useEffect(() => {
        setProductPrice(props?.productPrice || 0);
    }, [props]);

    return (
        <tfoot>
            <tr>
                <th className="text-right" colSpan="5">
                    Total (R$)
                </th>
                <th className="text-right">{productPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</th>
                <th colSpan="4">&nbsp;</th>
            </tr>
        </tfoot>
    );
}

export default function FormSliceIncludedProducts({ isLoading = false, itemType = 'included' }) {
    const { values, setValues } = useFormikContext();
    const { recalculatePrices } = useContext(SubscriptionPlansContext);
    const replaceParams = useReplaceParams;
    const UserManager = useUserManager();
    const { token } = UserManager;

    // Inclui um produto na lista
    const handleAddNewProduct = (product) => {
        // Calculando o valor do pacote
        let amount = 0;
        if (
            (values?.items || []).filter((item) => {
                amount += item.amount;
                return item.product_id === product.token;
            }).length > 0
        )
            return;
        amount += product.price;

        setValues((prev) => ({
            ...prev,
            ...{
                items: [
                    ...prev.items,
                    ...[
                        {
                            product_id: product?.token,
                            qtd: 1,
                            price: product?.price || 0,
                            amount: product?.price || 0,
                            recurrence_type: 'unlimited',
                            product,
                        },
                    ],
                ],
                prices: recalculatePrices(amount, prev.interval, prev.interval_count, prev.prices),
                price: amount,
            },
        }));
    };
    const handleProductPriceChange = (price) => {
        setValues((prev) => {
            const newList = prev?.items || [];
            let amount = 0;
            newList.forEach((item, index) => {
                if (item.product_id === price.token) {
                    newList[index].qtd = price.qtd;
                    newList[index].amount = parseFloat(price.total);
                }
                amount += newList[index].amount;
            });
            return {
                ...prev,
                ...{
                    price: amount,
                    prices: recalculatePrices(amount, prev.interval, prev.interval_count, prev.prices),
                    items: newList,
                },
            };
        });
    };

    /**
     * Consulta por produtos
     */
    const params = { type: '', pg: 1, orderBy: 'name', orderDir: 'asc' };
    const [suggestions, setSuggestions] = useState([]);
    const onSuggestionsFetchRequested = async ({ value, reason }) => {
        if (reason !== 'input-changed') return;
        try {
            Api({
                method: 'get',
                url: replaceParams(URLs.admin.products.list, {
                    ...params,
                    ...{ name: value, except: values?.token || '', nocache: new Date().getTime() },
                }),
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) => {
                    setSuggestions(response.data?.data || []);
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao tentar consultar o produto.');
                });
        } catch (error) {
            // console.log(error);
        }
    };

    // Adiciona o usuário à lista de acesso
    const handleSuggestionSelected = (suggestion, values, setValues) => {
        if (values === null || setValues === null) return;
        handleAddNewProduct(suggestion);
        setSuggestions([]);
        return suggestion.name;
    };
    // Render the suggested user list
    const renderSuggestions = (suggestion) => (
        <DropdownItem tag="label" className="suggestion-item" key={suggestion.id}>
            {suggestion.name}
        </DropdownItem>
    );

    /**
     * Controles da popup de alteração de preço do produto
     */
    const [selectedProduct, setSelectedProduct] = useState({});
    const [pricePopupIsOpen, setPricePopupIsOpen] = useState(false);
    const openPricePopup = () => {
        setPricePopupIsOpen(true);
    };
    const closePricePopup = () => {
        setPricePopupIsOpen(false);
    };
    const handleChangeProductPrice = useCallback(
        (token) => {
            values.items?.forEach((item) => {
                if (item.product_id === token) {
                    setSelectedProduct(Object.assign(item));
                    openPricePopup();
                }
            });
        },
        [values]
    );

    return (
        <>
            <h3 className="field-group-title col-12 mt-3">Produtos incluídos no plano</h3>
            <p className="text-small col-12 m-0">Selecione os produtos não opcionais que já estarão incluídos no valor do plano</p>
            <div className="mb-1 px-1 col-12 d-flex flex-wrap">
                <div className="col-12">
                    <div className="col-12 col-sm-8 py-1 pb-3">
                        <Label htmlFor="city_id">Buscar produto</Label>
                        <DropDown
                            className="col-12"
                            disabled={isLoading}
                            name="product_name"
                            placeholder=""
                            selectedValue=""
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            renderSuggestion={renderSuggestions}
                            getSuggestionValue={(suggestion) => {
                                handleSuggestionSelected(suggestion, values, setValues);
                            }}
                            formValues={values}
                            onSetValues={setValues}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <Table striped hover col="12" className="text-small table-responsive">
                        <TableHead />
                        <tbody>
                            {values.items.length > 0 ? (
                                values.items
                                    ?.filter((item) => item.item_type === itemType)
                                    .map((record, index) => (
                                        <ProductRow data={record} key={index} onChangePriceClick={handleChangeProductPrice} />
                                    ))
                            ) : (
                                <tr key={0}>
                                    <td colSpan={13} align="center">
                                        Nenhum produto no pacote
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <TableFoot productPrice={(values?.items || []).length > 0 ? values?.price || 0 : 0} />
                    </Table>
                </div>
                <ErrorMessage component="span" name="items" className="col-12 text-error text-small" />
            </div>
            <div className="d-flex flex-wrap px-1 py-1 col-12 col-sm-10 col-md-8 col-lg-6 d-flex">
                <Label className="col-12 field-label" htmlFor="description">
                    Valor total de <strong className="mx-1">R$ {useFloatToCurrency(values?.price || 0)}</strong>referente ao período de:
                </Label>
                <div className="col-12 d-flex">
                    <Field name="interval_count" type="number" min={1} placeholder="" className="col-6" disabled={isLoading} />
                    <Field as="select" name="interval" className="col-6 flex-shrink-1 ml-1">
                        <option value="day">Dia(s)</option>
                        <option value="week">Semana(s)</option>
                        <option value="month">Mes(es)</option>
                        <option value="year">Ano(s)</option>
                    </Field>
                </div>
                <ErrorMessage component="span" name="interval_count" className="col-12 text-error text-small" />
            </div>
            <ChangePricePopup
                modalIsOpen={pricePopupIsOpen}
                closeModal={closePricePopup}
                data={selectedProduct}
                onSubmit={handleProductPriceChange}
            />
        </>
    );
}
