import React from 'react';

import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga';
import store from './store';
import Routes from './routes/Routes';
import CookieAlert from './components/CookieAlert';
import { DRCAProvider } from './contexts/DRCAContext';

ReactGA.initialize('G-WE7289RTYC', { debug: true });

function App() {
    return (
        <Provider store={store}>
            <div className="App">
                <ToastProvider>
                    <DRCAProvider>
                        <Routes />
                    </DRCAProvider>
                </ToastProvider>
                <Toaster
                    position="bottom-right"
                    toastOptions={{
                        duration: 5000,
                        style: {
                            border: '2px solid #6b3b1c',
                            color: '#6b3b1c',
                        },
                    }}
                />
                <CookieAlert />
            </div>
        </Provider>
    );
}

export default App;
