import React, { useMemo } from 'react';
import { useFloatToCurrency } from '@app/helpers';

export default function AditionalItemPriceValue({ value = 0, unitLabel = '', className = '' }) {
    const floatToCurrency = useFloatToCurrency;
    const price = useMemo(() => {
        const priceArr = floatToCurrency(value || 0.0).split(',');
        return {
            priceWhole: priceArr[0],
            priceFraction: priceArr[1],
        };
    }, [value, floatToCurrency]);

    return (
        <div className={`d-flex align-items-start aditional-item-price ${className}`}>
            <span className="price-symbol">R$</span>
            <span className="price-whole fw-bold">{price.priceWhole}</span>
            <span className="price-fraction">{price.priceFraction}</span>
            {unitLabel !== '' && <span className="price-unit-label">{unitLabel}</span>}
        </div>
    );
}
