const createUniqueId = () => Math.floor(new Date().getTime() * Math.random());

export default [
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'nome',
        label: 'Nome',
        type: 'string',
        show: 1,
        validations: 'required|min:5|max:100',
        order: 0,
        style: 'px-1 col-6',
        mask: null,
        ref: '',
    },
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'email',
        label: 'Email',
        type: 'string',
        show: 1,
        validations: 'required|email',
        order: 1,
        style: 'px-1 col-6',
        mask: null,
        ref: '',
    },
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'cpf',
        label: 'CPF',
        type: 'masked',
        show: 0,
        validations: 'required|cpf',
        order: 2,
        style: 'px-1 col-6',
        mask: '999.999.999-99',
        ref: '',
    },
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'cnpj',
        label: 'CNPJ',
        type: 'masked',
        show: 0,
        validations: 'required|cnpj',
        order: 3,
        style: 'px-1 col-6',
        mask: '99.999.999/9999-99',
        ref: '',
    },
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'endereco',
        label: 'Endereço',
        type: 'string',
        show: 0,
        validations: 'required|min:5|max:200',
        order: 4,
        style: 'px-1 col-6',
        mask: null,
        ref: '',
    },
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'cep',
        label: 'CEP',
        type: 'masked',
        show: 0,
        validations: 'required|cep',
        order: 5,
        style: 'px-1 col-6',
        mask: '99.999-999',
    },
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'complemento',
        label: 'Complemento',
        type: 'string',
        show: 0,
        validations: 'max:100',
        order: 6,
        style: 'px-1 col-6',
        mask: null,
        ref: '',
    },
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'bairro',
        label: 'Bairro',
        type: 'string',
        show: 0,
        validations: 'required|min:5|max:100',
        order: 7,
        style: 'px-1 col-6',
        mask: null,
        ref: '',
    },
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'estado',
        label: 'Estado',
        type: 'states',
        show: 0,
        validations: 'required',
        order: 8,
        style: 'px-1 col-6',
        mask: null,
        ref: '',
    },
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'cidade',
        label: 'Cidade',
        type: 'cities',
        show: 0,
        validations: 'required',
        order: 9,
        style: 'px-1 col-6',
        mask: null,
        ref: '',
    },
    {
        disabled: false,
        selected: false,
        id: createUniqueId(),
        section_content_id: null,
        name: 'concordo',
        label: 'Li e concordo com a política de privacidade',
        type: 'boolean',
        validations: 'required',
        order: 10,
        style: 'px-1 col-6',
        mask: null,
        ref: '',
    },
];
