import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';

import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import { useSelector } from 'react-redux';

function CartSummary({ isLoadingPayment = false, handleCloseCart = () => null, ...props }) {
    const cart_summary = useSelector((state) => state.summary);

    return (
        <div className="d-flex col-12 flex-wrap cart-summary justify-content-center mb-3" {...props}>
            <div className="cart-values col-12 col-md-8 p-3 d-flex flex-column">
                <h2 className="text-large text-center">Resumo do seu carrinho de compras</h2>
                <div className="border-bottom my-2 col-12 d-flex flex-row">
                    <label className="text-medium col-8">
                        Subtotal ({cart_summary?.cart?.count} {cart_summary?.cart?.count === 1 ? 'Item' : 'Itens'}):
                    </label>
                    <label className="text-medium col-4 text-right">
                        <strong>R$ {(cart_summary?.cart?.amount || 0)?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</strong>
                    </label>
                </div>
                {isLoadingPayment && <LoadingSpinner />}
            </div>

            {(cart_summary?.cart?.count || 0) > 0 && (
                <div className="cart-controls col-12 col-md-4 p-3 d-flex flex-column align-items-center justify-content-center">
                    <Button
                        type="button"
                        title="Fechar pedido e finalizar a compra"
                        className="m-1 btn btn-primary btn-icon-left text-small col-12"
                        color="custom"
                        onClick={handleCloseCart}
                        disabled={isLoadingPayment || false}
                    >
                        <FontAwesomeIcon icon={faBox} className="mx-2 my-1" />
                        <span>FECHAR CARRINHO</span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default CartSummary;
