import React, { useMemo } from 'react';
import { useFloatToCurrency } from '@app/helpers/useFormatterHelper';

export default function ProductPriceStandardCols({
    qtd = 0,
    price = 0,
    max_qtd = 0,
    min_qtd = 0,
    discount = 0,
    discount_type = 'percentage',
    discount_after = 0,
}) {
    const floatToCurrency = useFloatToCurrency;
    const discountLabel = useMemo(() => {
        if (discount === 0) return 'R$ 0,0';
        return (
            <>
                Aplicar desconto de
                <span className=" mx-1 fw-bold">
                    {discount_type === 'flat' ? `R$ ${floatToCurrency(discount)} ` : `${floatToCurrency(discount * 100)}% `}
                </span>
                {Number(discount_after).isNaN ? '' : ` após ${discount_after} itens`}
            </>
        );
    }, [discount, discount_type, discount_after, floatToCurrency]);

    return (
        <>
            <td align="left" data-title="Preço Un.(R$)">
                Preço Unitário{' '}
                <span className="fw-bold ml-1">
                    R$&nbsp;
                    {price.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                </span>
            </td>
            <td align="left" data-title="Quantidade">
                Qtd.:<span className="fw-bold ml-1">{qtd}</span>
                <br />
                Qtd. Min.:<span className="fw-bold ml-1">{min_qtd}</span>
                <br />
                Qtd. Max.:<span className="fw-bold ml-1">{max_qtd}</span>
            </td>
            <td data-title="Desconto Un.(R$)">{discountLabel}</td>
        </>
    );
}
