import React, { useContext, useMemo } from 'react';
import PlanDisplayPlanRenderer from '@app/components/Plans/PlanDisplay/Renderer/PlanDisplayPlanRenderer';
import PlanEditor from './PlanEditor';
import PlanDisplayEditorContext from '../../PlanDisplayEditorContext';

export default function PlanDisplayEditorPlanRenderer({ plan, theme }) {
    const { content } = useContext(PlanDisplayEditorContext);
    const planConfig = useMemo(() => (content.filter?.plans || []).filter((item) => item.id === plan.id)[0] || {}, [content, plan]);
    return (
        <PlanEditor plan={planConfig}>
            <PlanDisplayPlanRenderer plan={plan} theme={theme} />
        </PlanEditor>
    );
}
