import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';

function ProductTypesSelector(props) {
    return (
        <select name="value" placeholder="Valor" className="col-12" {...props}>
            <option value="">-- selecione um parâmetro --</option>
            <option value="object">Objeto</option>
            <option value="video">Vídeo</option>
            <option value="document">Documento</option>
            <option value="service">Serviço</option>
            <option value="pack">Pacote / Curso</option>
        </select>
    );
}

function ProductPriceSelector(props) {
    return <input type="number" name="value" placeholder="Valor" className="col-12" {...props} />;
}

function ProductQtdSelector(props) {
    return <input type="number" name="value" placeholder="Valor" className="col-12" {...props} />;
}

function ProductTotalSelector(props) {
    return <input type="number" name="value" placeholder="Valor" className="col-12" {...props} />;
}

const ops_available = {
    '==': 'é igual a',
    '!=': 'é diferente de',
    '>': 'é maior que',
    '>=': 'é maior ou igual a',
    '<': 'é menor que',
    '<=': 'é menor ou igual a',
};
const product_types_available = {
    object: 'Objeto',
    video: 'Vídeo',
    document: 'Documento',
    service: 'Serviço',
    pack: 'Pacote / Curso',
};
const params_config = {
    x: {
        label: '-- selecione um parâmetro --',
        ops: [],
        selector: () => {},
        value: (value) => value,
    },
    product_type: {
        label: 'Tipo de produto',
        ops: ['=='],
        selector: (onChangeValue) => <ProductTypesSelector onChange={onChangeValue} />,
        value: (value) => product_types_available[value],
    },
    price: {
        label: 'Valor unitário',
        ops: ['==', '!=', '>', '>=', '<', '<='],
        selector: (onChangeValue) => <ProductPriceSelector onChange={onChangeValue} />,
        value: (value) => parseFloat(value || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
    qtd: {
        label: 'Quantidade',
        ops: ['==', '!=', '>', '>=', '<', '<='],
        selector: (onChangeValue) => <ProductQtdSelector onChange={onChangeValue} />,
        value: (value) => parseInt(value || 0),
    },
    total: {
        label: 'Valor total',
        ops: ['==', '!=', '>', '>=', '<', '<='],
        selector: (onChangeValue) => <ProductTotalSelector onChange={onChangeValue} />,
        value: (value) => parseFloat(value || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
};
const createConditionsLabel = (conditions) =>
    conditions
        .map(
            (condition) =>
                `${params_config[condition[0]].label} ${ops_available[condition[1]]} "${params_config[condition[0]].value(condition[2])}"`
        )
        .join(' E ');
function FormSliceConditions() {
    const { values, setValues } = useFormikContext();

    const [condition, setCondition] = useState({ param: 'x', op: '==', value: '' });

    const onChangeParam = (e) => setCondition((prev) => ({ ...prev, param: e.target.value, value: '' }));
    const onChangeOp = (e) => setCondition((prev) => ({ ...prev, op: e.target.value }));
    const onChangeValue = (e) => setCondition((prev) => ({ ...prev, value: e.target.value }));

    const onAddCondition = () => {
        setValues((prev) => {
            const newSet = [...prev.conditions, [condition.param, condition.op, condition.value]];
            const label = createConditionsLabel(newSet);
            return {
                ...prev,
                conditions: newSet,
                conditions_label: label,
            };
        });
        setCondition({ param: 'x', op: '==', value: '' });
    };

    const onDeleteCondition = (condition) => {
        setValues((prev) => {
            const newSet = prev.conditions.filter((item) => item.join('') !== condition);
            const label = createConditionsLabel(newSet);
            return {
                ...prev,
                conditions: newSet,
                conditions_label: label,
            };
        });
    };

    return (
        <>
            <div className="d-flex flex-wrap col-12 text-small mb-2">
                <select name="param" placeholder="Parâmetro" className="col-12 col-md-3" onChange={onChangeParam}>
                    {Object.keys(params_config).map((key, index) => (
                        <option key={index} value={key}>
                            {params_config[key].label}
                        </option>
                    ))}
                </select>
                <select name="op" className="col-12 col-md-3" onChange={onChangeOp}>
                    {params_config[condition.param].ops.map((op, index) => (
                        <option key={index} value={op}>
                            {ops_available[op]}
                        </option>
                    ))}
                </select>
                <div className="col-12 col-md-3">{params_config[condition.param].selector(onChangeValue)}</div>
                <Button
                    className="col-12 col-md-3"
                    color="secondary"
                    onClick={onAddCondition}
                    disabled={condition.param === '' || condition.op === '' || condition.value === ''}
                >
                    ADICIONAR
                </Button>
            </div>
            <Table striped bordered hover className="col-12">
                <thead>
                    <tr>
                        <th>Parâmetro</th>
                        <th>Condição</th>
                        <th>Valor</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {values.conditions.length > 0 ? (
                        values.conditions.map((condition, index) => (
                            <tr key={index}>
                                <td>{params_config[condition[0]].label}</td>
                                <td>{ops_available[condition[1]]}</td>
                                <td>{params_config[condition[0]].value(condition[2])}</td>
                                <td align="center" width="5%">
                                    <Button
                                        type="button"
                                        color="primary"
                                        title="Excluir condição"
                                        onClick={() => onDeleteCondition(condition.join(''))}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" align="center">
                                Qualquer condição
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
}
export default FormSliceConditions;
