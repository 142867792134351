import React, { useContext } from 'react';
import PlanContext from '../PlanContext';

export default function PlanHeader({ className = '', props }) {
    const { plan, themeColor } = useContext(PlanContext);
    return (
        <div className={`plan-header ${className}`} {...props}>
            <div
                className="title-container"
                style={{
                    backgroundColor: themeColor.light,
                    color: themeColor.title,
                    borderColor: `${themeColor.dark}45`,
                }}
            >
                <span>{plan?.name || ''}</span>
            </div>
            <div className="description-container" style={{ backgroundColor: themeColor.dark }}>
                <span>{plan?.description || ''}</span>
            </div>
        </div>
    );
}
