import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Col, Form, InputGroup, Label, Row } from 'reactstrap';

import Modal from 'react-modal';
import { ErrorMessage, Field, Formik } from 'formik';
import * as yup from 'yup';
import { URLs } from '@app/constants';
import { useUserManager } from '@app/hooks/useUserManager';
import Api from '@app/services/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        width: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
function UserProfilesForm(props) {
    const UserManager = useUserManager();
    const { token } = UserManager;

    const [tooltipIsVisible, setTooltipIsVisible] = useState(false);

    const handleUserSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        const method = values.id ? 'put' : 'post';
        const url = URLs.admin.user_profiles.index + (values.id ? `${values.id}` : '');

        Api({
            method,
            url,
            data: values,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (response.data && response.status === 200) {
                    toast.success(response.data.message);
                    props?.updateRequest();
                    props?.closeModal();
                } else {
                    toast.error(response.data.message);
                }
                setSubmitting(false);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Erro inesperado ao tentar alterar os dados do perfil de usuário!');
                setSubmitting(false);
            });
    };

    const userProfileValidations = yup.object().shape({
        name: yup
            .string()
            .min(5, 'O nome do perfil deve conter ao menos 5 caracteres.')
            .required('É necessário informar o nome do perfil.'),
    });

    const userProfileInitialValues = {
        id: props?.userProfileData?.id || '',
        name: props?.userProfileData?.name || '',
        access: {
            indicators: props?.userProfileData?.access?.indicators?.enabled || false,
            evaluations: props?.userProfileData?.access?.evaluations?.enabled || false,
            contracts: props?.userProfileData?.access?.contracts?.enabled || false,
            single_line: props?.userProfileData?.access?.single_line?.enabled || false,
            atlas: props?.userProfileData?.access?.atlas?.enabled || false,
            analysis: props?.userProfileData?.access?.analysis?.enabled || false,
            user_groups: props?.userProfileData?.access?.user_groups?.enabled || false,
            user_profiles: props?.userProfileData?.access?.user_profiles?.enabled || false,
            users: props?.userProfileData?.access?.users?.enabled || false,
            mesh: props?.userProfileData?.access?.mesh?.enabled || false,
        },
        permissions: {
            contracts: props?.userProfileData?.permissions?.contracts?.enabled || false,
            evaluations: props?.userProfileData?.permissions?.evaluations?.enabled || false,
            user_groups: props?.userProfileData?.permissions?.user_groups?.enabled || false,
            user_profiles: props?.userProfileData?.permissions?.user_profiles?.enabled || false,
            users: props?.userProfileData?.permissions?.users?.enabled || false,
            mesh: props?.userProfileData?.permissions?.mesh?.enabled || false,
        },
        export: props?.userProfileData?.export || false,
    };

    const afterOpenModal = () => {};

    // Funções de alteração dos dados do usuário
    return (
        <Modal
            isOpen={props?.modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={props?.closeModal}
            style={customStyles}
            contentLabel={props?.title}
            className=""
            ariaHideApp={false}
        >
            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <h2 className="flex-grow-1">{props?.title}</h2>
                    <Button
                        type="button"
                        className="btn-info mx-1"
                        color="custom"
                        onMouseOver={() => {
                            setTooltipIsVisible(true);
                        }}
                        onMouseOut={() => {
                            setTooltipIsVisible(false);
                        }}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </Button>
                    <Button type="button" className="btn-modal-close mx-1" color="secondary" onClick={props?.closeModal}>
                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                    </Button>
                </Col>
            </Row>
            {!tooltipIsVisible ? (
                ''
            ) : (
                <div className="modal-tooltip">
                    Obs: Somente usuários com permissão de escrita de &quot;Usuários&quot; poderão enxergar os links para as telas
                    &quot;Tipos de Usuários&quot; e &quot;Perfís de Usuários&quot;
                </div>
            )}
            <Formik
                initialValues={userProfileInitialValues}
                validationSchema={userProfileValidations}
                onSubmit={handleUserSubmit}
                render={(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <Field type="hidden" name="id" />
                        <InputGroup className="mb-3">
                            <Label htmlFor="name">Nome</Label>
                            <Field name="name" type="text" placeholder="Nome" className="col-12" />
                            <ErrorMessage component="span" name="name" className="text-error text-small" />
                        </InputGroup>
                        <Row>
                            <Col className="mb-3 col-6">
                                <h3>Visualização de telas</h3>
                                <Label className="col-12">
                                    <Field type="checkbox" name="access.indicators" className="mx-2" />
                                    Indicadores
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="access.evaluations" className="mx-2" />
                                    Levantamentos
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="access.contracts" className="mx-2" />
                                    Contratos
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="access.mesh" className="mx-2" />
                                    Malhas
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="access.single_line" className="mx-2" />
                                    Unifilar Gerencial
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="access.atlas" className="mx-2" />
                                    Atlas
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="access.analysis" className="mx-2" />
                                    Análise Gerencial
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="access.user_groups" className="mx-2" />
                                    Grupos de Usuários
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="access.user_profiles" className="mx-2" />
                                    Perfís de Usuários
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="access.users" className="mx-2" />
                                    Usuários
                                </Label>
                            </Col>
                            <Col className="mb-3 col-6">
                                <h3>Permissões</h3>
                                <Label className="col-12">
                                    <Field type="checkbox" name="permissions.contracts" className="mx-2" />
                                    Contratos
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="permissions.mesh" className="mx-2" />
                                    Malhas
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="permissions.evaluations" className="mx-2" />
                                    Avaliações
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="permissions.user_groups" className="mx-2" />
                                    Grupos de Usuários
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="permissions.user_profiles" className="mx-2" />
                                    Perfís de Usuários
                                </Label>
                                <Label className="col-12">
                                    <Field type="checkbox" name="permissions.users" className="mx-2" />
                                    Usuários
                                </Label>
                                <Label className="col-12 mt-3">
                                    <Field type="checkbox" name="export" className="mx-2" />
                                    Exibir botão &quot;Exportar&quot;
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3 col-6">
                                <Button type="button" className="mx-2" color="danger" onClick={props?.closeModal}>
                                    Cancelar
                                </Button>
                                <Button type="submit" className="mx-2" color="primary" disabled={formikProps.isSubmitting}>
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            />
        </Modal>
    );
}

export default UserProfilesForm;
