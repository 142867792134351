import React from 'react';
import { Row } from 'reactstrap';
import FilterContainer, { FilterFields, FilterTabLink, FilterTabs } from '@app/containers/FilterContainer/FilterContainer';

function AccountTabsMenu({ active = '', children, ...props }) {
    return (
        <FilterContainer showTitle={false} className="show-border" {...props}>
            <FilterFields>
                <Row>{children}</Row>
            </FilterFields>
            <FilterTabs active="profile">
                <FilterTabLink className="px-2" key="profile" active={active === 'data'} to="/conta">
                    Meus Dados
                </FilterTabLink>
                <FilterTabLink className="px-2" key="configurations" active={active === 'configurations'} to="/conta/configuracoes">
                    Configurações
                </FilterTabLink>
                <FilterTabLink className="px-2" key="cards" active={active === 'cards'} to="/conta/cartoes">
                    Cartões
                </FilterTabLink>
                <FilterTabLink className="px-2" key="subscriptions" active={active === 'subscriptions'} to="/conta/assinaturas">
                    Assinaturas
                </FilterTabLink>
                <FilterTabLink className="px-2" key="forms" active={active === 'forms'} to="/conta/formularios">
                    Formulários
                </FilterTabLink>
            </FilterTabs>
        </FilterContainer>
    );
}

export default AccountTabsMenu;
