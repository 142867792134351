import React, { useCallback, useContext } from 'react';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { fas, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import CheckPermission from '@app/components/CheckPermission';
import { CustomSwitch } from '@app/components/ui';
import SectionsContext from '../components/SectionContext';

import './SectionsTable.scss';

function SectionsTableHead() {
    return (
        <thead>
            <tr>
                <th className="text-center">Ícone</th>
                <th>Nome</th>
                <th>Texto do menu</th>
                <th>Tipo</th>
                <th className="text-center">Ativa</th>
                <th className="text-center">Exibir no menu</th>
                <CheckPermission permission="edit">
                    <th>Editar</th>
                </CheckPermission>
            </tr>
        </thead>
    );
}

function SectionRow({ section, index, ...props }) {
    const context = useContext(SectionsContext);

    return (
        <>
            <Draggable key={`section-${section.id}`} draggableId={`${section.token}`} id={section.id} index={index}>
                {(provided, snapshot) => {
                    const draggingClass = snapshot.isDragging ? 'row-dragging' : '';
                    return (
                        <tr
                            key={section.id}
                            {...props}
                            className={`${props?.className} section-${section.type} ${draggingClass}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <td align="center">
                                <FontAwesomeIcon icon={fas[section.icon]} className="mx-2 my-1" />
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                {section?.name}
                                <br />
                                <label className="text-url">{section.url}</label>
                            </td>
                            <td>{parse(section?.label)}</td>
                            <td>{section?.type_label}</td>
                            <td align="center">
                                <CheckPermission
                                    permission="change-status"
                                    allowed={
                                        <CustomSwitch
                                            name={`section-status-${section?.id}`}
                                            className="justify-content-center"
                                            onChange={context.handleSectionStateChange}
                                            checked={section?.status === 1 || false}
                                            isAsync
                                            data={{ token: section?.token, status: section?.status }}
                                            disabled={context.submitting || false}
                                        />
                                    }
                                    notAllowed={
                                        <label className={section.status === 1 ? 'text-success' : 'text-error'}>
                                            {section.status === 1 ? 'ATIVO' : 'INATIVO'}
                                        </label>
                                    }
                                />
                            </td>
                            <td align="center">
                                <CheckPermission
                                    permission="change-showing"
                                    allowed={
                                        <CustomSwitch
                                            name={`section-showing-${section?.id}`}
                                            className="justify-content-center"
                                            onChange={context.handleSectionShowingChange}
                                            checked={section?.menu === 1 || false}
                                            isAsync
                                            data={{ token: section?.token, menu: section?.menu }}
                                            disabled={context.submitting || false}
                                        />
                                    }
                                    notAllowed={
                                        <label className={section.menu === 1 ? 'text-success' : 'text-error'}>
                                            {section.status === 1 ? 'SIM' : 'NÃO'}
                                        </label>
                                    }
                                />
                            </td>

                            <CheckPermission permission="edit">
                                <td className="text-center ">
                                    {section.type !== 'static' && (
                                        <a
                                            name="edit-section"
                                            href={`/admin/sessoes/alterar/${section?.token}`}
                                            className="btn btn-primary-outline"
                                            title="Editar seção"
                                        >
                                            <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                        </a>
                                    )}
                                </td>
                            </CheckPermission>
                        </tr>
                    );
                }}
            </Draggable>
            {(section?.subsections || []).length > 0 && (
                <tr>
                    <td colSpan="8" className="p-0">
                        <div className="table-subsection-container">
                            <SectionsTableDroppable
                                sections={section.subsections}
                                className="table-subsection"
                                droppableId={`${section.id}`}
                            />
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}

function SectionsTableDroppable({ sections, className = '', droppableId, ...props }) {
    return (
        <Droppable droppableId={droppableId} type={droppableId} key={droppableId}>
            {(provided) => (
                <Table striped bordered hover {...props} className={`sections-table ${className}`}>
                    <SectionsTableHead droppableId={droppableId} />
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                        {sections?.length > 0 ? (
                            sections?.map((section, index) => <SectionRow key={index} index={index} section={section} />)
                        ) : (
                            <tr key={0}>
                                <td colSpan={13} align="center">
                                    Nenhum registro retornado
                                </td>
                            </tr>
                        )}
                        {provided.placeholder}
                    </tbody>
                </Table>
            )}
        </Droppable>
    );
}

function SectionsTable({ sections, className = '', ...props }) {
    const { handleSectionOrderChange } = useContext(SectionsContext);

    const onDragEnd = useCallback(
        (result) => {
            if (!result.destination) return;

            handleSectionOrderChange(result.draggableId, result.source.index, result.destination.index);
        },
        [handleSectionOrderChange]
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <SectionsTableDroppable sections={sections} className={className} droppableId="null" {...props} />
        </DragDropContext>
    );
}

export default SectionsTable;
