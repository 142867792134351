import { useFormikContext } from 'formik';
import React from 'react';
import {
    Editor,
    EditorProvider,
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnNumberedList,
    BtnRedo,
    BtnUnderline,
    BtnUndo,
    Separator,
    Toolbar,
} from 'react-simple-wysiwyg';

function FormSliceDetails({ isLoading }) {
    const { values, setFieldValue } = useFormikContext();

    const handleProductDetailsChange = (e) => {
        setFieldValue('details', e.target.value);
    };

    return (
        <div className="mb-1 col-12 px-1">
            <EditorProvider>
                <Editor value={values.details} onChange={handleProductDetailsChange} disabled={isLoading}>
                    <Toolbar>
                        <BtnUndo />
                        <BtnRedo />
                        <Separator />
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <Separator />
                        <BtnNumberedList />
                        <BtnBulletList />
                        <Separator />
                        <BtnClearFormatting />
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </div>
    );
}
export default FormSliceDetails;
