import React, { useState, useEffect } from 'react';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import { Form } from 'reactstrap';
import { useFetch } from '@app/hooks/useFetch';

import { useUserManager } from '@app/hooks';

import { URLs, useReplaceParams } from '@app/constants';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';

import Paginator from '@app/components/Paginator/Paginator';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';

import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CheckPermission from '@app/components/CheckPermission';
import UsersTable from './tables/UsersTable';
import UsersContext from './components/UsersContext';

function Users(props) {
    const UserManager = useUserManager();
    const { token } = UserManager;

    const replaceParams = useReplaceParams;

    /**
     * Consulta tipos de clientes
     */
    const [types, setTypes] = useState(null);
    useEffect(() => {
        if (types === null) return;
        const loadUserTypes = () => {
            const url = replaceParams(URLs.admin.user_types.basics, { nocache: new Date().getTime() });
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (!response.data || response.status !== 200) {
                        toast.error(response?.data?.message || 'Houve um erro ao tentar obter a lista de tipos de usuários.');
                    } else {
                        setTypes(response.data?.data || []);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Houve um erro ao tentar obter a lista de tipos de usuários!');
                });
        };

        loadUserTypes();
    }, [replaceParams, types, token]);

    /**
     * Consulta dados de sessões
     */
    const [params, setParams] = useState({
        nocache: new Date().getTime(),
        name: '',
        context: 'club',
        type: props?.product_type || '',
        pg: 1,
        orderBy: '',
        orderDir: 'asc',
    });
    const updateRequest = () => setParams((prev) => ({ ...prev, ...{ nocache: new Date().getTime(), pg: 1 } }));

    // eslint-disable-next-line prefer-const
    let { data, error, last_page } = useFetch(replaceParams(URLs.admin.users.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
    }, [data, error, last_page]);

    /**
     * Altera o status de um usuário
     */
    const handleUserStatusChange = (e) => {
        const { id } = e.data;
        const { checked } = e.data;

        const url = replaceParams(URLs.admin.users.status, { id });
        Api({ method: 'put', url, data: { status: checked ? 1 : 0 }, headers: { Authorization: `Bearer ${token}` } })
            .then(({ data: respData, status }) => {
                if (!respData || status !== 200) {
                    toast.error(respData?.message || 'Erro ao alterar o status do usuário.');
                    e.data.setStatus(!checked);
                } else {
                    data = data.map((user) => (user.id === id ? { ...user, status: checked } : user));
                    e.data.setStatus(checked);
                    if (!data) updateRequest();
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status do usuário!');
                e.data.setStatus(!checked);
            });
    };

    /**
     * Reseta a senha do usuário
     */
    const handleUserResetPassword = (id) => {
        const url = replaceParams(URLs.admin.users.reset, { id });
        Api({ method: 'put', url, headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao resetar a senha do usuário.');
                } else {
                    toast.success(response?.data?.message);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar resetar a senha do usuário!');
            });
    };

    /**
     * Deleta um usuário
     */
    const handleDeleteUser = (id) => {
        const url = replaceParams(URLs.admin.customers.delete, { id });
        Api({ method: 'delete', url })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao deletar a conta do cliente.');
                } else {
                    toast.success(response?.data?.message);
                    updateRequest();
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar deletar a conta do cliente!');
            });
    };

    /**
     * Enviar um link de confirmação do canal de comunicação (whatsapp/email) ao usuário
     */
    const handleUserConfirmChannel = (id, channel) => {
        const url = replaceParams(URLs.admin.customers.confirm_channel, { id, channel });
        Api({ method: 'post', url })
            .then((response) => {
                if (!response.data || response.status !== 200) {
                    toast.error(response?.data?.message || 'Erro ao enviar link de confirmação.');
                } else {
                    toast.success(response?.data?.message);
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Houve um erro ao tentar enviar link de confirmação!');
            });
    };
    const handleUserConfirmPhone = (id) => handleUserConfirmChannel(id, 'phone');
    const handleUserConfirmEmail = (id) => handleUserConfirmChannel(id, 'email');

    /**
     * Filtros de pesquisa
     */

    const onSearchByNameChange = (e) => setParams((prev) => ({ ...prev, ...{ name: e.target.value, pg: 1 } }));
    const onSearchByTypeChange = (e) => setParams((prev) => ({ ...prev, ...{ type: e.target.value, pg: 1 } }));
    const onSearchByStatusChange = (e) => setParams((prev) => ({ ...prev, ...{ status: e.target.value, pg: 1 } }));
    const handleOrderBy = (orderBy, orderDir) => setParams((prev) => ({ ...prev, ...{ orderBy, orderDir, pg: 1 } }));

    const handleNavigateFirst = () => setParams((prev) => ({ ...prev, ...{ pg: 1 } }));
    const handleNavigateLast = () => setParams((prev) => ({ ...prev, ...{ pg: last_page } }));
    const handleNavigateNext = () => setParams((prev) => ({ ...prev, ...{ pg: data?.length > 0 ? params.pg + 1 : params.pg } }));
    const handleNavigatePrevious = () => setParams((prev) => ({ ...prev, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } }));

    return (
        <ContentWrapper>
            <FilterContainer title="">
                <FilterFields className="col-4">
                    <Form className="login d-flex mb-3 col-12 ">
                        <fieldset className="col-4">
                            <label htmlFor="searchBy">Nome:</label>
                            <input type="text" name="searchBy" value={params.name} onChange={onSearchByNameChange} />
                        </fieldset>
                        <fieldset className="col-2">
                            <label htmlFor="orderBy">Tipo:</label>
                            <select name="status" id="filter-status" onChange={onSearchByTypeChange}>
                                <option value="" data-order-dir="asc">
                                    Todos
                                </option>
                                {types?.map((type, index) => (
                                    <option key={index} value={type.id}>
                                        {type.name}
                                    </option>
                                ))}
                            </select>
                        </fieldset>
                        <fieldset className="col-2">
                            <label htmlFor="orderBy">Status:</label>
                            <select name="status" id="filter-status" onChange={onSearchByStatusChange}>
                                <option value="" data-order-dir="asc">
                                    Todos
                                </option>
                                <option value="1" data-order-dir="asc">
                                    Apenas ativos
                                </option>
                                <option value="0" data-order-dir="asc">
                                    Apenas inativos
                                </option>
                            </select>
                        </fieldset>
                        <fieldset className="">
                            <CheckPermission permission="create">
                                <a href="/admin/usuarios/novo" className="btn btn-primary btn-medium btn-icon-left ">
                                    <FontAwesomeIcon icon={faPlus} className="mx-2" />
                                    Novo usuário
                                </a>
                            </CheckPermission>
                        </fieldset>
                        <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <UsersContext.Provider
                value={{
                    data,
                    handleUserStatusChange,
                    handleUserResetPassword,
                    handleUserConfirmEmail,
                    handleUserConfirmPhone,
                    handleDeleteUser,
                }}
            >
                <div className="page-content">
                    <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                        <Paginator
                            pg={params.pg}
                            last_page={last_page}
                            hasPrevious={params.pg > 1}
                            hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                            handleNavigateFirst={handleNavigateFirst}
                            handleNavigateNext={handleNavigateNext}
                            handleNavigatePrevious={handleNavigatePrevious}
                            handleNavigateLast={handleNavigateLast}
                        />
                    </div>
                    <UsersTable data={data} handleOrderBy={handleOrderBy} />
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                </div>
            </UsersContext.Provider>
        </ContentWrapper>
    );
}

export default Users;
