import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'reactstrap';

import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';

import FilterContainer, { FilterFields } from '@app/containers/FilterContainer/FilterContainer';
import { URLs, useReplaceParams } from '@app/constants';

import Paginator from '@app/components/Paginator/Paginator';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import { useFetch } from '@app/hooks/useFetch';
import FileDownloader from '@app/components/FileDownloader/FileDownloader';
import CheckPermission from '@app/components/CheckPermission';
import Api from '@app/services/Api';
import toast from 'react-hot-toast';
import CustomersTable from './table/CustomersTable';
import ResetPasswordPopup from './popups/ResetPassword';

function Customers() {
    const replaceParams = useReplaceParams;

    const [params, setParams] = useState({
        name: '',
        status: '',
        nocache: new Date().getTime(),
        pg: 1,
        orderBy: '',
        orderDir: 'asc',
    });
    const { data, error, last_page } = useFetch(replaceParams(URLs.admin.customers.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
    }, [data, error]);

    const updateRequest = useCallback(() => setParams((prev) => ({ ...prev, ...{ nocache: new Date().getTime() } })), []);

    const handleCustomerStateChange = useCallback(
        (e) => {
            const url = replaceParams(URLs.admin.customers.status, { id: e.data.id });
            Api({ method: 'put', url, data: { status: e.data.checked ? 1 : 0 } })
                .then((response) => {
                    if (!response.data || response.status !== 200) {
                        toast.error(response?.data?.message || 'Erro ao alterar o status do cliente.');
                        e.data.setStatus(!e.data.checked);
                    }
                    if (params.status !== '') updateRequest();
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Houve um erro ao tentar alterar o status do cliente!');
                    e.data.setStatus(!e.data.checked);
                });
        },
        [replaceParams, params, updateRequest]
    );

    /**
     * Deleta um usuário
     */
    const handleDeleteCustomer = useCallback(
        (id) => {
            const url = replaceParams(URLs.admin.customers.delete, { id });
            Api({ method: 'delete', url })
                .then((response) => {
                    if (!response.data || response.status !== 200) {
                        toast.error(response?.data?.message || 'Erro ao deletar a conta do cliente.');
                    } else {
                        toast.success(response?.data?.message);
                        updateRequest();
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Houve um erro ao tentar deletar a conta do cliente!');
                });
        },
        [replaceParams, updateRequest]
    );

    /**
     * Enviar um link de confirmação do canal de comunicação (whatsapp/email) ao usuário
     */
    const handleCustomerConfirmChannel = useCallback(
        (id, channel) => {
            const url = replaceParams(URLs.admin.customers.confirm_channel, { id, channel });
            Api({ method: 'post', url })
                .then((response) => {
                    if (!response.data || response.status !== 200) {
                        toast.error(response?.data?.message || 'Erro ao enviar link de confirmação.');
                    } else {
                        toast.success(response?.data?.message);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Houve um erro ao tentar enviar link de confirmação!');
                });
        },
        [replaceParams]
    );
    const handleCustomerConfirmPhone = (id) => handleCustomerConfirmChannel(id, 'phone');
    const handleCustomerConfirmEmail = (id) => handleCustomerConfirmChannel(id, 'email');

    /**
     * Popup de redefinição de senha do usuário
     */
    const [resetPasswordPopupConfig, setResetPasswordPopupConfig] = useState({
        isOpen: false,
        userId: null,
        userName: '',
        confirmedChannels: {
            whatsapp: false,
            'e-mail': false,
        },
    });

    const closeResetPasswordPopup = () => setResetPasswordPopupConfig({ isOpen: false, userId: null, userName: '' });
    const openResetPasswordPopup = (userId, userName, whatsappConfirmed, emailConfirmed) =>
        setResetPasswordPopupConfig({
            isOpen: true,
            userId,
            userName,
            confirmedChannels: { whatsapp: whatsappConfirmed, 'e-mail': emailConfirmed },
        });

    /**
     * Configuração de busca
     */
    const onSearchByNameChange = (e) => setParams({ ...params, ...{ name: e.target.value, pg: 1 } });
    const onSearchByStatusChange = (e) => setParams({ ...params, ...{ status: e.target.value, pg: 1 } });
    const handleOrderBy = (orderBy, orderDir) => setParams({ ...params, ...{ orderBy, orderDir, pg: 1 } });

    const handleNavigateFirst = () => setParams({ ...params, ...{ pg: 1 } });
    const handleNavigateLast = () => setParams({ ...params, ...{ pg: last_page } });
    const handleNavigateNext = () => setParams({ ...params, ...{ pg: data?.length > 0 ? params.pg + 1 : params.pg } });
    const handleNavigatePrevious = () => setParams({ ...params, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } });

    return (
        <ContentWrapper>
            <FilterContainer title="">
                <FilterFields className="col-4">
                    <Form className="login d-flex mb-3 col-12 ">
                        <fieldset className="col-4">
                            <label htmlFor="searchBy">Nome:</label>
                            <input type="text" name="searchBy" value={params.name} onChange={onSearchByNameChange} />
                        </fieldset>
                        <fieldset className="col-2">
                            <label htmlFor="orderBy">Status:</label>
                            <select name="status" id="filter-status" onChange={onSearchByStatusChange}>
                                <option value="" data-order-dir="asc">
                                    Todos
                                </option>
                                <option value="1" data-order-dir="asc">
                                    Apenas ativos
                                </option>
                                <option value="0" data-order-dir="asc">
                                    Apenas inativos
                                </option>
                            </select>
                        </fieldset>
                        <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <div className="page-content">
                <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                    <CheckPermission permission="export">
                        <div className="d-flex mb-1">
                            <FileDownloader
                                url={replaceParams(URLs.admin.customers.export, { ...params, ...{ ext: 'csv' } })}
                                filename="clientes"
                                label="Exportar CSV"
                                extension="csv"
                                className=""
                            />
                        </div>
                    </CheckPermission>
                </div>
                <CustomersTable
                    data={data}
                    handleOrderBy={handleOrderBy}
                    handleCustomerStateChange={handleCustomerStateChange}
                    handleCustomerResetPassword={openResetPasswordPopup}
                    handleCustomerConfirmPhone={handleCustomerConfirmPhone}
                    handleCustomerConfirmEmail={handleCustomerConfirmEmail}
                    handleDeleteCustomer={handleDeleteCustomer}
                />
                <Paginator
                    pg={params.pg}
                    last_page={last_page}
                    hasPrevious={params.pg > 1}
                    hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                    handleNavigateFirst={handleNavigateFirst}
                    handleNavigateNext={handleNavigateNext}
                    handleNavigatePrevious={handleNavigatePrevious}
                    handleNavigateLast={handleNavigateLast}
                />
            </div>
            <ResetPasswordPopup {...resetPasswordPopupConfig} onClose={closeResetPasswordPopup} />
        </ContentWrapper>
    );
}

export default Customers;
