import React, { useEffect, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { Label } from 'reactstrap';
import { toast } from 'react-hot-toast';

import Api from '@app/services/Api';
import { URLs, useReplaceParams } from '@app/constants';
import { useUserManager } from '@app/hooks/useUserManager';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

function FormSliceTypeFields({ isLoading, featureLabels }) {
    const replaceParams = useReplaceParams;

    const UserManager = useUserManager();
    const { token } = UserManager;

    const { values, setValues } = useFormikContext();

    // Carrega os dados do produto
    const [userTypes, setUserTypes] = useState([]);
    useEffect(() => {
        if (userTypes.length !== 0) return;

        const loadUserTypes = () => {
            const url = replaceParams(URLs.admin.user_types.list, { nocache: new Date().getTime() });
            Api({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
                    if (response.data && response.status === 200) {
                        setUserTypes(response.data);
                    }
                })
                .catch(({ response }) => {
                    toast.error(response?.data?.message || 'Erro inesperado ao obter os dados dos tipos de usuário');
                });
        };

        loadUserTypes();
    }, [replaceParams, token, userTypes]);

    return (
        <>
            <div className="mb-1 px-1 col-12 col-sm-6 col-lg-4">
                <Label className="field-label" htmlFor="user_type_id">
                    Tipo de usuário
                </Label>
                <select
                    name="user_type_id"
                    className="col-12"
                    value={values?.user_type_id || ''}
                    onChange={(e) =>
                        setValues((prev) => {
                            const id = parseInt(e.target.value);
                            return {
                                ...prev,
                                ...{
                                    user_type_id: id,
                                    type: userTypes[userTypes.findIndex((item) => item.id === id)],
                                },
                            };
                        })
                    }
                    disabled={isLoading}
                >
                    <option value="" disabled>
                        &nbsp;
                    </option>
                    {userTypes?.map((item, index) => (
                        <option key={index} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
                <ErrorMessage component="span" name="user_type_id" className="text-error text-small px-2" />
            </div>
            <div className="mb-1 px-1 col-12">
                {(values?.type?.sections || []).map((item, index) => (
                    <Label key={index} className="badge badge-purple mx-1" title={item.description}>
                        <span className="mx-2">{item.name}</span>
                        {(item?.features || []).map((feature, index) => (
                            <FontAwesomeIcon
                                key={index}
                                icon={fas[featureLabels?.[feature]?.icon || 'faQuestion']}
                                title={featureLabels?.[feature]?.title || ''}
                                className="mx-1 text-regular-yellow"
                            />
                        ))}
                    </Label>
                ))}
            </div>
        </>
    );
}
export default FormSliceTypeFields;
