import React, { useContext, useMemo, useState } from 'react';

import ProductDisplay from '@app/components/Products/ProductDisplays';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import ContentEditorContext from '../ContentEditorContext';
import { ContentSizeSelector, ContentPositionDropdown, ProductDisplayTypeDropdown } from '../CustomTools';
import ProductDisplayEditorContext from './ProductDisplayEditorContext';
import SelectProductsPopup from './popups/SelectProductsPopup';

function ProductSearchFilter({ content }) {
    const { setValues } = useFormikContext();
    const { openSelectProductsPopup } = useContext(ProductDisplayEditorContext);

    const changeFilterFieldValue = (field, value) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => {
                if (item.id === content.id)
                    return {
                        ...item,
                        filter: {
                            ...item.filter,
                            [field]: value,
                        },
                    };
                return item;
            }),
        }));
    };

    const onChangeFilterBy = (e) => {
        changeFilterFieldValue('by', e.target.value);
    };

    const onChangeFilterLimit = (e) => {
        changeFilterFieldValue('limit', parseInt(e.target.value));
    };

    const onChangeFilterOrder = (e) => {
        changeFilterFieldValue('order', e.target.value);
    };

    return (
        <>
            <div className="d-flex flex-column p-1">
                <label className="text-small pl-1">Filtro:</label>
                <div className="d-flex">
                    <select
                        name="filter_by"
                        title="Tipo de filtro"
                        className="col-12 flex-shrink-1 m-1"
                        onChange={onChangeFilterBy}
                        defaultValue={content.filter.by}
                    >
                        <option value="ids">Seleção de produtos</option>
                        <option value="tags">Filtro por tags</option>
                        <option value="aleatory">Lista aleatória</option>
                        <option value="onsale">Produtos em promoção</option>
                    </select>
                    {content.filter.by === 'ids' && (
                        <button type="button" className="btn btn-secondary text-medium" onClick={openSelectProductsPopup}>
                            <FontAwesomeIcon icon={faTasks} className="mr-1" />
                            Selecionar
                        </button>
                    )}
                </div>
            </div>
            <div className="d-flex flex-column p-1">
                <label className="text-small pl-1">Qtd. de produtos:</label>
                <select
                    name="limit"
                    title="Quantidade de itens"
                    id="product-search-filter-limit"
                    className="col-12 m-1"
                    onChange={onChangeFilterLimit}
                    defaultValue={content.filter.limit}
                    disabled={content.filter.by === 'ids'}
                >
                    {Array(30)
                        .fill(0)
                        .map((_, index) => (
                            <option key={index} value={index + 1}>
                                {index + 1}
                            </option>
                        ))}
                </select>
            </div>
            <div className="d-flex flex-column p-1">
                <label className="text-small pl-1">Ordenar por:</label>
                <select
                    name="order"
                    title="Ordenar itens por"
                    id="product-search-filter-order"
                    className="col-12 m-1"
                    onChange={onChangeFilterOrder}
                    defaultValue={content.filter.order}
                >
                    <option value="aleatory">Aleatório</option>
                    <option value="updated_at">Atualização</option>
                    <option value="created_at">Cadastro</option>
                    <option value="price">Menor preço</option>
                </select>
            </div>
        </>
    );
}

function ProductDisplayEditorToolbar({ content, ...props }) {
    return (
        <div className="toolbar d-flex flex-wrap col-12" {...props}>
            <ContentSizeSelector id={content.id} contentClass={content.className} className="d-flex flex-wrap" />
            <ContentPositionDropdown id={content.id} position={content.order} />
            <div className="col-12 d-flex flex-wrap">
                <div className="d-flex flex-column p-1">
                    <label className="text-small pl-1">Expositor:</label>
                    <ProductDisplayTypeDropdown id={content.id} />
                </div>
                <ProductSearchFilter content={content} className="d-flex flex-wrap" />
            </div>
            {props.children}
        </div>
    );
}

function ProductDisplayEditor({ content }) {
    const { isChanging, onApplyChanges, onDeleteContent } = useContext(ContentEditorContext);
    const { setValues } = useFormikContext();

    const display = useMemo(
        () => (
            <ProductDisplay
                type={content.displayType}
                filter={content.filter}
                className={content.className}
                productClassName=""
                style={{ width: '100%' }}
                blockControls
            />
        ),
        [content]
    );

    const [selectProductsPopupConfig, setSelectProductsPopupConfig] = useState({ isOpen: false });
    const openSelectProductsPopup = (field) => {
        setSelectProductsPopupConfig((prev) => ({
            ...prev,
            ...{
                isOpen: true,
                field,
            },
        }));
    };
    const closeSelectProductsPopup = () => {
        setSelectProductsPopupConfig((prev) => ({ ...prev, isOpen: false }));
    };
    const onApplyProductsSelection = (criteria) => {
        setValues((prev) => ({
            ...prev,
            content: prev.content.map((item) => {
                if (item.id === content.id) {
                    return {
                        ...item,
                        filter: {
                            ...item.filter,
                            criteria,
                        },
                    };
                }
                return item;
            }),
        }));
        closeSelectProductsPopup();
    };

    return (
        <>
            {isChanging === true && content && (
                <ProductDisplayEditorContext.Provider
                    value={{
                        content,
                        openSelectProductsPopup,
                        closeSelectProductsPopup,
                        onApplyProductsSelection,
                    }}
                >
                    <ProductDisplayEditorToolbar content={content}>
                        <div className="col-12 my-1 d-flex">
                            <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                                <button type="button" className="btn btn-success col-10" onClick={onApplyChanges}>
                                    APLICAR
                                </button>
                            </div>
                            <div className="col-6 text-center" style={{ maxWidth: '200px' }}>
                                <button type="button" className="btn btn-danger col-10" onClick={() => onDeleteContent(content.id)}>
                                    EXCLUIR
                                </button>
                            </div>
                        </div>
                    </ProductDisplayEditorToolbar>
                    <SelectProductsPopup {...selectProductsPopupConfig} />
                </ProductDisplayEditorContext.Provider>
            )}
            {display}
        </>
    );
}

export default ProductDisplayEditor;
