import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomerPendenciesBadge } from '../../../../components/CustomerProfile';

export default function UserAccountLink({ userName = '', isClosed = false }) {
    const content = useMemo(
        () => (
            <>
                <span>{isClosed ? userName.split(' ')[0] : userName}</span>
                <CustomerPendenciesBadge hideText={isClosed} />
            </>
        ),
        [userName, isClosed]
    );

    return (
        <div className="user-profile">
            <div className="user-profile-icon">
                <FontAwesomeIcon icon={faUser} className="mx-2 my-1" />
            </div>
            <div className="user-profile-name">
                <NavLink
                    tag={Link}
                    to={{
                        pathname: '/conta',
                        section: { name: '' },
                    }}
                    title="Minha conta"
                >
                    {content}
                </NavLink>
            </div>
        </div>
    );
}
