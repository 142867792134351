import React, { useState, useEffect } from 'react';
import { Form } from 'reactstrap';
import ContentWrapper from '@app/pages/shared/ContentWrapper/ContentWrapper';
import LoadingSpinner from '@app/components/LoadingSpinner/LoadingSpinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { URLs, useReplaceParams } from '@app/constants';
import { useFetch } from '@app/hooks/useFetch';

import FilterContainer, { FilterFields, FilterTabLink, FilterTabs } from '@app/containers/FilterContainer/FilterContainer';

import Paginator from '@app/components/Paginator/Paginator';
import CertificatesTable from './tables/CertificatesTable';

function CertificatesHome() {
    const replaceParams = useReplaceParams;

    /**
     * Listando certificados pendentes de avaliação
     */
    const [params, setParams] = useState({
        nocache: new Date().getTime(),
        pg: 1,
        status: '',
        searchBy: '',
        startDate: '',
        endDate: '',
        orderBy: '',
        orderDir: 'asc',
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // Consulta de dados de sessões
    const { data, error, last_page } = useFetch(replaceParams(URLs.admin.certificates.list, params));
    const [isLoading, setIsLoading] = useState(!data && !error);
    useEffect(() => {
        setIsLoading(!data && !error);
    }, [data, error]);
    const onSearchByStatusChange = (e) => setParams({ ...params, ...{ status: e.target.value } });
    const onSearchByDateChange = (e) => setParams({ ...params, ...{ searchBy: e.target.value } });
    const onSearchByStartDateChange = (date) => {
        setStartDate(date);
        if (endDate && date > endDate) setEndDate(date);

        const dt = date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : '';
        setParams({ ...params, ...{ startDate: dt, pg: 1 } });
    };
    const onSearchByEndDateChange = (date) => {
        setEndDate(date);
        const dt = date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : '';
        setParams({ ...params, ...{ endDate: dt, pg: 1 } });
    };

    const handleOrderBy = (orderBy, orderDir) => setParams({ ...params, ...{ orderBy, orderDir, pg: 1 } });

    const handleNavigateFirst = () => setParams({ ...params, ...{ pg: 1 } });
    const handleNavigateLast = () => setParams({ ...params, ...{ pg: last_page } });
    const handleNavigateNext = () => setParams({ ...params, ...{ pg: data?.length > 0 ? params.pg + 1 : params.pg } });
    const handleNavigatePrevious = () => setParams({ ...params, ...{ pg: params.pg > 1 ? params.pg - 1 : 1 } });

    return (
        <ContentWrapper>
            <FilterContainer showTitle={false} className="show-border">
                <FilterFields />
                <FilterTabs>
                    <FilterTabLink key="certificados" to="/admin/certificados" active>
                        Certificados
                    </FilterTabLink>
                    <FilterTabLink key="agenda" to="/admin/certificados/agenda">
                        Agenda
                    </FilterTabLink>
                </FilterTabs>
            </FilterContainer>
            <FilterContainer title="">
                <FilterFields className="col-4">
                    <Form className="login d-flex mb-3 col-12 ">
                        <fieldset className="col-12 col-sm-6 col-md-4">
                            <label htmlFor="status">Status:</label>
                            <select name="status" id="filter-status" onChange={onSearchByStatusChange}>
                                <option value="" data-order-dir="asc">
                                    Todos
                                </option>
                                <option value="0" data-order-dir="asc">
                                    Aguardando envio dos documentos
                                </option>
                                <option value="1" data-order-dir="asc">
                                    Aguardando revisão dos documentos
                                </option>
                                <option value="2" data-order-dir="asc">
                                    Documentos recusados
                                </option>
                                <option value="3" data-order-dir="asc">
                                    Aguardando agendamento da entrevista
                                </option>
                                <option value="4" data-order-dir="asc">
                                    Entrevista agendada
                                </option>
                                <option value="5" data-order-dir="asc">
                                    Aguardando emissão do certificado
                                </option>
                                <option value="6" data-order-dir="asc">
                                    Certificado emitido
                                </option>
                                <option value="9" data-order-dir="asc">
                                    Cancelado
                                </option>
                            </select>
                        </fieldset>
                        <fieldset className="col-12 col-sm-6 col-md-4">
                            <label htmlFor="status">Filtrar por:</label>
                            <select name="searchBy" id="filter-date" onChange={onSearchByDateChange}>
                                <option value="request" data-order-dir="asc">
                                    Data da solicitação
                                </option>
                                <option value="schedule" data-order-dir="asc">
                                    Data do agendamento
                                </option>
                                <option value="emission" data-order-dir="asc">
                                    Data da emissão
                                </option>
                            </select>
                        </fieldset>
                        <fieldset className="col-12 col-sm-2">
                            <label htmlFor="orderBy">Data Inicial:</label>
                            <DatePicker
                                className="col-12"
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                clearButtonTitle="Limpar"
                                clearButtonClassName="px-1"
                                selected={startDate}
                                onChange={(date) => onSearchByStartDateChange(date)}
                            />
                        </fieldset>
                        <fieldset className="col-12 col-sm-2">
                            <label htmlFor="orderBy">Data Final:</label>
                            <DatePicker
                                className="col-12"
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                clearButtonTitle="Limpar"
                                clearButtonClassName="px-1"
                                selected={endDate}
                                minDate={startDate}
                                onChange={(date) => onSearchByEndDateChange(date)}
                            />
                        </fieldset>
                        <fieldset className="col-1">{isLoading && <LoadingSpinner />}</fieldset>
                    </Form>
                </FilterFields>
            </FilterContainer>
            <div className="page-content">
                <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                    <Paginator
                        pg={params.pg}
                        last_page={last_page}
                        hasPrevious={params.pg > 1}
                        hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                        handleNavigateFirst={handleNavigateFirst}
                        handleNavigateNext={handleNavigateNext}
                        handleNavigatePrevious={handleNavigatePrevious}
                        handleNavigateLast={handleNavigateLast}
                    />
                </div>
                <CertificatesTable data={data} canChange handleOrderBy={handleOrderBy} />
                <Paginator
                    pg={params.pg}
                    last_page={last_page}
                    hasPrevious={params.pg > 1}
                    hasNext={(last_page !== null && last_page > params.pg) || (last_page === null && data?.length > 0)}
                    handleNavigateFirst={handleNavigateFirst}
                    handleNavigateNext={handleNavigateNext}
                    handleNavigatePrevious={handleNavigatePrevious}
                    handleNavigateLast={handleNavigateLast}
                />
            </div>
        </ContentWrapper>
    );
}

export default CertificatesHome;
