import React from 'react';
import { toast } from 'react-hot-toast';
import { Button } from 'reactstrap';

export default function toastConfirmation(callBack = () => {}, children = null, timeout = 60000) {
    toast.loading(
        (t) => {
            const confirm = () => {
                toast.dismiss(t.id);
                if (typeof callBack === 'function') callBack();
            };
            return (
                <div className="d-flex flex-wrap">
                    <div>{children}</div>
                    <div className="col-12 mt-3 d-flex">
                        <div className="mb-1 px-1 col-6 text-center">
                            <Button type="button" className="col-12 px-1" color="danger" onClick={() => toast.dismiss(t.id)}>
                                CANCELAR
                            </Button>
                        </div>
                        <div className="mb-1 px-1 col-6 text-center">
                            <Button type="button" className="col-12 px-1" color="success" onClick={confirm}>
                                CONFIRMAR
                            </Button>
                        </div>
                    </div>
                </div>
            );
        },
        {
            duration: timeout,
            position: 'top-center',
            icon: '',
        }
    );
}
