const PopupStyle = {
    overlay: {
        zIndex: 111,
        background: '#00000050',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
    },
    content: {
        position: 'unset',
        width: '75%',
        minWidth: '300px',
        background: '#f0e8e8',
        border: 'solid 2px #dbd3d3',
        color: '#595959',
        zIndex: 100000,
        maxHeight: '90vh',
    },
};
export default PopupStyle;
